import React from "react";
import classes from "@css/components/website/SustainabilityFeatures.module.css";
import simple from "../../../assets/landing_page/sustainability/simple.jpg";
import seamless from "../../../assets/landing_page/sustainability/seamless.jpg";
import sustainable from "../../../assets/landing_page/sustainability/sustainable.jpg";
import { Button, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import { KustomerLauncher } from "../../../containers/KustomerLauncher";

function SustainabilityFeatures() {
  const history = useHistory();
  return (
    <section className={classes.container}>
      <div className={classes.mainContent}>
        <div className={classes.featureContainer}>
          <div className={classes.feature}>
            <img src={simple} alt="Simple" />
            <Typography className={classes.featureHeader}>Simple</Typography>
            <Typography className={classes.description}>
              Dimension makes it easy and convenient for your business to minimize its environmental impact.
            </Typography>
          </div>
          <div className={classes.feature}>
            <img src={seamless} alt="Seamless" />
            <Typography className={classes.featureHeader}>Seamless</Typography>
            <Typography className={classes.description}>
              Our all-in-one digital platform allows you to effortlessly integrate eco-friendly waste management into your workflow.
            </Typography>
          </div>
          <div className={classes.feature}>
            <img src={sustainable} alt="Sustainable" />
            <Typography className={classes.featureHeader}>Sustainable</Typography>
            <Typography className={classes.description}>
              We&apos;re here to help you donate, recycle and dispose of unwanted items sustainably, without any additional stress or hassle.
            </Typography>
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button disableRipple className={classes.primaryButton} onClick={() => history.push("booking?step=initial")}>
            Book Now
          </Button>
          <Button disableRipple className={classes.secondaryButtonDark}>
            <KustomerLauncher>Chat With Us</KustomerLauncher>
          </Button>
        </div>
      </div>
    </section>
  );
}

export default SustainabilityFeatures;
