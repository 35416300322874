import React, { useRef, useEffect } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import check from "@assets/check.svg";
import history from "@history";
import { useDispatch, useSelector } from "react-redux";
import bookingConstants from "@store/constants/bookingTypes";
import { bookingStatus } from "@constants/booking";
import { updateBookingState } from "../../../store/actions/booking";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "10000",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0, 0, 0, 0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
  },
  modal: {
    background: "white",
    overflow: "scroll",
    borderRadius: "10px",
    width: "80%",
    maxWidth: "450px",
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
    },
    height: "100%",
  },
  checkImage: {
    backgroundImage: `url(${check})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "100px",
    height: "100px",
    margin: "30px 0px",
  },
}));

export const ThankYouModal = ({ setShowThankYouModal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const booking = useSelector((state) => state.booking.selectedBooking);

  const handleClickOutside = (ev) => {
    if (wrapperRef.current && !wrapperRef.current.contains(ev.target)) {
      dispatch({ type: bookingConstants.ACTIVE_BOOKING, selectedBooking: { ...booking, task_rated: true, status: bookingStatus.done.value } });
      setShowThankYouModal(false);
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={classes.root}>
      <Box className={classes.modal} ref={wrapperRef}>
        <Box className={classes.container}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                marginTop: "20px",
              }}
            >
              {" "}
              Thanks for the Feedback !{" "}
            </Typography>

            <img src={check} alt="checkIcon" className={classes.checkImage} />

            <Typography
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "30px",
              }}
            >
              Your review has been successfully submitted.
            </Typography>

            <Typography
              style={{
                fontSize: "15px",
                textAlign: "center",
                marginBottom: "30px",
                color: "gray",
              }}
            >
              Thank you for choosing Dimension. We hope to serve you again in the near future
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default ThankYouModal;
