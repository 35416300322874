import { useEffect, useMemo, useState } from "react";

const DefaultConfig = {
  makePretty: true,
  showSeconds: false,
};

function calculateTimeLeft(endDate) {
  const difference = new Date(endDate) - new Date();
  if (difference > 0) {
    const timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
    return Object.keys(timeLeft).reduce((acc, item) => {
      return {
        ...acc,
        [item]: timeLeft[item],
      };
    }, {});
  } else {
    return null;
  }
}

export const useTimer = (endDate, interval, config = DefaultConfig) => {
  const { makePretty, showSeconds } = config;
  const [timeLeft, setTimeLeft] = useState(null);
  const numberOfTimeComponents = timeLeft ? Object.keys(timeLeft).length : 0;
  let timer;
  useEffect(() => {
    setTimeLeft(calculateTimeLeft(endDate));
    timer = setInterval(() => {
      if (interval !== null && !interval.current) {
        clearInterval(timer);
        return;
      };
      setTimeLeft(calculateTimeLeft(endDate));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  if (numberOfTimeComponents === 0) {
    return "";
  }

  if (numberOfTimeComponents === 1) {
    if (timeLeft.minutes) {
      return `${timeLeft.minutes}${makePretty ? "m" : ""}`;
    }
    return "< 1m";
  }

  let timerComponents = Object.keys(timeLeft)
    .map((interval) => {
      if (!timeLeft[interval] && !["seconds", "minutes"].includes(interval)) {
        return;
      }

      if (interval === "seconds" && !showSeconds) {
        return;
      }

      let val = timeLeft[interval];
      if (val < 10) {
        val = "0" + val;
      }

      return `${val}${makePretty ? interval[0] : ""}`;
    })
    .filter((item) => !!item);

  return timerComponents.join(makePretty ? " " : ":");
};
