export const INDEXED_DB_NAME = "trashWarrior";
export const INDEXED_DB_VERSION = 1;
export const INDEXED_DB_MAX_LIMIT = 5020;

export const DB_TABLES = {
  bookings: "bookings",
  recurringBookings: "recurringBookings",
  users: "users",
  customers: "customers",
  providers: "providers",
};
