import React, { useState, useEffect } from "react";
import { makeStyles, Button, TextField, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import Rating from "@material-ui/lab/Rating";
import { postReviewRating } from "@store/actions/reviews";
import ThankYouModal from "./ThankYouModal";
import { toastr } from "react-redux-toastr";
import { bookingStatus } from "../../../constants/booking";
import { fetchBookingReview } from "../../../store/actions/reviews";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("xs")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
    },
  },
  container: {
    height: "100%",
  },
  titleText: {
    fontSize: "20px",
    marginTop: "20px",
  },
  backBtn: {
    height: "30px",
    marginBottom: "10px",
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.hover,
    },
  },
  warriorDetails: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  avatarWrapper: {
    height: "170px",
    width: "170px",
    borderRadius: "50%",
  },
  avatar: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "right",
    width: "100%",
    height: "100%",
    border: "5px solid white",
    borderRadius: "50%",
  },
  avatarDetails: {
    padding: "10px 30px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      textAlign: "center",
    },
  },
  header: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  headerText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "27px",
    },
    fontSize: "45px",
    fontWeight: "900",
  },
  titleContainer: {
    [theme.breakpoints.down("sm")]: {
      margin: "unset",
    },
    margin: "0 20px",
  },
  contentWrapper: {
    [theme.breakpoints.down("sm")]: {
      padding: "unset",
    },
    padding: "0 55px",
  },
  textFieldLabel: {
    fontWeight: 600,
    marginBottom: "5px",
  },
  submitButton: {
    marginTop: "20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  section: {
    borderTop: "1px solid #ccc",
    padding: theme.spacing(3, 0),
  },
  ratingText: {
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(3),
  },
  textbox: {
    width: "100%",
  },
}));

const RatingWarrior = ({ booking, userId, page }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [ratingValue, setRatingValue] = useState(0);
  const [commentValue, setCommentValue] = useState("");
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);

  const getRating = async () => {
    if (booking.task_rated) {
      const response = await fetchBookingReview(booking.id);
      if (response && response[0]) {
        const res = response[0];
        setRatingValue(res.rating);
        setCommentValue(res.comment);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getRating();
  }, [page]);

  const handleSubmit = () => {
    if (booking.task_rated) {
      toastr.warning("Oops", "Task already rated");
      return;
    }

    const form = {
      rating: ratingValue,
      comment: commentValue,
      reviewee: booking.warrior_id,
      reviewer: userId || null,
      booking_id: booking.id,
    };
    dispatch(postReviewRating(form));
    setShowThankYouModal(true);
    setSubmitted(true);
  };

  const rated = submitted || booking?.status === bookingStatus.done.value;
  const showForm = rated || page === "review";

  return (
    showForm &&
    !loading && (
      <>
        {showThankYouModal && <ThankYouModal setShowThankYouModal={setShowThankYouModal} />}
        <div className={classes.container}>
          <div className={classes.section}>
            <Typography className={classes.textFieldLabel}>Your rating</Typography>
            <Rating
              style={{ fontSize: "3.7rem" }}
              name="rating"
              size="large"
              value={ratingValue}
              disabled={rated}
              onChange={
                rated
                  ? undefined
                  : (e, newValue) => {
                      setRatingValue(newValue);
                    }
              }
            />
          </div>
          <div className={classes.section}>
            {rated && <Typography className={classes.ratingText}>{`${commentValue}`}</Typography>}
            {!rated && (
              <>
                <TextField
                  name="comment"
                  label="Please leave a comment"
                  variant="outlined"
                  value={commentValue}
                  minRows={3}
                  multiline
                  onChange={(e) => setCommentValue(e.target.value)}
                  className={classes.textbox}
                />
              </>
            )}
            {!rated && (
              <Button className={classes.submitButton} color="primary" variant="contained" disabled={!ratingValue} onClick={handleSubmit}>
                Submit
              </Button>
            )}
          </div>
        </div>
      </>
    )
  );
};

export default RatingWarrior;
