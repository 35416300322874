import React from "react";
import { withStyles, Dialog, DialogTitle, DialogContent, IconButton, Typography, Box, useMediaQuery } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2, 2, 0, 2),
  },
  title: {
    fontWeight: 700,
    fontSize: 20,
    fontFamily: "Roboto, sans-serif",
    textAlign: "center",
    color: theme.palette.grey[800],
  },

  subTitle: {
    fontWeight: 500,
    fontSize: 14,
    fontFamily: "Roboto, sans-serif",
    textAlign: "center",
    color: theme.palette.grey[600],
  },

  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitleComp = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.title}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const DialogContentComp = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(DialogContent);

const DialogSubtitleComp = withStyles(styles)((props) => <Typography className={props.classes.subTitle}>{props.children}</Typography>);

const Modal = ({ open, setOpen, title, subTitle, header, body, footer, containerClass, isMobileFullScreen = false, ...modalProps }) => {
  const isSmallMobile = useMediaQuery("(max-width:400px)");

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullScreen={isMobileFullScreen && isSmallMobile}
      onClose={handleClose}
      aria-labelledby="dialog-invite"
      open={open}
      {...modalProps}
      style={isMobileFullScreen && isSmallMobile && { zIndex: 10000 }}
    >
      <Box className={containerClass || ""}>
        <DialogTitleComp id="dialog-title" onClose={handleClose}>
          {title}
        </DialogTitleComp>
        {subTitle && <DialogSubtitleComp id="dialog-sub-title">{subTitle}</DialogSubtitleComp>}
        {header && <DialogContentComp>{header}</DialogContentComp>}
        {body}
      </Box>
      {footer}
    </Dialog>
  );
};

Modal.defaultProps = {
  open: false,
  setOpen: () => {},
};

export default Modal;
