import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
    "& p": {
      color: theme.palette.primary.main,
      fontWeight: 800,
      [theme.breakpoints.up("md")]: {
        fontSize: 18,
      },
    },
  },
}));

const CouponDiscount = () => {
  const classes = useStyles();
  const selectedCoupon = useSelector((state) => state.coupon.selectedCoupon);

  let discountvalue = "";
  if (selectedCoupon?.type === "percentage") {
    discountvalue = `${selectedCoupon.value}%`;
  } else {
    discountvalue = `$${selectedCoupon.value}`;
  }
  return discountvalue ? (
    <Box className={classes.root}>
      <Typography>Coupon Discount</Typography>
      <Typography>-{discountvalue}</Typography>
    </Box>
  ) : null;
};

export default CouponDiscount;
