import React from "react";
import { Button, Typography } from "@material-ui/core";
import classes from "@css/components/website/BottomCTA.module.css";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { segmentTrack } from "../../../helpers/segment";
import { KustomerLauncher } from "../../../containers/KustomerLauncher";
import { Kustomer } from "../../../helpers/kustomer";

function BottomCTA({ isProviderPortal = false, isB2b = false }: { isProviderPortal?: boolean; isB2b?: boolean }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleButtonClick = (userType: string) => {
    dispatch({ type: "SET_TEMP_USER", userType: userType });
    history.push("/auth/register");
    segmentTrack("User Clicks Register - Provider Portal", { userType: userType });
  };

  const handleProviderSpecialist = () => {
    segmentTrack("Kustomer Provider Specialist Click");
    Kustomer.assistant(Kustomer.assistants.provider);
  };

  return (
    <section className={classes.container}>
      <div className={classes.mainContent}>
        <div className={classes.content}>
          <h4 className={classes.topHeading}>Let&apos;s Get Started</h4>
          <h2 className={classes.heading}>
            {isB2b ? (
              <>
                Are You Ready
                <br />
                to Solve Your Waste
                <br />
                Management
                <br />
                Worries?
              </>
            ) : (
              <>
                Radically
                <br />
                Simplified Waste
                <br />
                Management
              </>
            )}
          </h2>
        </div>
        <div className={classes.content}>
          <Button
            disableRipple
            variant="contained"
            className={classes.primaryButton}
            onClick={() => (isProviderPortal ? handleButtonClick("provider") : history.push("/booking?step=initial"))}
          >
            {isProviderPortal ? "Sign Up Now" : "Book Now"}
          </Button>
          <Button disableRipple variant="contained" className={classes.secondaryButtonDark}>
            {isProviderPortal ? (
              <KustomerLauncher onClick={handleProviderSpecialist}>Chat With Our Provider Specialist</KustomerLauncher>
            ) : (
              <KustomerLauncher>Chat With Us</KustomerLauncher>
            )}
          </Button>
        </div>
      </div>
    </section>
  );
}

export default BottomCTA;
