import React, { useImperativeHandle, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import logoUrl from "@assets/landing_page/dimension-logo-dark.svg";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { toastr } from "react-redux-toastr";

import { savePaymentMethod } from "@store/actions/payments";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  dialogContainer: {
    backgroundColor: "rgb(227,227,230, 0.3)",
    zIndex: "1400 !important",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  companyName: {
    color: "black",
    fontSize: "18px",
    fontWeight: 600,
  },
  dialogTitle: {
    paddingTop: "60px",
    width: "100%",
    justifyContent: "space-around",
    display: "flex",
    textAlign: "center",
    backgroundColor: "#e8e9eb",
  },
  captionText: {
    fontSize: "0.90rem",
  },
  logo: {
    width: "80px",
    alignSelf: "center",
    margin: "auto",
  },
  logoContainer: {
    display: "flex",
    borderColor: "grey",
    borderStyle: "solid",
    borderWidth: "1px",
    width: "100px",
    alignSelf: "center",
    position: "fixed",
    marginTop: "-50px",
    backgroundColor: "white",
    borderRadius: "50%",
    height: "100px",
  },
  formGroup: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  dialogActionsContainer: {
    flex: "auto",
    flexDirection: "column",
    "& > h6": {
      color: "red",
    },
  },
  dialogActions: {
    minWidth: "40%",
    flex: "auto",
    margin: "20px 50px",
    color: "white",
    backgroundColor: "#339edf",
    "&:hover": {
      backgroundColor: "#2194db",
    },
    height: "40px",
    borderRadius: "5px",
  },
  formArea: {
    borderRadius: "10px",
    backgroundColor: "#f5f5f7",
  },
  dialogContent: {},
  cvcCode: {
    flex: 1,
  },
  expDate: {
    flex: 1,
    display: "flex",
    marginRight: "10px",
  },
  splitRow: {
    alignItems: "space-between",
    marginTop: "15px",
  },
}));

const StripeInput = ({ component: Component, inputRef, ...props }) => {
  const elementRef = useRef();
  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus,
  }));
  return <Component onReady={(element) => (elementRef.current = element)} {...props} />;
};

const CollectPaymentInfo = (props) => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const clientSecret = useSelector((state) => state.bookingPayment.setupIntent);
  const profile = useSelector((state) => state.firebase.profile);

  const [errorMessage, setErrorMessage] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = () => {
    setIsSaving(true);
    stripe
      .confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: profile.fullName,
          },
        },
      })
      .then(async function (result) {
        if (result.error) {
          setIsSaving(false);
          setErrorMessage(result.error.message);
        } else {
          await dispatch(savePaymentMethod(result.setupIntent.payment_method));
          setIsSaving(false);
          toastr.success("Success", "Payment method updated");
          props.onClose();
          props?.done?.();
        }
      });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="max-width-dialog-title"
      className={classes.dialogContainer}
    >
      <div className={classes.logoContainer}>
        <img alt="logo" className={classes.logo} src={logoUrl} />
      </div>
      <DialogTitle id="max-width-dialog-title" className={classes.dialogTitle}>
        <Box className={classes.companyName}>Dimension</Box>
        {props.showWarning ? (
          <div>
            <Typography variant="subtitle2" gutterBottom>
              We require every provider to have a credit card on file in case of potential penalty charges listed on the previous page.
              <br />
              <br />
              Please enter your credit card information. Your card will not be charged at this moment.
            </Typography>
          </div>
        ) : (
          <div>
            <Typography variant="subtitle2" gutterBottom>
              Please enter your credit card information.
            </Typography>
          </div>
        )}
        <Divider />
        <Typography variant="caption" display="block" gutterBottom>
          {profile.email}
        </Typography>
      </DialogTitle>
      <Box className={classes.formArea}>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText component={"div"}>
            <FormGroup component={"div"} row className={classes.formGroup}>
              <TextField
                label="Credit Card Number"
                name="ccnumber"
                className={classes.ccNumber}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardNumberElement,
                  },
                }}
                fullWidth
                variant="outlined"
                required={true}
              />
            </FormGroup>
            <FormGroup component={"div"} row className={classes.splitRow}>
              <FormControl component={"div"} className={classes.expDate}>
                <TextField
                  label="Expiration Date"
                  name="expdate"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: StripeInput,
                    inputProps: {
                      component: CardExpiryElement,
                    },
                  }}
                  variant="outlined"
                  required={true}
                />
              </FormControl>
              <FormControl component={"div"} className={classes.cvcCode}>
                <TextField
                  label="CVC"
                  name="cvccode"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: StripeInput,
                    inputProps: {
                      component: CardCvcElement,
                    },
                  }}
                  variant="outlined"
                  required={true}
                />
              </FormControl>
            </FormGroup>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActionsContainer}>
          <Button className={classes.dialogActions} variant="contained" onClick={handleSave}>
            {isSaving ? "Saving..." : "Save"}
          </Button>
          {errorMessage && (
            <Typography variant={"subtitle2"}>
              Errors:
              {errorMessage}
            </Typography>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CollectPaymentInfo;
