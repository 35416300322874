import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import history from "@history";
import { useAxiosGet } from "../../axios";

const validRedirections = ["confirmation", "guest", "task_details"];

const redirections = {
  confirmation: (id, orderNumber) => `/confirmation?id=${id}&orderNumber=${orderNumber}`,
  guest: (id) => `/bookings/${id}`,
  task_details: (id) => `/task_details/${id}?tracking=1`,
};

const BubbleRedirect = ({ slugId }) => {
  const bubbleSlug = useParams().id ?? slugId;

  useEffect(() => {
    const action = async () => {
      const url = new URL(window.location.href);
      const redirectPath = validRedirections.find((i) => url.pathname.includes(i));
      const res = await useAxiosGet(`/slugs/${bubbleSlug}`);
      if (res.data.success) {
        const { bookingId, orderNumber } = res.data;
        window.open(redirections[redirectPath](bookingId, orderNumber), "_self");
      } else {
        toastr.warning("Something went wrong...");
        history.push("/");
      }
    };
    action();
  }, [bubbleSlug]);

  return <div>Redirecting...</div>;
};

export default BubbleRedirect;
