import React, { useState, useRef, useEffect, useImperativeHandle } from "react";
import { makeStyles, Button } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    zIndex: 99,
  },
  dropdownArea: {
    marginTop: "10px",
    minHeight: "45px",
    minWidth: "300px",
    backgroundColor: "white",
    position: "absolute",
    top: "100%",
    borderRadius: "5px",
    padding: "20px",
    boxShadow: theme.shadows[5],
  },
}));

const DropDown = React.forwardRef(({ children, right, component, onClose, open, dropDownClasses, dropDownAreaClasses }, ref) => {
  const classes = useStyles();
  const wrapperRef = useRef(null);

  const btnRef = useRef(null);
  const childBoxRef = useRef(null);

  const [isOpen, changeIsOpen] = useState(false);

  const setIsOpen = (value) => {
    if (!value) {
      onClose && onClose();
    }
    changeIsOpen(value);
  };

  useImperativeHandle(
    ref,
    () => ({
      close: () => {
        setIsOpen((prev) => !prev);
      },
    }),
    []
  );

  useEffect(() => {
    open !== undefined && changeIsOpen(open);
  }, [open]);

  useImperativeHandle(
    ref,
    () => ({
      close: () => {
        setIsOpen((prev) => !prev);
      },
    }),
    []
  );

  const style = right ? { right: "0px" } : { left: "50%", transform: "translateX(-50%)" };

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (ev) => {
    const root = document.getElementById("root");
    if (root.contains(ev.target) && wrapperRef.current && !wrapperRef.current.contains(ev.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropDownClass = classNames(classes.root, dropDownClasses);
  const dropDownAreaClass = classNames(classes.dropdownArea, dropDownAreaClasses);

  return (
    <div className={dropDownClass} ref={wrapperRef}>
      {component ? (
        <div onClick={handleClick}>{component}</div>
      ) : (
        <Button variant="contained" onClick={handleClick} style={{ height: "100px" }} ref={btnRef}></Button>
      )}
      {isOpen && (
        <div style={style} ref={childBoxRef} className={dropDownAreaClass}>
          {children}
        </div>
      )}
    </div>
  );
});

export default DropDown;
