import { Step, Task, Vehicle } from "./enums";

export const taskName = {
  [Task.CardboardRemoval]: "Cardboard Removal",
  [Task.JunkRemoval]: "Waste Removal",
  [Task.DumpsterRental]: "Dumpster Rental",
};

export const vehicleName = {
  [Vehicle.SUV]: "SUV",
  [Vehicle.PickupTruck]: "Pick - Up Truck",
  [Vehicle.Truck]: "Truck",
};

export const vehicleDescription = {
  [Vehicle.SUV]: null,
  [Vehicle.PickupTruck]: "6 ft. long",
  [Vehicle.Truck]: "8 ft. long",
};

export const stepName = (isDefaultBid) => ({
  [Step.Initial]: isDefaultBid ? "Submit a Quote Request" : "What are you looking for?",
  [Step.DateAndTime]: "Date and Time",
  [Step.DoItAgainPreview]: "Task Details",
  [Step.TaskDescription]: "Task Description",
  [Step.Address]: null,
  [Step.ReviewOrder]: "Review your order",
  [Step.Payment]: isDefaultBid ? "Customer Information" : "Payment",
  [Step.QuoteReview]: "Order Details",
  [Step.BidQuoteReview]: "Quote Review",
});
