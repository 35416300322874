import React, { useEffect } from "react";
import { Box, Checkbox, FormControl, FormControlLabel, Link, makeStyles, Typography, FormHelperText } from "@material-ui/core";

import validators from "@booking/helpers/validators";
import { isError, errorCode } from "@booking/helpers/utils";
import { Input } from "@booking/helpers/enums";

import Policy from "./Policy";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "underline",
  },
}));

const TermsOfServiceFieldComponent = ({ expandPolicy, field, onChange, isBidMode }) => {
  const styles = useStyles();

  const { value, validation } = field;

  useEffect(() => {
    onChange({ validation: validators[Input.TermsOfService](field) });
  }, [value]);

  const handleCheckboxChange = (event) => {
    const nextValue = event.target.checked;
    onChange({ value: nextValue });
  };

  const label = (
    <Typography>
      <strong>
        I agree to the{" "}
        <Link href="https://getdimension.com/terms" target="_blank" className={styles.link}>
          Terms of Service
        </Link>{" "}
        and{" "}
        <Link href="https://getdimension.com/privacy-policy" target="_blank" className={styles.link}>
          Privacy Policy
        </Link>
        . *
      </strong>
    </Typography>
  );

  const control = <Checkbox required color="primary" checked={value} onChange={handleCheckboxChange} />;

  return (
    <>
      <Policy expanded={expandPolicy} isBidMode={isBidMode} />
      <FormControl>
        <FormControlLabel label={label} control={control} />
        <FormHelperText error={isError(validation)}>{errorCode(validation)}</FormHelperText>
      </FormControl>
    </>
  );
};

export default React.memo(TermsOfServiceFieldComponent);
