import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  detailsBlockContainer: {
    backgroundColor: "#asdasd",
  },
  detailsGridContainer: {
    padding: theme.spacing(2, 2),
    alignItems: "start",
    columnGap: 15,
    display: "grid",
    width: "100%",
    gridTemplateColumns: "max-content auto",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
    },
  },
  detailsBlockContent: {
    padding: theme.spacing(1, 2),
    width: "100%",
    position: "relative",
  },
  detailsGridContainerText: {
    columnGap: 15,
    display: "grid",
    width: "100%",
    color: "#828282",
    gridTemplateColumns: "150px 500px",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  block: {
    display: "block",
  },
  detailsGridItem: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    color: "#000",
  },
  detailsItemTitle: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#000",
  },
  greenDetailsItemTitle: {
    color: theme.palette.primary.main,
    fontSize: "16px",
    fontWeight: 400,
  },
  detailsItemValue: {
    fontSize: "16px",
    display: ({ isTable }) => (isTable ? "flex" : null),
    flexDirection: ({ isTable }) => (isTable ? "column" : null),
    color: "#000",
  },
  primaryText: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  subText: {
    fontWeight: 400,
    color: theme.palette.primary.main,
  },
  productList: {
    maxWidth: "100%",
    "& > * > * > td": {
      padding: theme.spacing(1),
    },
  },
  map: {
    height: 400,
    width: "100%",
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
}));

function BookingDetailsItem({ icon, label, render, greenLabel, block, isTable }) {
  const classes = useStyles({ isTable });
  return block ? (
    <Grid className={classes.detailsBlockContainer}>
      <Grid container className={classes.detailsGridContainer}>
        {icon && (
          <Grid item className={`${classes.detailsGridItem} ${greenLabel && classes.primaryText}`}>
            {icon ?? ""}
          </Grid>
        )}
        <Grid item className={`${classes.detailsItemValue} ${classes.detailsGridContainerText} ${block ? classes.block : ""}`}>
          <Typography className={`${greenLabel ? classes.greenDetailsItemTitle : classes.detailsItemTitle}`}>{label ?? ""}</Typography>
        </Grid>
      </Grid>
      <Grid item className={classes.detailsBlockContent}>
        {render && render(classes)}
      </Grid>
    </Grid>
  ) : (
    <Grid container className={classes.detailsGridContainer}>
      {icon && (
        <Grid item className={`${classes.detailsGridItem} ${greenLabel && classes.primaryText}`}>
          {icon}
        </Grid>
      )}
      <Grid item className={`${classes.detailsItemValue} ${classes.detailsGridContainerText} ${block ? classes.block : ""}`}>
        <Typography className={`${greenLabel ? classes.greenDetailsItemTitle : classes.detailsItemTitle}`}>{label ?? ""}</Typography>
        {block && <div style={{ height: 15 }} />}
        {render && render(classes)}
      </Grid>
    </Grid>
  );
}

BookingDetailsItem.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  render: PropTypes.func,
  greenLabel: PropTypes.bool,
  block: PropTypes.bool,
};

export default BookingDetailsItem;
