import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "1rem",
    opacity: 0.8,
    paddingBottom: theme.spacing(2),
  },
}));

const Transferfield = ({ timeZone }) => {
  const styles = useStyles();
  return (
    <>
      <Typography className={styles.text}>{timeZone}</Typography>
      <Typography className={styles.text}>
        Provider will arrive within half an hour of your selected time. Eg., if you select 11 AM, provider will target to arrive between 10:30 - 11:30
        AM.
      </Typography>
    </>
  );
};

export default Transferfield;
