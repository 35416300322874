import React, { forwardRef, useEffect } from "react";

import DebouncedTextField from "@shared/components/DebouncedTextField";

import { useBookingPageContext } from "../../context";

function TextFieldComponent({ className, field, label, validator, onChange, error, inputProps, helperText }, ref) {
  const value = field.value || "";
  const booking = useBookingPageContext();

  useEffect(() => {
    onChange({ validation: validator(field, booking) });
  }, [value]);

  const handleChange = (nextValue) => {
    onChange({
      value: nextValue,
    });
  };

  return (
    <DebouncedTextField
      fullWidth
      className={className}
      value={value}
      label={label}
      variant="outlined"
      onChange={handleChange}
      inputProps={inputProps}
      error={error}
      helperText={helperText}
      ref={ref}
    />
  );
}

export default forwardRef(TextFieldComponent);
