import React from "react";
import history from "../../../history";

type AuthContextFooterProps = {
  resetBooking: Function;
};

function AuthContextFooter({ resetBooking }: AuthContextFooterProps) {
  return (
    <div style={{ textAlign: "right" }}>
      <span
        style={{
          textDecoration: "none",
          color: "red",
          cursor: "pointer",
          fontWeight: "500",
        }}
        onClick={() => {
          resetBooking();
          history.push("/");
        }}>
        Close
      </span>
    </div>
  );
}

export default AuthContextFooter;
