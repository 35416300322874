import { useAxiosPost, useAxiosGet } from "../../axios";
import dumpSitesTypes from "../constants/dumpSitesTypes";
import { toastr } from "react-redux-toastr";

export const setSelectedDumpSite = (dumpSite) => (dispatch) => {
  dispatch({
    type: dumpSitesTypes.SET_SELECTED_DUMPSITE,
    payload: dumpSite,
  });
};

export const resetSelectedDumpSite = () => (dispatch) => {
  dispatch({ type: dumpSitesTypes.RESET_SELECTED_DUMPSITE });
};

export const fetchDumpSites = () => async (dispatch) => {
  try {
    dispatch({ type: dumpSitesTypes.SET_DUMPSITES_LOADING });
    const { data } = await useAxiosGet("/dumpsites/fetch-dumpsites");
    dispatch({ type: dumpSitesTypes.FETCH_DUMPSITES, payload: data.response });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error fetching dumpsites: ", error);
  } finally {
    dispatch({ type: dumpSitesTypes.RESET_DUMPSITES_LOADING });
  }
};

export const fetchNearestDumpSites = async (booking) => {
  try {
    const { data } = await useAxiosPost("/dumpsites/fetch-nearest-dumpsites", {
      coordinates: booking.address?.coordinates,
      products: booking.products,
    });
    return data?.dumpsites ?? null;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error fetching nearest dumpsites: ", error);
  }
};

export const createDumpSite = (payload, callback) => async (dispatch, getState) => {
  try {
    const response = await useAxiosPost(`/dumpsites/create-dumpsite/${payload.id}`, { info: payload });
    if (!response?.data) {
      return;
    }
    const { data } = await useAxiosGet(`/dumpsites/fetch-dumpsite/${payload.id}`);
    dispatch({
      type: dumpSitesTypes.ADD_DUMPSITE,
      payload: data?.response,
    });
    callback?.();
    toastr.success("Done", "New Dump Site added");
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    toastr.error("Error", "Error creating new Dump Site");
  }
};

export const updateDumpSite = (payload, callback) => async (dispatch) => {
  try {
    const response = await useAxiosPost(`/dumpsites/update-dumpsite/${payload.id}`, payload);
    if (!response?.data) {
      return;
    }
    const { data } = await useAxiosGet(`/dumpsites/fetch-dumpsite/${payload.id}`, payload);
    dispatch({
      type: dumpSitesTypes.UPDATE_DUMPSITE,
      payload: data?.response,
    });
    toastr.success("Done", "Dump Site updated");
    callback?.();
    dispatch(resetSelectedDumpSite());
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    toastr.error("Error", "Error updating Dump Site");
  }
};

export const deleteDumpSite = (id) => async (dispatch) => {
  try {
    const response = await useAxiosGet(`/dumpsites/delete-dumpsite/${id}`);
    if (!response?.data) {
      return;
    }
    dispatch({
      type: dumpSitesTypes.DELETE_DUMPSITE,
      payload: { id },
    });
    dispatch(resetSelectedDumpSite());
    toastr.success("Done", "Dump Site deleted");
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    toastr.error("Error", "Dump Site could not be deleted");
  }
};
