/* eslint-disable no-console */
import aggregateTypes from "@store/constants/aggregateTypes";
import { useAxiosGet } from "../../axios";

export const fetchAggregates = () => async (dispatch) => {
  try {
    const response = await useAxiosGet("/config/fetch-aggregates");
    dispatch({
      type: aggregateTypes.FETCH_AGGREGATES,
      reviews: response.data.reviews,
      bookings: response.data.bookings,
      counts: response.data.counts,
    });
  } catch (error) {
    console.log("Error fetch config: ", error);
    dispatch({ type: aggregateTypes.RESET_AGGREGATES });
  }
};
