/* eslint-disable no-console */
import React, { Component } from "react";
import Compressor from "compressorjs";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { CheckBox } from "@material-ui/icons";
import { DropzoneDialog } from "material-ui-dropzone";
import { storage } from "@config/firebaseConfig";
import Index from "../../../containers/Footer";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import { Dropzone } from "../../../shared/components/Dropzone";

const { libheif } = require("libheif-js");
const decoder = new libheif.HeifDecoder();

const { createCanvas } = require("canvas");

const ACCEPTED_FORMATS = ["image/*", "image/heic", "image/heif"];
const isHEIC = (type) => ["image/heic", "image/heif"].includes(type);

const useStyles = (theme) => ({
  container: {
    width: "100%",
    height: "auto",
    display: "flex",
    alignContent: "flex-start",
    flexFlow: "row wrap",
  },
  addImageButton: {
    height: "50px",
  },
  deleteImageButton: {
    color: "rgb(235,87,87)",
  },
  imageCheckbox: {
    backgroundColor: "rgba(255, 255, 255, 0.6)",
  },
  icon: {
    marginRight: "8px",
    width: "30px",
  },
  imageInput: {
    display: "none",
  },
  addDeleteContainer: {
    display: "flex",
    alignItems: "center",
    margin: "5px",
  },
});

class ImageHandlerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      baseId: this.props.baseId ?? "unknown",
      showDropzone: false,
      showDelete: false,
      images: this.props.images ? this.props.images : [],
      imageFileNames: this.props.imageFileNames ? this.props.imageFileNames : [],
      maxImageCount: this.props.maxImageCount ? this.props.maxImageCount : 1,
      showDeleteSelected: new Array(this.props.images?.length || 0).fill(false),
      destinationPath: this.props.destinationPath ?? "uploads",
      basePath: this.props.basePath ?? "bookingImages",
      title: this.props.title ?? "Select images to upload!",
      hidePreviews: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.baseId !== this.props.baseId) {
      this.setState({ baseId: this.props.baseId });
    }
    if (prevProps.images?.length !== this.props.images?.length || !(prevProps.images || [])?.every((image, index) => image === this.props.images?.[index])) {
      this.setState({ images: this.props.images });
    }
  }

  renderInitialButton = () => {
    const { classes } = this.props;

    switch (this.state.maxImageCount) {
      case 1:
        return (
          <Button
            variant="outlined"
            onClick={() => {
              this.setState({ showDropzone: true });
            }}
          >
            <PhotoCameraIcon className={classes.icon} color="primary" />
            Select picture
          </Button>
        );
      default:
        return (
          <Button
            variant="outlined"
            onClick={() => {
              this.setState({ showDropzone: true });
            }}
          >
            <PhotoCameraIcon className={classes.icon} color="primary" />
            {this.state.title}
          </Button>
        );
    }
  };

  handleCheckImage = (index) => {
    if (!this.state.showDelete) {
      return;
    }
    let newList = [...this.state.showDeleteSelected];
    newList[index] = !newList[index];
    this.setState({
      showDeleteSelected: newList,
    });
  };

  renderImages = () => {
    const { classes } = this.props;

    return this.state.images?.map((imageurl, index) => {
      //Alternatively we can override the withStyles to accept a prop and use something like
      // backgroundImage: prop => `url(${prop.url})`
      // I thought for this it would be easier to just custom define the stylesheet here
      const imageBox = {
        height: "70px",
        width: "70px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundImage: `url(${imageurl})`,
        margin: "5px",
        display: "flex",
        justifyContent: "flex-end",
        "& > svg": {
          display: "flex",
          alignSelf: "flex-end",
        },
      };
      return (
        <div style={imageBox} key={index} onClick={() => this.handleCheckImage(index)}>
          {this.state.showDelete && this.state.showDeleteSelected[index] ? (
            <CheckBox className={classes.imageCheckbox} checked={this.state.showDeleteSelected[index]} color="primary" />
          ) : (
            <></>
          )}
        </div>
      );
    });
  };

  updateShowDelete = () => {
    if (this.state.showDelete === false) {
      this.setState({
        showDelete: true,
      });
    } else {
      // New Objects so we don't try to overwrite existing array while iterating through it.
      const newImages = this.state.images;
      const newImageFileNames = this.state.imageFileNames;
      const newShowDeleteSelected = new Array(this.state.images?.length + 1).fill(false);
      const imageFileNamesFromUrl = newImages.map((url) => url?.split(this.state.destinationPath)[1]?.split("?")[0].replace("%2", ""));
      this.props.setLoaderState(this.props.loaderState, true);

      for (const isSelected of this.state.showDeleteSelected) {
        const index = this.state.showDeleteSelected.indexOf(isSelected);
        if (isSelected) {
          storage
            .ref(this.state.basePath)
            .child(this.state.baseId)
            .child(this.state.destinationPath)
            .child(this.state.imageFileNames[index] ?? imageFileNamesFromUrl[index])
            .delete();
          this.props.deletedCurrentUploadedImage && this.props.deletedCurrentUploadedImage(newImages[index]);
          newImages.splice(index, 1);
          newImageFileNames.splice(index, 1);
          newShowDeleteSelected.splice(index, 1);
        }
      }

      this.setState({
        showDelete: false,
        images: newImages,
        imageFileNames: newImageFileNames,
        showDeleteSelected: newShowDeleteSelected,
      });
      this.props.setImageUrls(this.state.destinationPath, [...this.state.images]);
      this.props.setLoaderState(this.props.loaderState, false);
    }
  };

  renderImageBox = () => {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        {this.state.images?.length > 0 ? this.renderImages() : <></>}
        <div className={classes.addDeleteContainer}>
          {this.props.maxImageCount > 1 && (
            <Button
              variant="outlined"
              color="primary"
              className={classes.addImageButton}
              onClick={() => {
                this.setState({ showDropzone: true });
              }}
            >
              <PhotoCameraIcon className={classes.icon} color="primary" />+
            </Button>
          )}
          <Button
            className={classes.deleteImageButton}
            onClick={() => {
              this.updateShowDelete();
            }}
          >
            {this.state.showDelete ? `Delete Selected` : `Delete`}
          </Button>
        </div>
      </div>
    );
  };

  handleCompressSuccess = (compressedFile, file, index, fileCount) => {
    const fileExt = file.name.split(".").pop();
    const newFileName = `image_${Date.now()}.${fileExt}`;
    const upload = storage.ref(`${this.state.basePath}/${this.state.baseId}/${this.state.destinationPath}/${newFileName}`).put(compressedFile);
    upload.on(
      "state_changed",
      (snapshot) => {
        // console.log(snapshot);
      },
      (err) => {
        console.error(err);
      },
      () => {
        storage
          .ref(this.state.basePath)
          .child(this.state.baseId)
          .child(this.state.destinationPath)
          .child(newFileName)
          .getDownloadURL()
          .then((url) => {
            this.setState({
              images: [url, ...this.state.images],
              imageFileNames: [newFileName, ...this.state.imageFileNames],
              showDeleteSelected: new Array(this.state.images?.length + 1).fill(false),
            });
            this.props.setImageUrls(this.state.destinationPath, [...this.state.images]);
            this.props.setCurrentUploadedImages &&
              this.props.setCurrentUploadedImages({
                url: url,
                type: file.type,
                size: file.size,
                storageName: newFileName,
                originalName: file.name,
                lastModified: file.lastModified,
              });
            index + 1 === fileCount && this.props.setLoaderState(this.props.loaderState, false);
          });
      }
    );
  };

  base64ToArrayBuffer = (base64) => {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };

  heicToJpegDataUrl = async (dataUrl) => {
    if (!dataUrl?.startsWith("data:image/heic;base64,")) {
      throw new Error("Expected HEIC/HEIF base64 string");
    }

    const inputBuffer = this.base64ToArrayBuffer(dataUrl.substring("data:image/heic;base64,".length));
    const data = decoder.decode(inputBuffer);

    const image = data[0];
    const width = image.get_width();
    const height = image.get_height();

    const outputBuffer = await new Promise((resolve, reject) => {
      image.display({ data: new Uint8ClampedArray(width * height * 4), width, height }, (displayData) => {
        if (!displayData) {
          return reject(new Error("HEIF processing error"));
        }
        resolve(displayData.data.buffer);
      });
    });

    const canvas = createCanvas(width, height);
    canvas.width = width;
    canvas.height = height;

    const context = canvas.getContext("2d");
    const imgData = context.createImageData(width, height);

    const ubuf = new Uint8Array(outputBuffer);
    for (let i = 0; i < ubuf.length; i += 4) {
      imgData.data[i] = ubuf[i]; // red
      imgData.data[i + 1] = ubuf[i + 1]; // green
      imgData.data[i + 2] = ubuf[i + 2]; // blue
      imgData.data[i + 3] = ubuf[i + 3]; // alpha
    }
    context.putImageData(imgData, 0, 0);
    return canvas.toDataURL("image/jpeg");
  };

  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  compressImage = async (file, index, fileCount) => {
    new Compressor(file, {
      quality: 0.6,
      success: (compressedFile) => {
        this.handleCompressSuccess(compressedFile, file, index, fileCount);
      },
      error(err) {
        console.error(err.message);
      },
    });
  };

  convertAndCompressImage = async (imageFile, index, fileCount) => {
    var reader = new FileReader();
    reader.readAsDataURL(imageFile);

    reader.onload = async () => {
      const { name, type, size, file } = imageFile;
      const base64jpeg = await this.heicToJpegDataUrl(reader.result);
      const jpegFile = this.dataURLtoFile(base64jpeg, `${name}.jpeg`);
      await this.compressImage(jpegFile, index, fileCount);
    };

    reader.onerror = (error) => {
      console.error("Error: ", error);
    };
  };

  handleImageUpload = async (files) => {
    this.props.setLoaderState(this.props.loaderState, true);
    files.forEach(async (file, index) => {
      const { type } = file;
      if (isHEIC(type)) {
        await this.convertAndCompressImage(file, index, files?.length);
      } else {
        this.compressImage(file, index, files?.length);
      }
    });
    this.setState({ showDropzone: false });
  };

  handleImageDrop = (files) => {
    const hasHEIC = files.map((file) => file.type).some(isHEIC);
    this.setState({ hidePreviews: hasHEIC });
  };

  onClose = () => {
    this.setState({ showDropzone: false });
  };

  render() {
    return (
      <>
        {this.props.isDropzoneOnly && <Dropzone handleDrop={this.handleImageUpload} />}
        {this.state.images?.length < 1 && !this.props.isDropzoneOnly ? this.renderInitialButton() : <></>}
        {this.state.images?.length > 0 ? this.renderImageBox() : <></>}
        {!this.props.isDropzoneOnly && (
          <DropzoneDialog
            showPreviews
            showFileNamesInPreview={false}
            useChipsForPreview={this.state.hidePreviews}
            acceptedFiles={ACCEPTED_FORMATS}
            cancelButtonText="Cancel"
            submitButtonText="Submit"
            maxFileSize={10000000}
            open={this.state.showDropzone}
            onDrop={this.handleImageDrop}
            onSave={this.handleImageUpload}
            onClose={this.onClose}
          />
        )}
      </>
    );
  }
}

export default withStyles(useStyles)(ImageHandlerForm);
