import { Button, makeStyles, Typography } from "@material-ui/core";
import React, { ReactElement, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BooleanParam, useQueryParam } from "use-query-params";
import Modal from "../shared/components/Modal";
import { fetchUnreadModals, handleClickModal, handleToggleModals, handleViewModal } from "../store/actions/ui";
import uiConstants from "../store/constants/uiTypes";

const useStyles = makeStyles((theme) => ({
  container: {},
  modalBody: {
    padding: theme.spacing(2, 6),
  },
  modalContainer: {},
  modalHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  textSegment: {
    marginTop: theme.spacing(2),
    fontSize: 18,
  },
  modalActions: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: theme.spacing(2),
    padding: theme.spacing(2, 6, 6),
  },
  modalAction: {
    display: "block",
    width: "100%",
    padding: theme.spacing(2, 6, 6),
    boxSizing: "border-box",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    margin: theme.spacing(6, 0, 0),
  },
}));

export const ModalScroller = (): ReactElement | null => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [override] = useQueryParam("override", BooleanParam);
  const modals = useSelector((state: any) => state.ui.modals);
  const currentIndex = useSelector((state: any) => state.ui.currentModal);
  const isLoading = useSelector((state: any) => state.ui.modalsLoading);
  const isOpen = useSelector((state: any) => state.ui.modalsOpen);
  const activeModal = modals?.[currentIndex] ?? null;

  const subscribed = useRef(false);
  useEffect(() => {
    subscribed.current = true;
    dispatch(fetchUnreadModals(subscribed, override));
    return () => {
      subscribed.current = false;
    };
  }, []);

  useEffect(() => {
    if (!activeModal?.id) return;
    dispatch(handleViewModal(activeModal?.id));
  }, [activeModal]);

  const handleNext = async () => {
    dispatch(handleClickModal(activeModal?.id));
    if (currentIndex < modals?.length - 1) {
      dispatch({
        type: uiConstants.SET_CURRENT_MODAL,
        payload: currentIndex + 1,
      });
      return;
    } else {
      dispatch({
        type: uiConstants.SET_MODALS_OPEN,
        payload: false,
      });
    }
  };

  const toggleOpen = (value: boolean) => {
    dispatch(handleToggleModals(value));
  };

  const ModalBody = () => {
    const bodySegments = activeModal?.body?.split(`\\n`);
    return (
      <div className={classes.modalContainer}>
        <div className={classes.modalHeader}>
          {activeModal.icon && <img src={activeModal.icon} alt="modal icon" style={{ paddingTop: "40px", height: 150 }} />}
          <Typography className={classes.title}>{activeModal?.title}</Typography>
        </div>
        <div className={classes.modalBody}>
          {bodySegments.map((segment: string, index: number) => (
            <div key={index}>
              <Typography className={classes.textSegment}>{segment}</Typography>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const ModalFooter = () => (
    <div className={activeModal?.closeText ? classes.modalActions : classes.modalAction}>
      {activeModal?.closeText && (
        <Button>{activeModal?.closeText}</Button>
      )}
      {activeModal?.confirmText ? (
        <Button variant="contained" color="primary" onClick={handleNext} style={{ display: !activeModal?.closeText ? "block" : undefined, marginLeft: "auto", marginRight: "auto", maxWidth: 300, width: "100%" }}>{activeModal?.confirmText}</Button>
      ) : <div />}
    </div>
  );

  if (isLoading) return null;

  return (
    <Modal
      open={isOpen}
      setOpen={toggleOpen}
      title={""}
      subTitle={undefined}
      containerClass={classes.container}
      header={undefined}
      body={<ModalBody />}
      footer={<ModalFooter />}
    />
  );
};
