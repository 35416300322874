import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";

const AcceptButton = ({ onClick, style, ...props }) => {
  const booking = useSelector((state) => state.booking.selectedBooking);
  const user = useSelector((state) => state.firebase.auth);

  const [isAssigned, setIsAssigned] = useState(!!booking.warrior_id);
  const [isAssignedToMe, setIsAssignedToMe] = useState(booking.warrior_id === user?.uid);
  const [isInWaitingList] = useState(booking.waiting_list?.includes(user?.uid));

  useEffect(() => {
    setIsAssigned(!!booking.warrior_id);
    setIsAssignedToMe(booking.warrior_id === user?.uid);
  }, [booking?.warrior_id]);

  if (isAssignedToMe) return null;
  if (isAssigned && !isAssignedToMe && !isInWaitingList) return null;
  if (!isAssigned && booking.waiting_list.length && booking.waiting_list[0] !== user?.uid) return null;

  const buttonText = isAssignedToMe ? "Confirmed" : isAssigned && isInWaitingList ? "Waitlisted" : "Accept";

  return (
    <Button onClick={onClick} color="primary" variant="contained" style={style} {...props}>
      {buttonText}
    </Button>
  );
};

AcceptButton.defaultProps = {
  onClick: () => {},
  style: {},
};

export default AcceptButton;
