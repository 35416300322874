import React, { forwardRef } from "react";
import { useMediaQuery } from "@material-ui/core";
import { MultiReviewOrderStepProps, ReviewOrderStepProps } from "./interface";
import MultiOrderReview from "./MultiOrderReview";
import SingleOrderReview from "./SingleOrderReview";

const ReviewOrderStep = forwardRef((props: ReviewOrderStepProps & MultiReviewOrderStepProps, ref) => {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const isMultipleQuotes = props.config.isMultipleQuotes;
  return isMultipleQuotes && isMobile ? (
    <MultiOrderReview {...props} bookings={props.bookings.filter((booking: any) => props.confirmedQuotes.includes(booking.quoteNumber))} ref={ref} />
  ) : (
    <SingleOrderReview {...props} ref={ref} />
  );
});

export default ReviewOrderStep;
