import React from "react";
import classes from "@css/components/website/B2bTestimonials.module.css";
import { Typography, useMediaQuery } from "@material-ui/core";
// import testimonialImage from "../../../assets/landing_page/testimonial-image.jpg";
import ArrowForward from "@material-ui/icons/ArrowForward";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import classNames from "classnames";

const testimonials = [
  {
    testimonial:
      "Working with Dimension has been great! Their team acts professionally, focuses on communication and ensures my specific needs are being met on a continuous basis.",
    name: "Racquel Battle",
    title: "CEO",
    company: "Erysian Logistix",
    image: null,
  },
  {
    testimonial:
      "Recyclops simply could not do everything we do without Dimension! Their quick and helpful Operations team takes on any problem we have thrown at them and their drivers have been reliable & professional every time. When a new challenge presents itself, we are grateful to have a trustworthy partner like Dimension.",
    name: "Ryan Smith",
    title: "CEO",
    company: "Recyclops",
    image: null,
  },
  {
    testimonial:
      "When one of our customers first needed to dispose of some old product, Dimension was my first call. They exceeded my expectations and were amazing from start to finish. We have since used Dimension a few times now and they will definitely be our first call if we need help again. Strong communication and punctuality.",
    name: "Eric Place",
    title: "Director of Sales and Operations",
    company: "Matson Logistics",
    image: null,
  },
];

function B2bTestimonials() {
  const isNotMobile = useMediaQuery("(min-width:650px)");
  return (
    <section className={classes.container}>
      <div className={classes.mainContent}>
        <div className={classes.headingContainer}>
          <h4 className={classNames(classes.heading, classes.topHeading)}>Don’t Take Our Word for It</h4>
          <h2 className={classes.heading}>
            Hear What Our
            <br />
            Clients Have to Say.
          </h2>
        </div>
        <CarouselProvider
          className={classes.carouselContainer}
          infinite
          naturalSlideWidth={2}
          naturalSlideHeight={1}
          totalSlides={testimonials.length}
        >
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div className={classes.testimonialContainer}>
              {isNotMobile && (
                <ButtonBack className={classes.navigationButton}>
                  <ArrowBack htmlColor="white" />
                </ButtonBack>
              )}
              <Slider className={classes.carousel}>
                {testimonials.map((testimonial, index) => (
                  <Slide key={testimonial.name + testimonial.title} index={index}>
                    <div className={classes.testimonial} key={testimonial.name}>
                      <Typography className={classes.testimonialText}>{testimonial.testimonial}</Typography>
                      <div className={classes.userContainer}>
                        <div className={classes.nameContainer}>
                          <p className={classes.testimonialName}>{testimonial.name}</p>
                          <p className={classes.testimonialJobTitle}>
                            {testimonial.title} - {testimonial.company}
                          </p>
                        </div>
                        {testimonial.image && <img className={classes.testimonialImage} src={testimonial.image} alt="Testimonial person" />}
                      </div>
                    </div>
                  </Slide>
                ))}
              </Slider>
              {isNotMobile && (
                <ButtonNext className={classes.navigationButton}>
                  <ArrowForward htmlColor="white" />
                </ButtonNext>
              )}
            </div>
            <DotGroup className={classes.navigationDots} />
          </div>
        </CarouselProvider>
      </div>
    </section>
  );
}

export default B2bTestimonials;
