/* eslint-disable no-console */
import paymentConstants from "@store/constants/paymentTypes";
import { toastr } from "react-redux-toastr";
import { useAxiosGet, useAxiosPost } from "../../axios";

export const fetchPaymentIntent = (booking) => async (dispatch, getState) => {
  const uid = getState().firebase.auth.uid;
  try {
    const response = await useAxiosPost("/payments/create-payment-intent", { booking, uid });
    dispatch({
      type: paymentConstants.FETCH_PAYMENT_INTENT,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: paymentConstants.FETCH_PAYMENT_INTENT_ERROR,
      payload: error,
    });
    console.log("Error on fetchPaymentIntent", error);
  }
};

export const fetchSetupIntent = (uidOverride) => async (dispatch, getState) => {
  try {
    const uid = uidOverride ?? getState().firebase.auth.uid;
    const response = await useAxiosPost("/payments/create-setup-intent", { uid });
    if (response?.data?.success) {
      dispatch({
        type: paymentConstants.FETCH_SETUP_INTENT,
        payload: response.data.client_secret,
      });
    } else {
      toastr.error("Something went wrong", "Please try again later");
    }
  } catch (error) {
    console.log("Error on fetchSetupIntent", error);
    toastr.error("Something went wrong", "Please try again later");
  }
};

export const savePaymentMethod = (paymentMethodId) => async (_, getState) => {
  try {
    const user = getState().firebase.profile;
    await useAxiosPost("/payments/save-payment-method", { paymentMethodId, user });
    return true;
  } catch (error) {
    console.log("Error on savePaymentMethod", error);
    return false;
  }
};
export const adminSaveCustomerPaymentMethod = (paymentMethodId, user) => async (_, getState) => {
  try {
    await useAxiosPost("/payments/save-payment-method", { paymentMethodId, user });
    return true;
  } catch (error) {
    console.log("Error on savePaymentMethod", error);
    return false;
  }
};

export const setDefaultPaymentMethod = (paymentMethodId) => async (_, getState) => {
  try {
    const user = getState().firebase.profile;
    await useAxiosPost("/payments/set-default-payment-method", { paymentMethodId, user });
  } catch (error) {
    console.log("Error on setDefaultPaymentMethod", error);
  }
};

export const deletePaymentMethod = (paymentMethodId, callback) => async (_, getState) => {
  try {
    const user = getState().firebase.profile;
    await useAxiosPost("/payments/detach-payment-method", { paymentMethodId, user });
    callback?.();
  } catch (error) {
    console.log("Error on deletePaymentMethod", error);
  }
};

export const transferWarriorPayout = (booking, payout, user) => async (dispatch) => {
  try {
    const response = await useAxiosPost("/payments/warrior-payout-transfer", { booking, payout, user });
    if (!response?.data?.success) {
      throw response?.data?.error?.raw?.message ?? "Something went wrong";
    }
    if (payout.method === "stripe") {
      toastr.success("Success", `Stripe transfer of $${payout.amount / 100} sent to ${user.email}`);
    } else {
      toastr.success(`Manual transfer of $${payout.amount / 100} recorded (no Stripe transfer created)`);
    }
    return response?.data?.success;
  } catch (error) {
    toastr.error("Error", error);
    console.log("Error on transferWarriorPayout", error);
  }
};

export const transferPayout = async (form, callback) => {
  try {
    const response = await useAxiosPost("/payments/transfer-payout", form);
    if (!response.data.success) {
      throw response?.data?.error?.raw?.message ?? "Something went wrong";
    }
    toastr.success("Success", ` payout transfer of $${form.amount / 100} sent to ${form.email}`);
    callback?.();
  } catch (error) {
    toastr.error("Error", error);
    console.log("Error on transfer payout", error);
    callback?.(error);
  }
};

export const fetchStripeCustomerId = async (email, uid) => {
  const response = await useAxiosPost("/payments/get-stripe-customer-id", { email, uid });
  try {
    if (response?.data?.success) {
      return response.data?.id;
    } else {
      toastr.error("Something went wrong", "Please try again later");
    }
  } catch (error) {
    console.log("Error fetching stripe customer", error);
    toastr.error("Something went wrong", "Please try again later");
  }
};

export const fetchAvailableBalance = async (stripeAccount) => {
  try {
    const response = await useAxiosPost("/payments/retrieve-balance", { stripeAccount });
    return response.data;
  } catch (error) {
    console.log("Error fetching balance info:", error);
    return null;
  }
};

export const instantPayout = async (form, callback) => {
  try {
    const response = await useAxiosPost("/payments/instant-payout", form);
    if (!response.data.success) {
      throw response?.data?.error?.raw?.message ?? "Something went wrong";
    }
    toastr.success("Done", `$${form.amount / 100} transferred to user account`);
    callback?.();
  } catch (error) {
    toastr.error("Error", error);
    console.log("Error on Instant payout:", error);
  }
};

export const chargeWarrior = async (form, callback) => {
  try {
    const response = await useAxiosPost("/payments/charge-warrior", form);
    if (!response.data.success) {
      throw response?.data?.error?.raw?.message ?? "Something went wrong";
    }
    toastr.success("Done", `Warrior has been charged $${form.amount / 100}`);
    callback?.();
  } catch (error) {
    toastr.error("Failed", `Error charging warrior ${error}`);
    callback?.(error);
  }
};

export const fetchProducts = async () => {
  try {
    const response = await useAxiosGet("/payments/get-products");
    if (!response?.data?.success) {
      throw response?.data?.error?.raw?.message ?? "Something went wrong";
    }
    return response.data;
  } catch (error) {
    toastr.error("Error fetching products", error);
    return null;
  }
};

export const fetchPrice = async (productId) => {
  try {
    const response = await useAxiosGet(`/payments/get-price/${productId}`);
    if (!response?.data?.success) {
      throw response?.data?.error?.raw?.message ?? "Something went wrong";
    }
    return response.data;
  } catch (error) {
    toastr.error("Error fetching products", error);
    return null;
  }
};

export const fetchInvoices = async (bookingId) => {
  try {
    const response = await useAxiosGet(`/payments/get-invoices/${bookingId}`);
    if (!response?.data?.success) {
      throw response?.data?.error?.raw?.message ?? "Something went wrong";
    }
    return response.data;
  } catch (error) {
    toastr.error("Error fetching invoices", error);
    return null;
  }
};

export const fetchStripeCustomer = async (customerId) => {
  try {
    const response = await useAxiosGet(`/payments/get-stripe-customer/${customerId}`);
    if (!response?.data?.success) {
      throw response?.data?.error?.raw?.message ?? "Something went wrong";
    }
    return response.data;
  } catch (error) {
    toastr.error("Error fetching stripe customer details:", error);
    return null;
  }
};

export const createProductAndPrice = async (form, cb) => {
  try {
    const response = await useAxiosPost("/payments/create-product-price", form);
    if (!response?.data?.success) {
      throw response?.data?.error?.raw?.message ?? "Something went wrong";
    }
    cb?.();
    return response.data;
  } catch (error) {
    toastr.error("Error creating product", error);
    return null;
  }
};

export const createInvoice = async (form, cb) => {
  try {
    const response = await useAxiosPost("/payments/create-invoice", form);
    if (!response?.data?.success) {
      throw response?.data?.error?.raw?.message ?? "Something went wrong";
    }
    cb?.();
    return response.data;
  } catch (error) {
    toastr.error("Error creating invoice", error);
    return null;
  }
};

export const updateInvoice = async (form, cb) => {
  try {
    const response = await useAxiosPost("/payments/update-invoice", form);
    if (!response?.data?.success) {
      throw response?.data?.error?.raw?.message ?? "Something went wrong";
    }
    cb?.();
    return response.data;
  } catch (error) {
    toastr.error("Error updating invoice", error);
    return null;
  }
};

export const updateCustomer = async (form) => {
  try {
    const response = await useAxiosPost("/payments/update-customer", form);
    if (!response?.data?.success) {
      throw response?.data?.error?.raw?.message ?? "Something went wrong";
    }
    return response.data;
  } catch (error) {
    toastr.error("Error updating customer info", error);
    return null;
  }
};

export const fetchCardInfo = async (chargeId) => {
  try {
    if (!chargeId) throw "No charge id provided";
    const response = await useAxiosPost("/payments/get-card-info", { chargeId });
    if (!response?.data?.success) {
      throw response?.data?.error?.raw?.message ?? "Something went wrong";
    }
    return response.data.payload;
  } catch (error) {
    console.log("Error!", error);
  }
};

export const createConsolidatedInvoice = async (form, cb) => {
  const { genericInvoice } = form;
  try {
    const route = genericInvoice ? "/payments/create-generic-invoice" : "/payments/create-consolidated-invoice";
    const response = await useAxiosPost(route, form);
    if (!response?.data?.success) {
      throw response?.data?.error?.raw?.message ?? "Something went wrong";
    }
    const successMsg = genericInvoice
      ? `Invoice created for: ${form.bookingIds[0]}`
      : `Single invoice consolidated for ${form.bookingIds.length} bookings`;
    toastr.success("Done", successMsg);
    cb?.();
    return response.data;
  } catch (error) {
    toastr.error("Error creating invoice", error);
    return null;
  }
};
