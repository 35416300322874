import React, { useState, forwardRef } from "react";
import { Box } from "@material-ui/core";
import { updateField } from "@booking/helpers/utils";
import Section from "@booking/components/Section";
import { Input } from "@booking/helpers/enums";
import useMountedEffect from "@shared/hooks/useMountedEffect";
import useTriggerValidation from "@shared/hooks/useTriggerValidation";
import ImageHandlerForm from "../../../../CompleteTask/components/ImageHandlerForm";
import DescriptionField from "../../fields/DescriptionField";
import { dumpsterDescription } from "../../constants";
import validators from "../../validators";
import { segmentTrack } from "../../../../../helpers/segment";

const BidRentalTaskDescription = forwardRef(({ defaultValue, onChange }, ref) => {
  const [imageField, setImageField] = useState(defaultValue[Input.TaskImages]);
  const [descriptionField, setDescriptionField] = useState(defaultValue[Input.Description]);

  useMountedEffect(() => {
    onChange({
      [Input.TaskImages]: imageField,
      [Input.Description]: descriptionField,
    });
  }, [imageField, descriptionField]);

  useTriggerValidation(ref, [setImageField, setDescriptionField]);

  const handleDescriptionChange = (field) => {
    setDescriptionField(updateField(descriptionField, field));
  };
  const handleImageChange = (field) => {
    const fieldValidator = validators[Input.TaskImages](field);
    setImageField(updateField(imageField, { ...field, validation: fieldValidator }));
    segmentTrack("Too Many Items Image Uploaded", { service: "Rental" });
  };

  return (
    <Box>
      <Section title="Please upload pictures of your items" width={"100%"}>
        <ImageHandlerForm
          title="Please upload pictures of any items you are getting rid of"
          baseId={"temp"}
          basePath={"bookingImages"}
          images={imageField?.value || []}
          maxImageCount={10}
          destinationPath={"uploads"}
          setImageUrls={(_, images) => handleImageChange({ value: images })}
          loaderState={false}
          setLoaderState={() => {}}
          isDropzoneOnly={true}
        />
      </Section>
      <br />
      <br />
      <DescriptionField field={descriptionField} minRows={8} width={"75%"} description={dumpsterDescription} onChange={handleDescriptionChange} />
    </Box>
  );
});

export default React.memo(BidRentalTaskDescription);
