import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { Map } from "@material-ui/icons";
import dayjs from "dayjs";
import history from "@history";
import address from "@assets/booking_details/address.svg";
import calendarDate from "@assets/booking_details/calendar-date.svg";
import clientNotes from "@assets/booking_details/client-notes.svg";
import destinationType from "@assets/booking_details/destination-type.svg";
import dismantling from "@assets/booking_details/dismantling.svg";
import endDestination from "@assets/booking_details/end-destination.svg";
import frequency from "@assets/booking_details/frequency.svg";
import itemsExpected from "@assets/booking_details/items-expected.svg";
import minVehicle from "@assets/booking_details/min-vehicle.svg";
import stairs from "@assets/booking_details/stairs.svg";
import { taskScheduleFrequency, vehicleTypes } from "@constants/booking";
import BookingDetailsLayout from "@shared/components/BookingDetailsLayout";
import BookingDetailsItem from "@shared/components/BookingDetailsLayout/shared/BookingDetailsItem";
import GoogleMaps from "@shared/components/GoogleMaps";
import ImageGalleryCustom from "@shared/components/ImageGallery";
import Loader from "@shared/components/Loader";
import ProductItem from "@shared/components/ProductItem";
import { dumpSiteTypes } from "../../constants/dumpSites";
import { getDumpsite } from "../../helpers/dumpSites";
import { scrollToTop } from "../../methods/scrollToTop";
import { getDistance } from "../../shared/utils/maps";
import BidAcceptCard from "./BidAcceptCard";
import { submitMyBid, fetchBidById, withdrawMyBid } from "../../store/actions/bookingBids";
import { segmentTrack } from "../../helpers/segment";
import { toastr } from "react-redux-toastr";
import AuthPopup from "../../shared/components/AuthPopup";
import { taskTypes } from "../../constants/booking";

const ImageContainer = ({ image, ...otherProps }) => <img width={21} src={image} {...otherProps} />;

function BidDetails() {
  const dispatch = useDispatch();
  const bidId = useParams().id;
  const selectedBid = useSelector((state) => state.bookingBid.selectedBid);
  const user = useSelector((state) => state.firebase.profile);
  const warriorId = user?.uid;
  const isGuest = user.isEmpty;
  const isDumpsterRental = selectedBid?.task === taskTypes["dumpster-rental"].value;
  const [destination, setDestination] = useState(null);
  const [renderMap, setRenderMap] = useState(false);
  const [bidSubmitted, setBidSubmitted] = useState(false);
  const [showAuthPopup, setShowAuthPopup] = useState(false);
  const isBefore2hours = dayjs(selectedBid?.date).tz(selectedBid?.timeZone).subtract(2, "hours").isBefore(dayjs().tz(selectedBid?.timeZone));

  const bidLimit = {
    "junk-removal": 5000,
    "cardboard-removal": 5000,
    "dumpster-rental": 20000,
  };

  scrollToTop();

  const handleSubmit = async (formData) => {
    const biddingPrice = formData.price * 100;
    const currentBidLimit = bidLimit[selectedBid?.task] ?? 5000;

    if (biddingPrice < currentBidLimit) {
      toastr.warning("Bid Warning", "Your bid is too low, please submit a higher bid.");
      return;
    }

    formData.vehicleSize = "";
    segmentTrack(`${isGuest ? "Guest w" : "W"}arrior Viewed Bid details`, { bidId: bidId });
    const payload = { warriorId: isGuest ? formData.email : user.uid, form: formData, bookingBidId: bidId };
    await dispatch(submitMyBid(payload));
    setBidSubmitted(true);
  };

  const onWithdraw = async (warriorBidId) => {
    if (!warriorBidId || !user.uid) return;
    dispatch(withdrawMyBid(warriorBidId));
  };

  const handleback = () => {
    history.goBack();
  };

  const prepMap = (dest) => {
    setDestination(dest);
    setRenderMap(true);
  };

  useEffect(() => {
    const getBid = async (bidId) => {
      if (bidId) {
        await dispatch(fetchBidById(bidId));
      }
    };
    getBid(bidId);
  }, []);

  useEffect(() => {
    if (selectedBid?.assignedDumpsiteId) {
      getDumpsite(selectedBid?.assignedDumpsiteId, prepMap);
    } else {
      setDestination(null);
    }
  }, [selectedBid]);

  useEffect(() => {
    // inbound tasks need logged in warriors to bid
    if (selectedBid?.isInbound && !warriorId) setShowAuthPopup(true);
  }, [warriorId, selectedBid]);

  const googleMapsPoints = selectedBid && [selectedBid?.address.coordinates];
  const directions =
    destination && selectedBid && isBefore2hours
      ? { origin: selectedBid.address.coordinates, destination: { lat: destination.lat, lng: destination.lng } }
      : null;

  const getDestinationTypeText = () => {
    if (!destination?.type) {
      return null;
    } else {
      return dumpSiteTypes[destination.type].label;
    }
  };

  const getDestinationText = (componentClasses) => {
    if (!destination) {
      return (
        <div className={componentClasses.endDestContainer}>
          <Typography className={componentClasses.greenText}>Flexible, provider&apos;s choice</Typography>
        </div>
      );
    } else {
      return (
        <div className={componentClasses.endDestContainer}>
          <Typography className={componentClasses.greenText}>{destination.name}</Typography>
          <Typography className={componentClasses.subText}>{destination.street}</Typography>
          <Typography className={componentClasses.subText}>
            {`${destination.city ? `${destination.city}, ` : ""}${destination.state ?? ""} ${destination.zip ?? ""}`.trim()}
          </Typography>
          <Typography className={componentClasses.helpText}>
            {`${getDistance(selectedBid?.address?.coordinates, { lat: destination.lat, lng: destination.lng })} miles from pickup location`}
          </Typography>
          <Typography className={componentClasses.helpText}>* Please drop it off at this location to receive payment *</Typography>
        </div>
      );
    }
  };

  const detailItems = () => {
    return [
      {
        icon: <ImageContainer image={calendarDate} alt="Desired service date" />,
        label: "Desired service date",
        show: true,
        render: (componentClasses) => (
          <span className={componentClasses.greenText}> {dayjs(selectedBid.date).tz(selectedBid.timeZone).format("MMM DD, YYYY h:mm A")}</span>
        ),
      },
      {
        icon: <ImageContainer image={frequency} alt="Frequency" />,
        label: "Frequency",
        show: true,
        render: (componentClasses) => (
          <Typography className={componentClasses.greenText}>{taskScheduleFrequency[selectedBid.frequency].label}</Typography>
        ),
      },
      {
        icon: <ImageContainer image={address} alt="Pick up Location" />,
        label: "Pick up Location",
        show: true,
        render: (componentClasses) => (
          <div>
            <Typography
              className={componentClasses.greenText}
            >{`${selectedBid.address?.city}, ${selectedBid.address.state} ${selectedBid.address?.zipCode}`}</Typography>
          </div>
        ),
      },
      {
        icon: <ImageContainer image={endDestination} alt="End Destination" />,
        label: "End Destination",
        show: true,
        render: (componentClasses) => getDestinationText(componentClasses),
        greenLabel: true,
      },
      {
        icon: <ImageContainer image={destinationType} alt="Destination Type" />,
        label: "Destination Type",
        show: !!destination,
        render: (componentClasses) => <Typography className={componentClasses.greenText}>{getDestinationTypeText(componentClasses)}</Typography>,
        greenLabel: true,
      },
      {
        icon: <ImageContainer image={clientNotes} alt="Client Notes" />,
        label: "Client Notes",
        show: true,
        render: (componentClasses) => <Typography>{selectedBid.description}</Typography>,
      },
      {
        icon: <ImageContainer image={minVehicle} alt="Min Vehicle Requirement" />,
        label: "Min Vehicle Requirement",
        show: !!selectedBid.vehicle,
        render: (componentClasses) => <Typography>{vehicleTypes[selectedBid.vehicle]?.label}</Typography>,
      },
      {
        icon: <ImageContainer image={stairs} alt="stairs" />,
        label: "Stairs",
        show: !isDumpsterRental,
        render: (componentClasses) => <Typography>{selectedBid.stairs ?? "Not expected"}</Typography>,
      },
      {
        icon: <ImageContainer image={dismantling} alt="dismantling" />,
        label: "Dismantling",
        show: !isDumpsterRental,
        render: (componentClasses) => <Typography>{selectedBid.dismantling ?? "Not expected"}</Typography>,
      },
    ];
  };

  const productItems = () => {
    return [
      {
        icon: <ImageContainer image={itemsExpected} alt="Items Expected" />,
        label: "Items Expected",
        show: !isDumpsterRental,
        render: (componentClasses) => (
          <div className={componentClasses.productList}>
            {selectedBid?.products?.map((item, index) => (
              <ProductItem key={index} icon={null} label={item.item.description} details={item.item.details} count={item.quantity} />
            ))}
          </div>
        ),
      },
    ];
  };

  const imageItems = () => {
    return [
      {
        icon: null,
        label: "Task Images",
        show: true,
        block: true,
        render: (componentClasses) =>
          selectedBid?.taskImages?.length ? (
            <ImageGalleryCustom images={selectedBid.taskImages} grid={true} />
          ) : (
            <Typography>Not available</Typography>
          ),
      },
    ];
  };

  if (!selectedBid) return <Loader />;

  return (
    <React.Fragment>
      {showAuthPopup && <AuthPopup onClose={() => setShowAuthPopup(false)} allowOutsideClick={false} isProvider={true} />}
      <BookingDetailsLayout
        subTitle={`Bid: ${selectedBid?.bidNumber} - ${taskTypes[selectedBid?.task].label}`}
        title="Bid Details"
        backText={"Back to tasks"}
        sections={[detailItems(), productItems(), imageItems()]}
        card={
          showAuthPopup ? <div /> : <BidAcceptCard isGuest={isGuest} onSubmit={handleSubmit} onWithdraw={onWithdraw} bidSubmitted={bidSubmitted} />
        }
        goBack={handleback}
      >
        {renderMap ? (
          <BookingDetailsItem
            icon={<Map />}
            block={true}
            label="Map"
            render={(componentClasses) => (
              <div className={componentClasses.map}>
                <GoogleMaps markers={isBefore2hours ? [googleMapsPoints[0]] : googleMapsPoints} showCard={!isBefore2hours} route={directions} />
              </div>
            )}
          />
        ) : (
          <div />
        )}
      </BookingDetailsLayout>
    </React.Fragment>
  );
}

export default BidDetails;
