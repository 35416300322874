import React from "react";
import { Box, makeStyles, Typography, Grid } from "@material-ui/core";

import Logo from "./Logo";
import Services from "./Services";
import AboutUs from "./AboutUs";
import Provider from "./Provider";
import Buttons from "./Buttons";
import Background from "@shared/components/Background";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#333",
    color: "#fff",
    display: "flex",
    padding: ({ isBookBarVisible }) => theme.spacing(5, 5, isBookBarVisible ? 15 : 5, 5),
  },
  condensedFooter: {
    backgroundColor: "#333",
    color: "#fff",
    display: "flex",
    textAlign: "center",
    padding: theme.spacing(2),
  },
  copyright: {
    width: "100%",
    marginTop: "10px",
    textAlign: "center",
  },
  spacer: {
    [theme.breakpoints.down("md")]: {
      marginTop: "30px",
    },
  },
}));

const Index = ({ isBookBarVisible, condensed }) => {
  const { condensedFooter, footer, spacer, copyright } = useStyles({ isBookBarVisible });

  return condensed ? (
    <Background backgroundColor="#333" height={40} width="100%" boxSizing="border-box" overflow="hidden">
      <Grid container spacing={1} className={condensedFooter}>
        <Grid item xs={12}>
          <small className={copyright}>©2022 by Dimension</small>
        </Grid>
      </Grid>
    </Background>
  ) : (
    <Background backgroundColor="#333" height={405}>
      <Grid container spacing={1} className={footer}>
        <Grid item xs={12} md={3}>
          <Logo />
          <Box>
            <Typography style={{ fontSize: "20px" }}>
              <b>
                Solve your trash problems <br /> at your fingertips.
              </b>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={2} className={spacer}>
          <Services />
        </Grid>
        <Grid item xs={12} md={2} className={spacer}>
          <AboutUs />
        </Grid>
        <Grid item xs={12} md={2} className={spacer}>
          <Provider />
        </Grid>
        <Grid item xs={12} md={3} className={spacer}>
          <Buttons />
        </Grid>
        <small className={copyright}>©2022 by Dimension</small>
      </Grid>
    </Background>
  );
};

export default Index;
