import React from "react";
import { useDispatch } from "react-redux";
import { makeStyles, Typography } from "@material-ui/core";

import deliveryTruck from "../../assets/delivery-truck.svg";
import { confirmSignOut, staySignedIn } from "@store/actions/auth";
import AlertDialog from "./AlertDialog";

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    lineHeight: "2rem",
    textAlign: "center",
    padding: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(8, 2),
    },
  },
}));

const SignOutConfirm = ({ image = deliveryTruck, setBurgered = null }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleStaySignedIn = () => {
    dispatch(staySignedIn());
  };

  const handleSignOut = () => {
    setBurgered?.(false);
    dispatch(confirmSignOut());
  };

  return (
    <AlertDialog
      title={<img className={classes.image} src={image} alt="Delivery Truck" />}
      body={<Typography className={classes.warning}>Are you sure you want to sign out from Dimension?</Typography>}
      agreeBtnText="Stay signed in"
      handleProceed={handleStaySignedIn}
      handleOutsideClick={handleStaySignedIn}
      disagreeBtnTxt="Sign out"
      onClose={handleSignOut}
    />
  );
};

export default SignOutConfirm;
