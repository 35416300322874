import React from "react";
import PropTypes from "prop-types";
import { Box, makeStyles, Modal, Backdrop, Fade } from "@material-ui/core";
import cn from "classnames";

import CarouselComponent from "react-material-ui-carousel";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "rgba(255, 255, 255, 0.6)",
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgb(191, 191, 191, 0.7)",
  },

  paper: {
    [theme.breakpoints.up("md")]: {
      width: ({ size }) =>
        ({
          small: "60%",
          medium: "70%",
          large: "80%",
          xlarge: "100%",
        }[size]),
    },
    width: "100%",
  },

  carouselItem: {
    background: "inherit",
    placeItems: "center",
    [theme.breakpoints.up("md")]: {
      width: "50%",
      height: ({ size }) =>
        ({
          small: 300,
          medium: 400,
          large: 500,
          xlarge: 500,
        }[size]),
    },
    height: 400,
    width: "100%",
    margin: "auto",
  },

  carouselImage: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
  },

  navButton: {
    borderRadius: 4,
    background: "#fff",
    color: "#000",
    boxShadow: theme.shadows[5],
  },

  navButtonWrapper: {
    "&:hover": { "& .MuiIconButton-root": { background: "#fff" } },
  },

  indicators: {
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
    width: "80%",
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(2, "auto"),
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },

  indicator: {
    height: 75,
    width: 75,
    cursor: "pointer",
    margin: theme.spacing(0, 1),
    background: "inherit",
    border: "2px solid transparent",
    filter: "grayscale(100%) opacity(80%)",
  },

  indicatorImg: {
    objectFit: "cover",
    height: "100%",
    width: "100%",
  },

  activeIndicator: {
    border: `2px solid ${theme.palette.primary.main}`,
    filter: "grayscale(0%) opacity(100%)",
  },
}));

const Indicators = ({ images, index, onChange }) => {
  const styles = useStyles();
  const activeIndicator = (key) => index === key;

  return (
    <Box className={styles.indicators}>
      {images.map((image, idx) => (
        <Box key={idx} className={cn(styles.indicator, activeIndicator(idx) && styles.activeIndicator)} onClick={() => onChange(idx)}>
          <img src={image} alt={`Carousel-indicator${idx}`} className={styles.indicatorImg} />
        </Box>
      ))}
    </Box>
  );
};

const Carousel = ({ open, onClose, images, index, onChange, size, download }) => {
  const styles = useStyles({ size });

  const handleBulkDownload = () => {};

  return (
    <Modal
      aria-labelledby="gallery-modal-title"
      aria-describedby="gallery-modal-description"
      className={styles.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 400,
        classes: {
          root: styles.root,
        },
      }}>
      <Fade in={open}>
        <Box className={styles.paper}>
          {download && false && (
            <Box style={{ position: "fixed", top: 10, right: 30, cursor: "pointer", zIndex: 100 }}>
              <CloudDownloadIcon color="primary" fontSize="large" onClick={handleBulkDownload} />
            </Box>
          )}
          <CarouselComponent
            className={styles.carousel}
            onChange={onChange}
            index={index}
            autoPlay={false}
            indicators={false}
            navButtonsAlwaysVisible
            navButtonsProps={{ className: styles.navButton }}
            navButtonsWrapperProps={{ className: styles.navButtonWrapper }}
            NextIcon={<NavigateNextIcon />}
            PrevIcon={<NavigateBeforeIcon />}>
            {images.map((image, idx) => (
              <Box className={styles.carouselItem} key={idx}>
                <img className={styles.carouselImage} src={image} alt={`Carousel-image${idx}`} />
              </Box>
            ))}
          </CarouselComponent>
          <Indicators images={images} index={index} onChange={onChange} />
        </Box>
      </Fade>
    </Modal>
  );
};

Carousel.defaultProps = {
  size: "small",
  onClose: () => {},
  download: false,
};

Carousel.propTypes = {
  onChange: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
  size: PropTypes.oneOf(["small", "medium", "large", "xlarge"]),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default Carousel;
