import * as React from "react";
import dimensionLogo from "../assets/landing_page/dimension-logo.svg";
import classes from "@css/components/website/Footer.module.css";
import { useSelector } from "react-redux";
import { footerSchema } from "../constants/footer";
import { useHistory } from "react-router-dom";
import { KustomerLauncher } from "./KustomerLauncher";
import { Kustomer } from "../helpers/kustomer";

export interface TrustPilotInterface {
  style?: string;
}

const FooterLink = ({ item }: any) => {
  const history = useHistory();
  const onOpenLink = () => {
    if (item.isOutsideLink) {
      window.open(item.link, "_blank");
    } else {
      history.push(item.link);
    }
  };
  if (item.link) {
    return (
      <a className={classes.hrefsColumnItem} onClick={onOpenLink}>
        {item.label}
      </a>
    );
  }
  if (item.isChatLauncher) {
    const handleClick = () =>{
      Kustomer.action(item.KustomerAction.action, item.KustomerAction.value);
    };
    return (
      <KustomerLauncher onClick={item.KustomerAction ? handleClick : undefined}>
        <a className={classes.hrefsColumnItem}>{item.label}</a>
      </KustomerLauncher>
    );
  }
  return <div className={classes.hrefsColumnItem} />;
};

function Footer() {
  const profile = useSelector((state: any) => state.firebase?.profile);
  const userType: any = ["provider", "customer"].includes(profile.userType) ? profile.userType : "default";
  const schema = footerSchema[userType];

  return (
    <section className={classes.container}>
      <div className={classes.mainContent}>
        <div className={classes.logoContainer} style={{ cursor: "unset" }}>
          <img src={dimensionLogo} height="52" alt="Dimension Logo" />
          <span className={classes.hrefsColumnHeader}>Address</span>
          <p className={classes.addressText} style={{ margin: 0, padding: 0 }}>
            2261 Market Street, #4061
            <br />
            San Francisco, CA 94114
          </p>
        </div>
        <div className={classes.hrefsContainer}>
          {schema.map((column: any, index: number) => (
            <div className={classes.hrefsColumn} key={index}>
              <span className={classes.hrefsColumnHeader}>{column.section}</span>
              {column.links?.map((link: any, index: number) => (
                <FooterLink item={link} key={index} />
              ))}
            </div>
          ))}
        </div>
      </div>
      {/* <div className={classes.trustBox}>
        <TrustBox backgroundColor="#0c232d" isDark={false} />
      </div> */}
      <div className={classes.bottomContent}>
        <span style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}>© 2022 Dimension. All rights reserved.</span>
      </div>
    </section>
  );
}

export default Footer;
