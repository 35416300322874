import React, { useEffect, useState } from "react";
import { Button, Divider, Typography } from "@material-ui/core";
import classes from "@css/components/website/BookingWidget.module.css";
import classNames from "classnames";
import AddressField from "../../../../shared/components/AddressField";
import { useDispatch, useSelector } from "react-redux";
import { validateServiceLocation } from "../../../../store/actions/address";
import { AddressFieldErrorCode } from "../../../BookingPage/helpers/enums";
import { segmentTrack } from "../../../../helpers/segment";
import querystring from "query-string";
import history from "../../../../history";
import NumberService from "../../../../shared/services/NumberService";
import { services, vehicles } from "../../../BookingPage/helpers/apiData";
import { getLowestItemPrice } from "../../../BookingPage/helpers/utils";

export interface BookingWidgetProps {
  isBusiness?: boolean;
  paramProps: {
    dynamic?: any;
    dynamicAddress?: any;
    urlAddressField?: any;
    defaultTask?: "junk-removal" | "cardboard-removal" | "dumpster-rental";
  };
}

function BookingWidget({ isBusiness, paramProps }: BookingWidgetProps) {
  const dispatch = useDispatch();
  const profile = useSelector((state: any) => state.firebase.profile);
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState<any>({ location: null, coordinates: null, type: null });
  const [error, setError] = useState<string | null>(null);
  const [taskType, setTaskType] = useState<"junk-removal" | "cardboard-removal" | "dumpster-rental">(paramProps.defaultTask ?? "junk-removal");

  const priceMultiplier = useSelector((state: any) => state.serviceLocations.activeLocation.priceMultipliers ?? 100);
  const lowestPrices = useSelector((state: any) => state.tempData.lowestPrices);

  const minimumVehicle = vehicles["pick-up-truck"];
  const taskPrice = NumberService.formatToCurrency(
    (services[taskType].price + minimumVehicle.price + getLowestItemPrice(taskType, lowestPrices)) * (priceMultiplier / 100)
  );

  const contentClasses = classNames(classes.locationSearch, {
    [classes.locationSearchBusiness]: isBusiness,
  });

  const mainContentClasses = classNames(classes.mainContent, {
    [classes.mainContentBusiness]: isBusiness,
  });

  const checkServiceLocation = async () => await dispatch(validateServiceLocation(address, taskType));

  const setValidationResult = async (result: any) => {
    const validationResult = await result;
    if (!validationResult) {
      setError(AddressFieldErrorCode.AddressNotSupported);
      segmentTrack("Location Not Served", {
        location: address,
        url: location.pathname + location.search,
      });
    } else {
      setError(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (address.coordinates?.lat) {
      const result = checkServiceLocation();
      setValidationResult(result);
    } else {
      setError(null);
    }
  }, [address]);

  useEffect(() => {
    if (!paramProps?.urlAddressField) return;
    setAddress(paramProps?.urlAddressField);
  }, [paramProps?.urlAddressField]);

  const handleBook = () => {
    segmentTrack(`Clicked Book - Pitch`);
    history.push(
      "/booking?".concat(
        querystring.stringify({
          task: taskType,
          business_client: Number(!!isBusiness),
          bid: isBusiness ? "1" : "0",
        })
      )
    );
  };

  const disabled = error !== null || loading;

  return (
    <div className={classes.container}>
      <div className={classes.tabs}>
        <Button
          disableRipple
          onClick={() => setTaskType("junk-removal")}
          className={classNames(classes.tabButton, { [classes.active]: taskType === "junk-removal" })}
        >
          Waste Removal
        </Button>
        <Button
          disableRipple
          onClick={() => setTaskType("cardboard-removal")}
          className={classNames(classes.tabButton, { [classes.active]: taskType === "cardboard-removal" })}
        >
          Cardboard Removal
        </Button>
        <Button
          disableRipple
          onClick={() => setTaskType("dumpster-rental")}
          className={classNames(classes.tabButton, { [classes.active]: taskType === "dumpster-rental" })}
        >
          Dumpster Rental
        </Button>
      </div>
      <div className={mainContentClasses}>
        <div className={contentClasses}>
          <Typography className={classes.mainText}>Check if we serve your area</Typography>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <AddressField
              className={classes.addressField}
              restrict={true}
              types={["(regions)"]}
              label=""
              address={address}
              setAddress={(value: any) => setAddress(value)}
              placeholder="Enter your Zip Code"
            />
            <Typography style={{ color: "#1E3B47", padding: "4px" }} className={classes.smallText}>
              {error}
            </Typography>
          </div>
        </div>
        {!isBusiness && (
          <div className={classes.priceSelector}>
            <Typography className={classes.mainText}>Service starts at</Typography>
            <div className={classes.price}>{taskPrice}</div>
          </div>
        )}
      </div>
      {profile?.userType !== "provider" && (
        <Button onClick={handleBook} disabled={disabled} fullWidth className={classes.primaryButton}>
          {isBusiness ? "Get a Quote" : "Book Now"}
        </Button>
      )}
    </div>
  );
}

export default BookingWidget;
