import ACH_Icon from "../assets/payments/bank_check.png";
import Visa_Icon from "../assets/payments/visa.png";
import Mastercard_Icon from "../assets/payments/mastercard.png";
import Amex_Icon from "../assets/payments/amex.png";
import CreditCard_Icon from "../assets/payments/credit_card.png";

export const Payment = {
  ACH: "ACH",
  Card: "card",
  Check: "check",
  Transfer: "transfer",
  Wallet: "wallet",
};

export const paymentMethodIcons = {
  [Payment.ACH]: ACH_Icon,
  [Payment.Card]: CreditCard_Icon,
  [Payment.Check]: ACH_Icon,
  [Payment.Transfer]: ACH_Icon,
  [Payment.Wallet]: ACH_Icon,
};

export const cardBrands = {
  visa: {
    name: "Visa",
    icon: Visa_Icon,
  },
  mastercard: {
    name: "Mastercard",
    icon: Mastercard_Icon,
  },
  amex: {
    name: "American Express",
    icon: Amex_Icon,
  },
};

export const paymentTriggers = {
  instantly: {
    label: "Pay instantly",
    value: "instantly",
    description: "Instantly charge the customer",
    types: [Payment.Card, Payment.ACH],
  },
  monthly: {
    label: "Monthly",
    value: "monthly",
    description: "Charge the customer monthly",
    types: [Payment.ACH],
  },
};

export const paymentMethods = [
  {
    icon: "receipt",
    label: "Send Invoice",
    value: "ACH",
    type: "ACH",
  },
  {
    icon: "payment",
    label: "Card",
    value: "card",
    type: "card",
  },
];

export const paymentStatuses = {
  unpaid: {
    label: "Not Paid",
    value: "unpaid",
    description: "The client has not paid yet",
    color: "#ff5e8c",
  },
  paid: {
    label: "Fully Paid",
    value: "paid",
    description: "The client has paid for the charged amount",
    color: "#6ccc73",
  },
  partially_paid: {
    label: "Partial Paid",
    value: "partially_paid",
    description: "The client has paid partially",
    color: "#f5dc6c",
  },
  // over_charged: {
  //   label: "Over Charged",
  //   value: "over_charged",
  //   description: "The customer is charged more than the booking total",
  // },
  // under_charged: {
  //   label: "Under Charged",
  //   value: "under_charged",
  //   description: "The client is charged less than the booking total",
  // },
};
