import React, { useState, forwardRef } from "react";
import { updateField } from "@booking/helpers/utils";
import Section from "@booking/components/Section";
import { Input } from "@booking/helpers/enums";
import DumpsterFieldv2 from "@booking/helpers/fields/DumpsterFieldV2";

import useMountedEffect from "@shared/hooks/useMountedEffect";
import useTriggerValidation from "@shared/hooks/useTriggerValidation";
import PrivilegedComponent from "../../../../../shared/components/PrivilegedComponent";
import { BidPrompt } from "../../../components/BidPrompt";

const TaskDescription = forwardRef(({ defaultValue, onChange, pickupForm, setIsBidMode, isDefaultBid }, ref) => {
  const [dumpsterField, setDumpsterField] = useState(defaultValue[Input.Dumpster]);
  const [descriptionField, setDescriptionField] = useState(defaultValue[Input.Description]);
  const [understandPricingField, setunderstandPricingField] = useState(defaultValue[Input.UnderstandPricing]);

  useMountedEffect(() => {
    onChange({
      [Input.Dumpster]: dumpsterField,
      [Input.Description]: descriptionField,
      [Input.UnderstandPricing]: understandPricingField,
    });
  }, [dumpsterField, descriptionField, understandPricingField]);

  useTriggerValidation(ref, [setDumpsterField, setDescriptionField, setunderstandPricingField]);

  const handleDumpsterChange = (field) => {
    setDumpsterField(updateField(dumpsterField, field));
  };

  const handleDescriptionChange = (field) => {
    setDescriptionField(updateField(descriptionField, field));
  };

  const handleUnderstandPricing = (field) => {
    setunderstandPricingField(updateField(understandPricingField, field));
  };

  return (
    <Section title="Dumpster size">
      <DumpsterFieldv2
        pickupForm={pickupForm}
        fields={{ dumpsterField, descriptionField, understandPricingField }}
        handleDescriptionChange={handleDescriptionChange}
        handleUnderstandPricing={handleUnderstandPricing}
        onChange={handleDumpsterChange}
      />
      <br />
      <br />
      <PrivilegedComponent flag={"b2c-bids"} track={true}>
        <BidPrompt isBidMode={false} setIsBidMode={setIsBidMode} message="Not sure which size you need?" isDefaultBid={isDefaultBid} />
      </PrivilegedComponent>
    </Section>
  );
});

export default React.memo(TaskDescription);
