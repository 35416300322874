/* eslint-disable no-console */
import trackerConstants from "@store/constants/trackersTypes";

export const setGCLID = (gclId) => async (dispatch) => {
  try {
    dispatch({ type: trackerConstants.SET_GCLID, value: gclId });
  } catch (error) {
    console.log("gclId tracking error: ", error);
  }
};

export const resetGCLID = () => async (dispatch) => {
  try {
    dispatch({ type: trackerConstants.RESET_GCLID });
  } catch (error) {
    console.log("gclId reset error: ", error);
  }
};
