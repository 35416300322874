import React, { useEffect, useState } from "react";
import { makeStyles, CardMedia, IconButton } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  mobileActiveScroller: {
    overflowX: "hidden",
    scrollBehavior: "smooth",
  },
  fadeIn: {
    animation: "$fadein 0.2s ease-in",
  },
  "@keyframes fadein": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  media: {
    height: 0,
    paddingTop: "70%",
  },
  cover: {
    objectFit: "cover",
  },
  scale: {
    objectFit: "scale-down",
  },
  fadeOut: {
    animation: "$fadeout 0.2s ease-in",
  },
  "@keyframes fadeout": {
    "0%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0,
    },
  },
  mobileFeed: {
    height: 400,
    borderRadius: 4,
    overflow: "hidden",
  },
  arrowBtnLeft: {
    position: "absolute",
    left: 25,
    top: 150,
    background: "#4444444d",
    opacity: 1,
    color: "#fff",
    padding: 25,
  },
  arrowBtnRight: {
    position: "absolute",
    right: 25,
    top: 150,
    background: "#4444444d",
    opacity: 1,
    color: "#fff",
    padding: 25,
  },
}));

function SwipeImage({ image, current, width, index, handleNext, handlePrev }) {
  const classes = useStyles();
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [fade, setFade] = useState(current === index);
  const [fit, setFit] = useState(true);

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    if (touchStart - touchEnd > 20) {
      handleNext();
    } else if (touchStart - touchEnd < -20) {
      handlePrev();
    }
    setTouchStart(0);
    setTouchEnd(0);
  };

  useEffect(() => {
    if (current === index) {
      setFade(false);
    } else {
      setFade(true);
    }
  }, [current]);

  const handleClick = (e) => {
    e.preventDefault();
    setFit(!fit);
  };

  return (
    <div
      onTouchStart={(e) => handleTouchStart(e)}
      onTouchMove={(e) => handleTouchMove(e)}
      onTouchEnd={(e) => handleTouchEnd(e)}
      onClick={(e) => handleClick(e)}
      style={{ display: "inline-block", width: width }}>
      <CardMedia className={`${classes.media} ${fit ? classes.cover : classes.scale}`} image={image || ""} title="Junk Item" />
    </div>
  );
}

export default function ImageSwiper({ images }) {
  const classes = useStyles();
  const [current, setCurrent] = useState(0);
  const [width, setWidth] = useState(400);

  useEffect(() => {
    var cardwidth = document.getElementById("mobile-feed").clientWidth;
    setWidth(cardwidth);
  }, []);

  const handleNext = () => {
    if (current + 1 !== images.length) {
      setCurrent(current + 1);
      document.getElementById("mobile-active-scroller").scrollTo((current + 1) * width, 0);
    }
  };

  const handlePrev = () => {
    if (current - 1 > -1) {
      setCurrent(current - 1);
      document.getElementById("mobile-active-scroller").scrollTo((current - 1) * width, 0);
    }
  };

  return (
    <div id="mobile-feed" className={classes.mobileFeed}>
      <div id="mobile-active-scroller" className={classes.mobileActiveScroller}>
        <IconButton className={classes.arrowBtnLeft} onClick={handlePrev}>
          <ArrowBackIos />
        </IconButton>
        <div id="mobile-active-width" style={{ width: "max-content" }}>
          {images?.map((image, index) => (
            <SwipeImage key={index} image={image} width={width} current={current} index={index} handleNext={handleNext} handlePrev={handlePrev} />
          ))}
        </div>
        <IconButton className={classes.arrowBtnRight} onClick={handleNext}>
          <ArrowForwardIos />
        </IconButton>
      </div>
    </div>
  );
}
