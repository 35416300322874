import React from "react";
import PropTypes from "prop-types";
import shipment_icon from "@assets/shipment_icon.png";
import { Button, Chip, makeStyles, Typography } from "@material-ui/core";
import { taskTypes } from "@constants/booking";
import { useHistory } from "react-router";
import dayjs from "dayjs";
import { bidsStatus } from "../../../constants/bids";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 2, 2),
    borderRadius: "5px",
    margin: theme.spacing(0, 0),
  },
  taskType: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "15px",
    },
  },
  grayText: {
    color: "grey",
  },
  greenText: {
    color: theme.palette.primary.main,
    fontSize: "clamp(0.8rem, 0.6rem + 0.33vw, 1rem)",
    fontWeight: "bold",
    textTransform: "capitalize",
  },
  status: {
    paddingRight: theme.spacing(2),
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "15px 0px",
      width: "100%",
    },
  },
  dateTimeText: {
    fontSize: "clamp(0.8rem, 0.6rem + 0.33vw, 1rem)",
    fontWeight: "bold",
    color: "black",
  },
  doBtn: {
    height: "40px",
    cursor: "pointer",
    fontSize: "clamp(0.8rem, 0.6rem + 0.33vw, 1rem)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  table: {
    width: "100%",
    borderCollapse: "separate",
    borderSpacing: "0 1rem",
  },
  tableHeader: {
    "& th": {
      color: "grey",
      fontSize: "clamp(0.9rem, 0.7rem + 0.3333vw, 1.1rem)",
      fontWeight: "bold",
      padding: theme.spacing(0, 1),
    },
  },
  tableRow: {
    backgroundColor: "#fff",
    borderRadius: "7px",
    boxShadow: "rgba(50, 50, 93, 0.10) 0px 6px 12px -2px, rgba(0, 0, 0, 0.10) 0px 3px 7px -3px",
    "& td": {
      borderTop: "1px solid #e8e8e8",
      borderBottom: "1px solid #e8e8e8",
      padding: theme.spacing(2, 1),
    },
    "& td:first-child": {
      borderRadius: "7px 0 0 7px",
      borderLeft: "1px solid #e8e8e8",
    },
    "& td:last-child": {
      borderRadius: "0 7px 7px 0",
      borderRight: "1px solid #e8e8e8",
    },
  },
  extraSmallColumn: {
    textAlign: "center",
  },
  smallColumn: {
    textAlign: "left",
  },
  mediumColumn: { textAlign: "left" },
  textCenter: { textAlign: "center" },
  addressCell: {
    color: "grey",
    textAlign: "left",
    fontSize: "clamp(0.8rem, 0.6rem + 0.33vw, 1rem)",
  },
  taskTypeCell: {
    display: "flex",
    alignItems: "start",
    textAlign: "left",
    marginLeft: theme.spacing(3),
    "& img": {
      marginTop: theme.spacing(0.3),
    },
    "& div:last-child": {
      marginLeft: theme.spacing(2),
      flexGrow: "1",
    },
  },
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  center: {
    textAlign: "center",
    justifyContent: "center",
  },
}));

const TaskTypeCell = ({ task, orderNumber }) => {
  const classes = useStyles();
  return (
    <div className={classes.taskTypeCell}>
      <img src={shipment_icon} alt="shipmentIcon" height="20px" width="20px" />
      <div className={classes.taskType}>
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          {taskTypes[task]?.label ?? ""}
        </Typography>
        <Typography variant="body1" classes={{ body1: classes.greenText }}>
          {orderNumber}
        </Typography>
      </div>
    </div>
  );
};

function BidsListTable({ taskListItems, activeData }) {
  const classes = useStyles();
  const history = useHistory();

  const handleBidDetails = (bidId) => {
    history.push(`/bid_details/${bidId}`);
  };

  return (
    <div className={classes.container}>
      <table className={classes.table}>
        <thead className={classes.tableHeader}>
          <tr>
            <th style={{ width: 180, textAlign: "left" }}>Task</th>
            <th style={{ width: 140, textAlign: "left" }}>Location</th>
            <th style={{ width: "auto", textAlign: "left" }}>Description</th>
            <th style={{ width: 80, textAlign: "left" }}>Bid</th>
            {activeData === bidsStatus.pending.value && (
              <th style={{ width: 120, textAlign: "left" }}>Time Left</th>
            )}
            <th style={{ width: 120 }}></th>
          </tr>
        </thead>
        <tbody>
          {taskListItems.map((bid) => (
            <tr key={bid.id} className={classes.tableRow} onClick={() => handleBidDetails(bid.bookingBidId)}>
              <td className={classes.left}>
                <TaskTypeCell task={bid.bidData?.task ?? "junk-removal"} orderNumber={bid.bookingBidNumber} />
              </td>
              <td className={classes.left}>
                <Typography className={classes.grayText}>{bid.bidData?.city}</Typography>
              </td>
              <td className={classes.left}>
                <Typography className={classes.grayText}>{bid.bidData?.description}</Typography>
              </td>
              <td className={classes.left}>
                <Typography className={classes.greenText}>${(bid.price / 100).toFixed(0)}</Typography>
              </td>
              {activeData === bidsStatus.pending.value && (
                <td className={classes.left}>
                  <Typography className={classes.grayText}>{`${dayjs(bid.bidData?.expiresAt).diff(dayjs(), "h")} hrs left`}</Typography>
                </td>
              )}
              <td>
                <Button variant="outlined" color="primary" className={classes.doBtn} onClick={() => handleBidDetails(bid.bookingBidId)}>
                  View Bid
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

BidsListTable.propTypes = {
  taskListItems: PropTypes.arrayOf(PropTypes.object),
  isUpcoming: PropTypes.bool,
};

export default BidsListTable;
