import React, { useEffect, useState } from "react";
import { Box, makeStyles, Popper, Typography, useMediaQuery } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { segmentTrack } from "../../../helpers/segment";
import { checkFeatureFlag } from "../../../store/actions/featureFlags";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  priceMatchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(255, 153, 0, 0.2)",
    borderRadius: "8px",
    padding: theme.spacing(1, 2),
    margin: theme.spacing(2, 0),
  },
  title: {
    fontWeight: "900",
    fontSize: "14px",
    color: "#DC8C13",
  },
  icon: {
    paddingLeft: "5px",
    fontWeight: "900",
    fontSize: "16px",
    color: "#DC8C13",
  },
  popperContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "22px",
    marginBottom: theme.spacing(3),
    background: "#FFFFFF",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
    color: "#4F4F4F",
    width: "400px",
    padding: theme.spacing(4),
  },
}));

const PriceMatchTag = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const pricingZone = useSelector((state) => state.serviceLocations?.activeLocation?.pricingZone ?? null);
  const [showPriceMatchTag, setShowPriceMatchTag] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    if (!isMobile) {
      setAnchorEl(event.currentTarget);
    }
    setOpen((previousOpen) => !previousOpen);
    segmentTrack("Price Tag Match Clicked");
  };

  /* Feature flag price-match-popup for showing the price quote match widget */
  const checkPriceMatchPopup = async () => {
    const data = {
      key: pricingZone,
      custom: {
        market: pricingZone,
      },
    };
    const isValid = await checkFeatureFlag("price-match-popup", data, false);
    if (isValid) {
      setShowPriceMatchTag(true);
    }
  };

  useEffect(() => {
    if (!pricingZone) return;
    checkPriceMatchPopup();
  }, [pricingZone]);

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  return (
    <div>
      {showPriceMatchTag && (
        <div className={classes.priceMatchContainer}>
          <Typography className={classes.title}>Don&apos;t shop around. We price match!</Typography>
          <InfoOutlined aria-describedby={id} className={classes.icon} onClick={handleClick} />
        </div>
      )}
      {!isMobile && (
        <Popper id={id} open={open} anchorEl={anchorEl} transition placement="top-end" className={classes.popperContainer}>
          <Box>
            Check out now worry-free. If you find a qualified provider that quotes a cheaper price after you reserve a Trash Warrior, we will price
            match to that provider and refund the difference back to you.
            <br />
            <br />
            If you need a price match after you complete the checkout, simply send us a screenshot of the provider&apos;s price quote to
            support@getdimension.com. Qualified provider = any waste business with at least 5 reviews on Google or Yelp.
          </Box>
        </Popper>
      )}
    </div>
  );
};

export default PriceMatchTag;
