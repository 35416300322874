/* eslint-disable no-console */
import invoicesConstants from "@store/constants/invoiceTypes";
import configConstants from "@store/constants/configTypes";
import { toastr } from "react-redux-toastr";
import { useDispatch } from "react-redux";
import useFirestoreListener from "@shared/hooks/useFirestoreListener";
import { useAxiosPost, useAxiosGet } from "../../axios";

export const addInvoicesState = (invoice) => (dispatch) => {
  dispatch({ type: invoicesConstants.ADD_INVOICE, payload: invoice });
};

export const updateInvoicesState = (invoice) => (dispatch) => {
  dispatch({ type: invoicesConstants.UPDATE_INVOICE, payload: invoice });
};

export const fetchBookingInvoices = async (bookingId) => {
  try {
    const response = await useAxiosGet(`/invoices/get-booking-invoices/${bookingId}`);
    return response?.data || [];
  } catch (error) {
    console.log("Error fetching booking invoices: ", error);
  }
};

export const fetchInvoices = () => async (dispatch) => {
  try {
    dispatch({ type: invoicesConstants.SET_INVOICES_LOADING });
    const response = await useAxiosGet("/invoices/get-invoices");
    dispatch({ type: invoicesConstants.FETCH_INVOICES, payload: response.data });
  } catch (error) {
    console.log("Error fetching invoices: ", error);
  } finally {
    dispatch({ type: invoicesConstants.RESET_INVOICES_LOADING });
  }
};

export const followUpInvoice = (invoiceId, invoice) => async (dispatch) => {
  try {
    const response = await useAxiosPost(`/invoices/follow-up/${invoiceId}`, invoice);
    if (response.data.success) {
      toastr.success("Success", "Invoice follow up processed");
    } else {
      throw "Something went wrong";
    }
  } catch (error) {
    console.log("Error updating invoice: ", error);
  }
};

export const triggerStripeDue = () => async (dispatch) => {
  try {
    const response = await useAxiosPost("/invoices/trigger-stripe-due");
    if (response.data.success) {
      toastr.success("Success", response.data.message ?? "Stripe due invoice triggered");
    } else {
      toastr.error("Error", response.data.message ?? "Something went wrong");
    }
  } catch (error) {
    console.log("Error triggering stripe due: ", error);
  } finally {
    dispatch({ type: configConstants.HIDE_STRIPE_DUE_TRIGGER });
  }
};

// hooks

export const useSubscribeToInvoices = () => {
  const dispatch = useDispatch();
  useFirestoreListener("invoices", {
    onAdd: (newDoc) => {
      dispatch(addInvoicesState(newDoc));
    },
    onUpdate: (updatedDoc) => {
      dispatch(updateInvoicesState(updatedDoc));
    },
  });
};
