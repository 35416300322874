import dayjs from "dayjs";
import { taskTypes, bookingStatus } from "@constants/booking";
import { vehicleTypes } from "@constants/vehicleInfo";
import { dumpSiteTypes } from "../../../../constants/dumpSites";
import { paymentTriggers } from "../../../../constants/payments";
import { bookingQuoteStatus } from "../../../../constants/booking";

const getFullName = (firstName, lastName = " ") => firstName?.trim() + " " + lastName?.trim();

export const formatESalesBookings = (bookings) => {
  const formatted = [];
  bookings?.map((b) => {
    const keys = Object.keys(b);
    const formatter = (value) => {
      if (!value) return undefined;
      if (value === "true") return true;
      if (value === "false") return false;
      if (Number(value) && b !== "customer_phone") return Number(value);
      return value;
    };
    const booking = {};
    keys.map((key) => (booking[key] = formatter(b[key]?.raw)));
    formatted.push(booking);
  });
  return formatted;
};

export const formatEBookings = (bookings) =>
  bookings?.map((b) => ({
    id: b.id?.raw,
    orderNumber: b.order_number?.raw,
    pseudoId: b.order_number?.raw ?? b?.id?.raw, // actual backend id
    fullName: getFullName(b.customer_first_name?.raw, b.customer_last_name?.raw),
    customer_id: b.customer_id?.raw,
    warrior_id: b.warrior_id?.raw,
    email: b.customer_email?.raw,
    phone: b.customer_phone?.raw,
    description: b.description?.raw,
    lat: b.lat?.raw,
    lng: b.lng?.raw,
    county: b.county?.raw,
    city: b.city?.raw,
    date: b.date_appointment?.raw,
    task: b.task?.raw,
    stripeCustomerId: b.stripe_customer_id?.raw,
    warriorPayoutStatus: b.warrior_payout_status?.raw,
    clientPaid: b.paid?.raw === "true",
    clientPaidDate: b.date_paid?.raw,
    status: b.status?.raw,
    pricingZone: b.pricing_zone?.raw,
    warriorName: b.warrior_name?.raw,
    createdAt: b.created?.raw,
    assignedDumpsiteId: b.assigned_dumpsite?.raw,
    total: parseInt(b.price_total?.raw ?? 0),
    payout: parseInt(b.price_payout?.raw ?? 0),
    closedBy: b.closed_by?.raw,
    frequency: b.frequency?.raw,
    recurringId: b.recurring_id?.raw,
    location: b.location?.raw,
    mattressBooking: b.is_tuft_and_needle_booking?.raw === "true",
    companyName: b.company_name?.raw,
    payment_method: b.payment_method?.raw,
    payment_id: b.stripe_payment_id?.raw,
    payment_invoices: b.invoice?.raw ?? b.invoices?.raw ?? [],
    hasConnectedPayment: b.invoices?.raw?.length > 0 || !!b.invoice?.raw || !!b.stripe_payment_id?.raw,
    amount_charged: parseInt(b.amount_charged?.raw ?? 0),
    amount_collected: parseInt(b.amount_collected?.raw ?? 0),
    amount_refunded: parseInt(b.amount_refunded?.raw),
    isMonthlyInvoiced: b.payment_frequency?.raw === paymentTriggers.monthly.value,
    paymentState: b.payment_state?.raw,
    chargedState: b.charged_state?.raw,
    refundState: b.refund_state?.raw,
  })) || [];

export const formatBookings = (bookings) =>
  bookings?.map((b) => ({
    id: b.id,
    orderNumber: b.orderNumber,
    pseudoId: b.orderNumber ?? b.id, // actual backend id
    fullName: getFullName(b.first_name, b.last_name),
    customer_id: b.customer_id,
    warrior_id: b.warrior_id,
    email: b.email,
    description: b.description,
    lat: b.address.lat,
    lng: b.address.lng,
    county: b.address.county,
    city: b.address.city,
    date: b.date,
    task: b.task,
    stripeCustomerId: b.stripeCustomerId,
    warriorPayoutStatus: b.warriorPayoutStatus,
    clientPaid: b.paid,
    status: b.status,
    pricingZone: b.pricingZone,
    warriorName: b.warriorName,
    createdAt: b.createdAt,
    assignedDumpsiteId: b.assignedDumpsiteId,
    total: b.total,
    location: b.address.location,
    payment_method: b.payment_method,
    payment_id: b.stripePaymentId,
    payment_invoices: b.invoice ?? b.invoices ?? [],
    hasConnectedPayment: b.invoices?.length > 0 || !!b.invoice || !!b.stripePaymentId,
    amount_charged: b.amount_charged ?? 0,
    amount_collected: b.amount_collected ?? 0,
    amount_refunded: b.amount_refunded ?? 0,
    isMonthlyInvoiced: b.paymentFrequency === paymentTriggers.monthly.value,
    paymentState: b.paymentState,
    chargedState: b.chargedState,
    refundState: b.refundState,
  })) || [];

export const formatEUsers = (users) =>
  users?.map((u) => ({
    id: u.id?.raw,
    firstName: u.firstname?.raw,
    lastName: u.lastname?.raw,
    fullName: u.fullname?.raw,
    company: u.company?.raw,
    companyId: u.company_id?.raw,
    email: u.email?.raw,
    city: u.city?.raw,
    phoneNumber: u.phone?.raw,
    tasksCount: u.taskcount?.raw,
    fiveStars: u.fivestars?.raw,
    penaltyPoints: u.penalty?.raw,
    isBanned: u.is_banned?.raw === "true",
    stripeUserId: u.stripeid?.raw,
    userType: u.usertype?.raw,
    lastJobDate: u.lastjobdate?.raw,
    gclId: u.gclid?.raw,
    backgroundClearanceDate: u.background_clearance_date?.raw,
    bookingCount: u.booking_count?.raw,
    createdAt: u.created?.raw,
    approvalState: u.approval_state?.raw,
    stripeConnected: u.stripe_connected?.raw && u.stripe_connected?.raw === "true",
    isMonthlyInvoiced: u.payment_frequency?.raw === paymentTriggers.monthly.value,
    tier: u.tier?.raw,
  })) || [];

export const formatUsers = (users) =>
  users?.map((u) => ({
    id: u.id,
    firstName: u.firstName,
    lastName: u.lastName,
    fullName: u.fullName,
    company: u.companyName,
    companyId: u.businessId,
    email: u.email,
    phoneNumber: u.phoneNumber,
    tasksCount: u.tasksCount,
    fiveStars: u.fiveStars,
    penaltyPoints: u.penaltyPoints,
    isBanned: u.isBanned,
    stripeUserId: u.stripeUserId,
    userType: u.userType,
    lastJobDate: u.lastJobDate,
    gclId: u.gclid,
    backgroundClearanceDate: u.backgroundClearanceDate,
    bookingCount: u.bookingCount,
    approvalState: u.approvalState,
    driversLicense: u.driversLicense,
    createdAt: u.createdAt,
    isMonthlyInvoiced: u.paymentFrequency === paymentTriggers.monthly.value,
    tier: u.tier ?? "tier4",
  })) || [];

export const formatWarriors = (warriors) =>
  warriors?.map((warrior) => ({
    id: warrior.uid,
    bubbleId: warrior.bubbleId,
    email: warrior.email,
    fullName: warrior.fullName,
    selectedVehicles: warrior.vehicleInfo?.selectedVehicles?.map((v) => vehicleTypes[v.value]?.label),
    isHelperAvailable: !!warrior.vehicleInfo?.isHelperAvailable,
    services: warrior.savedPreferences?.services?.map((s) => taskTypes[s]?.label),
    isActive: !warrior.isBanned && warrior.lastCompletedJobDate && dayjs(warrior.lastCompletedJobDate).isAfter(dayjs().subtract(1, "month")),
    stripeId: warrior?.stripeCustomerId,
    data: warrior,
  })) || [];

export const formatEBusinessAccounts = (list) =>
  list?.map((u) => ({
    id: u.id?.raw,
    name: u.name?.raw,
    email: u.email?.raw,
    created_at: u.created_at?.raw,
    closed_by: u.closed_by?.raw,
  })) || [];

export const formatQuotes = (quotes) =>
  quotes.map((quote) => ({
    id: quote.id,
    fullName: quote.customerName,
    email: quote.customerEmail,
    description: quote.jobDescription,
    serviceType: taskTypes[quote.serviceType]?.label,
    phone: quote.phone,
    date: quote.date,
    data: quote,
    price: quote.total,
    quoteNumber: quote.quoteNumber,
    closedBy: quote.closedBy,
  }));

export const formatBids = (bids) =>
  bids.map((bid) => ({
    id: bid.id,
    bidNumber: bid.bidNumber,
    bidCount: bid.metaBids,
    bidLengthValue: bid.bidLengthValue,
    bidLengthType: bid.bidLengthType,
    status: bid.status,
    fullName: bid.fullName,
    email: bid.email,
    phone_number: bid.phone_number,
    companyName: bid.businessClientDetails?.name ?? bid.companyName,
    closedBy: bid.businessClientDetails?.closedBy,
    companyId: bid.businessClientDetails?.id,
    businessClient: bid.businessClient,
    warriorPublicLink: bid.bookingBidWarriorUrl,
    createdAt: bid.createdAt,
    customer_id: bid.customer_id,
    expiresAt: bid.expiresAt,
    serviceType: bid.task,
    date: bid.date,
    timeQuoteCreated: bid.createdAt,
    bookingId: bid.bookingId,
    bookingQuoteId: bid.bookingQuoteId,
    meta: bid.meta,
    pricingZone: bid.pricingZone,
    isInbound: bid.isInbound,
    isAutoSelectOn: bid.isAutoSelectOn,
  }));

export const formatRecurringBooking = (bookings) =>
  bookings?.map((item) => ({
    id: item.id,
    bubbleId: item.bubbleId,
    fullName: getFullName(item.first_name, item.last_name),
    customer_id: item.customer_id,
    warrior_id: item.warrior_id,
    warriorName: item.warriorName,
    email: item.email,
    address: item.address,
    serviceType: taskTypes[item.task].label,
    stripeId: item.stripeCustomerId,
    paid: item.total / 100,
    nextDate: dayjs(item.date).tz(item.timeZone).format("MMM DD, YYYY hh:mm A"),
    status: bookingStatus.cancelled.value === bookingStatus[item.status]?.value ? bookingStatus[item.status].label : "Active",
    closedBy: item?.businessClientDetails?.closedBy ?? "-",
    cancelledBy: item.cancelledBy || "N/A",
    data: item,
    companyName: item.companyName,
    includeInvoice: item.includeInvoice ?? false,
    frequency: item?.frequency,
    frequencyPattern: item?.frequencyPattern ?? [],
  })) || [];

export const formatLocations = (locations) =>
  locations?.map((loc) => ({
    fullName: loc.name,
    id: loc.uid,
    data: loc,
  })) || [];

export const formatInvoices = (invoices) =>
  invoices?.map((invoice) => ({
    id: invoice.id,
    pseudoId: invoice.order_number,
    email: invoice.customer_email,
    paid: invoice.paid,
    total: invoice.total,
    due_date: dayjs(invoice.due_date),
    status: invoice.status,
    data: invoice,
  }));

export const formatDumpsites = (dumpsites) =>
  dumpsites.map((dumpsite) => ({
    fullName: dumpsite?.name || dumpsite?.googleName || "",
    address: { location: dumpsite.googleFormattedAddress, city: dumpsite.city },
    serviceType: dumpSiteTypes?.[dumpsite.type]?.label ?? "",
    serviceLocation: dumpsite?.serviceLocation ?? null,
    data: dumpsite,
  }));

export const formatBookingQuotes = (bookingQuotes) =>
  bookingQuotes.map((booking) => ({
    id: booking.id,
    fullName: getFullName(booking.first_name, booking.last_name),
    email: booking.email,
    description: booking.description,
    address: booking.address,
    dueDate: dayjs(booking.date).tz(booking.timeZone),
    serviceType: taskTypes[booking.task]?.value,
    status: bookingQuoteStatus[booking.status]?.value,
    pricingLocation: booking.pricingZone,
    data: booking,
    quoteNumber: booking.quoteNumber,
    bookingNumber: booking.bookingNumber,
    closedBy: booking.closedBy,
    customer_id: booking.customer_id,
    createdAt: booking.createdAt,
    isInbound: booking.isInbound,
  }));

export const formatBusinessAccounts = (businessAccounts) =>
  businessAccounts.map((businessAccount) => ({
    id: businessAccount.id,
    name: businessAccount.name,
    email: businessAccount.ownerEmail,
    closedBy: businessAccount.closedBy,
    data: businessAccount,
  }));
