import React from "react";
import classes from "@css/components/website/CompanyMission.module.css";
import vision from "../../../assets/landing_page/vision.jpg";
import mission from "../../../assets/landing_page/mission.jpg";
import FeatureScroller from "../Common/FeatureScroller";

const features = [
  {
    title: "Our Mission",
    subTitle: "Dimension brings the waste management industry into the modern world.",
    descriptions: [
      "No more chasing providers, dealing with everything manually or juggling half a dozen platforms.",
      "We combine the flexibility and customization of our independent contractors with accountability, scalability and unmatched waste diversion data enabled. All managed through our accessible online platform, giving our customers a consistent service they can rely on.",
    ],
    imageUrl: mission,
  },
  {
    title: "Our Vision",
    subTitle: "We want to create a world where managing complex waste streams is as simple as clicking a button.",
    descriptions: [
      "Dimension is here to be a champion for our customers, using digital tools to remove the hassle that comes from sourcing removal agents, managing pickups and ensuring compliance.",
      "We think it’s vital that we give our users the tools they need to create a clean, sustainable world. We’re committed to offering detailed, extremely transparent diversion data, empower businesses to be sustainable without increasing their already busy workload.",
    ],
    imageUrl: vision,
  },
];

function CompanyMission() {
  return (
    <section className={classes.container}>
      <FeatureScroller features={features} />
    </section>
  );
}

export default CompanyMission;
