import { useState, useEffect, useRef } from "react";

function useStateWithCallback(initialState) {
  const [state, setState] = useState(initialState);

  const callbackRef = useRef(null);

  useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current(state);
      callbackRef.current = null;
    }
  }, [state]);

  const setCallbackState = (value, callback) => {
    callbackRef.current = typeof callback === "function" ? callback : null;
    setState(value);
  };

  return [state, setCallbackState];
}

export default useStateWithCallback;
