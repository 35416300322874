import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  style: {
    color: "red",
    borderColor: "red",
    width: "100%",
  },
}));

const WithdrawButton = ({ onClick }) => {
  const classes = useStyles();
  const booking = useSelector((state) => state.booking.selectedBooking);
  const user = useSelector((state) => state.firebase.auth);

  const [isAssignedToMe] = useState(booking?.warrior_id === user?.uid);

  if (!isAssignedToMe) return null;

  return (
    <Button onClick={onClick} variant="outlined" className={classes.style}>
      {" "}
      Withdraw{" "}
    </Button>
  );
};

WithdrawButton.defaultProps = {
  onClick: () => {},
};

export default WithdrawButton;
