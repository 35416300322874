/* eslint-disable no-console */
import React, { useState, useEffect, useRef } from "react";
import { GoogleMap, Marker, DirectionsRenderer, DirectionsService } from "@react-google-maps/api";
import { Button, Card, CardContent, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import { haversineDistance } from "../../utils/haversine";
import { openCoordinatesOnMap } from "../../../helpers/maps";
import dropoffAddress from "@assets/booking_details/dropoff-address.svg";

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  card: {
    position: "absolute",
    borderRadius: "5px",
    textAlign: "center",
    maxHeight: theme.spacing(10),
    bottom: theme.spacing(2),
    left: theme.spacing(20),
    right: theme.spacing(20),
    [theme.breakpoints.down("sm")]: {
      left: theme.spacing(1),
      right: theme.spacing(1),
    },
  },
  cardDistance: {
    position: "absolute",
    borderRadius: "5px",
    textAlign: "center",
    maxHeight: theme.spacing(10),
    top: theme.spacing(2),
    left: theme.spacing(20),
    right: theme.spacing(20),
  },
  cardContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.primary.main,
    padding: theme.spacing(2),
    "& svg": {
      marginRight: theme.spacing(1),
    },
    "&:last-child": {
      paddingBottom: theme.spacing(2),
    },
  },
  destinationDistance: {
    display: "flex",
    marginTop: theme.spacing(3),
  },
  dropoffText: {
    margin: theme.spacing(0, 3),
  },
  distanceText: {
    color: theme.palette.primary.main,
  },
  openMapsContainer: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  openMapsBtn: {
    display: "block",
    marginLeft: "auto",
  },
}));
const GoogleMaps = ({ markers, route, showCard, hideOpenButton }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  const directionsCalled = useRef(false);
  const [directions, setDirections] = useState(null);

  const directionServiceCallback = async () => {
    const directionService = new google.maps.DirectionsService();
    directionService.route(
      {
        origin: route.origin,
        destination: route.destination,
        travelMode: "DRIVING",
      },
      (response, status) => {
        if (status === "OK") {
          setDirections(response);
          directionsCalled.current = true;
        } else {
          // eslint-disable-next-line no-console
          console.error(`error fetching directions ${response}`);
        }
      }
    );
  };

  const mapRef = useRef(null);
  const handleOnLoad = async (map) => {
    mapRef.current = map;
    if (route) {
      if (directionsCalled.current) return;
      await directionServiceCallback();
    } else {
      const bounds = new google.maps.LatLngBounds();
      const mapMarkers = markers.map((marker) => new google.maps.Marker({ position: marker, map }));

      // Create bounds from markers
      for (const index in mapMarkers) {
        const latlng = mapMarkers[index].getPosition();
        bounds.extend(latlng);
      }

      // Don't zoom in too far on only one marker
      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        const extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
        const extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01);
        bounds.extend(extendPoint1);
        bounds.extend(extendPoint2);
      }
      map.fitBounds(bounds);
    }
  };

  // Add Debouncer
  const reload = async () => {
    if (!mapRef.current || route) return;
    await handleOnLoad(mapRef.current);
  };

  // Open in maps
  const handleOpenMap = (e) => {
    e.preventDefault();
    openCoordinatesOnMap(markers[0]);
  };

  useEffect(() => {
    reload();
  }, [markers]);

  const distance = route ? haversineDistance(route?.origin, route?.destination).toFixed(1) : null;

  return (
    <div className={classes.mapContainer}>
      <GoogleMap onLoad={handleOnLoad} mapContainerStyle={{ width: "100%", height: "100%", borderRadius: "10px" }}>
        {directions && <DirectionsRenderer directions={directions} />}
        {!route && markers.map((position, index) => <Marker key={index} position={position} />)}
      </GoogleMap>
      {showCard && (
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <RoomOutlinedIcon classes={classes.icon} />
            <Typography>Exact location will be given 2 hours before</Typography>
          </CardContent>
        </Card>
      )}
      {distance && (
        <div className={classes.destinationDistance}>
          <img width={21} src={dropoffAddress} />
          <Typography className={classes.dropoffText}>{`Drop off address`}</Typography>
          <Typography className={classes.distanceText}>{`${distance} miles away`}</Typography>
        </div>
      )}
      {isMobile && !hideOpenButton && (
        <div className={classes.openMapsContainer}>
          <Button className={classes.openMapsBtn} onClick={handleOpenMap} disabled={!markers[0]} variant="contained" color="primary">
            Open Map
          </Button>
        </div>
      )}
    </div>
  );
};

GoogleMaps.defaultProps = {
  markers: [
    { lat: 37.309739, lng: -121.874312 },
    { lat: 37.310612, lng: -121.868927 },
  ],
};

export default React.memo(GoogleMaps);
