import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import cn from "classnames";
import { KustomerLauncher } from "./KustomerLauncher";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    color: theme.palette.primary.main,
    fontWeight: 700,
    "& .link": {
      textDecoration: "underline",
      color: theme.palette.primary.main,
      fontWeight: 700,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "24px",
    },
  },
}));

const ChatLink = ({ className, children, ...props }) => {
  const classes = useStyles();
  return (
    <Typography className={cn(classes.root, className)} {...props}>
      {children}
      <span className="link">
        <KustomerLauncher color="inherit">here.</KustomerLauncher>
      </span>
    </Typography>
  );
};

ChatLink.defaultProps = {
  children: "Have questions? Chat with us ",
};

export default ChatLink;
