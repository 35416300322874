import React from "react";
import { Button, Grid, Icon, Typography } from "@material-ui/core";

type BidConfirmationProps = {
  classes: any;
  bidInfo: any;
  handleDone: any;
};

function BidConfirmation({ classes, bidInfo, handleDone }: BidConfirmationProps) {
  if (!bidInfo) return <div />;

  return (
    <Grid container className={classes.root}>
      <Grid item sm={12} xs={12} md={1} className={classes.iconColumn}>
        <Icon className={`fas fa-check-circle ${classes.icon}`} color="primary" />
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={classes.detailsColumn}>
        <Typography variant="h5" className={classes.h5Text}>
          Your reference number is <b>{bidInfo.bidNumber}</b>.
        </Typography>
        <Typography variant="body1" className={classes.subHeader}>
          Our team will email you a link to complete your booking with the best price in 24 hours.
        </Typography>
        <br />
        <br />
        <Button color="primary" variant="contained" onClick={handleDone}>
          Create another
        </Button>
      </Grid>
      <Grid item md={5} className={classes.logoColumn}>
        <div className={classes.logoColumnLogo} />
      </Grid>
    </Grid>
  );
}

export default BidConfirmation;
