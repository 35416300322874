import { Typography } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import React, { ReactElement, useEffect } from "react";
import { segmentTrack } from "../../../helpers/segment";
import { scrollToTop } from "../../../methods/scrollToTop";
import { useStyles } from "../../../pages/utils/wordpressClasses";

interface WrapperProps {
  children: ReactElement | Array<ReactElement> | null;
  title: string | null;
}

export const Wrapper = ({ title, children }: WrapperProps): ReactElement => {
  const classes: ClassNameMap<any> = useStyles();
  scrollToTop(title);
  useEffect(() => {
    segmentTrack("Wordpress Page Visited", { page: title });
  }, []);
  return (
    <div>
      <div className={classes.wrapper}>
        <div id="wordpress-post" />
        <div className={classes.root} style={{ minHeight: "calc(100vh - 64px)", position: "relative" }}>
          <div style={{ padding: "0px 0px 64px", zIndex: 1, position: "relative" }}>
            <Typography variant="h1">{title}</Typography>
            <div style={{ height: 32 }} />
            {children && children}
          </div>
        </div>
      </div>
    </div>
  );
};
