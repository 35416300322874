import React from "react";
import classes from "@css/components/website/Testimonials.module.css";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

const StarContainer = ({ count }: { count: number }) => {
  const starArray = Array.from(Array(count).keys());
  return (
    <div style={{ display: "flex" }}>
      {starArray.map((item) => (
        <svg key={item} style={{ marginLeft: "4px" }} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14 0L17.8936 9.49159L28 10.3131L20.3 17.0007L22.6525 27L14 21.6416L5.34752 27L7.7 17.0007L0 10.3131L10.1064 9.49159L14 0Z"
            fill="#DBC6AA"
          />
        </svg>
      ))}
    </div>
  );
};

const Testimonial = ({ comment, name }: { comment: string, name: string }) => (
  <div className={classes.quote}>
    <StarContainer count={5} />
    <Typography className={classes.contentText}>
      {`${comment.substring(0, 100)} ${comment.length > 100 ? "..." : ""}`}
    </Typography>
    <Typography className={classes.contentText}>
      <strong>{name}</strong>
    </Typography>
  </div>
);

function Testimonials() {
  const testimonials = useSelector((state: any) => state.aggregates?.reviews ?? []);
  return (
    <section className={classes.container}>
      <div className={classes.mainContent}>
        {testimonials.map((testimonial: any, index: number) => (
          <Testimonial comment={testimonial.comment} name={testimonial.name} key={index} />
        ))}
      </div>
    </section>
  );
}

export default Testimonials;
