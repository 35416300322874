/* eslint-disable no-console */
import couponTypes from "@store/constants/couponTypes";
import { toastr } from "react-redux-toastr";
import { useDispatch } from "react-redux";
import useFirestoreListener from "@shared/hooks/useFirestoreListener";
import { useAxiosPost, useAxiosGet } from "../../axios";

export const addCouponState = (coupon) => (dispatch) => {
  dispatch({ type: couponTypes.ADD_COUPON, payload: coupon });
};

export const updateCouponState = (coupon) => (dispatch) => {
  dispatch({ type: couponTypes.UPDATE_COUPON, payload: coupon });
};

export const deleteCouponState = (coupon) => (dispatch) => {
  dispatch({ type: couponTypes.DELETE_COUPON, payload: coupon });
};

export const createCoupon = (form) => async (dispatch, getState) => {
  try {
    form.createdBy = getState().firebase.profile.uid;
    const response = await useAxiosPost("/coupon/create", form);
    dispatch({
      type: couponTypes.CREATE_COUPON,
      payload: response.data,
    });
    toastr.success("Done", "New Coupon Code created");
  } catch (error) {
    dispatch({
      type: couponTypes.CREATE_COUPON_ERROR,
      payload: error.response.data,
    });
  }
};

export const fetchCoupons = () => async (dispatch) => {
  try {
    dispatch({ type: couponTypes.SET_COUPONS_LOADING });
    const response = await useAxiosGet("/coupon/all");
    dispatch({ type: couponTypes.FETCH_COUPONS, payload: response.data });
  } catch (error) {
    console.log(error);
    dispatch({
      type: couponTypes.FETCH_COUPONS_ERROR,
      payload: error.response.data,
    });
  } finally {
    dispatch({ type: couponTypes.RESET_COUPONS_LOADING });
  }
};

export const updateCoupon = (form) => async () => {
  try {
    await useAxiosPost("/coupon/update", form);
    toastr.success("Done", "Coupon updated");
  } catch (error) {
    toastr.error("Error", "Coupon not updated");
    console.log(error);
  }
};

export const setActiveCoupon = (couponId) => async (dispatch) => {
  try {
    if (couponId) {
      const response = await useAxiosGet(`/coupon/${couponId}`);
      dispatch({
        type: couponTypes.SET_ACTIVE_COUPON,
        payload: response.data,
      });
      return;
    }
  } catch (error) {
    console.log("Error fetching Coupon info: ", error);
  }
};

export const resetSelectedCoupon = () => (dispatch) => {
  dispatch({
    type: couponTypes.RESET_SELECTED_COUPON,
  });
};

export const searchCoupon = (couponCode, userId) => async (dispatch) => {
  try {
    if (couponCode) {
      const response = await useAxiosPost("/coupon/search", { couponCode, userId });
      dispatch({
        type: couponTypes.SET_SELECTED_COUPON,
        payload: response.data?.success ? response.data : null,
      });

      if (!response.data?.success) {
        toastr.error(response.data?.message);
      }
      return response;
    }
  } catch (error) {
    console.log("Error fetching Coupon info: ", error);
  }
};

export const redeemCoupon = (couponId, userId) => async () => {
  try {
    const response = await useAxiosPost("/coupon/redeem", { id: couponId, userId });
    if (response?.data?.success) {
      return { success: true };
    } else {
      toastr.error("Invalid Coupon", "Please use a valid coupon");
      return { success: false };
    }
  } catch (error) {
    console.log("Error reedem Coupon: ", error);
  }
};

// hooks

export const useSubscribeToCoupons = () => {
  const dispatch = useDispatch();
  useFirestoreListener("coupons", {
    onAdd: (newDoc) => {
      dispatch(addCouponState(newDoc));
    },
    onUpdate: (updatedDoc) => {
      dispatch(updateCouponState(updatedDoc));
    },
    onDelete: (deletedDoc) => {
      dispatch(deleteCouponState(deletedDoc));
    },
  });
};
