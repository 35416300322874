export const footerSchema: any = {
  provider: [
    {
      section: "Provider",
      links: [
        {
          label: "Provider Portal",
          link: "/provider_portal",
        },
      ],
    },
    {
      section: "Policy",
      links: [
        {
          label: "Provider Agreement",
          link: "/provideragreementv2",
        },
        {
          label: "Service Provider Policy",
          link: "/service-provider-policy",
        },
      ],
    },
    {
      section: "About us",
      links: [
        {
          label: "Who is Dimension?",
          link: "/about_us",
        },
        {
          label: "Blog",
          link: "/blog",
        },
        {
          label: "Company News",
          link: "/in-the-news",
        },
      ],
    },
    {
      section: "Support",
      links: [
        {
          label: "Help Center",
          link: "https://support.getdimension.com/en_us/categories/provider-faq-HkPjq9kZi",
          isChatLauncher: true,
          KustomerAction: {
            action: "category",
            value: "provider-faq",
          }  
        },
        {
          label: "Contact us",
          link: null,
          isChatLauncher: true,
        },
        {
          label: "Terms of Service",
          link: "/terms",
        },
        {
          label: "Privacy Policy",
          link: "/privacy-policy",
        },
      ],
    },
  ],
  customer: [
    {
      section: "Business",
      links: [
        {
          label: "Dimension for Business",
          link: "/b2b-solutions",
        },
        {
          label: "Corporate Service Agreement",
          link: "/corporateserviceagreement",
        },
      ],
    },
    {
      section: "Clients",
      links: [
        {
          label: "Case Studies",
          link: "/case-studies",
        },
      ],
    },
    {
      section: "About us",
      links: [
        {
          label: "Who is Dimension?",
          link: "/about_us",
        },
        {
          label: "Blog",
          link: "/blog",
        },
        {
          label: "Company News",
          link: "/in-the-news",
        },
      ],
    },
    {
      section: "Support",
      links: [
        {
          label: "Help Center",
          link: "https://support.getdimension.com/en_us/categories/customer-faq-SkghOgbbi",
          isOutsideLink: true,
        },
        {
          label: "Contact us",
          link: null,
          isChatLauncher: true,
        },
        {
          label: "Terms of Service",
          link: "/terms",
        },
        {
          label: "Privacy Policy",
          link: "/privacy-policy",
        },
      ],
    },
  ],
  default: [
    {
      section: "Business",
      links: [
        {
          label: "Dimension for Business",
          link: "/b2b-solutions",
        },
        {
          label: "Case Studies",
          link: "/case-studies",
        },
        {
          label: "Corporate Service Agreement",
          link: "/corporateserviceagreement",
        },
      ],
    },
    {
      section: "Providers",
      links: [
        {
          label: "Become a Provider",
          link: "/provider_portal",
        },
        {
          label: "Provider Portal",
          link: "/provider_portal",
        },
      ],
    },
    {
      section: "About us",
      links: [
        {
          label: "Who is Dimension?",
          link: "/about_us",
        },
        {
          label: "Careers",
          link: "https://jobs.lever.co/getdimension",
          isOutsideLink: true,
        },
        {
          label: "Blog",
          link: "/blog",
        },
        {
          label: "Company News",
          link: "/in-the-news",
        },
      ],
    },
    {
      section: "Support",
      links: [
        {
          label: "Help Center",
          link: "https://support.getdimension.com/",
          isOutsideLink: true,
        },
        {
          label: "Contact us",
          link: null,
          isChatLauncher: true,
        },
        {
          label: "Terms of Service",
          link: "/terms",
        },
        {
          label: "Privacy Policy",
          link: "/privacy-policy",
        },
      ],
    },
  ],
};
