import React, { useCallback, useState, forwardRef } from "react";
import { useSelector } from "react-redux";
import { Accordion, AccordionSummary, Box, Divider, Hidden, makeStyles } from "@material-ui/core";

import useMountedEffect from "@shared/hooks/useMountedEffect";
import NeedHelp from "@shared/components/NeedHelp";

import { updateField } from "@booking/helpers/utils";
import useTriggerValidation from "@shared/hooks/useTriggerValidation";
import { Input } from "@booking/helpers/enums";
import { stairsPrice, dismantlingPrice } from "@booking/helpers/apiData";
import TermsOfServiceField from "@booking/helpers/fields/TermsOfServiceField";

import Total from "@booking/components/summary/Total";
import CouponDiscount from "@booking/components/summary/CouponDiscount";
import RentalPickupPrice from "@booking/components/summary/RentalPickupPrice";
import BulkDiscount from "@booking/components/summary/BulkDiscount";
import Item from "@booking/components/summary/Items/Item";
import Items from "@booking/components/summary/Items";
import BookingDetails from "@booking/components/summary/BookingDetails";

import { applicableTasksforDiscount } from "@constants/pricing";
import { NextDayDiscount } from "../../../components/summary/NextDayDiscount";
import { QuoteLabel } from "../../../components/summary/Items/QuoteLabel";
import CustomItems from "../../../components/summary/Items/CustomItems";
import BidQuote from "../../../components/BidQuote";
import { bidCPDiscount } from "../../../../../constants/bids";

const useStyles = makeStyles((theme) => ({
  summary: {
    "& .MuiAccordionSummary-content": {
      display: "block",
    },
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  total: {
    "& .item-tag": {
      fontSize: 18,
      fontWeight: 800,
      color: theme.palette.primary.main,
    },
    "& .item-price": {
      fontSize: 14,
      fontWeight: 800,
      color: theme.palette.primary.main,
    },
  },
}));

const SingleOrderReviewStep = forwardRef((props, ref) => {
  const styles = useStyles();

  const { defaultValue, onChange, draftBooking, config, summaryProps } = props;
  const { expandPolicy, showSummary = true, showPolicy = true, isBidMode } = config;
  const { task, vehicle, junk, dumpster, date, address, apartment, stairs, dismantling, total, pickup, pickupDate, showReducedPrice } = summaryProps;
  const adjustedTotal = showReducedPrice ? draftBooking?.total * ((100 - bidCPDiscount) / 100) : draftBooking?.total;
  const selectedCoupon = useSelector((state) => state.coupon.selectedCoupon);
  const hasCustomItems = junk && !total && junk?.filter((i) => i.item.isCustom)?.length > 0;
  const [termsOfServiceField, setTermsOfServiceField] = useState(defaultValue[Input.TermsOfService]);

  useMountedEffect(() => {
    onChange({
      [Input.TermsOfService]: termsOfServiceField,
    });
  }, [termsOfServiceField]);

  useTriggerValidation(ref, [setTermsOfServiceField]);

  const handleTermsOfServeChange = useCallback(
    (field) => {
      setTermsOfServiceField(updateField(termsOfServiceField, field));
    },
    [termsOfServiceField]
  );

  const locked = draftBooking?.isLockedPrice ?? false;

  return (
    <>
      {showSummary && !isBidMode && (
        <Hidden mdUp>
          <Accordion className={styles.summary} expanded defaultExpanded>
            <AccordionSummary>
              <Total
                className={styles.total}
                task={task}
                stairs={stairs}
                dismantling={dismantling}
                vehicle={vehicle}
                junk={junk}
                date={date}
                dumpster={dumpster}
                pickupDate={pickupDate}
                isServiceFeeWaived={draftBooking?.isServiceFeeWaived}
                isVehicleFeeWaived={draftBooking?.isVehicleFeeWaived}
                isLockedPrice={draftBooking?.isLockedPrice}
                total={adjustedTotal}
              />
              <Divider className={styles.divider} />
              <Items task={task} vehicle={vehicle} junk={junk?.filter((i) => !i.item.isCustom)} dumpster={dumpster} isLockedPrice={locked} />
              {applicableTasksforDiscount.includes(task) && !locked && (
                <BulkDiscount
                  task={task}
                  vehicle={vehicle}
                  junk={junk}
                  dumpster={dumpster}
                  isServiceFeeWaived={draftBooking?.isServiceFeeWaived}
                  isVehicleFeeWaived={draftBooking?.isVehicleFeeWaived}
                />
              )}
              {task !== "dumpster-rental" && !locked && <NextDayDiscount date={date} />}
              {pickup && !locked && <RentalPickupPrice dropOffDate={date} pickupDate={pickupDate} />}

              {!!stairs && !total && !locked && <Item tag="Stairs" price={stairsPrice.value} quantity={stairs} />}
              {!!dismantling && !total && !locked && <Item tag="Dismantling" price={dismantlingPrice.value} quantity={dismantling} />}

              {!total && !locked && <Divider className={styles.divider} />}

              {!locked && (
                <>
                  {selectedCoupon && <QuoteLabel label="Coupons" />}
                  {selectedCoupon && <CouponDiscount />}
                  {selectedCoupon && <Divider className={styles.divider} />}
                </>
              )}

              {hasCustomItems && <QuoteLabel label="Special Items" />}
              {hasCustomItems && <CustomItems junk={junk?.filter((i) => i.item.isCustom)} isLockedPrice={locked} />}
              {hasCustomItems && <Divider className={styles.divider} />}

              <BookingDetails date={date} address={address} apartment={apartment} />
            </AccordionSummary>
          </Accordion>
        </Hidden>
      )}
      {showSummary && isBidMode && (
        <Hidden mdUp>
          <BidQuote />
        </Hidden>
      )}
      {showPolicy && (
        <>
          <TermsOfServiceField expandPolicy={expandPolicy} field={termsOfServiceField} onChange={handleTermsOfServeChange} isBidMode={isBidMode} />{" "}
          <NeedHelp />
        </>
      )}
    </>
  );
});

SingleOrderReviewStep.defaultProps = {
  date: undefined,
  address: undefined,
  apartment: undefined,
};

export default React.memo(SingleOrderReviewStep);
