import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const StyledBox = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      flex: 1,
      maxWidth: theme.spacing(64 + theme.spacing(1)),
    },
  },
}))(Box);

const Wrapper = ({ children }) => {
  return <StyledBox>{children}</StyledBox>;
};

export default Wrapper;
