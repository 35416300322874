import React from "react";
import classes from "@css/components/website/Solutions.module.css";
import businessSolutionClasses from "../../../css/components/website/BusinessServices.module.css";
import { Button, Typography } from "@material-ui/core";
import JunkRemoval from "../../../assets/landing_page/waste-removal.svg";
import DumpsterRental from "../../../assets/landing_page/dumpster-rental.svg";
import classNames from "classnames";
import { useHistory } from "react-router";

function Solutions() {
  const history = useHistory();
  return (
    <section className={classes.container}>
      <div className={classes.mainContent}>
        <Typography className={classes.heading}>
          Whatever you need, <br />
          we have the solution
        </Typography>
        <div className={businessSolutionClasses.boxContainer}>
          <div className={businessSolutionClasses.box}>
            <div className={businessSolutionClasses.boxContent}>
              <div>
                <img src={JunkRemoval} alt="Waste removal" />
                <Typography className={classNames(classes.heading, classes.smallHeading)}>Waste and Recyclables removal</Typography>
              </div>
              <ul className={classes.boxList}>
                <li className={classes.boxItem}>Completely digital and on demand - solve your waste management worries in minutes.</li>
                <li className={classes.boxItem}>
                  Fully customizable solutions - whether you&apos;re looking for a one-off pick up or a recurring solution, we&apos;ve got you
                  covered.
                </li>
                <li className={classes.boxItem}>
                  Easy and reliable - we&apos;ll connect you with the providers best equipped to handle your job, leaving you free to focus on what
                  matters.
                </li>
              </ul>
              <Button
                disableRipple
                fullWidth
                className={classes.primaryButton}
                variant="contained"
                onClick={() => history.push("booking?step=initial")}
              >
                Book Now
              </Button>
            </div>
          </div>
          <div className={businessSolutionClasses.box}>
            <div className={classes.boxContent}>
              <div>
                <img src={DumpsterRental} alt="Dumpster Rental" />
                <Typography className={classNames(classes.heading, classes.smallHeading)}>Dumpster Rental</Typography>
              </div>
              <ul className={classes.boxList}>
                <li className={classes.boxItem}>
                  Fast and Flexible - choose from our wide selection of dumpsters and trailers, with delivery as soon as the next day.
                </li>
                <li className={classes.boxItem}>
                  Hassle free - our extensive diversion data and trusted providers give you the peace of mind that your waste will be managed
                  responsibly.
                </li>
                <li className={classes.boxItem}>
                  On your terms - whether you need a unit for a day or a monthly rental with routine pickups, we can find you the perfect solution.
                </li>
              </ul>
              <Button
                disableRipple
                fullWidth
                className={classes.primaryButton}
                variant="contained"
                onClick={() => history.push("/booking?step=initial&task=dumpster-rental")}
              >
                Book Now
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Solutions;
