import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";
import classes from "@css/components/website/Header.module.css";
import { accountMenuItems, headerMenu } from "./constants.js";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "../../store/actions/auth";
import tempDataConstants from "../../store/constants/tempDataTypes";
import { roles } from "../../constants/roles";

import accountIcon from "../../assets/landing_page/icons/user.svg";
import linkedInIcon from "../../assets/landing_page/linkedIn-icon.svg";
import { PowerSettingsNewOutlined } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

function MobileMenu({ isOpaque, onClose, options = {} }: { isOpaque: boolean; onClose: any; options: any }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoggedIn = useSelector((state: any) => state.firebase.auth?.uid);
  const profile = useSelector((state: any) => state.firebase.profile);
  const isProvider = profile?.userType === roles.provider.value;

  const handleMenuItemClick = (link: any) => {
    if (link.isOutsideLink) {
      window.open(link.link, "_blank");
      onClose();
    } else {
      history.push(link.link);
      onClose();
    }
  };

  const signOutMenuItem = {
    label: "Sign Out",
    icon: <PowerSettingsNewOutlined color="primary" />,
    func: () => dispatch(signOut()),
  };

  const handleLogin = () => {
    dispatch({
      type: tempDataConstants.SET_TEMP_USER,
      userType: roles.customer.value,
    });
    history.push("/auth/signin");
    onClose();
  };

  const handleBookNow = () => {
    history.push("booking?step=initial");
    onClose();
  };

  const headerMenuItems = () => {
    if (isLoggedIn) {
      if (isProvider) return headerMenu.providerHeaderMenu;
      return headerMenu.customerHeaderMenu;
    }
    return headerMenu.headerMenu;
  };

  return (
    <>
      <IconButton className={classes.menuCloseButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      {isLoggedIn && (
        <Accordion className={classes.drawerMenuAccordion}>
          <AccordionSummary
            classes={{ content: classes.drawerMenuAccordionTitle }}
            expandIcon={<ExpandMoreIcon htmlColor="#DBC6AA" />}
            aria-controls="account-content"
            id="account-header"
          >
            <img src={accountIcon} alt="Account icon" />
            <Typography className={classNames(classes.drawerText, classes.drawerMenuItem)}>Account</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List style={{ width: "100%" }} component="nav" aria-label="dimension mobile menu">
              {(isProvider
                ? accountMenuItems(profile, true).providerSideNavLinks
                : accountMenuItems({ ...profile, ...options }).customerSideNavLinks
              ).map((menuItem) => (
                <ListItem style={{ paddingRight: 0 }} key={menuItem.label} button>
                  <ListItemText
                    onClick={() => handleMenuItemClick(menuItem)}
                    className={classNames(classes.drawerText, classes.drawerMenuSubItem)}
                    primary={menuItem.label}
                  />
                  <ListItemIcon style={{ justifyContent: "flex-end" }}>
                    <ChevronRightIcon htmlColor="white" />
                  </ListItemIcon>
                </ListItem>
              ))}
              <ListItem style={{ paddingRight: 0 }} button>
                <ListItemText
                  onClick={() => signOutMenuItem.func()}
                  className={classNames(classes.drawerText, classes.drawerMenuSubItem)}
                  primary={signOutMenuItem.label}
                />
                <ListItemIcon style={{ justifyContent: "flex-end" }}>
                  <ChevronRightIcon htmlColor="white" />
                </ListItemIcon>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
      )}
      {headerMenuItems().map((item) => (
        <Accordion key={item.label} className={classes.drawerMenuAccordion}>
          <AccordionSummary
            onClick={() => item.menuItems.length === 0 && handleMenuItemClick(item || "")}
            expandIcon={item.menuItems.length > 0 && <ExpandMoreIcon htmlColor="#DBC6AA" />}
            aria-controls="menu-content"
            id="menu-header"
          >
            <Typography className={classNames(classes.drawerText, classes.drawerMenuItem)}>{item.label}</Typography>
          </AccordionSummary>
          {item.menuItems.length > 0 && (
            <AccordionDetails>
              <List style={{ width: "100%" }} component="nav" aria-label="dimension mobile menu">
                {item.menuItems.map((menuItem) => (
                  <ListItem style={{ paddingRight: 0 }} key={menuItem.label} button>
                    <ListItemText
                      onClick={() => handleMenuItemClick(menuItem)}
                      className={classNames(classes.drawerText, classes.drawerMenuSubItem)}
                      primary={menuItem.label}
                    />
                    <ListItemIcon style={{ justifyContent: "flex-end" }}>
                      <ChevronRightIcon htmlColor="white" />
                    </ListItemIcon>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          )}
        </Accordion>
      ))}
      <div className={classes.buttonContainerMenu}>
        {!isLoggedIn && (
          <Button
            disableRipple
            onClick={handleLogin}
            style={{ color: isOpaque ? "black" : "white" }}
            className={classNames(classes.secondaryButtonDark, classes.buttonMenu)}
            variant="contained"
          >
            Log In
          </Button>
        )}
        {!isProvider && (
          <Button disableRipple className={classNames(classes.primaryButton, classes.buttonMenu)} variant="contained" onClick={handleBookNow}>
            Book Now
          </Button>
        )}
      </div>
      <div className={classes.socialIcons}>
        {/* <img src={twitterIcon} alt="twitter icon" /> */}
        <img src={linkedInIcon} alt="linkedin icon" onClick={() => window.open("https://www.linkedin.com/company/getdimension/", "_blank")} />
        {/* <img src={facebookIcon} alt="facebook icon" /> */}
      </div>
    </>
  );
}

export default MobileMenu;
