import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginBottom: theme.spacing(2),
  },
  quoteLabel: {
    opacity: 0.5,
  },
}));
export const QuoteLabel = ({ label }) => {
  const styles = useStyles();
  return <Typography className={styles.quoteLabel}>{label}</Typography>;
};
