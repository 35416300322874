import React from "react";
import classnames from "classnames";
import { Box, makeStyles, Typography } from "@material-ui/core";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.marginTop": {
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      width: (props) => props.width,
    },
  },
  title: {
    fontSize: "1.2rem",
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  description: {
    fontSize: "1rem",
    opacity: 0.8,
  },
  bottomMargin: {
    marginBottom: theme.spacing(3),
  },
  flex: {
    display: "flex",
    verticalAlign: "middle",
  },
  toolTip: {
    marginLeft: 10,
    marginTop: -3,
    "& :hover": {
      cursor: "pointer",
    },
  },
}));

const Section = ({ marginTop, title, description, subDescription, width, toolTip, onToolTipClick, children }) => {
  const styles = useStyles({ width });

  const rootClassName = classnames(styles.root, marginTop ? "marginTop" : null);
  const titleClassName = classnames(styles.title, title && !description ? styles.bottomMargin : null);
  const descriptionClassName = classnames(styles.description, styles.bottomMargin);

  return (
    <Box className={rootClassName}>
      {title && <Typography className={titleClassName}>{title}</Typography>}
      {description && (
        <Box className={styles.flex}>
          <Typography className={descriptionClassName}>{description}</Typography>
          {toolTip && (
            <Box className={styles.toolTip} onClick={onToolTipClick}>
              <HelpOutlineOutlinedIcon color="primary" />
            </Box>
          )}
        </Box>
      )}
      {subDescription && <Typography className={descriptionClassName}>{subDescription}</Typography>}
      <Box>{children}</Box>
    </Box>
  );
};

Section.defaultProps = {
  title: null,
  description: null,
  subDescription: null,
  marginTop: false,
  toolTip: false,
  width: "100%",
  onToolTipClick: () => {},
};

export default Section;
