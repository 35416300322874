import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { Box, FormHelperText, makeStyles, Typography, useMediaQuery, useTheme } from "@material-ui/core";

import validators from "@booking/helpers/validators";
import { isError, errorCode } from "@booking/helpers/utils";
import { Input, Vehicle } from "@booking/helpers/enums";

import Section from "@booking/components/Section";
import useCommonStyles from "../useStyles";

import Option from "./Option";
import AlertDialog from "@shared/components/AlertDialog";

import { segmentTrack } from "@helpers/segment";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
  option: {
    marginBottom: theme.spacing(1),
    "&:last-child": {
      marginRight: 0,
    },
    [theme.breakpoints.up("lg")]: {
      flex: 1,
      marginRight: theme.spacing(2),
      marginBottom: 0,
    },
  },
  vehicles: {
    color: "#686872",
  },
  guidelines: {
    marginTop: 20,
    color: "#686872",
    maxWidth: 400,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    cursor: "pointer",
  },
  mobileHelperText: {
    marginBottom: theme.spacing(3),
    color: "#555",
    fontSize: "0.8rem",
    opacity: 0.5,
  },
}));

const VehicleDetails = () => {
  const styles = useStyles();

  return (
    <Box>
      <Box className={styles.vehicles}>
        Truck: ~ 1 - 4 cubic yards of space <br />
        Box Truck: ~ 5 - 10 cubic yards of space
      </Box>
      <Box className={styles.guidelines}>
        Please note these are general guidance - actual vehicles might vary. Please see this{" "}
        <Link to="/pricing_guide" target="_blank" className={styles.link}>
          link
        </Link>{" "}
        for a few real world examples what each vehicle can max contain.
      </Box>
    </Box>
  );
};

const VehicleFieldComponent = ({ field, onChange, isQuote }) => {
  const styles = useStyles();
  const [helperDialog, setHelperDialog] = useState(false);
  const commonStyles = useCommonStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { value, validation } = field;

  useEffect(() => {
    onChange({ validation: validators[Input.Vehicle](field) });
  }, [value]);

  const isSelected = (vehicle) => vehicle === value;
  const handleSelect = (nextVehicle) => () => {
    onChange({ value: nextVehicle });

    /* Segment Analytics */
    segmentTrack("Vehicle size chosen", { vehicle: nextVehicle });
  };

  const title = "Choose a vehicle";
  const description = isQuote ? "We've selected the right vehicle for your request" : "Make your best guess. We will review every order.";
  const mobileHelperText =
    "Having a hard time deciding on the vehicle size? Our guidelines: a pickup truck usually fits <20 cardboard, and a truck can fit more than 20 cardboard. We will review every order after you finish booking, and will reach out if we think you underbook or overbook.";

  return (
    <Section title={title} description={description} toolTip onToolTipClick={() => setHelperDialog(true)}>
      <Box className={classnames(commonStyles.field, styles.root)}>
        <Option
          className={styles.option}
          vehicle={Vehicle.PickupTruck}
          selected={isSelected(Vehicle.PickupTruck)}
          onSelect={handleSelect(Vehicle.PickupTruck)}
        />
        <Option className={styles.option} vehicle={Vehicle.Truck} selected={isSelected(Vehicle.Truck)} onSelect={handleSelect(Vehicle.Truck)} />
      </Box>
      {isMobile && !isQuote && <Typography className={styles.mobileHelperText}>{mobileHelperText}</Typography>}
      <FormHelperText error={isError(validation)}>{errorCode(validation)}</FormHelperText>
      {helperDialog && (
        <AlertDialog
          title=""
          body={<VehicleDetails />}
          agreeBtnText="Got it"
          hideDisagreeBtn
          onClose={() => setHelperDialog(false)}
          handleProceed={() => setHelperDialog(false)}
        />
      )}
    </Section>
  );
};

export default React.memo(VehicleFieldComponent);
