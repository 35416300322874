import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import dayjs from "dayjs";
import { QuoteLabel } from "./Items/QuoteLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(4),
    "& p": {
      color: "#ab4a0a",
      fontWeight: 500,
      [theme.breakpoints.up("md")]: {
        fontSize: "1rem",
      },
    },
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tnc: {
    marginTop: theme.spacing(1),
    fontSize: "0.8rem",
    opacity: 0.8,
    fontWeight: 400,
    marginBottom: 20,
  },
}));

const RentalPickupPrice = ({ dropOffDate, pickupDate }) => {
  const classes = useStyles();

  const extraDays = dayjs(pickupDate).diff(dayjs(dropOffDate).hour(0).add(20, "days"), "days");

  if (extraDays <= 0) {
    return null;
  }

  return (
    <>
      <QuoteLabel label="Rental Fees" />
      <Box className={classes.row}>
        <Typography>
          {extraDays} extra {extraDays > 1 ? "days" : "day"}
        </Typography>
        <Typography>${extraDays * 20}.00</Typography>
      </Box>
      <Box className={classes.tnc}>Additional $20 per day after 20 days</Box>
    </>
  );
};

export default RentalPickupPrice;
