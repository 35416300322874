import React, { useState } from "react";
import classnames from "classnames";
import { Box, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  policy: {
    marginBottom: theme.spacing(2),
  },
  transaction: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
  linked: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
  title: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
}));

const Policy = ({ expanded = false, isBidMode = false }) => {
  const styles = useStyles();
  const [isExpanded, setExpanded] = useState(expanded);

  const handleTitleClick = () => {
    setExpanded(true);
  };

  return isBidMode ? <div /> : (
    <Box className={styles.policy}>
      <Typography variant="subtitle2" className={styles.transaction}>
        Your transaction is protected by <b>Stripe</b>.
      </Typography>
      <Typography variant="subtitle1" className={classnames(styles.title, !isExpanded ? styles.linked : null)} onClick={handleTitleClick}>
        Cancellation and Reschedule policy
      </Typography>
      {isExpanded && (
        <Typography variant="subtitle2" component="ul">
          <li style={{ fontWeight: "bold"}}>Advance booking was cancelled</li>
          <li>Within 5 hours before the appointment - refund 15% of the booking.</li>
          <li>Within 24 hours before the appointment - refund 25% of the booking.</li>
          <li>More than 24 hours before the appointment - refund 75% of the booking.</li>
        </Typography>
      )}
      {isExpanded && (
        <Typography variant="subtitle2" component="ul">
        <li style={{ fontWeight: "bold"}}>Same day booking was cancelled</li>
          <li>Within 6 hours before the appointment - refund 75% of the booking.</li>
          <li>Within 8 hours before the appointment - refund 80% of the booking.</li>
          <li>More than 8 hours before the appointment - refund 90% of the booking</li>
        </Typography>
      )}
    </Box>
  );
};

Policy.defaultProps = {
  expanded: false,
};

export default Policy;
