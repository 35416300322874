import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  section: {
    width: "100%",
    padding: theme.spacing(3, 0),
    borderTop: "1px solid #ccc",
  },
  sectionNoBorder: {
    width: "100%",
    padding: theme.spacing(3, 0),
  },
}));

function BookingDetailsSection({ border, children }) {
  const classes = useStyles();
  return <div className={!border ? classes.sectionNoBorder : classes.section}>{children}</div>;
}

BookingDetailsSection.propTypes = {
  border: PropTypes.bool,
  children: PropTypes.node,
};

export default BookingDetailsSection;
