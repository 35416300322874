import React, { useState, useRef, useEffect } from "react";
import classes from "@css/components/website/Sustainability.module.css";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
import AddIcon from "@material-ui/icons/Add";
import { getWordpressPostList } from "../../../pages/utils/wordpress";
import { Link } from "react-router-dom";

const getTransformStyle = (expanded: boolean) => (expanded ? "rotate(45deg)" : "");

export const SustainabilityCard = ({ item, index, expanded, handleOpen }: { item: any; index: number; expanded: boolean; handleOpen: any }) => (
  <div className={classes.feature}>
    <img src={item.jetpack_featured_media_url ?? ""} className={classes.imgIcon} />
    <div className={classes.customAccordion} onClick={() => handleOpen("panel" + (index + 1))}>
      <div className={classes.accordionSummary}>
        <Typography className={classes.accordionHeader}>{ReactHtmlParser(item.title?.rendered)}</Typography>
      </div>
      <AddIcon className={classes.primaryButton} style={{ transform: getTransformStyle(expanded) }} />
      {expanded && (
        <div className={classes.accordionDetails}>
          <Typography>{ReactHtmlParser(item.excerpt?.rendered)}</Typography>
          <Link className={classes.link} to={`/business/${item.slug}`}>
            Read more
          </Link>
        </div>
      )}
    </div>
  </div>
);

function Sustainability() {
  const [openAccordions, setOpenAccordions] = useState<Array<string>>([]);
  const [posts, setPosts] = useState([]);

  const handleOpen = (accordion: string) => {
    if (!openAccordions.includes(accordion)) {
      setOpenAccordions((state) => [...state, accordion]);
    } else {
      setOpenAccordions((state) => state.filter((i) => i !== accordion));
    }
  };

  const subscribed = useRef(false);

  const getPost = async () => {
    await getWordpressPostList("1372", setPosts, subscribed);
  };

  useEffect(() => {
    subscribed.current = true;
    getPost();
    return () => {
      subscribed.current = false;
    };
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.mainContent}>
        <div className={classes.hero}>
          <Typography className={classes.header}>Sustainable Waste Management Solutions for your Unique Business Needs.</Typography>
          <Typography className={classes.description}>
            Every industry has their own waste problems, which is why we focus on providing customized, efficient, budget friendly and sustainable
            disposal solutions. Learn more about our industry-specific solutions and expertise.
          </Typography>
        </div>
        <div className={classes.resourceContainer}>
          {posts.map((post: any, index: number) => (
            <SustainabilityCard
              item={post}
              key={index}
              index={index}
              expanded={openAccordions.includes("panel" + (index + 1))}
              handleOpen={handleOpen}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Sustainability;
