import React, { useCallback, useState, forwardRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Accordion, AccordionDetails, AccordionSummary, Divider, Hidden, makeStyles } from "@material-ui/core";

import { NextDayDiscount } from "../../../components/summary/NextDayDiscount";
import { QuoteLabel } from "../../../components/summary/Items/QuoteLabel";
import CustomItems from "../../../components/summary/Items/CustomItems";
import { MultiReviewOrderStepProps } from "./interface";
import { applicableTasksforDiscount } from "../../../../../constants/pricing";
import Items from "../../../components/summary/Items";
import BulkDiscount from "../../../components/summary/BulkDiscount";
import RentalPickupPrice from "../../../components/summary/RentalPickupPrice";
import CouponDiscount from "../../../components/summary/CouponDiscount";
import BookingDetails from "../../../components/summary/BookingDetails";
import Total from "../../../components/summary/Total";
import useMountedEffect from "../../../../../shared/hooks/useMountedEffect";
import { Input } from "../../enums";
import useTriggerValidation from "../../../../../shared/hooks/useTriggerValidation";
import { getSummaryPropsForPriceQuote, updateField } from "../../utils";
import { dismantlingPrice, stairsPrice } from "../../apiData";
import Item from "../../../components/summary/Items/Item";
import TermsOfServiceField from "../../fields/TermsOfServiceField";
import NeedHelp from "../../../../../shared/components/NeedHelp";

const useStyles = makeStyles((theme) => ({
  summary: {
    "& .MuiAccordionSummary-content": {
      display: "block",
    },
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  total: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 0,
    width: "100%",
    "& .item-tag": {
      fontSize: 18,
      fontWeight: 800,
      color: theme.palette.primary.main,
    },
    "& .item-price": {
      fontSize: 18,
      fontWeight: 800,
      color: theme.palette.primary.main,
    },
  },
  accordion: {
    marginBottom: "16px",
  },
}));

const SingleOrderReviewStep = forwardRef((props: MultiReviewOrderStepProps, ref) => {
  const styles = useStyles();

  const { defaultValue, onChange, bookings, config, confirmedQuotes } = props;
  const { expandPolicy, showSummary = true, showPolicy = true, isBidMode } = config;

  const selectedCoupon = useSelector((state: any) => state.coupon.selectedCoupon);

  const [termsOfServiceField, setTermsOfServiceField] = useState(defaultValue[Input.TermsOfService]);
  const [quoteDetails, setQuoteDetails] = useState<Array<any>>([]);

  const calculateTotals = () => {
    let quoteData: Array<any> = [];
    bookings?.forEach((booking: any) => {
      const isConfirmedQuote = confirmedQuotes.includes(booking.quoteNumber);
      quoteData.push(getSummaryPropsForPriceQuote({ ...booking, isConfirmedQuote }));
    });
    setQuoteDetails(quoteData);
  };

  useEffect(() => {
    calculateTotals();
  }, [bookings, confirmedQuotes]);

  useMountedEffect(() => {
    onChange({
      [Input.TermsOfService]: termsOfServiceField,
    });
  }, [termsOfServiceField]);

  useTriggerValidation(ref, [setTermsOfServiceField]);

  const handleTermsOfServeChange = useCallback(
    (field) => {
      setTermsOfServiceField(updateField(termsOfServiceField, field));
    },
    [termsOfServiceField]
  );

  return (
    <>
      {showSummary &&
        quoteDetails.map((quote) => {
          const {
            task,
            vehicle,
            junk,
            dumpster,
            date,
            address,
            apartment,
            stairs,
            dismantling,
            total,
            pickup,
            pickupDate,
            isLockedPrice,
            isServiceFeeWaived,
            isVehicleFeeWaived,
            hasCustomItems,
          } = quote;
          return (
            <Accordion classes={{ root: styles.accordion }} key={quote.quoteNumber}>
              <AccordionSummary>
                <Total
                  className={styles.total}
                  task={task}
                  stairs={stairs}
                  dismantling={dismantling}
                  vehicle={vehicle}
                  junk={junk}
                  date={date}
                  dumpster={dumpster}
                  pickupDate={pickupDate}
                  isServiceFeeWaived={isServiceFeeWaived}
                  isVehicleFeeWaived={isVehicleFeeWaived}
                  isLockedPrice={isLockedPrice}
                  total={total}
                  tag={`Total - ${quote.quoteNumber}`}
                />
              </AccordionSummary>
              <AccordionDetails style={{ display: "flex", flexDirection: "column" }}>
                <Items
                  task={task}
                  vehicle={vehicle}
                  junk={junk?.filter((i: any) => !i.item.isCustom)}
                  dumpster={dumpster}
                  isLockedPrice={isLockedPrice}
                />
                {applicableTasksforDiscount.includes(task) && !isLockedPrice && (
                  <BulkDiscount
                    task={task}
                    vehicle={vehicle}
                    junk={junk}
                    dumpster={dumpster}
                    isServiceFeeWaived={isServiceFeeWaived}
                    isVehicleFeeWaived={isVehicleFeeWaived}
                  />
                )}
                {!isLockedPrice && (
                  <>
                    {task !== "dumpster-rental" && <NextDayDiscount date={date} />}
                    {pickup && <RentalPickupPrice dropOffDate={date} pickupDate={pickupDate} />}

                    {!!stairs && !total && <Item tag="Stairs" price={stairsPrice.value} quantity={stairs} />}
                    {!!dismantling && !total && <Item tag="Dismantling" price={dismantlingPrice.value} quantity={dismantling} />}

                    {!total && <Divider className={styles.divider} />}
                    {selectedCoupon && <QuoteLabel label="Coupons" />}
                    {selectedCoupon && <CouponDiscount />}
                    {selectedCoupon && <Divider className={styles.divider} />}
                  </>
                )}

                {hasCustomItems && <QuoteLabel label="Special Items" />}
                {hasCustomItems && <CustomItems junk={junk?.filter((i: any) => i.item.isCustom)} isLockedPrice={isLockedPrice} />}
                {hasCustomItems && <Divider className={styles.divider} />}

                <BookingDetails date={date} address={address} apartment={apartment} />
              </AccordionDetails>
            </Accordion>
          );
        })}
      {showPolicy && (
        <>
          <TermsOfServiceField expandPolicy={expandPolicy} field={termsOfServiceField} onChange={handleTermsOfServeChange} isBidMode={isBidMode} />{" "}
          <NeedHelp />
        </>
      )}
    </>
  );
});

export default React.memo(SingleOrderReviewStep);
