import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";

import validators from "@booking/helpers/validators";
import { Input } from "@booking/helpers/enums";
import Section from "@booking/components/Section";
import DescriptionField from "@booking/helpers/fields/DescriptionField";
import CouponField from "@booking/helpers/fields/CouponField";
import UnderstandPricing from "@booking/helpers/fields/UnderstandPricing";

import useStyles from "../useStyles";

import { uuid } from "@shared/utils/generateUID";

import { ProductList, DumpsterSelector } from "./components";
import { dumpsterDescription } from "../../constants";
import { useSelector } from "react-redux";
import { constructDumpsterSizes, everydayPickupPrice } from "../../../../../constants/dumpsters";
import { getDynamicMatchingItem } from "../../../../../helpers/dumpsters";
import dayjs from "dayjs";

function DumpsterPicker({ fields, onChange, handleUnderstandPricing, handleDescriptionChange, pickupForm }) {
  const classes = useStyles();
  const { dumpsterField, descriptionField, understandPricingField } = fields;
  const { value } = dumpsterField;

  // const pickup = pickupForm[Input.Pickup].value;
  const pickupDate = pickupForm[Input.PickupDate].value;
  const dropOffDate = pickupForm[Input.Date].value;
  const pickupDropoffDiff = dayjs(pickupDate).diff(dayjs(dropOffDate).hour(0), "day");

  const dumpsterBasePrices = useSelector((state) => state.tempData.dumpsterBasePrices);
  const dumpsterSheetList = useSelector((state) => state.tempData.dumpsterMarketPricing) ?? [];

  const [showFinalForm, setShowFinalForm] = useState(false);
  const [isSelectorVisible, setSelectorVisibility] = useState(true);
  const [options, setOptions] = useState(constructDumpsterSizes(pickupDropoffDiff, dumpsterBasePrices));

  useEffect(() => {
    onChange({ validation: validators[Input.Dumpster](dumpsterField) });
    setShowFinalForm(value.length > 0 && value.every((product) => product.item.items?.length > 0));
    if (value.length > 0) {
      setSelectorVisibility(false);
    }
  }, [value]);

  useEffect(() => {
    setOptions(constructDumpsterSizes(pickupDropoffDiff, dumpsterBasePrices));
  }, [pickupDropoffDiff, dumpsterBasePrices]);

  useEffect(() => {
    const newVal = value.map((dumpster) => {
      const matchingItemInSheetList = getDynamicMatchingItem(dumpster.item, dumpsterSheetList);
      let price = 0;
      if (matchingItemInSheetList) {
        dumpster.item.isLockedPriceItem = true;
        dumpster.item.price = matchingItemInSheetList.customerPrice;
        dumpster.item.warriorPrice = matchingItemInSheetList.warriorPrice;
        dumpster.item.customerPrice = matchingItemInSheetList.customerPrice;
        price = matchingItemInSheetList.customerPrice + pickupDropoffDiff * everydayPickupPrice;
      } else {
        dumpster.item.isLockedPriceItem = false;
        price = options.find((option) => option.id === dumpster.item.id)?.price ?? 0;
        delete dumpster.item.warriorPrice;
        delete dumpster.item.customerPrice;
      }

      return {
        ...dumpster,
        item: {
          ...dumpster.item,
          ...options.find(({ id }) => id === dumpster.item.id), // updating items on pickup change,
          ...(price && { price }),
        },
      };
    });
    onChange({ value: newVal });
  }, [options]);

  const handleChange = (_event, item) => {
    if (item) {
      item.uid = uuid();
      onChange({ value: [...value, { item }] });
      setSelectorVisibility(false);
    }
  };

  const removeProduct = (productId) => {
    onChange({
      value: value.filter(({ item }) => item.uid !== productId),
    });
    setSelectorVisibility(true);
  };

  const updateProduct = (item) => {
    const index = value.findIndex((product) => product.item.uid === item.uid);
    if (index !== -1) {
      const nextValue = [...value];
      nextValue[index] = { item };
      onChange({ value: nextValue });
      setSelectorVisibility(false);
    }
  };

  return (
    <Section title="Please pick dumpster(s) and dumpster material(s)">
      <div className={classes.field}>
        <ProductList days={pickupDropoffDiff} products={value} onRemove={removeProduct} onChange={updateProduct} />
        {isSelectorVisible && <DumpsterSelector dumpsterSizes={options} selectedDumpsterSizes={value} onChange={handleChange} />}
        {!isSelectorVisible && showFinalForm && (
          <>
            <Typography color="primary" variant="body1" component="h3" onClick={() => setSelectorVisibility(true)} className={classes.addAnotherLink}>
              Want to add another dumpster?
            </Typography>
            <UnderstandPricing field={understandPricingField} onChange={handleUnderstandPricing} />
            <DescriptionField minRows={4} field={descriptionField} description={dumpsterDescription} onChange={handleDescriptionChange} />
            <CouponField />
          </>
        )}
      </div>
    </Section>
  );
}

export default React.memo(DumpsterPicker);
