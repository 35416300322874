import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, Grid, Divider, Typography } from "@material-ui/core";
import cn from "classnames";

import PaymentRoundedIcon from "@material-ui/icons/PaymentRounded";
import { cardBrands, paymentMethodIcons } from "../../../constants/payments";
import dayjs from "dayjs";
import Dropdown from "../../Admin/HomePage/components/dropdown";
import { useAxiosPost } from "../../../axios";
import { fetchSetupIntent, setDefaultPaymentMethod, deletePaymentMethod } from "../../../store/actions/payments";
import CollectPaymentInfo from "../../../shared/components/CollectPaymentInfo";

const useStyles = makeStyles((theme) => ({
  paymentListContainer: {
    margin: "2rem 0",
  },
  paymentMethod: {
    display: "grid",
    gridTemplateColumns: "55px 140px auto 80px 50px",
    width: "100%",
    borderBottom: "1px solid #eee",
    padding: theme.spacing(1, 0),
    alignItems: "center",
  },
  header: {
    marginBottom: "1rem",
  },
  colGap: {
    [theme.breakpoints.down("sm")]: {
      columnGap: theme.spacing(1),
    },
  },
  add: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: "16px",
    cursor: "pointer",
    textDecoration: "underline",
    textAlign: "right",
  },
  rows: {
    padding: "0.8rem 0.1rem",
    alignItems: "center",
    borderRadius: "0.5rem",
  },
  greyText: {
    color: theme.palette.grey[500],
  },
  defaultChip: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "1rem",
    padding: theme.spacing(0.2, 1),
    fontSize: 14,
    textAlign: "center",
  },
  paymentName: {
    fontWeight: 600,
  },
  paymentDescription: {
    opacity: 0.6,
  },
}));

const Icon = (src) => {
  return (
    <img
      src={src.src}
      style={{
        height: 30,
        width: 30,
        objectFit: "scale-down",
      }}
    />
  );
};

const constructMethods = (paymentMethods, defaultPaymentId, setFetchMethods) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.firebase.profile);
  const formattedMethods = [];
  if (paymentMethods.length) {
    paymentMethods.forEach((method) => {
      const {
        type,
        card: { brand, last4, exp_month, exp_year },
      } = method;
      formattedMethods.push({
        id: method.id,
        icon: cardBrands[brand]?.icon ?? paymentMethodIcons[type],
        title: `${cardBrands[brand]?.name ?? brand} •••• ${last4} `,
        subTitle: `Exp ${dayjs()
          .month(exp_month - 1)
          .year(exp_year)
          .format("MM/YY")}`,
        isDefault: method.id === defaultPaymentId,
        actions: [
          {
            label: "Make Default",
            isDisabled: method.id === defaultPaymentId,
            onClick: (e, close) => {
              dispatch(setDefaultPaymentMethod(method.id));
              close?.(e);
            },
          },
          {
            label: "Delete",
            isDisabled: paymentMethods.length === 1,
            onClick: (e, close) => {
              dispatch(deletePaymentMethod(method.id, () => setFetchMethods(true)));
              close?.(e);
            },
          },
        ],
      });
    });
    // only add ACH if they have a cc on file
    if (profile.businessId) {
      formattedMethods.push({
        id: "ACH",
        icon: paymentMethodIcons.ACH,
        title: "ACH",
        subTitle: "Invoice sent once booked",
        isDefault: defaultPaymentId === "ACH",
        type: "ACH",
        actions: [
          {
            label: "Make Default",
            onClick: (e, close) => {
              dispatch(setDefaultPaymentMethod("ACH"));
              close?.(e);
            },
          },
        ],
      });
    }
  }
  return formattedMethods;
};

const AccountPaymentMethods = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.firebase.profile);
  const [stripePaymentMethods, setStripePaymentMethods] = useState([]);
  const [showPaymentModal, setShowPaymentmodal] = useState(false);
  const [fetchMethods, setFetchMethods] = useState(true);

  const fetchMyPaymentMethods = async () => {
    const response = await useAxiosPost("/payments/get-payment-methods", { uid: profile.uid });
    const allPaymentMethods = response.data?.data?.data ?? [];
    setStripePaymentMethods(allPaymentMethods);
    setFetchMethods(false);
  };

  const onClickPaymentMethod = () => {
    dispatch(fetchSetupIntent());
    setShowPaymentmodal(true);
  };

  const onCloseModal = () => {
    setShowPaymentmodal(false);
  };

  useEffect(() => {
    if (fetchMethods) {
      fetchMyPaymentMethods();
    }
  }, [fetchMethods]);

  const defaultPaymentId = profile?.paymentMethod?.id ?? null;
  const paymentMethods = constructMethods(stripePaymentMethods, defaultPaymentId, setFetchMethods);

  return (
    <React.Fragment>
      <Divider />
      <Grid container className={classes.paymentListContainer}>
        <Grid container style={{ marginBottom: "15px" }} alignItems="center">
          <Grid item xs={2} sm={1}>
            <PaymentRoundedIcon fontSize="large" />
          </Grid>
          <Grid item xs={6} sm={8}>
            <Typography variant="h6">Payment Methods </Typography>
          </Grid>
          <Grid item xs={4} sm={3} className={classes.add} onClick={onClickPaymentMethod}>
            Add
          </Grid>
        </Grid>

        {paymentMethods.map((method) => (
          <div key={method.id} className={classes.paymentMethod}>
            <Icon src={method.icon} />
            <Typography className={classes.paymentName}>{method.title}</Typography>
            <Typography className={classes.paymentDescription}>{method.subTitle}</Typography>
            <Typography className={cn(method.isDefault ? classes.defaultChip : null)}>{method.isDefault ? "Default" : ""}</Typography>
            <Dropdown options={method.actions} />
          </div>
        ))}
      </Grid>
      {showPaymentModal && <CollectPaymentInfo open={showPaymentModal} onClose={onCloseModal} done={() => setFetchMethods(true)} />}
    </React.Fragment>
  );
};

export default AccountPaymentMethods;
