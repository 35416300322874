export const wordpressPostIds: { [key: string]: string } = {
  "waste-streams": "183",
  "improve-diversion": "198",
  "b2b-solutions": "203",
  "service-provider-policy": "320",
  "privacy-policy": "1012",
  "slack": "1022",
  "instacart-landing": "1064",
  "in-the-news": "1112",
};
export const wordpressCategoryIds: { [key: string]: number } = {
  blog: 1382,
  materials: 1385,
  recycling: 1389,
  business: 1372,
};

export interface WordPressDirectorySectionConfig {
  title: string;
  backgroundColor?: string | null;
  description: string;
  category: string;
  actions: Array<string>;
}

export interface WordPressDirectoryConfig {
  title: string;
  description: string;
  backgroundColor?: string | null;
  backgroundImage?: string | null;
  sections: Array<WordPressDirectorySectionConfig>;
  actions: Array<string>;
}

export interface PostConfig {
  id: number;
  slug: string;
  title: { rendered?: string };
  excerpt?: { rendered?: string };
  content?: { rendered?: string };
  categories?: Array<number>;
  jetpack_featured_media_url?: string;
}

export interface PostSummaryConfig {
  id: number;
  slug: string;
  title: { rendered?: string };
  excerpt?: { rendered?: string };
  categories?: Array<number>;
  jetpack_featured_media_url?: string;
}

export const wordpressDirectories: { [key: string]: WordPressDirectoryConfig } = {
  sustainability: {
    title: "Planet & Dimension",
    backgroundColor: "#459F65",
    description:
      "At Dimension, we care deeply how to properly dispose and recycle every material and every waste stream.  Learn more about our solutions and expertise.",
    sections: [
      {
        title: "Waste material",
        description: "There's a happy place for every material :)",
        backgroundColor: "#fff",
        category: "materials",
        actions: ["book", "talk"],
      },
      {
        title: "How to recycle?",
        description: "Learn about how to responsibly recycle the following waste stream and waste scenarios.",
        backgroundColor: "#f0f0f0",
        category: "recycling",
        actions: ["book", "talk"],
      },
    ],
    actions: ["book", "talk"],
  },
  ["b2b-solutions"]: {
    title: "Your Business & Dimension",
    backgroundColor: "#459F65",
    description:
      "At Dimension, we offer customized solutions to business customers of all industry verticals.  Learn more about our solutions and expertise.",
    sections: [
      {
        title: "Industry specific solutions",
        description: "",
        backgroundColor: "#fff",
        category: "business",
        actions: ["book", "talk"],
      },
    ],
    actions: ["book", "talk"],
  },
};
