import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Avatar, Box, Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import { vehicleTypes } from "@constants/booking";
import Spacer from "@shared/components/Spacer";
import BallotOutlinedIcon from "@material-ui/icons/BallotOutlined";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";
import EventIcon from "@material-ui/icons/Event";
import ClearAllOutlinedIcon from "@material-ui/icons/ClearAllOutlined";
import LocalAtmOutlinedIcon from "@material-ui/icons/LocalAtmOutlined";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import NoteOutlinedIcon from "@material-ui/icons/NoteOutlined";
import PinDropOutlinedIcon from "@material-ui/icons/PinDropOutlined";
import { useAxiosGet } from "../../../axios";
import ImageGalleryCustom from "../../../shared/components/ImageGallery";
import dayjs from "dayjs";
import { VerifiedUser } from "@material-ui/icons";
import { Rating } from "@material-ui/lab";
import { taskTypes } from "../../../constants/booking";
import { dumpsterPickups, Pickup } from "../../../constants/dumpsters";

const useStyles = makeStyles((theme) => ({
  detailsContainer: {
    padding: "10px 24px",
  },
  title: {
    fontSize: "1.2rem",
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    padding: "10px 24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  detailsGridContainer: {
    padding: "10px 0px",
    alignItems: "start",
    display: "grid",
    gridTemplateColumns: "200px auto",
  },
  detailsGridItem: {
    alignItems: "center",
    display: "flex",
  },
  detailsItemTitle: {
    paddingLeft: "10px",
  },
  detailsProductItemDescription: {
    paddingLeft: 10,
    opacity: 0.7,
  },
  detailsItemValue: {
    paddingLeft: "35px",
  },
  image: {
    height: 150,
    width: "100%",
    objectFit: "cover",
    marginBottom: 20,
  },
  imageBox: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
    columnGap: 20,
    width: "auto",
    maxWidth: "100%",
    justifyContent: "flex-start",
  },
  productItem: {
    display: "grid",
    gridTemplateColumns: "80px auto",
  },
}));

const WarriorContact = ({ warrior }) => (
  <div style={{ display: "grid", gridTemplateColumns: "50px auto", columnGap: 16 }}>
    <div style={{ marginLeft: -16 }}>
      <Avatar alt={warrior?.name} src={warrior?.headshot} style={{ border: "2px solid #459F65" }} />
    </div>
    <div style={{ marginLeft: -10 }}>
      <Typography>{warrior?.name}</Typography>
      {warrior?.rating !== "NaN" && (
        <Rating name="rating" style={{ fontSize: "12px" }} value={warrior?.rating !== "NaN" ? Number(warrior?.rating) : 0} disabled={true} />
      )}
    </div>
  </div>
);

const DoItAgainPreview = ({ booking, isQuote, summary, warrior }) => {
  const classes = useStyles();
  const profile = useSelector((state) => state.firebase.profile);
  const [myPaymentMethod, setMyPaymentMethod] = React.useState(null);

  const fetchMyPaymentMethod = async () => {
    const response = await useAxiosGet(`/payments/get-payment-method/${profile.paymentMethod.id}`);
    const paymentMethod = response.data?.data?.data ?? null;
    setMyPaymentMethod(paymentMethod);
  };

  useEffect(() => {
    if (profile?.paymentMethod?.id) {
      fetchMyPaymentMethod();
    }
  }, [profile.paymentMethod]);

  const ListedItem = ({ item }) => (
    <Box className={classes.detailsGridContainer}>
      <Box className={classes.detailsGridItem}>
        {item.icon}
        <Typography className={classes.detailsItemTitle}>{item.label}</Typography>
      </Box>
      <Box className={classes.detailsItemValue}>{item.value}</Box>
    </Box>
  );

  const isDumpster = booking?.task === taskTypes["dumpster-rental"].value;
  const isPowerWash = booking?.task === taskTypes["power-washing"].value;

  const quoteItems = [
    {
      label: isDumpster ? "Delivery Date" : "Service Date",
      value: dayjs(summary?.date ?? booking?.date)?.format("MMM DD, YYYY hh:mm A"),
      icon: <EventIcon />,
    },
    {
      label: "Pickup",
      value: dumpsterPickups[summary?.pickup ?? booking?.pickup]?.label,
      icon: <EventIcon />,
      hidden: !isDumpster,
    },
    {
      label: "Service Address",
      value: booking?.address.location,
      icon: <PinDropOutlinedIcon />,
    },
    // {
    //   label: "Dropoff Location",
    //   value: "Placeholder",
    //   icon: <PinDropOutlinedIcon />,
    //   hidden: isDumpster,
    // },
    {
      label: "Your Notes",
      value: booking?.isLockedPrice ? booking?.description : summary?.description,
      icon: <NoteOutlinedIcon />,
    },
    {
      label: "Min Vehicle Requirement",
      value: vehicleTypes[summary?.vehicle ?? booking?.vehicle]?.label,
      icon: <LocalShippingOutlinedIcon />,
      hidden: !!booking?.bookingBidId || isDumpster || isPowerWash,
    },
    {
      label: "Stairs (Flights)",
      value: summary?.stairs || booking?.stairs || "None",
      icon: <ClearAllOutlinedIcon />,
      hidden: isDumpster,
    },
    {
      label: "Dismantling (Items)",
      value: summary?.dismantling || booking?.dismantling || "None",
      icon: <BuildOutlinedIcon />,
      hidden: isDumpster,
    },
  ];

  const warriorItems = [
    {
      label: "Assigned Warrior",
      value: <WarriorContact warrior={warrior} />,
      icon: <VerifiedUser />,
      hidden: !warrior,
    },
  ];

  const doItAgainItems = [
    {
      label: "Service Date",
      value: dayjs(booking.date ?? "")?.format("MMM DD, YYYY hh:mm A"),
      icon: <EventIcon />,
    },
    {
      label: "Service Address",
      value: booking.address.location,
      icon: <PinDropOutlinedIcon />,
    },
    {
      label: "Your Notes",
      value: booking.description ?? "",
      icon: <NoteOutlinedIcon />,
    },
    {
      label: "Min Vehicle Requirement",
      value: vehicleTypes[booking.vehicle]?.label,
      icon: <LocalShippingOutlinedIcon />,
    },
    {
      label: "Stairs (Flights)",
      value: booking.stairs || 0,
      icon: <ClearAllOutlinedIcon />,
    },
    {
      label: "Dismantling (Items)",
      value: booking.dismantling || 0,
      icon: <BuildOutlinedIcon />,
    },
  ];

  const listedItems = isQuote ? quoteItems : doItAgainItems;
  const products = isQuote && !booking?.isLockedPrice ? summary?.junk : booking?.products;

  return (
    <div>
      <Typography className={classes.title}>Booking Details</Typography>
      <div className={classes.detailsContainer}>
        {listedItems
          .filter((i) => !i.hidden)
          .map((i, index) => (
            <ListedItem item={i} key={index} />
          ))}
        <Spacer x={20} />
        <Divider />
        <Spacer x={20} />
        {!isDumpster && (
          <Box className={classes.detailsGridContainer}>
            <div
              style={{
                display: "flex",
                justifyItems: "center",
                marginBottom: "15px",
              }}
            >
              <BallotOutlinedIcon />
              <div className={classes.detailsItemTitle}>Items expected</div>
            </div>
            <div>
              {products?.map((item, index) => (
                <Box key={index} className={classes.productItem}>
                  <Box className={classes.detailsItemValue}>{item.quantity}</Box>
                  <Box>
                    <Typography className={classes.detailsItemTitle}>{item.item.description}</Typography>
                    <div />
                    <Typography className={classes.detailsProductItemDescription}>{item.item.details}</Typography>
                  </Box>
                </Box>
              ))}
            </div>
          </Box>
        )}
        {/** TODO: DUMPSTER has different items structure **/}
        {isDumpster && (
          <Box className={classes.detailsGridContainer}>
            <div
              style={{
                display: "flex",
                justifyItems: "center",
                marginBottom: "15px",
              }}
            >
              <BallotOutlinedIcon />
              <div className={classes.detailsItemTitle}>Dumpsters</div>
            </div>
            {booking?.services?.map((item, index) => (
              <Box key={index} className={classes.productItem}>
                <Box className={classes.detailsItemValue}>{item.item.items.length}</Box>
                <Box>{item.item.description}</Box>
              </Box>
            ))}
          </Box>
        )}
        {/* {warrior && (
          <>
            <Spacer x={20} />
            <Divider />
            <Spacer x={20} />
            {warriorItems
              .filter((i) => !i.hidden)
              .map((i, index) => (
                <ListedItem item={i} key={index} />
              ))}
          </>
        )} */}
        <Spacer x={20} />
        <Divider />
        <Spacer x={20} />
        {!!booking?.taskImages?.length && (
          <>
            <div style={{ marginBottom: "15px" }}>Task Pictures</div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ImageGalleryCustom grid={true} images={booking.taskImages} />
              </Grid>
            </Grid>
            <Spacer x={20} />
            <Divider />
            <Spacer x={20} />
          </>
        )}
        {myPaymentMethod?.id && (
          <>
            <div
              style={{
                display: "flex",
                justifyItems: "center",
                marginBottom: "15px",
              }}
            >
              <LocalAtmOutlinedIcon />
              <div className={classes.detailsItemTitle}>Payment</div>
            </div>
            <Grid container spacing={2} style={{ marginLeft: "25px" }}>
              <Grid item xs={6} style={{ fontWeight: "bold" }}>
                Keep my previous payment method{" "}
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right", fontWeight: "bold" }}>
                {`${myPaymentMethod.card.brand} ending with •••• ${myPaymentMethod.card.last4}`}
              </Grid>
              <Grid item xs={12}>
                {booking.first_name} {booking.last_name || ""}
              </Grid>
              <Grid item xs={12}>
                {booking.email}
              </Grid>
              <Grid item xs={12}>
                {booking.phone_number}
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </div>
  );
};

export default DoItAgainPreview;
