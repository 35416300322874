import React from "react";

import Item from "./Item";

function CustomItems({ junk, className = null, isLockedPrice }) {
  return (
    <div className={className}>
      {junk?.map(({ item, quantity }) => (
        <Item
          key={item?.uid ?? item.id}
          tag={item.description}
          price={item.price}
          quantity={quantity}
          priceMultiplier={1}
          isLockedPrice={isLockedPrice}
        />
      ))}
    </div>
  );
}

export default CustomItems;
