import url from "@assets/complete_task.svg";
import history from "@history";
import { Button, Checkbox, Grid, makeStyles, Typography } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import tempDataConstants from "@store/constants/tempDataTypes";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { roles } from "../../constants/roles";
import { segmentTrack } from "../../helpers/segment";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "10000",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0, 0, 0, 0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "scroll",
  },
  agreementModal: {
    background: "white",
    width: "80%",
    overflow: "auto",
    maxHeight: "90vh",
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
      width: "100%",
    },
  },
  container: {
    fontSize: "17px",
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  termsWrapper: {
    borderRadius: "10px",
    backgroundColor: "#f1f1f1",
    padding: theme.spacing(3),
    fontSize: "17px",
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
      padding: theme.spacing(2, 1),
    },
  },
  paddingWrapper: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
    },
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  title: {
    fontSize: "25px",
    fontWeight: "bold",
    lineHeight: "1.1",
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      fontSize: "35px",
    },
  },
  backBtn: {
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.hover,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "25px",
    },
  },
  checkBox: {
    color: theme.palette.primary.main,
    "&checked": {
      color: theme.palette.primary.hover,
    },
  },
  acceptContinue: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    width: "250px",
    "&:hover": {
      backgroundColor: theme.palette.primary.hover,
    },
  },
  acceptTop: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  policyAgreement: { display: "flex", marginBottom: "10px" },
  acceptBottom: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    cursor: "pointer",
  },
}));

const AgreementModal = ({ setAgreement, agreementCheck, setAgreementCheck }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleAgreementAccept = () => {
    segmentTrack("Warrior Clicks Accept - Provider Agreement");
    setAgreement(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.agreementModal}>
        <Grid container className={classes.container}>
          <Grid item xs={12} md={6} className={classes.banner}>
            <div className={classes.paddingWrapper}>
              <div className={classes.titleWrapper}>
                <KeyboardBackspaceIcon
                  className={classes.backBtn}
                  fontSize="large"
                  onClick={() => {
                    dispatch({
                      type: tempDataConstants.SET_TEMP_USER,
                      userType: roles.customer.value,
                    });
                    history.goBack();
                  }}
                />
                <Typography className={classes.title}>Become a Provider</Typography>
              </div>
              Hello! Nice to have you here. Before you sign up as a provider, we would like to tell you a bit about the platform. Dimension is an
              on-demand waste management platform. Imagine &quot;Uber for Trash.&quot;
              <div style={{ height: "200px", margin: "30px 0px" }}>
                <div
                  style={{
                    backgroundImage: `url(${url})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    width: "100%",
                    height: "100%",
                  }}
                ></div>
              </div>
              <div className={classes.acceptTop}>
                <div className={classes.policyAgreement}>
                  <Checkbox
                    checked={agreementCheck}
                    color="primary"
                    onChange={() => setAgreementCheck(!agreementCheck)}
                    className={classes.checkBox}
                  />
                  <p>
                    I&apos;ve read and agree to the{" "}
                    <Link to="/provideragreementv2" target="_blank" className={classes.link}>
                      Provider Agreement
                    </Link>{" "}
                    and{" "}
                    <Link to="/service-provider-policy" target="_blank" className={classes.link}>
                      Service Provider Policy
                    </Link>
                  </p>
                </div>
                <Button variant="contained" disabled={!agreementCheck} onClick={handleAgreementAccept} className={classes.acceptContinue}>
                  Accept and continue
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className={classes.termsWrapper}>
            <ul>
              <li>As a Service Provider, you will do your best to provide a great service to customers.</li>
              <li>You agree to never re-negotiate service time and pricing with the customer.</li>
              <li>
                You agree to dump the junk/waste responsibly in a dump or a recycle center. You understand illegal dumping will not only result in us
                permanently banning you from the network, but will have other legal or financial consequences directly to you.
              </li>
              <li>
                You will only receive payout from us (not from customers) after each service completion. You will need to take and upload pictures to
                prove the completion.
              </li>
              <li>
                After your first service completion, we will instruct you to connect with Stripe Connect, through which we will dispense your payout
                to you. All you need is to connect your Stripe Connect with either a debit card or an ACH bank account. You understand your card/bank
                information is stored on Stripe safely - Dimension does not not have direct access to your card information. Stripe Connect is how
                companies like Instacart and Lyft pay their providers.
              </li>
              <li>
                You understand our support team is always there to help you succeed in every job! If you need help while using the website or doing
                the job, please do not feel shy to use the chat bot feature on this website, or email us at support@getdimension.com.
              </li>
            </ul>
          </Grid>
          <div className={classes.acceptBottom}>
            <div className={classes.policyAgreement}>
              <Checkbox checked={agreementCheck} color="primary" onChange={() => setAgreementCheck(!agreementCheck)} className={classes.checkBox} />
              <p>
                I&apos;ve read and agree to the{" "}
                <Link to="/provideragreementv2" target="_blank" className={classes.link}>
                  Provider Agreement
                </Link>{" "}
                and{" "}
                <Link to="/service-provider-policy" target="_blank" className={classes.link}>
                  Service Provider Policy
                </Link>
              </p>
            </div>
            <Button variant="contained" disabled={!agreementCheck} onClick={handleAgreementAccept} className={classes.acceptContinue}>
              Accept and continue
            </Button>
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default AgreementModal;
