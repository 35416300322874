export const DefaultFilter = {
  searchQuery: "",
  filterTaskType: null,
  filterDateValue: null,
  filterLocation: null,
};

export const DefaultDropdownState = {
  filterTaskType: false,
  filterDateValue: false,
  filterLocation: false,
};

export const DefaultPagination = { pageNumber: 1, pageSize: 10 };
