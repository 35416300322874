export const bidsStatus = {
  pending: {
    label: "Pending",
    value: "pending",
    color: "#459F65",
    backgroundColor: "#FFFFFF",
  },
  won: {
    label: "Won",
    value: "won",
    color: "#FFFFFF",
    backgroundColor: "#459F65",
  },
  lost: {
    label: "Lost",
    value: "lost",
    color: "#D63A3B",
    backgroundColor: "#FFFFFF",
  },
  cancelled: {
    label: "Cancelled",
    value: "cancelled",
    color: "#FFFFFF",
    backgroundColor: "#D63A3B",
  },
};
// The below discount must match the backend variable of the same name
export const bidCPDiscount = 15;
export const bidCPHours = 12;
