import { Task, Vehicle } from "./enums";
import { sortA_Z } from "@methods/sort";

export const services = {
  [Task.JunkRemoval]: {
    id: "",
    description: Task.JunkRemoval,
    price: 7000,
  },
  [Task.CardboardRemoval]: {
    id: "",
    description: Task.CardboardRemoval,
    price: 6000,
  },
  [Task.DumpsterRental]: {
    id: "",
    description: Task.DumpsterRental,
    price: 0,
  },
};

export const vehicles = {
  [Vehicle.SUV]: {
    id: "",
    description: Vehicle.SUV,
    price: 0,
  },
  [Vehicle.PickupTruck]: {
    id: "",
    description: Vehicle.PickupTruck,
    price: 1500,
  },
  [Vehicle.Truck]: {
    id: "",
    description: Vehicle.Truck,
    price: 3000,
  },
};

export const cardboardItems = sortA_Z(
  [
    {
      id: 1,
      description: "Cardboard (flattened)",
      isHelperNeeded: false,
      price: 200,
    },
    {
      id: 2,
      description: "Cardboard (unflattened)",
      isHelperNeeded: false,
      price: 300,
    },
    {
      id: 3,
      description: "Styrofoam/other packing materials (per cubic yard)",
      isHelperNeeded: false,
      price: 1000,
    },
    {
      id: 4,
      description: "Unlisted (send us a picture, additional fees may apply)",
      isHelperNeeded: false,
      price: 0,
    },
    {
      id: 5,
      description: "Wooden Pallet",
      isHelperNeeded: false,
      price: 300,
    },
  ],
  "description"
);

export const junkItems = sortA_Z(
  [
    {
      id: 0,
      description: "AC window unit",
      isHelperNeeded: false,
      price: 2000,
    },
    {
      id: 1,
      description: "Area Rug (<= 6'x9')",
      isHelperNeeded: false,
      price: 2000,
    },
    {
      id: 2,
      description: "Area Rug (> 6'x9')",
      isHelperNeeded: false,
      price: 3500,
    },
    {
      id: 3,
      description: "BBQ Grill",
      isHelperNeeded: false,
      price: 4000,
    },
    {
      id: 4,
      description: "Bedframe",
      isHelperNeeded: false,
      price: 2500,
    },
    {
      id: 5,
      description: "Bookcase",
      isHelperNeeded: true,
      price: 3000,
    },
    {
      id: 6,
      description: "Boxspring",
      isHelperNeeded: false,
      price: 1500,
    },
    {
      id: 7,
      description: "Cardboard (flattened)",
      isHelperNeeded: false,
      price: 200,
    },
    {
      id: 8,
      description: "Cardboard (unflattened)",
      isHelperNeeded: false,
      price: 300,
    },
    {
      id: 9,
      description: "Chair (non-plastic)",
      isHelperNeeded: false,
      price: 1500,
    },
    {
      id: 10,
      description: "Chair (plastic)",
      isHelperNeeded: false,
      price: 1000,
    },
    {
      id: 11,
      description: "Desk (Large)",
      isHelperNeeded: false,
      price: 6500,
    },
    {
      id: 12,
      description: "Desk (Medium)",
      isHelperNeeded: false,
      price: 3000,
    },
    {
      id: 13,
      description: "Desk (Small)",
      isHelperNeeded: false,
      price: 2000,
    },
    {
      id: 14,
      description: "Dishwasher",
      isHelperNeeded: true,
      price: 2500,
    },
    {
      id: 15,
      description: "Electric Fan",
      isHelperNeeded: false,
      price: 1500,
    },
    {
      id: 16,
      description: "Floor Lamp",
      isHelperNeeded: false,
      price: 1500,
    },
    {
      id: 17,
      description: "Fridge",
      isHelperNeeded: true,
      price: 5500,
    },
    {
      id: 18,
      description: "Futon",
      isHelperNeeded: false,
      price: 4000,
    },
    {
      id: 19,
      description: "Headboard",
      isHelperNeeded: false,
      price: 1000,
    },
    {
      id: 20,
      description: "Hot Tub",
      isHelperNeeded: true,
      price: 55000,
    },
    {
      id: 21,
      description: "Lawn Mower",
      isHelperNeeded: false,
      price: 3500,
    },
    {
      id: 22,
      description: "Mattress",
      isHelperNeeded: false,
      price: 2500,
    },
    {
      id: 23,
      description: "Mattress Topper",
      isHelperNeeded: false,
      price: 1500,
    },
    {
      id: 24,
      description: "Microwave",
      isHelperNeeded: false,
      price: 1000,
    },
    {
      id: 25,
      description: "Nightstand",
      isHelperNeeded: false,
      price: 1500,
    },
    {
      id: 26,
      description: "Ottoman",
      isHelperNeeded: false,
      price: 1500,
    },
    {
      id: 27,
      description: "Oven",
      isHelperNeeded: true,
      price: 3000,
    },
    {
      id: 28,
      description: "Piano",
      isHelperNeeded: true,
      price: 40000,
    },
    {
      id: 29,
      description: "Recliner Chair",
      isHelperNeeded: true,
      price: 2000,
    },
    {
      id: 30,
      description: "Sink",
      isHelperNeeded: false,
      price: 2000,
    },
    {
      id: 31,
      description: "Sofa bed",
      isHelperNeeded: true,
      price: 6000,
    },
    {
      id: 32,
      description: "Sofa Chair",
      isHelperNeeded: false,
      price: 1500,
    },
    {
      id: 33,
      description: "Stationary Bike",
      isHelperNeeded: false,
      price: 4000,
    },
    {
      id: 34,
      description: "Stereo",
      isHelperNeeded: false,
      price: 2000,
    },
    {
      id: 35,
      description: "Stove",
      isHelperNeeded: true,
      price: 3000,
    },
    {
      id: 36,
      description: "Surfboard",
      isHelperNeeded: false,
      price: 2000,
    },
    {
      id: 37,
      description: "Television",
      isHelperNeeded: true,
      price: 3000,
    },
    {
      id: 38,
      description: "Toilet",
      isHelperNeeded: false,
      price: 3000,
    },
    {
      id: 39,
      description: "Trampoline",
      isHelperNeeded: true,
      price: 10000,
    },
    {
      id: 40,
      description: "Trash Bags, 13 gallon",
      isHelperNeeded: false,
      price: 700,
    },
    {
      id: 41,
      description: "Treadmill",
      isHelperNeeded: true,
      price: 8500,
    },
    {
      id: 42,
      description: "Unlisted (send us a picture, additional fees may apply)",
      isHelperNeeded: false,
      price: 0,
    },
    {
      id: 43,
      description: "Vacuum",
      isHelperNeeded: false,
      price: 1000,
    },
    {
      id: 44,
      description: "Vanity Desk (Large)",
      isHelperNeeded: false,
      price: 6500,
    },
    {
      id: 45,
      description: "Vanity Desk (Medium)",
      isHelperNeeded: false,
      price: 3000,
    },
    {
      id: 46,
      description: "Vanity Desk (Small)",
      isHelperNeeded: false,
      price: 2000,
    },
    {
      id: 47,
      description: "Washer/Dryer",
      isHelperNeeded: false,
      price: 2500,
    },
    {
      id: 48,
      description: "Wooden Pallet",
      isHelperNeeded: false,
      price: 300,
    },
    {
      description: "Artificial Christmas Tree (10ft or less)",
      isHelperNeeded: false,
      price: 2000,
      id: 49,
    },
    {
      description: "Basketball Hoop (unattached to the ground)",
      isHelperNeeded: false,
      price: 3500,
      id: 50,
    },
    {
      description: "Bicycle (motorized)",
      isHelperNeeded: false,
      price: 29000,
      id: 51,
    },
    {
      description: "Bicycle (non-motorized)",
      isHelperNeeded: false,
      price: 1500,
      id: 52,
    },
    {
      description: "Cabinet",
      isHelperNeeded: false,
      price: 3000,
      id: 53,
    },
    {
      description: "Cat Tree",
      isHelperNeeded: false,
      price: 2000,
      id: 54,
    },
    {
      description: "Computer Monitor",
      isHelperNeeded: false,
      price: 2000,
      id: 55,
    },
    {
      description: "Couch (2 seater/Love seat)",
      isHelperNeeded: false,
      price: 2500,
      id: 56,
    },
    {
      description: "Couch (3 seater)",
      isHelperNeeded: true,
      price: 3500,
      id: 57,
    },
    {
      description: "Couch (4 seater)",
      isHelperNeeded: true,
      price: 4500,
      id: 58,
    },
    {
      description: "Couch (Sectional couch - per individual piece)",
      isHelperNeeded: true,
      price: 6500,
      id: 59,
    },
    {
      description: "Dishwasher",
      isHelperNeeded: false,
      price: 2500,
      id: 60,
    },
    {
      description: "Door (Double/French door - Exterior)",
      isHelperNeeded: false,
      price: 13000,
      id: 61,
    },
    {
      description: "Door (Double/French door - Interior)",
      isHelperNeeded: false,
      price: 8000,
      id: 62,
    },
    {
      description: "Door (Single door - Exterior)",
      isHelperNeeded: false,
      price: 6500,
      id: 63,
    },
    {
      description: "Door (Single door - Interior)",
      isHelperNeeded: false,
      price: 4000,
      id: 64,
    },
    {
      description: "Dresser",
      isHelperNeeded: false,
      price: 3000,
      id: 65,
    },
    {
      description: "Freezer (Must be empty)",
      isHelperNeeded: false,
      price: 4000,
      id: 66,
    },
    {
      description: "Fridge (Commercial-size - Must be empty)",
      isHelperNeeded: true,
      price: 26000,
      id: 67,
    },
    {
      description: "Fridge (Full-size - Must be empty)",
      isHelperNeeded: true,
      price: 5500,
      id: 68,
    },
    {
      description: "Fridge (Mini - Must be empty)",
      isHelperNeeded: false,
      price: 2000,
      id: 69,
    },
    {
      description: "Ironing Board",
      isHelperNeeded: false,
      price: 1500,
      id: 70,
    },
    {
      description: "Mirror",
      isHelperNeeded: false,
      price: 2000,
      id: 71,
    },
    {
      description: "Miscellaneous Small Items (Small sized home decor, small sized electronics, small sized toys, etc)",
      isHelperNeeded: false,
      price: 1000,
      id: 72,
    },
    {
      description: "Outdoor Umbrella",
      isHelperNeeded: false,
      price: 2000,
      id: 73,
    },
    {
      description: "Planter Pot",
      isHelperNeeded: false,
      price: 1500,
      id: 74,
    },
    {
      description: "Pool Table or commercial game room table",
      isHelperNeeded: false,
      price: 34000,
      id: 75,
    },
    {
      description: "Printer (Floor-standing)",
      isHelperNeeded: false,
      price: 9000,
      id: 76,
    },
    {
      description: "Printer (Small)",
      isHelperNeeded: false,
      price: 1000,
      id: 77,
    },
    {
      description: "Satellite Dish (<36 inches wide)",
      isHelperNeeded: false,
      price: 4000,
      id: 78,
    },
    {
      description: "Small-sized Ewaste",
      isHelperNeeded: false,
      price: 1000,
      id: 79,
    },
    {
      description: "Sofa",
      isHelperNeeded: false,
      price: 3000,
      id: 80,
    },
    {
      description: "Styrofoam/Packing Materials (per cubic yard)",
      isHelperNeeded: false,
      price: 1000,
      id: 81,
    },
    {
      description: "Table",
      isHelperNeeded: false,
      price: 3000,
      id: 82,
    },
    {
      description: "Tire",
      isHelperNeeded: false,
      price: 2000,
      id: 83,
    },
    {
      description: "Wheelchair (Motorized)",
      isHelperNeeded: false,
      price: 23000,
      id: 84,
    },
    {
      description: "Wheelchair (Non-motorized)",
      isHelperNeeded: false,
      price: 2500,
      id: 85,
    },
    {
      description: "Yard Debris (per cubic yard)",
      isHelperNeeded: false,
      price: 5000,
      id: 86,
    },
  ],
  "description"
);

export const stairsPrice = {
  value: 500,
};

export const dismantlingPrice = {
  value: 1500,
};
