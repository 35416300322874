import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  body: {
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    lineHeight: "2rem",
  },
  cancel: {
    borderRadius: 4,
    border: "1px solid #fff",
    color: theme.palette.error.main,
    height: "3rem",
    "&:hover": {
      background: "white",
      borderColor: "white",
      textDecoration: "underline",
    },
  },
  maybe: {
    borderRadius: 4,
    border: "1px solid #fff",
    background: theme.palette.primary.main,
    color: "#fff",
    height: "3rem",
  },
  confirm: {
    borderRadius: 4,
    border: "1px solid #fff",
    background: theme.palette.primary.main,
    color: "#fff",
    height: "3rem",
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
  actions: {
    paddingRight: 30,
    paddingBottom: 30,
  },
}));

const AlertDialog = ({
  title = "Alert",
  body = "",
  agreeBtnText = "Proceed",
  disagreeBtnTxt = "Cancel",
  footer = "",
  showMaybeBtn = false,
  maybeBtnTxt = "",
  handleMaybe = () => {},
  handleProceed = () => {},
  onClose = () => {},
  handleOutsideClick,
  hideDisagreeBtn = false,
  loading = false,
  ...props
}) => {
  const classes = useStyles();
  const [saving, setSaving] = useState(false);
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    setSaving(false);
    onClose();
  };

  const onProceed = () => {
    setSaving(true);
    handleProceed();
  };

  const onMaybe = () => {
    setSaving(true);
    handleMaybe();
  };

  const onOutsideClick = (e) => {
    if (typeof handleOutsideClick === "function") {
      handleOutsideClick();
      return;
    }
    handleClose();
  };

  return (
    <Dialog open={open} {...props} onClose={onOutsideClick} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle className={classes.header} id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent className={classes.body}>{body}</DialogContent>
      <DialogActions className={classes.actions}>
        {!hideDisagreeBtn && (
          <Button className={classes.cancel} onClick={handleClose} color="primary" variant="outlined" disabled={saving || loading}>
            {disagreeBtnTxt}
          </Button>
        )}
        {showMaybeBtn && (
          <Button className={classes.maybe} onClick={onMaybe} color="primary" variant="outlined" disabled={saving || loading}>
            {maybeBtnTxt}
          </Button>
        )}
        <Button className={classes.confirm} onClick={onProceed} color="primary" variant="outlined" autoFocus disabled={saving || loading}>
          {agreeBtnText}
        </Button>
      </DialogActions>
      {!!footer && (
        <DialogContent>
          <DialogContentText id="alert-dialog-footer" style={{ fontSize: "15px" }}>
            {footer}
          </DialogContentText>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default AlertDialog;
