import React from "react";
import { scrollToTop } from "../../../methods/scrollToTop";
import BottomCTA from "../LandingPage/BottomCTA";
import LearnMore from "./LearnMore";
import Pitch from "./Pitch";
import SustainabilityData from "./SustainabilityData";
import SustainabilityFeatures from "./SustainabilityFeatures";

function Sustainability() {
  scrollToTop();
  return (
    <>
      <Pitch />
      <SustainabilityData />
      <SustainabilityFeatures />
      <LearnMore />
      <BottomCTA />
    </>
  );
}

export default Sustainability;
