import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { bidCPDiscount } from "../../../../constants/bids";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    "& p": {
      color: theme.palette.primary.main,
      fontWeight: 600,
      [theme.breakpoints.up("md")]: {
        fontSize: 16,
      },
    },
  },
}));

export const InboundEarlyDiscount = () => {
  const discount = "Book in under 12 hours";
  const text = `${bidCPDiscount}% off`;
  const classes = useStyles();
  const discountTag = () => (
    <Box className={classes.root}>
      <Typography>{discount}</Typography>
      <Typography>{text}</Typography>
    </Box>
  );
  return discountTag();
};
