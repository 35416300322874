import alt_avatar from "@assets/alt_avatar.png";
import { avatarImageLimit } from "@constants/storage";
import { Card, Input, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import UploadModal from "./UploadModal";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "100px",
    [theme.breakpoints.down("sm")]: {
      top: "70px",
    },
  },
  avatar_wrapper: {
    height: "170px",
    width: "170px",
    borderRadius: "50%",
    display: "inline-block",
    margin: "5px",
    position: "relative",
    border: "10px solid white",
  },
  avatar: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "100%",
    height: "100%",
    maxHeight: "150px",
    maxWidth: "150px",
    borderRadius: "50%",
    position: "absolute",
    top: "10px",
    left: "10px",
  },
}));

const Avatar = ({ profile, readOnly }) => {
  const classes = useStyles();
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const profileUrl = profile.photoURL || alt_avatar;

  const handleFileChange = (e) => {
    e.preventDefault();
    const fileSizeLimitInBytes = 1048576 * avatarImageLimit; // 1 MB * avatarImageLimit

    if (e.target.files?.length > 0) {
      const fileSize = e.target.files[0]?.size;
      if (fileSize > fileSizeLimitInBytes) {
        setError(`File size exceeded ${avatarImageLimit} MB`);
        return;
      }
      setError(null);
      const reader = new FileReader();
      reader.onload = () => {
        setFile(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
      setShowProfileModal(true);
    }
  };

  return (
    <>
      {showProfileModal && <UploadModal file={file} setShowProfileModal={setShowProfileModal} profile={profile} />}
      <div className={classes.root}>
        <label htmlFor="uploadAvatar">
          <Card className={classes.avatar_wrapper}>
            <div className={classes.avatar} style={{ backgroundImage: `url(${profileUrl})` }}></div>
          </Card>
        </label>
        <Input
          disabled={readOnly}
          style={{ display: "none" }}
          type="file"
          accept="image/*"
          id="uploadAvatar"
          aria-describedby="my-helper-text"
          onChange={handleFileChange}
        />
        {error && <p style={{ color: "red" }}>{error}</p>}
      </div>
    </>
  );
};

Avatar.defaultProps = {
  profile: {},
  readOnly: false,
};

export default Avatar;
