import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { segmentTrack } from "../../../helpers/segment";
import { useSelector } from "react-redux";
import { checkFeatureFlag } from "../../../store/actions/featureFlags";

const useStyles = makeStyles((theme) => ({
  priceMatchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(255, 153, 0, 0.2)",
    borderRadius: "8px",
    padding: theme.spacing(1),
  },
  title: {
    fontWeight: "900",
    fontSize: "14px",
    color: "#DC8C13",
  },
  icon: {
    paddingLeft: "5px",
    fontWeight: "900",
    fontSize: "20px",
    color: "#DC8C13",
  },
  dialogContent: {
    margin: theme.spacing(4),
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#4F4F4F",
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(10, 0, 0, 0),
      padding: theme.spacing(4),
    },
  },
  button: {
    width: "200px",
    margin: "0 auto",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2),
    },
  },
}));

const PriceMatchModal = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const pricingZone = useSelector((state) => state.serviceLocations?.activeLocation?.pricingZone ?? null);
  const classes = useStyles();
  const [showPriceMatchTag, setShowPriceMatchTag] = useState(false);
  const [open, setOpen] = useState(false);

  /* Feature flag price-match-popup for showing the price quote match widget */
  const checkPriceMatchPopup = async () => {
    const data = {
      key: pricingZone,
      custom: {
        market: pricingZone,
      },
    };
    const isValid = await checkFeatureFlag("price-match-popup", data, false);
    if (isValid) {
      setShowPriceMatchTag(true);
    }
  };

  useEffect(() => {
    if (!pricingZone) return;
    checkPriceMatchPopup();
  }, [pricingZone]);

  return (
    <>
      {showPriceMatchTag && (
        <div
          className={classes.priceMatchContainer}
          onClick={() => {
            setOpen(true);
            segmentTrack("Price Tag Match Clicked");
          }}
        >
          <Typography className={classes.title}>Don&apos;t shop around. We price match!</Typography>
          <InfoOutlined className={classes.icon} />
        </div>
      )}
      <Dialog fullScreen={isMobile} open={open} onClose={() => setOpen(false)}>
        <DialogContent className={classes.dialogContent}>
          Check out now worry-free. If you find a qualified provider that quotes a cheaper price after you reserve a Trash Warrior, we will price
          match to that provider and refund the difference back to you.
          <br />
          <br />
          If you need a price match after you complete the checkout, simply send us a screenshot of the provider&apos;s price quote to
          support@getdimension.com. Qualified provider = any waste business with at least 5 reviews on Google or Yelp.
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" type="dense" className={classes.button} onClick={() => setOpen(false)}>
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PriceMatchModal;
