import { useImperativeHandle } from "react";

export default function useTriggerValidation(ref, setters, setter) {
  /* 
  making validate true for each fields 
  errors are visible only if validate is true.
  */
  const validate = () => {
    setters.forEach((setState) => setState((state) => ({ ...state, validation: { ...state.validation, validate: true } })));
  };

  const setField = (value) => {
    setter((prevState) => ({ ...prevState, value }));
  };

  useImperativeHandle(ref, () => ({
    trigger: validate,
    set: setField,
  }));
}
