export const aeSales = {
  "ben@getdimension.com": "Ben",
  "marshall@getdimension.com": "Marshall",
  "greg@getdimension.com": "Greg",
  "ehelbig@getdimension.com": "Eric",
  "sean@getdimension.com": "Sean",
  "frank@getdimension.com": "Frank",
  "jcubacub@getdimension.com": "John",
  "justin@getdimension.com": "Justin",
  "ben@trashwarrior.com": "Ben",
  "marshall@trashwarrior.com": "Marshall",
  "greg@trashwarrior.com": "Greg",
  "ehelbig@trashwarrior.com": "Eric",
  "sean@trashwarrior.com": "Sean",
  "frank@trashwarrior.com": "Frank",
  "jcubacub@trashwarrior.com": "John",
};
