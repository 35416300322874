import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import Star from "@material-ui/icons/Star";
import Person from "@material-ui/icons/Person";
import { bookingStatus } from "../../../../constants/booking";
import Paginations from "@shared/components/Paginations";
import BookingsList from "./BookingsList";
import BookingsTable from "./BookingsTable";
import BookingsCards from "./BookingsCards";

const useStyles = makeStyles((theme) => ({
  statusContainer: {
    background: "#fff",
    borderRadius: 4,
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    color: theme.palette.primary.main,
    fontWeight: 500,
    marginBottom: theme.spacing(-1),
    "& svg": {
      marginRight: theme.spacing(1),
      height: 20,
      width: 20,
    },
  },
  needsReviewStatus: {
    cursor: "pointer",
    "&:hover": {
      outline: "1px solid gray",
      color: "black",
    },
  },
  needsWarrior: {
    color: "gray",
  },
  paidContainer: {
    display: "grid",
    gridTemplateColumns: "max-content auto",
    alignItems: "center",
    columnGap: 8,
  },
  paidCircle: {
    height: 15,
    width: 15,
    borderRadius: 100,
    border: "2px solid #93DF8A",
    background: "#D3F2D0",
  },
  invoiceCircle: {
    height: 15,
    width: 15,
    borderRadius: 100,
    border: "2px solid #ccc",
    background: "#ddd",
  },
  invoiceText: {
    color: "#1A50CF",
  },
  paidText: {
    color: "#000",
  },
  statusReview: {},
  mobilePagination: {
    display: "flex",
    height: "55px",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    justifyContent: "center",
    alignItems: "center",
    bottom: 0,
    position: "sticky",
    border: "1px solid #e0e0e0",
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.primary.contrastText,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  desktopPagination: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  status: {
    paddingRight: "20px",
    width: "200px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "15px 0px",
      width: "100%",
    },
  },
}));
function Bookings({ bookings = [], showQuotes, view, setView, actions, authorizedDoItAgain }) {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:959px)");
  const [pageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const visitedData = (pageNumber - 1) * pageSize;

  const NeedsReview = ({ booking, actions }) => (
    <div className={`${classes.statusContainer} ${classes.needsReviewStatus}`} onClick={(e) => actions.handleStatusClick(e, booking)}>
      <Star />
      Click here to review
    </div>
  );
  const NeedsWarrior = () => (
    <div className={`${classes.statusContainer} ${classes.needsWarrior}`}>
      <Person />
      Matching Provider
    </div>
  );
  const WarriorMatched = () => (
    <div className={classes.statusContainer}>
      <Person />
      Provider Matched
    </div>
  );

  const getStatusAction = (booking) => {
    const isReviewPending = booking.status === "review";
    const isNeedsWarrior = booking.status === "booked";
    const isWarriorMatched = booking.status === "current";
    const isOther = !isReviewPending && !isNeedsWarrior && !isWarriorMatched;
    return (
      <>
        {isReviewPending && <NeedsReview booking={booking} actions={actions} />}
        {isWarriorMatched && <WarriorMatched />}
        {isNeedsWarrior && <NeedsWarrior />}
        {isOther && <div className={classes.statusReview}>{bookingStatus[booking.status]?.nextActionLabel}</div>}
      </>
    );
  };

  const isDoItAgain = (booking) => booking.status === bookingStatus.review.value || booking.status === bookingStatus.done.value;

  const getContent = () => {
    if (isMobile || view === "card") {
      return (
        <BookingsCards
          bookings={bookings}
          actions={actions}
          pagenationDetails={{ visitedData, pageSize }}
          getStatusAction={getStatusAction}
          showQuotes={showQuotes}
          doItAgainOverride={authorizedDoItAgain}
        />
      );
    }
    if (view === "list") {
      return (
        <BookingsList
          bookings={bookings}
          actions={actions}
          pagenationDetails={{ visitedData, pageSize }}
          getStatusAction={getStatusAction}
          setView={setView}
          showQuotes={showQuotes}
          doItAgainOverride={authorizedDoItAgain}
        />
      );
    }
    return <BookingsTable rows={bookings} showQuotes={showQuotes} actions={actions} isDoItAgain={isDoItAgain} getStatusAction={getStatusAction} />;
  };

  return (
    <div style={{ position: "relative", minHeight: "calc(100vh - 280px" }}>
      {getContent()}
      <div className={isMobile ? classes.mobilePagination : classes.desktopPagination}>
        <Paginations totalCount={bookings.length} pageNumber={pageNumber} pageSize={pageSize} setPageNumber={setPageNumber} size="large" />
      </div>
    </div>
  );
}

Bookings.defaultProps = {
  showQuotes: false,
};

Bookings.propTypes = {
  bookings: PropTypes.array,
  quotes: PropTypes.array,
  view: PropTypes.string,
  setView: PropTypes.func,
  actions: PropTypes.object,
  showQuotes: PropTypes.bool,
};

export default Bookings;
