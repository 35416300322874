import React from "react";
import history from "../../../history";
import { Step } from "../helpers/enums";

type AuthPopupContextProps = {
  resetBooking: Function;
};

function AuthPopupContext({ resetBooking }: AuthPopupContextProps) {
  return (
    <div style={{ textAlign: "center" }}>
      Please login to view your quote.
      <br />
      <br />
      Didn&apos;t mean to review this quote? Click{" "}
      <span
        style={{
          textDecoration: "underline",
          color: "#1E3B47",
          cursor: "pointer",
          fontWeight: "700",
        }}
        onClick={() => {
          resetBooking();
          history.push(`/booking`);
        }}>
        here
      </span>{" "}
      to make a new booking with us.
    </div>
  );
}

export default AuthPopupContext;
