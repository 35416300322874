import React from "react";

import Weee from "../../../assets/landing_page/clients/weee.svg";
import HolidayInn from "../../../assets/landing_page/clients/holiday-inn.svg";
import Amazon from "../../../assets/landing_page/clients/amazon.svg";
import Instacart from "../../../assets/landing_page/clients/instacart.svg";
import SRG from "../../../assets/landing_page/clients/srg.svg";
import ImperfectFoods from "../../../assets/landing_page/clients/imperfect-foods.svg";

import WeeeDark from "../../../assets/landing_page/clients/weee-dark.svg";
import HolidayInnDark from "../../../assets/landing_page/clients/holiday-inn-dark.svg";
import AmazonDark from "../../../assets/landing_page/clients/amazon-dark.svg";
import InstacartDark from "../../../assets/landing_page/clients/instacart-dark.svg";
import SRGDark from "../../../assets/landing_page/clients/srg-dark.svg";
import ImperfectFoodsDark from "../../../assets/landing_page/clients/imperfect-foods-dark.svg";
import TuftAndNeedle from "../../../assets/landing_page/clients/tuft-and-needle.svg";
import TuftAndNeedleDark from "../../../assets/landing_page/clients/tuft-and-needle-dark.svg";
import BurgerKing from "../../../assets/landing_page/clients/burgerking.svg";
import RiotGames from "../../../assets/landing_page/clients/riotgames.svg";
import Blick from "../../../assets/landing_page/clients/dickblick.svg";

import classes from "@css/components/website/Clients.module.css";
import { Typography, useMediaQuery } from "@material-ui/core";
import classNames from "classnames";

function Clients({ isB2b }: { isB2b?: boolean }) {
  const isMobile = useMediaQuery("(max-width:768px)");
  return (
    <section className={classNames(classes.container, { [classes.containerB2b]: isB2b })}>
      <div className={classes.mainContent}>
        <Typography className={classNames(classes.heading, { [classes.headingB2b]: isB2b })}>{isB2b ? "Partnerships" : "Proudly Serving"}</Typography>
        {isB2b && (
          <Typography className={classes.description}>
            Sustainability and superior service is achieved when we work together with the
            {!isMobile && <br />}
            brightest minds in the industry. Dimension employs a network of thousands of providers ready
            {!isMobile && <br />}
            to assist your trash, waste disposal and recycling needs.
          </Typography>
        )}
        <div className={classes.logoContainer}>
          <img className={classes.logo} src={isB2b ? WeeeDark : Weee} alt="Weee logo" />
          <img className={classes.logo} src={isB2b ? HolidayInnDark : HolidayInn} alt="Holiday Inn logo" />
          <img className={classes.logo} src={isB2b ? AmazonDark : Amazon} alt="Amazon logo" />
          <img className={classes.logo} src={isB2b ? InstacartDark : Instacart} alt="Instacart logo" />
          <img className={classes.logo} src={isB2b ? SRGDark : SRG} alt="Sares Regis Group logo" />
          <img className={classes.logo} src={isB2b ? ImperfectFoodsDark : ImperfectFoods} alt="Amazon logo" />
          <img className={classes.logo} src={isB2b ? TuftAndNeedleDark : TuftAndNeedle} alt="Tuft And Needle logo" />
          <img className={isB2b ? classes.logoNormal : classes.logoDark} src={BurgerKing} alt="Burger King" />
          <img className={isB2b ? classes.logoNormal : classes.logoDark} src={RiotGames} alt="Riot Games" />
          <img className={isB2b ? classes.logoNormal : classes.logoDark} src={Blick} alt="Blick" />
        </div>
      </div>
    </section>
  );
}

export default Clients;
