import React, { useState, useEffect } from "react";
import { Box, makeStyles, Radio, RadioGroup, FormControlLabel, Grid } from "@material-ui/core";
import Section from "@booking/components/Section";
import validators from "@booking/helpers/validators";
import { Input } from "@booking/helpers/enums";

import Counter from "@shared/components/Counter";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  description: {
    flex: 1,
    display: "flex",
    alignItems: "center",
  },
  notes: {
    color: "gray",
    fontSize: "15px",
    fontWeight: "400",
    marginLeft: "5px",
  },
}));

const DismantlingField = ({ field, onChange }) => {
  const classes = useStyles();
  const { value } = field;

  const [dismantlingUsed, setDismantlingUsed] = useState(!!value);
  const [dismantling, setDismantling] = useState(value);

  const handleDismantlingChange = (value) => {
    setDismantling(value);
    onChange({ value });
  };

  useEffect(() => {
    onChange({ validation: validators[Input.Dismantling](field) });
  }, [value]);

  return (
    <Box className={classes.root}>
      <Section title="Dismantling" description="Does one of your items require dismantling?">
        <Grid container spacing={2} alignItems="flex-start" style={{ height: "65px" }}>
          <Grid item xs={6}>
            <RadioGroup
              row
              aria-label="stairs"
              value={dismantlingUsed}
              onChange={(e) => {
                setDismantlingUsed(e.target.value === "true");
                if (e.target.value === "true") {
                  handleDismantlingChange(1);
                } else {
                  handleDismantlingChange(0);
                }
              }}>
              <FormControlLabel className={classes.radioControl} control={<Radio color="primary" />} label="Yes" value={true} />
              <FormControlLabel className={classes.radioControl} control={<Radio color="primary" />} label="No" value={false} />
            </RadioGroup>
          </Grid>
          {dismantlingUsed && (
            <Grid item xs={6}>
              <Counter value={dismantling} minValue={1} maxValue={50} onChange={handleDismantlingChange} />
              <Box className={classes.notes}> x $15 per item</Box>
            </Grid>
          )}
        </Grid>
      </Section>
    </Box>
  );
};

export default DismantlingField;
