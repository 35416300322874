import React from "react";
import { Button, Typography } from "@material-ui/core";
import classes from "@css/components/website/CaseStudies.module.css";

import Amazon from "../../../assets/landing_page/clients/amazon-color.svg";
import Instacart from "../../../assets/landing_page/clients/instacart-color.svg";

import AmazonCase from "../../../assets/landing_page/amazon-case.jpg";
import InstacartCase from "../../../assets/landing_page/instacart-case.jpg";
import { useHistory } from "react-router";
import { segmentTrack } from "../../../helpers/segment";

function CaseStudies() {
  const history = useHistory();
  const handleClick = (tracker: string, url: string) => {
    segmentTrack("Click", {
      button: tracker,
    });
    history.push(url);
  };
  return (
    <section className={classes.mainContainer}>
      <div className={classes.mainContent}>
        <Typography className={classes.heading}>Case Studies</Typography>
        <div className={classes.studiesContainer}>
          <div className={classes.case}>
            <img className={classes.caseImage} src={AmazonCase} alt="Amazon company image" />
            <img className={classes.brandLogoAmazon} src={Amazon} id="amazon-logo" alt="amazon logo" />
            <Typography className={classes.description}>
              We provide on-demand waste and cardboard removal for a number of Amazon warehouses, providing an efficient, reliable solution to their
              unpredictable loads.
            </Typography>
            <Button disableRipple className={classes.readMoreButton} onClick={() => handleClick("Read More - Amazon", "/amazon_case_study")}>
              Read More
            </Button>
          </div>
          <div className={classes.case}>
            <img className={classes.caseImage} src={InstacartCase} alt="instacart company image" />
            <img className={classes.brandLogo} src={Instacart} alt="instacart logo" />
            <Typography className={classes.description}>
              We provide Instacart with customized solutions to ensure that their nationwide warehouses each receive a service that’s tailor-built to
              fit their needs.
            </Typography>
            <Button disableRipple className={classes.readMoreButton} onClick={() => handleClick("Read More - Instacart", "/case-studies/instacart")}>
              Read More
            </Button>
          </div>
        </div>
        <Button disableRipple className={classes.moreStudiesButton} variant="contained" onClick={() => handleClick("Read More - Case Studies", "/case-studies")}>
          More Case Studies
        </Button>
      </div>
    </section>
  );
}

export default CaseStudies;
