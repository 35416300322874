import React from "react";
import { Kustomer } from "../helpers/kustomer";

export const KustomerLauncher = ({ children, element, id, onClick }: any) => {

  const showKustomer = () => {
    Kustomer.open();
  };

  return (
    <React.Fragment>
      {element === "div" ? (
        <div onClick={onClick ?? showKustomer} id={id}>
          {children}
        </div>
      ) : (
        <span style={{ cursor: "pointer" }} onClick={onClick ?? showKustomer}>
          {children}
        </span>
      )}
    </React.Fragment>
  );
};
