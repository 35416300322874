import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { taskTypes } from "../../../../constants/booking";
import { Grid, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import { connect, useSelector } from "react-redux";
import { fetchMyBookings } from "@store/actions/booking";
import { AccountBalanceWalletOutlined } from "@material-ui/icons";
import PayoutTable from "./PayoutTable";
import { fetchMyBonusPayouts } from "../../../../store/actions/bonuses";
import NoData from "@shared/components/NoData";
import Paginations from "@shared/components/Paginations";

const DefaultPagination = { pageNumber: 1, pageSize: 10 };

const useStyles = makeStyles((theme) => ({
  mobilePagination: {
    display: "flex",
    width: "100%",
    height: "55px",
    boxShadow: "0px 4px 20px rgb(0 0 0 / 15%)",
    justifyContent: "center",
    alignItems: "center",
    bottom: 0,
    position: "sticky",
    border: "1px solid #e0e0e0",
    margin: theme.spacing(3, 0),
    backgroundColor: theme.palette.primary.contrastText,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  desktopPagination: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const Payout = ({ bookings, fetchMyBookings, firebase }) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const bonusPayments = useSelector((state) => state.auth.bonuses);
  const [paginationDetails, setPaginationDetails] = useState(DefaultPagination);
  const { pageNumber, pageSize } = paginationDetails;
  const visitedData = (pageNumber - 1) * pageSize;
  const setPageNumber = (newPage) => setPaginationDetails({ ...paginationDetails, pageNumber: newPage });
  const getPayouts = () => {
    const paidBookings = bookings.filter((booking) => booking.status && booking.warriorPayoutStatus === "done");
    let bookingPayouts = [];
    paidBookings?.map((book) => {
      const pays = book.warriorPayoutHistory;
      pays.map((pay) => {
        bookingPayouts.push({
          ...pay,
          orderNumber: book.orderNumber,
          task: book.task,
          bookingDate: book.date,
          date: pay.date ?? book.date,
        });
      });
    });
    return bookingPayouts;
  };
  const payouts = getPayouts();

  const modifiedPayout =
    payouts?.map((payout) => ({
      id: payout.id,
      date: dayjs(payout.date).format("MM/DD"),
      description: taskTypes[payout.task]?.label + " " + payout.orderNumber + " on " + dayjs(payout.bookingDate).format("MM/DD"),
      amount: (payout.amount / 100)?.toFixed(),
      fullDate: dayjs(payout.date),
    })) || [];

  const modifiedRewards =
    bonusPayments?.map((reward) => ({
      id: reward.id,
      date: dayjs(reward.createdAt).format("MM/DD"),
      description: reward.bonusName + " " + reward.description,
      amount: (reward.amount / 100)?.toFixed(),
      fullDate: dayjs(reward.createdAt),
    })) || [];

  const totalPayouts = modifiedPayout.concat(modifiedRewards).sort((a, b) => (a.fullDate > b.fullDate ? -1 : 1));
  const slicedPayouts = totalPayouts?.slice(visitedData, visitedData + pageSize);
  useEffect(() => {
    if (firebase.auth.uid !== undefined) {
      fetchMyBookings(firebase.auth.uid);
    }
  }, []);

  return (
    <Grid container style={{ width: "100%" }}>
      <Grid container>
        <Grid item xs={2} sm={1}>
          <AccountBalanceWalletOutlined fontSize="large" />
        </Grid>
        <Grid item xs={10} sm={11}>
          <Typography style={{ fontSize: "20px" }}>Payout history</Typography>
        </Grid>
      </Grid>
      <Grid container>
        {totalPayouts.length ? (
          <>
            <PayoutTable payout={slicedPayouts} />
            <div className={isDesktop ? classes.desktopPagination : classes.mobilePagination}>
              <Paginations totalCount={totalPayouts.length} setPageNumber={setPageNumber} {...paginationDetails} />
            </div>
          </>
        ) : (
          <NoData displayText="No Payouts Found!" />
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  bookings: state.booking.myBookings,
  firebase: state.firebase,
});

Payout.propTypes = {
  bookings: PropTypes.array,
  fetchMyBookings: PropTypes.func,
  firebase: PropTypes.object,
};

export default connect(mapStateToProps, { fetchMyBookings })(Payout);
