import React, { useRef, useState, useEffect } from "react";
import { makeStyles, Box, Card, CardContent, Grid, Typography, TextField, Button, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { useSelector } from "react-redux";
import Input from "react-phone-number-input/input";

import alt_avatar from "@assets/alt_avatar.png";
import Spacer from "@shared/components/Spacer";
import PhoneField from "@shared/components/PhoneField";
import ConfirmUpdateModal from "../../AccountPage/components/ConfirmUpdateModal";

import { getAuthProvider } from "@helpers/auth";
import { updateUserProfile } from "@helpers/auth";
import { scrollToTop } from "../../../methods/scrollToTop";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
      padding: theme.spacing(3),
    },
  },
  avatarWrapper: {
    height: "100px",
    width: "100px",
    borderRadius: "50%",
  },
  avatar: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "right",
    width: "100%",
    height: "100%",
    border: "5px solid white",
    borderRadius: "50%",
    backgroundImage: (props) => `url(${props.url || alt_avatar})`,
  },
  avatarDetails: {
    padding: "10px 30px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      textAlign: "center",
      padding: "10px 5px",
    },
  },
  editBtns: {
    height: "50px",
    "&:hover": {
      backgroundColor: theme.palette.primary.contrastText,
    },
    float: "right",
    marginBottom: "10px",
  },
}));

function ConfirmAccount({ next }) {
  const user = useSelector((state) => state.firebase.profile);
  const classes = useStyles({ url: user?.photoURL });
  const authFirebase = useSelector((state) => state.firebase.auth);
  const lastAuthProvider = !authFirebase.isEmpty && getAuthProvider(authFirebase);

  const emailRef = useRef(null);
  const phoneRef = useRef(null);

  const [email, setEmail] = useState(user?.email);
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber);
  const [emailDisabled, setEmailDisabled] = useState(true);
  const [phoneDisabled, setPhoneDisabled] = useState(true);
  const [confirmModal, setConfirmModal] = useState(false);
  const [oldPassword, setOldPassword] = useState("");

  const handleNextConfirmBtn = () => {
    if (user?.email !== email || user?.phoneNumber !== phoneNumber) {
      if (user?.email !== email) {
        setConfirmModal(true);
      } else {
        handleUpdateDetails();
      }
    } else {
      next();
    }
  };

  const handleUpdateDetails = async () => {
    const newProfile = {
      phoneNumber,
    };
    const credentials = {
      currentPassword: oldPassword,
      newEmail: email,
    };
    await updateUserProfile(newProfile, credentials);
    next();
  };

  scrollToTop();

  return (
    <>
      {confirmModal && (
        <ConfirmUpdateModal
          setConfirmModal={setConfirmModal}
          oldPassword={oldPassword}
          setOldPassword={setOldPassword}
          handleUpdateDetails={handleUpdateDetails}
        />
      )}
      <Card raised className={classes.root}>
        <CardContent>
          <Grid container>
            <Grid item xs={4}>
              <Box className={classes.avatarDetails}>
                <Card className={classes.avatarWrapper}>
                  <div className={classes.avatar} />
                </Card>
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Box className={classes.avatarDetails}>
                <Typography
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    marginTop: "20px",
                    padding: "5px",
                    overflowWrap: "anywhere",
                  }}>
                  {user?.fullName}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Spacer x={40} />

          <TextField
            name="email"
            label="Email"
            variant="outlined"
            inputRef={emailRef}
            disabled={emailDisabled}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            style={{ width: "100%" }}
          />

          {lastAuthProvider.allowEdits ? (
            <Button
              className={classes.editBtns}
              color="primary"
              onClick={async () => {
                await setEmailDisabled(false);
                emailRef.current.focus();
              }}>
              Edit email
            </Button>
          ) : (
            <Spacer x={30} />
          )}

          <Input
            placeholder="Enter phone number"
            defaultCountry="US"
            label="Phone Number"
            variant="outlined"
            fullWidth
            disabled={phoneDisabled}
            ref={phoneRef}
            value={phoneNumber}
            onChange={(value) => {
              setPhoneNumber(value);
            }}
            inputComponent={PhoneField}
          />

          <Button
            className={classes.editBtns}
            color="primary"
            onClick={async () => {
              await setPhoneDisabled(false);
              phoneRef.current.focus();
            }}>
            Edit phone
          </Button>

          <Spacer x={60} />

          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <Typography>Stripe Connect Account Status</Typography>
              <RadioGroup
                aria-label="stripe-connect"
                name="stripe-connect"
                value={!!user.paymentMethod}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
                onChange={() => {}}>
                <FormControlLabel value={true} control={<Radio color="primary" disabled={true} />} label={"Yes"} />
                <FormControlLabel value={false} control={<Radio color="primary" disabled={true} />} label={"No"} />
              </RadioGroup>
            </Grid>
            <Grid item xs={12} md={5}>
              <Button color="primary" variant="contained" style={{ width: "100%" }} onClick={handleNextConfirmBtn}>
                Confirm
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default ConfirmAccount;
