import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import validators from "@booking/helpers/validators";
import { isError, errorCode } from "@booking/helpers/utils";
import { Input, Task } from "@booking/helpers/enums";
import { taskTypes } from "../../../../../constants/booking";

import Section from "@booking/components/Section";
import useStyles from "../useStyles";

import { segmentTrack } from "@helpers/segment";

import ProductInput from "./ProductInput";
import { useAxiosGet } from "../../../../../axios";
import { toastr } from "react-redux-toastr";

const emptyValue = "";

const JunkPicker = ({ task, field, onChange, isQuote }) => {
  const styles = useStyles();
  const [inputValue, setInputValue] = useState(emptyValue);
  const [isFetchingItemPrice, setIsFetchingItemPrice] = useState(false);
  const dropDownItems = useSelector((state) => state.dropDownItems.items);

  const { value, validation } = field;

  useEffect(() => {
    onChange({ validation: validators[Input.Junk](field) });
  }, [value]);

  const selectedItems = value.reduce((result, { item }) => result.add(item.id), new Set());

  const items = {
    [Task.JunkRemoval]: dropDownItems.filter((item) => item.serviceTypes.includes(taskTypes["junk-removal"].label)),
    [Task.CardboardRemoval]: dropDownItems.filter((item) => item.serviceTypes.includes(taskTypes["cardboard-removal"].label)),
  };
  const selectableItems = items[task].filter((item) => !selectedItems.has(item.id));

  const handleChange = async (_event, item) => {
    /* Segment Analytics */
    segmentTrack("Drop down items chosen", {
      product: item?.description ?? "undefined",
    });
    segmentTrack("SelfServeBookingCartStarted", {
      product: item?.description ?? "undefined",
    });
    segmentTrack("Additional Info Requested");

    if (item) {
      const selectedItem = {
        id: item.id,
        description: item.description,
        price: item.price,
        isHelperNeeded: item.isHelperNeeded,
        weight: item.weight ?? 0,
        wasteStream: item.wasteStream,
      };
      setInputValue(emptyValue);
      const newValue = [
        ...value,
        {
          quantity: 1,
          item: selectedItem,
          totalWeight: item.weight * 1,
        },
      ];
      onChange({ value: newValue });
      handleProductPriceUpdate(selectedItem, newValue);
    }
  };

  const handleInputChange = (_event, nextInputValue) => {
    setInputValue(nextInputValue);
  };

  const getOptionLabel = (item) => item.description;

  const removeProduct = (product) => {
    onChange({
      value: value.filter(({ item }) => item.id !== product.item.id),
    });
    segmentTrack("Drop down items removed", {
      product: product?.item?.description ?? "undefined",
    });
  };

  const updateProduct = ({ item, quantity }) => {
    const index = value.findIndex((product) => product.item.id === item.id);

    if (index !== -1) {
      const nextValue = [...value];

      // It's safe to mutate array.
      const totalWeight = item.weight * quantity;
      nextValue[index] = { item, quantity, totalWeight };

      onChange({
        value: nextValue,
      });
    }
  };

  const handleProductUpdate = (product) => (quantity) => {
    if (quantity > 0) {
      updateProduct({ ...product, quantity });
    } else {
      removeProduct(product);
    }
  };

  const handleProductPriceUpdate = async (selectedItem, value) => {
    setIsFetchingItemPrice(selectedItem.id);
    const itemResponse = await useAxiosGet(`/config/item/${selectedItem.id}`);

    if (!itemResponse.data.success) {
      toastr.warning(`Error getting item price for ${item.description}`);
      return;
    }

    const itemPrice = itemResponse.data.price;

    const index = value.findIndex((product) => product.item.id === selectedItem.id);
    if (index !== -1) {
      const nextValue = [...value];
      nextValue[index] = {
        ...nextValue[index],
        item: {
          ...nextValue[index].item,
          price: itemPrice,
        },
      };

      onChange({ value: nextValue });
    }
    setIsFetchingItemPrice(false);
  };

  const normalCopy = "Please select all of the items and the number of items that need to be removed for your booking";
  const quoteCopy = "These items were picked by Dimension according to your request. You can add or remove items if needed.";

  return (
    <Box className={styles.field}>
      <Section
        title={isQuote ? quoteCopy : normalCopy}
        description={`Please note, our providers will ONLY pick up the items that you selected and paid for. `}
        subDescription={`If you have unlisted items, we will reach out to you for an updated price quote after reviewing your booking. If you do not agree with the final quote, we will refund you promptly, but please note there will be a 10% service fee.`}
        width="75%"
      >
        <Autocomplete
          fullWidth
          value={null}
          inputValue={inputValue}
          onChange={handleChange}
          onInputChange={handleInputChange}
          options={selectableItems}
          getOptionLabel={getOptionLabel}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Type or select here *"
              variant="outlined"
              error={isError(validation)}
              helperText={errorCode(validation)}
            />
          )}
        />
        {value.map((product) => (
          <ProductInput
            key={product.item.id}
            priceLoader={product.item.id === isFetchingItemPrice}
            item={product.item}
            quantity={product.quantity}
            onChange={handleProductUpdate(product)}
          />
        ))}
      </Section>
      <br />
    </Box>
  );
};

export default React.memo(JunkPicker);
