import dayjs from "dayjs";
import { toastr } from "react-redux-toastr";

import { store } from "@index";
import { Frequency, Key, Task, Input } from "./enums";
import validators from "@booking/helpers/validators";
import { services, vehicles, stairsPrice, dismantlingPrice } from "./apiData";

import minMax from "dayjs/plugin/minMax";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { discountPrice, applicableTasksforDiscount } from "@constants/pricing";
import resetDate from "@shared/utils/resetDate";
import { sameDayBookingPrice, taskTypes } from "../../../constants/booking";
import { rentalTasks, removalQuoteTasks as removalTasks } from "./constants";
import { constructDumpsterSizes, Pickup } from "../../../constants/dumpsters";

// Dayjs Configuration
dayjs.extend(minMax);
dayjs.extend(localizedFormat);

export const isFormValid = (form, fields) => {
  const keys = Object.keys(form);

  const reducer = (result, key) => {
    return result && !!form[key]?.[Key.Validation]?.[Key.isValid];
  };

  return keys.reduce(reducer, true);
};

export const updateField = (field, value) => ({
  ...(field || {}),
  ...value,
});

export const triggerValidation = (setters) => {
  setters.forEach((setState) => setState((prevState) => ({ ...prevState, validation: { ...prevState.validation, validate: true } })));
};

export const isError = (validation) => {
  if (validation?.validate && typeof validation?.isValid == "boolean") {
    return validation.isValid === false;
  }
  return false;
};

export const errorCode = (validation) => {
  return validation?.validate && validation?.error?.code;
};

export const convertTask = (task) => {
  switch (task) {
    case Task.CardboardRemoval:
      return Task.CardboardRemoval;
    case Task.JunkRemoval:
      return Task.JunkRemoval;
    default:
      return null;
  }
};

export const convertFrequency = (frequency) => {
  switch (frequency) {
    case Frequency.Once:
      return Frequency.Once;
    case Frequency.Weekly:
      return Frequency.Weekly;
    case Frequency.Monthly:
      return Frequency.Monthly;
    default:
      return null;
  }
};

export const calculatePickupDate = (pickup, date, dropOffDate) => {
  const validation = { validation: validators[Input.PickupDate](date) };
  const dropOffDateValue = dropOffDate?.value;
  switch (pickup.value) {
    case Pickup.NextBooking:
      return {
        value: dayjs(dropOffDateValue).add(20, "day"),
        ...validation,
      };
    case Pickup.ThreeDays:
      return {
        value: dayjs(dropOffDateValue).add(3, "day"),
        ...validation,
      };
    case Pickup.SevenDays:
      return {
        value: dayjs(dropOffDateValue).add(1, "week"),
        ...validation,
      };
    default:
      return date;
  }
};

export const calculatePickupTime = (pickup, time, dropOffTime) => {
  const validation = { validation: validators[Input.PickupTime](time) };
  switch (pickup.value) {
    case Pickup.NextBooking:
    case Pickup.ThreeDays:
    case Pickup.SevenDays:
      return { value: { ...(dropOffTime?.value || { key: "0-0", hour: 0, minute: 0 }) }, ...validation };
    default:
      return time;
  }
};

export const getSoonestBusinessDate = () => {
  const { businessHours, businessTime } = store.getState().config;
  const now = dayjs();

  const earliestToday = resetDate(now).set("hour", businessHours.earliest.hour).set("minute", businessHours.earliest.minute);

  const latestToday = resetDate(now).set("hour", businessHours.latest.hour).set("minute", businessHours.latest.minute);

  // if it's during business hours but there's no time today
  const hoursTillClosing = latestToday.diff(now, "hour");

  let earliest = earliestToday.add(1, "day").add(Math.ceil(businessTime.hour - hoursTillClosing), "hour");

  // if there's time for a booking today
  if (now.isBefore(latestToday.subtract(businessTime.hour, "hour"))) {
    const date = dayjs.max(now, earliestToday);
    earliest = date
      .add(businessTime.hour + 1, "hour")
      .add(businessTime.minute, "minute")
      .set("minute", 0)
      .set("second", 0)
      .set("millisecond", 0);

    return earliest;
  }

  // if it's after business hours
  if (now.isAfter(latestToday)) {
    earliest = earliestToday
      .add(1, "day")
      .add(businessTime.hour + 1, "hour")
      .add(businessTime.minute, "minute");

    return earliest;
  }

  return earliest;
};

export const calculateMaterialPercentage = (materials) => {
  const percentages = [0]; // material percentages
  materials?.forEach(({ percentage }) => {
    percentages.push(percentage);
  });
  return Math.max(...percentages);
};

export const calculateNormalItemsPrices = (items) => {
  if (!items) {
    return 0;
  }

  let total = 0;
  items
    .filter((item) => !item.item.isCustom)
    .forEach(({ item, quantity }) => {
      total += item.price * quantity;
    });

  return total;
};

export const calculateCustomItemsPrices = (items) => {
  if (!items) {
    return 0;
  }

  let total = 0;
  items
    .filter((item) => item.item.isCustom)
    .forEach(({ item, quantity }) => {
      total += item.price * quantity;
    });

  return total;
};

export const getServicePrice = (task) => {
  if (!task) return 0;
  return services[task]?.price || 0;
};

export const getVehiclePrice = (vehicle) => {
  if (!vehicle) return 0;
  return vehicles[vehicle]?.price || 0;
};

export const calculateTotalPrice = ({ task, vehicle, junk, dumpster, isServiceFeeWaived, isVehicleFeeWaived }) => {
  const taskPrice = isServiceFeeWaived ? 0 : services[task]?.price;

  // junk,cardboard removal
  const calculateRemovalTotal = () => {
    let removalTotal = taskPrice + (isVehicleFeeWaived ? 0 : vehicles[vehicle]?.price ?? 0);

    junk
      ?.filter((i) => !i.item.isCustom)
      ?.forEach(({ item, quantity }) => {
        removalTotal += item.price * quantity;
      });

    return removalTotal;
  };

  // dumpster rental
  const calculateRentalTotal = () => {
    let rentalTotal = isServiceFeeWaived ? 0 : taskPrice;
    dumpster?.forEach(({ item: { price, isLockedPriceItem, items } }) => {
      const itemPrice = price;
      const maxPercentage = isLockedPriceItem ? 0 : calculateMaterialPercentage(items);
      rentalTotal += itemPrice + (itemPrice * maxPercentage) / 100;
    });
    return rentalTotal;
  };

  // total price
  const total = {
    [Task.JunkRemoval]: calculateRemovalTotal,
    [Task.CardboardRemoval]: calculateRemovalTotal,
    [Task.DumpsterRental]: calculateRentalTotal,
  }[task];

  return total?.();
};

export const formatPrice = (price = 0) => `$ ${(price / 100).toFixed(2)}`;

export const applyBulkDiscount = ({ total, task }) => {
  let discountedTotal = total;
  if (applicableTasksforDiscount.includes(task)) {
    const applyDiscount = (discount) => (total * discount) / 100;

    const discountAmount = {
      [discountedTotal >= discountPrice.$200 && discountedTotal < discountPrice.$500]: applyDiscount(10),
      [discountedTotal >= discountPrice.$500]: applyDiscount(20),
    }.true;

    if (discountAmount) {
      discountedTotal -= discountAmount;
    }
  }
  return discountedTotal;
};

export const calculateStairsDismantlingPrice = ({ stairs, dismantling }) => {
  let total = 0;
  if (stairs) {
    total += stairs * stairsPrice.value;
  }
  if (dismantling) {
    total += dismantling * dismantlingPrice.value;
  }
  return total;
};

export const applyCouponDiscount = ({ total, selectedCoupon }) => {
  let discountedPrice = total;

  if (selectedCoupon) {
    let discount = selectedCoupon.value * 100;
    if (selectedCoupon.type === "percentage") {
      discount = (selectedCoupon.value / 100) * total;
    }
    discountedPrice = Math.max(discountedPrice - discount, 100);
  }
  return discountedPrice;
};

const getLowestPrice = (items) => (items.length ? Math.min(...items.map((item) => item.price)) : 0);

export const getLowestItemPrice = (task, lowestPrices, dumpsterBasePrice) =>
  ({
    [Task.JunkRemoval]: (lowestPrices?.[task] ?? 0) + getLowestPrice(Object.values(vehicles)),
    [Task.CardboardRemoval]: (lowestPrices?.[task] ?? 0) + getLowestPrice(Object.values(vehicles)),
    [Task.DumpsterRental]: getLowestPrice(constructDumpsterSizes(1, dumpsterBasePrice)), // 1 is the minimum dumpster pickup dropff diff in days
  }[task]);

export const calculateTotalBookingPrice = (
  task,
  vehicle,
  junk,
  stairs,
  dismantling,
  dumpster,
  isServiceFeeWaived,
  isVehicleFeeWaived,
  priceMultiplier,
  selectedCoupon
) => {
  let finalTotal = 0;

  const cleanTotal = calculateTotalPrice({ task, vehicle, junk, dumpster, isServiceFeeWaived, isVehicleFeeWaived }); // calculate raw total

  const stairsDismantlingTotal = calculateStairsDismantlingPrice({
    stairs,
    dismantling,
  }); // stairs and dismantling total

  const customItemTotal = calculateCustomItemsPrices(junk); // calculate the custom items separately

  const t1 = cleanTotal * (priceMultiplier / 100); // apply location multipliers
  const t2 = applyBulkDiscount({ total: t1, task }); // apply bulk discount
  const t3 = t2 + stairsDismantlingTotal;
  const t4 = applyCouponDiscount({ total: t3, selectedCoupon }); // apply coupon
  const t5 = t4 + customItemTotal; // apply the custom items
  finalTotal = Math.round(t5);
  // same day pricing adjustments

  return finalTotal;
};

export const calculateBookingPriceAdjustment = (pickupDate, date, isDumpsterTask) => {
  let adjustment = 0;

  const extraDays = dayjs(pickupDate).diff(dayjs(date).hour(0).add(20, "days"), "days");

  if ((!date || (date && dayjs().isSame(date, "day"))) && !isDumpsterTask) {
    adjustment = sameDayBookingPrice;
  }

  //pickup days extra charge after 20 days for dumpster rental
  if (extraDays > 0 && isDumpsterTask) {
    adjustment += extraDays * 20 * 100;
  }

  return adjustment;
};

export const getSummaryProps = (task, businessClient, draftBooking, quoteDescriptionForm, dateAndTimeForm, quoteReviewForm) => {
  return {
    task,
    businessClient,
    frequency: dateAndTimeForm[Input.Frequency]?.value,
    vehicle: quoteReviewForm[Input.Vehicle]?.value,
    junk: quoteReviewForm[Input.Junk]?.value,
    dumpster: quoteReviewForm[Input.Dumpster]?.value,
    total: draftBooking?.isLockedPrice ? draftBooking.total : null,
    date: dateAndTimeForm[Input.Date]?.value,
    pickup: dateAndTimeForm[Input.Pickup]?.value,
    pickupdate: dateAndTimeForm[Input.PickupDate]?.value,
    pickuptime: dateAndTimeForm[Input.PickupTime]?.value,
    stairs: quoteDescriptionForm[Input.Stairs]?.value,
    dismantling: quoteDescriptionForm[Input.Dismantling]?.value,
    timezone: draftBooking?.timeZone,
    description: quoteDescriptionForm[Input.Description]?.value,
  };
};

export const getSummaryPropsForPriceQuote = (booking) => {
  return {
    task: booking[Input.Task],
    businessClient: booking.businessClient,
    frequency: booking[Input.Frequency],
    vehicle: booking[Input.Vehicle],
    junk: booking[Input.Junk],
    dumpster: booking[Input.Dumpster],
    total: booking?.isLockedPrice ? booking.total : null,
    finalTotal: booking.finalTotal,
    date: dayjs(booking[Input.Date]),
    pickup: booking[Input.Pickup],
    pickupdate: booking.pickupDate,
    pickuptime: booking.pickupTime,
    stairs: booking[Input.Stairs],
    dismantling: booking[Input.Dismantling],
    timezone: booking?.timeZone,
    description: booking[Input.Description],
    address: booking?.[Input.PickupAddress],
    quoteNumber: booking?.quoteNumber,
    isServiceFeeWaived: !!booking?.isServiceFeeWaived,
    isVehicleFeeWaived: !!booking?.isVehicleFeeWaived,
    isConfirmedQuote: !!booking.isConfirmedQuote,
    isLockedPrice: booking.isLockedPrice,
    hasCustomItems: booking[Input.Junk] && !booking?.total && booking[Input.Junk]?.filter((i) => i.item.isCustom)?.length > 0,
  };
};

export const isQuoteUserMatching = (draftBooking, auth, setShowAuthPopup, resetBooking, setActiveStep) => {
  if (!draftBooking) return;
  const userType = profile?.userType;
  // Don't display the Auth Popup if it is an admin
  if (userType && !["customer", "provider", "business"].includes(userType)) return;

  const bookingQuoteCustomerId = draftBooking.customer_id;
  const quoteCustomerAuthed = bookingQuoteCustomerId === auth.uid;
  if (!auth.uid && bookingQuoteCustomerId) {
    setShowAuthPopup(true);
    return;
  }
  if (!bookingQuoteCustomerId) {
    setShowAuthPopup(false);
    return;
  }
  // user logged in but does not match the quote customer
  if (bookingQuoteCustomerId !== auth.uid) {
    toastr.info("This quote was not created for this account. Redirecting...");
    resetBooking();
    setActiveStep(0);
    history.push("/booking?step=initial");
    return;
  }
  // user logged in and matches the quote customer
  if (quoteCustomerAuthed) {
    setShowAuthPopup(false);
  }
};

export const isFormsValid = (validForms) => validForms && Object.keys(validForms).every((form) => validForms[form]);

export const getActiveForm = (removal, rental, task) => ({ [removalTasks.includes(task)]: removal, [rentalTasks.includes(task)]: rental }.true);

export const isDateValid = (showDateSelector, task, removal, rental) => {
  if (!showDateSelector) return true;
  if (task === taskTypes["dumpster-rental"].value) {
    const d = !!rental[Input.Date].value;
    const t = !!rental[Input.Time].value;
    const p = !!rental[Input.Pickup].value;
    const pd = !!rental[Input.PickupDate].value;
    const pt = !!rental[Input.PickupTime].value;
    if (p === Pickup.Manual) {
      return d && t && pd && pt;
    }
    return d && t;
  }

  const d = !!removal[Input.Date].value;
  const t = !!removal[Input.Time].value;

  return d && t;
};

export const isQuoteDescriptionValid = (showPricingItems, task, removal, rental) => {
  if (!showPricingItems) return true;
  if (task === taskTypes["dumpster-rental"].value) {
    const du = !!rental[Input.Dumpster].value;
    const de = !!rental[Input.Description].value;
    const u = !!rental[Input.UnderstandPricing].value;
    return du && de && u;
  }
  const de = !!removal[Input.Description].value;
  return de;
};

export const isQuoteItemsValid = (showPricingItems, task, removal) => {
  if (!showPricingItems) return true;
  if (task === taskTypes["dumpster-rental"].value) {
    return true;
  }
  const j = !!removal[Input.Junk].value;
  const v = !!removal[Input.Vehicle].value;

  return j && v;
};

export const setTotalsForBookings = (bookings, priceMultiplier, selectedCoupon) => {
  bookings?.map((booking) => {
    let adjustment = 0;
    let finalTotal = booking.total;
    const isLockedPrice = booking?.isLockedPrice;
    const isDumpsterTask = booking.task === Task.DumpsterRental;
    if (isLockedPrice) {
      finalTotal = booking.total;
    } else {
      let total = calculateTotalBookingPrice(
        booking.task,
        booking.vehicle,
        booking.products,
        booking.stairs,
        booking.dismantling,
        booking.services,
        booking.isServiceFeeWaived,
        booking.isVehicleFeeWaived,
        priceMultiplier,
        selectedCoupon
      );
      adjustment = calculateBookingPriceAdjustment(booking["pickup-date"], booking.date, isDumpsterTask);
      finalTotal = total + (adjustment || 0);
    }
    booking.finalTotal = finalTotal;
    const quoteData = getSummaryPropsForPriceQuote(booking);
  });
};
