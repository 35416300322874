import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";

const WaitlistButton = ({ onClick, style }) => {
  const booking = useSelector((state) => state.booking.selectedBooking);
  const user = useSelector((state) => state.firebase.auth);

  const [isAssigned] = useState(!!booking?.warrior_id);
  const [isAssignedToMe] = useState(booking?.warrior_id === user?.uid);
  const [isInWaitingList] = useState(booking?.waiting_list?.includes(user?.uid));

  if (isAssigned && isAssignedToMe) return null;
  if (!isInWaitingList) return null;

  return (
    <Button fullWidth onClick={onClick} color="primary" variant="outlined" style={style}>
      {" "}
      You&lsquo;re on the Waitlist{" "}
    </Button>
  );
};

WaitlistButton.defaultProps = {
  onClick: () => {},
  style: {},
};

export default WaitlistButton;
