import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Card, CardContent, CardMedia, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import shipment_icon from "@assets/shipment_icon.png";
import { taskTypes, bookingStatus } from "../../../../constants/booking";
import dayjs from "dayjs";
import { services, urlMap } from "../../../Tasks/GetTasksPage/components/TaskListItem";

const useStyles = makeStyles((theme) => ({
  listContainer: {
    margin: theme.spacing(2, 1),
    display: "grid",
    gap: theme.spacing(2),
    gridTemplateColumns: "repeat(auto-fill, minmax(370px, 1fr) )",
    padding: theme.spacing(3, 0),
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "auto",
    },
  },
  cardRoot: {
    position: "relative",
    borderRadius: theme.spacing(1),
  },
  status: {
    textAlign: "center",
    position: "absolute",
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
  media: {
    height: 300,
    backgroundColor: "#f1f1f1",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  mainContainer: {
    flexGrow: 1,
    display: "flex",
    marginBottom: theme.spacing(2),
    "& img:first-child": {
      marginRight: theme.spacing(1.5),
      marginTop: theme.spacing(0.7),
    },
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    "& :last-child": {
      marginLeft: theme.spacing(2),
    },
  },
  cardIcon: {
    paddingRight: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
    fontSize: "1.1rem",
  },
  bodyText: {
    fontSize: "clamp(0.9rem, 0.5rem + 0.6667vw, 1.3rem)",
    maxWidth: "80%",
  },
  amount: {
    fontSize: "clamp(0.9rem)",
    fontWeight: "bold",
    color: "#1E3B47",
    top: theme.spacing(2.2),
    right: theme.spacing(3),
    position: "absolute",
  },
  dateTimeContainer: {
    display: "flex",
    color: "grey",
    paddingRight: "20px",
    "& p:last-child": {
      marginLeft: theme.spacing(2),
    },
  },
  dateTimeText: { fontWeight: "bold", color: "black" },
}));

function BookingsCards({ bookings, showQuotes, actions, pagenationDetails, getStatusAction, doItAgainOverride }) {
  const { visitedData, pageSize } = pagenationDetails;
  const classes = useStyles();

  const getImageURl = (images) => (images ? images[0] : "");
  const isQuote = (booking) => !!booking.bookingQuoteUrl && !booking.orderNumber;

  return (
    <div className={classes.listContainer}>
      {bookings.slice(visitedData, visitedData + pageSize).map((booking) => (
        <Card className={classes.cardRoot} key={booking.id}>
          <CardMedia
            className={classes.media}
            image={booking.taskImages?.length ? booking.taskImages[0] : urlMap[services[booking.task]]}
            title="Provider Image"
          />
          <CardContent className={classes.cardContent}>
            <div className={classes.mainContainer}>
              <img src={shipment_icon} alt="shipmentIcon" height="20px" width="20px" />
              <div>
                <Typography classes={{ h5: classes.title }} gutterBottom variant="h5" component="h3">
                  {taskTypes[booking.task].label}
                </Typography>
                <Typography gutterBottom classes={{ body1: classes.bodyText }} variant="body1" color="textSecondary" component="p">
                  {booking.address.location}, {booking.address.zipCode}
                </Typography>
                <div className={classes.dateTimeContainer}>
                  <Typography gutterBottom classes={{ body1: classes.bodyText }} variant="body1" color="textSecondary" component="p">
                    Date: <span className={classes.dateTimeText}>{dayjs(booking.date).tz(booking.timeZone).format("MM/DD")}</span>
                  </Typography>
                  <Typography gutterBottom classes={{ body1: classes.bodyText }} variant="body1" color="textSecondary" component="p">
                    Time: <span className={classes.dateTimeText}>{dayjs(booking.date).tz(booking.timeZone).format("hh:mm A")}</span>
                  </Typography>
                </div>
                {!isQuote(booking) && <div className={classes.amount}>{`$${(booking.total / 100)?.toFixed(2)}`}</div>}
              </div>
            </div>
            <div className={classes.buttonContainer}>
              {isQuote(booking) ? (
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.cardButton}
                  onClick={(e) => (booking.bookingId ? actions.handleBookingDetails(e, booking.bookingId) : actions.goToBookingFlow(e, booking.id))}
                >
                  {booking.bookingId ? "Show Details" : "Go to Quote"}
                </Button>
              ) : (
                <>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    className={classes.cardButton}
                    onClick={(e) => actions.handleBookingDetails(e, booking.id)}
                  >
                    Details
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.cardButton}
                    disabled={(booking.status === bookingStatus.booked.value || booking.status === bookingStatus.current.value) && !doItAgainOverride}
                    onClick={(e) => actions.handleDoItAgain(e, booking)}
                  >
                    Do it again
                  </Button>
                </>
              )}
            </div>
          </CardContent>
          {!isQuote(booking) && (
            <div className={classes.status} onClick={(e) => actions.handleStatusClick(e, booking)}>
              {getStatusAction(booking)}
            </div>
          )}
        </Card>
      ))}
    </div>
  );
}

BookingsCards.defaultProps = {
  rows: [],
  showQuotes: false,
};

BookingsCards.propTypes = {
  rows: PropTypes.array,
  actions: PropTypes.object,
  pagenationDetails: PropTypes.object,
  getStatusAction: PropTypes.func,
  showQuotes: PropTypes.bool,
};

export default BookingsCards;
