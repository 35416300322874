import { useMediaQuery } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useParams } from "react-router-dom";
import { Kustomer } from "../../../helpers/kustomer";
import history, { getLinkWithGclid } from "../../../history";
import { PostConfig, wordpressPostIds } from "../../../pages/constants/wordpress";
import { getWordpressPost, getWordpressPostBySlug } from "../../../pages/utils/wordpress";
import { Wrapper } from "./Wrapper";

export const WordPressPost = () => {
  const { id }: { id: string } = useParams();
  const [post, setPost] = useState<PostConfig | null>(null);
  const subscribed = useRef(false);
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  const getPost = async () => {
    const path = window.location.pathname?.replace("/", "");

    if (id) {
      await getWordpressPostBySlug(id, setPost, subscribed);
    } else {
      const postId = wordpressPostIds[path];
      if (!postId) {
        return;
      }
      await getWordpressPost(postId, setPost, subscribed);
    }
  };

  useEffect(() => {
    subscribed.current = true;
    getPost();
    return () => {
      subscribed.current = false;
    };
  }, []);

  let postTimer: number = 0;

  const generateBackrounds = () => {
    // apply section backgrounds
    const container = document.getElementById("wordpress-post");
    if (!container) return;
    const background1 = document.getElementsByClassName("has-base-background-color");
    const background2 = document.getElementsByClassName("has-base-2-background-color");
    let i = 0;
    let j = 0;
    if (background1.length > 0) {
      for (i; background1.length; i++) {
        const ele = background1.item(i);
        const rect = ele?.getBoundingClientRect();
        if (!ele) return;
        const h = rect?.height;
        const y = rect?.y ?? 0;
        const div = document.createElement("div");
        div.setAttribute(
          "style",
          `width: 100vw; background: #f0f0f0; height: ${h}px; position: absolute; top: ${y - (isMobile ? 64 : 115)}px; left: 0px, z-index: 0`
        );
        container.insertBefore(div, ele);
      }
    }
    if (background2.length > 0) {
      for (j; background2.length; j++) {
        const ele = background2.item(j);
        const rect = ele?.getBoundingClientRect();
        if (!ele) return;
        const h = rect?.height;
        const y = rect?.y ?? 0;
        const div = document.createElement("div");
        div.setAttribute(
          "style",
          `width: 100vw; background: #f7f8f9; height: ${h}px; position: absolute; top: ${y - (isMobile ? 64 : 115)}px; left: 0px, z-index: 0`
        );
        container.append(div);
      }
    }
  };

  const openKustomer = (e: any) => {
    e.preventDefault();
    Kustomer.open();
  };

  const goToBooking = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(getLinkWithGclid("/booking?step=initial"));
  };

  const generateButtonFunctions = () => {
    const buttons = document.getElementsByClassName("wp-block-button");
    if (!buttons) return;
    for (var i = 0; i < buttons.length; i++) {
      const button = buttons.item(i);
      const text = button?.firstChild?.textContent;
      if (text?.toLowerCase()?.includes("talk")) {
        button?.addEventListener("click", openKustomer);
      }
      if (text?.toLowerCase()?.includes("book")) {
        button?.addEventListener("click", goToBooking);
      }
    }
  };
  const generateLinkFunctions = () => {
    const buttons = document.getElementsByClassName("wp-block-button");
    if (!buttons) return;
    for (var i = 0; i < buttons.length; i++) {
      const button = buttons.item(i);
      const text = button?.firstChild?.textContent;
      if (text?.toLowerCase()?.includes("talk")) {
        button?.addEventListener("click", openKustomer);
      }
    }
  };

  useEffect(() => {
    postTimer = window.setTimeout(() => {
      if (post?.id) {
        // apply section backgrounds
        generateBackrounds();
        generateButtonFunctions();
        generateLinkFunctions();
      }
      // find Talk to Dimension buttons
    }, 500);
    return () => clearTimeout(postTimer);
  }, [post?.id]);

  const fixedHtml = post?.content?.rendered?.split("#038;")?.join("");

  return <Wrapper title={post?.title.rendered?.split("#038;")?.join("") ?? ""}>{post && ReactHtmlParser(fixedHtml || "")}</Wrapper>;
};

export const WordPressPostTest = () => {
  const [post, setPost] = useState<PostConfig | null>(null);
  const subscribed = useRef(false);

  const getPost = async () => {
    const path = window.location.pathname?.replace("/staging/", "");
    if (!path) {
      return;
    }
    await getWordpressPost(path, setPost, subscribed);
  };

  useEffect(() => {
    getPost();
    return () => {
      subscribed.current = false;
    };
  }, []);

  const fixedHtml = post?.content?.rendered?.split("#038;")?.join("");

  return post ? (
    <Wrapper title={post.title.rendered?.split("#038;")?.join("") ?? ""}>{ReactHtmlParser(fixedHtml || "")}</Wrapper>
  ) : (
    <div style={{ height: "100vh" }} />
  );
};
