import React, { useEffect, useRef, useState } from "react";
import debounce from "debounce";
import { TextField } from "@material-ui/core";

import useMountedEffect from "../hooks/useMountedEffect";

const DebouncedTextField = ({ value, onChange, ...rest }) => {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  useMountedEffect(() => {
    if (typeof currentValue === "string") {
      onChange(currentValue);
    }
  }, [currentValue]);

  const debouncedChange = useRef(
    debounce((nextValue) => {
      setCurrentValue(nextValue);
    }, 200)
  );

  const handleChange = (event) => {
    debouncedChange.current(event.target.value);
  };

  return <TextField defaultValue={currentValue} onChange={handleChange} {...rest} />;
};

export default DebouncedTextField;
