import React, { useEffect, useState } from "react";
import { Button, Divider, Grid, Tab, Tabs, Typography, useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useAxiosGet } from "../../../../axios";
import { taskTypes, vehicleTypes } from "../../../../constants/booking";
import BallotOutlinedIcon from "@material-ui/icons/BallotOutlined";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";
import EventIcon from "@material-ui/icons/Event";
import ClearAllOutlinedIcon from "@material-ui/icons/ClearAllOutlined";
import LocalAtmOutlinedIcon from "@material-ui/icons/LocalAtmOutlined";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import NoteOutlinedIcon from "@material-ui/icons/NoteOutlined";
import PinDropOutlinedIcon from "@material-ui/icons/PinDropOutlined";

import dayjs from "dayjs";

import Spacer from "../../../../shared/components/Spacer";
import ImageGalleryCustom from "../../../../shared/components/ImageGallery";
import classes from "@css/components/BookingPreview.module.css";
import { dumpsterPickups } from "../../../../constants/dumpsters";

const VehicleTypes: any = vehicleTypes;

type DetailsViewProps = {
  bookings: Array<any>;
  summary: any;
  isMultipleQuotes: boolean;
  confirmAllQuotes: any;
  confirmedQuotes: Array<any>;
  handleConfirmItem: any;
  handleContinue: any;
  displayedQuote: string | null;
};

interface IListedItem {
  icon: JSX.Element;
  label: string;
  value: string;
  hidden?: boolean;
}

const ListedItem = ({ item }: { item: IListedItem }) => (
  <div className={classes.detailsGridContainer}>
    <div className={classes.detailsGridItem}>
      {item.icon}
      <Typography className={classes.detailsItemTitle}>{item.label}</Typography>
    </div>
    <div className={classes.detailsItemValue}>{item.value}</div>
  </div>
);

const getQuoteItems = (booking: any, summary: any, isDumpster: boolean, isPowerWash: boolean): Array<IListedItem> => [
  {
    label: isDumpster ? "Delivery Date" : "Service Date",
    value: dayjs(summary?.date ?? booking?.date)?.format("MMM DD, YYYY hh:mm A"),
    icon: <EventIcon />,
  },
  {
    label: "Pickup",
    value: dumpsterPickups[summary?.pickup ?? booking?.pickup]?.label,
    icon: <EventIcon />,
    hidden: !isDumpster,
  },
  {
    label: "Service Address",
    value: booking?.address.location,
    icon: <PinDropOutlinedIcon />,
  },
  {
    label: "Your Notes",
    value: booking?.isLockedPrice ? booking?.description : summary?.description,
    icon: <NoteOutlinedIcon />,
  },
  {
    label: "Min Vehicle Requirement",
    value: VehicleTypes[summary?.vehicle ?? booking?.vehicle]?.label,
    icon: <LocalShippingOutlinedIcon />,
    hidden: !!booking?.bookingBidId || isDumpster || isPowerWash,
  },
  {
    label: "Stairs (Flights)",
    value: summary?.stairs || booking?.stairs || "None",
    icon: <ClearAllOutlinedIcon />,
    hidden: isDumpster,
  },
  {
    label: "Dismantling (Items)",
    value: summary?.dismantling || booking?.dismantling || "None",
    icon: <BuildOutlinedIcon />,
    hidden: isDumpster,
  },
];

function DetailsView({
  bookings,
  summary,
  isMultipleQuotes,
  confirmAllQuotes,
  confirmedQuotes,
  handleConfirmItem,
  handleContinue,
  displayedQuote,
}: DetailsViewProps) {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const [myPaymentMethod, setMyPaymentMethod] = useState<any>(null);
  const [currentBooking, setCurrentBooking] = useState<any>(bookings[0]);
  const profile = useSelector((state: any) => state.firebase.profile);

  const isAllConfirmed = bookings.length === confirmedQuotes.length;
  const isCurrentQuoteConfirmed = confirmedQuotes.includes(currentBooking?.quoteNumber);
  const isDumpster = currentBooking?.task === taskTypes["dumpster-rental"].value;
  const isPowerWash = currentBooking?.task === taskTypes["power-washing"].value;

  const listedItems: Array<IListedItem> = getQuoteItems(currentBooking, summary, isDumpster, isPowerWash);

  const products = !currentBooking?.isLockedPrice ? summary?.junk : currentBooking?.products;

  useEffect(() => {
    if (profile?.paymentMethod?.id) {
      fetchMyPaymentMethod();
    }
  }, [profile.paymentMethod]);

  useEffect(() => {
    if (displayedQuote) {
      setCurrentBooking(bookings.find((booking) => booking.quoteNumber === displayedQuote));
    }
  }, [displayedQuote]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    const selectedQuote = bookings.find((booking: any) => booking.quoteNumber === newValue);
    if (newValue !== currentBooking.quoteNumber) {
      setCurrentBooking(selectedQuote);
    }
  };

  const fetchMyPaymentMethod = async () => {
    const response = await useAxiosGet(`/payments/get-payment-method/${profile.paymentMethod.id}`);
    const paymentMethod = response?.data?.data?.data ?? null;
    setMyPaymentMethod(paymentMethod);
  };

  return (
    <div className={classes.desktopRoot}>
      {isMultipleQuotes && !isMobile && (
        <>
          <div className={classes.tabsContainer}>
            <Tabs
              classes={{ root: classes.quoteSelectorTabsRoot }}
              value={currentBooking?.quoteNumber}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable tabs to select quotes"
            >
              {bookings.map((booking: any) => {
                const isConfirmedQuote = confirmedQuotes.includes(booking.quoteNumber);
                return (
                  <Tab
                    classes={{ root: classes.quoteSelectorTab, wrapper: isConfirmedQuote ? classes.enabledTabText : classes.disabledTabText }}
                    key={booking.id}
                    value={booking.quoteNumber}
                    label={booking.quoteNumber}
                  />
                );
              })}
            </Tabs>
            <Button className={classes.confirmAllButton} onClick={confirmAllQuotes} color="primary" variant="text">
              {isAllConfirmed ? "Unc" : "C"}onfirm All
            </Button>
          </div>
          <Divider />
        </>
      )}
      <div className={classes.detailsContainer}>
        {listedItems
          .filter((i: IListedItem) => !i.hidden)
          .map((i: IListedItem, index) => (
            <ListedItem item={i} key={index} />
          ))}
        <Spacer x={20} />
        <Divider />
        <Spacer x={20} />
        {!isDumpster && products?.length > 0 && (
          <div className={classes.detailsGridContainer}>
            <div className={classes.sectionHeader}>
              <BallotOutlinedIcon />
              <div className={classes.detailsItemTitle}>Items expected</div>
            </div>
            <div>
              {products?.map((item: any, index: number) => (
                <div key={index} className={classes.productItem}>
                  <div className={classes.detailsItemValue}>{item.quantity}</div>
                  <div>
                    <Typography className={classes.detailsItemTitle}>{item.item.description}</Typography>
                    <div />
                    <Typography className={classes.detailsProductItemDescription}>{item.item.details}</Typography>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {isDumpster && currentBooking?.services?.length > 0 && (
          <div className={classes.detailsGridContainerColumn}>
            <div className={classes.sectionHeader}>
              <BallotOutlinedIcon />
              <div className={classes.detailsItemTitle}>Dumpsters</div>
            </div>
            {currentBooking?.services?.map((item: any, index: number) => (
              <div key={index} className={classes.productItem}>
                <div className={classes.detailsItemValue}>{item.item.items.length}x</div>
                <div>{item.item.description}</div>
              </div>
            ))}
          </div>
        )}
        <Spacer x={20} />
        <Divider />
        <Spacer x={20} />
        {!!currentBooking?.taskImages?.length && (
          <>
            <div style={{ margin: "15px 0", fontWeight: "bold" }}>Task Pictures</div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ImageGalleryCustom grid={true} images={currentBooking.taskImages} />
              </Grid>
            </Grid>
            <Spacer x={20} />
            <Divider />
            <Spacer x={20} />
          </>
        )}
        {myPaymentMethod?.id && (
          <>
            <div className={classes.sectionHeader}>
              <LocalAtmOutlinedIcon />
              <div className={classes.detailsItemTitle}>Payment</div>
            </div>
            <Grid container spacing={2} style={{ marginLeft: "25px" }}>
              <Grid item xs={6} style={{ fontWeight: "bold" }}>
                Keep my previous payment method{" "}
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right", fontWeight: "bold" }}>
                {`${myPaymentMethod.card.brand} ending with •••• ${myPaymentMethod.card.last4}`}
              </Grid>
              <Grid item xs={12}>
                {currentBooking.first_name} {currentBooking.last_name || ""}
              </Grid>
              <Grid item xs={12}>
                {currentBooking.email}
              </Grid>
              <Grid item xs={12}>
                {currentBooking.phone_number}
              </Grid>
            </Grid>
          </>
        )}
      </div>
      {isMultipleQuotes && !isMobile && (
        <div className={classes.confirmButton}>
          <Button onClick={() => handleConfirmItem(currentBooking.quoteNumber, isCurrentQuoteConfirmed)} variant="outlined" color="primary">
            {isCurrentQuoteConfirmed ? "Unc" : "C"}onfirm Items
          </Button>
          <Button style={{ marginLeft: "16px" }} disabled={confirmedQuotes.length === 0} onClick={handleContinue} variant="contained" color="primary">
            Continue to next step
          </Button>
        </div>
      )}
    </div>
  );
}

export default DetailsView;
