import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import url from "@assets/empty_trash.svg";

const useStyles = makeStyles(() => ({
  root: {
    padding: "10px",
    width: "100%",
    height: "calc(100vh - 280px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bannerImage: {
    backgroundImage: `url(${url})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    width: "200px",
    height: "200px",
    opacity: "0.3",
    position: "absolute",
  },
  noDataText: {
    fontWeight: "bold",
    fontSize: "25px",
    textAlign: "center",
    marginTop: "100px",
  },
}));

function NoData({ displayText = "No Data Available" }) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.bannerImage} />
      <Box className={classes.noDataText}>{displayText}</Box>
    </Box>
  );
}

export default NoData;
