import { useState } from "react";

const read = (key) => {
  if (typeof window === "undefined") return null;
  try {
    const item = window.localStorage.getItem(key);
    return item && item !== undefined ? JSON.parse(item) : null;
  } catch (error) {
    console.warn(`Error reading localStorage key “${key}”:`, error); // eslint-disable-line no-console
    return null;
  }
};

const set = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

const remove = (key) => {
  window.localStorage.removeItem(key);
};

const initiate = (key, value) => {
  const stored = read(key);
  if (stored) return stored;
  set(key, value);
  return value;
};

export const getlocalStorage = {
  read,
  set,
  remove,
  initiate,
};

export default function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(initiate(key, initialValue));

  const setValue = (value) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    set(key, valueToStore);
    setStoredValue(valueToStore);
  };

  return [storedValue, setValue]; // optional (readValue, removeValue)
}
