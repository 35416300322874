import React from "react";
import { Controller } from "react-hook-form";
import { FormControlLabel, FormHelperText, Checkbox } from "@material-ui/core";

const CheckboxField = ({ name, control, label, errorMessage = "", ...rest }) => {
  return (
    <>
      <FormControlLabel
        control={
          <Controller
            render={({ onChange, value }) => <Checkbox checked={!!value} name={name} onChange={(e) => onChange(!!e.target.checked)} {...rest} />}
            name={name}
            control={control}
          />
        }
        label={label}
        name={name}
      />
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </>
  );
};

export default CheckboxField;
