import React from "react";
import Modal from "@shared/components/Modal";
import ProviderNoShow from "@assets/raccoon-tree.png";
import { Button, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: theme.spacing(55),
    minHeight: theme.spacing(32),
    zIndex: 10000,
    "& h6": {
      textAlign: "center",
    },
  },
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: theme.spacing(4),
  },
  image: {
    padding: theme.spacing(2),
    width: 250,
    [theme.breakpoints.down("xs")]: {
      width: 150,
    },
  },
  title: {
    fontSize: "22px",
    color: "#4F4F4F",
    fontWeight: "700",
    lineHeight: "30px",
    padding: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      lineHeight: "28px",
      padding: theme.spacing(2, 0),
    },
  },
  description: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#4F4F4F",
    padding: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      padding: theme.spacing(2, 0),
      lineHeight: "20px",
    },
  },
  link: {
    color: "inherit",
    textDecoration: "underline",
    cursor: "pointer",
  },
  button: {
    margin: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(2),
    },
  },
}));

const WarriorNoShowContent = ({ onClose }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={ProviderNoShow} alt="ProviderNoShow" className={classes.image} />
      <Typography className={classes.title} variant="p">
        Attention New Warrior
        <br /> Keep Us Updated!
      </Typography>
      <Typography className={classes.description} variant="body2">
        If you do not show up, or withdraw less than 2 hours before an appointment, you will be automatically banned from the platform. Please reach
        out to{" "}
        <a className={classes.link} href="mailto:support@getdimension.com">
          support@getdimension.com
        </a>{" "}
        or (415) 304-8171 immediately if you must withdraw from a job.
      </Typography>
      <Button className={classes.button} variant="contained" color="primary" onClick={onClose}>
        I Acknowledge
      </Button>
    </div>
  );
};

const WarriorNoShowModal = ({ onClose, ...modalProps }) => {
  const classes = useStyles();
  return <Modal {...modalProps} isMobileFullScreen header={<WarriorNoShowContent onClose={onClose} />} />;
};

export default WarriorNoShowModal;
