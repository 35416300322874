import React from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import shipment_icon from "@assets/shipment_icon.png";
import { Button, Chip, makeStyles, Typography, withStyles } from "@material-ui/core";
import { bookingStatus, taskTypes } from "../../../constants/booking";
import { ChipProps } from "../constants";
import history from "@history";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 2, 2),
    borderRadius: "5px",
    margin: theme.spacing(0, 0),
  },
  taskType: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "15px",
    },
  },
  grayText: {
    color: "grey",
  },
  greenText: {
    color: theme.palette.primary.main,
    fontSize: "clamp(0.8rem, 0.6rem + 0.33vw, 1rem)",
    fontWeight: "bold",
    textTransform: "capitalize",
  },
  status: {
    paddingRight: theme.spacing(2),
    width: "200px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "15px 0px",
      width: "100%",
    },
  },
  dateTimeText: {
    fontSize: "clamp(0.8rem, 0.6rem + 0.33vw, 1rem)",
    fontWeight: "bold",
    color: "black",
  },
  doBtn: {
    height: "40px",
    width: "140px",
    cursor: "pointer",
    fontSize: "clamp(0.8rem, 0.6rem + 0.33vw, 1rem)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  table: {
    width: "100%",
    borderCollapse: "separate",
    borderSpacing: "0 1rem",
  },
  tableHeader: {
    "& th": {
      color: "grey",
      fontSize: "clamp(0.9rem, 0.7rem + 0.3333vw, 1.1rem)",
      fontWeight: "bold",
      padding: theme.spacing(2, 0, 1, 0),
    },
    "& th:nth-child(2)": {
      paddingLeft: 0,
    },
    "& th:first-child": {
      paddingLeft: theme.spacing(4),
    },
  },
  tableRow: {
    backgroundColor: "#fff",
    borderRadius: "7px",
    boxShadow: "rgba(50, 50, 93, 0.10) 0px 6px 12px -2px, rgba(0, 0, 0, 0.10) 0px 3px 7px -3px",
    "& td": {
      textAlign: "center",
      padding: theme.spacing(2, 0, 2, 0),
      borderTop: "1px solid #e8e8e8",
      borderBottom: "1px solid #e8e8e8",
    },
    "& td:first-child": {
      width: "10%",
      borderRadius: "7px 0 0 7px",
      borderLeft: "1px solid #e8e8e8",
    },
    "& td:nth-child(2)": {
      padding: theme.spacing(0, 1, 0, 0),
    },
    "& td:last-child": {
      width: "10%",
      borderRadius: "0 7px 7px 0",
      borderRight: "1px solid #e8e8e8",
      paddingRight: theme.spacing(4),
    },
  },
  extraSmallColumn: {
    textAlign: "center",
    width: "10%",
  },
  smallColumn: {
    textAlign: "left",
    width: "15%",
  },
  mediumColumn: { textAlign: "left", width: "25%" },
  textCenter: { textAlign: "center" },
  addressCell: {
    color: "grey",
    textAlign: "left",
    fontSize: "clamp(0.8rem, 0.6rem + 0.33vw, 1rem)",
    padding: theme.spacing(1, 1, 1, 0),
  },
  taskTypeCell: {
    display: "flex",
    alignItems: "start",
    textAlign: "left",
    marginLeft: theme.spacing(3),
    "& img": {
      marginTop: theme.spacing(0.3),
    },
    "& div:last-child": {
      marginLeft: theme.spacing(1),
      flexGrow: "1",
    },
  },
  chip: {
    fontSize: "13px",
    lineHeight: 1.1,
    fontWeight: "bold",
    minWidth: theme.spacing(10),
  },
  redChip: {
    color: "red",
    border: "1px solid red",
  },
  redText: {
    color: "red",
  },
}));

const TaskTypeCell = ({ task, orderNumber }) => {
  const classes = useStyles();
  return (
    <div className={classes.taskTypeCell}>
      <img src={shipment_icon} alt="shipmentIcon" height="20px" width="20px" />
      <div className={classes.taskType}>
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          {taskTypes[task]?.label ?? ""}
        </Typography>
        <Typography variant="body1" classes={{ body1: classes.greenText }}>
          {orderNumber}
        </Typography>
      </div>
    </div>
  );
};

const PayoutStatusChip = ({ status = "processing" }) => {
  const classes = useStyles();
  return <Chip className={classes.chip} {...ChipProps[status]} />;
};

function TaskListTable({ taskListItems, isUpcoming, isSubmissionError }) {
  const classes = useStyles();

  const getPayoutDate = (booking) => {
    const { warriorPayoutHistory, warriorPayoutStatus, payout } = booking;
    return warriorPayoutStatus === bookingStatus.done.value
      ? warriorPayoutHistory.length
        ? dayjs(warriorPayoutHistory[warriorPayoutHistory.length - 1].date).format("M/DD")
        : "Not Found!"
      : "-";
  };

  const getTotalPayout = (booking) => {
    if (booking.warriorPayoutHistory?.length > 0) {
      let total = 0;
      booking.warriorPayoutHistory.map((payout) => (total += payout?.amount || 0));
      return (total / 100).toFixed(2);
    } else {
      return "-";
    }
  };

  const openTask = (id) => {
    history.push(`/task_details/${id}`);
  };

  return (
    <div className={classes.container}>
      <table className={classes.table}>
        <thead className={classes.tableHeader}>
          <tr>
            <th className={classes.smallColumn}>ID & type</th>
            <th className={classes.mediumColumn}>Address</th>
            <th>Date</th>
            <th>Time</th>
            {isUpcoming || isSubmissionError ? (
              <>
                <th>Amount</th>
                <th>Vehicle</th>
              </>
            ) : (
              <>
                <th>Payout Amount</th>
                <th>Payout Status</th>
                <th>Payout Date</th>
              </>
            )}
            {isSubmissionError && <th>State</th>}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {taskListItems.map((booking) => (
            <tr key={booking.id} className={classes.tableRow} onClick={() => openTask(booking.id)}>
              <td>
                <TaskTypeCell task={booking.task} orderNumber={booking.orderNumber} />
              </td>
              <td>
                <Typography variant="body1" classes={{ root: classes.addressCell }}>
                  {booking.address.location}, {booking.address.zipCode}
                </Typography>
              </td>
              <td>
                <span className={classes.dateTimeText}>{dayjs(booking.date).tz(booking.timeZone).format("MM/DD")}</span>
              </td>
              <td>
                <span className={classes.dateTimeText}>{dayjs(booking.date).tz(booking.timeZone).format("hh:mm A")}</span>
              </td>
              {isUpcoming || isSubmissionError ? (
                <>
                  <td className={classes.textCenter}>
                    <span className={classes.greenText}>{booking.payout_dynamic ? `$${(booking.payout_dynamic / 100).toFixed(2)}` : "-"}</span>
                  </td>
                  <td className={classes.textCenter}>
                    <div className={classes.greenText}>{booking.vehicle || "-"}</div>
                  </td>
                </>
              ) : (
                <>
                  <td className={classes.textCenter}>
                    <span className={classes.greenText}>
                      {booking.warriorPayoutStatus === bookingStatus.done.value && booking.payout ? `$${getTotalPayout(booking)}` : "-"}
                    </span>
                  </td>
                  <td>
                    <PayoutStatusChip status={booking.warriorPayoutStatus === bookingStatus.done.value ? "dispensed" : "processing"} />
                  </td>
                  <td>
                    <span className={classes.dateTimeText}>{getPayoutDate(booking)}</span>
                  </td>
                </>
              )}
              {isSubmissionError && (
                <td className={classes.textCenter}>
                  <span className={booking?.status === "submissionError" ? classes.redText : classes.grayText}>
                    {booking?.status === "rejected" ? "Errors Found" : "Pending Admin Review"}
                  </span>
                </td>
              )}
              <td>
                <Button
                  variant="outlined"
                  color={booking?.status !== "rejected" ? "primary" : "inherit"}
                  className={`${classes.doBtn} ${booking?.status === "rejected" && classes.redChip}`}
                >
                  {booking?.status === "rejected" ? "Re-Submit" : "Manage Task"}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

TaskListTable.propTypes = {
  taskListItems: PropTypes.arrayOf(PropTypes.object),
  isUpcoming: PropTypes.bool,
};

export default TaskListTable;
