import range from "./range";

const timeRange = (from, to, interval = 1) => {
  const hours = range(from, to);

  const segment = hours.slice(0, hours.length - 1);
  const until = hours[hours.length - 1];

  const scalars = range(0, interval - 1);
  const vector = 60 / interval;

  const result = segment
    .map((hour) =>
      scalars.map((scalar) => {
        const minute = scalar * vector;

        return {
          hour,
          minute,
          key: `${hour}-${minute}`,
        };
      })
    )
    .concat([
      {
        hour: until,
        minute: 0,
        key: `${until}-${0}`,
      },
    ]);

  const reducer = (accResult, element) => accResult.concat(element);

  return result.reduce(reducer, []);
};

export default timeRange;
