import { useEffect } from "react";
import useMounted from "./useMounted";

function useMountedEffect(effect, deps) {
  const isMounted = useMounted();

  useEffect(() => {
    if (isMounted) {
      effect();
    }
  }, deps);
}

export default useMountedEffect;
