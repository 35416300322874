import React from "react";
import Pitch from "./Pitch";
import AuthRedirector from "../../Auth/AuthRedirector";
import { scrollToTop } from "../../../methods/scrollToTop";
import Clients from "./Clients";
import ProductFeatures from "./ProductFeatures";
import HowWeWork from "./HowWeWork";
import Testimonials from "./Testimonials";
import Solutions from "./Solutions";
import CaseStudies from "./CaseStudies";
import BottomCTA from "./BottomCTA";

function LandingPage() {
  scrollToTop();

  return (
    <>
      <AuthRedirector />
      <Pitch />
      <Clients />
      <ProductFeatures />
      <HowWeWork />
      <Testimonials />
      <Solutions />
      <CaseStudies />
      <BottomCTA />
    </>
  );
}

export default LandingPage;
