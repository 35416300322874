import { makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import React, { ReactElement } from "react";
import ReactHtmlParser from "react-html-parser";
import { useStyles } from "../../../pages/utils/wordpressClasses";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { getLinkWithGclid } from "../../../history";

const useSummaryStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(5),
    marginBottom: theme.spacing(2),
    border: "1px solid #eee",
  },
  imageContainer: {
    height: 350,
    [theme.breakpoints.up("md")]: {
      height: 300,
    },
    width: "100%",
    margin: theme.spacing(3, 0),
    "& img": {
      height: 350,
      [theme.breakpoints.up("md")]: {
        height: 300,
      },
      width: "100%",
      objectFit: "cover",
    },
    borderRadius: 4,
    overflow: "hidden",
  },
  title: {},
  previewContainer: {
    "& a": {
      display: "none",
    },
  },
  preview: {
    "& a": {
      display: "none",
    },
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: "1.0rem",
  },
  content: {
    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridTemplateColumns: "350px auto",
      columnGap: theme.spacing(3),
    },
  },
}));

export interface WordPressPostSummaryProps {
  post: any;
}

export const WordPressPostSummary = ({ post }: WordPressPostSummaryProps): ReactElement => {
  const classes = useSummaryStyles();
  const wordpressClasses: ClassNameMap<any> = useStyles();
  const title = post.title?.rendered;
  const excerpt = post.excerpt?.rendered || "Test";
  const link = getLinkWithGclid(`/blog/${post.slug ?? ""}`);
  const image = post.jetpack_featured_media_url ?? "";

  return (
    <div className={classes.container}>
      <Typography variant="h2">{title}</Typography>
      <div className={classes.content} style={{ display: !image ? "block" : undefined }}>
        {image && (
          <div className={classes.imageContainer}>
            <img alt={post.slug} src={image} />
          </div>
        )}
        <div>
          <div className={classes.previewContainer}>{ReactHtmlParser(excerpt)}</div>
          <Link className={classes.link} to={link}>
            Read more
          </Link>
        </div>
      </div>
    </div>
  );
};
