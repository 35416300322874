import React from "react";
import classes from "@css/components/website/Values.module.css";
import { Typography } from "@material-ui/core";
function Values() {
  return (
    <section className={classes.container}>
      <div className={classes.mainContent}>
        <Typography className={classes.header}>Our Values</Typography>
        <div className={classes.listContainer}>
          <div className={classes.listItem}>
            <div>
              <Typography className={classes.smallHeader}>Reliable</Typography>
              <span className={classes.listNumber}>01</span>
            </div>
            <Typography className={classes.description}>
              Our customers can rest easy, knowing that their waste is being handled by our trusted network of providers. If your original provider is
              unable to fulfil your request, we&apos;ll always ensure that a replacement is dispatched to you as soon as possible.
            </Typography>
          </div>
          <div className={classes.listItem}>
            <div>
              <Typography className={classes.smallHeader}>Revolutionary</Typography>
              <span className={classes.listNumber}>02</span>
            </div>
            <Typography className={classes.description}>
              Dimension is here to radically change waste management with the power of technology.
            </Typography>
          </div>
          <div className={classes.listItem}>
            <div>
              <Typography className={classes.smallHeader}>Rewarding</Typography>
              <span className={classes.listNumber}>03</span>
            </div>
            <Typography className={classes.description}>
              Dimension takes all the hassle out of managing waste, leaving our customers with more time to focus on the things that really matter to
              them.
            </Typography>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Values;
