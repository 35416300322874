import axios from "axios";
import { haversineDistance } from "./haversine";
const googleMapsApiKey = process.env.REACT_APP_GMAPS_API_KEY;

export const extractCity = (address) => {
  let city = null;
  for (var i = 0, len = address.address_components.length; i < len; i++) {
    var ac = address.address_components[i];
    if (ac.types.indexOf("locality") >= 0) {
      city = ac.short_name;
      break;
    } else if (ac.types.indexOf("administrative_area_level_1") >= 0) {
      city = ac.short_name;
    }
  }
  return city;
};

export const getAddressFromComponents = (geo) => {
  const streetNumber = geo.find((g) => g.types.find((t) => t === "street_number"))?.long_name;
  const streetName = geo.find((g) => g.types.find((t) => t === "route"))?.long_name;
  const cityName = geo.find((g) => g.types.find((t) => t === "locality") && g.types.find((t) => t === "political"))?.long_name;
  const stateName = geo.find((g) => g.types.find((t) => t === "administrative_area_level_1") && g.types.find((t) => t === "political"))?.long_name;
  const zipCode = geo.find((g) => g.types.find((t) => t === "postal_code"))?.long_name;
  const county = geo.find((g) => g.types.find((t) => t === "administrative_area_level_2") && g.types.find((t) => t === "political"))?.long_name;

  const streetAddress = `${streetNumber ?? ""} ${streetName ?? ""}`?.trim();

  return {
    street: streetAddress,
    city: cityName ?? stateName ?? "",
    state: stateName ?? "",
    zip: zipCode ?? "",
    county: county ?? "",
  };
};

export const getZip = async ({ lat, lng }) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&result_type=postal_code&key=${googleMapsApiKey}`;

  try {
    let zipCode = "";
    const response = await axios.get(url);
    const address = response.data.results[0].address_components;
    for (const location of address) {
      if (location.types.includes("postal_code")) {
        zipCode = location.long_name;
        break;
      }
    }
    return zipCode;
  } catch (error) {
    // eslint-disable-next-line
    console.log(error);
  }
};

export const getDistance = (a, b) => {
  const distance = haversineDistance(a, b)?.toFixed(1);
  return distance;
};

export const getOpeningHours = (placeId) => {
  return new Promise((resolve) => {
    let openingHours = [];
    const request = {
      placeId,
      fields: ["opening_hours"],
    };

    const service = new google.maps.places.PlacesService(document.createElement("div"));
    service.getDetails(request, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && place.opening_hours) {
        openingHours = [...place.opening_hours.weekday_text];
      }
      resolve(openingHours);
    });
  });
};
