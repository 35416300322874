import { useEffect } from "react";

const useSetDocTitle = () => {
  const pathName = location.pathname;

  const getTitle = () => {
    switch (pathName) {
      // TODO: add title as per page
      case "/":
        return "Dimension | Waste Removal, Waste Management Services";
      case "/booking":
        return "Book A Service | Dimension";
      case "/provider_portal":
        return "Provider Portal | Dimension";
      case "/newclientintake":
        return "New Client Intake | Dimension";
      default:
        return "Dimension";
    }
  };

  useEffect(() => {
    document.title = getTitle();
  }, [location.pathname]);
};

export default useSetDocTitle;
