import React, { useEffect, useState } from "react";
import classes from "@css/components/website/Pitch.module.css";
import { Button, useMediaQuery } from "@material-ui/core";
import BookingWidget from "./BookingWidget";
import { StringParam, useQueryParam } from "use-query-params";
import { defaultValues } from "../../../BookingPage/helpers/constants";
import { Input } from "../../../BookingPage/helpers/enums";
import { getFormattedAddress } from "../../../../helpers/address";
import { getMatchingServiceLocation } from "../../../../helpers/location";
import { useAxiosGet } from "../../../../axios";
import { useDispatch } from "react-redux";
import { validateServiceLocation } from "../../../../store/actions/address";
import TrustBox from "../../../../shared/components/TrustPilot";
import { KustomerLauncher } from "../../../../containers/KustomerLauncher";

const urlTaskMapping: any = {
  cardboard_removal: "cardboard-removal",
  junk_removal: "junk-removal",
  dumpster_rental: "dumpster-rental",
  power_washing: "power-washing",
};

const dynamicLabels: any = {
  "junk-removal": "Waste Removal",
  "cardboard-removal": "Cardboard Removal",
  "dumpster-rental": "Dumpster Rental",
};

const HeroText = ({ isBusiness, dynamicText }: { isBusiness?: boolean; dynamicText?: string | null }) => (
  <h1 className={classes.heroText}>
    {isBusiness ? (
      <>
        Radically
        <br /> Simplify Your
        <br /> Business’
        <br /> Waste Removal
      </>
    ) : dynamicText ? (
      <>{dynamicText}</>
    ) : (
      <>
        Fast
        <br /> Reliable
        <br /> Sustainable
      </>
    )}
  </h1>
);

const SubText = ({ isBusiness }: { isBusiness?: boolean }) => (
  <div className={classes.subTextContainer}>
    <p className={classes.subText}>
      {isBusiness
        ? "Sick of expensive, unreliable, inflexible waste management? "
        : "Dimension is the all-in-one solution for waste management, nationwide."}
    </p>
    <p className={classes.subText}>
      {isBusiness
        ? "Dimension solves all of your worries with a single click. "
        : "Book same day service online in minutes, or chat with our team to discuss customized solutions."}
    </p>
    {!isBusiness && (
      <Button className={classes.secondaryButtonDark} variant="contained">
        <KustomerLauncher>Chat With Us</KustomerLauncher>
      </Button>
    )}
  </div>
);

function Pitch({ isBusiness }: { isBusiness?: boolean }) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:768px)");
  const [locationId] = useQueryParam("location", StringParam);
  const [market] = useQueryParam("market", StringParam);
  const [dynamic] = useQueryParam("dynamic", StringParam);
  const [dynamicLocation, setDynamicLocation] = useState(null);
  const [urlAddressField, setUrlAddressField] = useState(null);
  const regexPath: any = location.pathname.match(/(cardboard_removal|junk_removal|dumpster_rental)/g);
  const urlTask = urlTaskMapping[regexPath];
  const defaultTask = urlTask ?? defaultValues[Input.Task];

  const dynamicException = market?.includes("St_") ? market.replace("St_", "St._") : market;
  const dynamicAddress = dynamicException
    ?.trim()
    .replaceAll(/[^a-zA-Z. ]/g, " ")
    .trim();

  const fetchLocation = async () => {
    let response = null;
    if (!locationId) return;
    const res = await useAxiosGet(`/geotarget/${locationId}`);
    if (res?.data?.success) {
      const { cityLocation, state } = res.data;
      const derivedLocation = `${cityLocation} ${state}`;
      setDynamicLocation(res.data.name);
      const address = await getFormattedAddress(derivedLocation);
      response = address;
    }
    return response;
  };

  const fetchMarketLocation = async () => {
    if (!dynamicAddress) return;
    return getMatchingServiceLocation(dynamicAddress);
  };

  const locateMarketAddress = async () => {
    const address = (await fetchLocation()) ?? (await fetchMarketLocation());
    if (address) {
      setUrlAddressField(address);
      dispatch(validateServiceLocation({ coordinates: address.coordinates }, defaultTask));
    }
  };

  useEffect(() => {
    locateMarketAddress();
  }, [locationId, dynamicAddress]);

  const paramsProps = {
    defaultTask,
    urlAddressField,
  };

  const dynamicText = dynamicLocation || dynamic ? `${dynamicLocation ?? ""} ${dynamic ?? dynamicLabels[defaultTask]}` : null;

  const Heading = () => <HeroText isBusiness={isBusiness} dynamicText={dynamicText} />;
  const SubHeading = () => <SubText isBusiness={isBusiness} />;

  return (
    <section className={classes.container}>
      <div className={classes.content}>
        <div className={classes.leftPane}>
          {isMobile && <Heading />}
          {!isBusiness && <BookingWidget isBusiness={isBusiness} paramProps={paramsProps} />}
          {isBusiness && <BookingWidget isBusiness={isBusiness} paramProps={paramsProps} />}
          {isMobile && <SubHeading />}
          {isMobile && <TrustBox style="landing-widget" />}
        </div>
        <div className={classes.rightPane}>
          <div className={classes.pitchContent}>
            <Heading />
            <SubHeading />
            <TrustBox style="landing-widget" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pitch;
