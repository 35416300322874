import history from "@history";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@material-ui/core";
import Button from "@shared/components/Button";
import Form, { InputField } from "@shared/components/Form";
import OtpValidationPopup from "@shared/components/OtpValidation";
import { updateProfile } from "@store//actions/users";
import { signInWithGoogle } from "@store/actions/auth";
import { sendVerifyToken } from "@store/actions/twilio";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import { roles } from "../../constants/roles";
import { segmentTrack } from "../../helpers/segment";
import AuthRedirector from "./AuthRedirector";

const formDefaults = {
  phoneNumber: "",
};

const formSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .required("Phone Numer is required")
    .test("phone-validation", "Invalid format!", (value) => value && isValidPhoneNumber(value)),
});

const GoogleSignUp = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const userType = useSelector((state) => state.tempData.userType);
  const isCustomer = userType === roles.customer.value;

  const [isInvalidPhone, setIsInvalidPhone] = useState(() => !!location.search && new URLSearchParams(location.search).get("invalidPhoneNumber"));
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [isTwilioVerified, setIsTwilioVerified] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [btnLabel, setBtnLabel] = useState(isCustomer && !isInvalidPhone ? "Create Account" : "Verify Number");
  const [formData, setFormData] = useState(null);

  const { control, watch, handleSubmit, errors } = useForm({
    defaultValues: formDefaults,
    resolver: yupResolver(formSchema),
  });

  const phoneNumber = watch("phoneNumber");

  useEffect(() => {
    if (isTwilioVerified) {
      setIsTwilioVerified(false);
      setBtnLabel("Verify Number");
    }
  }, [phoneNumber]);

  const trackProviderAction = (action, payload) => {
    if (userType === roles.provider.value) {
      segmentTrack(action, payload);
    }
  };

  const onSubmit = async ({ phoneNumber }) => {
    if (isCustomer || isTwilioVerified) {
      trackProviderAction("Warrior Clicks Sign Up with Google - Register");
      dispatch(signInWithGoogle({ loader: true, phone: phoneNumber, twilioVerified: isTwilioVerified }));
      return;
    }

    if (phoneNumber && !isTwilioVerified) {
      // send verify token
      trackProviderAction("Warrior Clicks Verify Number - Register", { phone: phoneNumber });
      setIsSubmitLoading(true);
      const res = await sendVerifyToken(phoneNumber);
      setIsSubmitLoading(false);
      if (!res) return;
      if (res.status === "approved") {
        setIsTwilioVerified(true);
        setBtnLabel("Create Account");
      } else {
        setFormData({ phoneNumber });
        setShowOtpInput(true);
      }
      return;
    }
  };

  const verify = async (setter) => {
    const data = formData;
    if (!data) {
      setter("Please fill out the form");
      return;
    }
    setShowOtpInput(false);
    setIsInvalidPhone(false);
    await register(data, true);
  };

  const register = async (data, verified) => {
    if (isInvalidPhone) {
      dispatch(updateProfile(data));
    } else {
      dispatch(signInWithGoogle({ loader: true, phone: data?.phoneNumber, twilioVerified: verified }));
    }
  };

  const resend = async (e, setter) => {
    e.preventDefault();
    if (phoneNumber && !isTwilioVerified) {
      await sendVerifyToken(phoneNumber);
      setter("SMS resent to your phone number");
    }
  };

  return (
    <>
      {!isInvalidPhone && <AuthRedirector />}
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={12} style={{ padding: "20px 0", marginTop: "20px" }}>
            <InputField
              fullWidth
              required
              name="phoneNumber"
              type="phone"
              id="phoneNumber"
              label="Phone Number"
              placeholder="Enter phone number"
              control={control}
              errors={errors}
              disabled={showOtpInput}
              style={{ display: showOtpInput ? "none" : undefined }}
            />
            {showOtpInput && (
              <OtpValidationPopup
                phoneNumber={phoneNumber}
                setOpen={setShowOtpInput}
                isNumberVerified={isTwilioVerified}
                setNumberVerified={verify}
                resend={resend}
              />
            )}
          </Grid>
          <div style={{ width: "100%", display: showOtpInput ? "none" : "flex" }}>
            {!isInvalidPhone && (
              <Button fullWidth onClick={() => history.push("/auth/register")} variant="text" color="primary">
                Back to Register
              </Button>
            )}
            <Button fullWidth type="submit" variant="contained" color="primary" loading={isSubmitLoading} loadingText="Sending OTP...">
              {btnLabel}
            </Button>
          </div>
        </Grid>
      </Form>
    </>
  );
};

export default GoogleSignUp;
