import React from "react";
import { Box, IconButton, makeStyles, Paper } from "@material-ui/core";
import { AddCircle, RemoveCircle } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "120px",
  },
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  item: {
    flex: 1,
  },
  value: {
    textAlign: "center",
  },
}));

const Counter = ({ value, minValue, maxValue, onChange }) => {
  const classes = useStyles();

  const onAdd = () => onChange(value + 1);
  const onRemove = () => onChange(value - 1);

  return (
    <Paper className={classes.root} variant="outlined">
      <Box className={classes.container}>
        <Box className={classes.item}>
          <IconButton disableRipple disabled={value === minValue} onClick={onRemove} style={{ padding: "5px" }}>
            <RemoveCircle color={value === minValue ? "disabled" : "primary"} />
          </IconButton>
        </Box>
        <Box className={`${classes.item} ${classes.value}`}>{value}</Box>
        <Box className={classes.item}>
          <IconButton disableRipple disabled={value === maxValue} onClick={onAdd} style={{ padding: "5px" }}>
            <AddCircle color={value === maxValue ? "disabled" : "primary"} />
          </IconButton>
        </Box>
      </Box>
    </Paper>
  );
};

export default Counter;
