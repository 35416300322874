import React from "react";
import PropTypes from "prop-types";
import { Button, Card, CardContent, Grid, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
    border: "1px solid #eee",
  },
  cardColumn: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  cardContent: {
    marginTop: theme.spacing(2),
  },
  detailsCardTitle: {
    fontSize: "18px",
    fontWeight: "bold",
    margin: theme.spacing(0, 2),
  },
  payment: {
    fontWeight: "bold",
    fontSize: "22px",
    color: theme.palette.primary.main,
    margin: theme.spacing(2),
  },
}));
function OrderReviewCard({ isCompletedTask, booking, handleRateService }) {
  const classes = useStyles();
  return (
    <Grid className={classes.cardColumn}>
      <Card className={`${classes.card} ${classes.sideSticky}`}>
        <CardContent className={classes.cardContent}>
          <Grid container direction="column">
            <Typography className={classes.detailsCardTitle}>Total amount paid</Typography>
            <Typography className={classes.payment}>{`$ ${(booking?.total / 100).toFixed(2)}`}</Typography>
          </Grid>
          <Grid></Grid>
        </CardContent>
      </Card>
      <div />
    </Grid>
  );
}

OrderReviewCard.propTypes = {
  isCompletedTask: PropTypes.bool,
  booking: PropTypes.object,
  handleRateService: PropTypes.func,
};

export default OrderReviewCard;
