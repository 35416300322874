import dayjs from "dayjs";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const getText = (val) => (val ? `$${val} off if you choose tomorrow or later!` : "");

export const useSameDayDatePickerText = (date, triggers, isRental) => {
  const sameDaySurge = useSelector((state) => state.ui.sameDaySurge.fetchedValue);

  let timer;
  useEffect(() => {
    const addHelperText = () => {
      const elementToRemove = document.getElementById("same-day-pricing-helper-text");
      if (elementToRemove) {
        return;
      }
      const toHijack = document.querySelector(".MuiPickersBasePicker-container");
      if (toHijack) {
        const divElementCss = "width: 100%; margin: 10px auto;";
        const textElementCss = "opacity: 1; transition: all 0.5s ease-in; text-align: center; color: #1E3B47; margin-top: -10px; font-size: 0.9rem;";
        const divElement = document.createElement("div");
        divElement.style.cssText = divElementCss;
        divElement.id = "same-day-pricing-helper-text";
        const textElement = document.createElement("p");
        textElement.style.cssText = textElementCss;
        const textNode = document.createTextNode(getText(sameDaySurge / 100));
        textElement.appendChild(textNode);
        divElement.appendChild(textElement);
        toHijack.appendChild(divElement);
      }
    };
    const removeHelperText = () => {
      const elementToRemove = document.getElementById("same-day-pricing-helper-text");
      if (elementToRemove) {
        elementToRemove.remove();
      }
    };
    const checkShowText = () => {
      if (isRental) return;
      const isSameDay = (date && dayjs().isSame(date, "day")) || !date;
      if (isSameDay) {
        timer = window.setTimeout(() => {
          addHelperText();
        }, 50);
      } else {
        removeHelperText();
      }
    };
    checkShowText();
  }, [date, triggers]);
};
