import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Box, Divider, Grid, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Spacer from "@shared/components/Spacer";
import BookingDetailsSection from "./BookingDetailsSection";
import BookingDetailsItem from "./shared/BookingDetailsItem";
import classes from "@css/components/BookingDetailsLayout.module.css";

function BookingDetailsLayout({ backText, subTitle, title, sections, sectionTitles = [], card, goBack, children, notes }) {
  const history = useHistory();
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleGoBack = () => (goBack ? goBack() : history.goBack());

  return (
    <div>
      <Box className={classes.root}>
        <div className={classes.container}>
          <Grid container>
            <Grid item>
              <div className={classes.header}>
                <KeyboardBackspaceIcon className={classes.backBtn} fontSize="large" onClick={handleGoBack} />
                <div className={classes.titleContainer}>
                  <Typography className={classes.headerText}>{title}</Typography>
                  <Typography className={classes.headerSubtext}>{subTitle}</Typography>
                </div>
              </div>
            </Grid>
          </Grid>
          {notes && (
            <Grid container className={classes.detailsContainer}>
              {notes}
            </Grid>
          )}
          <Grid container className={classes.detailsContainer}>
            <Grid item>
              {sections
                ?.filter((section) => {
                  return section.filter((item) => item.show)?.length > 0;
                })
                .map((section, index) => (
                  <BookingDetailsSection key={index} border>
                    {sectionTitles.length ? <Typography className={classes.sectionTitle}>{sectionTitles[index]}</Typography> : ""}
                    {section
                      .filter((item) => item.show)
                      .map((item, index) => (
                        <BookingDetailsItem
                          key={index}
                          icon={item.icon}
                          block={item.block}
                          render={item.render}
                          label={item.label}
                          greenLabel={item.greenLabel}
                          isTable={item.isTable}
                        />
                      ))}
                  </BookingDetailsSection>
                ))}
              {children && <BookingDetailsSection border>{children}</BookingDetailsSection>}
            </Grid>
          </Grid>
        </div>
        <Grid className={classes.cardWrapper}>{card}</Grid>
      </Box>
    </div>
  );
}

BookingDetailsLayout.propTypes = {
  backText: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  sections: PropTypes.array,
  card: PropTypes.element,
  goBack: PropTypes.func,
  children: PropTypes.element,
};

export default BookingDetailsLayout;
