import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { Button, makeStyles } from "@material-ui/core";
import { taskTypes } from "../../../../constants/booking";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    border: "1px solid #ccc",
    marginTop: theme.spacing(3),
  },
  table: {
    width: "100%",
    maxHeight: "500px",
    borderCollapse: "collapse",
  },
  thead: {
    width: "100%",
  },
  tbody: {
    width: "100%",
  },
  tr: {
    borderBottom: "1px solid #ccc",
    "&:hover": {
      background: "#fafafa",
    },
    "&:active": {
      background: "#eee",
      opacity: 0.5,
    },
    cursor: "pointer",
  },
  td: {
    textAlign: "left",
    padding: 15,
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: "0.9rem",
    fontWeight: 400,
    color: "#000",
    "&:nth-child(1)": {
      fontWeight: 700,
      paddingLeft: 20,
    },
    "&:nth-child(2)": {
      textAlign: "center",
    },
    borderLeft: 0,
  },
  th: {
    textAlign: "left",
    padding: 15,
    fontSize: "0.8rem",
    "&:nth-child(1)": {
      paddingLeft: 20,
    },
  },
  thr: {
    background: "#fafafa",
    borderBottom: "2px solid #ccc",
  },
}));

function BookingsTable({ bookings, showQuotes, actions, isDoItAgain, getStatusAction }) {
  const classes = useStyles();
  return (
    <div className={classes.tableContainer}>
      <table className={classes.table}>
        <thead className={classes.thead}>
          <tr className={classes.thr}>
            <th className={classes.th}>Order #</th>
            <th className={classes.th}>Type</th>
            <th className={classes.th}>Booking Date</th>
            <th className={classes.th}>Location</th>
            {showQuotes && (
              <>
                <th className={classes.th}>Cost</th>
                <th className={classes.th}>Status</th>
              </>
            )}

            <th className={classes.th}></th>
          </tr>
        </thead>
        <tbody className={classes.tbody}>
          {bookings.map((row, index) => (
            <tr key={index} className={classes.tr} onClick={() => actions.handleBookingDetails(e, row.id)}>
              <td className={classes.td} style={{ width: "100px" }}>
                {row.orderNumber}
              </td>
              <td className={classes.td} style={{ width: "80px", textAlign: "left" }}>
                {taskTypes[row.task].label.split(" ")[0]}
              </td>
              <td className={classes.td} style={{ width: "130px", minWidth: 130 }}>
                {`${dayjs(row.date).tz(row.timeZone).format("MMM DD, YYYY")} ${dayjs(row.date).tz(row.timeZone).format("h:mm A")}`}
              </td>
              <td className={classes.td} style={{ width: "auto", minWidth: 200 }}>
                {row.address.location}
              </td>
              {showQuotes && (
                <>
                  <td className={classes.td} style={{ width: "130px" }}>{`$${(row.total / 100).toFixed(2)}`}</td>
                  <td className={classes.td} style={{ width: "200px" }}>
                    {getStatusAction(row)}
                  </td>
                </>
              )}
              <td className={classes.td} style={{ width: "130px", textAlign: "right", borderLeft: "1px solid #eee" }}>
                {showQuotes ? (
                  <Button variant="contained" color="primary" onClick={(e) => actions.goToBookingFlow(e, row)}>
                    Go to Booking
                  </Button>
                ) : (
                  isDoItAgain(row) && (
                    <Button variant="contained" color="primary" onClick={(e) => actions.handleDoItAgain(e, row)}>
                      Do it again
                    </Button>
                  )
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

BookingsTable.defaultProps = {
  rows: [],
};

BookingsTable.propTypes = {
  rows: PropTypes.array,
  actions: PropTypes.object,
  isDoItAgain: PropTypes.func,
  getStatusAction: PropTypes.func,
};

export default BookingsTable;
