import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Checkbox, Grid, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  resetButton: {
    marginRight: "auto",
  },
  title: {
    flex: 1,
    marginLeft: "auto",
    marginRight: "auto",
    color: "black",
    fontWeight: "500",
  },
  innerContainer: {
    padding: theme.spacing(0, 2),
  },
  formFields: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(2),
  },
  formFieldLabel: {
    flex: 0.8,
    display: "flex",
    alignSelf: "flex-start",
    fontWeight: "bold",
    marginLeft: "10px",
  },
  formFieldObject: {
    padding: 0,
  },
}));

const FilterCard = ({ title, items, TitleIcon, onChange, isCollapsable, showMorelabel }) => {
  const classes = useStyles();
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [showAllItems, setShowAllItems] = useState(false);

  const handleSelectAll = () => {
    setIsSelectAll(true);
    const updateCheckboxItems = items.map((item) => ({ ...item, value: true }));
    onChange(updateCheckboxItems);
  };

  const handleResetAll = () => {
    setIsSelectAll(false);
    const updateCheckboxItems = items.map((item) => ({ ...item, value: false }));
    onChange(updateCheckboxItems);
  };

  const updateCheckBoxItem = (item, index) => {
    const updateCheckboxItems = items;
    updateCheckboxItems[index] = { ...item, value: !item.value };
    onChange(updateCheckboxItems);
  };

  const handleCheckBoxChange = (item, index) => {
    setIsSelectAll(false);
    updateCheckBoxItem(item, index);
  };

  const CheckboxField = ({ item, index = 0, onClick }) => {
    const { label, value } = item;
    const [checked, setChecked] = useState(!!value);
    const handleClick = () => {
      setChecked(!checked);
      onClick();
    };
    const isHideItem = isCollapsable && !showAllItems && index > 2;
    return (
      <div key={`box-${item.label}-${index}`} className={classes.formFields} style={{ display: isHideItem ? "none" : "flex" }}>
        <Checkbox key={`checkbox-${label}-${index}`} className={classes.formFieldObject} color="primary" checked={checked} onClick={handleClick} />
        <Typography variant="body1" className={classes.formFieldLabel} color={index === "selectAll" ? "primary" : "inherit"}>
          {label}
        </Typography>
      </div>
    );
  };

  return (
    <Grid className={classes.container}>
      <Grid container alignItems="center">
        {TitleIcon}
        <Typography variant={"h6"} className={classes.title}>
          {title}
        </Typography>
      </Grid>
      <CheckboxField item={{ label: "Select All", value: isSelectAll }} onClick={() => handleSelectAll()} />
      <Grid className={classes.innerContainer}>
        {items.map((item, index) => (
          <CheckboxField key={`box-${item.label}-${index}`} item={item} index={index} onClick={() => handleCheckBoxChange(item, index)} />
        ))}
        {isCollapsable && !showAllItems && (
          <Button className={classes.resetButton} onClick={() => setShowAllItems(true)}>
            {showMorelabel} <ExpandMoreIcon />
          </Button>
        )}
      </Grid>
      <Button color="primary" className={classes.resetButton} onClick={handleResetAll}>
        Reset
      </Button>
    </Grid>
  );
};

FilterCard.defaultProps = {
  isCollapsable: false,
  showMorelabel: "Show more",
  items: [],
};

export default FilterCard;
