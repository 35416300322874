import dayjs from "dayjs";

import resetDate from "@shared/utils/resetDate";

import FieldError from "./errors/FieldError";
import { getSoonestBusinessDate } from "./utils";
import { isValidPhoneNumber } from "react-phone-number-input";
import { validateEmail } from "@helpers/validators";
import { verifyMobileNumber } from "@store/actions/twilio";

import {
  DateFieldErrorCode,
  Input,
  JunkFieldErrorCode,
  DumpsterFieldErrorCode,
  RequiredTextFieldErrorCode,
  TermsOfServiceFieldErrorCode,
  TimeFieldErrorCode,
  PhoneFieldErrorCode,
  VehicleFieldErrorCode,
  EmailFieldErrorCode,
  AddressFieldErrorCode,
  companyNameFieldErrorCode,
  DateFieldOutOfRangeErrorCode,
} from "./enums";
import _debounce from "debounce";

export const valid = {
  validate: true,
  error: null,
  isValid: true,
};

/**
 * Common Validators
 */

const requiredTextFieldValidator = (field, min = 0) => {
  const value = field?.value;

  if (!value) {
    return {
      ...field?.validation,
      isValid: false,
      error: new FieldError(RequiredTextFieldErrorCode.required),
    };
  }

  if (value?.length < min) {
    const remaining = min - value?.length;
    return {
      ...field?.validation,
      isValid: false,
      error: new FieldError(`Please enter ${remaining} more characters!`),
    };
  }

  return valid;
};

/**
 * Book Form validators.
 */
const taskValidator = (field) => {
  const { value } = field;

  if (!value) {
    return {
      ...field?.validation,
      isValid: false,
      error: new FieldError(RequiredTextFieldErrorCode.required),
    };
  }

  return valid;
};

const businessClientValidator = () => valid;

/**
 * Date and Time Form validators.
 */
const dateValidator = (field, minDate, maxDate) => {
  const { value } = field;
  const isValid = dayjs.isDayjs(value);

  if (!value || !isValid) {
    return {
      ...field?.validation,
      isValid: false,
      error: new FieldError(DateFieldErrorCode.required),
    };
  }

  const soonestBusinessDate = resetDate(getSoonestBusinessDate());

  if (value.isBefore(soonestBusinessDate)) {
    return {
      ...field?.validation,
      isValid: false,
      error: new FieldError(DateFieldErrorCode.required),
    };
  }

  if ((maxDate && value.isAfter(maxDate)) || (minDate && value.isBefore(minDate))) {
    return {
      ...field?.validation,
      isValid: false,
      error: new FieldError(DateFieldOutOfRangeErrorCode.required),
    };
  }

  return valid;
};

// @todo: Would be better to create a FieldValidators type that accept a tuple of fields
// as generic type. Not sure if it's possible though.
// Discussion here: https://github.com/Microsoft/TypeScript/issues/5453

const timeValidator = (dateField, timeField) => {
  const dateValue = dateField?.value;
  const timeValue = timeField?.value;
  const isDateValid = dayjs.isDayjs(dateValue);

  if (!dateValue || !timeValue || !isDateValid) {
    return {
      ...timeField?.validation,
      isValid: false,
      error: new FieldError(DateFieldErrorCode.required),
    };
  }

  const soonestBusinessDate = getSoonestBusinessDate();
  const dateTime = dateValue.set("hour", timeValue.hour).set("minute", timeValue.minute);

  if (dateTime.isBefore(soonestBusinessDate)) {
    return {
      ...timeField?.validation,
      isValid: false,
      error: new FieldError(TimeFieldErrorCode.invalidDate),
    };
  }

  return valid;
};

const frequencyValidator = () => valid;

const pickupValidator = () => valid;

const pickupDateValidator = () => valid;

const pickupTimeValidator = () => valid;

const stairsValidator = () => valid;

const dismantlingValidator = () => valid;

const taskImagesValidator = () => valid;
/**
 * Task Description validators.
 */
const vehicleValidator = (field) => {
  const { value } = field;

  if (!value) {
    return {
      ...field?.validation,
      isValid: false,
      error: new FieldError(VehicleFieldErrorCode.required),
    };
  }

  return valid;
};

const junkValidator = (field) => {
  const { value } = field;

  if (!value) {
    return {
      ...field?.validation,
      isValid: false,
      error: new FieldError(JunkFieldErrorCode.required),
    };
  }

  if (!value.length) {
    return {
      ...field?.validation,
      isValid: false,
      error: new FieldError(JunkFieldErrorCode.invalidJunk),
    };
  }

  return valid;
};

const dumpsterValidator = (field) => {
  const { value } = field;

  if (!value.length) {
    return {
      ...field?.validation,
      isValid: false,
      error: new FieldError(DumpsterFieldErrorCode.invalidDumpster),
    };
  }

  const materials = value.every((item) => item.item.items);

  if (!materials) {
    return {
      ...field?.validation,
      isValid: false,
      error: new FieldError(DumpsterFieldErrorCode.itemsRequired),
    };
  }

  return valid;
};

/**
 * Address validators.
 */

const pickupAddressValidator = (field) => {
  const { value } = field;

  if (!value?.location) {
    return {
      ...field?.validation,
      isValid: false,
      error: new FieldError(AddressFieldErrorCode.required),
    };
  }

  return valid;
};

const pickupAddressAdditionalValidator = () => valid;

const companyNameValidator = (field, booking) => {
  const { value } = field;
  const isBusinessClient = booking.commonForm[Input.BusinessClient].value;

  if (isBusinessClient && !value) {
    return {
      ...field?.validation,
      isValid: false,
      error: new FieldError(companyNameFieldErrorCode.required),
    };
  }

  return valid;
};

/**
 * Review Order validators.
 */
const termsOfServiceValidator = (field) => {
  const { value } = field;

  if (!value) {
    return {
      ...field?.validation,
      isValid: false,
      error: new FieldError(TermsOfServiceFieldErrorCode.required),
    };
  }

  return valid;
};

/**
 * Payment form validator
 */

const paymentFirstNameValidator = requiredTextFieldValidator;

const paymentLastNameValidator = () => valid;

const paymentEmailValidator = (field) => {
  const value = field?.value;

  if (!value) {
    return {
      ...field?.validation,
      isValid: false,
      error: new FieldError(EmailFieldErrorCode.required),
    };
  }

  if (value && !validateEmail(value)) {
    return {
      ...field?.validation,
      isValid: false,
      error: new FieldError(EmailFieldErrorCode.invalidEmail),
    };
  }

  return valid;
};

const phoneNumberValidator = async (field) => {
  const value = field?.value;

  if (!value) {
    return {
      ...field?.validation,
      isValid: false,
      error: new FieldError(PhoneFieldErrorCode.required),
      invalidCarrierType: false,
    };
  }

  const trials = sessionStorage.getItem("phone_trials") || 0;

  if (parseInt(trials) === 10) {
    return {
      ...field?.validation,
      isValid: false,
      error: new FieldError(PhoneFieldErrorCode.maxTrials),
      invalidCarrierType: false,
    };
  }

  if (!isValidPhoneNumber(value)) {
    return {
      ...field?.validation,
      isValid: false,
      error: new FieldError(PhoneFieldErrorCode.invalidPhone),
      invalidCarrierType: false,
    };
  }

  const carrierType = await verifyMobileNumber(value);

  sessionStorage.setItem("phone_trials", parseInt(trials) + 1);

  if (carrierType !== "mobile") {
    return {
      ...field?.validation,
      isValid: false,
      error: new FieldError(PhoneFieldErrorCode.invalidMobile(carrierType)),
      invalidCarrierType: true,
      carrierType,
    };
  }

  return valid;
};

/**
 * only for Dumpster rental
 */

const understandPricingValidtor = (field) => {
  const { value } = field;

  if (!value) {
    return {
      ...field?.validation,
      isValid: false,
      error: new FieldError(DumpsterFieldErrorCode.understandPricing),
    };
  }

  return valid;
};

const validators = {
  [Input.Task]: taskValidator,
  [Input.BusinessClient]: businessClientValidator,
  [Input.Date]: dateValidator,
  [Input.Time]: timeValidator,
  [Input.Pickup]: pickupValidator,
  [Input.PickupDate]: pickupDateValidator,
  [Input.PickupTime]: pickupTimeValidator,
  [Input.Frequency]: frequencyValidator,
  [Input.Vehicle]: vehicleValidator,
  [Input.Junk]: junkValidator,
  [Input.Dumpster]: dumpsterValidator,
  [Input.Stairs]: stairsValidator,
  [Input.Dismantling]: dismantlingValidator,
  [Input.TaskImages]: taskImagesValidator,
  [Input.Description]: requiredTextFieldValidator,
  [Input.PickupAddress]: pickupAddressValidator,
  [Input.PickupAddressAdditional]: pickupAddressAdditionalValidator,
  [Input.CompanyName]: companyNameValidator,
  [Input.TermsOfService]: termsOfServiceValidator,
  [Input.PaymentFirstName]: paymentFirstNameValidator,
  [Input.PaymentLastName]: paymentLastNameValidator,
  [Input.PaymentEmail]: paymentEmailValidator,
  [Input.PaymentPhone]: phoneNumberValidator,
  [Input.UnderstandPricing]: understandPricingValidtor,
};

export default validators;
