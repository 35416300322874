import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, useMediaQuery } from "@material-ui/core";
import ImageGallery from "react-image-gallery";

import Gallery from "./Gallery";
import Carousel from "./Carousel";

const ImageGalleryCustom = ({ images, height = "auto", size = "small", grid, download = false, ...rest }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleSelectImage = (index) => {
    setSelectedImageIndex(index);
    setOpen(true);
  };

  if (images.length === 0) return null;

  const imageList = images?.map((image) => ({ original: image, thumbnail: image, originalHeight: 300 }));

  return isMobile ? (
    <Box style={{ height: 400, background: "#fafafa", borderRadius: 4 }}>
      <ImageGallery items={imageList} showPlayButton={false} />
    </Box>
  ) : (
    <Box style={{ height }}>
      <Gallery images={images} onSelect={handleSelectImage} {...rest} size={size} grid={grid} />
      <Carousel images={images} open={open} index={selectedImageIndex} onChange={setSelectedImageIndex} onClose={() => setOpen(false)} {...rest} />
    </Box>
  );
};

ImageGalleryCustom.defaultProps = {
  images: [],
  height: "auto",
  size: "small",
  grid: false,
  download: false,
};

ImageGalleryCustom.propTypes = {
  images: PropTypes.array,
  size: PropTypes.string,
  grid: PropTypes.bool,
  download: PropTypes.bool,
};

export default ImageGalleryCustom;
