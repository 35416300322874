/* eslint-disable */
import * as yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";
import { validateEmail } from "@helpers/validators";

export const validationSchema = () =>
  yup.object().shape({
    phoneNumber: yup
      .string()
      .required()
      .test("phone-validation", "Invalid format!", (value) => (value ? isValidPhoneNumber(value) : true)),
    fullName: yup.string(),
    newEmail: yup
      .string()
      .required()
      .test("email-validation", "Invalid format!", (value) => (value ? validateEmail(value) : true)),
    newPassword: yup.string(),
    oldPassword: yup.string(),
  });

export const businessAccountValidationSchema = () =>
  yup.object().shape({
    servicePhone: yup
      .string()
      .required()
      .test("phone-validation", "Invalid format!", (value) => (value ? isValidPhoneNumber(value) : true)),
    companyName: yup.string().required(),
    ownerEmail: yup
      .string()
      .required()
      .test("email-validation", "Invalid format!", (value) => (value ? validateEmail(value) : true)),
    serviceAddress: yup.string(),
  });
