import * as yup from "yup";

export const priceMultipliers = {
  "junk-removal": 100,
  "cardboard-removal": 100,
  "dumpster-rental": 100,
  "power-washing": 100,
};

export const payoutPercent = {
  "junk-removal": 65,
  "cardboard-removal": 65,
  "dumpster-rental": 65,
  "power-washing": 65,
};

export const pricingFormDefaults = (data) => ({
  name: data?.name ?? "",
  address: data?.address ?? {},
  center: data?.center ?? {},
  isLive: data?.isLive ?? false,
  distance: data?.distance ?? 5,
  isRadiusBased: data?.isRadiusBased ?? true,
  isAreaBased: data?.isAreaBased ?? false,
  pr_ml_junk_removal: data?.priceMultipliers["junk-removal"] ?? priceMultipliers["junk-removal"],
  pr_ml_cardboard_removal: data?.priceMultipliers["cardboard-removal"] ?? priceMultipliers["cardboard-removal"],
  pr_ml_dumpster_rental: data?.priceMultipliers["dumpster-rental"] ?? priceMultipliers["dumpster-rental"],
  // pr_ml_power_washing: data?.priceMultipliers['power-washing'] ?? priceMultipliers['power-washing'],
  py_pc_junk_removal: data?.payoutPercent["junk-removal"] ?? payoutPercent["junk-removal"],
  py_pc_cardboard_removal: data?.payoutPercent["cardboard-removal"] ?? payoutPercent["cardboard-removal"],
  py_pc_dumpster_rental: data?.payoutPercent["dumpster-rental"] ?? payoutPercent["dumpster-rental"],
  // py_pc_power_washing: data?.payoutPercent['power-washing'] ?? payoutPercent['power-washing'],
  maxBooking: data?.maxBooking ?? 100,
  slotRadius: data?.slotRadius ?? 10,
  googleListing: data?.googleListing ?? "",
  yelpListing: data?.yelpListing ?? "",
});

export const pricingFormSchema = yup.object().shape({
  name: yup.string().optional(),
  address: yup.object().shape({
    location: yup.string().required("Invalid Location !"),
    coordinates: yup.object().required("Cannot find coordinates"),
    placeId: yup.string().required("Invalid Location"),
    zipCode: yup.string().required("Cannot find zip"),
    city: yup.string().optional(),
  }),
  center: yup.object().optional(),
  isRadiusBased: yup.boolean().required(),
  isAreaBased: yup.boolean().required(),
  distance: yup.number("Invalid number").positive("Distance is invalid !").typeError("Must be a number").moreThan(0).required(),
  isLive: yup.boolean().optional(),
  pr_ml_junk_removal: yup.number("Invalid number").positive("Invalid !").moreThan(0).typeError("Must be a number").required(),
  pr_ml_cardboard_removal: yup.number("Invalid number").positive("Invalid !").moreThan(0).typeError("Must be a number").required(),
  pr_ml_dumpster_rental: yup.number("Invalid number").positive("Invalid !").moreThan(0).typeError("Must be a number").required(),
  // pr_ml_power_washing: yup.number('Invalid number').positive('Invalid !').moreThan(0).typeError('Must be a number').required(),
  py_pc_junk_removal: yup.number("Invalid number").positive("Invalid !").moreThan(0).typeError("Must be a number").required(),
  py_pc_cardboard_removal: yup.number("Invalid number").positive("Invalid !").moreThan(0).typeError("Must be a number").required(),
  py_pc_dumpster_rental: yup.number("Invalid number").positive("Invalid !").moreThan(0).typeError("Must be a number").required(),
  // py_pc_power_washing: yup.number('Invalid number').positive('Invalid !').moreThan(0).typeError('Must be a number').required(),
  maxBooking: yup.number("Invalid number").positive("Invalid !").moreThan(0).typeError("Must be a number").required(),
  slotRadius: yup.number("Invalid number").positive("Invalid !").moreThan(0).typeError("Must be a number").required(),
  googleListing: yup.string().optional(),
  yelpListing: yup.string().optional(),
});

export const discountPrice = {
  $200: 20000,
  $500: 50000,
};

export const applicableTasksforDiscount = ["junk-removal", "cardboard-removal"];

// item pricing

export const defaultSubItemPricing = {
  description: "",
  isHelperNeeded: false,
  name: "",
  price: 0,
};

export const defaultItemPricing = {
  item: "",
  avatar: "",
  tags: [],
  tasks: {
    "cardboard-removal": false,
    "dumpster-rental": false,
    "junk-removal": true,
  },
  types: [
    {
      ...defaultSubItemPricing,
    },
  ],
};
