import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import dayjs from "dayjs";

import { capitalize } from "@shared/utils/methods";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "40px 0px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  myReviews: {
    fontSize: "25px",
    fontWeight: "bold",
    margin: "30px 0px",
  },
  reviewBox: {
    paddingBottom: "20px",
  },
}));

const Review = (review) => {
  const classes = useStyles();

  return (
    <Box className={classes.reviewBox}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ fontWeight: "bold", flex: "1" }}>
          {capitalize(review.reviewer)}, {dayjs(review.createdAt).format("DD/MM/YYYY")}
        </div>
        <Rating name="simple-controlled" value={review.rating} precision={0.5} style={{ color: "#1E3B47" }} size="small" readOnly />
      </div>
      <div style={{ color: "#1E3B47", padding: "10px 0px" }}>{review.task}</div>
      <div>{review.comment}</div>
    </Box>
  );
};

Review.defaultProps = {
  review: {},
};

export default Review;
