import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField, Box } from "@material-ui/core";
import { sendPasswordResetEmail, confirmPasswordReset } from "@store/actions/auth";
import authConstants from "@store/constants/authTypes";
import { validateEmail } from "@helpers/validators";
import history from "@history";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  textField: {
    width: "100%",
    marginBottom: "30px",
  },
  resetButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    width: "250px",
    "&:hover": {
      backgroundColor: theme.palette.primary.hover,
    },
  },
  signInButton: {
    width: "250px",
    "&:hover": {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
}));

const InitialStep = ({ email, setEmail, errorEmail, setErrorEmail, handleSubmit, hideSignIn = false }) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.container}>
        <TextField
          name="email"
          label="Email"
          variant="outlined"
          value={email}
          error={!!errorEmail}
          helperText={errorEmail}
          onChange={(e) => {
            errorEmail && setErrorEmail("");
            setEmail(e.target.value?.trim());
          }}
          className={classes.textField}
        />
      </Box>
      <Grid container justifyContent="flex-start">
        {!hideSignIn && (
          <Box clone order={{ xs: 2, sm: 1 }}>
            <Grid item style={{ flex: 1 }} align="center">
              <Button onClick={() => history.push("/auth/signin")} color="primary" className={classes.signInButton}>
                Back to Sign in
              </Button>
            </Grid>
          </Box>
        )}
        <Box clone order={{ xs: 1, sm: 2 }}>
          <Grid item style={{ flex: 1 }} align="center">
            <Button variant="contained" onClick={handleSubmit} className={classes.resetButton} disabled={!email}>
              Reset Password
            </Button>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

const FinalStep = ({ newPassword, setNewPassword, confirmPassword, setConfirmPassword, handleConfirmPassword, errorPassword, setErrorPassword }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <TextField
        name="newPassword"
        label="New Password"
        type="password"
        variant="outlined"
        value={newPassword}
        helperText="Must be 6 or more characters and contain at least 1 number and 1 letter."
        onChange={(e) => {
          errorPassword && setErrorPassword("");
          setNewPassword(e.target.value);
        }}
        className={classes.textField}
      />
      <TextField
        name="confirmPassword"
        label="Confirm Password"
        type="password"
        variant="outlined"
        value={confirmPassword}
        error={!!errorPassword}
        helperText={errorPassword}
        onChange={(e) => {
          setConfirmPassword(e.target.value);
        }}
        className={classes.textField}
      />
      <Button variant="contained" onClick={handleConfirmPassword} className={classes.resetButton} disabled={!newPassword || !confirmPassword}>
        Confirm
      </Button>
    </Box>
  );
};

const ResetPassword = ({ email: propsEmail, hideSignIn }) => {
  const dispatch = useDispatch();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const [email, setEmail] = useState(propsEmail ?? "");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const isResetMode = useQuery()?.get("mode") === "resetPassword";
  const actionCode = useQuery()?.get("oobCode");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateEmail(email)) {
      dispatch(sendPasswordResetEmail(email));
      dispatch({ type: authConstants.CLEAR_MIGRATION_PWD });
    } else {
      setErrorEmail("Invalid Email");
      return;
    }
  };

  const handleConfirmPassword = (e) => {
    e.preventDefault();
    if (!newPassword.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{6,}$/)) {
      setErrorPassword("Must be 6 or more characters and contain at least 1 number and 1 letter.");
      return;
    }
    if (newPassword === confirmPassword) {
      dispatch(confirmPasswordReset(actionCode, newPassword));
    } else {
      setErrorPassword("Password mismatch");
      return;
    }
  };

  return (
    <>
      {isResetMode ? (
        <FinalStep
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          handleConfirmPassword={handleConfirmPassword}
          errorPassword={errorPassword}
          setErrorPassword={setErrorPassword}
        />
      ) : (
        <InitialStep
          email={email}
          setEmail={setEmail}
          hideSignIn={hideSignIn}
          handleSubmit={handleSubmit}
          errorEmail={errorEmail}
          setErrorEmail={setErrorEmail}
        />
      )}
    </>
  );
};

export default ResetPassword;
