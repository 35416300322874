import { loginFormDefaults, loginFormSchema } from "@constants/auth";
import classes from "@css/components/SignIn.module.css";
import history from "@history";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Typography } from "@material-ui/core";
import Button from "@shared/components/Button";
import InputField from "@shared/components/Form/InputField";
import Modal from "@shared/components/Modal";
import { signInWithEmail } from "@store/actions/auth";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { roles } from "../../constants/roles";
import AuthRedirector from "./AuthRedirector";
import ResetPassword from "./ResetPasswod";

const MigratePwdReset = ({ open, setOpen }) => {
  const email = useSelector((state) => state.firebase.auth.email);

  return (
    <div style={{ marginTop: "30px" }}>
      <Modal
        title={
          "Hi there! We have updated our password policy. We have just sent you an email. Please follow the link inside the email to reset your password. Thanks."
        }
        containerClass={classes.container}
        body={<ResetPassword email={email} hideSignIn onClose={() => setOpen(false)} />}
        open={open}
        setOpen={setOpen}
      />
    </div>
  );
};

const SignIn = ({ toggleMode, onClose }) => {
  const dispatch = useDispatch();
  const [migratePwdReset, setMigratePwdReset] = useState(false);

  const isBusiness = useSelector((state) => state.tempData.userType) === roles.business.value;

  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    setMigratePwdReset(auth.migratePwdReset);
  }, [auth.migratePwdReset]);

  const {
    control: loginControl,
    handleSubmit: loginSubmit,
    errors: loginErrors,
  } = useForm({
    defaultValues: loginFormDefaults,
    resolver: yupResolver(loginFormSchema),
  });

  const onLoginSubmit = async (data) => {
    await dispatch(
      signInWithEmail({
        email: data.email,
        password: data.password,
        callback: onClose,
      })
    );
  };

  const inputProps = {
    control: loginControl,
    errors: loginErrors,
    className: classes.textField,
  };

  const handleForgot = (e) => {
    e.preventDefault();
    if (onClose) {
      onClose();
    }
    history.push("/auth/reset_pw");
  };

  const handleToggleMode = () => {
    if (toggleMode) {
      toggleMode();
    } else {
      history.push("/auth/register");
    }
  };

  return (
    <>
      <AuthRedirector />

      {auth.migratePwdReset && <MigratePwdReset open={migratePwdReset} setOpen={setMigratePwdReset} />}

      <form className={classes.form} onSubmit={loginSubmit(onLoginSubmit)}>
        <Box style={{ marginBottom: "50px" }}>
          <InputField name="email" label="Email" {...inputProps} />
          <InputField name="password" type="password" label="Password" {...inputProps} />
          <Typography className={classes.forgotPassword} onClick={handleForgot}>
            Forgot password?
          </Typography>
        </Box>

        <Box style={{ display: "grid", width: "100%", gridTemplateColumns: "1fr 1fr", columnGap: "16px" }}>
          <Button variant="outlined" onClick={handleToggleMode} color="primary" className={classes.registerHere}>
            Register here
          </Button>
          <Button type="submit" variant="contained" className={classes.signIn}>
            Sign in
          </Button>
        </Box>
      </form>
    </>
  );
};

export default SignIn;
