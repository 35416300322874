import React, { useEffect, useState } from "react";
import { ArrayParam, BooleanParam, StringParam, useQueryParam } from "use-query-params";

import url from "@assets/complete_task.svg";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid, Typography, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import ImageHandlerForm from "../CompleteTask/components/ImageHandlerForm";
import Icon from "@material-ui/core/Icon";
import history from "@history";
import { formatBookingId } from "@helpers/booking";
import { updateBooking } from "@store/actions/booking";
import tempDataConstants from "@store/constants/tempDataTypes";
import configConstants from "@store/constants/configTypes";
import { confirmationPageSurevyOptions } from "@constants/booking";
import { segmentTrack } from "@helpers/segment";
import { roles } from "../../constants/roles";
import { scrollToTop } from "../../methods/scrollToTop";
import { getLinkWithGclid } from "../../history";
import { fetchBidByNumber } from "../../store/actions/bookingBids";
import BidConfirmation from "./BidConfirmation";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
    minHeight: "calc(100vh - 174px)",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
      maxWidth: "100vw",
      padding: theme.spacing(1, 4, 4, 2),
    },
  },
  iconColumn: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    "& > span": {
      // checkbox icon
      fontSize: "42px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(2),
      display: "flex",
      justifyContent: "center",
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "15px",
    },
  },
  detailsColumn: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(2),
    },
  },
  subHeader: {
    marginTop: theme.spacing(3),
  },
  logoColumn: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  logoColumnLogo: {
    marginTop: "auto",
    marginBottom: "auto",
    [theme.breakpoints.up("md")]: {
      backgroundImage: `url(${url})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundPosition: "center",
      height: "482px", //2x dimensions of SVG image complete_task.svg
      width: "254px",
      marginRight: "25px",
    },
  },
  orderNumbers: {
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    },
    "& > b": {
      whiteSpace: "nowrap",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },
  },
  skipBox: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "& > button": {
      alignSelf: "center",
      width: "80%",
    },
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
    [theme.breakpoints.down("sm")]: {
      "& button": {
        width: "100%",
        zoom: "90%",
      },
    },
  },
  signInBox: {
    display: "flex",
    flexDirection: "column",
    "& > button": {
      alignSelf: "center",
      width: "80%",
      marginTop: "10px",
      marginBottom: "10px",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  fieldBox: {
    marginTop: theme.spacing(3),
    "& > h6": {
      color: "black",
      fontWeight: "500",
      fontSize: "16px",
    },
  },
  surveyDataField: {
    display: "flex",
    marginTop: "10px",
  },
  survey: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80vw",
    },
  },
}));

const ConfirmationPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [bookingParamsId] = useQueryParam("id", StringParam);
  const [isMultiOrders = false] = useQueryParam("multipleOrders", BooleanParam);
  const [orderNumber] = useQueryParam("order_number", isMultiOrders ? ArrayParam : StringParam);
  const [bidNumber] = useQueryParam("bid_number");
  const [type] = useQueryParam("type");
  const [bidInfo, setBidInfo] = useState(null);
  const isBidConfirmation = type === "bid";
  const createdBooking = useSelector((state) => state.booking.newBooking);
  const auth = useSelector((state) => state.firbase?.auth);

  const newBooking = isMultiOrders ? {} : isBidConfirmation ? bidInfo : createdBooking;

  const bookingId = isMultiOrders ? null : bookingParamsId || newBooking?.booking_id;

  const [state, setState] = useState({
    isSignedIn: auth?.uid,
    uploads: [],
    surveyedData: "",
    isUploading: false,
  });

  scrollToTop();

  const getBid = async () => {
    if (isBidConfirmation && bidNumber) {
      const bidData = await fetchBidByNumber(bidNumber);
      setBidInfo(bidData);
    }
  };
  useEffect(() => {
    getBid();
  }, []);

  useEffect(() => {
    if (isBidConfirmation || isMultiOrders) return;

    dispatch({ type: configConstants.SET_CONFIG_CACHED });

    if (!bookingId) {
      history.replace("/");
      return;
    }
    if (newBooking?.booking_id) {
      if (!auth?.uid) {
        // If we're not signed in we'll want to save the booking details in local storage
        let currentBooking = JSON.parse(localStorage.getItem("newBooking"));
        if (currentBooking === null) {
          localStorage.setItem("newBooking", JSON.stringify([].concat(newBooking)));
        } else {
          if (Array.isArray(currentBooking)) {
            // If already an array we add to it.
            localStorage.setItem("newBooking", JSON.stringify(currentBooking.concat(newBooking)));
          } else {
            // If not an array we'll make it an array. This shouldn't be the case.
            const currentData = [].concat(currentBooking);
            localStorage.setItem("newBooking", JSON.stringify([...currentData, newBooking]));
          }
        }
      }
      segmentTrack("Pictures Requested");
    }
  }, []);

  const handleSubmit = () => {
    const form = {
      taskImages: state.uploads,
      surveyedData: state.surveyedData,
    };
    if (state.uploads?.length > 0) {
      segmentTrack("Pictures Uploaded");
    }
    dispatch(updateBooking({ id: bookingId, form }));
    history.push(getLinkWithGclid(`/bookings/${bookingId}`));
  };

  const setImageUrls = (destination, urlArray) => {
    setState({ ...state, [destination]: urlArray });
  };

  const setLoaderState = (variable, value) => {
    setState({ ...state, [variable]: value });
  };

  const handleSurveySelect = (e) => {
    setState({ ...state, surveyedData: e.target.value });
  };

  const handleDone = () => {
    history.push("/booking?step=initial");
  };

  const getBookingIds = () => {
    if (isMultiOrders) {
      return orderNumber.join(", ");
    } else {
      return formatBookingId(newBooking?.booking?.orderNumber ?? newBooking?.booking_id ?? orderNumber);
    }
  };

  if (isBidConfirmation) {
    return <BidConfirmation classes={classes} bidInfo={bidInfo} handleDone={handleDone} />;
  }

  return (
    <Grid container className={classes.root}>
      <Grid item sm={12} xs={12} md={1} className={classes.iconColumn}>
        <Icon className={`fas fa-check-circle ${classes.icon}`} color="primary" />
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={classes.detailsColumn}>
        <Typography variant={isMultiOrders ? "h4" : "h5"}>
          Your order {isMultiOrders ? "numbers are" : "number is"}{" "}
          <span className={classes.orderNumbers} onClick={() => history.push("/")}>
            <b>{getBookingIds()}</b>
          </span>{" "}
          for your record.
        </Typography>
        {!isMultiOrders && (
          <>
            <Typography variant="body1" className={classes.subHeader}>
              The following 2 questions are optional but highly recommended, to ensure a good service experience.
            </Typography>
            <div className={classes.fieldBox}>
              <Typography variant="h6">Where did you first hear about Dimension?</Typography>
              <FormControl variant="outlined" fullWidth>
                <div className={classes.surveyDataField}>
                  <InputLabel id="surveyOptions">Survey</InputLabel>
                  <Select
                    fullWidth
                    className={classes.survey}
                    labelId="surveyOptions"
                    label="Survey"
                    placeholder="Survey"
                    value={state.surveyedData}
                    onChange={handleSurveySelect}
                  >
                    {confirmationPageSurevyOptions.map((option, index) => (
                      <MenuItem value={option.label} key={index}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <Button color="primary" onClick={() => setState((prevState) => ({ ...prevState, surveyedData: "" }))}>
                    Clear
                  </Button>
                </div>
              </FormControl>
            </div>
            <div className={classes.fieldBox}>
              <Typography variant="h6" className={classes.uploadDescription}>
                <strong>Upload task-related images</strong>
              </Typography>
              <ImageHandlerForm
                baseId={bookingId}
                basePath={"bookingImages"}
                maxImageCount={10}
                images={[]}
                setImageUrls={setImageUrls}
                destinationPath={"uploads"}
                loaderState={"isUploading"}
                setLoaderState={setLoaderState}
              />
            </div>
            <div className={classes.skipBox}>
              <Button
                color="primary"
                variant="outlined"
                disabled={!(state.uploads.length || state.surveyedData) || state.isUploading}
                onClick={handleSubmit}
              >
                {state.isUploading ? "Uploading Image" : "Submit"}
              </Button>
              <Button
                color="primary"
                disabled={!!(state.uploads.length || state.surveyedData || state.isUploading)}
                onClick={() => history.push(getLinkWithGclid(`/bookings/${bookingId}`))}
              >
                Skip
              </Button>
            </div>
          </>
        )}
        {isMultiOrders && (
          <div className={classes.skipBox}>
            <Button style={{ width: "230px", marginTop: 24 }} color="primary" variant="contained" onClick={() => history.push("/")}>
              Back to my bookings
            </Button>
          </div>
        )}
        {!state.isSignedIn && (
          <div className={classes.signInBox}>
            <Button
              color="primary"
              disabled={state.isUploading}
              onClick={() => {
                dispatch({
                  type: tempDataConstants.SET_TEMP_USER,
                  userType: roles.customer.value,
                });
                history.replace("/auth/signin");
              }}
              variant="contained"
            >
              Already have an account? <br />
              Sign in
            </Button>
            <Button
              color="primary"
              disabled={state.isUploading}
              onClick={() => {
                dispatch({
                  type: tempDataConstants.SET_TEMP_USER,
                  userType: roles.customer.value,
                });
                history.replace("/auth/register");
              }}
              variant="outlined"
            >
              Sign up to track your order
            </Button>
          </div>
        )}
      </Grid>
      <Grid item md={5} className={classes.logoColumn}>
        <div className={classes.logoColumnLogo} />
      </Grid>
    </Grid>
  );
};

export default ConfirmationPage;
