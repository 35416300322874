import React, { useEffect } from "react";
import classnames from "classnames";
import { makeStyles, Box, Typography } from "@material-ui/core";

import validators from "@booking/helpers/validators";
import { isError, errorCode } from "@booking/helpers/utils";
import { Input } from "@booking/helpers/enums";

import TextField from "@booking/helpers/fields/TextField";

import HouseIcon from "@material-ui/icons/House";
import BusinessIcon from "@material-ui/icons/Business";

import { useBookingPageContext } from "../../context";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(3, 0),
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  item: {
    position: "relative",
    height: 170,
    width: 170,
    [theme.breakpoints.down("md")]: {
      height: 140,
      width: 140,
    },
    border: "2px solid rgba(0,0,0,0.05)",
    borderRadius: "5px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    whiteSpace: "nowrap",
  },
  activeItem: {
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
  },
  icon: {
    color: "grey",
    margin: theme.spacing(1),
  },
  activeIcon: {
    color: theme.palette.primary.main,
  },
  iconLabel: {
    color: "grey",
    fontSize: "1rem",
  },
  typography: {
    opacity: "0.5",
    marginBottom: theme.spacing(2),
  },
}));

const BusinessClientIconsComponent = ({ field, onChange, onChangeCompanyName, companyNameField }) => {
  const classes = useStyles();
  const { value } = field;
  const booking = useBookingPageContext();

  useEffect(() => {
    onChange({ validation: validators[Input.BusinessClient](field) });
    onChangeCompanyName({ validation: validators[Input.CompanyName](companyNameField, booking) });
  }, [value]);

  const handleChange = (nextValue) => {
    booking.setCommonForm({ [Input.BusinessClient]: { value: nextValue } });
    onChange({ value: nextValue });
  };

  return (
    <>
      <Box className={classes.container}>
        <Box mr={3} className={classnames(classes.item, { [classes.activeItem]: !value })} onClick={() => handleChange(false)}>
          <HouseIcon fontSize="large" className={classnames(classes.icon, !value ? classes.activeIcon : null)} />
          <Box className={classes.iconLabel}>Residential</Box>
        </Box>
        <Box className={classnames(classes.item, { [classes.activeItem]: value })} onClick={() => handleChange(true)}>
          <BusinessIcon fontSize="large" className={classnames(classes.icon, value ? classes.activeIcon : null)} />
          <Box className={classes.iconLabel}>Business Client</Box>
        </Box>
      </Box>
      {value && (
        <TextField
          label="Company Name"
          field={companyNameField}
          validator={validators[Input.CompanyName]}
          onChange={onChangeCompanyName}
          error={isError(companyNameField.validation)}
          helperText={errorCode(companyNameField.validation)}
        />
      )}
    </>
  );
};

export default React.memo(BusinessClientIconsComponent);
