import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

import logoUrl from "@assets/landing_page/dimension-logo-dark.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 800,
    fontSize: "1rem",
    color: theme.palette.secondary.main,
  },
  subTitle: {
    lineHeight: ".8rem",
    color: theme.palette.primary.main,
  },
  logo: {
    width: "50px",
    alignSelf: "flex-start",
  },
  typographyContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    marginLeft: theme.spacing(1),
  },
}));

const FooterLogo = () => {
  const { container, logo, typographyContainer, subTitle, title } = useStyles();

  return (
    <Box className={container}>
      <img alt="Trashwarrior Logo" src={logoUrl} className={logo} />
      <Box display="flex" className={typographyContainer}>
        <Typography className={title}>Dimension</Typography>
        <Typography className={subTitle}>beta</Typography>
      </Box>
    </Box>
  );
};

export default FooterLogo;
