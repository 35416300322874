import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { bookingStatus } from "@constants/booking";

const blockingStatuses = [
  bookingStatus.booked.value,
  bookingStatus.review.value,
  bookingStatus.done.value,
  bookingStatus.cancelled.value,
  bookingStatus.pending.value,
  bookingStatus.incomplete.value,
];

const CompleteButton = ({ onClick, style, buttonTitle }) => {
  const booking = useSelector((state) => state.booking.selectedBooking);
  const user = useSelector((state) => state.firebase.auth);

  const [isAssignedToMe, setIsAssignedToMe] = useState(booking?.warrior_id === user?.uid);

  useEffect(() => {
    setIsAssignedToMe(booking.warrior_id === user?.uid);
  }, [booking?.warrior_id]);

  if (!isAssignedToMe) return null;
  if (blockingStatuses.includes(booking.status)) return null; // block all but review status

  const isCompleted = !!booking?.completion_date && booking?.status !== bookingStatus["current"].value;

  return (
    <Button
      fullWidth
      onClick={onClick}
      color="primary"
      variant="contained"
      style={style}
      disabled={(isCompleted && booking?.status !== "rejected") || booking?.status === "needs_approval"}
    >
      {buttonTitle ? buttonTitle : isCompleted ? "Complete" : "Complete Task"}
    </Button>
  );
};

CompleteButton.defaultProps = {
  onClick: () => {},
  style: {},
};

export default CompleteButton;
