import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Card, CardContent, CardMedia, Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import shipment_icon from "@assets/shipment_icon.png";
import junkUrl from "@assets/junk.jpg";
import cardboardUrl from "@assets/cardboard.jpg";
import dumpsterUrl from "@assets/dumpster.jpg";
import locationPinIcon from "@assets/location-pin-icon.svg";
import calendarDateIcon from "@assets/calendar-date.svg";

import history from "@history";
import { bookingStatus } from "../../../constants/booking";
import { services, urlMap } from "../constants";
import { segmentTrack } from "../../../helpers/segment";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gap: theme.spacing(3),
    gridTemplateColumns: "repeat(auto-fill, minmax(370px, 1fr) )",
    backgroundColor: "#f1f1f1",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3),
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(auto-fill, minmax(360px, 1fr) )",
      borderRadius: 0,
      margin: 0,
    },
  },
  cardRoot: {
    position: "relative",
    borderRadius: theme.spacing(1),
    cursor: "pointer",
    boxShadow: "0px 4px 20px rgb(0 0 0 / 15%)",
  },
  status: {
    textAlign: "center",
    position: "absolute",
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
  media: {
    height: 300,
    backgroundColor: "#f1f1f1",
    [theme.breakpoints.down("sm")]: {
      height: 260,
    },
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  mainContainer: {
    flexGrow: 1,
    display: "flex",
    marginBottom: theme.spacing(2),
    "& img:first-child": {
      marginRight: theme.spacing(1.5),
      marginTop: theme.spacing(0.7),
    },
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    "& button:first-child": {
      marginRight: theme.spacing(1),
    },
  },
  cardIcon: {
    paddingRight: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
    fontSize: "clamp(1rem, 1.245rem + 0.2125vw, 1.3rem - 4px)",
  },
  bodyText: {
    fontSize: "clamp(0.9rem, 0.79rem + 0.425vw, 1rem)",
  },
  amount: {
    fontSize: "clamp(1rem, 1.245rem + 0.2125vw, 1.3rem - 4px)",
    fontWeight: "bold",
    color: "#1E3B47",
    top: theme.spacing(1.7),
    right: theme.spacing(3),
    position: "absolute",
  },
  paid: {
    fontSize: "0.8rem",
    fontWeight: "bold",
    color: "grey",
    top: theme.spacing(5),
    right: theme.spacing(3),
    position: "absolute",
    display: "none",
  },
  dateTimeContainer: {
    display: "flex",
    color: "grey",
    paddingRight: "20px",
    "& p:last-child": {
      marginLeft: theme.spacing(2),
    },
  },
  dateTimeText: {
    fontSize: "clamp(0.9rem, 0.79rem + 0.425vw, 1rem)",
    fontWeight: "bold",
    color: "black",
  },
  sidebarBox: {
    position: "relative",
    overflowX: "hidden",
    overflowY: "auto",
    cursor: "pointer",
    maxHeight: "200px",
  },
  readMore: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    paddingTop: theme.spacing(8),
    backgroundImage: "linear-gradient(to bottom, transparent, white)",
  },
  dangerButton: {
    borderColor: "red",
    color: "red",
  },
  taskType: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "15px",
    },
  },
  greenText: {
    color: theme.palette.primary.main,
    fontSize: "clamp(0.8rem, 0.6rem + 0.33vw, 1rem)",
    fontWeight: "bold",
    textTransform: "capitalize",
  },
  submissionError: {
    color: "red",
  },
  statusBox: {
    position: "absolute",
    top: "0",
    right: "0",
    border: "1px solid",
    padding: "5px 10px",
    marginTop: "10px",
    marginRight: "10px",
    borderRadius: "10px",
    background: "white",
  },
}));

const TaskTypeCell = ({ task, orderNumber }) => {
  const classes = useStyles();
  return (
    <div className={classes.taskType}>
      <Typography variant="body1" className={classes.title}>
        {task}
      </Typography>
      <Typography variant="body1" classes={{ body1: classes.greenText }}>
        {orderNumber}
      </Typography>
    </div>
  );
};

const TaskListCards = ({ taskListItems, showButtons, isSubmissionError }) => {
  const classes = useStyles();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const parsePrice = (item) => {
    return `$${((item?.payout_dynamic || item?.payout) / 100).toFixed(2)}`;
  };

  const getTotalPayout = (booking) => {
    if (booking.warriorPayoutHistory?.length > 0) {
      let total = 0;
      booking.warriorPayoutHistory.map((payout) => (total += payout?.amount || 0));
      return (total / 100).toFixed(2);
    } else {
      return "-";
    }
  };

  const handleDescriptionClick = (e, item) => {
    e.stopPropagation();
    if (item.description.length > 100) {
      setIsCollapsed(!isCollapsed);
    }
  };

  const openTask = (id) => {
    history.push(`/task_details/${id}`);
    segmentTrack("Warrior Clicks Claim This Task - Get Tasks", { bookingId: id ?? "" });
  };

  return (
    <div className={classes.container}>
      {taskListItems.map((item) => (
        <Card onClick={() => openTask(item.id)} className={classes.cardRoot} key={item.id}>
          <CardMedia
            className={classes.media}
            image={item.taskImages?.length ? item.taskImages[0] : urlMap[services[item.task]]}
            title="Task Image"
          />
          {isSubmissionError && (
            <div className={`${classes.statusBox} ${item.status === "submissionError" && classes.submissionError}`}>
              {item.status === "rejected" ? "Errors Found" : "Pending Admin Review"}
            </div>
          )}
          <CardContent className={classes.cardContent}>
            <div className={classes.mainContainer}>
              <img src={shipment_icon} alt="shipmentIcon" height="20px" width="20px" />
              <div>
                <TaskTypeCell task={services[item.task]} orderNumber={item.orderNumber} />
                <Typography gutterBottom classes={{ body1: classes.bodyText }} variant="body1" color="textSecondary" component="p">
                  {item.address.city}, {item.address.zipCode}
                </Typography>
                <div className={classes.dateTimeContainer}>
                  <Typography gutterBottom classes={{ body1: classes.bodyText }} variant="body1" color="textSecondary" component="p">
                    Date: <span className={classes.dateTimeText}>{dayjs(item.date).tz(item.timeZone)?.format("MM/DD")}</span>
                  </Typography>
                  <Typography gutterBottom classes={{ body1: classes.bodyText }} variant="body1" color="textSecondary" component="p">
                    Time: <span className={classes.dateTimeText}>{dayjs(item.date).tz(item.timeZone)?.format("hh:mm A")}</span>
                  </Typography>
                </div>
                {item.repeat && <FormControlLabel label="Recurring" control={<Checkbox checked color="primary" />} />}
                <Typography className={classes.amount}>{parsePrice(item)}</Typography>
                {/* <Typography className={classes.paid}>{`Paid: ${getTotalPayout(item)}`}</Typography> */}
                <div onClick={(e) => handleDescriptionClick(e, item)} className={classes.sidebarBox}>
                  <Typography variant="body1" component="p" classes={{ body1: classes.bodyText }} color="textSecondary">
                    {isCollapsed ? item.description.slice(0, 100) : item.description}
                  </Typography>
                  {isCollapsed && item.description.length > 100 && <div className={classes.readMore} />}
                </div>
              </div>
            </div>
            {showButtons && (
              <div className={classes.buttonContainer}>
                <Button fullWidth variant="outlined" className={classes.dangerButton} onClick={() => openTask(item.id)}>
                  Withdraw
                </Button>
                <Button fullWidth variant="contained" color="primary" onClick={() => openTask(item.id)}>
                  Complete
                </Button>
              </div>
            )}
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

TaskListCards.defaultProps = {
  taskListItems: [],
  showButtons: false,
  onClick: () => {},
};

TaskListCards.propTypes = {
  taskListItems: PropTypes.arrayOf(PropTypes.object),
  showButtons: PropTypes.bool,
  onClick: PropTypes.func,
};

export default TaskListCards;
