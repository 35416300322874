import { makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import React from "react";
import { warriorEarlyClaimBonus } from "../../../../constants/booking";
import { parsePrice } from "./TaskListItem";

const useStyles = makeStyles((theme) => ({
  amountContainer: {
    top: theme.spacing(1.7),
    right: theme.spacing(3),
    position: "absolute",
    padding: theme.spacing(0.5, 0),
    display: "flex",
  },
  amount: {
    fontSize: "1.3rem",
    fontWeight: "bold",
    color: "#1E3B47",
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(2),
    },
  },
  strikeAmount: {
    fontSize: "1.3rem",
    fontWeight: "bold",
    color: "#1E3B47",
    textDecoration: "line-through",
    opacity: 0.5,
    marginRight: theme.spacing(1),
  },
}));

export const WarriorEarlyTaskPrice = ({ item, applyBonus, isTaskDetails }) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return applyBonus ? (
    <div className={classes.amountContainer} style={{ top: isTaskDetails && isDesktop ? -6 : undefined, right: isTaskDetails ? 0 : undefined }}>
      <Typography className={classes.strikeAmount}>{parsePrice(item)}</Typography>
      <Typography className={classes.amount}>{parsePrice(item, warriorEarlyClaimBonus)}</Typography>
    </div>
  ) : (
    <div className={classes.amountContainer} style={{ top: isTaskDetails && isDesktop ? -6 : undefined, right: isTaskDetails ? 0 : undefined }}>
      <Typography className={classes.amount}>{parsePrice(item)}</Typography>
    </div>
  );
};
