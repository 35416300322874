import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect, useSelector } from "react-redux";

import { makeStyles, Typography, useMediaQuery } from "@material-ui/core";

import { fetchMyBookings } from "@store/actions/booking";
import { applyFiltersMyOrders } from "@helpers/booking";

import Paginations from "@shared/components/Paginations";
import NoData from "@shared/components/NoData";

import TaskListCards from "./components/TaskListCards";
import TaskListTable from "./components/TaskListTable";
import FilterBar from "./components/FilterBar";

import { DefaultFilter, DefaultPagenation } from "./constants";
import { bookingStatus } from "../../constants/booking";
import { ClientTaskPageLayout } from "../../shared/components/ClientTasksLayout";

const useStyles = makeStyles((theme) => ({
  mobilePagination: {
    display: "flex",
    height: "55px",
    boxShadow: "0px 4px 20px rgb(0 0 0 / 15%)",
    justifyContent: "center",
    alignItems: "center",
    bottom: 0,
    position: "sticky",
    border: "1px solid #e0e0e0",
    margin: theme.spacing(3, 0),
    backgroundColor: theme.palette.primary.contrastText,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  desktopPagination: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

function MyTasksPage({ firebase, fetchMyBookings }) {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:984px)");
  const [activeStatus, setActiveStatus] = useState("active");
  const [listStyle, setListStyle] = useState("list");
  const allBookings = useSelector((state) => state.booking.myBookings);
  const [taskListItems, setTaskListItems] = useState([]);
  const [paginationDetails, setPaginationDetails] = useState(DefaultPagenation);
  const [headerSearch, setHeaderSearch] = useState("");

  const setPageNumber = (newPage) => setPaginationDetails({ ...paginationDetails, pageNumber: newPage });

  useEffect(() => {
    if (firebase.auth.uid !== undefined) {
      fetchMyBookings(firebase.auth.uid);
    }
  }, []);

  useEffect(() => {
    applyFilter();
  }, [allBookings?.length, activeStatus, paginationDetails.pageNumber]);

  const { pageNumber, pageSize } = paginationDetails;
  const visitedData = (pageNumber - 1) * pageSize;
  const slicedTasks = taskListItems.slice(visitedData, visitedData + pageSize);

  const applyFilter = (filters = { ...DefaultFilter }) => {
    const filteredBookings = applyFiltersMyOrders(allBookings, { ...filters, activeStatus }).sort((a, b) => new Date(b.date) - new Date(a.date));
    setTaskListItems(filteredBookings);
  };

  const tabs = [
    {
      value: "active",
      label: "Upcoming",
    },
    {
      value: "warrior-done",
      label: "Completed",
    },
    {
      value: "submissionError",
      label: "Submission Error",
    },
  ];

  const layoutProps = {
    title: "My Tasks",
    filterBar: (showMobileFilter, setShowMobileFilter) => (
      <FilterBar
        setView={setListStyle}
        applyFilter={applyFilter}
        headerSearch={headerSearch}
        showMobileFilter={showMobileFilter}
        setShowMobileFilter={setShowMobileFilter}
      />
    ),
    search: headerSearch,
    setSearch: setHeaderSearch,
    tabs,
    activeStatus,
    setActiveStatus,
  };

  return (
    <ClientTaskPageLayout {...layoutProps}>
      {slicedTasks?.length ? (
        <>
          {activeStatus === "rejected" && (
            <Typography style={{ color: "red", marginLeft: "30px" }} variant="body2">
              There was an issue with your task completion.
            </Typography>
          )}
          {listStyle === "card" || isMobile ? (
            <TaskListCards
              showButtons={activeStatus === "active"}
              isSubmissionError={activeStatus === "submissionError"}
              taskListItems={slicedTasks}
            />
          ) : (
            <TaskListTable
              isUpcoming={activeStatus === "active"}
              isSubmissionError={activeStatus === "submissionError"}
              taskListItems={slicedTasks}
            />
          )}
          <div className={isMobile ? classes.mobilePagination : classes.desktopPagination}>
            <Paginations totalCount={taskListItems.length} setPageNumber={setPageNumber} {...paginationDetails} />
          </div>
        </>
      ) : (
        <NoData displayText="No Tasks Found!" />
      )}
    </ClientTaskPageLayout>
  );
}

const mapStateToProps = (state) => ({
  firebase: state.firebase,
});

MyTasksPage.propTypes = {
  fetchMyBookings: PropTypes.func,
  firebase: PropTypes.object,
};

export default connect(mapStateToProps, { fetchMyBookings })(MyTasksPage);
