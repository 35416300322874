import React, { useState } from "react";
import { Box, Hidden, InputBase, makeStyles, Typography } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";
import StatusTabs from "../../views/MyOrders/OrdersPage/StatusTabs";
import ChatLink from "../../containers/ChatLink";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2, 3),
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  header: {
    display: "grid",
    gridTemplateColumns: "auto max-content",
    alignItems: "center",
    marginTop: theme.spacing(3),
    padding: theme.spacing(0, 3),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 2),
      gridTemplateColumns: "auto",
    },
  },
  titleContainer: {
    display: "flex",
  },
  backBtn: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.hover,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
    },
  },
  pageTitle: {
    fontSize: "25px",
    fontWeight: "bold",
    paddingLeft: theme.spacing(1),
    lineHeight: "1.2",
    flexGrow: "1",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      padding: theme.spacing(0, 1),
      fontWeight: 700,
    },
  },
  search: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    margin: theme.spacing(2, 0),
    position: "relative",
    display: "grid",
    gridTemplateColumns: "auto max-content",
    columnGap: theme.spacing(2),
    color: theme.palette.primary.main,
    width: "100%",
    alignItems: "center",
    boxSizing: "border-box",
    padding: theme.spacing(0, 2, 1, 3),
  },
  searchIcon: {
    height: "80%",
    margin: theme.spacing(0, 0.5, 0, 1),
    pointerEvents: "none",
    color: theme.palette.primary.main,
  },
  searchInput: {
    fontSize: "14px",
    border: `2px solid #E0E0E0`,
    borderRadius: 100,
    padding: theme.spacing(0.5, 1),
  },
  filterBoxMobile: {
    cursor: "pointer",
  },
  chatLink: {
    textAlign: "center",
    width: "100%",
  },
  tabContainer: {
    padding: theme.spacing(2, 4, 0),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 2, 0),
    },
  },
  formBox: {
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
}));

export const ClientTaskPageLayout = ({ children, title, back, filterBar, search, setSearch, tabs, activeStatus, setActiveStatus, placeholder }) => {
  const classes = useStyles();
  const history = useHistory();
  const [showMobileFilter, setShowMobileFilter] = useState(false);

  const handleBack = () => {
    if (back) {
      history.push(back);
    } else {
      history.goBack();
    }
  };

  return (
    <Box>
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.titleContainer}>
            <KeyboardBackspaceIcon className={classes.backBtn} fontSize="large" onClick={handleBack} />
            <Typography className={classes.pageTitle}>{title}</Typography>
          </div>
          <Hidden smDown>{filterBar(showMobileFilter, setShowMobileFilter)}</Hidden>
        </div>
        <div className={classes.search}>
          <InputBase
            startAdornment={<SearchIcon className={classes.searchIcon} />}
            placeholder={placeholder || "Search a past booking.."}
            inputProps={{ "aria-label": "search" }}
            className={classes.searchInput}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <FilterListIcon onClick={() => setShowMobileFilter((prev) => !prev)} className={classes.filterBoxMobile} />
        </div>
        <Hidden mdUp>{filterBar(showMobileFilter, setShowMobileFilter)}</Hidden>
        <div className={classes.tabContainer}>
          <StatusTabs tabs={tabs} activeStatus={activeStatus} setActiveStatus={setActiveStatus} />
        </div>
        <div className={classes.formBox}>{children}</div>
        <div className={classes.chatLink}>
          <ChatLink />
        </div>
      </div>
    </Box>
  );
};
