import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Icon, Typography } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { useSelector } from "react-redux";
import { Route, Router, Switch, NavLink, useLocation } from "react-router-dom";

import history from "@history";
import customerLoginUrl from "@assets/landing-page-customer-login.svg";
import customerRegisterUrl from "@assets/landing-page-customer-register.svg";
import providerLoginUrl from "@assets/landing-page-provider-login.svg";
import googleSvgUrl from "@assets/google.svg";

import SignIn from "./SignIn";
import Register from "./Register";
import ResetPassword from "./ResetPasswod";
import SignUpWithGoogle from "./SignUpWithGoogle";
import { roles } from "../../constants/roles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      height: 1200,
    },
  },
  leftBanner: {
    width: "40%",
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  bannerImage: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100%",
  },
  rightBanner: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4, 0, 0),
      position: "relative",
      paddingBottom: theme.spacing(8),
      boxSizing: "border-box",
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      flexGrow: 1,
      padding: theme.spacing(4),
      minHeight: "calc(100vh - 124px)",
    },
    [theme.breakpoints.up("lg")]: {
      flexGrow: 1,
      padding: "50px 100px",
    },
  },
  formContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(10),
      boxSizing: "border-box",
      position: "relative",
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
    },
    maxWidth: "600px",
  },
  formHeader: {
    display: "grid",
    gridTemplateColumns: "50px auto",
    alignItems: "center",
    marginBottom: 40,
  },
  tab: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  tabText: {
    [theme.breakpoints.down("xs")]: {
      margin: "0px 25px",
    },
    color: "#E0E0E0",
    fontWeight: 700,
    fontSize: 30,
    marginRight: 50,
    textDecoration: "none",
  },
  tabTextActive: {
    color: "#4F4F4F",
    marginBottom: theme.spacing(1),
  },
  backBtn: {
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.hover,
    },
  },
  imageIcon: {
    [theme.breakpoints.down("xs")]: {
      height: "35px",
    },
    height: "45px",
  },
  iconContainer: {
    [theme.breakpoints.down("xs")]: {
      height: "35px",
      width: "35px",
    },
    textAlign: "center",
    height: "45px",
    width: "45px",
  },
  googleSignupTitle: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  googleSignUpText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
    color: "#4F4F4F",
    fontWeight: "bold",
    fontSize: "25px",
    marginLeft: "20px",
  },
}));

const Banner = () => {
  const classes = useStyles();
  const userType = useSelector((state) => state.tempData.userType);
  const location = useLocation();
  const isLogin = location.pathname === "/auth/signin";
  const isRegister = ["/auth/register", "/auth/register/business-info", "/auth/signup_with_google"].includes(location.pathname);

  const customerUrls = () => ({ [isLogin]: customerLoginUrl, [isRegister]: customerRegisterUrl }.true);
  const getCurrentUrl = () => {
    return { [roles.provider.value]: providerLoginUrl, [roles.customer.value]: customerUrls() }[userType] || customerUrls();
  };

  return (
    <div
      className={classes.bannerImage}
      style={{
        backgroundImage: `url(${getCurrentUrl()})`,
        width: "100%",
      }}
    />
  );
};

const FormHeader = () => {
  const classes = useStyles();
  const location = useLocation();
  const isResetPassword = location.pathname === "/auth/reset_pw";
  const isRegister = location.pathname === "/auth/register";
  const isSignIn = location.pathname === "/auth/signin";
  const showTabs = ["/auth/signin", "/auth/register"].indexOf(location.pathname) !== -1;

  return (
    <div className={classes.tab}>
      {isResetPassword && (
        <NavLink activeClassName={classes.tabTextActive} className={classes.tabText} to="/auth/reset_pw">
          Reset Password
        </NavLink>
      )}
      {showTabs && isSignIn && (
        <NavLink activeClassName={classes.tabTextActive} className={classes.tabText} to="/auth/register">
          Sign In
        </NavLink>
      )}
      {showTabs && isRegister && (
        <NavLink activeClassName={classes.tabTextActive} className={classes.tabText} to="/auth/signin">
          Register
        </NavLink>
      )}
    </div>
  );
};

const FormArea = () => {
  const classes = useStyles();
  const location = useLocation();
  const isResetPassword = location.pathname === "/auth/reset_pw";
  const isGoogleSignUp = location.pathname === "/auth/signup_with_google";
  const isInvalidPhone = !!location.search && new URLSearchParams(location.search).get("invalidPhoneNumber");

  return (
    <div className={classes.formContainer}>
      <div className={classes.formHeader}>
        {!isResetPassword && <KeyboardBackspaceIcon className={classes.backBtn} fontSize="large" onClick={() => history.push("/")} />}

        {isGoogleSignUp ? (
          <div className={classes.googleSignupTitle}>
            <Icon classes={{ root: classes.iconContainer }}>
              <img className={classes.imageIcon} src={googleSvgUrl} />
            </Icon>
            <Typography className={classes.googleSignUpText}>{isInvalidPhone ? "Complete Sign Up" : "Sign up with Google"}</Typography>
          </div>
        ) : (
          <FormHeader />
        )}
      </div>
      <Router history={history}>
        <Switch>
          <Route exact path="/auth/register" name="Register" component={Register} />
          <Route path="/auth/signin" name="Login" component={SignIn} />
          <Route path="/auth/reset_pw" name="ResetPassword" component={ResetPassword} />
          <Route path="/auth/signup_with_google" name="SignUpWithGoogle" component={SignUpWithGoogle} />
        </Switch>
      </Router>
    </div>
  );
};

function AuthPage() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.leftBanner}>
        <Banner />
      </div>
      <div className={classes.rightBanner}>
        <FormArea />
      </div>
    </div>
  );
}

export default AuthPage;
