import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  paymentDivider: {
    display: "grid",
    gridTemplateColumns: "auto max-content auto",
    columnGap: 15,
    padding: 15,
    paddingLeft: 30,
    paddingRight: 30,
    alignItems: "center",
    width: "100%",
    boxSizing: "border-box",
  },
  paymentDividerBlank: {
    display: "block",
    columnGap: 15,
    padding: 15,
    paddingLeft: 30,
    paddingRight: 30,
    alignItems: "center",
    width: "100%",
    boxSizing: "border-box",
  },
  paymentDividerLineBreak: {
    height: 1,
    width: "100%",
    background: "#ccc",
  },
  paymentDividerText: {
    color: "#ccc",
    textAlign: "center",
  },
}));

export const LineDivider = ({ text }) => {
  const classes = useStyles();
  return !text ? (
    <div className={classes.paymentDividerBlank}>
      <div className={classes.paymentDividerLineBreak} />
    </div>
  ) : (
    <div className={classes.paymentDivider}>
      <div className={classes.paymentDividerLineBreak}></div>
      <div className={classes.paymentDividerText}>{text}</div>
      <div className={classes.paymentDividerLineBreak}></div>
    </div>
  );
};
