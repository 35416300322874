import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import { toastr } from "react-redux-toastr";
import Typography from "@material-ui/core/Typography";
import Button from "@shared/components/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import logoUrl from "@assets/landing_page/dimension-logo-dark.svg";
import { CardNumberElement } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import { updateProfile } from "../../../store/actions/users";
import { redeemCoupon } from "../../../store/actions/coupon";
import { createBooking } from "../../../store/actions/booking";
import { segmentTrack } from "../../../helpers/segment";
import PaymentMethodField from "../helpers/fields/PaymentMethodField";
import { WalletPay } from "../../../shared/components/PaymentOptions/WalletPay";
import { CreditCardForm } from "../../../shared/components/PaymentOptions/CreditCardForm";
import { LineDivider } from "../../../shared/components/PaymentOptions/LineDivider";
import { Payment } from "../../../constants/payments";
import { ACHForm } from "./ACHForm";

const nonCardPaymentMethods = [Payment.Check, Payment.Transfer];

const useStyles = (theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  dialogContainer: {
    backgroundColor: "rgb(227,227,230, 0.3)",
    zIndex: "14000 !important",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  companyName: {
    color: "black",
    fontSize: "18px",
    fontWeight: 600,
  },
  dialogTitle: {
    paddingTop: "60px",
    justifyContent: "space-around",
    display: "flex",
    textAlign: "center",
    backgroundColor: "#e8e9eb",
  },
  captionText: {
    fontSize: "0.90rem",
  },
  logo: {
    width: "80px",
    alignSelf: "center",
    margin: "auto",
  },
  logoContainer: {
    display: "flex",
    borderColor: "black",
    borderStyle: "solid",
    borderWidth: "1px",
    width: "100px",
    alignSelf: "center",
    position: "fixed",
    marginTop: "-50px",
    backgroundColor: "white",
    borderRadius: "100px",
    height: "100px",
  },
  formGroup: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  dialogActionsContainer: {
    flex: "auto",
    flexDirection: "column",
    "& > h6": {
      color: "red",
    },
  },
  dialogActions: {
    minWidth: "40%",
    flex: "auto",
    margin: "20px 50px",
    color: "white",
    backgroundColor: "#339edf",
    "&:hover": {
      backgroundColor: "#2194db",
    },
    height: "40px",
    borderRadius: "5px",
  },
  dialogContent: {},
  cvcCode: {
    flex: 1,
    display: "flex",
  },
  expDate: {
    flex: 1,
    display: "flex",
    marginRight: "10px",
  },
  splitRow: {
    display: "flex",
    alignItems: "space-between",
    marginTop: 25,
  },
});

class ProcessPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isProcessing: false,
      errors: null,
      isRemember: false,
      isLoggedIn: !!this.props.firebase.auth?.uid,
      paymentMethod: this.props.isMultipleQuotes ? Payment.Transfer : Payment.Card,
      keepClose: false,
      isNotValidPickupDate: dayjs().isAfter(dayjs(this.props.bookingData.date)),
    };
  }

  init = async () => {
    const { firebase } = this.props;
    if (firebase.profile?.isRememberPaymentMethod && !this.props.isQuote) {
      this.setState({ keepClose: true });
      if (this.props.selectedCoupon) {
        const response = await this.props.redeemCoupon(this.props.selectedCoupon.id, this.props.bookingData.customer_id);
        if (!response.success) {
          this.setState({ isProcessing: false, errors: "Invalid coupon" });
          return;
        }
      }

      if (this.state.isNotValidPickupDate) {
        this.setState({ isProcessing: false, errors: "Please add a valid pickup date" });
        toastr.error("Error", "Please add a valid pickup date");
        return;
      }
      // automated payment
      await this.props.createBooking({ ...this.props.bookingData }, { payment_method: firebase.profile?.paymentMethod?.type || "ACH" });
    }
  };

  componentDidMount() {
    this.init();
  }

  onChangeRemember = (e) => {
    this.setState({ isRemember: e.target.checked });
  };

  onChangeMethod = (method) => {
    this.setState({ paymentMethod: method });
  };

  handleWalletPayment = async (methodId) => {
    this.setState({ isProcessing: true });
    try {
      if (this.state.isNotValidPickupDate && !this.props.isQuote) {
        this.setState({ isProcessing: false, errors: "Please add a valid pickup date" });
        toastr.error("Error", "Please add a valid pickup date");
        return;
      }
      if (this.props.coupon.selectedCoupon) {
        const {
          coupon: { selectedCoupon },
        } = this.props;
        const response = await this.props.redeemCoupon(selectedCoupon.id, this.props.bookingData.customer_id);
        if (!response.success) {
          this.setState({ isProcessing: false, errors: "Invalid coupon" });
          return;
        }
      }
      await this.props.createBooking(
        { ...this.props.bookingData },
        {
          isRememberPaymentMethod: this.state.isRemember,
          payment_method: "card",
          paymentMethod: methodId,
          paymentSelectedMethod: "wallet",
        }
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error", e);
      this.setState({ isProcessing: false, errors: "Error creating booking" });
      return;
    }
    this.setState({ isProcessing: false, errors: null });
  };

  handleCardPayment = async () => {
    this.setState({ isProcessing: true });

    const userEmail = this.props.firebase.auth?.email;
    const email = this.props.paymentForm?.email?.value;
    const first_name = this.props.paymentForm?.first_name?.value || "UNKNOWN";
    const last_name = this.props.paymentForm?.last_name?.value || "UNKNOWN";
    const name = first_name + " " + last_name;

    if (!this.props.stripe || !this.props.elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      this.setState({
        isProcessing: false,
        errors: "Unknown error occured, please try your payment again",
      });
      return;
    }

    const paymentMethodDetails = {
      type: "card",
      card: this.props.elements.getElement(CardNumberElement),
      billing_details: {
        name: name,
        email: userEmail || email,
      },
    };

    // if (this.state.isRemember || this.props.dateAndTimeForm?.frequency?.value !== Frequency.Once) {
    //   paymentDetails.setup_future_usage = "off_session";
    // }

    // confirm card payment
    try {
      if (this.state.isNotValidPickupDate && !this.props.isQuote) {
        this.setState({ isProcessing: false, errors: "Please add a valid pickup date" });
        toastr.error("Error", "Please add a valid pickup date");
        return;
      }

      if (this.props.selectedCoupon) {
        const response = await this.props.redeemCoupon(this.props.selectedCoupon.id, this.props.bookingData.customer_id);
        if (!response.success) {
          this.setState({ isProcessing: false, errors: "Invalid coupon" });
          return;
        }
      }

      const PMResponse = await this.props.stripe.createPaymentMethod(paymentMethodDetails);

      if (PMResponse.error) {
        segmentTrack("Stripe Fail", { error: PMResponse.error.message });
        this.setState({ isProcessing: false, errors: PMResponse.error.message });
        return;
      }
      await this.props.createBooking(
        { ...this.props.bookingData },
        {
          isRememberPaymentMethod: this.state.isRemember,
          payment_method: "card",
          paymentMethod: PMResponse.paymentMethod.id,
        }
      );
      this.setState({ isProcessing: false, errors: null });
    } catch (error) {
      this.setState({ isProcessing: false, errors: error.message || "Something went wrong with the payment method" });
    }
  };

  handleACHPayment = async () => {
    this.setState({ isProcessing: true });
    try {
      if (this.state.isNotValidPickupDate && !this.props.isQuote) {
        this.setState({ isProcessing: false, errors: "Please add a valid pickup date" });
        toastr.error("Error", "Please add a valid pickup date");
        return;
      }
      if (this.props.coupon.selectedCoupon) {
        const {
          coupon: { selectedCoupon },
        } = this.props;
        const customer_id = this.props.isMultipleQuotes ? this.props.bookingData[0].customer_id : this.props.bookingData.customer_id;
        const response = await this.props.redeemCoupon(selectedCoupon.id, customer_id);
        if (!response.success) {
          this.setState({ isProcessing: false, errors: "Invalid coupon" });
          return;
        }
      }
      const booking = this.props.isMultipleQuotes ? this.props.bookingData : { ...this.props.bookingData };
      const form = {
        payment_method: "ACH",
        paymentSelectedMethod: this.state.paymentMethod,
      };
      await this.props.createBooking(booking, form, this.props.isMultipleQuotes);
    } catch (error) {
      this.setState({ isProcessing: false, errors: error.message || "Something went wrong with the payment method" });
    }
  };

  render() {
    const { open, stripe, elements, handleClose, paymentForm, summaryProps, classes, isQuote } = this.props;
    return (
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open && !this.state.keepClose}
        onClose={() => handleClose(false)}
        aria-labelledby="max-width-dialog-title"
        className={classes.dialogContainer}
      >
        <div className={classes.logoContainer}>
          <img alt="logo" className={classes.logo} src={logoUrl} />
        </div>
        <DialogTitle id="max-width-dialog-title" className={classes.dialogTitle}>
          <div className={classes.companyName}>Dimension</div>
          <div>
            <Typography variant="subtitle2" gutterBottom>
              Dimension: Service Job Fee
            </Typography>
          </div>
          <hr />
          <Typography variant="caption" display="block" gutterBottom>
            {paymentForm?.email?.value}
          </Typography>
        </DialogTitle>
        {!isQuote && (
          <WalletPay
            stripe={stripe}
            elements={elements}
            isMultipleQuotes={this.props.isMultipleQuotes}
            confirmWalletPayment={this.handleWalletPayment}
            summaryProps={summaryProps}
          />
        )}

        <PaymentMethodField
          selected={this.state.paymentMethod}
          onChange={this.onChangeMethod}
          isBusiness={summaryProps?.businessClient}
          isQuote={isQuote}
          isMultipleQuotes={this.props.isMultipleQuotes}
        />

        <LineDivider />

        {this.state.paymentMethod === Payment.Card && (
          <>
            <CreditCardForm isLoggedIn={this.state.isLoggedIn} onChange={this.onChangeRemember} />
            <DialogActions className={classes.dialogActionsContainer}>
              <Button className={classes.dialogActions} onClick={this.handleCardPayment} loading={this.state.isProcessing} loadingText="Processing">
                Pay
              </Button>
              {this.state.errors ? <Typography variant={"subtitle2"}>Error: {this.state.errors}</Typography> : <></>}
            </DialogActions>
          </>
        )}
        {nonCardPaymentMethods.includes(this.state.paymentMethod) && (
          <>
            {this.state.paymentMethod === Payment.Transfer && <ACHForm type="Bank Transfer" />}
            {this.state.paymentMethod === Payment.Check && <ACHForm type="Check" />}
            <DialogActions className={classes.dialogActionsContainer}>
              <Button className={classes.dialogActions} onClick={this.handleACHPayment} loading={this.state.isProcessing} loadingText="Processing">
                Confirm Booking
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default withStyles(useStyles)(
  connect(mapStateToProps, {
    updateProfile,
    redeemCoupon,
    createBooking,
  })(ProcessPayment)
);
