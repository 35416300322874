import React from "react";
import cn from "classnames";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  dialogContainer: {
    backgroundColor: (props) => props.dropShadow && "rgb(227,227,230)",
  },
  dialogActions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    padding: "0 20px 0 20px",
    "& > button": {
      marginBottom: "10px",
    },
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

const ConfirmationModal = ({
  open,
  title,
  body,
  onProceed,
  onCancel,
  hideCancel,
  proceedBtnText = "Ok",
  cancelBtnTxt = "Cancel",
  proceedBtnProps = {},
  cancelBtnProps = {},
  dropShadow = true,
  fullWidth = false,
  footer,
}) => {
  const classes = useStyles({ dropShadow });

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      fullWidth={fullWidth}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.dialogContainer}
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <DialogContent className={classes.dialogContent}>{body}</DialogContent>
      <DialogActions className={classes.dialogActions} style={{ paddingBottom: footer ? "0" : "20px" }}>
        {!hideCancel && (
          <Button onClick={onCancel} color="primary" variant="outlined" style={{ width: "100%", height: "40px" }} {...cancelBtnProps}>
            {cancelBtnTxt}
          </Button>
        )}
        <Button onClick={onProceed} color="primary" variant="contained" autoFocus style={{ width: "100%", height: "40px" }} {...proceedBtnProps}>
          {proceedBtnText}
        </Button>
      </DialogActions>
      {!!footer && (
        <DialogContent className={classes.dialogContent}>
          <DialogContentText id="alert-dialog-footer" style={{ fontSize: "15px" }}>
            {footer}
          </DialogContentText>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ConfirmationModal;
