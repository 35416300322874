import React, { useState } from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import shipment_icon from "@assets/shipment_icon.png";
import { Button, makeStyles, Typography, Chip, Checkbox } from "@material-ui/core";
import { taskTypes, bookingStatus } from "../../../../constants/booking";
import { Frequency } from "../../../BookingPage/helpers/enums";
import { fetchBookingInvoices } from "../../../../store/actions/invoices";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
  },
  taskType: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "15px",
    },
  },
  grayText: {
    color: "grey",
  },
  greenText: {
    color: theme.palette.primary.main,
    fontSize: "clamp(0.8rem, 0.6rem + 0.33vw, 1rem)",
    fontWeight: "bold",
    textTransform: "capitalize",
  },
  status: {
    paddingRight: theme.spacing(2),
    width: "200px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "15px 0px",
      width: "100%",
    },
  },
  dateTimeText: {
    fontSize: "clamp(0.8rem, 0.6rem + 0.33vw, 1rem)",
    fontWeight: "bold",
    color: "black",
  },
  doBtn: {
    height: "40px",
    width: "140px",
    fontSize: "clamp(0.8rem, 0.6rem + 0.33vw, 1rem)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  table: {
    width: "100%",
    borderCollapse: "separate",
    borderSpacing: "0 1rem",
  },
  tableHeader: {
    "& th": {
      color: "grey",
      fontSize: "clamp(0.9rem, 0.7rem + 0.3333vw, 1.1rem)",
      fontWeight: "normal",
      padding: theme.spacing(2, 0, 1, 0),
    },
    "& th:nth-child(2)": {
      paddingLeft: 0,
    },
    "& th:first-child": {
      paddingLeft: theme.spacing(4),
    },
  },
  tableRow: {
    backgroundColor: "#fff",
    cursor: "pointer",
    borderRadius: "7px",
    boxShadow: "rgba(50, 50, 93, 0.10) 0px 6px 12px -2px, rgba(0, 0, 0, 0.10) 0px 3px 7px -3px",
    "& td": {
      textAlign: "center",
      padding: theme.spacing(2, 0, 2, 0),
      borderTop: "1px solid #e8e8e8",
      borderBottom: "1px solid #e8e8e8",
    },
    "& td:first-child": {
      width: "10%",
      borderRadius: "7px 0 0 7px",
      borderLeft: "1px solid #e8e8e8",
    },
    "& td:nth-child(2)": {
      padding: theme.spacing(0, 1, 0, 0),
    },
    "& td:last-child": {
      width: "10%",
      borderRadius: "0 7px 7px 0",
      borderRight: "1px solid #e8e8e8",
      paddingRight: theme.spacing(4),
    },
  },
  extraSmallColumn: {
    textAlign: "center",
    width: "10%",
  },
  smallColumn: {
    textAlign: "left",
    width: "15%",
  },
  mediumColumn: { textAlign: "left", width: "25%" },
  textCenter: { textAlign: "center" },
  addressCell: {
    color: "grey",
    textAlign: "left",
    fontSize: "clamp(0.8rem, 0.6rem + 0.33vw, 1rem)",
    padding: theme.spacing(1, 1, 1, 0),
  },
  taskTypeCell: {
    display: "flex",
    alignItems: "start",
    textAlign: "left",
    marginLeft: theme.spacing(3),
    "& img": {
      marginTop: theme.spacing(0.3),
    },
    "& div:last-child": {
      marginLeft: theme.spacing(1),
      flexGrow: "1",
    },
  },
  chip: {
    fontSize: "13px",
    lineHeight: 1.1,
    fontWeight: "bold",
    minWidth: theme.spacing(10),
  },
  chipHover: {
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0px 0px 2px 1px #ccc",
    },
  },
  redChip: {
    color: "red",
    border: "1px solid red",
  },
}));

const TaskTypeCell = ({ task, orderNumber }) => {
  const classes = useStyles();
  return (
    <div className={classes.taskTypeCell}>
      <img src={shipment_icon} alt="shipmentIcon" height="20px" width="20px" />
      <div className={classes.taskType}>
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          {taskTypes[task]?.label ?? ""}
        </Typography>
        <Typography variant="body1" classes={{ body1: classes.greenText }}>
          {orderNumber}
        </Typography>
      </div>
    </div>
  );
};

const PaymentStatusChip = ({ status = "pastDue", booking, bookingId, actions }) => {
  const classes = useStyles();
  const [load, setLoad] = useState(false);

  const openOutstandingInvoice = async (bookingId) => {
    setLoad(true);
    const invoices = await fetchBookingInvoices(bookingId);
    if (!invoices) return;

    const outstandingInvoices = invoices.filter((invoice) => invoice.status === "open");
    const link = outstandingInvoices?.[0]?.hosted_invoice_url || "";

    setLoad(false);
    if (link) {
      window.open(link, "_blank");
    }
  };

  const chipProps = {
    paid: {
      color: "primary",
      variant: "default",
      label: "Paid",
    },
    payInvoice: {
      color: "primary",
      variant: "outlined",
      label: "Pay Invoice",
      classes: {
        root: `${classes.chip} ${classes.chipHover}`,
      },
      onClick:
        !load && booking.invoices?.length === 1
          ? (e) => {
              e.preventDefault();
              e.stopPropagation();
              openOutstandingInvoice(bookingId);
            }
          : (e) => actions.handleBookingDetails(e, bookingId),
    },
    pastDue: {
      variant: "outlined",
      label: "Past Due",
      classes: {
        root: `${classes.chip} ${classes.redChip} ${classes.chipHover}`,
      },
      onClick: !load
        ? (e) => {
            e.preventDefault();
            e.stopPropagation();
            openOutstandingInvoice(bookingId);
          }
        : undefined,
    },
  };
  return <Chip className={classes.chip} {...chipProps[status]} />;
};

const RecurringCheckBox = ({ isReccuring }) => {
  const checkboxProps = isReccuring ? { color: "primary", checked: true } : { color: "default", indeterminate: true };
  return <Checkbox checked {...checkboxProps} />;
};

function BookingsList({ bookings, showQuotes, actions, pagenationDetails, getStatusAction, doItAgainOverride }) {
  const { visitedData, pageSize } = pagenationDetails;
  const classes = useStyles();

  const getBookingStatusText = (status) => {
    switch (status) {
      case "current":
        return "Provider assigned";
      case "booked":
        return "Finding provider";
      default:
        return status;
    }
  };

  const getPaymentStatus = (booking) => {
    if (booking.paid) {
      return "paid";
    } else if (booking.payment_method === "ACH") {
      return "payInvoice";
    } else {
      return "pastDue";
    }
  };

  return (
    <div className={classes.container}>
      <table className={classes.table}>
        <thead className={classes.tableHeader}>
          <tr>
            <th className={classes.smallColumn}>ID & type</th>
            <th className={classes.mediumColumn}>Address</th>
            <th>Date</th>
            <th>Time</th>
            {!showQuotes && (
              <>
                <th>Amount</th>
                <th>Status</th>
                <th>Payment status</th>
                <th className={classes.extraSmallColumn}>Recurring</th>
              </>
            )}
            <th></th>
          </tr>
        </thead>
        {!!bookings && (
          <tbody>
            {bookings?.slice(visitedData, visitedData + pageSize)?.map((booking) => (
              <tr key={booking.id} className={classes.tableRow} onClick={(e) => !showQuotes && actions.handleBookingDetails(e, booking.id)}>
                <td>
                  <TaskTypeCell task={booking.task} orderNumber={booking.orderNumber} />
                </td>
                <td>
                  <Typography variant="body1" classes={{ root: classes.addressCell }}>
                    {booking.address.location}, {booking.address.zipCode}
                  </Typography>
                </td>
                <td>
                  <span className={classes.dateTimeText}>{dayjs(booking.date).tz(booking.timeZone).format("MM/DD")}</span>
                </td>
                <td>
                  <span className={classes.dateTimeText}>{dayjs(booking.date).tz(booking.timeZone).format("hh:mm A")}</span>
                </td>
                {!showQuotes && (
                  <td className={classes.textCenter}>
                    <span className={classes.greenText}>{`$${(booking.total / 100)?.toFixed(2)}`}</span>
                  </td>
                )}
                {!showQuotes && (
                  <>
                    <td className={classes.textCenter}>
                      <div className={classes.greenText}>{getBookingStatusText(booking.status)}</div>
                    </td>
                    <td className={classes.textCenter}>
                      {getPaymentStatus(booking) !== "paid" && !booking.invoices?.length ? (
                        "-"
                      ) : (
                        <PaymentStatusChip status={getPaymentStatus(booking)} booking={booking} bookingId={booking.id} actions={actions} />
                      )}
                    </td>
                    <td className={classes.textCenter}>
                      <RecurringCheckBox isReccuring={booking.frequency && booking.frequency !== Frequency.Once} />
                    </td>
                  </>
                )}
                <td>
                  {showQuotes ? (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.doBtn}
                      onClick={(e) =>
                        booking.bookingId ? actions.handleBookingDetails(e, booking.bookingId) : actions.goToBookingFlow(e, booking.id)
                      }
                    >
                      {booking.bookingId ? "Show Details" : "Go to Quote"}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.doBtn}
                      disabled={
                        (booking.status === bookingStatus.booked.value || booking.status === bookingStatus.current.value) && !doItAgainOverride
                      }
                      onClick={(e) => actions.handleDoItAgain(e, booking)}
                    >
                      Do it again
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
}

BookingsList.propTypes = {
  bookings: PropTypes.array,
  showQuotes: PropTypes.bool,
  actions: PropTypes.object,
  pagenationDetails: PropTypes.object,
  getStatusAction: PropTypes.func,
};

export default BookingsList;
