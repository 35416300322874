import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Box, Grid, Button, TextField } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginFormDefaults, loginFormSchema, registerFormDefaults, registerFormSchema } from "@constants/auth";
import Input from "react-phone-number-input/input";
import PhoneField from "@shared/components/PhoneField";
import GoogleBtn from "@shared/components/GoogleBtn";

import "react-phone-number-input/style.css";

import DividerWithText from "@shared/components/DividerWithText";
import { useDispatch, useSelector } from "react-redux";
import { registerWithEmail, signInWithGoogle, signInWithEmail } from "@store/actions/auth";
import { userLogin } from "../../store/actions/users";
import tempDataConstants from "../../store/constants/tempDataTypes";
import { roles } from "../../constants/roles";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "10000",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0, 0, 0, 0.6)",
    backdropFilter: "blur(5px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalArea: {
    background: "white",
    width: "80%",
    maxWidth: "450px",
    [theme.breakpoints.up("lg")]: {
      width: "50%",
    },
    overflowY: "scroll",
    maxHeight: "90vh",
    borderRadius: "10px",
  },
  googleBtn: {
    width: "100%",
  },
  formContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
    },
  },
  socialBtns: {
    margin: "20px 0px",
  },
  form: {
    margin: "20px 0px",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
  phoneField: {
    margin: "8px 8px 4px",
  },
  toggleForm: {
    width: "250px",
    "&:hover": {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
  submitBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    width: "250px",
    "&:hover": {
      backgroundColor: theme.palette.primary.hover,
    },
  },
  forgotPassword: {
    marginTop: -24,
    textDecoration: "none",
    fontSize: 14,
    color: "grey",
    fontWeight: 400,
  },
}));

const AuthPopup = ({ onClose, allowOutsideClick, context, contextFooter, isProvider }) => {
  const classes = useStyles();
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoginForm, setIsLoginForm] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const userType = useSelector((state) => state.tempData.userType);
  const authUser = useSelector((state) => state.firebase.auth);

  const handleClickOutside = (ev) => {
    if (wrapperRef.current && !wrapperRef.current.contains(ev.target)) {
      allowOutsideClick && onClose();
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const {
    control: loginControl,
    handleSubmit: loginSubmit,
    errors: loginErrors,
  } = useForm({
    defaultValues: loginFormDefaults,
    resolver: yupResolver(loginFormSchema),
  });

  const {
    control: registerControl,
    handleSubmit: registerSubmit,
    errors: registerErrors,
  } = useForm({
    defaultValues: registerFormDefaults,
    resolver: yupResolver(registerFormSchema),
  });

  const onRegisterSubmit = async (data) => {
    setIsLoading(true);
    const user = {
      fullName: data.fullName,
      email: data.email,
      password: data.password,
      phone: data.phone,
      userType,
    };
    await dispatch(registerWithEmail({ newUser: user, loader: false }));
    authUser?.uid && onClose();
    authUser?.uid && (await userLogin({ ...data, uid: authUser?.uid }));
    setIsLoading(false);
  };

  const onLoginSubmit = async (data) => {
    await dispatch(
      signInWithEmail({
        email: data.email,
        password: data.password,
        loader: false,
      })
    );
    authUser?.uid && onClose();
    authUser?.uid && (await userLogin({ ...data, uid: authUser?.uid }));
  };

  const handleGoogleSignin = async () => {
    await dispatch(signInWithGoogle({ loader: false }));
    onClose();
  };

  const handleSignUp = () => {
    setIsLoginForm(false);
    if (isProvider) {
      dispatch({
        type: tempDataConstants.SET_TEMP_USER,
        userType: roles.provider.value,
      });
      history.push("/auth/register");
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.modalArea} ref={wrapperRef}>
        <Box className={classes.formContainer}>
          {context}
          <Grid container className={classes.socialBtns} spacing={2}>
            <Grid item xs={12} align="center">
              <GoogleBtn className={classes.googleBtn} onClick={handleGoogleSignin}>
                {" "}
                Sign In with Google
              </GoogleBtn>
            </Grid>
          </Grid>
          <DividerWithText>Or</DividerWithText>

          {isLoginForm ? (
            <form className={classes.form} onSubmit={loginSubmit(onLoginSubmit)}>
              <Box style={{ marginBottom: "30px" }}>
                <Controller
                  as={
                    <TextField
                      label="Email"
                      margin="dense"
                      variant="outlined"
                      className={classes.textField}
                      error={!!loginErrors?.email?.message}
                      helperText={loginErrors.email?.message}
                    />
                  }
                  name="email"
                  control={loginControl}
                />
                <Controller
                  as={
                    <TextField
                      label="Password"
                      margin="dense"
                      variant="outlined"
                      type="password"
                      className={classes.textField}
                      error={!!loginErrors?.password?.message}
                      helperText={loginErrors.password?.message}
                    />
                  }
                  name="password"
                  control={loginControl}
                />
              </Box>
              <Grid item style={{ flex: 1 }} align="center">
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("/auth/reset_pw");
                  }}
                  color="primary"
                  className={classes.forgotPassword}
                >
                  Forgot Password?
                </Button>
              </Grid>
              <Grid container justifyContent="flex-start" spacing={2}>
                <Box clone order={{ xs: 2, sm: 1 }}>
                  <Grid item style={{ flex: 1 }} align="center">
                    <Button onClick={handleSignUp} color="primary" className={classes.toggleForm}>
                      Sign Up for Dimension
                    </Button>
                  </Grid>
                </Box>
                <Box clone order={{ xs: 1, sm: 2 }}>
                  <Grid item style={{ flex: 1 }} align="center">
                    <Button type="submit" variant="contained" className={classes.submitBtn}>
                      Sign in
                    </Button>
                  </Grid>
                </Box>
              </Grid>
            </form>
          ) : (
            <form className={classes.form} onSubmit={registerSubmit(onRegisterSubmit)}>
              <Box style={{ marginBottom: "30px" }}>
                <Controller
                  as={
                    <TextField
                      label="Full Name"
                      margin="dense"
                      variant="outlined"
                      className={classes.textField}
                      error={!!registerErrors?.fullName?.message}
                      helperText={registerErrors.fullName?.message}
                    />
                  }
                  name="fullName"
                  control={registerControl}
                />

                <Grid container spacing={2}>
                  <Grid item style={{ flex: 1 }}>
                    <Controller
                      as={
                        <TextField
                          label="Email"
                          margin="dense"
                          variant="outlined"
                          className={classes.textField}
                          error={!!registerErrors?.email?.message}
                          helperText={registerErrors.email?.message}
                        />
                      }
                      name="email"
                      control={registerControl}
                    />
                  </Grid>
                  <Grid item style={{ flex: 1 }}>
                    <Controller
                      name="phone"
                      control={registerControl}
                      render={({ onChange, value }) => (
                        <Input
                          placeholder="Phone number"
                          defaultCountry="US"
                          size="small"
                          label="Phone Number"
                          variant="outlined"
                          fullWidth
                          value={value}
                          onChange={onChange}
                          inputComponent={PhoneField}
                          className={classes.phoneField}
                          style={{ margin: "8px 8px 4px" }}
                          error={!!registerErrors?.phone?.message}
                          helperText={registerErrors.phone?.message}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item style={{ flex: 1 }}>
                    <Controller
                      as={
                        <TextField
                          label="Password"
                          margin="dense"
                          variant="outlined"
                          type="password"
                          className={classes.textField}
                          error={!!registerErrors?.password?.message}
                          helperText={registerErrors.password?.message}
                        />
                      }
                      name="password"
                      control={registerControl}
                    />
                  </Grid>
                  <Grid item style={{ flex: 1 }}>
                    <Controller
                      as={
                        <TextField
                          label="Confirm Password"
                          margin="dense"
                          variant="outlined"
                          type="password"
                          className={classes.textField}
                          error={!!registerErrors?.confirmPassword?.message}
                          helperText={registerErrors.confirmPassword?.message}
                        />
                      }
                      name="confirmPassword"
                      control={registerControl}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Grid container justifyContent="flex-start" spacing={2}>
                <Box clone order={{ xs: 2, sm: 1 }}>
                  <Grid item style={{ flex: 1 }} align="center">
                    <Button onClick={() => setIsLoginForm(true)} color="primary" className={classes.toggleForm}>
                      Already have an account? Login
                    </Button>
                  </Grid>
                </Box>
                <Box clone order={{ xs: 1, sm: 2 }}>
                  <Grid item style={{ flex: 1 }} align="center">
                    <Button type="submit" disabled={isLoading} variant="contained" className={classes.submitBtn}>
                      Create Account
                    </Button>
                  </Grid>
                </Box>
              </Grid>
            </form>
          )}
          {contextFooter}
        </Box>
      </Box>
    </Box>
  );
};

AuthPopup.defaultProps = {
  onClose: () => {},
  allowOutsideClick: true,
  context: null,
  contextFooter: null,
};

export default AuthPopup;
