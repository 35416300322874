import React, { useEffect, useRef, useState } from "react";
import { Typography, useMediaQuery } from "@material-ui/core";
import classes from "@css/components/website/FeatureScroller.module.css";
import useIntersectionObserver from "../../../shared/hooks/useIntersectionObserver";
import classNames from "classnames";
import { useHistory } from "react-router";

type ContentProps = { isMobile?: boolean; feature: any; featureIndex?: number; setImageIndex: any };

function Content({ isMobile, feature, featureIndex, setImageIndex }: ContentProps) {
  const history = useHistory();
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    isVisible && setImageIndex(featureIndex);
  }, [isVisible]);

  const mobileContentStyles = isMobile && feature.backgroundColor ? { backgroundColor: feature.backgroundColor, width: "100%" } : {};

  return (
    <div style={mobileContentStyles} className={classNames(classes.content, { [classes.contentMobile]: isMobile })}>
      {isMobile && <img src={feature.imageUrl} className={classNames(classes.rightImage, classes.rightImageMobile)} />}
      <h2 ref={ref} className={classes.header}>
        {feature.title}
      </h2>
      {feature.subTitle && <Typography className={classes.subHeader}>{feature.subTitle}</Typography>}
      {feature.descriptions.map((description: string) => (
        <Typography key={description} className={classes.description}>
          {description}
        </Typography>
      ))}
      {feature.buttons && feature?.buttons((path: string) => history.push(path))}
    </div>
  );
}

function FeatureScroller({ features }: { features: Array<any> }) {
  const [imageIndex, setImageIndex] = useState(0);
  const isMobile = useMediaQuery("(max-width:959.95px)");
  return isMobile ? (
    <div className={classes.contentContainerMobile}>
      {features.map((feature: any) => (
        <Content isMobile key={feature.title} feature={feature} setImageIndex={setImageIndex} />
      ))}
    </div>
  ) : (
    <div className={classes.featuresWrapper}>
      <div className={classes.featuresColumnLeft}>
        {features.map((feature: any, idx: number) => (
          <Content key={feature.title} feature={feature} setImageIndex={setImageIndex} featureIndex={idx} />
        ))}
      </div>
      <div className={classes.featuresColumnRight}>
        {features.map((feature: any, idx: number) => (
          <div style={{ opacity: imageIndex === idx ? 1 : 0 }} key={feature.title + feature.imageUrl} className={classes.featureIllustrationWrapper}>
            <img src={feature.imageUrl} className={classes.rightImage} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default FeatureScroller;
