import React, { useEffect, useMemo, useState, useRef } from "react";
import classes from "@css/components/website/Header.module.css";
import dimensionLogo from "../../assets/landing_page/dimension-logo.svg";
import dimensionLogoDark from "../../assets/landing_page/dimension-logo-dark.svg";
import accountIcon from "../../assets/landing_page/icons/user.svg";
import accountIconDark from "../../assets/landing_page/icons/user-dark.svg";
import HeaderMenuItem from "./HeaderMenuItem";
import { Button, Drawer, IconButton, useMediaQuery } from "@material-ui/core";
import {
  aboutUsMenuItems,
  accountMenuItems,
  businessMenuItems,
  providersMenuItems,
  loggedInSupportItems,
  supportMenuItems,
  loggedInCustomerSupportMenuItems,
  aboutUsMenuItemsCustomer,
} from "./constants.js";
import MenuIcon from "../../assets/menu-icon.svg";
import MenuIconDark from "../../assets/menu-icon-dark.svg";
import MobileMenu from "./MobileMenu";
import history from "../../history";
import { roles } from "../../constants/roles";
import { useDispatch, useSelector } from "react-redux";
import useScrollPosition from "../../shared/hooks/useScrollPosition";
import LoginModal from "../LoginModal";
import { signOut } from "../../store/actions/auth";
import tempDataConstants from "../../store/constants/tempDataTypes";
import { PowerSettingsNewOutlined } from "@material-ui/icons";
import { checkFeatureFlag } from "../../store/actions/featureFlags";

function Header({ opaque }: { opaque?: boolean }) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:768px)");

  const [open, setOpen] = useState<boolean>(false);
  const [showEditBusiness, setShowEditBusiness] = useState(false);
  const [showIntegrations, setShowIntegrations] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [isOpaque, setIsOpaque] = useState<boolean>(opaque ?? false);

  const subscribed = useRef(false);

  const isLoggedIn = useSelector((state: any) => state.firebase.auth?.uid);
  const profile = useSelector((state: any) => state.firebase.profile);
  const isProvider = profile?.userType === roles.provider.value;
  const isApproved = !profile?.approvalState || profile?.approvalState === "complete";
  const isBanned = profile.isBanned;
  const isBusinessAccount = !!useSelector((state: any) => state.firebase.profile.businessId);

  const scrollPosition = useScrollPosition();

  const includeBusinessItems = async () => {
    const result = await checkFeatureFlag("edit-company-settings", { key: profile?.uid }, false);
    if (subscribed.current === false) return;
    setShowEditBusiness(false);
  };

  const query = useMemo(
    () => ({
      key: profile?.uid,
      custom: {
        market: profile?.smsSettings?.address?.city,
        email: profile?.email ?? "",
        userType: profile?.userType ?? "",
      },
    }),
    [profile]
  );

  const includeIntegrations = async () => {
    const result = await checkFeatureFlag("integrations", query, false);
    if (subscribed.current === false) return;
    setShowIntegrations(result);
  };

  useEffect(() => {
    if (profile?.uid) {
      includeIntegrations();
    }
    if (profile?.uid && isBusinessAccount) {
      includeBusinessItems();
    }
  }, [profile?.uid, isBusinessAccount]);

  useEffect(() => {
    subscribed.current = true;
    return () => {
      subscribed.current = false;
    };
  }, []);

  const signOutMenuItem = {
    label: "Sign Out",
    icon: <PowerSettingsNewOutlined color="primary" />,
    func: () => dispatch(signOut()),
  };

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (event?.type === "keydown" && (event?.key === "Tab" || event?.key === "Shift")) {
      return;
    }
    setIsDrawerOpen(open);
  };

  useEffect(() => {
    const offset = isMobile ? 30 : 200;
    setIsOpaque(opaque ? true : Math.round(scrollPosition / offset) > 0);
  }, [scrollPosition, opaque]);

  const headerClasses = {
    container: isOpaque ? classes.containerOpaque : classes.container,
  };

  const handleLogin = () => {
    dispatch({
      type: tempDataConstants.SET_TEMP_USER,
      userType: roles.customer.value,
    });
    setOpen(true);
  };

  const itemsColorStyle = { color: isOpaque ? "black" : "white" };
  const menuIconProps: any = { style: itemsColorStyle };

  const sideLinks = isProvider
    ? isBanned
      ? accountMenuItems().providerSideNavLinks.filter((link: any) => link.url === "/account")
      : accountMenuItems(profile).providerSideNavLinks.filter((link) => !link.hidden)
    : isBusinessAccount
    ? accountMenuItems()
        .customerSideNavLinks.filter((link) => (link.isBusiness && showEditBusiness) || !link.isBusiness)
        .filter((link) => (link.isIntegrations && showIntegrations) || !link.isIntegrations)
    : accountMenuItems()
        .customerSideNavLinks.filter((link) => !link.isBusiness)
        .filter((link) => (link.isIntegrations && showIntegrations) || !link.isIntegrations);

  // Really need to refactor those conditions below! //
  return (
    <div className={headerClasses.container}>
      <div className={classes.contents}>
        <img
          className={classes.headerLogo}
          onClick={() => history.push("/")}
          style={{ cursor: "pointer" }}
          src={isOpaque ? dimensionLogoDark : dimensionLogo}
          alt="Dimension Logo"
        />
        <div className={classes.headerButtons}>
          <div className={classes.menuContainer}>
            {(!isLoggedIn || !isProvider) && <HeaderMenuItem isOpaque={isOpaque} name="Business" menuItems={businessMenuItems} />}
            {!isLoggedIn && <HeaderMenuItem isOpaque={isOpaque} name="Providers" menuItems={providersMenuItems} />}
            {(!isLoggedIn || !isProvider) && (
              <Button onClick={() => history.push("/sustainability")} style={itemsColorStyle} className={classes.menuButton}>
                Sustainability
              </Button>
            )}
            {!isLoggedIn && <HeaderMenuItem isOpaque={isOpaque} name="About us" menuItems={aboutUsMenuItems} />}
            {isLoggedIn && !isProvider && <HeaderMenuItem isOpaque={isOpaque} name="About us" menuItems={aboutUsMenuItemsCustomer} />}
            {!isLoggedIn && <HeaderMenuItem isOpaque={isOpaque} name="Support" menuItems={supportMenuItems} />}
            {isLoggedIn && !isProvider && <HeaderMenuItem isOpaque={isOpaque} name="Support" menuItems={loggedInCustomerSupportMenuItems} />}
          </div>
          {isLoggedIn && isProvider && (
            <div className={classes.menuContainer}>
              <Button onClick={() => history.push("/provider_portal")} style={itemsColorStyle} className={classes.menuButton}>
                Provider Portal
              </Button>
              <HeaderMenuItem isOpaque={isOpaque} name="Support" menuItems={loggedInSupportItems} />
            </div>
          )}
          <div className={classes.buttonContainer}>
            {!isLoggedIn && (
              <Button
                disableRipple
                onClick={handleLogin}
                className={isOpaque ? classes.secondaryButton : classes.secondaryButtonDark}
                variant="contained"
              >
                Log In
              </Button>
            )}
            {!isBanned && isProvider ? (
              <>
                <Button
                  disableRipple
                  style={{ width: "140px" }}
                  className={classes.primaryButton}
                  disabled={!isApproved}
                  onClick={() => history.push("/get_tasks")}
                >
                  Claim Tasks
                </Button>
                <Button
                  disableRipple
                  style={{ width: "190px" }}
                  className={classes.primaryButton}
                  disabled={!isApproved}
                  onClick={() => history.push("/get_bids")}
                >
                  Bid on Projects
                </Button>
              </>
            ) : (
              <Button
                disableRipple
                onClick={() => history.push("booking?step=initial")}
                className={isOpaque ? classes.primaryButton : classes.primaryButtonDark}
                variant="contained"
              >
                Book Now
              </Button>
            )}
            {isLoggedIn && (
              <HeaderMenuItem
                isOpaque={isOpaque}
                icon={isOpaque ? accountIconDark : accountIcon}
                name=""
                menuItems={[...sideLinks, signOutMenuItem]}
              />
            )}
          </div>
          <IconButton disableRipple className={classes.drawerButton} onClick={toggleDrawer(true)}>
            <img src={isOpaque ? MenuIconDark : MenuIcon} {...menuIconProps} alt="Menu button" />
          </IconButton>
        </div>
      </div>
      <Drawer
        className={classes.drawer}
        classes={{ paperAnchorRight: classes.drawerMenu }}
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
      >
        <MobileMenu isOpaque={isOpaque} onClose={toggleDrawer(false)} options={{ showIntegrations }} />
      </Drawer>
      <LoginModal open={open} closeModal={() => setOpen(false)} />
    </div>
  );
}

export default Header;
