import React, { useEffect, useRef, useState } from "react";
import classes from "@css/components/website/LearnMore.module.css";
import cardboard from "../../../assets/landing_page/sustainability/cardboard.jpg";
import { Button, Typography } from "@material-ui/core";
import classNames from "classnames";
import { getWordpressPostList } from "../../../pages/utils/wordpress";
import ReactHtmlParser from "react-html-parser";
import { useHistory } from "react-router-dom";

function LearnMore() {
  const history = useHistory();
  const [posts, setPosts] = useState([]);

  const subscribed = useRef(false);

  const getPost = async () => {
    await getWordpressPostList("1385", setPosts, subscribed);
  };

  useEffect(() => {
    subscribed.current = true;
    getPost();
    return () => {
      subscribed.current = false;
    };
  }, []);

  const openResource = (link: string) => {
    history.push(link);
  };

  const Resource = ({ resource }: { resource: any }) => (
    <div className={classes.resource} onClick={() => openResource(`/materials/${resource.slug}`)}>
      <div>
        <Typography className={classNames(classes.heading, classes.resourceCategory)}>How To</Typography>
        <Typography className={classNames(classes.heading, classes.resourceTitle)}>
          {ReactHtmlParser(resource.title?.rendered?.replace("How To ", ""))}
        </Typography>
      </div>
      <img src={resource.jetpack_featured_media_url ?? ""} className={classes.imgIcon} />
    </div>
  );

  return (
    <section className={classes.container}>
      <div className={classes.mainContent}>
        <div className={classes.textContainer}>
          <Typography className={classes.heading}>Learn More About Sustainable Waste Management</Typography>
          <Typography className={classes.description}>
            Dive into our Sustainability Hub and discover resources covering best practice for disposing or recycling everything from cardboard to
            commercial food waste.
          </Typography>
        </div>
        <div className={classes.resourceContainer}>
          <Typography className={classNames(classes.heading, classes.listHeader)}>Sustainability Resource Hub</Typography>
          {posts.map((post, index) => (
            <Resource key={index} resource={post} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default LearnMore;
