import { Input } from "./enums";

export const bookFormFields = [Input.Task, Input.PickupAddress];

export const removalDateAndTimeFormFields = [Input.Date, Input.Time, Input.Frequency];

export const rentalDateAndTimeFormFields = [Input.Date, Input.Time, Input.Frequency, Input.Pickup, Input.PickupDate, Input.PickupTime];

export const removalTaskDescriptionFormFields = [Input.Vehicle, Input.Junk, Input.Description, Input.Stairs, Input.Dismantling];

export const rentalTaskDescriptionFormFields = [Input.Dumpster, Input.Description, Input.UnderstandPricing];

export const addressFormFields = [Input.BusinessClient, Input.CompanyName, Input.PickupAddress, Input.PickupAddressAdditional];

export const reviewOrderFormFields = [Input.TermsOfService];

export const paymentFormFields = [Input.PaymentFirstName, Input.PaymentLastName, Input.PaymentEmail, Input.PaymentPhone];

// For Booking Quotes
export const quoteReviewItemsFormFields = [Input.Junk, Input.Vehicle];
export const quoteReviewDescriptionFormFields = [Input.Description, Input.Stairs, Input.Dismantling];

// For Booking Bids
export const bidRemovalTaskDescriptionFormFields = [Input.Description, Input.Stairs, Input.Dismantling, Input.TaskImages];
export const bidRentalTaskDescriptionFormFields = [Input.Description, Input.TaskImages];