import React, { useState } from "react";
import { Box, makeStyles, Typography, Link, Grid } from "@material-ui/core";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import Button from "@shared/components/Button";
import Hidden from "@shared/components/Hidden";
import NeedHelp from "@shared/components/NeedHelp";
import ConfirmationModal from "@shared/components/ConfirmationModal";

import Total from "./summary/Total";
import PriceQuoteDescription from "./summary/PriceQuoteDescription";
import { bidCPDiscount } from "../../../constants/bids";
import PriceMatchTag from "./PriceMatchTag";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f2f2f2",
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3, 3),
      flexDirection: "column",
    },
    "& .title": {
      fontSize: 24,
      marginBottom: theme.spacing(3),
    },
    "& .price-quote": {
      flex: 1,
      marginBottom: 0,
      marginTop: 40,
      [theme.breakpoints.down("sm")]: {
        marginTop: 0,
      },
      "& .item-tag": {
        fontSize: 14,
        fontWeight: 800,
      },
    },
    "& .booking-details": {
      paddingBottom: theme.spacing(7),
    },
    "& .total": {
      "& .item-tag": {
        fontSize: 24,
        fontWeight: 800,
      },
      "& .item-price": {
        fontSize: 18,
      },
    },
    "& .items": {
      marginBottom: theme.spacing(4),
      flex: 1,
    },
  },
  popupTitle: {
    fontSize: 20,
    fontWeight: 700,
  },
  pricePopup: {
    padding: "0 5px 0 20px",
  },
  pricing: {
    opacity: "0.8",
    marginTop: 5,
    fontSize: 14,
  },
  pricingLink: {
    cursor: "pointer",
    color: "inherit",
    textDecoration: "underline",
  },
}));

const PriceQuote = ({ enableCheckoutButton, onClick, loading, draftBooking, summaryProps }) => {
  const { task, vehicle, date, junk, pickupDate, dumpster, stairs, dismantling, total, showReducedPrice } = summaryProps;
  const [pricePopup, setPricePopup] = useState(false);
  const adjustedTotal = showReducedPrice ? total * ((100 - bidCPDiscount) / 100) : total;

  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Hidden desktop>
        <ConfirmationModal
          open={pricePopup}
          body={
            <>
              <Typography className={styles.popupTitle}>Price Quote</Typography>
              <PriceQuoteDescription summaryProps={summaryProps} draftBooking={draftBooking} />
            </>
          }
          hideCancel
          fullWidth
          proceedBtnText="CLOSE"
          onProceed={() => setPricePopup(false)}
          onCancel={() => setPricePopup(false)}
          dropShadow={false}
        />
        <div onClick={() => setPricePopup(true)}>
          <Total
            tag={
              <span style={{ display: "flex" }}>
                <span>Price Quote</span>
                <KeyboardArrowDownIcon className={styles.pricePopup} />
              </span>
            }
            className="price-quote"
            date={date}
            task={task}
            vehicle={vehicle}
            junk={junk}
            dumpster={dumpster}
            stairs={stairs}
            dismantling={dismantling}
            pickupDate={pickupDate}
            total={adjustedTotal}
            isServiceFeeWaived={draftBooking?.isServiceFeeWaived}
            isVehicleFeeWaived={draftBooking?.isVehicleFeeWaived}
            isLockedPrice={draftBooking?.isLockedPrice}
          />
        </div>
      </Hidden>
      <Hidden mobile>
        <Typography variant="h3" className="title">
          Price Quote
        </Typography>
        <PriceQuoteDescription summaryProps={summaryProps} draftBooking={draftBooking} />
        <PriceMatchTag />
        <Button fullWidth disabled={!enableCheckoutButton} color="primary" variant="contained" onClick={onClick} loading={loading}>
          Checkout
        </Button>
        <NeedHelp />
        <div className={styles.pricing}>
          Click{" "}
          <Link
            href="https://intercom.help/trash-warrior/en/articles/4521231-trash-warrior-pricing-faq"
            target="_blank"
            className={styles.pricingLink}
          >
            here
          </Link>{" "}
          to understand our pricing.
        </div>
      </Hidden>
    </div>
  );
};

PriceQuote.defaultProps = {
  date: undefined,
  apartment: undefined,
  repeat: false,
  onClick: () => {},
};

export default React.memo(PriceQuote);
