import { segmentTrack } from "../../../helpers/segment";
import { getTasksAnalyticsTypes } from "../../constants/analyticsTypes";

export const trackGetTaskAnalytics = () => (_, getState) => {
  const state = getState().analytics.getTasks;
  const { totalTasks, initialVisibility, isMobileView, viewedTasks, timeOnPage } = state;
  segmentTrack("Get Tasks Page", {
    totalTasks,
    initialVisibility,
    isMobileView,
    viewedTasks: viewedTasks.length,
    timeOnPage,
  });
};

export const setTotalAvailableTasks = (tasksCount) => (dispatch) => {
  if (!tasksCount) return;
  dispatch({ type: getTasksAnalyticsTypes.SET_TOTAL_TASKS, payload: tasksCount });
};

export const setInitialVisibility = (isMobile) => (dispatch) => {
  const initialInviewCount = isMobile ? 1 : 3;
  dispatch({ type: getTasksAnalyticsTypes.SET_INITIAL_VISIBILITY, payload: initialInviewCount });
};

export const setTaskViewed = (taskId) => (dispatch, getState) => {
  const viewedTasks = getState().analytics.getTasks.viewedTasks;
  if (!taskId) return;
  if (viewedTasks.includes(taskId)) return;

  dispatch({ type: getTasksAnalyticsTypes.VIEWED_TASKS, payload: [...viewedTasks, taskId] });
};

export const setIsMobileView = (isMobileView) => (dispatch) => {
  dispatch({ type: getTasksAnalyticsTypes.IS_MOBILE_VIEW, payload: isMobileView });
};

export const setTimeOnPage = (timeOnPage) => (dispatch) => {
  dispatch({ type: getTasksAnalyticsTypes.TIME_ON_PAGE, payload: timeOnPage });
};

export const reset = () => (dispatch) => {
  dispatch({ type: getTasksAnalyticsTypes.RESET_GET_TASKS_ANALYTICS });
};
