import React, { useState, useEffect } from "react";
import { BooleanParam, StringParam, useQueryParam } from "use-query-params";
import Booking from "./components/Booking";
import BookingQuote from "./components/BookingQuote";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router";
import { useHistory } from "react-router-dom";
import { roles } from "../../constants/roles";
import Loader from "../../shared/components/Loader";
import { toastr } from "react-redux-toastr";
import { fetchItemsWithoutPrices } from "../../store/actions/items";
import { fetchAndSetAllQuote, fetchAndSetBooking, fetchAndSetBookingQuote, resetSelectedBooking } from "../../store/actions/booking";
import { setTotalsForBookings } from "./helpers/utils";
import { resetAddress } from "../../store/actions/address";
import { resetSelectedCoupon } from "../../store/actions/coupon";
import AuthPopupContext from "./components/AuthPopupContext";
import AuthContextFooter from "./components/AuthContextFooter";
import AuthPopup from "../../shared/components/AuthPopup";
import { Box } from "@material-ui/core";

function BookingPage() {
  const [isLoadingFirst, setIsLoadingFirst] = useState(true);
  const [isLoadingThird, setIsLoadingThird] = useState(true);
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [allQuotes, setAllQuotes] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state: any) => state.firebase.profile);
  const userType = user?.userType;
  const isBusiness = !!user?.businessId;
  const draftBooking = useSelector((state: any) => state.booking.draftBooking);
  const selectedBooking = useSelector((state: any) => state.booking.selectedBooking);

  const [task, setTask] = useQueryParam("task", StringParam);
  const [businessClient, setBusinessClient] = isBusiness ? useState(true) : useQueryParam("business_client", BooleanParam);
  const [repeat] = useQueryParam("repeat", BooleanParam);
  const [bookingId] = useQueryParam("booking_id", StringParam);
  const [draftBookingId] = useQueryParam("draft_id", StringParam);
  const [isDefaultBid] = useQueryParam("bid", BooleanParam);
  const [source] = useQueryParam("source", StringParam);
  const [isMultipleQuotes = false] = useQueryParam("all_quotes", BooleanParam);
  const [showAuthPopup, setShowAuthPopup] = useState(false);

  const priceMultiplier = useSelector((state: any) => state.serviceLocations.activeLocation?.priceMultipliers ?? 100);
  const selectedCoupon = useSelector((state: any) => state.coupon.selectedCoupon);

  useEffect(() => {
    dispatch(fetchItemsWithoutPrices());
  }, []);

  useEffect(() => {
    if (repeat && bookingId) {
      const setDoItAgain = async () => {
        const booking: any = await dispatch(fetchAndSetBooking(bookingId));
        if (booking) {
          setTask(booking.task);
          setBusinessClient(booking.businessClient);
        }
        setIsLoadingFirst(false);
      };
      setDoItAgain();
    } else {
      setIsLoadingFirst(false);
    }
  }, [repeat]);

  const setBookingDraft = async () => {
    const booking: any = await dispatch(fetchAndSetBookingQuote(draftBookingId));
    if (booking) {
      setTask(booking.task);
      setBusinessClient(booking.businessClient);
    }
    setIsLoadingThird(false);
  };

  const setMultipleBookingDraft = async () => {
    if (user.uid) {
      const bookings: any = await dispatch(fetchAndSetAllQuote(user.uid));
      if (bookings) {
        setTotalsForBookings(bookings, priceMultiplier, selectedCoupon);
        setAllQuotes(bookings);
      }
      setIsLoadingThird(false);
    } else {
      // Temporary until a better flow is determined
      toastr.error("Unauthenticated", "Please log in to view your available quotes");
      setIsLoadingThird(false);
    }
  };

  const resetBooking = () => {
    dispatch(resetSelectedBooking());
    dispatch(resetAddress());
    dispatch(resetSelectedCoupon());
    setShowAuthPopup(false);
  };

  const isQuoteUserMatching = () => {
    if (!draftBooking) return;
    const userType = user?.userType;
    // Don't display the Auth Popup if it is an admin
    if (userType && !["customer", "provider", "business"].includes(userType)) return;

    const bookingQuoteCustomerId = draftBooking.customer_id;
    const quoteCustomerAuthed = bookingQuoteCustomerId === user.uid;
    if (!user.uid && bookingQuoteCustomerId) {
      setShowAuthPopup(true);
      setIsAuthChecked(true);
      return;
    }
    if (!bookingQuoteCustomerId) {
      setShowAuthPopup(false);
      setIsAuthChecked(true);
      return;
    }
    // user logged in but does not match the quote customer
    if (bookingQuoteCustomerId !== user.uid) {
      toastr.info("", "This quote was not created for this account. Redirecting...");
      history.push("/booking?step=initial");
      resetBooking();
      return;
    }
    // user logged in and matches the quote customer
    if (quoteCustomerAuthed) {
      setShowAuthPopup(false);
      setIsAuthChecked(true);
    }
  };

  const checkAuth = () => {
    if (!draftBookingId && !isMultipleQuotes) {
      setShowAuthPopup(false);
      setIsAuthChecked(true);
      return;
    }

    if (isMultipleQuotes && !user.uid) {
      setShowAuthPopup(true);
      setIsAuthChecked(true);
      return;
    }

    if (isMultipleQuotes) {
      setShowAuthPopup(false);
      setIsAuthChecked(true);
      return;
    }

    isQuoteUserMatching();
  };

  useEffect(() => {
    if (!isMultipleQuotes) return;
    setMultipleBookingDraft();
  }, [isMultipleQuotes, user?.uid]);

  useEffect(() => {
    if (isMultipleQuotes) return;
    if (draftBookingId) {
      setBookingDraft();
    } else {
      setIsLoadingThird(false);
    }
  }, [draftBookingId, isMultipleQuotes]);

  useEffect(() => {
    checkAuth();
  }, [isMultipleQuotes, draftBookingId, draftBooking, user]);

  // only guests and customers can create booking
  if (userType && userType !== roles.customer.value) return <Redirect to="/" />;

  if (isLoadingFirst || isLoadingThird || !isAuthChecked || (draftBookingId && !selectedBooking)) return <Loader />;

  if (showAuthPopup) {
    return (
      <Box>
        <div style={{ minHeight: "100vh" }}>
          <AuthPopup
            onClose={isQuoteUserMatching}
            allowOutsideClick={false}
            context={<AuthPopupContext resetBooking={resetBooking} />}
            contextFooter={<AuthContextFooter resetBooking={resetBooking} />}
            isProvider={false}
          />
        </div>
      </Box>
    );
  }

  return (
    <>
      {(draftBookingId && !isLoadingThird) || allQuotes.length > 0 ? (
        <BookingQuote
          draftBooking={isMultipleQuotes ? allQuotes : draftBooking}
          isMultipleQuotes={isMultipleQuotes && allQuotes.length > 0}
          resetBooking={resetBooking}
        />
      ) : (
        <Booking
          defaultTask={task}
          defaultBusinessClient={businessClient}
          repeat={!!repeat}
          isDefaultBid={isDefaultBid ?? false}
          sourceReferral={source ?? ""}
        />
      )}
    </>
  );
}

export default BookingPage;
