import React, { useEffect, useState } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import OtpInput from "react-otp-input";
import Button from "@shared/components/Button";
import { checkVerifyToken } from "@store/actions/twilio";

const useStyles = makeStyles((theme) => ({
  OtpSection: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  OtpInput: {
    fontSize: "1.3rem",
    background: "#fafafa",
    width: "40px !important",
    height: "40px",
    border: "none",
    margin: "20px 2px",
    [theme.breakpoints.up("md")]: {
      fontSize: "2.5rem",
      margin: "20px 10px",
      fontWeight: 600,
    },
  },
  actions: {
    display: "grid",
    gridTemplateColumns: "120px 120px",
    columnGap: 10,
    marginTop: 20,
  },
  resend: {
    background: "none",
    textDecoration: "underline",
    color: "blue",
    marginLeft: 5,
    display: "inline-block",
    border: "none",
    cursor: "pointer",
  },
}));

const OtpValidationPopup = ({ phoneNumber, setOpen, setNumberVerified, resend }) => {
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [message, setMessage] = useState("");
  const [load, setLoad] = useState(false);

  const handleVerify = async (e) => {
    e.preventDefault();
    setLoad(true);
    if (!phoneNumber) {
      setMessage("No phone number provided");
      setLoad(false);
      return;
    }
    if (value.length !== 6) {
      setMessage("Code isn't long enough");
      setLoad(false);
    }

    const res = await checkVerifyToken(phoneNumber, value);
    if (!res) {
      setMessage("There is an issue verifying this number");
      setLoad(false);
      return;
    }
    if (res.status === "approved") {
      await setNumberVerified(setMessage);
      setLoad(false);
    } else {
      setMessage("Unable to send to this phone number");
      setValue("");
      setLoad(false);
    }
  };

  return (
    <Box className={classes.root}>
      <Typography style={{ fontSize: "1.1rem" }}>Please enter the code sent to your phone number to verify your account.</Typography>
      <br />
      <Box className={classes.OtpSection}>
        <OtpInput
          inputStyle={classes.OtpInput}
          isInputNum
          numInputs={6}
          value={value}
          onChange={(val) => {
            setValue(val);
            setMessage("");
          }}
        />
      </Box>
      <Box className={classes.OtpSection}>
        <Box className={classes.actions}>
          <Button variant="contained" className={classes.cancel} onClick={() => setOpen(false)}>
            {"Cancel"}
          </Button>
          <Button variant="contained" color="primary" loading={load} className={classes.submit} onClick={handleVerify}>
            {"Submit"}
          </Button>
        </Box>
      </Box>
      <br />
      <br />
      <Box style={{ display: "flex" }}>
        <Typography variant="body1" onClick={(e) => resend(e, setMessage)}>
          {`Didn't get a code?`} <span className={classes.resend}>Resend code</span>
        </Typography>
      </Box>
      <Box className={classes.errorBox}>{message && message}</Box>
    </Box>
  );
};

export default OtpValidationPopup;
