import React, { Fragment, useEffect, useState } from "react";
import { makeStyles, Typography, Link, Button, Hidden } from "@material-ui/core";

import Total from "../summary/Total";
import PriceQuoteDescription from "../summary/PriceQuoteDescription";
import { getSummaryPropsForPriceQuote } from "../../helpers/utils";
import NumberService from "../../../../shared/services/NumberService";
import NeedHelp from "../../../../shared/components/NeedHelp";
import HeaderAccordion from "./HeaderAccordion";

type QuotePriceQuoteProps = {
  enableCheckoutButton: boolean;
  onClick: () => void;
  bookings: any;
  confirmedQuotes: Array<string>;
  setConfirmedQuotes: any;
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f2f2f2",
    padding: "16px",
    [theme.breakpoints.up("md")]: {
      padding: "24px 24px",
      flexDirection: "column",
    },
  },
  title: {
    fontSize: 24,
    marginBottom: "16px",
  },
  "price-quote": {
    flex: 1,
    marginBottom: 0,
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
    "& .item-tag": {
      fontSize: 14,
      fontWeight: 800,
    },
  },
  "booking-details": {
    paddingBottom: "56px",
  },
  total: {
    "& .item-tag": {
      fontSize: 24,
      fontWeight: 800,
    },
    "& .item-price": {
      fontSize: 18,
    },
  },
  items: {
    marginBottom: "32px",
    flex: 1,
  },
  popupTitle: {
    fontSize: 20,
    fontWeight: 700,
  },
  pricing: {
    opacity: "0.8",
    marginTop: 5,
    fontSize: 14,
  },
  pricingLink: {
    cursor: "pointer",
    color: "inherit",
    textDecoration: "underline",
  },
  accordionContainer: {
    marginBottom: "24px",
  },
  accordionRoot: {
    boxShadow: "none",
    backgroundColor: "#f2f2f2",
    minWidth: "285px",
    margin: 0,
    ":before": {
      backgroundColor: "none",
    },
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  accordionSummaryRoot: {
    padding: 0,
  },
  accordionSummaryContent: {
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  accordionDetailsRoot: {
    padding: "0 8px",
  },
  accordionIcon: {
    transition: "none",
  },
}));

function MultiQuotePriceDetails({ enableCheckoutButton, onClick, bookings, confirmedQuotes, setConfirmedQuotes }: QuotePriceQuoteProps) {
  const classes = useStyles();
  const [quoteDetails, setQuoteDetails] = useState<Array<any>>([]);
  const [consolidatedTotal, setConsolidatedTotal] = useState(0);

  const calculateTotals = () => {
    let quoteData: Array<any> = [];
    let total = 0;
    bookings?.forEach((booking: any) => {
      const isConfirmedQuote = confirmedQuotes.includes(booking.quoteNumber);
      quoteData.push(getSummaryPropsForPriceQuote({ ...booking, isConfirmedQuote }));
      if (isConfirmedQuote) {
        total = total + booking.finalTotal;
      }
    });
    setConsolidatedTotal(total);
    setQuoteDetails(quoteData);
  };

  useEffect(() => {
    calculateTotals();
  }, [bookings, confirmedQuotes]);

  const handleConfirmItem = (quoteNumber: string) => {
    if (confirmedQuotes.includes(quoteNumber)) {
      setConfirmedQuotes((prevValues: Array<string>) => prevValues.filter((item: string) => item !== quoteNumber));
    } else {
      setConfirmedQuotes(Array.from(new Set([...confirmedQuotes, quoteNumber])));
    }
  };

  if (quoteDetails.length === 0) {
    return <Fragment />;
  }

  return (
    <Hidden smDown>
      <div className={classes.root}>
        <Typography variant="h3" className={classes.title}>
          Price Quote
        </Typography>
        <div className={classes.accordionContainer}>
          {quoteDetails.map((quote, index) => {
            return (
              <HeaderAccordion
                key={`${quote.quoteNumber}-${index}`}
                classes={classes}
                quoteNumber={quote.quoteNumber}
                total={NumberService.formatToCurrency(quote.finalTotal)}
                isConfirmedQuote={quote.isConfirmedQuote}
                handleConfirmItem={handleConfirmItem}
              >
                <PriceQuoteDescription summaryProps={quote} draftBooking={quote} />
              </HeaderAccordion>
            );
          })}
        </div>
        <Total className={classes.total} overallTotal={consolidatedTotal} />
        <Button fullWidth disabled={!enableCheckoutButton} color="primary" variant="contained" onClick={onClick}>
          Checkout
        </Button>
        <NeedHelp />
        <div className={classes.pricing}>
          Click{" "}
          <Link
            href="https://intercom.help/trash-warrior/en/articles/4521231-trash-warrior-pricing-faq"
            target="_blank"
            className={classes.pricingLink}
          >
            here
          </Link>{" "}
          to understand our pricing.
        </div>
      </div>
    </Hidden>
  );
}

export default MultiQuotePriceDetails;
