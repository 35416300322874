/* eslint-disable no-console */
import React, { useState, useEffect, useImperativeHandle, forwardRef, useCallback } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";

import { CircularProgress, makeStyles, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import isEmpty from "@methods/isEmpty";
import { sortA_Z } from "@methods/sort";
import { useFetchCustomersWithIDB, useFetchProvidersWithIDB } from "@store/actions/users";
import { roles } from "../../../constants/roles";
import { envs } from "../../../constants/envs";
import { fetchEUsers, fetchUserProfileInfo } from "../../../store/actions/users";
import { constructUserSearchQuery } from "../../../helpers/elasticSearch";
import { formatEUsers, formatUsers } from "../../../views/Admin/HomePage/utils/format";
import debounce from "@methods/debounce";

const typeMap = {
  email: "email",
  name: "fullName",
  company: "company",
  id: "id",
  phone: "phoneNumber",
};

const useStyles = makeStyles((theme) => ({
  optionLabel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  label: {
    width: 222,
  },
}));

const AutoCompleteUserClient = forwardRef(({ control, errors, name, type, labelType, onChange, multiple, userType, label, ...rest }, ref) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const fetchUsers = (() => {
    switch (userType) {
      case "provider":
        return useFetchProvidersWithIDB();
      case "customer":
        return useFetchCustomersWithIDB();
      default:
        return [];
    }
  })();

  const options = useSelector(
    (state) =>
      state.auth[
        (() => {
          switch (userType) {
            case "provider":
              return "providers";
            case "customer":
              return "customers";
            default:
              return "users";
          }
        })()
      ]
  );

  useEffect(() => {
    if (!options.length) {
      setIsLoading(true);
      fetchUsers();
    } else {
      setIsLoading(false);
    }
  }, [options]);

  const defaultValue = multiple ? [] : null;
  const [value, setValue] = useState(defaultValue);

  useImperativeHandle(ref, () => ({
    reset: () => setValue(defaultValue),
  }));

  const error = errors[name];

  const handleChange = (value, cb) => {
    setValue(value);
    // update fields
    if (multiple) cb(value.map((v) => v?.[typeMap[type]] ?? ""));
    else cb(value?.[typeMap[type]] ?? "");
    // callback to update other fields
    onChange(value);
  };

  const constructValue = (defaultValue) => {
    const findVal = (val) => options?.find((v) => v?.[typeMap[type]] === val);
    const format = (val) => (typeof val === "string" ? findVal(val) : val);
    if (multiple) return !isEmpty(value) ? value : defaultValue.map(format);
    return value ?? format(defaultValue);
  };

  const renderOption = (option) => {
    if (Array.isArray(labelType))
      return (
        <div className={classes.optionLabel}>
          {sortA_Z(labelType).map((label) => (
            <span className={classes.label} key={label}>
              {option?.[typeMap[label]]}
            </span>
          ))}
        </div>
      );
    return option?.[typeMap[labelType]];
  };

  const getOptionLabel = (option) => option?.[typeMap[Array.isArray(labelType) ? labelType[0] : labelType]] || "";

  return (
    <Controller
      render={(props) => {
        return (
          <Autocomplete
            multiple={multiple}
            options={options}
            value={constructValue(props.value) || ""}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            loading={isLoading}
            renderInput={(params) => <TextField {...params} variant="outlined" error={!!error} helperText={error?.message} label={label} {...rest} />}
            onChange={(_, nextValue) => handleChange(nextValue, props.onChange)}
          />
        );
      }}
      name={name}
      control={control}
    />
  );
});

const AutoCompleteUserServer = forwardRef(({ control, errors, name, type, labelType, onChange, multiple, userType, label, ...rest }, ref) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const fetchUsers = async () => {
    const response = await fetchEUsers(constructUserSearchQuery(userType, inputValue));
    if (response?.results) {
      const users = formatEUsers(response.results);
      setOptions([...users]);
    }
    setLoading(false);
  };

  const fetchUserInfo = async (id) => {
    setLoading(true);
    const response = await fetchUserProfileInfo(id);
    if (response) {
      const [user] = formatUsers([{ ...response, id }]);
      setInputValue(user?.[typeMap[Array.isArray(labelType) ? labelType[0] : labelType]] ?? "");
    }
    setLoading(false);
  };

  const debouncedSearchQuery = useCallback(debounce(fetchUsers, 300), [inputValue]);

  useEffect(() => {
    if (open && [roles.provider.value, roles.customer.value].includes(userType)) {
      setOptions([]);
      setLoading(true);
      debouncedSearchQuery();
    }
  }, [inputValue, open]);

  const defaultValue = multiple ? [] : null;
  const [value, setValue] = useState(defaultValue);

  useImperativeHandle(ref, () => ({
    reset: () => setValue(defaultValue),
  }));

  const error = errors[name];

  const handleChange = (value, cb) => {
    setInputValue(value?.[typeMap[Array.isArray(labelType) ? labelType[0] : labelType]] ?? "");
    setValue(value);
    // update fields
    if (multiple) cb(value.map((v) => v?.[typeMap[type]] ?? ""));
    else cb(value?.[typeMap[type]] ?? "");
    // callback to update other fields
    onChange(value);
  };

  const constructValue = (defaultValue) => {
    const findVal = (val) => options.find((v) => v?.[typeMap[type]] === val);
    const format = (val) => (typeof val === "string" ? findVal(val) : val);
    if (multiple) return !isEmpty(value) ? value : defaultValue.map(format);
    return value ?? format(defaultValue);
  };

  const renderOption = (option) => {
    if (Array.isArray(labelType))
      return (
        <div className={classes.optionLabel}>
          {sortA_Z(labelType).map((label) => (
            <span className={classes.label} key={label}>
              {option?.[typeMap[label]]}
            </span>
          ))}
        </div>
      );
    return option?.[typeMap[labelType]];
  };

  const getOptionLabel = (option) => option?.[typeMap[Array.isArray(labelType) ? labelType[0] : labelType]] || "";

  return (
    <Controller
      render={(props) => {
        useEffect(() => {
          if (!inputValue && props.value && type === typeMap.id) {
            fetchUserInfo(props.value);
          }
        }, [props.value]);

        return (
          <Autocomplete
            id="auto-complete-user"
            open={open}
            inputValue={inputValue}
            value={constructValue(props.value) || ""}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option[type] === value[type]}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            options={options}
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                error={!!error}
                helperText={error?.message}
                label={label}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
                {...rest}
              />
            )}
            onChange={(_, nextValue) => handleChange(nextValue, props.onChange)}
          />
        );
      }}
      name={name}
      control={control}
    />
  );
});

const AutoCompleteUserField = (props) => {
  const currentEnv = process.env.REACT_APP_ENV;
  const locDevEnvs = [envs.local.value, envs.development.value];
  const stagingProdEnvs = [envs.staging.value, envs.production.value];

  if (locDevEnvs.includes(currentEnv)) {
    // for dev/local envs
    return <AutoCompleteUserClient {...props} />;
  }

  if (stagingProdEnvs.includes(currentEnv)) {
    // for staging/prod envs
    return <AutoCompleteUserServer {...props} />;
  }

  return null;
};

AutoCompleteUserField.defaultProps = {
  labelType: "name",
  type: "name",
  onChange: () => {},
  multiple: false,
};

AutoCompleteUserField.propTypes = {
  labelType: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  type: PropTypes.oneOf(["name", "email", "id", "phone"]),
  userType: PropTypes.oneOf([roles.provider.value, roles.customer.value]),
};

export default AutoCompleteUserField;
