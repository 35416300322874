/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Button,
  Card,
  CardContent,
  Grid,
  Divider,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Link,
  useMediaQuery,
  Box,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import RatingWarrior from "../OrdersPage/RatingWarrior";

import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import EventNoteIcon from "@material-ui/icons/EventNote";
import AssignmentTurnedInOutlined from "@material-ui/icons/AssignmentTurnedInOutlined";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";

import NoData from "@shared/components/NoData";
import Loader from "@shared/components/Loader";

import tempDataConstants from "@store/constants/tempDataTypes";
import { bookingStatus } from "@constants/booking";
import { setActiveBooking } from "@store/actions/booking";
import ImageGalleryCustom from "@shared/components/ImageGallery";
import ImageSwiper from "@shared/components/ImageGallery/ImageSwipe";
import OrderReviewCard from "./OrderReviewCard";
import { dumpSiteTreatments, dumpSiteTypes } from "../../../constants/dumpSites";
import BookingDetailsLayout from "@shared/components/BookingDetailsLayout";
import { Map, PictureInPicture } from "@material-ui/icons";
import WarriorCard from "../OrdersPage/WarriorCard";
import GoogleMaps from "@shared/components/GoogleMaps";
import { getDumpsite } from "../../../helpers/dumpSites";
import { scrollToTop } from "../../../methods/scrollToTop";
import { segmentTrack } from "../../../helpers/segment";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("xs")]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
    },
  },
  backBtn: {
    height: "30px",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.hover,
    },
  },
  header: {
    display: "grid",
    gridTemplateColumns: "30px auto",
    [theme.breakpoints.down("sm")]: {
      margin: "unset",
      display: "grid",
      gridTemplateColumns: "auto",
      padding: theme.spacing(2),
    },
  },
  titleContainer: {
    [theme.breakpoints.down("sm")]: {
      margin: "unset",
    },
    margin: "0 20px",
  },
  detailsConatiner: {
    padding: theme.spacing(2, 4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 0),
    },
  },
  cardMobile: {
    position: "sticky",
    bottom: "0px",
    zIndex: 1,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  greyedText: {
    color: "#4F4F4F",
  },
  linkText: {
    textDecoration: "underline",
    fontSize: "1rem",
    color: "blue",
  },
  greenText: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  headerText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "27px",
    },
    fontSize: "32px",
    fontWeight: "800",
  },
  detailsItemTitle: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  card: {
    border: "1px solid #f1f1f1",
    maxWidth: "100%",
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },
  // totalWasteCard: {
  //   paddingTop: theme.spacing(1),
  //   "& div": {
  //     display: "flex",
  //     "& h6": {
  //       flexGrow: 1,
  //       lineHeight: 1.4,
  //     },
  //     "& h3": {
  //       [theme.breakpoints.up("sm")]: {
  //         alignSelf: "end",
  //       },
  //     },
  //     [theme.breakpoints.down("md")]: {
  //       flexDirection: "column",
  //     },
  //   },
  // },
  totalWasteCard: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(-4),
    "& div": {
      "& h6": {
        flexGrow: 1,
        lineHeight: 1.4,
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
    border: "1px solid #eee",
  },
  itemContainer: {
    display: "grid",
    gridTemplateColumns: "50px auto",
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "start",
      padding: theme.spacing(2, 0),
      "& div:last-child": {
        flexGrow: 1,
        justifySelf: "start",
        [theme.breakpoints.down("md")]: {
          maxWidth: "100%",
        },
      },
    },
  },
  itemContent: {
    display: "grid",
    gridTemplateColumns: "200px auto",
    alignItems: "start",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      marginLeft: theme.spacing(3),
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
  },
  itemTitle: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 10,
    },
    fontSize: "18px",
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  itemValue: {
    width: "100%",
    marginTop: 2,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  table: {
    width: "100%",
    boxShadow: "none",
    "& > * > tr > *": {
      fontSize: "16px",
      borderBottom: "none",
      maxHeight: theme.spacing(1),
      lineHeight: 0.5,
    },
  },
  imageGalleryContainer: {
    marginTop: 15,
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  imageSwipeContainer: {
    marginTop: 15,
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  wasteStreamRow: {
    display: "grid",
    gridTemplateColumns: "auto max-content",
    maxWidth: 300,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontWeight: 500,
  },
  streamType: {
    color: "#828282",
  },
  streamWeight: {
    color: "#828282",
  },
  content: {
    padding: theme.spacing(2),
  },
  wasteDivertedLabel: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 10,
    color: "#828282",
  },
  wasteStreamLabel: {
    fontSize: 15,
    fontWeight: 600,
    textDecoration: "underline",
    color: "#828282",
    marginBottom: 5,
  },
  dumpSiteName: {
    fontWeight: 600,
  },
}));

function ReviewOrder() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const bookingId = useParams().id;
  const [destination, setDestination] = useState(null);
  const [renderMap, setRenderMap] = useState(false);

  const isLoading = useSelector((state) => state.booking.isLoading);
  const booking = useSelector((state) => state.booking.selectedBooking);
  const googleMapsPoints = booking && [booking?.address.coordinates];
  const directions =
    destination && booking ? { origin: booking.address.coordinates, destination: { lat: destination.lat, lng: destination.lng } } : null;

  const bookingWeight = booking?.dumpWeight || booking?.totalWeight;
  const landfill = booking?.dumpsiteType === dumpSiteTypes.landfill.value;
  // group by waste stream
  const wasteStreams = (booking?.products || []).reduce((acc, item) => {
    if (!item?.item?.wasteStream) return acc;
    return {
      ...acc,
      [item.item.wasteStream]: {
        ...(acc[item.item.wasteStream] || {}),
        type: item.item.wasteStream,
        amount: (acc[item.item.wasteStream]?.amount || 0) + (item.totalWeight || 0),
      },
    };
  }, {});

  const handleGoBack = () => {
    history.push(`/bookings/${bookingId}`);
  };

  const prepMap = (dest) => {
    setDestination(dest);
    setRenderMap(true);
  };

  scrollToTop();

  const handleDisplay = async () => {
    segmentTrack("Booking Review Page Opened", {
      bookingId: bookingId,
      businessClient: booking?.businessClient,
    });
    const hasWeight = booking?.dumpWeight || booking?.totalWeight;
    let dumpSiteId = booking?.dumpSiteId;
    if (dumpSiteId === "" || !dumpSiteId) {
      dumpSiteId = booking?.userSelectedDumpSite?.id ?? null;
    }
    const isMapCapable = dumpSiteId && hasWeight;
    if (isMapCapable) {
      await getDumpsite(dumpSiteId, prepMap);
      segmentTrack("Booking Diversion Details Displayed", {
        bookingId: bookingId,
        weight: hasWeight,
        breakdown: wasteStreams ?? {},
        customerId: booking?.customer_id ?? "",
        guestCustomerId: booking?.guest_customer_id ?? "",
        market: booking?.pricingZone ?? "",
        dumpSiteId: dumpSiteId,
        dumpSiteValidation: booking?.isDesignatedDumpSite ? "assigned" : booking?.isDesignatedDumpSite ? "suggested" : "none",
        businessClient: Boolean(booking?.businessClient),
      });
    } else {
      setDestination(null);
    }
  };

  useEffect(() => {
    if (!booking) return;
    handleDisplay();
  }, [booking]);

  useEffect(() => {
    if (!isLoading && booking?.id !== bookingId) {
      dispatch(setActiveBooking(booking, bookingId));
    }
  }, [bookingId, isLoading]);

  const getDropoffTerm = () => {
    const term =
      booking.wasteTreatment && Array.isArray(booking.wasteTreatment)
        ? dumpSiteTreatments[booking.wasteTreatment[0]]?.process ?? dumpSiteTreatments["other"]?.process
        : dumpSiteTreatments["other"]?.process;
    return term;
  };

  const detailItems = () => {
    return [
      {
        icon: <AssignmentTurnedInOutlined />,
        label: "Dropoff Details",
        show: true,
        render: (componentClasses) => (
          <div>
            <Typography className={componentClasses.greenText}>{`Your items were ${getDropoffTerm()} at`}</Typography>
            <div style={{ height: 10 }} />
            <Typography className={componentClasses.dumpSiteName}>{booking?.dump_site ?? "-"}</Typography>
            <Typography>{booking?.dump_address?.location ?? booking?.dump_address ?? "-"}</Typography>
            {booking?.dumpingImages?.length > 0 && (
              <div style={{ marginTop: 10 }}>
                <Link underline="always" href="#" className={classes.linkText} onClick={() => window.open(booking.dumpingImages[0])}>
                  View Dumping Receipt
                </Link>
              </div>
            )}
          </div>
        ),
      },
      {
        icon: <EventNoteIcon />,
        label: "Task completed on",
        show: true,
        render: (componentClasses) => (
          <Typography variant="body1" className={classes.greenText}>
            {dayjs(booking.completion_date).format("dddd, MMMM D, YYYY")} at {dayjs(booking.completion_date).format("hh:mm A")}
          </Typography>
        ),
      },
    ];
  };
  const dropoffItems = () => {
    return [
      {
        icon: null,
        label: "",
        show: renderMap,
        block: true,
        render: (componentClasses) => wasteDivertedCard(),
      },
    ];
  };
  const mapItems = () => {
    return [
      {
        icon: <Map />,
        block: true,
        label: "Route",
        show: renderMap,
        render: (componentClasses) => (
          <Box className={componentClasses.map}>
            <GoogleMaps markers={googleMapsPoints} route={directions} hideOpenButton />
          </Box>
        ),
      },
    ];
  };
  const imageItems = () => {
    return [
      {
        icon: <PictureInPicture />,
        label: "Provider's Before Images",
        show: true,
        block: true,
        render: (componentClasses) =>
          booking?.beforeImages?.length ? (
            <ImageGalleryCustom images={booking?.beforeImages} grid={true} />
          ) : (
            <Typography className={classes.greyedText}>Not available</Typography>
          ),
      },
      {
        icon: <PictureInPicture />,
        label: "Provider's After Images",
        show: true,
        block: true,
        render: (componentClasses) =>
          booking?.afterImages?.length ? (
            <ImageGalleryCustom images={booking?.afterImages} grid={true} />
          ) : (
            <Typography className={classes.greyedText}>Not available</Typography>
          ),
      },
    ];
  };

  const WasteStreamTable = ({ rows }) => {
    const classes = useStyles();
    const getAmount = (amount) => {
      if (booking?.dumpWeight && booking?.totalWeight !== booking?.dumpWeight) {
        const percent = booking?.dumpWeight / booking?.totalWeight;
        const adjustedWeight = Math.round(amount * percent) ?? "";
        return adjustedWeight;
      }
      return amount;
    };
    return (
      <div className={classes.table}>
        {rows.map((row) => (
          <div key={row.type} className={classes.wasteStreamRow}>
            <Typography className={classes.streamType}>{row.type}</Typography>
            <Typography className={classes.streamWeight}>{getAmount(row.amount)} lbs</Typography>
          </div>
        ))}
      </div>
    );
  };

  const wasteDivertedCard = () => (
    <Card className={`${classes.card} ${classes.totalWasteCard}`}>
      <CardContent>
        <div style={{ display: "block" }}>
          <Typography className={classes.wasteDivertedLabel}>{`Total Waste${landfill ? " Buried" : " Diverted"}`}</Typography>
        </div>
        <Typography variant="h4" className={classes.greenText}>
          {bookingWeight} lbs
        </Typography>
      </CardContent>
      {!!Object.values(wasteStreams).length && (
        <CardContent>
          <div style={{ display: "block" }}>
            <Typography className={classes.wasteStreamLabel}>Breakdown by Waste Stream</Typography>
          </div>
          <div style={{ display: "block" }}>{!!Object.values(wasteStreams).length && <WasteStreamTable rows={Object.values(wasteStreams)} />}</div>
        </CardContent>
      )}
    </Card>
  );

  return (
    <Box>
      {booking && (
        <BookingDetailsLayout
          subTitle={`Order ${booking.orderNumber} - Please review the service results submitted by your provider`}
          title={"Review booking"}
          backText={"Details"}
          sections={[detailItems(), dropoffItems(), mapItems(), imageItems()]}
          goBack={handleGoBack}
          card={<WarriorCard page={"review"} />}
        ></BookingDetailsLayout>
      )}
      {booking && (
        <Grid item xs={12} className={classes.cardMobile}>
          <Card raised style={{ width: "100%" }}>
            <CardContent style={{ display: "flex" }}>
              <div style={{ flexGrow: "1" }}>
                <div>
                  <b>Total amount paid</b>
                </div>
                <Typography className={classes.greenText}>
                  <b>${(booking?.total / 100).toFixed(2)}</b>
                </Typography>
              </div>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Box>
  );
}

export default ReviewOrder;
