import React, { useRef, useEffect } from "react";
import { Controller } from "react-hook-form";
import { makeStyles, Grid, Box, Button, TextField, Typography } from "@material-ui/core";
import history from "@history";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "10000",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0, 0, 0, 0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "scroll",
  },
  modal: {
    background: "white",
    overflow: "scroll",
    borderRadius: "10px",
    [theme.breakpoints.down("xs")]: {
      // height: '40vh',
      width: "80%",
    },
    [theme.breakpoints.up("sm")]: {
      // height: '30vh',
      width: "50%",
    },
    [theme.breakpoints.up("md")]: {
      // height: '35vh',
      width: "40%",
    },
    [theme.breakpoints.up("lg")]: {
      // height: '30vh',
      width: "30%",
    },
  },
  container: {
    padding: "25px",
  },
  forgotPassword: {
    width: "250px",
    "&:hover": {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
}));

export const ConfirmUpdateModal = ({ setConfirmModal, setValue, control, handleUpdateDetails }) => {
  const classes = useStyles();
  const wrapperRef = useRef(null);

  const handleClickOutside = (ev) => {
    if (wrapperRef.current && !wrapperRef.current.contains(ev.target)) {
      setConfirmModal(false);
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div className={classes.root}>
      <Box className={classes.modal} ref={wrapperRef}>
        <Box className={classes.container}>
          <Box
            style={{
              marginBottom: "30px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}>
            <Typography
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                marginBottom: "20px",
                textAlign: "center",
              }}>
              Confirm changes
            </Typography>
            <Controller
              name="oldPassword"
              render={({ onChange, value }) => (
                <TextField
                  id="oldPassword"
                  type="password"
                  label="Enter current password"
                  onChange={onChange}
                  value={value}
                  style={{ width: "80%" }}
                  InputProps={{ classes: { underline: classes.underline } }}
                />
              )}
              control={control}
            />
          </Box>

          <Grid container spacing={1} justifyContent="flex-start">
            <Box clone order={{ xs: 1, md: 2 }}>
              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ height: "40px", width: "100%", marginTop: "20px" }}
                  onClick={() => {
                    setConfirmModal(false);
                    handleUpdateDetails();
                  }}>
                  Confirm
                </Button>
              </Grid>
            </Box>
            <Box clone order={{ xs: 2, md: 1 }}>
              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  style={{ height: "40px", width: "100%", marginTop: "20px" }}
                  onClick={() => {
                    setValue("oldPassword", "");
                    setConfirmModal(false);
                  }}>
                  Cancel
                </Button>
              </Grid>
            </Box>
            <Box clone order={{ xs: 3, lg: 3 }}>
              <Grid item style={{ flex: 1 }} align="center">
                <Button
                  onClick={() => {
                    history.push("/auth/reset_pw");
                  }}
                  color="primary"
                  className={classes.forgotPassword}>
                  Forgot Password
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default ConfirmUpdateModal;
