import React from "react";
import Material from "./Material";
import { Typography, makeStyles } from "@material-ui/core";
import { materials } from "../../../../../../constants/dumpsters";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 500,
    padding: theme.spacing(4, 0),
  },
}));

export default (props) => {
  const classes = useStyles();
  const defaultSelected = "Cardboard and wooden pallets";

  return (
    <>
      <Typography className={classes.title}>Choose the main material you would like to dump</Typography>
      {materials.map((material) => (
        <Material key={material.id} material={material} defaultSelected={material.description === defaultSelected} {...props} />
      ))}
    </>
  );
};
