import React from "react";
import { Box, makeStyles, Link as LinkHref } from "@material-ui/core";
import { Link as LinkTo } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  list: {
    margin: 0,
    padding: 0,
    listStyle: "none",
  },
  title: {
    fontWeight: 800,
    marginBottom: theme.spacing(1),
  },
  item: {
    margin: theme.spacing(2, 0),
    fontWeight: 500,
    opacity: 0.4,
    cursor: "pointer",
  },
  link: {
    color: "#fff",
    textDecoration: "none",
  },
}));

const Nav = ({ className, title, items }) => {
  const styles = useStyles();

  const Anchor = ({ href, ...props }) => {
    // some of the links refers to differrent origin (eg. https://jobs.lever.co/)
    try {
      new URL(href);
      return <LinkHref {...props} href={href} />;
    } catch (ex) {
      return <LinkTo {...props} to={href} />;
    }
  };

  return (
    <Box className={className}>
      <ul className={styles.list}>
        <li className={styles.title}>{title}</li>
        {items.map((item, index) => (
          <li key={index} className={styles.item} onClick={item.onClick}>
            <Anchor href={item.href} className={styles.link} target="_blank">
              {item.text}
            </Anchor>
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default Nav;
