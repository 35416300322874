import React from "react";
import { Controller } from "react-hook-form";
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@material-ui/core";
import lookup from "@methods/lookup";

const SelectField = ({ name, control, errors, id, label, options, inputProps, ...rest }) => {
  const error = lookup(errors, name);

  return (
    <FormControl name={name} variant="outlined" error={!!error} {...rest}>
      <InputLabel htmlFor={name} shrink>
        {label}
      </InputLabel>
      <Controller
        name={name}
        as={
          <Select label={label} id={name} inputProps={{ id, ...inputProps }}>
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        }
        control={control}
      />
      <FormHelperText error={!!error}>{error?.message ?? null}</FormHelperText>
    </FormControl>
  );
};

SelectField.defaultProps = {
  options: [],
};

export default SelectField;
