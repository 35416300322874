import { toastr } from "react-redux-toastr";
import { useAxiosPost, useAxiosGet } from "../../axios";
import bookingQuotesTypes from "../constants/bookingQuoteTypes";

export const createAdminBookingQuote = (bookingQuote, notify = false) => async (dispatch) => {
  try {
    const response = await useAxiosPost(`/booking-quotes/create`, { bookingQuote, notify });
    if (response?.data?.success) {
      toastr.success("Done", `Draft booking quote created ${response.data.id}`);

      if (notify && response.data?.customerNotified) {
        toastr.success("Notified", `Customer has been notified by sms sent to ${bookingQuote.phone_number}`);
      }
      if (notify && !response.data?.customerNotified) {
        toastr.warning("Warning", "Unable to sent sms to customer");
      }

      const { success, ...booking } = response.data;
      dispatch({
        type: bookingQuotesTypes.ADD_BOOKING_QUOTE,
        payload: { ...booking },
      });

      return true;
    } else {
      // eslint-disable-next-line no-console
      toastr.error("Error", response?.data?.error);
      return false;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("Error creating admin booking: ", error);
    return false;
  }
};

export const updateAdminBookingQuote = (bookingQuote, notify = false) => async (dispatch) => {
  try {
    const response = await useAxiosPost(`/booking-quotes/update/${bookingQuote.id}`, { bookingQuote, notify });
    if (response?.data?.success) {
      toastr.success("Done", `Booking quote updated ${response.data.id}`);

      if (notify && response.data?.customerNotified) {
        toastr.success("Notified", `Customer has been notified by sms sent to ${bookingQuote.phone_number}`);
      }
      if (notify && !response.data?.customerNotified) {
        toastr.warning("Warning", "Unable to sent sms to customer");
      }

      const { success, ...booking } = response.data;
      dispatch({
        type: bookingQuotesTypes.UPDATE_BOOKING_QUOTE,
        payload: { ...booking },
      });

      return true;
    } else {
      // eslint-disable-next-line no-console
      toastr.error("Error", response?.data?.error);
      return false;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("Error creating admin booking: ", error);
    return false;
  }
};

export const deleteAdminBookingQuote = (id) => async (dispatch) => {
  try {
    const response = await useAxiosPost(`/booking-quotes/delete/${id}`);
    if (response?.data?.success) {
      toastr.success("Done", `Booking quote deleted ${id}`);
      dispatch({
        type: bookingQuotesTypes.DELETE_BOOKING_QUOTE,
        payload: { id },
      });
      return true;
    } else {
      // eslint-disable-next-line no-console
      toastr.error("Error", response?.data?.error);
      return false;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("Error creating admin booking: ", error);
    return false;
  }
};

export const fetchBookingQuotes = () => async (dispatch) => {
  try {
    dispatch({ type: bookingQuotesTypes.SET_BOOKING_QUOTES_LOADING });
    const { data } = await useAxiosGet("/booking-quotes/all");
    dispatch({ type: bookingQuotesTypes.FETCH_BOOKING_QUOTES, payload: data.response });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error fetching booking quotes: ", error);
  } finally {
    dispatch({ type: bookingQuotesTypes.RESET_BOOKING_QUOTES_LOADING });
  }
};

export const fetchBookingQuotesOfCustomer = (customerId) => async (dispatch) => {
  try {
    dispatch({ type: bookingQuotesTypes.SET_BOOKING_QUOTES_LOADING });
    const { data } = await useAxiosGet(`/booking-quotes/all/${customerId}`);
    dispatch({ type: bookingQuotesTypes.FETCH_CUST_BOOKING_QUOTES, payload: data.response });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error fetching booking quotes: ", error);
  } finally {
    dispatch({ type: bookingQuotesTypes.RESET_BOOKING_QUOTES_LOADING });
  }
};
