/* eslint-disable no-console */
import locationConstants from "@store/constants/locationPricingTypes";
import { toastr } from "react-redux-toastr";
import { useDispatch } from "react-redux";
import useFirestoreListener from "@shared/hooks/useFirestoreListener";
import { useAxiosPost, useAxiosGet } from "../../axios";

export const setActivePricing = (activeLocation) => (dispatch) => {
  dispatch({ type: locationConstants.SET_ACTIVE_PRICING, activeLocation });
};

export const resetActivePricing = () => (dispatch) => {
  dispatch({ type: locationConstants.RESET_ACTIVE_PRICING });
};

export const addLocationState = (location) => (dispatch) => {
  dispatch({ type: locationConstants.ADD_PRICING_LOCATION, payload: location });
};

export const updateLocationState = (location) => (dispatch) => {
  dispatch({ type: locationConstants.UPDATE_PRICING_LOCATION, payload: location });
};

export const deleteLocationState = (location) => (dispatch) => {
  dispatch({ type: locationConstants.DELETE_PRICING_LOCATION, payload: location });
};

export const createPricing = (form) => async (dispatch, getState) => {
  try {
    form.createdBy = getState().firebase.profile.uid;
    dispatch({ type: locationConstants.SET_PRICING_LOADING });
    await useAxiosPost("/locations/create", form);
    toastr.success("Created !");
  } catch (error) {
    console.log("Error: ", error);
  } finally {
    dispatch({ type: locationConstants.RESET_PRICING_LOADING });
  }
};

export const updatePricing = (form) => async (_, getState) => {
  try {
    form.updatedBy = getState().firebase?.profile?.email ?? "";
    await useAxiosPost("/locations/update", form);
    toastr.success("Updated !");
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const deletePricing = (id) => async () => {
  try {
    await useAxiosPost("/locations/delete", id);
    toastr.success("Deleted !");
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const loadServiceAreas = () => async (dispatch) => {
  try {
    dispatch({ type: locationConstants.SET_PRICING_LOADING });
    const response = await useAxiosGet(`/locations/service-areas`);
    dispatch({ type: locationConstants.FETCH_PRICING_LOCATIONS, locations: response.data });
  } catch (error) {
    console.log("Error: ", error);
    dispatch({
      type: locationConstants.FETCH_PRICING_ERROR,
      payload: error.response,
    });
  } finally {
    dispatch({ type: locationConstants.RESET_PRICING_LOADING });
  }
};
export const loadServiceAreasClient = () => async (dispatch) => {
  try {
    dispatch({ type: locationConstants.SET_PRICING_LOADING });
    const response = await useAxiosGet(`/locations/service-areas-client`);
    dispatch({ type: locationConstants.FETCH_PRICING_LOCATIONS, locations: response.data });
  } catch (error) {
    console.log("Error: ", error);
    dispatch({
      type: locationConstants.FETCH_PRICING_ERROR,
      payload: error.response,
    });
  } finally {
    dispatch({ type: locationConstants.RESET_PRICING_LOADING });
  }
};

// hooks

export const useSubscribeToLocations = () => {
  const dispatch = useDispatch();
  useFirestoreListener("location_pricing", {
    onAdd: (newDoc) => {
      dispatch(addLocationState(newDoc));
    },
    onUpdate: (updatedDoc) => {
      dispatch(updateLocationState(updatedDoc));
    },
    onDelete: (deletedDoc) => {
      dispatch(deleteLocationState(deletedDoc));
    },
  });
};
