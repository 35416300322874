import React from "react";
import { useSelector } from "react-redux";
import { makeStyles, Divider, useMediaQuery } from "@material-ui/core";

import { applicableTasksforDiscount } from "@constants/pricing";
import { stairsPrice, dismantlingPrice } from "@booking/helpers/apiData";

import Total from "./Total";
import Items from "./Items";
import Item from "./Items/Item";
import BookingDetails from "./BookingDetails";
import CouponDiscount from "./CouponDiscount";
import RentalPickupPrice from "./RentalPickupPrice";
import BulkDiscount from "./BulkDiscount";

import { NextDayDiscount } from "./NextDayDiscount";
import CustomItems from "./Items/CustomItems";
import { QuoteLabel } from "./Items/QuoteLabel";
import { bidCPDiscount } from "../../../../constants/bids";
import { InboundEarlyDiscount } from "./InboundEarlyDiscount";
import PriceMatchModal from "../PriceMatchModal";
import { Task } from "../../helpers/enums";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginBottom: theme.spacing(2),
  },
  quoteLabel: {
    opacity: 0.5,
  },
}));

function PriceQuoteDescription({ summaryProps, draftBooking }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const styles = useStyles();
  const { task, vehicle, junk, date, address, apartment, dumpster, stairs, dismantling, total, pickup, pickupDate, showReducedPrice } = summaryProps;
  const selectedCoupon = useSelector((state) => state.coupon.selectedCoupon);

  const hasCustomItems = junk && junk?.filter((i) => i.item.isCustom)?.length > 0;
  const isLockedPrice = draftBooking?.isLockedPrice;
  const adjustedTotal = showReducedPrice ? total * ((100 - bidCPDiscount) / 100) : total;

  return (
    <div>
      {task !== Task.PowerWashing && (
        <div className="items">
          <Items
            task={task}
            vehicle={vehicle}
            junk={junk?.filter((i) => !i.item.isCustom)}
            dumpster={dumpster}
            draftBooking={draftBooking}
            isLockedPrice={isLockedPrice}
          />
        </div>
      )}

      {showReducedPrice && <Item tag="Quote Total" price={total} />}
      {showReducedPrice && <InboundEarlyDiscount />}

      {applicableTasksforDiscount.includes(task) && !isLockedPrice && (
        <BulkDiscount
          task={task}
          vehicle={vehicle}
          junk={junk}
          dumpster={dumpster}
          isServiceFeeWaived={draftBooking?.isServiceFeeWaived}
          isVehicleFeeWaived={draftBooking?.isVehicleFeeWaived}
        />
      )}

      {task !== Task.DumpsterRental && !isLockedPrice && <NextDayDiscount date={date} />}
      {!!stairs && <Item tag="Stairs" price={stairsPrice.value} quantity={stairs} isLockedPrice={isLockedPrice} />}
      {!!dismantling && <Item tag="Dismantling" price={dismantlingPrice.value} quantity={dismantling} isLockedPrice={isLockedPrice} />}
      {pickup && !isLockedPrice && <RentalPickupPrice dropOffDate={date} pickupDate={pickupDate} />}

      <Divider className={styles.divider} />

      {!isLockedPrice && selectedCoupon && <QuoteLabel label="Coupons" />}
      {!isLockedPrice && selectedCoupon && <CouponDiscount />}
      {!isLockedPrice && selectedCoupon && <Divider className={styles.divider} />}

      {hasCustomItems && <QuoteLabel label="Special Items" />}
      {hasCustomItems && <CustomItems junk={junk?.filter((i) => i.item.isCustom)} isLockedPrice={isLockedPrice} />}
      {hasCustomItems && <Divider className={styles.divider} isLockedPrice={isLockedPrice} />}
      <BookingDetails className="booking-details" date={date} address={address ?? draftBooking?.address} apartment={apartment} />
      <Total
        className="total"
        date={date}
        task={task}
        vehicle={vehicle}
        junk={junk}
        dumpster={dumpster}
        stairs={stairs}
        dismantling={dismantling}
        total={adjustedTotal}
        address={address}
        pickupDate={pickupDate}
        isServiceFeeWaived={draftBooking?.isServiceFeeWaived}
        isVehicleFeeWaived={draftBooking?.isVehicleFeeWaived}
        isLockedPrice={isLockedPrice}
      />
      {isMobile && <PriceMatchModal />}
    </div>
  );
}

export default PriceQuoteDescription;
