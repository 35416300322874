import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@material-ui/core";

import MobileView from "./MobileView";

import DetailsView from "./DetailsView";
import { scrollToTop } from "../../../../methods/scrollToTop";
import BottomBar from "./BottomBar";

type BookingPreviewProps = {
  bookings: Array<any>;
  isQuote: boolean;
  isMultipleQuotes: boolean;
  summary: any;
  warrior: any;
  loading: boolean;
  confirmedQuotes: Array<any>;
  setConfirmedQuotes: Function;
  handleContinue: () => void;
};

function BookingPreview({ bookings, isMultipleQuotes, summary, confirmedQuotes, loading, setConfirmedQuotes, handleContinue }: BookingPreviewProps) {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  const [showDetails, setShowDetails] = useState(false);
  const [displayedQuote, setDisplayedQuote] = useState<string | null>(null);
  const [consolidatedTotal, setConsolidatedTotal] = useState<number>(0);

  const showMobile = isMobile && isMultipleQuotes && !showDetails;
  const showDesktop = isMultipleQuotes ? !isMobile || showDetails : true;
  const disableNext = confirmedQuotes.length === 0;
  const isCurrentQuoteConfirmed = confirmedQuotes.includes(displayedQuote);

  scrollToTop(showDetails);

  const confirmAllQuotes = () => {
    if (bookings.length === confirmedQuotes.length) {
      setConfirmedQuotes([]);
    } else {
      setConfirmedQuotes(bookings.map((booking: any) => booking.quoteNumber));
    }
    setShowDetails(false);
    setDisplayedQuote(null);
  };

  const handleConfirmItem = (quoteNumber: string | null, isRemove: boolean) => {
    if (quoteNumber && !isRemove) {
      setConfirmedQuotes(Array.from(new Set([...confirmedQuotes, quoteNumber])));
    } else {
      setConfirmedQuotes(confirmedQuotes.filter((quote) => quote !== quoteNumber));
    }
    setShowDetails(false);
    setDisplayedQuote(null);
  };

  const calculateConsolidatedTotal = () => {
    setConsolidatedTotal(0);
    bookings.forEach((booking) => {
      setConsolidatedTotal((prevValue) => prevValue + booking.finalTotal);
    });
  };

  useEffect(() => {
    calculateConsolidatedTotal();
  }, [bookings]);

  useEffect(() => {
    const nextQuote = bookings?.find((booking) => !confirmedQuotes.includes(booking.quoteNumber))?.quoteNumber;
    if (nextQuote) {
      setDisplayedQuote(nextQuote);
    }
  }, [confirmedQuotes]);

  return (
    <>
      {showMobile && (
        <MobileView
          bookings={bookings}
          confirmedQuotes={confirmedQuotes}
          confirmAllQuotes={confirmAllQuotes}
          showDetails={(quoteNumber: string) => {
            setShowDetails(true);
            setDisplayedQuote(quoteNumber);
          }}
        />
      )}
      {showDesktop && (
        <DetailsView
          confirmedQuotes={confirmedQuotes}
          isMultipleQuotes={isMultipleQuotes}
          summary={summary}
          bookings={bookings}
          confirmAllQuotes={confirmAllQuotes}
          handleConfirmItem={handleConfirmItem}
          handleContinue={handleContinue}
          displayedQuote={displayedQuote}
        />
      )}
      {isMobile && isMultipleQuotes && (
        <BottomBar
          loading={loading}
          disableNext={disableNext}
          consolidatedTotal={consolidatedTotal}
          showDetails={showDetails}
          setShowDetails={setShowDetails}
          handleContinue={handleContinue}
          isCurrentQuoteConfirmed={isCurrentQuoteConfirmed}
          handleConfirmItem={() => handleConfirmItem(displayedQuote, isCurrentQuoteConfirmed)}
        />
      )}
    </>
  );
}

export default BookingPreview;
