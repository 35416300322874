import React, { useCallback, useState, forwardRef } from "react";
import { Box, Grid } from "@material-ui/core";

import useMountedEffect from "@shared/hooks/useMountedEffect";

import { updateField } from "@booking/helpers/utils";
import { Input } from "@booking/helpers/enums";
import useTriggerValidation from "@shared/hooks/useTriggerValidation";
import NeedHelp from "@shared/components/NeedHelp";

import VehicleField from "@booking/helpers/fields/VehicleField";
import JunkField from "@booking/helpers/fields/JunkField";

const QuoteItems = forwardRef(({ task, defaultValue, onChange }, ref) => {
  const [junkField, setJunkField] = useState(defaultValue[Input.Junk]);
  const [vehicleField, setVehicleField] = useState(defaultValue[Input.Vehicle]);

  useMountedEffect(() => {
    onChange({
      [Input.Vehicle]: vehicleField,
      [Input.Junk]: junkField,
    });
  }, [vehicleField, junkField]);

  useTriggerValidation(ref, [setVehicleField, setJunkField]);

  const handleVehicleChange = useCallback(
    (field) => {
      setVehicleField(updateField(vehicleField, field));
    },
    [vehicleField]
  );

  const handleJunkChange = useCallback(
    (field) => {
      setJunkField(updateField(junkField, field));
    },
    [junkField]
  );

  return (
    <Box>
      <JunkField task={task} field={junkField} onChange={handleJunkChange} isQuote={true} />
      <br />
      <br />
      <VehicleField field={vehicleField} onChange={handleVehicleChange} isQuote={true} />
      <NeedHelp />
    </Box>
  );
});

export default React.memo(QuoteItems);
