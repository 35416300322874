import React from "react";
import { Box, Button } from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import AddToCalendarHOC from "react-add-to-calendar-hoc";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  componentStyles: {
    width: "100%",
    fontSize: "0.8rem",
    padding: theme.spacing(0),
    color: theme.palette.grey[800],
  },
  linkStyles: {
    textDecoration: "none",
    fontWeight: 400,
    fontSize: "1rem",
    padding: "6px",
    "&:hover": {
      background: "#fafafa",
    },
  },
  dropdownStyles: {
    display: "flex",
    flexDirection: "column",
    width: "180px",
    position: "absolute",
    padding: "10px",
    border: "1px solid #E5E5E5",
    borderTop: "none",
    backgroundColor: "#FFF",
  },
}));

const DropDown = ({ children }) => {
  const classes = useStyles();
  return <Box className={classes.dropdownStyles}>{children}</Box>;
};

const ButtonComponent = ({ children, ...rest }) => (
  <Button
    variant="contained"
    style={{ fontSize: "0.9rem", borderRadius: 4, height: 35, marginTop: 10, padding: "5px 20px", background: "#fafafa" }}
    startIcon={<CalendarTodayIcon />}
    {...rest}>
    {children}
  </Button>
);

const AddToCalendarComponent = ({ event }) => {
  const classes = useStyles();

  const AddToCalendarDropdown = AddToCalendarHOC(ButtonComponent, DropDown);

  return (
    <AddToCalendarDropdown
      className={classes.componentStyles}
      linkProps={{
        className: classes.linkStyles,
      }}
      event={event}
    />
  );
};

export default AddToCalendarComponent;
