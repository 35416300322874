import React from "react";
import { makeStyles, TextField } from "@material-ui/core";
import { Controller } from "react-hook-form";

type FormInputProps = {
  name: string;
  label: string;
  control: any;
  type?: string;
  rules?: any;
  multiline?: boolean;
  minRows?: number;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  TextFieldProps?: any;
};

const useStyles = makeStyles(() => ({
  input: {
    margin: "16px 0",
  },
}));

function FormInput({ name, type, label, control, rules, multiline, disabled, minRows = 0, error, helperText, ...TextFieldProps }: FormInputProps) {
  const classes = useStyles();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ onChange, value, name, ref }) => {
        return (
          <TextField
            fullWidth
            name={name}
            label={label}
            type={type || "text"}
            className={classes.input}
            variant="outlined"
            value={value || ""}
            onChange={onChange}
            innerRef={ref}
            multiline={multiline}
            minRows={minRows}
            disabled={disabled}
            error={error}
            helperText={helperText}
            {...TextFieldProps}
          />
        );
      }}
    />
  );
}

export default FormInput;
