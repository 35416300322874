import React from "react";
import classnames from "classnames";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";

import NumberService from "@shared/services/NumberService";
import { Task } from "@booking/helpers/enums";
import { services } from "@booking/helpers/apiData";
import { taskName } from "@booking/helpers/wordings";
import { useSelector } from "react-redux";

import junkRemovalUrl from "@assets/services/waste-removal.jpg";
import cardboardRemovalUrl from "@assets/services/cardboard-removal.jpg";
import dumpsterRentalUrl from "@assets/services/dumpster-rental.jpg";
import { dumpsterSize, dumpster_WP_CP_FormFactor } from "../../../../../constants/dumpsters";
const useStyles = makeStyles((theme) => ({
  button: {
    minHeight: theme.spacing(9),
    paddingTop: theme.spacing(1),
    justifyContent: "left",
    "&.inactive": {
      opacity: 0.5,
    },
  },
  image: {
    marginRight: theme.spacing(2),
  },
}));

const svgUrl = {
  [Task.JunkRemoval]: junkRemovalUrl,
  [Task.CardboardRemoval]: cardboardRemovalUrl,
  [Task.DumpsterRental]: dumpsterRentalUrl,
};

const Option = ({ className, task, selected, onSelect, showPrice }) => {
  const styles = useStyles();
  const buttonClassNames = classnames(styles.button, !selected ? "inactive" : null);
  const currentActive = useSelector((state) => state.serviceLocations?.activeLocation);
  const currentPricingMultiplier = currentActive ? currentActive.allMultipliers?.[task] : 100;
  const isDumpsterTask = task === Task.DumpsterRental;
  const sameDaySurge = isDumpsterTask ? 0 : useSelector((state) => state.ui.sameDaySurge.fetchedValue);
  const dumpsterBasePrices = useSelector((state) => state.tempData.dumpsterBasePrices);

  const priceVal = isDumpsterTask
    ? dumpsterBasePrices[dumpsterSize[5].value] * dumpster_WP_CP_FormFactor
    : services[task].price * (currentPricingMultiplier / 100) + sameDaySurge;

  return (
    <Box className={className}>
      <Button fullWidth variant="outlined" color={selected ? "primary" : "default"} className={buttonClassNames} onClick={onSelect}>
        <img className={styles.image} alt={task} src={svgUrl[task]} />
        <Box flex={1}>
          <Typography align="left">
            <strong>{taskName[task]}</strong>
          </Typography>
        </Box>
        {showPrice && (
          <Box flex={1}>
            <Typography align="right">
              <strong>{NumberService.formatToCurrency(priceVal)}</strong>
            </Typography>
          </Box>
        )}
      </Button>
    </Box>
  );
};

Option.defaultProps = {
  showPrice: true,
};

export default Option;
