import React, { ReactElement } from "react";
import { Box, Button, Typography, useMediaQuery } from "@material-ui/core";
import approvalBg from "@assets/approval-bg.jpg";
import approvalBgSmall from "@assets/approval-bg-small.jpg";
import approvalImage from "@assets/approved.png";
import preApproved from "@assets/raccoon-tree.png";
import rejected from "@assets/rejected.jpg";
import classes from "@css/components/Approval.module.css";
import history from "../../../../history";

type ApprovalProps = {
  approvalState: string;
};

const Content: any = {
  pending: {
    title: "You’re almost ready to start",
    imageUrl: preApproved,
    message:
      "We are reviewing your application details now and will reach out to you within 48 business hours. Contact us at warrior@getdimension.com if you have any questions.",
  },
  approved: {
    title: "Congratulations! Your account has been approved!",
    imageUrl: approvalImage,
    message: "You’re almost ready to start making money. Finish watching these setup videos to claim a job!",
  },
  rejected: {
    title: "Hey there, Your account is pending approval.",
    imageUrl: rejected,
    message: "At this time, we’ve reached full capacity for providers in your area. We will reach out to you when additional jobs become available.",
    hideCelebration: true,
  },
};

function Approval({ approvalState = "pending" }: ApprovalProps): ReactElement {
  const content = Content[approvalState === "info-filled" ? "pending" : approvalState];
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const bgLink = isMobile ? approvalBgSmall : approvalBg;
  const url = !content?.hideCelebration ? bgLink : "";

  const goToVideoPage = () => {
    history.push("/watch_video");
  };

  return (
    <Box>
      <div style={{ backgroundImage: `url(${url})` }} className={classes.background}>
        <div className={classes.content}>
          <Typography className={classes.heading}>{content.title}</Typography>
          <img className={classes.image} src={content.imageUrl} alt="Approval stage image" />
          <Typography className={classes.message} variant="body1">
            {content.message}
          </Typography>
          {approvalState === "approved" && (
            <Button className={classes.videoButton} onClick={goToVideoPage} variant="contained" color="primary">
              Watch Videos
            </Button>
          )}
        </div>
      </div>
    </Box>
  );
}

export default Approval;
