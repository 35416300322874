import { React } from "react";
import account from "../../assets/landing_page/icons/account.svg";
import mail from "../../assets/landing_page/icons/mail.svg";
import bookings from "../../assets/landing_page/icons/bookings.svg";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import BusinessIcon from "@material-ui/icons/Business";
import SettingsIcon from "@material-ui/icons/Settings";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import EventNoteOutlinedIcon from "@material-ui/icons/EventNoteOutlined";
import PowerIcon from "@material-ui/icons/Power";
import { FormatListBulleted, Notifications } from "@material-ui/icons";

export const businessMenuItems = [
  {
    label: "Dimension for Business",
    link: "/b2b-solutions",
  },
  {
    label: "Case Studies",
    link: "/case-studies",
  },
];

export const providersMenuItems = [
  {
    label: "Become a Provider",
    link: "/provider_portal",
  },
  {
    label: "Provider Portal",
    link: "/provider_portal",
  },
  {
    label: "Provider Agreement",
    link: "/provideragreementv2",
  },
  {
    label: "Service Provider Policy",
    link: "/service-provider-policy",
  },
];

export const aboutUsMenuItems = [
  {
    label: "About Dimension",
    link: "/about_us",
  },
  {
    label: "Careers",
    link: "https://jobs.lever.co/getdimension",
    isOutsideLink: true,
  },
  {
    label: "Blog",
    link: "/blog",
  },
  {
    label: "Company News",
    link: "/in-the-news",
  },
];

export const aboutUsMenuItemsCustomer = [
  {
    label: "About Dimension",
    link: "/about_us",
  },
  {
    label: "Blog",
    link: "/blog",
  },
  {
    label: "Company News",
    link: "/in-the-news",
  },
];

export const supportMenuItems = [
  {
    label: "Help Center",
    link: "https://support.getdimension.com/",
    isOutsideLink: true,
  },
  {
    label: "Contact us",
    link: "",
  },
];

export const loggedInCustomerSupportMenuItems = [
  {
    label: "Help Center",
    link: "https://support.getdimension.com/en_us/categories/customer-faq-SkghOgbbi",
    isOutsideLink: true,
  },
  {
    label: "Contact us",
    link: "",
  },
  {
    label: "Terms of Service",
    link: "/terms",
  },
  {
    label: "Privacy Policy",
    link: "/privacy-policy",
  },
];

export const loggedInSupportItems = [
  {
    label: "Help Center",
    link: "https://support.getdimension.com/en_us/categories/provider-faq-HkPjq9kZi",
    isOutsideLink: true,
  },
  {
    label: "Contact us",
    link: "",
  },
  {
    label: "Service Provider Policy",
    link: "/service-provider-policy",
  },
  {
    label: "Provider Agreement",
    link: "/provideragreementv2",
  },
];

export const headerMenu = {
  headerMenu: [
    {
      label: "Business",
      menuItems: businessMenuItems,
      link: null,
    },
    {
      label: "Providers",
      menuItems: providersMenuItems,
      link: null,
    },
    {
      label: "Sustainability",
      menuItems: [],
      link: "/sustainability",
      isButton: true,
    },
    {
      label: "About Us",
      menuItems: aboutUsMenuItems,
      link: null,
    },
    {
      label: "Support",
      menuItems: supportMenuItems,
      link: null,
    },
  ],
  providerHeaderMenu: [
    {
      label: "Provider Portal",
      menuItems: [],
      link: "/provider_portal",
    },
    {
      label: "Support",
      menuItems: loggedInSupportItems,
      link: null,
    },
  ],
  customerHeaderMenu: [
    {
      label: "Business",
      menuItems: businessMenuItems,
      link: null,
    },
    {
      label: "Sustainability",
      menuItems: [],
      link: "/sustainability",
      isButton: true,
    },
    {
      label: "About Us",
      menuItems: aboutUsMenuItemsCustomer,
      link: null,
    },
    {
      label: "Support",
      menuItems: loggedInSupportItems,
      link: null,
    },
  ],
};

export const accountMenuItems = (profile, isMobile) => {
  return {
    providerSideNavLinks: [
      {
        icon: <FormatListBulleted color="primary" />,
        label: "Get Tasks",
        link: "/get_tasks",
        static: false,
        hidden: !isMobile || (!!profile?.approvalState && profile.approvalState !== "complete"),
      },
      {
        icon: <EventNoteOutlinedIcon color="primary" />,
        label: "My Tasks",
        link: "/tasks",
        static: false,
        hidden: (!!profile?.approvalState && profile.approvalState !== "complete") || profile?.isBanned,
      },
      {
        icon: <FormatListBulleted color="primary" />,
        label: "Bid On Tasks",
        link: "/get_bids",
        static: false,
        hidden: !isMobile || (!!profile?.approvalState && profile.approvalState !== "complete"),
      },
      {
        icon: <EventNoteOutlinedIcon color="primary" />,
        label: "My Bids",
        link: "/bids",
        static: false,
        hidden: (!!profile?.approvalState && profile.approvalState !== "complete") || profile?.isBanned,
      },
      {
        icon: <AccountCircleIcon color="primary" />,
        label: "Account",
        link: "/account",
        static: false,
      },
      {
        icon: <Notifications color="primary" />,
        label: "Alert Settings",
        link: "/alerts",
        static: false,
        hidden: profile?.isBanned,
      },
      {
        icon: <LocalShippingOutlinedIcon color="primary" />,
        label: "Vehicle Info",
        link: "/vehicle_info",
        static: false,
        hidden: profile?.isBanned,
      },
    ],
    customerSideNavLinks: [
      {
        icon: <BusinessIcon color="primary" />,
        label: "Edit Company",
        link: "/company",
        static: false,
        isBusiness: true,
        hidden: true,
      },
      {
        icon: <EventNoteOutlinedIcon color="primary" />,
        label: "My Bookings",
        link: "/bookings",
        static: false,
      },
      {
        icon: <AccountCircleIcon color="primary" />,
        label: "Account",
        link: "/account",
        static: false,
      },
      {
        icon: <SettingsIcon color="primary" />,
        label: "SMS and Email settings",
        link: "/alerts_sms_email",
        static: false,
      },
      {
        icon: <PowerIcon color="primary" />,
        label: "Integrations",
        link: "/integrations",
        static: false,
        isIntegrations: true,
        hidden: !profile?.showIntegrations,
      },
      // {
      //   icon: <PermPhoneMsgOutlinedIcon color="primary" />,
      //   label: "Contact us",
      //   link: "/help",
      //   static: false,
      // },
    ],
  };
};

export const mobileAccountItems = [
  {
    label: "Account",
    link: "/account",
    icon: account,
  },
  {
    label: "My Bookings",
    link: "/bookings",
    icon: bookings,
  },
  {
    label: "SMS and Email Settings",
    link: "/alerts_sms_email",
    icon: mail,
  },
];
