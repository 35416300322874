/* eslint-disable */
import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

import history from "@history";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import Review from "./Review";
import Sample from "./Sample";
import { scrollToTop } from "../../../../methods/scrollToTop";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2),
    width: "100%",
    minHeight: "100vh",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 8),
    },
  },
  myReviews: {
    display: "flex",
    alignItems: "center",
    margin: "30px 0px",

    "& .text": {
      fontSize: "25px",
      fontWeight: "bold",
      margin: 0,
    },

    "& .backBtn": {
      marginRight: theme.spacing(1),
      color: theme.palette.grey[300],
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  },

  reviews: {
    [theme.breakpoints.up("md")]: {
      width: "80%",
      marginLeft: theme.spacing(15),
    },
  },
}));

const Reviews = () => {
  const classes = useStyles();
  const reviews = useSelector((state) => state.review.reviews);
  scrollToTop();

  return (
    <Box>
      <div className={classes.root}>
        {reviews.length === 0 ? (
          <Typography color="primary">No reviews yet!</Typography>
        ) : (
          <>
            <div className={classes.myReviews}>
              <ArrowBackIosIcon className="backBtn" onClick={() => history.goBack()} />
              <Typography className="text">My Reviews</Typography>
            </div>
            <div className={classes.reviews}>
              {reviews.map((review, idx) => (
                <Review key={idx} {...review} />
              ))}
            </div>
          </>
        )}
      </div>
    </Box>
  );
};

Reviews.Sample = Sample;

export default Reviews;
