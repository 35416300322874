import React, { useState } from "react";
import classes from "@css/components/LoginModal.module.css";
import { Button, Modal, Typography } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import tempDataConstants from "../store/constants/tempDataTypes";
import { useDispatch } from "react-redux";
import { roles } from "../constants/roles";
import { segmentTrack } from "../helpers/segment";
import GoogleBtn from "../shared/components/GoogleBtn";
import { LineDivider } from "../shared/components/PaymentOptions/LineDivider";
import SignIn from "../views/Auth/SignIn";
import history from "../history";

const ROLES: any = roles;

const ModalModeText: Record<string, any> = {
  signin: { mainText: "Log in", toggleText: "Dont have an account yet?", toggleLink: "Register here" },
  register: { mainText: "Register", toggleText: "Already have an account?", toggleLink: "Sign in" },
};

function LoginModal({ open, closeModal }: { open: boolean; closeModal: any }) {
  const dispatch = useDispatch();
  const [modalMode, setModalMode] = useState<any>("signin");

  const getAuthLink = (mode: string) => {
    if (mode === "signin") {
      return `/auth/signin`;
    } else if (mode === "register") {
      return `/auth/register`;
    }
    return "";
  };

  const handleButtonClick = (userType: string) => {
    dispatch({ type: tempDataConstants.SET_TEMP_USER, userType: ROLES[userType].value });
    history.push(getAuthLink(modalMode));
    closeModal();
    segmentTrack("User Clicks Register - Home", { userType: userType });
  };

  const toggleModalMode = () =>
    setModalMode((prevMode: string) => {
      if (prevMode === "signin") {
        return "register";
      }
      if (prevMode === "register") {
        return "signin";
      }
      return "signin";
    });

  const handleOnClose = () => {
    setModalMode("signin");
    closeModal();
  };

  return (
    <Modal open={open} onClose={handleOnClose} aria-labelledby="login modal" aria-describedby="modal to login" style={{ zIndex: 20000 }}>
      <div
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxHeight: "100vh",
          overflowY: "auto",
        }}
        className={classes.modal}
      >
        <div className={classes.modalContainer}>
          <Typography className={classes.modalHeading} variant="h4" component="h4">
            {ModalModeText[modalMode].mainText}
          </Typography>
          {modalMode === "signin" && (
            <div>
              <div className={classes.googleBtnContainer} onClick={handleOnClose}>
                <GoogleBtn className={classes.googleBtn} signUp={modalMode === "register"}>
                  {ModalModeText[modalMode].mainText} with Google
                </GoogleBtn>
              </div>
              <LineDivider text={`Or ${ModalModeText[modalMode].mainText} with email and password`} />
              <SignIn toggleMode={toggleModalMode} onClose={handleOnClose} />
              <div className={classes.closeButton}>
                <CloseIcon onClick={handleOnClose} />
              </div>
            </div>
          )}
          {modalMode !== "signin" && (
            <div>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                onClick={() => handleButtonClick("customer")}
                className={classes.registerTypeButton}
              >
                {ModalModeText[modalMode].mainText} as a Customer
              </Button>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                onClick={() => handleButtonClick("business")}
                className={classes.registerTypeButton}
              >
                {ModalModeText[modalMode].mainText} as a Business Customer
              </Button>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => handleButtonClick("provider")}
                className={classes.registerTypeButton}
              >
                {ModalModeText[modalMode].mainText} as a Provider
              </Button>
              <Typography className={classes.modalFooter}>
                <br /> <a onClick={() => toggleModalMode()}>Back to login</a>
              </Typography>
              <div className={classes.closeButton}>
                <CloseIcon onClick={handleOnClose} />
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default LoginModal;
