/* eslint-disable no-console */
import { useAxiosGet, useAxiosPost } from "../../axios";
import authConstants from "@store/constants/authTypes";

export const fetchAvailableBonuses = async (userType, market) => {
  try {
    const res = await useAxiosPost(`/bonuses/fetch-available-bonuses`, { userType, market });
    const { success, response, error } = res?.data;
    if (!success) {
      console.error(error);
      return null;
    }
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const fetchAllBonuses = async () => {
  try {
    const res = await useAxiosGet(`/bonuses/fetch-bonuses`);
    const { success, response, error } = res.data;
    if (!success) {
      console.error(error);
      return null;
    }
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const fetchMyBonusPayouts = (uid) => async (dispatch) => {
  try {
    const res = await useAxiosGet(`bonuses/fetch-user-bonuses/${uid}`);
    const { success, response, error } = res.data;
    if (!success) {
      console.error(error);
      return null;
    }
    dispatch({
      type: authConstants.SET_BONUS_PAYOUTS,
      payload: response,
    });
  } catch (error) {
    console.error(error);
    return null;
  }
};
