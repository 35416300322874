import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { roles } from "../../constants/roles";

const AuthRedirector = () => {
  const profile = useSelector((state) => state.firebase.profile);
  const redirectedInSession = window.sessionStorage.getItem("initial-redirect");

  if (profile?.isBanned) return <Redirect to="/account" />;

  if (redirectedInSession === "true") {
    return null;
  }

  if (!profile?.isEmpty) {
    window.sessionStorage.setItem("initial-redirect", "true");
    return <Redirect to={roles[profile.userType].homepage} />;
  }

  return null;
};

export default AuthRedirector;
