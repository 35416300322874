import React, { useCallback, useState, forwardRef, useEffect } from "react";
import { Box } from "@material-ui/core";

import useMountedEffect from "@shared/hooks/useMountedEffect";

import { updateField } from "@booking/helpers/utils";
import { Input } from "@booking/helpers/enums";
import useTriggerValidation from "@shared/hooks/useTriggerValidation";
import NeedHelp from "@shared/components/NeedHelp";

import Wrapper from "@booking/components/Wrapper";
import Section from "@booking/components/Section";
import TransferField from "@booking/helpers/fields/TransferField";
import DateTimeField from "@booking/helpers/fields/DateTimeField";
import FrequencyField from "@booking/helpers/fields/FrequencyField";
import { defaultBidBuffer } from "../../constants";
import dayjs from "dayjs";
import validators from "../../validators";

const DateAndTimeForm = forwardRef(({ defaultValue, onChange, timeZone, isDefaultBid }, ref) => {
  const [dateField, setDateField] = useState(defaultValue[Input.Date]);
  const [timeField, setTimeField] = useState(defaultValue[Input.Time]);
  const [frequencyField, setFrequencyField] = useState(defaultValue[Input.Frequency]);

  useMountedEffect(() => {
    onChange({
      [Input.Frequency]: frequencyField,
      [Input.Date]: dateField,
      [Input.Time]: timeField,
    });
  }, [dateField, timeField, frequencyField]);

  useTriggerValidation(ref, [setDateField, setTimeField, setFrequencyField], setFrequencyField);

  const handleDateChange = useCallback(
    (field) => {
      setDateField(updateField(dateField, field));
    },
    [dateField]
  );

  const handleTimeChange = useCallback(
    (field) => {
      setTimeField(updateField(timeField, field));
    },
    [timeField]
  );

  const handleFrequencyChange = useCallback(
    (value) => {
      setFrequencyField(updateField(frequencyField, value));
    },
    [frequencyField]
  );

  const title = "Pickup";

  return (
    <Wrapper>
      <Section title={title}>
        {!isDefaultBid && <TransferField timeZone={timeZone} />}
        <DateTimeField
          dateField={dateField}
          timeField={timeField}
          onDateChange={handleDateChange}
          onTimeChange={handleTimeChange}
          isRemoval
          overRideTimeRange={isDefaultBid ? defaultBidBuffer : undefined}
        />
        <div style={{ height: 24 }} />
        <div style={{ display: isDefaultBid ? "none" : undefined }}>
          <FrequencyField field={frequencyField} onChange={handleFrequencyChange} />
        </div>
        <NeedHelp />
      </Section>
    </Wrapper>
  );
});

export default React.memo(DateAndTimeForm);
