/* eslint-disable no-console */
import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, Grid, Box, Button, Card, LinearProgress } from "@material-ui/core";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { storage } from "@config/firebaseConfig";
import { dataURItoBlob } from "@helpers/storage";
import { updateProfile } from "@store/actions/users";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "10000",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0, 0, 0, 0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "scroll",
  },
  modal: {
    background: "white",
    overflow: "scroll",
    borderRadius: "10px",
    [theme.breakpoints.down("xs")]: {
      height: "80vh",
      width: "80%",
    },
    [theme.breakpoints.up("sm")]: {
      height: "50vh",
      width: "80%",
    },
    [theme.breakpoints.up("lg")]: {
      height: "50vh",
      width: "60%",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
    },
    height: "100%",
  },
  cropperArea: {
    [theme.breakpoints.down("xs")]: {
      height: "30vh",
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      height: "400px",
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      height: "400px",
      width: "100%",
    },
  },
  previewArea: {
    [theme.breakpoints.down("xs")]: {
      height: "30vh",
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      height: "50vh",
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      height: "60vh",
      width: "100%",
    },
  },
  avatar_wrapper: {
    height: "170px",
    width: "170px",
    borderRadius: "50%",
    boxShadow: "0px 0px 68px -6px rgba(0,0,0,0.36)",
    margin: "0 auto",
    border: "5px solid white",
  },
}));

export const UploadModal = ({ file, setShowProfileModal, profile }) => {
  const classes = useStyles();
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();

  const [cropper, setCropper] = useState(null);
  const [progress, setProgress] = useState(false);

  const handleImageUpload = () => {
    const dataURL = cropper.getCroppedCanvas().toDataURL();
    const fileBinary = dataURItoBlob(dataURL);

    let file = new File([fileBinary], "my_avatar", { type: "image/png" });

    const upload = storage.ref(`profileImages/${profile.uid}/${file.name}`).put(file);
    upload.on(
      "state_changed",
      (snapshot) => {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (err) => {
        console.log(err);
      },
      () => {
        storage
          .ref("profileImages")
          .child(profile.uid)
          .child(file.name)
          .getDownloadURL()
          .then((url) => {
            const newProfile = { photoURL: url };
            dispatch(updateProfile(newProfile));
            setShowProfileModal(false);
          });
      }
    );
  };

  const handleClickOutside = (ev) => {
    if (wrapperRef.current && !wrapperRef.current.contains(ev.target)) {
      setShowProfileModal(false);
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div className={classes.root}>
      <Box className={classes.modal} ref={wrapperRef}>
        <Grid container className={classes.container} spacing={3}>
          <Grid item xs={12} sm={8}>
            {file && (
              <Cropper
                initialAspectRatio={1}
                preview=".img-preview"
                src={file}
                viewMode={1}
                guides={true}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                aspectRatio={1 / 1}
                className={classes.cropperArea}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box style={{ height: "70%", justifyItems: "center" }}>
              <h1 style={{ textAlign: "center" }}>Preview</h1>
              <Card className={classes.avatar_wrapper}>
                <div
                  className="img-preview"
                  style={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    borderRadius: "50%",
                  }}
                />
              </Card>
            </Box>
            <Box style={{ float: "right" }}>
              <Button variant="contained" color="primary" style={{ height: "40px" }} onClick={handleImageUpload}>
                Upload
              </Button>
            </Box>
          </Grid>
        </Grid>
        {!!progress && <LinearProgress variant="determinate" value={progress} style={{ borderRadius: "5px", height: "10px", margin: "15px 0px" }} />}
      </Box>
    </div>
  );
};

export default UploadModal;
