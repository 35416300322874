import React from "react";
import { makeStyles, Grid } from "@material-ui/core";

import Hidden from "@shared/components/Hidden";

import BackButton from "./BackButton";
import ContinueButton from "./ContinueButton";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    display: "flex",
  },
  continueBtn: {
    float: "right",
  },
}));

const Buttons = ({ onContinue, onBack, disableContinueButton, renderBackButton, continueButtonLabel, loading }) => {
  const styles = useStyles();

  return (
    <Grid container spacing={2} className={styles.root}>
      <Grid item xs={6}>
        {renderBackButton && (
          <Hidden desktop>
            <BackButton fullWidth onClick={onBack} />
          </Hidden>
        )}
      </Grid>
      <Grid item xs={6}>
        <ContinueButton
          className={styles.continueBtn}
          label={continueButtonLabel}
          disabled={disableContinueButton}
          onClick={onContinue}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};

Buttons.defaultProps = {
  renderBackButton: false,
  continueButtonLabel: "Continue",
  loading: false,
};

export default Buttons;
