import * as yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";

export const loginFormDefaults = {
  email: "",
  password: "",
};

export const loginFormSchema = yup.object().shape({
  email: yup.string().email().required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{6,}$/, "Must be 6 or more characters and contain at least 1 number and 1 letter."),
});

export const registerFormDefaults = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  password: "",
  confirmPassword: "",
  companyName: "",
};

export const businessFieldsSchema = yup.object().shape({
  companyName: yup.string().required("Company name is required"),
});

export const registerFormSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().optional(),
  email: yup.string().email().required("Email is required"),
  phone: yup
    .string()
    .required("Phone Numer is required")
    .test("phone-validation", "Invalid format!", (value) => isValidPhoneNumber(value)),
  password: yup
    .string()
    .required("Password is required")
    .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{6,}$/, "Must be 6 or more characters and contain at least 1 number and 1 letter."),
  confirmPassword: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
});

export const businessInfoFormDefaults = {
  serviceAddress: "",
  needs: [],
  closedBy: "",
};

export const businessInfoFormSchema = yup.object().shape({
  serviceAddress: yup.string().required("Service Address is required"),
  closedBy: yup.string().optional(),
  // salesContactEmail: yup.string().email().optional(),
  needs: yup.array().min(1, "Needs are required"),
});

export const businessUserFormSchema = yup.object().shape({
  companyName: yup.string(),
  closedBy: yup.string().optional(),
});

export const businessUserFormDefaults = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  password: "",
  confirmPassword: "",
  companyName: "",
  closedBy: "",
};
