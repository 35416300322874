class FieldError extends Error {
  code;

  constructor(code) {
    super(undefined);
    this.code = code;
  }
}

export default FieldError;
