import React from "react";
import { Button } from "@material-ui/core";
import classes from "@css/components/website/PitchLeft.module.css";
import classNames from "classnames";
import { useHistory } from "react-router";
import TrustBox from "../../../shared/components/TrustPilot";
import { KustomerLauncher } from "../../../containers/KustomerLauncher";

const HeroText = () => (
  <h3 className={classes.heroText}>
    Sustainable
    <br /> Solutions for
    <br /> a Brighter Future
  </h3>
);

const SubText = () => {
  const history = useHistory();
  return (
    <>
      <p className={classes.subText}>
        Dimension empowers you with the tools and tech you need to ensure that your waste management is as good for the environment as it is for your
        business.
      </p>
      <div className={classes.buttonContainer}>
        <Button disableRipple className={classes.primaryButton} variant="contained" onClick={() => history.push("booking?step=initial")}>
          Book Now
        </Button>
        <Button disableRipple className={classes.secondaryButtonDark} variant="contained">
          <KustomerLauncher>Chat With Us</KustomerLauncher>
        </Button>
      </div>
    </>
  );
};

function Pitch() {
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classNames(classes.leftPane, classes.highLeft)}>
          <div className={classes.pitchContent}>
            <HeroText />
            <SubText />
            <TrustBox style="landing-widget" />
          </div>
        </div>
        <div className={classNames(classes.rightPane, classes.sustainability)} />
      </div>
    </div>
  );
}
export default Pitch;
