import React, { Fragment, useState } from "react";

import { makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import Materials from "./Materials";

import DumpsterViewCard from "./DumpsterViewCard";
import { useSelector } from "react-redux";
import { getDynamicMatchingItem } from "../../../../../../helpers/dumpsters.ts";
import { everydayPickupPrice } from "../../../../../../constants/dumpsters";

const useStyles = makeStyles((theme) => ({
  boldText: { fontWeight: 500 },
  backdrop: {
    [theme.breakpoints.up("md")]: {
      height: 370,
    },
  },
  materials: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      padding: theme.spacing(6),
      background: "#FFF",
      inset: 0,
      zIndex: 99,
      height: "auto",
    },
  },
  continueButton: {
    [theme.breakpoints.up("md")]: {
      width: 240,
      float: "right",
      marginTop: theme.spacing(8),
    },
    width: "100%",
    marginTop: theme.spacing(4),
  },
}));

function ProductList({ days, products, onRemove, onChange }) {
  const classes = useStyles();
  const [updatedItems, setUpdateditems] = useState([]);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const dumpsterSheetList = useSelector((state) => state.tempData.dumpsterMarketPricing) ?? [];

  const handleSelectItem = (newItem) => {
    const updatedList = new Set(updatedItems);
    if (updatedList.has(newItem)) {
      updatedList.delete(newItem);
    } else {
      updatedList.add(newItem);
    }
    setUpdateditems([...updatedList]);
  };

  const removeItem = (itemId) => () => {
    setUpdateditems([]);
    onRemove(itemId);
  };

  const addItems = (item) => {
    const payload = {
      ...item,
      items: updatedItems,
    };
    const matchingItemInSheetList = getDynamicMatchingItem(payload, dumpsterSheetList);
    if (matchingItemInSheetList) {
      payload.isLockedPriceItem = true;
      payload.prePrice = payload.price;
      payload.price = matchingItemInSheetList.customerPrice + days * everydayPickupPrice;
      payload.warriorPrice = matchingItemInSheetList.warriorPrice;
      payload.customerPrice = matchingItemInSheetList.customerPrice;
    }
    onChange(payload);
    setUpdateditems([]);
  };

  return products.map((product) => {
    const { item } = product;
    const isNew = !item.items;

    return (
      <Fragment key={item.uid}>
        {!isNew && <DumpsterViewCard item={item} materials={item.items} handleRemoveProduct={removeItem(item.uid)} />}
        {isNew && (
          <div className={classes.backdrop}>
            <div className={classes.materials}>
              <Typography className={classes.boldText}>Dumpster Size</Typography>
              <DumpsterViewCard item={item} materials={item.items} hideMaterials={isNew} handleRemoveProduct={removeItem(item.uid)} />
              <Materials onSelect={handleSelectItem} />
              <Button
                color="primary"
                variant="contained"
                className={classes.continueButton}
                disabled={!updatedItems.length}
                onClick={() => addItems(item)}
              >
                {isDesktop ? "Continue" : "Add"}
              </Button>
            </div>
          </div>
        )}
      </Fragment>
    );
  });
}

export default ProductList;
