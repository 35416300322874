import StringService from "./StringService";

class NumberService {
  static thousandSeparator = ",";

  static decimalSeparator = ".";

  static currencyPrefix = "$";

  static currencyDecimalPlaces = 2;

  static limitDecimals = (givenEntry) => {
    const factor = 10 ** NumberService.currencyDecimalPlaces;
    return Math.round(givenEntry * factor) / factor;
  };

  /**
   * Usage examples:
   *  formatThousands(100) -> 100
   *  formatThousands(1000) -> 1,000
   *  formatThousands(100000) -> 100,000
   *  formatThousands(1000000) -> 1,000,000
   */
  static formatThousands = (givenEntry) =>
    StringService.reverseReplace(givenEntry.toString(), /(\d{3})/g, `$1${NumberService.thousandSeparator}`).replace(
      new RegExp(`^${NumberService.thousandSeparator}`),
      ""
    );

  /**
   * Usage examples:
   *  formatToCurrency(100) -> $1,00
   *  formatToCurrency(1000) -> $10,00
   *  formatToCurrency(10000) -> $100,00
   *  formatToCurrency(100000) -> $1000,00
   */
  static formatToCurrency = (givenEntry = 0, fillDecimalPlaces = true) => {
    const givenEntryInteger = parseInt(givenEntry.toString(), 10) / 100;
    const splittedEntry = givenEntryInteger.toString().split(".");

    const givenEntryDecimals = (splittedEntry[1] || 0)
      .toString()
      .concat(fillDecimalPlaces ? "0".repeat(NumberService.currencyDecimalPlaces) : "")
      .substr(0, NumberService.currencyDecimalPlaces);

    return NumberService.currencyPrefix
      .concat(NumberService.formatThousands(splittedEntry[0]))
      .concat(givenEntryDecimals && fillDecimalPlaces ? NumberService.decimalSeparator.concat(givenEntryDecimals) : "");
  };
}

export default NumberService;
