import React from "react";
import { Button, Typography } from "@material-ui/core";
import classes from "@css/components/website/SustainabilityData.module.css";
import sustainabilityData from "../../../assets/landing_page/sustainability/sustainabilityData.jpg";
import feature1 from "../../../assets/landing_page/sustainability/feature-1.svg";
import feature2 from "../../../assets/landing_page/sustainability/feature-2.svg";
import feature3 from "../../../assets/landing_page/sustainability/feature-3.svg";
import { useHistory } from "react-router";

function SustainabilityData() {
  const history = useHistory();
  return (
    <section className={classes.container}>
      <div className={classes.mainContent}>
        <img className={classes.image} src={sustainabilityData} />
        <div className={classes.featureContainer}>
          <Typography className={classes.header}>Revolutionary Diversion Data</Typography>
          <div className={classes.feature}>
            <img src={feature1} alt="" />
            <div className={classes.featureContent}>
              <Typography className={classes.featureHeader}>Total Transparency</Typography>
              <Typography className={classes.featureDescription}>
                Dimension offers crystal clear visibility on every pickup, making it simple for you to check where your waste ended up.
              </Typography>
            </div>
          </div>
          <div className={classes.feature}>
            <img src={feature2} alt="" />
            <div className={classes.featureContent}>
              <Typography className={classes.featureHeader}>Precise Breakdowns</Typography>
              <Typography className={classes.featureDescription}>
                Easily track the total weight and composition of every pickup with our detailed waste stream stats.
              </Typography>
            </div>
          </div>
          <div className={classes.feature}>
            <img src={feature3} alt="" />
            <div className={classes.featureContent}>
              <Typography className={classes.featureHeader}>Consolidated Data</Typography>
              <Typography className={classes.featureDescription}>Access data about all of your pickups in one clean, intuitive interface.</Typography>
            </div>
          </div>
          <Button disableRipple className={classes.primaryButton} onClick={() => history.push("booking?step=initial")}>
            Book Now
          </Button>
        </div>
      </div>
    </section>
  );
}

export default SustainabilityData;
