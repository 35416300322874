import React, { useRef, useEffect } from "react";
import trustPilotLogo from "@assets/trustpilot.svg";
import trustPilotLogoDark from "@assets/trustpilot-dark.svg";
import { useMediaQuery } from "@material-ui/core";

export interface TrustBoxProps {
  style?: string;
  backgroundColor?: string;
  isDark?: boolean;
  styles?: any;
}

export const desktopTrustPilotLandingStyles = {
  display: "block",
  transform: "scale(0.7)",
  opacity: 1,
  marginRight: "auto",
  marginLeft: -58,
  marginTop: 20,
};

/* The mobile styles only applies to the pages with Booking Widget */
export const mobileTrustPilotLandingStyles = {
  transform: "scale(0.7)",
  transformOrigin: "center",
  opacity: 1,
};

function TrustBox({ style, backgroundColor = "#FFFFFF", isDark = true, styles = null }: TrustBoxProps) {
  const ref = useRef(null);
  const isMobile = useMediaQuery("(max-width:768px)");

  useEffect(() => {
    const wind: any = window;
    if (wind.Trustpilot) {
      wind.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  if (style === "landing-widget") {
    return (
      // <!-- TrustBox widget - Landing Page Widget -->
      <div style={isMobile ? { position: "relative", display: "block", marginLeft: "auto", marginRight: "auto" } : desktopTrustPilotLandingStyles}>
        <div style={styles ?? isMobile ? mobileTrustPilotLandingStyles : undefined}>
          <div
            ref={ref}
            className="trustpilot-widget"
            data-locale="en-US"
            data-template-id="53aa8807dec7e10d38f59f32"
            data-businessunit-id="61de4d6cf97b0e1385a9337c"
            data-style-height="150px"
            data-style-width="100%"
            data-theme="dark"
          >
            <a href="https://www.trustpilot.com/review/trashwarrior.com" target="_blank" rel="noopener noreferrer">
              Trustpilot
            </a>
          </div>
        </div>
      </div>
      // <!-- End TrustBox widget -->
    );
  }

  if (style === "micro-button") {
    return (
      // <!-- TrustBox widget - Micro Button -->
      <div style={{ transform: "scale(1.3)", ...(styles ? styles : {}) }}>
        <div
          ref={ref}
          className="trustpilot-widget"
          data-locale="en-US"
          data-template-id="5419b757fa0340045cd0c938"
          data-businessunit-id="61de4d6cf97b0e1385a9337c"
          data-style-height="25px"
          data-style-width="100%"
        >
          <a href="https://www.trustpilot.com/review/trashwarrior.com" target="_blank" rel="noopener noreferrer">
            Trustpilot
          </a>
        </div>
      </div>
      // <!-- End TrustBox widget -->
    );
  }

  return (
    <div
      ref={ref}
      style={{
        backgroundColor,
        padding: "24px",
      }}
      data-locale="en-US"
      data-template-id="5419b6ffb0d04a076446a9af"
      data-businessunit-id="61de4d6cf97b0e1385a9337c"
      data-style-height="20px"
      data-style-width="100%"
      data-theme="light"
    >
      <a href="https://www.trustpilot.com/review/trashwarrior.com" target="_blank" rel="noreferrer">
        <img height="30" src={isDark ? trustPilotLogoDark : trustPilotLogo} alt="Trustpilot widget" />
      </a>
    </div>
  );
}

export default TrustBox;
