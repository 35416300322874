import React from "react";
import classnames from "classnames";
import { Box, makeStyles, Typography } from "@material-ui/core";

import NumberService from "@shared/services/NumberService";

const size = ({ small }) =>
  small
    ? {
        itemTagFontSize: 13,
        itemPriceFontSizeMdUp: 13,
        itemPriceFontSizeMdDown: 13,
      }
    : {
        itemPriceFontSizeMdUp: 16,
        itemPriceFontSizeMdDown: 14,
      };

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    "& .item-tag": {
      maxWidth: (p) => (p.isLockedPrice ? theme.spacing(48) : theme.spacing(24)),
      fontSize: (p) => size(p).itemTagFontSize,
    },
    "& .item-price": {
      fontWeight: 800,
      fontSize: (p) => size(p).itemPriceFontSizeMdDown,
      [theme.breakpoints.up("md")]: {
        fontSize: (p) => size(p).itemPriceFontSizeMdUp,
      },
      "& .item-percentage": {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: "bold",
      },
    },
  },
  itemTag: {
    flex: 1,
  },
}));

const Item = ({
  className = null,
  color = "initial",
  tag,
  quantity = undefined,
  price,
  priceMultiplier,
  percentage,
  showPercentage,
  adjustment = 0,
  small,
  isLockedPrice = false,
}) => {
  const styles = useStyles({ small, isLockedPrice });

  return (
    <Box className={classnames(styles.item, className)}>
      <Box className={styles.itemTag}>
        <Typography className="item-tag" color={color}>
          {tag}
          {typeof quantity !== "undefined" ? <React.Fragment> (x{quantity})</React.Fragment> : null}
        </Typography>
      </Box>
      {(!isLockedPrice || tag === "Total") && (
        <Typography className="item-price" color={color}>
          {NumberService.formatToCurrency(
            typeof quantity !== "undefined" ? quantity * price * priceMultiplier + (adjustment || 0) : price * priceMultiplier + (adjustment || 0)
          )}
          {showPercentage && percentage > 0 && <span className="item-percentage"> +{percentage}%</span>}
        </Typography>
      )}
    </Box>
  );
};

Item.defaultProps = {
  priceMultiplier: 1,
  showPercentage: false,
  percentage: 0,
  small: false,
};

export default Item;
