import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 3),
  },
  description: {
    background: "#fafafa",
    borderRadius: 4,
    padding: theme.spacing(4, 2),
    textAlign: "center",
    fontWeight: 600,
  },
}));

export const ACHForm = ({ type }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {/* <div className={classes.title}>Pay via {type}</div> */}
      <Typography className={classes.description}>Ok! We will send an invoice with your booking confirmation.</Typography>
    </div>
  );
};
