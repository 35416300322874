import React, { Component, useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import logoUrl from "@assets/landing_page/dimension-logo-dark.svg";
import querystring from "query-string";
import { Box, DialogContent, makeStyles } from "@material-ui/core";
import { createBookingBid } from "../../../store/actions/bookingBids";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Loader from "../../../shared/components/Loader";
import Spacer from "../../../shared/components/Spacer";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  dialogContainer: {
    backgroundColor: "rgb(227,227,230, 0.3)",
    zIndex: "14000 !important",
  },
  companyName: {
    color: "black",
    fontSize: "18px",
    fontWeight: 600,
  },
  dialogTitle: {
    paddingTop: "60px",
    justifyContent: "space-around",
    display: "flex",
    textAlign: "center",
    backgroundColor: "#e8e9eb",
  },
  captionText: {
    fontSize: "0.90rem",
  },
  logo: {
    width: "80px",
    alignSelf: "center",
    margin: "auto",
  },
  logoContainer: {
    display: "flex",
    borderColor: "black",
    borderStyle: "solid",
    borderWidth: "1px",
    width: "100px",
    alignSelf: "center",
    position: "fixed",
    marginTop: "-50px",
    backgroundColor: "white",
    borderRadius: "100px",
    height: "100px",
  },
  dialogActionsContainer: {
    flex: "auto",
    flexDirection: "column",
    "& > h6": {
      color: "red",
    },
  },
  dialogActions: {
    minWidth: "40%",
    flex: "auto",
    margin: "20px 50px",
    color: "white",
    backgroundColor: "#339edf",
    "&:hover": {
      backgroundColor: "#2194db",
    },
    height: "40px",
    borderRadius: "5px",
  },
  dialogContent: {},
}));

export const ProcessBid = ({ open, handleClose, bookingData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const openBid = (bidId) => {
    history.push(
      "/confirmation?".concat(
        querystring.stringify({
          bid_number: bidId,
          type: "bid",
        })
      )
    );
  };
  const handleSubmit = async () => {
    dispatch(createBookingBid(bookingData, openBid));
  };

  useEffect(() => {
    if (!open) return;
    handleSubmit();
  }, [open]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="max-width-dialog-title"
      className={classes.dialogContainer}
    >
      <div className={classes.logoContainer}>
        <img alt="logo" className={classes.logo} src={logoUrl} />
      </div>
      <DialogTitle id="max-width-dialog-title" className={classes.dialogTitle}>
        <Box className={classes.companyName}>Dimension</Box>
        <br />
        <Typography variant="caption" display="block" gutterBottom>
          {bookingData?.email}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Spacer y={30} />
        <Loader height="300px" />
        <Spacer y={30} />
      </DialogContent>
    </Dialog>
  );
};
