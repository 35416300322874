import React from "react";
import classnames from "classnames";
import { Box, makeStyles, Typography } from "@material-ui/core";
import Spacer from "@shared/components/Spacer";
import { taskScheduleFrequency } from "@constants/booking";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(1),
    fontWeight: 800,
    fontSize: 18,
  },
  text: {
    fontSize: 14,
    marginBottom: 5,
  },
  address: {
    wordWrap: "word",
  },
}));

const BookingDetailsMin = ({ className, date, address, apartment, frequency }) => {
  const styles = useStyles();

  return (
    <Box className={className}>
      <Typography variant="h4" color="primary" className={styles.title}>
        Booking Details
      </Typography>
      <div style={{ height: 15 }} />
      {address.location && (
        <>
          <Typography className={classnames(styles.text, styles.address)}>
            {address.location}
            {apartment && `, ${apartment}.`}
          </Typography>
          <div style={{ height: 15 }} />
        </>
      )}
      {date && (
        <>
          <Typography className={styles.text}>{date.format("MMMM DD YYYY, h A.")}</Typography>
          <div style={{ height: 15 }} />
        </>
      )}
      {frequency && (
        <>
          <Typography className={styles.text}>Frequency - {taskScheduleFrequency[frequency]?.label}</Typography>
          <div style={{ height: 15 }} />
        </>
      )}
    </Box>
  );
};

BookingDetailsMin.defaultProps = {
  address: { location: null },
  apartment: null,
  date: null,
  frequency: null,
};

export default BookingDetailsMin;
