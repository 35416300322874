import React from "react";

import { LinearProgress, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      marginBottom: "24px",
      paddingTop: "42px",
    },
  },
  typography: {
    marginBottom: "24px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  progress: {
    [theme.breakpoints.up("md")]: {
      borderRadius: "5px",
      height: 18,
    },
  },
}));

function Progress({ value }) {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Typography className={styles.typography}>Your Progress</Typography>
      <LinearProgress className={styles.progress} color="primary" variant="determinate" value={value} />
    </div>
  );
}

export default React.memo(Progress);
