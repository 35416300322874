import { makeStyles, Typography } from "@material-ui/core";
import React, {useCallback} from "react";
import { useDropzone } from "react-dropzone";
export interface ImageDropzoneProps {
  handleDrop: (files: any) => Promise<void>;
  images: Array<string> | undefined;
  hidePreviews: boolean;
}

const useStyles = makeStyles((theme) => ({
  dropzone: {
    flex: 1,
    height: 180,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    maxWidth: "75%",
    borderWidth: 2,
    borderRadius: 4,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    background: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    boxSizing: "border-box",
    transition: "border .24s ease-in-out",
    cursor: "pointer",
    "&:active": {
      borderColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    }
  }
}));

export const Dropzone = ({ handleDrop }: ImageDropzoneProps) => {
  const classes = useStyles();

  const onDrop = useCallback(async (acceptedFiles) => {
    await handleDrop(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps({ className: classes.dropzone })}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p style={{ marginTop: 50 }}>Drop the files here ...</p> :
          <p style={{ marginTop: 50 }}>Drag drop files here, or click to select</p>
      }
    </div>
  );
};
 