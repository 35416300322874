import React, { useState } from "react";
import { Button, Card, CardMedia, CardContent, makeStyles, Typography } from "@material-ui/core";
import shipment_icon from "@assets/shipment_icon.png";
import junkUrl from "@assets/junk.jpg";
import cardboardUrl from "@assets/cardboard.jpg";
import dumpsterUrl from "@assets/dumpster.jpg";
import history from "@history";
import { useSelector } from "react-redux";
import { segmentTrack } from "../../../helpers/segment";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    position: "relative",
    borderRadius: theme.spacing(1),
    boxShadow: "0px 4px 20px rgb(0 0 0 / 15%)",
  },
  status: {
    textAlign: "center",
    position: "absolute",
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
  media: {
    height: 140,
    backgroundColor: "#f1f1f1",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    minHeight: 200,
  },
  mainContainer: {
    flexGrow: 1,
    display: "flex",
    marginBottom: theme.spacing(2),
    "& img:first-child": {
      marginRight: theme.spacing(1.5),
      marginTop: theme.spacing(0.7),
    },
  },
  buttonContainer: {
    display: "flex",
    alignbids: "center",
  },
  cardIcon: {
    paddingRight: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
    fontSize: "clamp(1.3rem, 1.245rem + 0.2125vw, 1.5rem)",
  },
  addressText: {
    fontSize: "16px",
    color: "#bdbdbd",
  },
  descriptionText: {
    fontSize: "14px",
  },
  amount: {
    fontSize: "clamp(1.3rem, 1.245rem + 0.2125vw, 1.5rem)",
    fontWeight: "bold",
    color: "#1E3B47",
    top: theme.spacing(1.7),
    right: theme.spacing(3),
    position: "absolute",
  },
  dateTimeContainer: {
    display: "flex",
    color: "grey",
    paddingRight: "20px",
    "& p:last-child": {
      marginLeft: theme.spacing(2),
    },
  },
  dateTimeText: {
    fontSize: "clamp(0.9rem, 0.79rem + 0.425vw, 1.3rem)",
    fontWeight: "bold",
    color: "black",
  },
  sidebarBox: {
    position: "relative",
    overflowX: "hidden",
    overflowY: "auto",
    cursor: "pointer",
    minHeight: 100,
    maxHeight: 200,
  },
  readMore: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    paddingTop: theme.spacing(8),
    backgroundImage: "linear-gradient(to bottom, transparent, white)",
  },
}));

export const services = {
  "cardboard-removal": "Cardboard Removal",
  "junk-removal": "Waste Removal",
  "dumpster-rental": "Dumpster Rental",
};

export const urlMap = {
  "Waste Removal": junkUrl,
  "Cardboard Removal": cardboardUrl,
  "Dumpster Rental": dumpsterUrl,
};

const BidCard = ({ bid }) => {
  const classes = useStyles();
  const profile = useSelector((state) => state.firebase.profile);

  const [isCollapsed, setIsCollapsed] = useState(true);

  const bidOnTask = () => {
    segmentTrack("Warrior Viewed Bid details", { bidId: bid.id });
    history.push(`/bid_details/${bid.id}`);
  };

  const handleDescriptionClick = (e, item) => {
    e.stopPropagation();
    if (item.description.length > 100) {
      setIsCollapsed(!isCollapsed);
    }
  };
  return (
    <Card className={classes.cardRoot} key={bid.id}>
      <CardMedia className={classes.media} image={bid.taskImages?.length ? bid.taskImages[0] : urlMap[services[bid.task]]} title="Task Image" />
      <CardContent className={classes.cardContent}>
        <div className={classes.mainContainer}>
          <img src={shipment_icon} alt="shipmentIcon" height="20px" width="20px" />
          <div>
            <Typography classes={{ h5: classes.title }} gutterBottom variant="h5" component="h3">
              {services[bid.task]}
            </Typography>
            <Typography gutterBottom classes={{ body1: classes.addressText }} variant="body1" component="p">
              {bid.address.city}, {bid.address.state}
            </Typography>
            <div onClick={(e) => handleDescriptionClick(e, bid)} className={classes.sidebarBox}>
              <Typography variant="body1" component="p" classes={{ body1: classes.descriptionText }} color="textSecondary">
                {isCollapsed ? bid.description.slice(0, 100) : bid.description}
              </Typography>
              {isCollapsed && bid.description.length > 100 && <div className={classes.readMore} />}
            </div>
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button fullWidth variant="contained" color="primary" className={classes.cardButton} onClick={bidOnTask}>
            Bid on task
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default BidCard;
