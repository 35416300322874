import React, { useEffect, useRef, useState } from "react";
import { wordpressCategoryIds } from "../../../pages/constants/wordpress";
import { getWordpressPostList } from "../../../pages/utils/wordpress";
import { WordPressPostSummary } from "./WordPressPostSummary";
import { Wrapper } from "./Wrapper";

export const WordPressPostList = () => {
  const [list, setList] = useState<Array<any>>([]);
  const category = window.location.pathname.replace("/", "");
  const categoryCode = wordpressCategoryIds[category];
  const subscribed = useRef(false);

  const getPost = async () => {
    await getWordpressPostList(categoryCode?.toString(), setList, subscribed);
  };

  useEffect(() => {
    subscribed.current = true;
    getPost();
    return () => {
      subscribed.current = false;
    };
  }, []);

  return <Wrapper title={"Blog"}>{list.length ? list.map((post, index) => <WordPressPostSummary post={post} key={index} />) : <div />}</Wrapper>;
};
