import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Box, Grid, TextField } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Cancel";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import { getAddressFromComponents, getZip, getOpeningHours as fetchOpeningHours } from "@shared/utils/maps";
import { getTimeZone } from "@helpers/location";
import cn from "classnames";

export const addressNull = {
  location: "",
  coordinates: {},
  placeId: "",
  zipCode: "",
  street: "",
  city: "",
  state: "",
  county: "",
  timeZone: "",
};
/** 
 address : {
  location: "San Jose 95101",
  coordinates: { lat: "37.3904943", lng: "-121.8854337" }
  placeId: "ChIJZ2fd6RvMj4ARKUI5wEomras",
  zipCode: "95101"
  street: "Malena street",
  city: "San Jose",
  state: "CA",
  county: "santa clara county"
  timeZone: America/Los_Angelas
 } 
*/
const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    verticalAlign: "middle",
  },
  clearBtn: {
    color: "grey",
    cursor: "pointer",
    fontSize: 26,
  },
  red: {
    color: "rgb(255 ,0 ,0)",
  },
  dropdown: ({ fixed, width }) => ({
    color: "rgba(0, 0, 0, 0.87)",
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    zIndex: 10,
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
    borderRadius: 5,
    marginRight: 26,
    ...(fixed
      ? {
          position: "fixed",
          width: width,
          [theme.breakpoints.down("sm")]: {
            width: "auto",
          },
          bottom: 90,
        }
      : {
          position: "absolute",
          minWidth: "100%",
        }),
  }),

  dropItem: {
    borderRadius: 5,
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    padding: "5px 10px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
  itemDesc: {
    fontSize: 14,
    paddingLeft: "5px",
    lineHeight: 1.5,
    flex: 1,
  },
}));

function AddressField(props) {
  const { label, restrict, placeholder, address, setAddress, error, helperText, className, fixed, getOpeningHours = false, types, ...rest } = props;
  const inputRef = useRef(null);

  const classes = useStyles({ fixed, width: inputRef?.current?.offsetWidth });
  const [location, setLocation] = useState(address.location ?? "");
  
  const restrictingSearchOptions = restrict
    ? {
        types: types ?? ["address"],
      }
    : {};

  useEffect(() => {
    setLocation(address.location ?? "");
  }, [address.location]);

  const handleSelect = async (value) => {
    setLocation(value);
    const [result] = await geocodeByAddress(value);
    const { street, city, state, zip, county } = getAddressFromComponents(result.address_components);
    const placeId = result.place_id;
    const coordinates = {
      lat: result.geometry.location.lat(),
      lng: result.geometry.location.lng(),
    };
    const zipCode = zip || (await getZip(coordinates));
    const openingHours = getOpeningHours && (await fetchOpeningHours(placeId));
    const timeZone = getTimeZone(coordinates);

    const address = {
      location: value,
      coordinates,
      placeId,
      zipCode,
      city,
      street,
      state,
      county,
      timeZone,
    };
    if (openingHours) {
      address.openingHours = openingHours;
    }
    setAddress(address);
  };

  const handleInputClear = () => {
    setLocation("");
    setAddress(addressNull);
  };

  return (
    <PlacesAutocomplete
      debounce={1000}
      value={location}
      onChange={setLocation}
      onSelect={handleSelect}
      className={className}
      searchOptions={{
        componentRestrictions: {
          country: ["us"],
        },
        ...restrictingSearchOptions,
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Box position="relative">
          <TextField
            className={className}
            type="search"
            ref={inputRef}
            {...getInputProps({
              placeholder,
            })}
            inputProps={{
              autoComplete: "new-password",
              form: {
                autoComplete: "off",
              },
            }}
            InputProps={{
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {location && !loading ? (
                    <CloseIcon className={cn(classes.clearBtn, { [classes.red]: !!error })} size={20} onClick={handleInputClear} />
                  ) : null}
                </>
              ),
            }}
            label={label}
            error={error}
            helperText={helperText}
            variant="outlined"
            fullWidth
            {...rest}
          />
          <Box className={classes.dropdown}>
            {suggestions.map((suggestion, index) => {
              return (
                <Grid container key={index} {...getSuggestionItemProps(suggestion)} className={classes.dropItem} alignItems="center">
                  <Grid item>
                    <LocationOnIcon className={classes.icon} />
                  </Grid>
                  <Box className={classes.itemDesc}>{suggestion.description}</Box>
                </Grid>
              );
            })}
          </Box>
        </Box>
      )}
    </PlacesAutocomplete>
  );
}

AddressField.defaultProps = {
  label: "Address",
  address: addressNull,
  placeholder: "Search Places ...",
  error: false,
  helperText: "",
  className: "",
  disabled: false,
  restrict: false,
  position: "static",
};

export default AddressField;
