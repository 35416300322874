import React from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: "5px",
    margin: theme.spacing(0, 0),
  },
  grayText: {
    color: "grey",
  },
  greenText: {
    color: theme.palette.primary.main,
    fontSize: "clamp(0.8rem, 0.6rem + 0.33vw, 1rem)",
    fontWeight: "bold",
    textTransform: "capitalize",
  },
  table: {
    width: "100%",
    borderCollapse: "separate",
    borderSpacing: "0 1rem",
  },
  tableHeader: {
    "& th": {
      color: "grey",
      fontSize: "clamp(0.9rem, 0.7rem + 0.3333vw, 1.1rem)",
      fontWeight: "bold",
      padding: theme.spacing(2, 0, 1, 0),
    },
    "& th:nth-child(2)": {
      paddingLeft: 0,
    },
    "& th:first-child": {
      paddingLeft: theme.spacing(2),
    },
    "& th:last-child": {
      paddingRight: theme.spacing(2),
    },
  },
  tableRow: {
    backgroundColor: "#fff",
    borderRadius: "7px",
    boxShadow: "rgba(50, 50, 93, 0.10) 0px 6px 12px -2px, rgba(0, 0, 0, 0.10) 0px 3px 7px -3px",
    "& td": {
      padding: theme.spacing(2, 0, 2, 0),
      borderTop: "1px solid #e8e8e8",
      borderBottom: "1px solid #e8e8e8",
    },
    "& td:first-child": {
      paddingLeft: theme.spacing(2),
    },
    "& td:nth-child(2)": {
      textAlign: "left",
    },
    "& td:nth-child(3)": {
      textAlign: "left",
    },
    "& td:last-child": {
      paddingRight: theme.spacing(2),
    },
  },
  smallColumn: {
    textAlign: "left",
    width: "100px",
  },
  mediumColumn: { textAlign: "left", width: "auto" },
}));

function PayoutTable({ payout }) {
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={12} className={classes.container}>
      <table className={classes.table}>
        <thead className={classes.tableHeader}>
          <tr>
            <th className={classes.smallColumn}>Date</th>
            <th className={classes.mediumColumn}>Description</th>
            <th className={classes.smallColumn}>Amount</th>
          </tr>
        </thead>
        <tbody>
          {payout.map((item, index) => (
            <tr key={index} className={classes.tableRow}>
              <td>
                <Typography className={classes.greenText}>{item.date}</Typography>
              </td>
              <td>
                <Typography variant="body1" className={classes.grayText}>
                  {item.description}
                </Typography>
              </td>
              <td>
                <Typography className={classes.greenText}>${item.amount}</Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Grid>
  );
}

export default PayoutTable;
