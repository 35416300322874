import React from "react";
import Modal from "@shared/components/Modal";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import ProviderStreakGroup from "@assets/provider-streak-group.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 100000000000,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "30px",
    [theme.breakpoints.down("sm")]: {
      margin: "10px",
    },
  },
  image: {
    margin: "20px 0",
  },
  heading: {
    fontSize: "22px",
    fontWeight: 900,
    color: "#4F4F4F",
    textAlign: "center",
  },
  list: {
    display: "flex",
    flexDirection: "column",
    fontSize: "16px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  button: {
    width: "200px",
    margin: "10px",
  },
}));

const TaskReviewContent = ({ onClose, setIsTaskReviewModalSubmitted }) => {
  const classes = useStyles();

  const handleSubmit = () => {
    setIsTaskReviewModalSubmitted(true);
    onClose();
  };

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid className={classes.container}>
        <img className={classes.image} src={ProviderStreakGroup} />
        <Typography className={classes.heading}>
          Wait a minute!
          <br /> Before you submit, please check the following:
        </Typography>
        <ol className={classes.list}>
          <li>Your pictures are related to the service provided.</li>
          <li>You input the correct dumping location, dumping weight, dumping cost amount.</li>
          <li>You went to the right destination for any job with pre-specified dumping destinations.</li>
          <li>You completed the job at the correct time.</li>
          <li>Failure to do so will cancel your payout.</li>
        </ol>
      </Grid>
      <Grid container spacing={3} className={classes.buttonContainer}>
        <Button className={classes.button} variant="outlined" onClick={onClose}>
          Go Back
        </Button>
        <Button className={classes.button} variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

const TaskReviewModal = ({ setIsTaskReviewModalSubmitted, onClose, ...modalProps }) => {
  return <Modal {...modalProps} header={<TaskReviewContent setIsTaskReviewModalSubmitted={setIsTaskReviewModalSubmitted} onClose={onClose} />} />;
};

export default TaskReviewModal;
