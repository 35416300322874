import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { KustomerLauncher } from "../../containers/KustomerLauncher";

const useStyles = makeStyles(() => ({
  root: {
    opacity: "0.8",
    marginTop: 20,
    fontSize: 14,
  },
  flex: {
    display: "flex",
  },
}));

const NeedHelp = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      Need help? We are here for you! You can chat with us{" "}
      <Box style={{ textDecoration: "underline", display: "inline" }}>
        <KustomerLauncher>here</KustomerLauncher>
      </Box>
      .
    </Box>
  );
};

export default NeedHelp;
