import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dividerContainer: {
    display: "flex",
    alignItems: "center",
  },
  dividerBorder: {
    borderBottom: "2px solid lightgray",
    width: "100%",
  },
  dividerContent: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontWeight: 500,
    fontSize: 22,
    color: "lightgray",
  },
}));

const DividerWithText = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.dividerContainer}>
      <div className={classes.dividerBorder} />
      <span className={classes.dividerContent}>{children}</span>
      <div className={classes.dividerBorder} />
    </div>
  );
};

export default DividerWithText;
