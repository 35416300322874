import React from "react";
import PropTypes from "prop-types";
import { Card, CardContent, CardMedia, makeStyles, Typography, useMediaQuery } from "@material-ui/core";

import NumberService from "@shared/services/NumberService";

import fiveYard from "@assets/dumpsterSizes/fiveYard.jpg";
import tenYard from "@assets/dumpsterSizes/tenYard.jpg";
import twentyYard from "@assets/dumpsterSizes/twentyYard.jpg";
import thirtyYard from "@assets/dumpsterSizes/thirtyYard.jpg";
import fourtyYard from "@assets/dumpsterSizes/fourtyYard.jpg";
import { useSelector } from "react-redux";
import Carousel from "react-material-ui-carousel";

const dumpsterSizeImage = {
  "5 Yard": fiveYard,
  "10 Yard": tenYard,
  "20 Yard": twentyYard,
  "30 Yard": thirtyYard,
  "40 Yard": fourtyYard,
};

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  card: {
    cursor: "pointer",
    outline: "1px solid #f1f1f1",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
    [theme.breakpoints.down("sm")]: {
      border: "1px solid #f1f1f1",
    },
  },
  amount: {
    fontSize: "clamp(1.3rem, 1.245rem + 0.2125vw, 1.5rem)",
    fontWeight: "bold",
    color: "#1E3B47",
  },
  cardsContainer: {
    display: "grid",
    gap: theme.spacing(3),
    gridTemplateColumns: "repeat(auto-fill, minmax(370px, 1fr))",
  },
  cardContent: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  dumpsterInfoList: {
    minHeight: "95px",
    padding: theme.spacing(0, 3),
    marginBottom: 0,
  },
  carousel: {
    "& div:first-child": {
      width: "95%",
      margin: "auto",
    },
  },
}));

function DumpsterSelectorCard({ item, onChange }) {
  const classes = useStyles();
  const priceMultiplier = useSelector((state) => state.serviceLocations.activeLocation.priceMultipliers);

  return (
    <Card key={item.id} className={classes.card} onClick={() => onChange({}, item)}>
      <CardMedia
        component="img"
        alt={`${item.description} Dumpster`}
        height="244"
        src={dumpsterSizeImage[item.description]}
        title={item.description}
      />
      <CardContent className={classes.cardContent}>
        <Typography gutterBottom variant="h5" component="h2">
          {item.description} Dumpster
        </Typography>
        <ul className={classes.dumpsterInfoList}>
          {item.info.map((item, index) => (
            <li key={`${item.description}-info-${index}`}>
              <Typography variant="body1" color="textSecondary" component="p">
                {item}
              </Typography>
            </li>
          ))}
        </ul>
        <div style={{ display: "block", marginLeft: "auto", width: "max-content" }}>
          <Typography gutterBottom variant="h6" component="p" style={{ marginBottom: -8, marginTop: 16 }}>
            <span style={{ fontWeight: 400, marginRight: 8, opacity: 0.5 }}>as low as</span>
            {NumberService.formatToCurrency(item.price * (priceMultiplier / 100))}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
}

function DumpsterSelector({ dumpsterSizes, onChange, selectedDumpsterSizes }) {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:810px)");

  const nonSelectedCards = (item) => !selectedDumpsterSizes.some((_) => _.item.id === item.id);

  const navButtonProps = {
    style: {
      backgroundColor: "transparent",
      margin: 0,
      color: "gray",
      padding: 0,
      fontSize: "1rem",
    },
  };

  const indicatorIconButtonProps = {
    style: {
      color: "#bdbdbd",
      height: "8px",
      width: "8px",
      marginRight: "8px",
    },
  };

  const activeIndicatorIconButtonProps = {
    style: {
      color: "#1E3B47",
    },
  };

  return (
    <>
      <Typography className={classes.header} variant="body1">
        Select dumpster size
      </Typography>
      {isMobile ? (
        <Carousel
          autoPlay={false}
          animation="slide"
          duration={300}
          navButtonsAlwaysVisible
          className={classes.carousel}
          navButtonsProps={navButtonProps}
          activeIndicatorIconButtonProps={activeIndicatorIconButtonProps}
          indicatorIconButtonProps={indicatorIconButtonProps}
        >
          {dumpsterSizes.filter(nonSelectedCards).map((item) => (
            <DumpsterSelectorCard item={item} onChange={onChange} key={item.id} />
          ))}
        </Carousel>
      ) : (
        <div className={classes.cardsContainer}>
          {dumpsterSizes.filter(nonSelectedCards).map((item) => (
            <DumpsterSelectorCard item={item} onChange={onChange} key={item.id} />
          ))}
        </div>
      )}
    </>
  );
}

DumpsterSelector.propTypes = {
  dumpsterSizes: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  selectedDumpsterSize: PropTypes.object,
};

export default DumpsterSelector;
