import React from "react";
import PropTypes from "prop-types";
import { Button, Card, CardContent, Grid, makeStyles, Typography } from "@material-ui/core";
import AcceptButton from "./Buttons/AcceptButton";
import CompleteButton from "./Buttons/CompleteButton";
import WaitlistButton from "./Buttons/WaitlistButton";
import dayjs from "dayjs";
import WithdrawButton from "./Buttons/WithdrawButton";

const useStyles = makeStyles((theme) => ({
  detailsItemValue: {
    paddingLeft: "35px",
  },
  card: {
    padding: "10px",
    animation: "$fadein 0.2s ease-in",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
    border: "1px solid #eee",
  },
  "@keyframes fadein": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  cardColumn: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  buttonContainer: {
    "& > button": {
      marginTop: theme.spacing(2),
    },
  },
  cardContent: {
    marginTop: "20px",
  },
  detailsCardTitle: {
    fontWeight: "bold",
    fontSize: "18px",
  },
  payment: {
    fontWeight: "bold",
    fontSize: "18px",
  },
  greenText: {
    color: theme.palette.primary.main,
  },
  backLinkButton: {
    textDecoration: "none",
    fontSize: "14px",
    fontWeight: "400",
  },
}));

function TaskDetailCard({ handleTaskAccept, handleTaskComplete, handleWithdrawModal, handleGoBack, booking, isCompleted, showWithdraw, user, getFinalPayoutPrice }) {
  const classes = useStyles();
  return (
    <div className={classes.cardColumn}>
      <Card className={`${classes.card}`}>
        <CardContent className={classes.cardContent}>
          <Typography style={{ fontWeight: "bold", marginBottom: "15px" }}>
            {dayjs(booking.date).tz(booking.timeZone).format("MMM DD, YYYY h:mm A")}
          </Typography>
          <Grid container justifyContent="space-between">
            <Grid className={classes.detailsCardTitle}>Payout</Grid>
            <div style={{ position: "relative" }}>
              {getFinalPayoutPrice()}
            </div>
          </Grid>
          {booking?.status === "rejected" ? (
            <>
              <CompleteButton style={{ marginTop: "20px" }} fullWidth buttonTitle="Submit Again" onClick={handleTaskComplete} />
              <Button onClick={handleGoBack} fullWidth className={classes.backLinkButton}>
                Back to Tasks
              </Button>
            </>
          ) : (
            <>
              {!isCompleted && (
                <div className={classes.buttonContainer}>
                  <AcceptButton fullWidth onClick={handleTaskAccept} disabled={!user.uid} />
                  <CompleteButton fullWidth onClick={handleTaskComplete} />
                  <WaitlistButton />
                  <div style={{ marginTop: 10 }} />
                  {showWithdraw && <WithdrawButton onClick={handleWithdrawModal} />}
                  <Button onClick={handleGoBack} fullWidth className={classes.backLinkButton}>
                    Back to Tasks
                  </Button>
                </div>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

TaskDetailCard.propTypes = {
  handleTaskAccept: PropTypes.func,
  handleTaskComplete: PropTypes.func,
  booking: PropTypes.object,
  user: PropTypes.object,
};

export default TaskDetailCard;
