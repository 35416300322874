import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexFlow: "row",
  },
  icon: {
    margin: theme.spacing(0, 1.5),
  },
}));

const ButtonComponent = ({ children, loadingText, loading, className, disabled, variant, ...rest }) => {
  const styles = useStyles();
  return (
    <Button disabled={disabled || loading} variant={variant} className={className} {...rest}>
      {loading ? loadingText || children : children}
      {loading && <CircularProgress className={styles.icon} color="inherit" size={20} />}
    </Button>
  );
};

ButtonComponent.defaultProps = {
  variant: "contained",
  children: "Button",
  loadingText: null,
  loading: false,
  disabled: false,
  className: "",
};

export default ButtonComponent;
