import React, { useCallback, useState, forwardRef } from "react";
import { Box, Grid } from "@material-ui/core";

import useMountedEffect from "@shared/hooks/useMountedEffect";

import { updateField } from "@booking/helpers/utils";
import { Input } from "@booking/helpers/enums";
import useTriggerValidation from "@shared/hooks/useTriggerValidation";
import NeedHelp from "@shared/components/NeedHelp";

import VehicleField from "@booking/helpers/fields/VehicleField";
import JunkField from "@booking/helpers/fields/JunkField";
import StairsField from "@booking/helpers/fields/StairsField";
import DismantlingField from "@booking/helpers/fields/DismantlingField";
import DescriptionField from "@booking/helpers/fields/DescriptionField";
import CouponField from "@booking/helpers/fields/CouponField";
import PrivilegedComponent from "../../../../../shared/components/PrivilegedComponent";
import { BidPrompt } from "../../../components/BidPrompt";

const TaskDescriptionForm = forwardRef(({ task, defaultValue, onChange, setIsBidMode, isBidPromptValid, isDefaultBid }, ref) => {
  const [vehicleField, setVehicleField] = useState(defaultValue[Input.Vehicle]);
  const [junkField, setJunkField] = useState(defaultValue[Input.Junk]);
  const [stairsField, setStairsField] = useState(defaultValue[Input.Stairs]);
  const [dismantlingField, setDismantlingField] = useState(defaultValue[Input.Dismantling]);
  const [descriptionField, setDescriptionField] = useState(defaultValue[Input.Description]);

  useMountedEffect(() => {
    onChange({
      [Input.Vehicle]: vehicleField,
      [Input.Junk]: junkField,
      [Input.Description]: descriptionField,
      [Input.Stairs]: stairsField,
      [Input.Dismantling]: dismantlingField,
    });
  }, [vehicleField, junkField, descriptionField, stairsField, dismantlingField]);

  useTriggerValidation(ref, [setVehicleField, setJunkField, setStairsField, setDismantlingField, setDescriptionField]);

  const handleVehicleChange = useCallback(
    (field) => {
      setVehicleField(updateField(vehicleField, field));
    },
    [vehicleField]
  );

  const handleJunkChange = useCallback(
    (field) => {
      setJunkField(updateField(junkField, field));
    },
    [junkField]
  );

  const handleStairsChange = useCallback(
    (field) => {
      setStairsField(updateField(stairsField, field));
    },
    [stairsField]
  );

  const handleDismantlingChange = useCallback(
    (field) => {
      setDismantlingField(updateField(dismantlingField, field));
    },
    [dismantlingField]
  );

  const handleDescriptionChange = useCallback(
    (field) => {
      setDescriptionField(updateField(descriptionField, field));
    },
    [descriptionField]
  );

  return (
    <Box>
      <JunkField task={task} field={junkField} onChange={handleJunkChange} />
      {(isBidPromptValid || isDefaultBid) && (
        <PrivilegedComponent flag={"b2c-bids"} track={true}>
          <BidPrompt isBidMode={false} setIsBidMode={setIsBidMode} isDefaultBid={isDefaultBid} />
        </PrivilegedComponent>
      )}
      <br />
      <br />
      <VehicleField field={vehicleField} onChange={handleVehicleChange} />
      <br />
      <br />
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={12}>
          <StairsField field={stairsField} onChange={handleStairsChange} />
        </Grid>
        <Grid item xs={12}>
          <DismantlingField field={dismantlingField} onChange={handleDismantlingChange} />
        </Grid>
      </Grid>
      <br />
      <br />
      <DescriptionField
        field={descriptionField}
        minRows={8}
        width={"75%"}
        description={`For example, "1x king-sized mattress that will be located inside the garage", "4x trash bags of food, cardboard, and general house trash will be located on the front lawn." 

Note: Items you wish to be removed must be itemized and added to the dropdown list of "pickup type" above. If you cannot find an item from the dropdown list, please choose "unlisted" in the dropdown menu, and describe them in this box.`}
        onChange={handleDescriptionChange}
      />
      <br />
      <br />
      <CouponField />
      <br />
      <br />
      <NeedHelp />
    </Box>
  );
});

export default React.memo(TaskDescriptionForm);
