import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { makeStyles, Typography, Grid, Box } from "@material-ui/core";
import dayjs from "dayjs";
import cn from "classnames";
import history from "@history";

import FaceIcon from "@material-ui/icons/Face";
import TimeIcon from "@material-ui/icons/AccessTime";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";

import { taskTypes, bookingStatus } from "@constants/booking";
import { setActiveBooking, setBookingOrderNumber } from "@store/actions/booking";
import tempDataConstants from "@store/constants/tempDataTypes";

import NoData from "@shared/components/NoData";
import Loader from "@shared/components/Loader";
import Spacer from "@shared/components/Spacer";
import ProductItem from "@shared/components/ProductItem";
import ImageGalleryCustom from "@shared/components/ImageGallery";
import BookingDetailsLayout from "@shared/components/BookingDetailsLayout";

import WarriorCard from "../OrdersPage/WarriorCard";
import { Payment } from "../../../constants/payments";
import { fetchCardInfo, fetchInvoices } from "../../../store/actions/payments";
import { roles } from "../../../constants/roles";

import providerStatus from "@assets/booking_details/provider-status.svg";
import calendarDate from "@assets/booking_details/calendar-date.svg";
import address from "@assets/booking_details/address.svg";
import clientNotes from "@assets/booking_details/client-notes.svg";
import paymentType from "@assets/booking_details/payment-type.svg";
import itemsExpected from "@assets/booking_details/items-expected.svg";
import stairs from "@assets/booking_details/stairs.svg";
import dismantling from "@assets/booking_details/dismantling.svg";
import { scrollToTop } from "../../../methods/scrollToTop";
import { dumpsterPickups, Pickup } from "../../../constants/dumpsters";

const useStyles = makeStyles((theme) => ({
  detailsContainer: {
    width: "90vw",
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up("md")]: {
      margin: "0 20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 0),
    },
  },
  detailsGridContainer: {
    padding: theme.spacing(1, 2),
    alignItems: "start",
    columnGap: 15,
    display: "grid",
    width: "100%",
    gridTemplateColumns: "max-content auto",
  },
  detailsGridContainerText: {
    columnGap: 15,
    display: "grid",
    width: "100%",
    gridTemplateColumns: "150px auto",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  invoice: {
    width: "100%",
    maxWidth: 600,
    border: "1px solid #eee",
    padding: theme.spacing(4),
    borderRadius: 4,
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      width: "max-content",
    },
  },
  detailsItemTitle: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#333333",
  },
  subItem: {
    display: "grid",
    gridTemplateColumns: "164px auto",
    margin: theme.spacing(0, 0, 2),
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "120px auto",
    },
  },
  subItemValue: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#4F4F4F",
  },
  subItemLabel: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#4F4F4F",
  },
  detailsItemValue: {
    fontSize: "16px",
    color: "grey",
  },
  detailsCardTitle: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  greyedText: {
    color: "grey",
  },
  greenText: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  cardMobileContainer: {
    position: "sticky",
    bottom: "0px",
    background: "#fff",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  cardMobile: {
    display: "flex",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    alignItems: "start",
    justifyContent: "space-between",
    bottom: "0px",
    padding: theme.spacing(3, 6),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },
  staticButton: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  cardWrapper: {
    maxWidth: "350px",
    margin: "auto",
  },
  link: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  receiptLink: {
    marginLeft: 164,
  },
}));

const ImageContainer = ({ image, ...otherProps }) => <img width={21} src={image} {...otherProps} />;

const TaskInfo = () => {
  const classes = useStyles();
  const bookingId = useParams().id;

  const booking = useSelector((state) => state.booking.selectedBooking);
  const user = useSelector((state) => state.firebase.profile);
  const warriorDetails = useSelector((state) => state.tempData.user);
  const isDumpsterRental = booking.task === taskTypes["dumpster-rental"].value;

  const [cardInfo, setCardInfo] = useState({});
  const [invoicesInfo, setInvoicesInfo] = useState([]);
  // const isDumpsterRental = true;

  useEffect(() => {
    const fetchPaymentInfo = async () => {
      switch (booking.payment_method) {
        case Payment.Card:
          {
            const ccInfo = await fetchCardInfo(booking?.stripeChargeId);
            if (ccInfo) {
              const card = ccInfo.payment_method_details.card;
              setCardInfo({
                ccInfo: `**** **** **** ${card.last4}`,
                paymentDate: dayjs.unix(ccInfo.created).format("MMMM DD, YYYY hh:mm A"),
                amount: `$${(ccInfo.amount / 100).toFixed(2)}`,
                receiptUrl: ccInfo.receipt_url ?? "-",
              });
            }
          }
          break;
        case Payment.ACH:
          {
            const invoicesInfo = await fetchInvoices(booking.id);
            if (invoicesInfo?.invoices?.length) {
              setInvoicesInfo(
                invoicesInfo.invoices.map((invoice) => ({
                  dueDate: dayjs.unix(invoice.due_date).format("MMMM DD, YYYY hh:mm A"),
                  paymentDate: invoice.status_transitions.paid_at
                    ? dayjs.unix(invoice.status_transitions.paid_at).format("MMMM DD, YYYY hh:mm A")
                    : "N/A",
                  amount: `$${(invoice.amount_due / 100).toFixed(2)}`,
                  description: invoice.description,
                  paymentLink: invoice.hosted_invoice_url,
                }))
              );
            }
          }
          break;
        default:
          break;
      }
    };
    fetchPaymentInfo();
  }, [bookingId]);

  const handleBack = () => {
    if (!user.isEmpty) {
      history.push("/bookings");
    } else {
      history.goBack();
    }
  };

  const detailItems = () => {
    return [
      {
        icon: <FaceIcon />,
        label: "Provider Matched",
        show: booking.status === bookingStatus["current"].value,
        render: (componentClasses) => (
          <Typography color="textPrimary">
            <b>{warriorDetails?.firstName ?? "-"}</b>
          </Typography>
        ),
      },
      {
        icon: <ImageContainer image={providerStatus} alt="Provider Status" />,
        label: "Provider Status",
        show: booking.status !== bookingStatus["current"].value,
        render: (componentClasses) => (
          <>
            <Typography>
              <b>{bookingStatus[booking.status].nextActionLabel}</b>
            </Typography>
          </>
        ),
      },
      {
        icon: <ImageContainer image={calendarDate} alt={isDumpsterRental ? "Dumpster drop off date" : "Date and time"} />,
        label: isDumpsterRental ? "Dumpster drop off date" : "Date and time",
        show: true,
        render: (componentClasses) => (
          <Typography className={componentClasses.greenText}>{dayjs(booking.date).tz(booking.timeZone).format("MMMM DD, YYYY hh:mm A")}</Typography>
        ),
      },
      {
        icon: <ImageContainer image={calendarDate} alt="Pickup Date" />,
        label: "Pickup Date",
        show: isDumpsterRental,
        render: (componentClasses) => (
          <Typography className={componentClasses.greenText}>
            {dumpsterPickups[booking.pickup]?.value !== Pickup.Manual
              ? dumpsterPickups[booking.pickup]?.label
              : dayjs(booking["pickup-date"]).tz(booking.timeZone).format("MMMM DD, YYYY hh:mm A")}
          </Typography>
        ),
      },
      {
        icon: <ImageContainer image={address} alt="Service Address" />,
        label: "Service Address",
        show: true,
        render: (componentClasses) => (
          <div>
            <Typography className={componentClasses.greenText}>{booking.address.location}</Typography>
            <Typography className={componentClasses.greenText}>{booking.address_aditional ?? ""}</Typography>
            <Typography className={componentClasses.greenText}>{booking.address.zipCode}</Typography>
          </div>
        ),
      },
      {
        icon: <ImageContainer image={clientNotes} alt="Client Notes" />,
        label: "Client Notes",
        show: true,
        render: (componentClasses) => <Typography className={componentClasses.greyedText}>{booking.description}</Typography>,
      },
      {
        icon: <ImageContainer image={stairs} alt="stairs" />,
        label: "Stairs",
        show: !isDumpsterRental,
        render: (componentClasses) => <Typography>{booking.stairs ?? "Not expected"}</Typography>,
      },
      {
        icon: <ImageContainer image={dismantling} alt="dismantling" />,
        label: "Dismantling",
        show: !isDumpsterRental,
        render: (componentClasses) => <Typography>{booking.dismantling ?? "Not expected"}</Typography>,
      },
    ];
  };

  const paymentItems = () => {
    const TypeOfPayment = (type) => ({
      icon: <ImageContainer image={paymentType} alt="Type of payment" />,
      label: "Type of payment",
      show: true,
      render: (componentClasses) => (
        <Typography>
          <b>{type}</b>
        </Typography>
      ),
    });

    const PaymentLine = ({ label, value }) => (
      <Box className={classes.subItem}>
        <Typography className={classes?.subItemLabel}>{label}</Typography>
        {typeof value == "string" ? <Typography className={classes?.subItemValue}>{value}</Typography> : value}
      </Box>
    );

    const CardPayment = () => (
      <Box className={classes.invoice}>
        <PaymentLine label={"Payment Date"} value={cardInfo.paymentDate ?? "-"} />
        <PaymentLine label={"Amount"} value={cardInfo.amount ?? "-"} />
        <Typography>
          <a className={cn(classes.greenText, classes.link, classes.receiptLink)} rel="noreferrer" href={cardInfo.receiptUrl ?? ""} target="_blank">
            Receipt Link
          </a>
        </Typography>
      </Box>
    );

    const Invoices = () => (
      <Box>
        {invoicesInfo?.map((invoice, index) => (
          <Box key={index} className={classes.invoice}>
            <PaymentLine label={"Due Date"} value={invoice.dueDate ?? "-"} />
            <PaymentLine label={"Payment Date"} value={invoice.paymentDate ?? "-"} />
            <PaymentLine label={"Amount"} value={invoice.amount ?? "-"} />
            <PaymentLine label={"Memo"} value={invoice.description ?? "-"} />
            <Typography>
              <a className={cn(classes.greenText, classes.link, classes.receiptLink)} rel="noreferrer" href={invoice.paymentLink} target="_blank">
                Receipt Link
              </a>
            </Typography>
          </Box>
        ))}
      </Box>
    );
    return [
      ...(booking?.payment_method === Payment.Card
        ? [
            {
              ...TypeOfPayment("Credit Card"),
            },
            {
              label: "",
              show: true,
              isTable: true,
              render: () => <CardPayment />,
            },
          ]
        : booking?.payment_method === Payment.ACH
        ? [
            {
              ...TypeOfPayment("Invoice"),
            },
            {
              label: "",
              show: true,
              isTable: true,
              render: () => <Invoices />,
            },
          ]
        : [
            {
              ...TypeOfPayment("N/A"),
            },
          ]),
    ];
  };

  const productItems = () => {
    return [
      {
        icon: <ImageContainer image={itemsExpected} alt="Items Expected" />,
        label: "Items Expected",
        show: !isDumpsterRental,
        render: (componentClasses) => (
          <div className={componentClasses.productList}>
            {booking?.products?.map((item, index) => (
              <ProductItem key={index} icon={null} label={item.item.description} count={item.quantity} details={item.item.details} />
            ))}
          </div>
        ),
      },
      {
        icon: <TimeIcon />,
        label: "Expected Duration of Dumpster at Client site",
        show: isDumpsterRental,
        render: (componentClasses) => (
          <Typography className={componentClasses.greenText}>
            {booking.pickup === Pickup.Manual
              ? `${dayjs(booking["pickup-date"]).tz(booking.timeZone).format("MMM DD, YYYY")} ${dayjs()
                  .hour(booking["pickup-time"].hour)
                  .minute(0)
                  .format("h:mm A")}`
              : dumpsterPickups[booking.pickup]?.shortLabel}
          </Typography>
        ),
      },
      {
        icon: <BuildOutlinedIcon />,
        label: "Dumpsters Expected",
        show: isDumpsterRental,
        render: (componentClasses) => (
          <div>
            {booking?.services?.map((item, index) => (
              <div key={index}>
                <Typography>
                  <b>Dumpster size: {item.item.description}</b>
                </Typography>
                <Spacer />
                {item.item.items.map((material, index) => (
                  <Typography key={index}>Dumping material: {material.description}</Typography>
                ))}
                <Spacer />
              </div>
            ))}
          </div>
        ),
      },
    ];
  };

  const imageItems = () => {
    return [
      {
        icon: null,
        label: "Task Images",
        show: true,
        block: true,
        render: (componentClasses) =>
          booking?.taskImages?.length ? (
            <ImageGalleryCustom images={booking.taskImages} grid={true} />
          ) : (
            <Typography className={classes.greyedText}>Not available</Typography>
          ),
      },
    ];
  };

  return (
    <Box>
      <BookingDetailsLayout
        subTitle={`Order ${booking.orderNumber} - ${taskTypes[booking.task].label}`}
        title={`${`Booking Details`}`}
        backText={"Home page"}
        sections={[detailItems(), productItems(), imageItems(), paymentItems()]}
        goBack={handleBack}
        card={<WarriorCard page="details" />}
      />
      <Grid item xs={12} className={classes.cardMobileContainer}>
        <div className={classes.cardMobile}>
          <div className={classes.detailsCardTitle}>Total amount paid</div>
          <Typography className={`${classes.greenText}`}>${(booking?.total / 100).toFixed(2)}</Typography>
        </div>
      </Grid>
    </Box>
  );
};

const BookingDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const bookingId = useParams().id;

  const myBookings = useSelector((state) => state.booking?.myBookings);
  const booking = useSelector((state) => state.booking.selectedBooking);
  const isLoading = useSelector((state) => state.booking.isLoading);
  const user = useSelector((state) => state.firebase.profile);

  scrollToTop();

  useEffect(() => {
    if (!isLoading && booking?.id !== bookingId) {
      if (user && (user.userType === roles.superadmin.value || user.userType === roles.admin.value)) {
        if (booking?.orderNumber !== bookingId) {
          const fetchBookingWithOrderNumber = async (bookingId) => {
            dispatch(setBookingOrderNumber(bookingId));
          };
          fetchBookingWithOrderNumber(bookingId);
        }
      } else {
        const matchingBooking = myBookings?.find((b) => b.id === bookingId);
        dispatch(setActiveBooking(matchingBooking ?? undefined, bookingId));
      }
    }
    return () => {
      dispatch({ type: tempDataConstants.RESET });
    };
  }, [bookingId, user, isLoading, myBookings?.length]);

  if (isLoading) {
    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <Loader />
        </div>
      </div>
    );
  }

  if (!isLoading && !booking) return <NoData />;

  return <TaskInfo />;
};

export default BookingDetails;
