import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { services, vehicles } from "@booking/helpers/apiData";
import { taskName, vehicleName } from "@booking/helpers/wordings";
import { Task } from "@booking/helpers/enums";
import { calculateMaterialPercentage } from "@booking/helpers/utils";

import Item from "./Item";

const Items = ({ task, vehicle, junk, dumpster, className = null, draftBooking = null, isLockedPrice }) => {
  const currentActive = useSelector((state) => state.serviceLocations?.activeLocation);
  const sameDaySurge = useSelector((state) => state.ui.sameDaySurge.fetchedValue);
  const priceMultiplier = currentActive ? currentActive.allMultipliers?.[task] : 100;
  const doAdjust = task !== Task.DumpsterRental && !draftBooking?.isServiceFeeWaived;
  const sameDayAdjustment = doAdjust ? sameDaySurge : 0;
  const isVehicleFeeWaived = draftBooking?.isVehicleFeeWaived;
  const isDumpsterTask = task === Task.DumpsterRental;

  const getMultiplier = () => {
    if (draftBooking?.isServiceFeeWaived) {
      return 1;
    }
    return priceMultiplier / 100;
  };

  const RemovalItems = () => (
    <>
      {!isVehicleFeeWaived && (
        <Item
          tag={`Vehicle Size: ${vehicleName[vehicle]}`}
          price={isVehicleFeeWaived ? 0 : vehicles[vehicle].price}
          priceMultiplier={getMultiplier()}
          isLockedPrice={isLockedPrice}
        />
      )}
      {junk.map(({ item, quantity }) => (
        <Item
          key={item?.uid ?? item.id}
          tag={item.description}
          price={item.price}
          quantity={quantity}
          priceMultiplier={priceMultiplier / 100}
          isLockedPrice={isLockedPrice}
        />
      ))}
    </>
  );

  const RentalItems = () =>
    dumpster.map(({ item }) => {
      const isLockedPriceItem = item?.isLockedPriceItem ?? false;
      const percentage = isLockedPriceItem ? 0 : calculateMaterialPercentage(item.items);

      const price = item.price + (item.price * percentage) / 100;

      return (
        <Item
          key={item?.uid ?? item.id}
          tag={item.description}
          price={price}
          percentage={percentage}
          // showPercentage={!isLockedPriceItem}
          showPercentage={false}
          priceMultiplier={priceMultiplier / 100}
          isLockedPrice={isLockedPrice}
        />
      );
    });

  const ItemComponent = {
    [Task.JunkRemoval]: RemovalItems,
    [Task.CardboardRemoval]: RemovalItems,
    [Task.DumpsterRental]: RentalItems,
  }[task];

  const getBasePrice = () => {
    let base = services[task]?.price;
    if (draftBooking?.isServiceFeeWaived) {
      return 0;
    }
    return base;
  };

  return (
    <Box className={className}>
      {!isDumpsterTask && (
        <Item
          tag={taskName[task]}
          price={getBasePrice()}
          adjustment={sameDayAdjustment}
          priceMultiplier={getMultiplier()}
          isLockedPrice={isLockedPrice}
        />
      )}
      <ItemComponent />
    </Box>
  );
};

export default Items;
