import React from "react";
import { makeStyles, Box, Button } from "@material-ui/core";
import { useHistory } from "react-router";

const useStyles = makeStyles(() => ({
  root: {
    padding: "10px",
    width: "100%",
    height: "calc(100vh - 280px)",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    alignItems: "center",
  },
  noDataText: {
    fontWeight: "bold",
    fontSize: "25px",
    textAlign: "center",
    margin: "10px 0",
  },
  subText: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#333333",
    textAlign: "center",
  },
  buttonText: {
    margin: "20px 0",
  },
}));

function NoDataWarrior({ displayText, bannerImage, subText, buttonText, buttonLink }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.root}>
      {bannerImage && (
        <Box>
          <img src={bannerImage} alt="no data" style={{ height: 300, marginBottom: 48 }} />
        </Box>
      )}
      <Box className={classes.noDataText}>{displayText}</Box>
      {subText && <Box className={classes.subText}>{subText}</Box>}
      {buttonText && (
        <Box className={classes.buttonText}>
          <Button variant="contained" color="primary" onClick={() => history.push(buttonLink)}>
            {buttonText}
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default NoDataWarrior;
