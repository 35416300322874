import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { FormControlLabel, Radio, RadioGroup, useMediaQuery, makeStyles, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";

import validators from "@booking/helpers/validators";
import { Input } from "@booking/helpers/enums";

import Section from "@booking/components/Section";
import classNames from "classnames";
import { Pickup } from "../../../../constants/dumpsters";
import { convertPickup } from "../../../../helpers/dumpsters";

const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    marginTop: theme.spacing(2),
    "& .MuiPaper-elevation0": {
      boxShadow: "none",
    },
    "& .MuiAccordionSummary-root": {
      padding: 0,
      minHeight: 0,
      height: "0px",
    },
    "& .MuiAccordionDetails-root": {
      display: "block",
      width: "100%",
      padding: 0,
      "$ div": {
        width: "100%",
      },
    },
  },
  heading: {
    paddingBottom: theme.spacing(2),
    fontSize: 14,
    color: theme.palette.primary.main,
    textDecoration: "underline",
    fontWeight: theme.typography.fontWeightBold,
  },
  label: {
    display: "flex",
    flexDirection: "column",
    "& > div:first-child": {
      fontSize: "1rem",
    },
    "& > div:last-child": {
      fontSize: "0.9rem",
      color: theme.palette.text.secondary,
    },
  },
  radioItems: {
    display: "flex",
    paddingBottom: theme.spacing(2),
    alignItems: "start",
    marginRight: "40px",
    "& > span:first-child": {
      padding: theme.spacing(0, 1),
    },
  },
  activeRadioItem: {
    "& > span:last-child": {
      fontWeight: "bold",
    },
  },
}));

const TwoLinelabel = ({ line1, line2 }) => {
  const classes = useStyles();
  return (
    <div className={classes.label}>
      <div>{line1}</div>
      <div>{line2}</div>
    </div>
  );
};

const PickupField = ({ field, selectedDate, onChange, children }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [expanded, setExpanded] = useState(false);
  const [selectedOption, setSelectedOption] = useState(Pickup.NextBooking);
  const classes = useStyles();

  const { value } = field;

  useEffect(() => {
    onChange({
      validation: validators[Input.Pickup](field),
    });
  }, [value]);

  const handleRadioChange = (e, radioValue) => {
    setSelectedOption(radioValue);
    const nextValue = convertPickup(radioValue);
    if (nextValue) {
      setExpanded((expanded) => (nextValue === Pickup.Manual ? !expanded : false));
      onChange({ value: nextValue });
    }
  };

  const getFutureDate = (days) => dayjs(selectedDate).add(days, "day").format("MM-DD-YYYY");

  const getRadioItemClasses = (value) =>
    classNames(classes.radioItems, {
      [classes.activeRadioItem]: selectedOption === value,
    });

  return (
    <Section title="Pick Up Date">
      <RadioGroup aria-label="pickup" name="pickup" row={matches} value={value} onChange={handleRadioChange}>
        <FormControlLabel
          className={getRadioItemClasses(Pickup.NextBooking)}
          value={Pickup.NextBooking}
          control={<Radio color="primary" />}
          label={<TwoLinelabel line1="Next booking" />}
        />
        <FormControlLabel
          className={getRadioItemClasses(Pickup.ThreeDays)}
          value={Pickup.ThreeDays}
          control={<Radio color="primary" />}
          label={<TwoLinelabel line1="3 days later" line2={getFutureDate(3)} />}
        />
        <FormControlLabel
          className={getRadioItemClasses(Pickup.SevenDays)}
          value={Pickup.SevenDays}
          control={<Radio color="primary" />}
          label={<TwoLinelabel line1="7 days later" line2={getFutureDate(7)} />}
        />
        <FormControlLabel
          className={getRadioItemClasses(Pickup.Manual)}
          value={Pickup.Manual}
          control={<Radio color="primary" />}
          label={<TwoLinelabel line1="Choose date manually" />}
        />
      </RadioGroup>
      <div className={classes.accordionRoot}>
        <Accordion TransitionProps={{ unmountOnExit: true }} defaultExpanded={false} expanded={expanded}>
          <AccordionSummary></AccordionSummary>
          <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
      </div>
    </Section>
  );
};

export default PickupField;
