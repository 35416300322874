import React from "react";
import { scrollToTop } from "../../../methods/scrollToTop";
import BottomCTA from "../LandingPage/BottomCTA";
import CompanyMission from "./CompanyMission";
import Pitch from "./Pitch";
import Values from "./Values";

function AboutUs() {
  scrollToTop();
  return (
    <>
      <Pitch />
      <CompanyMission />
      <Values />
      <BottomCTA />
    </>
  );
}

export default AboutUs;
