import React from "react";
import classes from "@css/components/website/ProductFeatures.module.css";
import feature_1 from "../../../assets/landing_page/features/feature_1.jpg";
import feature_2 from "../../../assets/landing_page/features/feature_2.jpg";
import feature_3 from "../../../assets/landing_page/features/feature_3.png";
import feature_4 from "../../../assets/landing_page/features/feature_4.jpg";
import { Button } from "@material-ui/core";
import FeatureScroller from "../Common/FeatureScroller";
import { KustomerLauncher } from "../../../containers/KustomerLauncher";

const features = [
  {
    title: "Waste Management On-Demand",
    descriptions: [
      "Solve your waste management worries with the click of a button. Our fully digital service makes it simple to organize sustainable pickup for any waste you want to remove and recycle from your site, including: cardboard, bulky items, food waste, medical waste and hazardous waste.",
    ],
    buttons: (onBookClick: any = () => {}) => (
      <div className={classes.buttonContainer}>
        <Button disableRipple className={classes.primaryButton} onClick={() => onBookClick("booking?step=initial")}>
          Book Now
        </Button>
        <Button disableRipple className={classes.secondaryButton}>
          <KustomerLauncher>Chat with us</KustomerLauncher>
        </Button>
      </div>
    ),
    imageUrl: feature_1,
  },
  {
    title: "Flexible & Customizable",
    descriptions: [
      "Whether you’re scheduling a one-off pickup, searching for a reliable regular provider or solving a waste emergency, we’ll find the solution to perfectly suit your needs.",
    ],
    buttons: (onBookClick: any = () => {}) => (
      <div className={classes.buttonContainer}>
        <Button disableRipple className={classes.primaryButton} onClick={() => onBookClick("booking?step=initial")}>
          Book Now
        </Button>
        <Button disableRipple className={classes.secondaryButton}>
          <KustomerLauncher>Chat with us</KustomerLauncher>
        </Button>
      </div>
    ),
    imageUrl: feature_2,
  },
  {
    title: "Simplifying Sustainability",
    descriptions: [
      "Dimension offers businesses the tools and data they need to minimise their environmental impact without increasing an already busy workload.",
    ],
    buttons: (onBookClick: any = () => {}) => (
      <div className={classes.buttonContainer}>
        <Button disableRipple className={classes.primaryButton} onClick={() => onBookClick("booking?step=initial&business_client=1")}>
          Book Now
        </Button>
        <Button disableRipple className={classes.secondaryButton}>
          <KustomerLauncher>Chat with us</KustomerLauncher>
        </Button>
      </div>
    ),
    imageUrl: feature_4,
  },
  {
    title: "Powerful Diversion Data",
    descriptions: [
      "Track exactly where your waste ends up, including receipts, photos and detailed breakdowns of exactly what was hauled. Whether you’re looking to streamline legal compliance or be more environmentally conscious, we’ve got you covered.",
    ],
    buttons: (onBookClick: any = () => {}) => (
      <div className={classes.buttonContainer}>
        <Button disableRipple className={classes.primaryButton} onClick={() => onBookClick("booking?step=initial")}>
          Book Now
        </Button>
        <Button disableRipple className={classes.secondaryButton}>
          <KustomerLauncher>Chat with us</KustomerLauncher>
        </Button>
      </div>
    ),
    backgroundColor: "#F1F1F1",
    imageUrl: feature_3,
  },
];

function ProductFeatures() {
  return (
    <section className={classes.container}>
      <FeatureScroller features={features} />
    </section>
  );
}

export default ProductFeatures;
