import React, { useEffect } from "react";
import { Box, FormControlLabel, makeStyles, Radio, RadioGroup, Typography, useMediaQuery } from "@material-ui/core";

import validators from "@booking/helpers/validators";
import { Input } from "@booking/helpers/enums";
import { taskScheduleFrequency } from "@constants/booking";

import Section from "@booking/components/Section";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  radioItems: {
    marginRight: "40px",
  },
  activeRadioItem: {
    "& > span:last-child": {
      fontWeight: "bold",
    },
  },
  description: {
    fontSize: "12px",
  },
}));

const FrequencyFieldComponent = ({ field, onChange }) => {
  const classes = useStyles();
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const { value } = field;

  useEffect(() => {
    onChange({ validation: validators[Input.Frequency](field) });
  }, [value]);

  const handleChange = (_, radioValue) => {
    onChange({ value: radioValue });
  };

  const getRadioItemClasses = (selectedValue) =>
    classNames(classes.radioItems, {
      [classes.activeRadioItem]: value === selectedValue,
    });

  const description = (
    <Typography className={classes.description}>
      If you book a recurring frequency, you will be only charged for the first booking today. Your card will then be charged automatically 4 days
      ahead of your next booking.
    </Typography>
  );

  return (
    <Section title="Frequency" description={description}>
      <RadioGroup aria-label="frequency" name="frequency" row={matches} value={value} onChange={handleChange}>
        {Object.values(taskScheduleFrequency).map((item, index) => (
          <FormControlLabel
            className={getRadioItemClasses(item.value)}
            key={index}
            value={item.value}
            control={<Radio color="primary" />}
            label={item.label}
          />
        ))}
      </RadioGroup>
    </Section>
  );
};

export default React.memo(FrequencyFieldComponent);
