import React from "react";
import { makeStyles, Typography, Divider, useMediaQuery, FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    boxSizing: "border-box",
    "& > .active": {
      color: theme.palette.primary.main,
      borderBottom: "2px solid",
    },
    "& > .inactive": {
      color: "rgba(0, 0, 0, .34)",
    },
    position: "relative",
  },
  divider: {
    position: "absolute",
    width: "100%",
    borderBottom: "1px solid #ccc",
    bottom: 14,
  },
  tab: {
    cursor: "pointer",
    width: "max-content",
    whiteSpace: "nowrap",
    display: "inline-block",
    fontSize: "clamp(1rem, 0.8rem + 0.3333vw, 1.2rem)",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
    zIndex: 1,
    position: "relative",
  },
  activeTab: {
    color: "#1E3B47",
    fontSize: "16px",
    fontWeight: "800",
    cursor: "pointer",
    padding: theme.spacing(0, 2),
    // transition: "all 0.2s",
  },
  normalTab: {
    color: "#aaa",
    fontSize: "16px",
    fontWeight: "400",
    cursor: "pointer",
    padding: theme.spacing(0, 2),
    // transition: "all 0.2s",
  },
  activeDivider: { margin: "8px 0", height: "5px", borderRadius: 100, backgroundColor: theme.palette.primary.main, transition: "all 0.2s" },
  normalDivider: { margin: "10px 0", height: "1px", transition: "all 0.2s", opacity: 0 },
}));

const StatusTab = ({ children }) => children;

const StatusTabs = ({ tabs, activeStatus, setActiveStatus }) => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {isDesktop &&
        tabs.map((tab, index) => (
          <StatusTab key={index}>
            <>
              <div className={classes.tab} onClick={() => setActiveStatus(tab.value)}>
                <Typography className={activeStatus === tab.value ? classes.activeTab : classes.normalTab}>{tab.label}</Typography>
                <Divider className={activeStatus === tab.value ? classes.activeDivider : classes.normalDivider} />
              </div>
              <div className={classes.divider} />
            </>
          </StatusTab>
        ))}

      {!isDesktop && (
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="bookingStatus">Status</InputLabel>
          <Select
            labelId="bookingStatus"
            label="Status"
            id="bookingStatusSelect"
            value={activeStatus}
            onChange={(e) => setActiveStatus(e.target.value)}
          >
            {tabs.map((tab, index) => (
              <MenuItem key={index} value={tab.value}>
                {tab.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
};

export default StatusTabs;
