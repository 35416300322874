import React, { useEffect } from "react";
import { makeStyles, Typography, Checkbox, FormControlLabel, useMediaQuery } from "@material-ui/core";

import useStateWithCallback from "@shared/hooks/useStateWithCallback";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1, 2),
    border: ({ selected }) => `2px solid ${selected ? theme.palette.primary.main : "#B3B3B3"}`,
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxSizing: "border-box",
  },
  materialDescription: {
    alignItems: "center",
    display: "flex",
    flexGrow: 1,
    "& label:first-child": {
      marginRight: 0,
    },
  },
  costIncrease: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    textAlign: "right",
    "& p:first-child": {
      fontWeight: "bold",
    },
    [theme.breakpoints.down("sm")]: {
      width: "20%",
    },
  },
  descriptionText: {
    fontSize: "clamp(0.9rem, 0.8175rem + 0.3187vw, 1.2rem)",
    fontWeight: 800,
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
}));

const Material = ({ material, onSelect, defaultSelected }) => {
  const [selected, setSelected] = useStateWithCallback(defaultSelected);
  const classes = useStyles({ selected });
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleSelectMaterial = () => {
    setSelected(!selected, () => {
      onSelect(material);
    });
  };

  useEffect(() => {
    if (defaultSelected && material) onSelect(material);
  }, []);

  return (
    <div className={classes.root} variant="outlined" onClick={handleSelectMaterial}>
      <div className={classes.materialDescription}>
        <FormControlLabel control={<Checkbox checked={selected} name="checkbox-material" color="primary" />} />
        <Typography variant="body1" className={classes.descriptionText}>
          {material.description}
        </Typography>
      </div>
      <div className={classes.costIncrease}>
        {/* <Typography color="primary">{material.percentage}%</Typography>
        <Typography variant="body2" color="textSecondary">
          added to the total price
        </Typography> */}
      </div>
    </div>
  );
};

export default Material;
