import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { segmentTrack } from "../../helpers/segment";
import { checkFeatureFlag } from "../../store/actions/featureFlags";

const PrivilegedComponent = ({ children, somePrivileges, everyPrivileges, flag, isGuest, track }) => {
  const { uid, email, userType, smsSettings } = useSelector((state) => state.firebase.profile);
  const sessionPrivileges = useSelector((state) => state.auth.privileges);
  const [hasAccess, setHasAccess] = useState(false);
  let subscribed = useRef(false);

  const checkFlag = async () => {
    const data = {
      key: uid ?? "guest",
      custom: {
        market: smsSettings?.address?.city,
        userType: userType ?? "",
        email: email ?? "",
      },
    };
    const isValid = await checkFeatureFlag(flag, data, false);

    if (track) {
      segmentTrack("Feature Flag Check", { flag, value: isValid });
    }

    return isValid;
  };

  const checkAccess = async () => {
    if (flag) {
      const isValid = await checkFlag();
      if (!subscribed.current) return;
      setHasAccess(isValid);
      return;
    }
    let hasAccess = true;
    if (somePrivileges.length) {
      hasAccess = somePrivileges.some((privilege) => sessionPrivileges.includes(privilege) && !privilege.disabled);
    }
    if (everyPrivileges.length) {
      hasAccess = everyPrivileges.every((privilege) => sessionPrivileges.includes(privilege) && !privilege.disabled);
    }
    setHasAccess(hasAccess);
    return;
  };

  useEffect(() => {
    subscribed.current = true;
    checkAccess();
    return () => {
      subscribed.current = false;
    };
  }, []);

  return <React.Fragment>{hasAccess ? children : null}</React.Fragment>;
};

PrivilegedComponent.defaultProps = {
  somePrivileges: [],
  everyPrivileges: [],
  flag: "",
  isGuest: false,
  track: false,
};

export default PrivilegedComponent;
