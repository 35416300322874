import React from "react";
import { Box, Typography, makeStyles, Button } from "@material-ui/core";
import { bookingStatusNoData } from "@constants/booking";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px",
    width: "100%",
    height: "calc(100vh - 280px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  imageStyles: {
    height: "200px",
    width: "300px",
    [theme.breakpoints.down("sm")]: {
      width: "180px",
      height: "150px",
    },
  },
  title: {
    paddingTop: "30px",
    paddingBottom: "10px",
    fontSize: "16px",
    color: "#4F4F4F",
  },
  subtext: {
    fontSize: "14px",
    color: "#828282",
    textAlign: "center",
  },
  actionButton: {
    margin: "20px 0px",
  },
}));

const NoDataCustomers = ({ activeStatus }) => {
  const classes = useStyles();
  const history = useHistory();
  const activeData = bookingStatusNoData[activeStatus];

  const handleClick = () => {
    history.push(activeData?.actionUrl);
  };
  return (
    <Box>
      <Box className={classes.root}>
        <Box className={classes.container}>
          <img src={activeData.image} alt="No Data" className={classes.imageStyles} />
          <Typography className={classes.title}>{activeData.title}</Typography>
          <Typography className={classes.subtext}>{activeData.subtext}</Typography>
          {activeData.actionUrl && (
            <Button variant="contained" color="primary" className={classes.actionButton} onClick={handleClick}>
              {activeData.actionName}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default NoDataCustomers;
