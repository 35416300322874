import React, { useEffect, useMemo, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Card, CardContent, CardMedia, useMediaQuery, Typography } from "@material-ui/core";
import { InView } from "react-intersection-observer";

import dayjs from "dayjs";
import shipment_icon from "@assets/shipment_icon.png";
import junkUrl from "@assets/junk.jpg";
import cardboardUrl from "@assets/cardboard.jpg";
import dumpsterUrl from "@assets/dumpster.jpg";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

import history from "@history";
import { segmentTrack } from "../../../../helpers/segment";
import { useDispatch } from "react-redux";
import { setTaskViewed } from "../../../../store/actions/analytics/getTasks";
import { useTimer } from "../../../../shared/hooks/useTimer";
import { warriorEarlyClaimBonus } from "../../../../constants/booking";
import { WarriorEarlyTaskPrice } from "./WarriorEarlyTaskPrice";

const useStyles = makeStyles((theme) => ({
  "@keyframes fadein": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 100,
    },
  },
  cardRoot: {
    position: "relative",
    borderRadius: theme.spacing(1),
    boxShadow: "0px 4px 20px rgb(0 0 0 / 15%)",
  },
  status: {
    textAlign: "center",
    position: "absolute",
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
  media: {
    height: 260,
    backgroundColor: "#f1f1f1",
  },
  badge: {
    backgroundColor: "white",
    borderRadius: "20px",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    maxWidth: "300px",
    alignItems: "center",
    display: "flex",
    padding: theme.spacing(1, 2),
    fontWeight: 500,
  },
  bonusBadge: {
    backgroundColor: "#ffffff",
    borderRadius: "35px 8px 8px 35px",
    boxShadow: "0px 0px 3px 1px #0000004d",
    color: theme.palette.primary.main,
    position: "absolute",
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    maxWidth: "300px",
    alignItems: "center",
    display: "grid",
    gridTemplateColumns: "max-content auto",
    columnGap: theme.spacing(2),
    padding: theme.spacing(1, 3, 1, 1),
    fontWeight: 500,
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  mainContainer: {
    flexGrow: 1,
    display: "flex",
    marginBottom: theme.spacing(2),
    "& img:first-child": {
      marginRight: theme.spacing(1.5),
      marginTop: theme.spacing(0.7),
    },
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
  },
  cardIcon: {
    paddingRight: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
    fontSize: "1.3rem",
    padding: theme.spacing(0.5, 0),
  },
  bodyText: {
    fontSize: "1rem",
  },
  bonusBadgeAmount: {
    fontWeight: "bold",
  },
  bonusBadgeIcon: {
    height: 40,
    width: 40,
    color: "#E8C032",
  },
  bonusBadgeTimer: {
    textAlign: "left",
    fontSize: "1.2rem",
  },
  amountContainer: {
    top: theme.spacing(1.7),
    right: theme.spacing(3),
    position: "absolute",
    padding: theme.spacing(0.5, 0),
    display: "flex",
  },
  amount: {
    fontSize: "1.3rem",
    fontWeight: "bold",
    color: "#1E3B47",
  },
  strikeAmount: {
    fontSize: "1.3rem",
    fontWeight: "bold",
    color: "#1E3B47",
    textDecoration: "line-through",
    opacity: 0.5,
    marginRight: theme.spacing(1),
  },
  dateTimeContainer: {
    display: "flex",
    color: "grey",
    paddingRight: "20px",
    padding: theme.spacing(0.5, 0),
    "& p:last-child": {
      marginLeft: theme.spacing(2),
    },
  },
  dateTimeText: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: "black",
    padding: theme.spacing(0.5, 0),
  },
  sidebarBox: {
    position: "relative",
    overflowX: "hidden",
    overflowY: "auto",
    cursor: "pointer",
    maxHeight: "200px",
  },
  readMore: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    paddingTop: theme.spacing(8),
    backgroundImage: "linear-gradient(to bottom, transparent, white)",
  },
}));

export const services = {
  "cardboard-removal": "Cardboard Removal",
  "junk-removal": "Waste Removal",
  "dumpster-rental": "Dumpster Rental",
};

export const urlMap = {
  "Waste Removal": junkUrl,
  "Cardboard Removal": cardboardUrl,
  "Dumpster Rental": dumpsterUrl,
};

export const parsePrice = (item, add = 0) => `$${formatPrice((item?.payout_dynamic || item?.payout) + add)}`;
const formatPrice = (valueInCents) => (valueInCents / 100).toFixed(2);
const formateDate = (date) => dayjs(date).format("MM/DD");
const formatTime = (time) => dayjs(time).format("hh:mm A");

const TaskListItem = ({ item, canShowEarlyBonus }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [isCollapsed, setIsCollapsed] = useState(true);

  const isPayoutSurged = () => item.payout_surged > 0;
  const isIn15Minutes = (canShowEarlyBonus && dayjs().diff(dayjs(item.createdAt), "minute") <= 30) || false;
  const bonusAmount = warriorEarlyClaimBonus;

  useEffect(() => {
    if (isIn15Minutes) {
      segmentTrack("Early Claim Available", {
        bookingId: item?.id,
        timeRemaining: 30 - dayjs().diff(dayjs(item.createdAt), "minute"),
      });
    }
  }, []);

  const claimTask = () => {
    if (!item.id) return;
    history.push(`/task_details/${item.id}`);
    segmentTrack("Warrior Clicks Claim This Task - Get Tasks", {
      bookingId: item?.id,
      price: (item?.payout_dynamic || item?.payout) / 100,
      location: item?.address?.location,
      date: formateDate(item.date),
      time: formatTime(item.date),
      description: item.description ?? "",
      isEarlyBonusClick: isIn15Minutes,
    });
  };

  const handleDescriptionClick = (e, item) => {
    e.stopPropagation();
    if (item.description.length > 100) {
      setIsCollapsed(!isCollapsed);
    }
  };

  const handleCardInView = (item) => {
    dispatch(setTaskViewed(item.id));
  };

  const interval = useRef(false);
  const bonusTimer = useTimer(dayjs(item.createdAt).add(30, "minute"), interval, { showSeconds: true, makePretty: false });

  useEffect(() => {
    interval.current = isIn15Minutes;
  }, [isIn15Minutes]);

  return (
    <Card className={classes.cardRoot} key={item.id}>
      <CardMedia className={classes.media} image={item.taskImages?.length ? item.taskImages[0] : urlMap[services[item.task]]} title="Task Image">
        {isPayoutSurged() && !isIn15Minutes && (
          <div className={classes.badge}>
            <TrendingUpIcon color="primary" className={classes.badge} />
            {isDesktop && <div style={{ paddingLeft: "10px" }}>Recent Payout Increase</div>}
          </div>
        )}
        <div className={classes.bonusBadge} style={{ display: isIn15Minutes ? undefined : "none" }}>
          <MonetizationOnIcon color="primary" className={classes.bonusBadgeIcon} />
          <div className={classes.bonusBadgeText}>
            <Typography className={classes.bonusBadgeAmount}>{`$${bonusAmount / 100} Bonus`}</Typography>
            <Typography className={classes.bonusBadgeTimer}>{bonusTimer}</Typography>
          </div>
        </div>
      </CardMedia>
      <CardContent className={classes.cardContent}>
        <div className={classes.mainContainer}>
          <img src={shipment_icon} alt="shipmentIcon" height="20px" width="20px" />
          <div>
            <Typography classes={{ h5: classes.title }} gutterBottom variant="h5" component="h3">
              {services[item.task]}
            </Typography>
            <InView as="div" onChange={(inView) => inView && handleCardInView(item)}>
              <Typography gutterBottom classes={{ body1: classes.bodyText }} variant="body1" color="textSecondary" component="p">
                {item.address.city}, {item.address.zipCode}
              </Typography>
            </InView>
            <div className={classes.dateTimeContainer}>
              <Typography gutterBottom classes={{ body1: classes.bodyText }} variant="body1" color="textSecondary" component="p">
                Date: <span className={classes.dateTimeText}>{formateDate(item.date)}</span>
              </Typography>
              <Typography gutterBottom classes={{ body1: classes.bodyText }} variant="body1" color="textSecondary" component="p">
                Time: <span className={classes.dateTimeText}>{formatTime(item.date)}</span>
              </Typography>
            </div>
            <WarriorEarlyTaskPrice item={item} applyBonus={isIn15Minutes} />
            <div onClick={(e) => handleDescriptionClick(e, item)} className={classes.sidebarBox}>
              <Typography variant="body1" component="p" classes={{ body1: classes.bodyText }} color="textSecondary">
                {isCollapsed ? item.description.slice(0, 100) : item.description}
              </Typography>
              {isCollapsed && item.description.length > 100 && <div className={classes.readMore} />}
            </div>
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button fullWidth variant="contained" color="primary" className={classes.cardButton} onClick={claimTask}>
            Claim this task
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default TaskListItem;
