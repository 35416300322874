import React from "react";
import classnames from "classnames";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import Spacer from "@shared/components/Spacer";

import history from "@history";
import { getLinkWithGclid } from "../../history";
import { KustomerLauncher } from "../KustomerLauncher";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    height: "80%",
    width: "250px",
  },
  book: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.hover,
    },
  },
  signUp: {
    cursor: "pointer",
    fontWeight: 500,
  },
}));

const Buttons = () => {
  const { root, button, book, helpCenter, signUp } = useStyles();
  const profile = useSelector((state) => state.firebase.profile);

  return (
    <Box className={root}>
      <Button variant="contained" className={classnames(button, book)} onClick={() => history.push(getLinkWithGclid("/booking"))}>
        Book Now
      </Button>
      <Spacer x={8} />
      <KustomerLauncher color="inherit" variant="outlined">
        <span onClick={() => history.push(getLinkWithGclid("/help"))}>Help Center</span>
      </KustomerLauncher>
      <Spacer x={8} />
      {!profile?.uid && (
        <Typography className={signUp} onClick={() => history.push(getLinkWithGclid("/auth/register"))}>
          Sign up
        </Typography>
      )}
    </Box>
  );
};

export default Buttons;
