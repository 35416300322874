import { Help } from "@material-ui/icons";
import React from "react";
import BuyerIcon from "../assets/icons/buyer.svg";
import DonationIcon from "../assets/icons/donation.svg";
import LandfillIcon from "../assets/icons/landfill.svg";
import RecycleIcon from "../assets/icons/recycle.svg";

export const dumpSiteTypes = {
  ["direct"]: {
    value: "direct",
    label: "Direct Buyer",
    applyTreatments: [],
    customerLabel: "Direct Buyer (paper mill, store, etc)",
    icon: <BuyerIcon />,
    required: {
      weight: true,
      receipt: true,
      cost: true,
    },
  },
  ["donation"]: {
    value: "donation",
    label: "Donation Center",
    applyTreatments: ["donated"],
    customerLabel: "Donation Center",
    icon: <DonationIcon />,
    required: {
      weight: true,
      receipt: true,
      cost: true,
    },
  },
  ["recycle"]: {
    value: "recycle",
    label: "Recycle Center",
    applyTreatments: ["recycled"],
    customerLabel: "Recycle Center",
    icon: <RecycleIcon />,
    required: {
      weight: true,
      receipt: true,
      cost: true,
    },
  },
  ["landfill"]: {
    value: "landfill",
    label: "Landfill",
    applyTreatments: ["buried"],
    customerLabel: "Landfill",
    icon: <LandfillIcon />,
    required: {
      weight: true,
      receipt: true,
      cost: true,
    },
  },
  ["other"]: {
    value: "other",
    label: "Other",
    applyTreatments: [],
    customerLabel: "Other",
    icon: <Help style={{ color: "#ccc" }} />,
    required: {
      weight: true,
      receipt: false,
      cost: true,
    },
  },
};
export const dumpSiteTreatments = {
  ["buried"]: {
    value: "buried",
    label: "Bury",
    customerLabel: "Buried",
    process: "buried",
  },
  ["donated"]: {
    value: "donated",
    label: "Donate",
    customerLabel: "Donated",
    process: "donated",
  },
  ["recycled"]: {
    value: "recycled",
    label: "Recycle",
    customerLabel: "Recycled",
    process: "recycled",
  },
  ["other"]: {
    value: "other",
    label: "Other",
    customerLabel: "Not sure",
    process: "dropped off",
  },
};

export const wasteStreams = [
  "Bulky furniture",
  "Cardboard",
  "Compost",
  "Concrete and Drywall",
  "E-Waste",
  "Glass",
  "Metal",
  "Plastics",
  "Solid waste",
  "Textile",
  "Wood",
];
