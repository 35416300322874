import React, { useState } from "react";
import PropTypes from "prop-types";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import { makeStyles, IconButton, Menu, MenuItem, Box } from "@material-ui/core";
import cn from "classnames";

const ITEM_HEIGHT = 48;

const useStyles = makeStyles(() => ({
  menuItem: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: "10px",
    "& .MuiSvgIcon-root": {
      verticalAlign: "text-bottom",
    },
  },
}));

const Dropdown = ({ options, style, onClick }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    // const optionsLength = options.filter((item) => !item?.isHidden).length;
    // if (optionsLength === 0) return;
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    onClick(event);
  };

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick} style={style}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 600,
            minWidth: "20ch",
          },
        }}
      >
        {options
          .filter((item) => !item?.isHidden)
          .map(({ label, labelStyle, icon, onClick, className, isHidden, isDisabled, ...rest }) => (
            <MenuItem
              key={label}
              disabled={!!isDisabled}
              onClick={(e) => onClick(e, handleClose)}
              {...rest}
              className={cn(classes.menuItem, className)}
            >
              <Box className={classes.icon}>{icon}</Box>
              <Box style={labelStyle}>{label}</Box>
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};

Dropdown.defaultProps = {
  onClick: () => {},
};

Dropdown.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    })
  ),
};

export default Dropdown;
