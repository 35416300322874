import React, { useCallback, useState, forwardRef } from "react";
import { useSelector } from "react-redux";
import { Box, makeStyles } from "@material-ui/core";

import useMountedEffect from "@shared/hooks/useMountedEffect";

import { updateField, getLowestItemPrice } from "@booking/helpers/utils";
import useTriggerValidation from "@shared/hooks/useTriggerValidation";
import { Input } from "@booking/helpers/enums";

import TaskField from "@booking/helpers/fields/TaskField";
import ServiceableField from "@booking/helpers/fields/Serviceable";
import NumberService from "@shared/services/NumberService";
import { pushToSegment } from "@helpers/segment";

import { services } from "@booking/helpers/apiData";
import { taskTypes } from "@constants/booking";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-end",
    marginTop: theme.spacing(5),
  },
  address: {
    [theme.breakpoints.up("md")]: {
      minWidth: 280,
    },
    minWidth: "100%",
  },
  businessClient: {
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(8),
    },
    marginTop: theme.spacing(4),
  },
}));

const InitialForm = forwardRef(({ defaultValue, onChange, isBidMode }, ref) => {
  const styles = useStyles();
  const priceMultiplier = useSelector((state) => state.serviceLocations.activeLocation.priceMultipliers ?? 100);
  const lowestPrices = useSelector((state) => state.tempData.lowestPrices);
  const dumpsterBasePrice = useSelector((state) => state.tempData.dumpsterBasePrices);

  const [taskField, setTaskField] = useState(defaultValue[Input.Task]);
  const [addressField, setAddressField] = useState(defaultValue[Input.PickupAddress]);

  const taskPrice = NumberService.formatToCurrency(
    (services[taskField.value].price + getLowestItemPrice(taskField.value, lowestPrices, dumpsterBasePrice)) * (priceMultiplier / 100)
  );

  useMountedEffect(() => {
    onChange({
      [Input.Task]: taskField,
      [Input.PickupAddress]: addressField,
    });
  }, [addressField, taskField]);

  useMountedEffect(() => {
    if (addressField.value?.location && taskField.value) {
      pushToSegment("Change Location Pricing", {
        location: addressField.value.location,
        taskType: taskTypes[taskField.value].label,
        adjustedPrice: taskPrice,
      });
    }
  }, [taskField.value, addressField.value, priceMultiplier]);

  useTriggerValidation(ref, [setTaskField, setAddressField]);

  const handleTaskChange = useCallback(
    (field) => {
      setTaskField(updateField(taskField, field));
    },
    [taskField]
  );

  const handleAddressChange = useCallback(
    (field) => {
      setAddressField(updateField(addressField, field));
    },
    [addressField]
  );

  return (
    <Box>
      <TaskField field={taskField} onChange={handleTaskChange} isBidMode={isBidMode} />
      <Box className={styles.container}>
        <Box className={styles.address}>
          <ServiceableField validate label="Enter your ZIP code" task={taskField.value} field={addressField} onChange={handleAddressChange} />
        </Box>
      </Box>
    </Box>
  );
});

export default React.memo(InitialForm);
