import { useEffect } from "react";
import { checkFeatureFlag } from "../../store/actions/featureFlags";

export const useLDChecker = ({ flag, dependencies, profile, callback, run, privilege = null, sessionPrivileges = null }) => {
  const check = async () => {
    let isValid = true;
    if (privilege) {
      isValid = sessionPrivileges.includes(privilege);
    }
    if (!isValid) {
      callback(false);
      return;
    }
    const session = sessionStorage.getItem(`ld-flag-${flag}`);
    if (session) {
      callback(session === "true" ? true : false);
      return;
    }
    const query = {
      key: profile?.uid ?? "",
      custom: {
        market: profile?.smsSettings?.address?.city,
        email: profile?.email ?? "",
        userType: profile?.userType ?? "",
      },
    };
    isValid = await checkFeatureFlag(flag, query, false);
    sessionStorage.setItem(`ld-flag-${flag}`, isValid.toString());
    callback(isValid);
  };
  useEffect(() => {
    if (run) {
      check();
    }
  }, [...dependencies, run]);
};
