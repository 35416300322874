import axios from "axios";
import { toastr } from "react-redux-toastr";
import { getAppCheckToken } from "./helpers/appCheck";

const handleSuccessResponse = (response) => {
  return response;
};

const handleErrorResponse = (error) => {
  if (error.response?.status === 401) {
    // toastr.error("Error", error.response?.data?.error);
  } else if (error.response?.data?.errors) {
    error.response.data.errors.forEach((error) => {
      // toastr.error("Error", error);
    });
  } else {
    // toastr.error("Error", error.response?.data?.error || error.message);
  }
  return Promise.reject(error);
};

const registerResponseIntercept = () => {
  axios.interceptors.response.use(handleSuccessResponse, (error) => handleErrorResponse(error));
};

export const getAxiosAppCheckHeaders = async () => {
  const appCheckToken = await getAppCheckToken();
  if (!appCheckToken) {
    return null;
  } else {
    return {
      Accept: "applicaion/json",
      "Content-Type": "application/json",
      "X-Firebase-AppCheck": appCheckToken,
    };
  }
};

export const useAxiosGet = async (uri, customHeaders) => {
  let headers = await getAxiosAppCheckHeaders();
  if (customHeaders) {
    headers = {
      ...customHeaders,
      ...headers,
    };
  }
  try {
    const response = await axios.get(uri, { headers: headers });
    return response;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

export const useAxiosPost = async (uri, form, customHeaders) => {
  let headers = await getAxiosAppCheckHeaders();
  if (customHeaders) {
    headers = {
      ...customHeaders,
      ...headers,
    };
  }
  try {
    const response = await axios.post(uri, form, { headers: headers });
    return response;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

export const configureAxios = async () => {
  axios.defaults.baseURL = "/api";
  axios.defaults.headers = {
    Accept: "applicaion/json",
    "Content-Type": "application/json",
  };
  registerResponseIntercept();
};
