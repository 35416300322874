/* eslint-disable no-console*/
import { useAxiosPost } from "@axios";
import { toastr } from "react-redux-toastr";

export const verifyMobileNumber = async (phoneNumber) => {
  try {
    const response = await useAxiosPost("/twilio/get-phone-carrier", { phoneNumber });
    return response.data;
  } catch (error) {
    console.log("Error verifying mobile number", error);
  }
};

export const sendVerifyToken = async (phoneNumber) => {
  try {
    const response = await useAxiosPost("/twilio/send-verification-token", { phoneNumber });
    if (response?.data?.success) {
      return response.data;
    }
    throw response?.data?.error ?? "Something went wrong";
  } catch (error) {
    console.log("Error sending verification token", error);
    toastr.warning("Error sending verification token");
    return null;
  }
};

export const checkVerifyToken = async (phoneNumber, code) => {
  try {
    const response = await useAxiosPost("/twilio/check-verification-token", { phoneNumber, code });
    if (response?.data?.success) {
      return response.data;
    }
    throw response?.data?.error ?? "Something went wrong";
  } catch (error) {
    console.log("Error checking verification token", error);
    toastr.warning("Error verifying otp");
    return null;
  }
};
