import React from "react";
import Modal from "@shared/components/Modal";
import ProviderStreakFrame from "@assets/provider-streak-frame.svg";
import ProviderStreakGroup from "@assets/provider-streak-group.svg";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: theme.spacing(55),
    minHeight: theme.spacing(32),
    zIndex: 10000,
    "& h6": {
      textAlign: "center",
    },
  },
}));

const WarriorStreakContent = ({ onClose }) => {
  const history = useHistory();
  return (
    <div>
      <div style={{ position: "relative", padding: "-16px" }}>
        <img src={ProviderStreakFrame} alt="ProviderStreakFrame" width="100%" style={{ position: "absolute", top: "0" }} />
      </div>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
        <img src={ProviderStreakGroup} alt="ProviderStreakGroup" style={{ paddingTop: "40px" }} />
        <span style={{ fontSize: "30px", color: "#4F4F4F", fontWeight: "800", padding: "20px" }}>
          We have new streak bonuses for Providers this month!
        </span>
        <span style={{ fontSize: "16px", color: "#4F4F4F", padding: "0 40px" }}>
          For this month only, when you complete 4 jobs in a week and provide excellent service, you will receive a $30 bonus.
          <br /> Thank you to our loyal Providers!
        </span>
        <Grid container spacing={2} style={{ margin: "20px", display: "flex", justifyContent: "space-between" }}>
          <Grid item xs={12} md={6}>
            <Button variant="outlined" onClick={onClose} style={{ width: "100%" }}>
              Got it
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button variant="contained" color="primary" onClick={() => history.push("/account")} style={{ width: "100%" }}>
              View my streak
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const WarriorStreakModal = ({ onClose, ...modalProps }) => {
  const classes = useStyles();
  return <Modal {...modalProps} header={<WarriorStreakContent onClose={onClose} />} />;
};

export default WarriorStreakModal;
