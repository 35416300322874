import React from "react";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    overflowX: "scroll",
  },
  image: {
    [theme.breakpoints.up("md")]: {
      flex: ({ size }) =>
        ({
          small: "0 0 100px",
          medium: "0 0 120px",
          large: "0 0 140px",
          xlarge: "0 0 200px",
        }[size]),
    },
    cursor: "pointer",
    margin: theme.spacing(2),
    whiteSpace: "no-wrap",
    alignSelf: "center",
    flex: "0 0 80px",
  },
  imageBox: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
    columnGap: 20,
    width: "auto",
    maxWidth: "100%",
    justifyContent: "flex-start",
  },
  imageHorizontal: {
    height: "100%",
    width: "100%",
  },
  imageInGrid: {
    height: 200,
    width: "100%",
    maxWidth: 350,
    objectFit: "cover",
    marginBottom: 20,
    borderRadius: 2,
    cursor: "pointer",
    "&:hover": {
      opacity: 0.5,
    },
  },
}));

const Gallery = ({ size, images, onSelect, grid }) => {
  const styles = useStyles({ size });

  return (
    <Box className={grid ? styles.imageBox : styles.root}>
      {images.map((image, idx) => (
        <Box key={idx} className={grid ? undefined : styles.image} onClick={() => onSelect(idx)}>
          <img src={image} className={grid ? styles.imageInGrid : styles.imageHorizontal} alt={`Gallery-image${idx}`} />
        </Box>
      ))}
    </Box>
  );
};

Gallery.defaultProps = {
  size: "small",
};

Gallery.propTypes = {
  onSelect: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
  size: PropTypes.oneOf(["small", "medium", "large", "xlarge"]),
};

export default Gallery;
