import React from "react";
import classnames from "classnames";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  hideMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hideDesktop: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const Hidden = ({ className, display, desktop, mobile, children }) => {
  const styles = useStyles();
  const boxClassName = classnames(desktop ? styles.hideDesktop : null, mobile ? styles.hideMobile : null, className);

  return (
    <Box className={boxClassName} display={display}>
      {children}
    </Box>
  );
};

Hidden.defaultProps = {
  desktop: false,
  mobile: false,
};

export default Hidden;
