import React, { useState, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import routes from "../routes";
import MainPageContextProvider from "./context";
import Header from "./HeaderV2";
import Footer from "./FooterV2";
import { loadStripe } from "@stripe/stripe-js";
import { renderComponent } from "../helpers/renderPage";
import classes from "@css/components/DefaultLayout.module.css";
import classNames from "classnames";
import { segmentIdentify } from "../helpers/segment";
import { fetchWarriorSubmittedBids } from "../store/actions/bookingBids";
import BonusSuccessModal from "../views/CompleteTask/components/BonusSuccessModal";
import { useLDChecker } from "../shared/hooks/useLDChecker";
import { fetchMyBonusPayouts } from "../store/actions/bonuses";
import { ModalScroller } from "./ModalScroller";
import { loadServiceAreasClient } from "../store/actions/locations";
import { loadHelloBar } from "../scripts";
import { isProduction } from "../constants/environments";
import history from "../history";
import SignOutConfirm from "../shared/components/SignOutConfirm";
import useSetDocTitle from "../shared/hooks/useSetDocTitle";
import { signInWithCustomToken } from "../store/actions/auth";
import { fetchConfig } from "../store/actions/config";
import { fetchLowestPrices } from "../store/actions/items";
import loaderConstants from "../store/constants/loadingTypes";
import { setGCLID } from "../store/actions/trackers";
import { fetchAggregates } from "../store/actions/aggregates";
import { bookingBidTypes } from "../store/constants/bookingQuoteTypes";
import { Elements } from "@stripe/react-stripe-js";
import { Kustomer } from "../helpers/kustomer";

const LANDING_PAGE_ROUTES = ["/", "/provider_portal", "/b2b-solutions", "/about_us", "/sustainability"];

const ROUTES: any = routes;

const hashRouterInterceptor = () => {
  const url = new URL(window.location.href);
  if (url.href.includes("/#/")) {
    const newUrl = url.hash.replace("#/", "/");
    history.replace(newUrl);
  }
};

function DefaultLayout() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [bonusModal, setBonusModal] = useState(false);
  let isDimension = LANDING_PAGE_ROUTES.includes(new URL(window.location.href).pathname);

  const sessionPrivileges = useSelector((state: any) => state.auth.privileges);
  const isLoggedIn = useSelector((state: any) => state.firebase.auth?.uid);
  const profile = useSelector((state: any) => state.firebase.profile);
  const isAdmin = useSelector((state: any) => state.auth.isAdmin);
  const { showSignOutModal = false } = useSelector((state: any) => state.tempData);

  const isWarrior = profile?.userType === "provider";

  const stripePk: any = process.env.REACT_APP_STRIPE_PK;
  const [stripePromise] = useState<any>(() => loadStripe(stripePk));

  useSetDocTitle(); // update document title on page change

  const getParams = (search: string) => {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.get(search);
  };

  const loadEssentials = async () => {
    const signInWithJWT = () => {
      const jwt = getParams("jwt");
      if (jwt) dispatch(signInWithCustomToken(jwt));
    };
  
    // jwt sign in
    signInWithJWT();
    dispatch(fetchConfig());
    dispatch(fetchLowestPrices());
    if (isWarrior) {
      dispatch(loadServiceAreasClient());
      dispatch(fetchWarriorSubmittedBids());
    } else {
      dispatch({ type: bookingBidTypes.SET_WARRIOR_BIDS, payload: [] });
    }
    dispatch({ type: loaderConstants.RESET_LOADING });
    if (!isAdmin) {
      dispatch(fetchAggregates());
      if (isProduction) {
        loadHelloBar();
      }
    }
  };

  Kustomer.useKustomer(getParams, isLoggedIn);

  useEffect(() => {
    isDimension = LANDING_PAGE_ROUTES.includes(new URL(window.location.href).pathname);
  }, [window.location.href]);

  useEffect(() => {
    dispatch({ type: loaderConstants.SET_LOADING, isLoading: true, loaderType: "spinner" });
    // eslint-disable-next-line
    console.log(`%c env: ${process.env.REACT_APP_ENV}`, "color: #3be5ff");
    hashRouterInterceptor();

    const getGoogleClickIdentifier = () => {
      let gclId = getParams("gclid");
      if (!gclId) {
        const url = window.location;
        if (url.href.includes("gclid=")) {
          gclId = url.search.split("=")[1];
        }
      }
      return gclId;
    };
    // gclid tracking
    const gclIdentifier = getGoogleClickIdentifier();
    if (gclIdentifier) {
      dispatch(setGCLID(gclIdentifier));
      sessionStorage.setItem("gclid-track", gclIdentifier);
    }
    loadEssentials();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      const identified = sessionStorage.getItem("segment-identified");
      if (identified === "true") return;
      segmentIdentify();
      sessionStorage.setItem("segment-identified", "true");
    }
  }, []);

  const handleBonuses = (isValid: boolean) => {
    if (isValid) {
      dispatch(fetchMyBonusPayouts(profile?.uid));
      setBonusModal(true);
    }
  };

  useLDChecker({ flag: "warrior-bonuses", dependencies: [], profile, callback: (isValid: boolean) => handleBonuses(isValid), run: isWarrior });

  const MainLayoutClasses = classNames({
    [classes.mainLayout]: !isDimension,
    [classes.mainLayoutNew]: isDimension,
  });

  if (showSignOutModal) return <SignOutConfirm />;

  return (
    <MainPageContextProvider>
      <Header opaque={!isDimension} />
      <div className={MainLayoutClasses} id={"app-scroller"}>
        {stripePromise && (
          <Elements stripe={stripePromise}>
            <React.Fragment>
              <Switch>
                {ROUTES.map((route: any, idx: number) =>
                  route.component ? (
                    <Route key={idx} path={route.path} exact={route.exact ?? undefined} render={renderComponent(route, sessionPrivileges, profile)} />
                  ) : null
                )}
              </Switch>
            </React.Fragment>
          </Elements>
        )}
      </div>
      <Footer />
      {isWarrior && bonusModal && <BonusSuccessModal />}
      <ModalScroller />
    </MainPageContextProvider>
  );
}

export default DefaultLayout;
