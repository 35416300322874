import React, { useEffect } from "react";

import validators from "@booking/helpers/validators";
import { isError, errorCode } from "@booking/helpers/utils";
import DebouncedTextField from "@shared/components/DebouncedTextField";
import { Input } from "@booking/helpers/enums";

import Section from "@booking/components/Section";
import useStyles from "./useStyles";

const DescriptionFieldComponent = ({ field, onChange, description, rows, width, minChars = 0 }) => {
  const { value, validation } = field;
  const classes = useStyles();

  useEffect(() => {
    onChange({ validation: validators[Input.Description](field, minChars) });
  }, [value]);

  const handleChange = (nextValue) => {
    onChange({ value: nextValue });
  };

  const label = minChars ? `Please describe the items you need to remove (${minChars} characters or more)` : "Please describe the items you need to remove";

  return (
    <div className={classes.field}>
      <Section title={label} width={width}>
        <DebouncedTextField
          multiline
          fullWidth
          required
          minRows={rows}
          value={value}
          placeholder={description}
          variant="outlined"
          onChange={handleChange}
          error={isError(validation)}
          helperText={errorCode(validation)}
        />
      </Section>
    </div>
  );
};

DescriptionFieldComponent.defaultProps = {
  description: "",
  rows: 3,
  width: "100%",
};

export default React.memo(DescriptionFieldComponent);
