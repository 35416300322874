import React, { useState, useEffect } from "react";
import { makeStyles, Box } from "@material-ui/core";
import { Route, Switch } from "react-router";
import { BooleanParam, useQueryParam } from "use-query-params";

import url from "@assets/withdraw_task.svg";
import { useSelector, useDispatch } from "react-redux";
import Avatar from "./components/Avatar";
import { updateProfile, fetchMyGuestAccount, linkMyAccounts } from "@store/actions/users";
import { fetchReviews } from "@store/actions/reviews";
import resetOrigin from "@shared/utils/resetOrigin";
import LinkIcon from "@material-ui/icons/Link";

import Provider from "./Provider";
import Customer from "./Customer";
import Reviews from "./components/Reviews";
import { toastr } from "react-redux-toastr";
import { roles } from "../../constants/roles";
import Button from "../../shared/components/Button";
import { scrollToTop } from "../../methods/scrollToTop";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(10),
    },
    minHeight: "100vh",
  },
  bannerBg: {
    backgroundColor: theme.palette.primary.hover,
    height: "200px",
  },
  bannerImage: {
    backgroundImage: `url(${url})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "right",
    width: "100%",
    height: "100%",
    opacity: "0.6",
  },
  detailsContainer: {
    marginTop: "10vh",
    [theme.breakpoints.up("lg")]: {
      marginTop: "5vh",
    },
  },
  linkBtn: {
    height: "2rem",
    margin: "2rem",
  },
}));

export const TopBanner = () => {
  const classes = useStyles();
  return (
    <Box className={classes.bannerBg}>
      <Box className={classes.bannerImage} />
    </Box>
  );
};

const ProfileInfo = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [guestAccount, setGuestAccount] = useState(null);
  const [stripe_connected] = useQueryParam("stripe_connected", BooleanParam);

  const profile = useSelector((state) => state.firebase.profile);
  const isProvider = profile.userType === roles.provider.value;
  const isCustomer = profile.userType === roles.customer.value;

  const stripeConnected = async () => {
    resetOrigin();
    !profile.stripeConnected && (await dispatch(updateProfile({ stripeConnected: true })));
    toastr.success("Success", "Connected to Stripe");
  };

  const checkGuestAccountLinking = async () => {
    const myGuestAccount = await fetchMyGuestAccount(profile.email);
    // if already linked, skip
    if (myGuestAccount && !myGuestAccount.linkedAt) setGuestAccount(myGuestAccount);
  };

  const connectAccounts = async () => {
    if (!guestAccount.id) return;
    const isLinked = await linkMyAccounts(guestAccount.id, profile.uid);
    isLinked && setGuestAccount(null);
  };

  scrollToTop();

  useEffect(() => {
    if (isProvider) {
      stripe_connected && stripeConnected();
      dispatch(fetchReviews(profile.uid));
    }
  }, [isProvider, stripe_connected]);

  useEffect(() => {
    if (isCustomer) checkGuestAccountLinking();
  }, [isCustomer]);

  const ActiveProfile = isProvider ? Provider : Customer;

  const disabledLink = true;
  return (
    <Box className={classes.rootContainer}>
      <TopBanner />
      <Box className={classes.root}>
        <Avatar profile={profile} />
        <Box className={classes.detailsContainer}>
          <ActiveProfile />
        </Box>
      </Box>
      {guestAccount && !disabledLink && (
        <Button variant="contained" className={classes.linkBtn} onClick={connectAccounts}>
          <LinkIcon color="primary" />
          Link Account
        </Button>
      )}
    </Box>
  );
};

const AccountPage = () => {
  const profile = useSelector((state) => state.firebase.profile);
  const isProvider = profile.userType === roles.provider.value;

  return (
    <Box>
      <Switch>
        {isProvider && <Route path="/account/reviews" component={Reviews} />}
        <Route path="/account" component={ProfileInfo} />
      </Switch>
    </Box>
  );
};

export default React.memo(AccountPage);
