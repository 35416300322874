const radioHelperValues = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const vehicleTypes = {
  truck: {
    label: "Truck",
    value: "truck",
  },
  "pick-up-truck": {
    label: "Pick - Up Truck",
    value: "pick-up-truck",
  },
  "closed-van": {
    label: "Closed Van",
    value: "closed-van",
  },
  "box-truck": {
    label: "Box Truck",
    value: "box-truck",
  },
  "dump-truck": {
    label: "Dump Truck",
    value: "dump-truck",
  },
  trailer: {
    label: "Trailer",
    value: "trailer",
  },
  dumpster: {
    label: "Dumpster",
    value: "dumpster",
  },
};

const vehicleList = [
  {
    ...vehicleTypes["closed-van"],
    question: "How many closed van(s) do you have?",
  },
  {
    ...vehicleTypes["pick-up-truck"],
    question: "How many pick - up truck(s) do you have?",
  },
  {
    ...vehicleTypes["box-truck"],
    question: "How many dumpster(s) do you have?",
  },
  { label: "Dump Truck", value: "dump-truck", question: "" },
  {
    ...vehicleTypes["trailer"],
    question: "How many trailer(s) do you have?",
    sizeOptions: [
      { label: "5-10 cubic yards", value: "5-10" },
      { label: "10-20 cubic yards", value: "10-20" },
      { label: "20-30 cubic yards", value: "20-30" },
    ],
  },
  {
    ...vehicleTypes["dumpster"],
    question: "How many dumpster(s) do you have?",
    sizeOptions: [
      { label: "5 cubic yards", value: "5" },
      { label: "10 cubic yards", value: "10" },
      { label: "20 cubic yards", value: "20" },
      { label: "30 cubic yards", value: "30" },
      { label: "40 cubic yards", value: "40" },
    ],
  },
];

export { radioHelperValues, vehicleList, vehicleTypes };
