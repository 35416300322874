import React from "react";

import { makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";

import NumberService from "@shared/services/NumberService";

import dumpsterSmall from "@assets/dumpster-small.svg";
import dumpsterLarge from "@assets/dumpster-large.svg";
import { calculateMaterialPercentage } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  dumpsterCardContainer: {
    width: "100%",
    display: "flex",
    alignItems: "start",
    marginBottom: theme.spacing(2),
    boxSizing: "border-box",
    padding: theme.spacing(2),
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: "10px",
  },
  dumpsterCardContents: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  dumpsterCardHeader: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    marginLeft: theme.spacing(2),
    "& h2:first-child": {
      lineHeight: 1.7,
      flexGrow: 1,
    },
  },
  materialsList: {
    display: "grid",
    gridTemplateColumns: "auto max-content",
    margin: theme.spacing(1, 5, 0, 2),
    columnGap: 8,
  },
  flexCenterBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  description: {
    fontSize: 14,
    marginLeft: theme.spacing(2),
    fontWeight: 700,
  },
  close: {
    marginLeft: theme.spacing(2),
    color: "#E0E0E0",
    cursor: "pointer",
    "& :hover": {
      color: theme.palette.primary.main,
    },
  },
  materials: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      padding: theme.spacing(6),
      background: "#FFF",
      inset: 0,
      zIndex: 99,
    },
  },
}));

function DumpsterViewCard({ item, materials, hideMaterials, handleRemoveProduct }) {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  const priceMultiplier = useSelector((state) => state.serviceLocations.activeLocation.priceMultipliers);

  const getItemImage = (size) =>
    ({
      small: dumpsterSmall,
      large: dumpsterLarge,
    }[size]);

  const defaultPrice = item.isLockedPriceItem ? item.prePrice * (priceMultiplier / 100) : item.price * (priceMultiplier / 100);
  const percentage = item.isLockedPriceItem ? 0 : calculateMaterialPercentage(item?.items ?? []);
  const displayPrice = item.price * (priceMultiplier / 100) + (item.price * percentage) / 100;
  const difference = displayPrice - defaultPrice;

  return (
    <div className={classes.dumpsterCardContainer}>
      <img src={getItemImage(item.size)} alt="" />
      <div className={classes.dumpsterCardContents}>
        <div className={classes.dumpsterCardHeader}>
          <Typography variant="h5" component="h2">
            {item.description}
          </Typography>
          <div className={classes.flexCenterBetween}>
            <Typography variant="body1">
              <b>{NumberService.formatToCurrency(displayPrice)}</b>
            </Typography>
            <CloseIcon className={classes.close} onClick={handleRemoveProduct} />
          </div>
        </div>
        {!hideMaterials && (
          <>
            {materials?.map((material, index) => (
              <div key={material.id} className={classes.materialsList}>
                <Typography variant="body1" component="h3">
                  {material.description}
                </Typography>
                {index === 0 && difference > 0 && (
                  <Typography style={{ opacity: 0.7 }}>
                    {isMobile ? "+" : "added"} {NumberService.formatToCurrency(difference)}
                  </Typography>
                )}
              </div>
            ))}
            {/* <div className={classes.materialsList}>
              <Typography variant="body1" component="h3">
                Total price
              </Typography>
              <Typography style={{ opacity: 0.7 }}>
                <b>{NumberService.formatToCurrency(displayPrice)}</b>
              </Typography>
            </div> */}
          </>
        )}
      </div>
    </div>
  );
}

export default DumpsterViewCard;
