import React from "react";

import ProfileDetails from "./components/ProfileDetails";

const Customer = () => {
  return <ProfileDetails isCustomer />;
};

Customer.propTypes = {};

export default Customer;
