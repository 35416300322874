import React from "react";
import { Button, Typography } from "@material-ui/core";
import NumberService from "../../../../shared/services/NumberService";
import classes from "@css/components/BookingPreview.module.css";

type BottomBarProps = {
  loading: boolean;
  consolidatedTotal: number;
  disableNext: boolean;
  showDetails: boolean;
  isCurrentQuoteConfirmed: boolean;
  setShowDetails: Function;
  handleContinue: any;
  handleConfirmItem: any;
};

function BottomBar({
  loading,
  consolidatedTotal,
  disableNext,
  isCurrentQuoteConfirmed,
  showDetails,
  setShowDetails,
  handleContinue,
  handleConfirmItem,
}: BottomBarProps) {
  return (
    <div className={classes.bottomBar}>
      {showDetails && (
        <>
          <Button fullWidth style={{ marginRight: "16px" }} onClick={() => setShowDetails(false)} variant="outlined" color="primary">
            Go back
          </Button>
          <Button fullWidth onClick={handleConfirmItem} disabled={loading} variant="contained" color="primary">
            {isCurrentQuoteConfirmed ? "Unc" : "C"}onfirm Items
          </Button>
        </>
      )}
      {!showDetails && (
        <>
          <div>
            <strong>Total</strong>
            <Typography variant="h5" color="primary">
              {NumberService.formatToCurrency(consolidatedTotal)}
            </Typography>
          </div>
          <Button style={{ width: "40%" }} onClick={handleContinue} disabled={loading || disableNext} variant="contained" color="primary">
            Next
          </Button>
        </>
      )}
    </div>
  );
}

export default BottomBar;
