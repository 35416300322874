import React from "react";

const postFields = "id,slug,excerpt,title,jetpack_featured_media_url,content,date_gmt,categories";
const postSummaryFields = "id,slug,excerpt,title,categories,jetpack_featured_media_url,date_gmt";

export const getWordpressPost = async (post: string, setter: any, subscribed: React.MutableRefObject<boolean>) => {
  fetch(`https://staging.trashwarrior.com/wp-json/wp/v2/posts/${post}?_fields=${postFields}`)
    .then((result) => result.json())
    .then((data) => subscribed.current && setter(data))
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
    });
};

export const getWordpressPostBySlug = async (post: string, setter: any, subscribed: React.MutableRefObject<boolean>) => {
  fetch(`https://staging.trashwarrior.com/wp-json/wp/v2/posts?slug=${post}&&_fields=${postFields}`)
    .then((result) => result.json())
    .then((data) => subscribed.current && setter(data[0]))
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
    });
};

export const getWordpressPostList = async (category: string, setter: any, subscribed: React.MutableRefObject<boolean>): Promise<void> => {
  fetch(`https://staging.trashwarrior.com/wp-json/wp/v2/posts?_fields=${postSummaryFields}&&per_page=100&categories=${category}`)
    .then((result) => result.json())
    .then((data: Array<any>) => {
      if (!subscribed.current) return;
      setter(data);
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
      return [];
    });
};
