import React from "react";
import { useSelector } from "react-redux";
import { Box, IconButton, makeStyles, Paper, Typography } from "@material-ui/core";
import { AddCircle, RemoveCircle } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
    background: "#f2f2f2",
    padding: theme.spacing(0, 2),
    boxSizing: "border-box",
  },
  topline: {
    width: "100%",
    display: "flex",
  },
  description: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    maxWidth: 200,
    margin: "5px 3px",
  },
  priceDescription: {
    flex: 1,
    display: "flex",
    marginTop: "10px",
    alignItems: "top",
    justifyContent: "center",
  },
  detailsContainer: {
    marginTop: theme.spacing(0),
    opacity: 0.8,
    paddingLeft: "3px",
    fontSize: "0.9rem",
    paddingBottom: theme.spacing(1),
  },
}));

const ProductInput = ({ item, quantity, onChange, disabled = false, priceLoader = false }) => {
  const styles = useStyles();
  const priceMultiplier = useSelector((state) => state.serviceLocations.activeLocation.priceMultipliers / 100) || 1;
  const adjustedMultiplier = item.isCustom ? 1 : priceMultiplier;

  const isCustom = item.isCustom;

  const onAdd = () => !disabled && onChange(quantity + 1);

  const removeDisabled = disabled || (isCustom && quantity === 1);
  const onRemove = () => !removeDisabled && onChange(quantity - 1);

  return (
    <Paper className={styles.root} variant="outlined">
      <Box className={styles.topline}>
        <Box className={styles.description}>
          <Typography>
            {item.description}
            <br />
            (${((item.price * adjustedMultiplier) / 100).toFixed(2)} each)
          </Typography>
        </Box>
        <Box className={styles.priceDescription}>
          {priceLoader ? (
            <CircularProgress color="primary" size={20} thickness={5} />
          ) : (
            <Typography>${((quantity * item.price * adjustedMultiplier) / 100).toFixed(2)}</Typography>
          )}
        </Box>
        <Box>
          <IconButton disabled={removeDisabled} onClick={onRemove}>
            <RemoveCircle color={removeDisabled ? undefined : "primary"} />
          </IconButton>
          {quantity}
          <IconButton onClick={onAdd}>
            <AddCircle color={disabled ? undefined : "primary"} />
          </IconButton>
        </Box>
      </Box>
      {item.details && (
        <Box className={styles.detailsContainer}>
          <Typography className={styles.details}>{item.details}</Typography>
        </Box>
      )}
    </Paper>
  );
};

export default ProductInput;
