import React, { useCallback, useState, forwardRef } from "react";
import { Box, Grid } from "@material-ui/core";

import useMountedEffect from "@shared/hooks/useMountedEffect";

import { updateField } from "@booking/helpers/utils";
import { Input } from "@booking/helpers/enums";
import useTriggerValidation from "@shared/hooks/useTriggerValidation";
import NeedHelp from "@shared/components/NeedHelp";

import StairsField from "@booking/helpers/fields/StairsField";
import DismantlingField from "@booking/helpers/fields/DismantlingField";
import DescriptionField from "@booking/helpers/fields/DescriptionField";
import CouponField from "@booking/helpers/fields/CouponField";

const QuoteDescription = forwardRef(({ task, defaultValue, onChange }, ref) => {
  const [stairsField, setStairsField] = useState(defaultValue[Input.Stairs]);
  const [dismantlingField, setDismantlingField] = useState(defaultValue[Input.Dismantling]);
  const [descriptionField, setDescriptionField] = useState(defaultValue[Input.Description]);

  useMountedEffect(() => {
    onChange({
      [Input.Description]: descriptionField,
      [Input.Stairs]: stairsField,
      [Input.Dismantling]: dismantlingField,
    });
  }, [descriptionField, stairsField, dismantlingField]);

  useTriggerValidation(ref, [setStairsField, setDismantlingField, setDescriptionField]);

  const handleStairsChange = useCallback(
    (field) => {
      setStairsField(updateField(stairsField, field));
    },
    [stairsField]
  );

  const handleDismantlingChange = useCallback(
    (field) => {
      setDismantlingField(updateField(dismantlingField, field));
    },
    [dismantlingField]
  );

  const handleDescriptionChange = useCallback(
    (field) => {
      setDescriptionField(updateField(descriptionField, field));
    },
    [descriptionField]
  );

  return (
    <Box>
      <Grid container spacing={3} alignItems="flex-start" style={{ marginBottom: "20px" }}>
        <Grid item xs={12}>
          <StairsField field={stairsField} onChange={handleStairsChange} />
        </Grid>
        <Grid item xs={12}>
          <DismantlingField field={dismantlingField} onChange={handleDismantlingChange} />
        </Grid>
      </Grid>
      <DescriptionField
        field={descriptionField}
        minRows={8}
        width={"75%"}
        description={`For example, "1x king-sized mattress that will be located inside the garage", "4x trash bags of food, cardboard, and general house trash will be located on the front lawn." 

Note: Items you wish to be removed must be itemized and added to the dropdown list of "pickup type" above. If you cannot find an item from the dropdown list, please choose "unlisted" in the dropdown menu, and describe them in this box.`}
        onChange={handleDescriptionChange}
      />
      <div style={{ height: 10 }} />
      <CouponField />
      <NeedHelp />
    </Box>
  );
});

export default React.memo(QuoteDescription);
