import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography, makeStyles } from "@material-ui/core";

import { discountPrice } from "@constants/pricing";
import { calculateTotalPrice } from "@booking/helpers/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    "& p": {
      color: theme.palette.primary.main,
      fontWeight: 600,
      [theme.breakpoints.up("md")]: {
        fontSize: 16,
      },
    },
  },
}));

const BulkDiscount = (props) => {
  const classes = useStyles();
  const priceMultiplier = useSelector((state) => state.serviceLocations.activeLocation?.priceMultipliers ?? 100);
  const price = calculateTotalPrice(props) * (priceMultiplier / 100);

  const discountAmount = {
    [price >= discountPrice.$200 && price < discountPrice.$500]: 10,
    [price >= discountPrice.$500]: 20,
  }.true;

  return discountAmount ? (
    <Box className={classes.root}>
      <Typography>Big order discount</Typography>
      <Typography>- {discountAmount}%</Typography>
    </Box>
  ) : null;
};

export default BulkDiscount;
