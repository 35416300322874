import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles, Box, Grid, Typography, Button } from "@material-ui/core";

import url from "@assets/complete_task.svg";

import Hidden from "@shared/components/Hidden";
import { scrollToTop } from "../../../methods/scrollToTop";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
    },
  },
  text: {
    marginBottom: "15px",
  },
  agreeBtn: {
    width: "100%",
    marginTop: "30px",
    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
  },
  redText: {
    color: "#cc3b31",
    fontWeight: "bold",
  },
  cardMobile: {
    width: "100%",
    position: "sticky",
    bottom: "0px",
  },
  link: {
    color: "grey",
    textDecoration: "underline",
    cursor: "pointer",
    fontWeight: "bold",
  },
}));

function PossiblePenalty({ next }) {
  const classes = useStyles();

  scrollToTop();

  return (
    <Grid container className={classes.root} spacing={4}>
      <Grid item xs={12} md={6}>
        <Typography align="justify" className={classes.text}>
          We deeply care about customer experience and only work with <b>responsible and responsive providers.</b>
        </Typography>

        <Typography align="justify" className={classes.text}>
          <span className={classes.redText}>Note our terms have recently updated.</span> By proceeding with &quot;Continue&quot;, you agree to
          Dimension’s{" "}
          <Link to="/service-provider-policy" target="_blank" className={classes.link}>
            Service Provider Policy
          </Link>
          , including the following penalty terms possible for providers:
        </Typography>

        <Typography align="justify" className={classes.text} component={"span"}>
          <b>Cancellation: </b>
          <ul>
            <li>Cancellation at least 7 work hours in advance: no penalty </li>
            <li>Cancellation between 7 work hours and 4 work hours in advance: $15 per incident</li>
            <li>Cancellation within 4 work hours: $35 per incident</li>
          </ul>
        </Typography>

        <Typography align="justify" className={classes.text} component={"span"}>
          <b>Late/ No-show:</b>
          <ul>
            <li>
              Being late for the appointment for more than an hour:{" "}
              <span className={classes.redText}>Provider shall be charged 5% of the listed payout amount per incident. </span>
            </li>
            <li>
              No show to the appointment:{" "}
              <span className={classes.redText}>Provider shall be charged 30% of the listed payout amount per incident. </span>
            </li>
          </ul>
        </Typography>

        <Typography align="justify" className={classes.text} component={"span"}>
          <b>Under equipped: </b>
          <ul>
            <li>Showing up understaffed than what is listed as the task requirement: $30 per incident </li>
            <li>Showing up with vehicle lower than min requirement: $30 per incident </li>
            <li>
              <b>Covid 19</b>: Showing up without wearing PPE required by local gov: $15 per incident{" "}
            </li>
          </ul>
        </Typography>

        <Typography align="justify" className={classes.text} component={"span"}>
          <b>Other violation: </b>
          <ul>
            <li>
              Making separate arrangements with customers (example: ask to arrive at least an hour early or late than scheduled, additional
              compensation, bypass Dimension):{" "}
              <span className={classes.redText}>$50 for first violation. Banned permanently after second violation.</span>
            </li>
            <li>
              Illegal dumping of the waste:{" "}
              <span className={classes.redText}>
                $50 penalty + return original payout associated with this job. Be banned immediately after the first violation. You also agree to
                assume the full legal and financial liability.{" "}
              </span>
            </li>
          </ul>
        </Typography>

        <Typography align="justify" className={classes.text}>
          To avoid penalty, <b>all you need to do is let us know your plan change as soon as possible.</b> You can text us at 415-304-8171 or email
          support@getdimension.com
        </Typography>

        <Button color="primary" variant="contained" className={classes.agreeBtn} onClick={() => next()}>
          I Agree
        </Button>
      </Grid>
      <Grid item md={6}>
        <Hidden mobile>
          <Box
            style={{
              backgroundImage: `url(${url})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
              height: "300px",
              width: "100%",
            }}
          />
        </Hidden>
      </Grid>
    </Grid>
  );
}

export default PossiblePenalty;
