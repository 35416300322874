import React, { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { BooleanParam, useQueryParam } from "use-query-params";
import filterIcon from "@assets/filter-icon.svg";
import locationPinIcon from "@assets/location-pin-icon.svg";
import serviceTypeIcon from "@assets/service-type.svg";
import NoDataImage1 from "@assets/nodata/warrior_no_data_group1.png";
import NoDataImage2 from "@assets/nodata/warrior_no_data_group2.png";
import classes from "@css/components/GetTasksPage.module.css";
import history from "@history";
import { Button, Card, CardContent, CircularProgress, Grid, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Spacer from "@shared/components/Spacer";
import useLocalStorage from "@shared/hooks/useLocalStorage";
import { haversineDistance } from "@shared/utils/haversine";
import { fetchAvailableBookings } from "@store/actions/booking";
import { updateProfile } from "@store/actions/users";
import { roles } from "../../../constants/roles";
import isEqual from "../../../methods/isEqual";
import { checkFeatureFlag } from "../../../store/actions/featureFlags";
import FilterCard from "./components/FilterCard";
import FilterCardGroup from "./components/FilterCardGroup";
import TaskListItem from "./components/TaskListItem";
import WarriorStreakModal from "./WarriorStreakModal";
import Approval from "./components/Approval";
import {
  reset,
  setInitialVisibility,
  setIsMobileView,
  setTimeOnPage,
  setTotalAvailableTasks,
  trackGetTaskAnalytics,
} from "../../../store/actions/analytics/getTasks";
import { useLDChecker } from "../../../shared/hooks/useLDChecker";
import NoDataWarrior from "../../../shared/components/NoDataWarrior";
import { getlocalStorage } from "../../../shared/hooks/useLocalStorage";
import { isProduction } from "../../../constants/environments";
import WarriorNoShowModal from "./WarriorNoShowModal";
import { segmentTrack } from "../../../helpers/segment";

const ServiceTypes = [
  { label: "Cardboard Removal", value: true, id: "cardboard-removal" },
  { label: "Waste Removal", value: true, id: "junk-removal" },
  { label: "Dumpster Rental", value: true, id: "dumpster-rental" },
];

function PageLoader() {
  return (
    <div className={classes.loader}>
      <CircularProgress />
      <Spacer />
      <Typography color="primary">Loading tasks...</Typography>
    </div>
  );
}

function GetTasksPage() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isOpen, setOpen] = useState(false);
  const [isNoShow, setIsNoShow] = useState(null);
  const profile = useSelector((state) => state.firebase.profile);
  const notificationSettings = useSelector((state) => state.firebase.profile?.alerts);
  const bookings = useSelector((state) => state.booking?.availableTasks || []);
  const isLoading = useSelector((state) => state.booking?.isLoading || state.serviceLocations?.isLoading);
  const serviceLocations = useSelector((state) => state.serviceLocations.locations);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLoggedInProvider = profile.userType === roles.provider.value;
  const savedLocations = profile?.savedPreferences?.locations ?? [];
  const savedServices = profile?.savedPreferences?.services;
  const defaultServiceTypes =
    savedServices?.length > 0 ? ServiceTypes.map((item) => ({ ...item, value: savedServices.includes(item.id) })) : ServiceTypes;

  const userCity = profile?.smsSettings?.address?.city;
  const isTaskTimeOut = profile?.taskTimeout && dayjs(profile?.taskTimeout).isAfter(dayjs());

  const showApproval = profile.approvalState !== "complete";
  // states
  const [services, setServices] = useLocalStorage("services", defaultServiceTypes);
  const [locations, setLocations] = useLocalStorage("locations", []);
  const [showFilterOnly, setShowFilterOnly] = useState(false);

  // Launch Darkly 15 Minute Bonus
  const [showEarlyBonus, setShowEarlyBonus] = useState(false);
  const [canRenderTasks, setCanRender] = useState(false);

  const handleDisplayBonus = (value) => {
    setShowEarlyBonus(value);
    setCanRender(true);
  };

  // Launch Darkly 15 Minute Bonus Checked
  useLDChecker({
    flag: "fifteen-minute-bonus",
    dependencies: [],
    profile,
    callback: (value) => handleDisplayBonus(value),
    run: isLoggedInProvider,
  });

  const checkFlag = async () => {
    const data = {
      key: profile?.uid,
      custom: {
        market: userCity,
      },
    };
    const isValid = await checkFeatureFlag("warrior-bonuses", data, false);
    if (isValid) {
      const isPrompt = localStorage.getItem("warrior-bonus-prompt");
      if (isPrompt) return;
      setOpen(true);
      localStorage.setItem("warrior-bonus-prompt", dayjs().format("YYYY-MM-DD"));
    }
  };

  const applyFilters = (taskList) => {
    if (!taskList) return [];
    return taskList.filter((item) => {
      const filteredServices = services.filter((service) => {
        return !service?.value && item.task.toLowerCase() === service?.id?.toLowerCase();
      });
      if (filteredServices.length > 0) {
        return false; //We're currently filtering this item out
      }

      let hasNoLocationFilter = true;
      locations.forEach((location) => {
        const calculatedDistance = haversineDistance(item.address.coordinates, location.coordinates);
        if (location.limits && calculatedDistance <= location.limits && location.value) {
          hasNoLocationFilter = false;
        }
      });
      return !hasNoLocationFilter;
    });
  };

  const handleGoBack = () => {
    if (isMobile && showFilterOnly) {
      setShowFilterOnly(false);
    } else history.goBack();
  };

  useEffect(() => {
    if (profile.tasksCount < 3) {
      setIsNoShow(true);
      segmentTrack("Warrior No Show Modal Displayed", { tasksCount: profile.tasksCount });
    }
  }, [profile.tasksCount]);

  useEffect(() => {
    const activeLocations = serviceLocations.map((item) => {
      const id = item.address.placeId;
      let selected = false;
      if (savedLocations && savedLocations?.includes(id)) {
        selected = true;
      }
      return {
        id,
        value: selected,
        label: item.address.city,
        group: item.address.state,
        coordinates: item.center?.coordinates ?? item.address.coordinates,
        limits: item.distance,
      };
    });
    // sort alphabetically (a-z)
    activeLocations.sort((a, b) => (a.label > b.label) - (a.label < b.label)).sort((x, y) => y.value - x.value);
    setLocations(activeLocations);
  }, [serviceLocations]);

  const getActiveIds = (arr) =>
    arr.reduce((ids, i) => {
      if (i.value) ids.push(i.id);
      return ids;
    }, []);

  const isProfileComplete = () => {
    const isDriversLicenseEntered = !!profile.driversLicense;
    const isPhotoAdded = !!profile.photoURL;
    const isSmsSettingsAdded = !!profile.smsSettings;
    const isVehicleInfoAdded = !!profile.vehicleInfo;
    const isAgreementSigned = !!profile.providerAgreement?.date;
    return isDriversLicenseEntered && isPhotoAdded && isSmsSettingsAdded && isVehicleInfoAdded && isAgreementSigned;
  };

  useEffect(() => {
    checkFlag();
  }, [userCity]);

  const init = () => {
    const localServices = getlocalStorage.read("services");
    if (localServices?.length && !localServices[0]?.id) {
      getlocalStorage.remove("services");
    }
    localStorage.setItem("initViewTime", dayjs());
    !isTaskTimeOut && dispatch(fetchAvailableBookings());
  };

  useEffect(() => {
    init();
    return () => {
      const initViewTime = localStorage.getItem("initViewTime");
      const timeSpentOnPage = dayjs().diff(initViewTime, "seconds");
      dispatch(setTimeOnPage(timeSpentOnPage));
      localStorage.removeItem("initViewTime");
      dispatch(trackGetTaskAnalytics());
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    dispatch(setIsMobileView(isMobile));
    dispatch(setInitialVisibility(isMobile));
  }, [isMobile]);

  const updateProfileState = async () => {
    await dispatch(updateProfile({ approvalState: "info-filled" }));
  };

  const handleServicesChange = async (values) => {
    setServices(values);
    const ids = getActiveIds(values);
    await dispatch(updateProfile({ savedPreferences: { services: ids } }, null, false, false));
  };

  const handleLocationsChange = async (values) => {
    setLocations(values);
    const ids = getActiveIds(values);
    await dispatch(updateProfile({ savedPreferences: { locations: ids } }, null, false, false));
  };

  useEffect(() => {
    // will be applicable only for logged in provider
    if (isLoggedInProvider) {
      if (profile.isBanned) {
        history.push("/account");
      }
      if (isProfileComplete() || !isProduction) {
        if (!["approved", "complete", "info-filled"].includes(profile.approvalState)) {
          updateProfileState();
        }
      } else {
        history.push("/complete_profile");
      }
    }
  }, [profile?.isBanned, profile?.driversLicense, profile?.smsSettings, profile?.vehicleInfo, profile.providerAgreement?.date]);

  const TaskItemsList = () => {
    const filteredBookings = applyFilters(bookings);
    dispatch(setTotalAvailableTasks(filteredBookings.length));

    if (!filteredBookings.length && !notificationSettings?.length) {
      return (
        <NoDataWarrior
          bannerImage={NoDataImage1}
          displayText="No available jobs right now"
          subText="Turn on your On Demand text notifications and be the first to know when a new job is available"
          buttonText="Update Notifications"
          buttonLink="/alerts"
        />
      );
    }
    if (!filteredBookings.length && notificationSettings?.length) {
      return (
        <NoDataWarrior
          bannerImage={NoDataImage2}
          displayText="No available jobs right now"
          subText="Don't worry - check back soon or expand your location range to see additional jobs"
        />
      );
    }
    return (
      <div className={classes.listContainer}>
        {filteredBookings.map((item) => (
          <TaskListItem item={item} key={`tasklistitem-${item.id}`} canShowEarlyBonus={showEarlyBonus} />
        ))}
      </div>
    );
  };

  if (showApproval) {
    return (
      <div style={{ height: "100%" }}>
        <Approval approvalState={profile?.approvalState} />
      </div>
    );
  }

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.headerTitle}>
            <KeyboardBackspaceIcon className={classes.backBtn} fontSize="large" onClick={handleGoBack} />
            <div onClick={() => history.push("/provider_portal")}>
              <Typography className={classes.backBtnText}>{isMobile && showFilterOnly ? "Filter" : "Nearby Tasks"}</Typography>
            </div>
          </div>
          {!showFilterOnly && ( // css check for mobile view is there
            <div className={classes.filterIconContainer}>
              <img src={filterIcon} alt="filterIcon" className={classes.filterIcon} onClick={() => setShowFilterOnly(true)} />
            </div>
          )}
        </div>
        {isMobile && showFilterOnly && <Typography style={{ marginLeft: "30px" }}>Select the options from below</Typography>}
        <div className={classes.contentContainer}>
          {(!isMobile || (isMobile && showFilterOnly)) && (
            <div className={classes.filterBar}>
              <FilterCard
                key="serviceTypeFilter"
                items={services}
                title="Service Type"
                TitleIcon={<img src={serviceTypeIcon} alt="serviceTypeIcon" className={classes.titleIcon} />}
                onChange={handleServicesChange}
              />
              <FilterCardGroup
                key="locationTypeFilter"
                items={locations}
                title="Location"
                TitleIcon={<img src={locationPinIcon} alt="locationPinIcon" className={classes.titleIcon} />}
                isCollapsable
                showMorelabel="More locations"
                onChange={handleLocationsChange}
              />
            </div>
          )}
          {(!showFilterOnly || !isMobile) && (
            <div className={classes.cardsContainer}>
              {isLoading || !canRenderTasks ? <PageLoader /> : bookings ? <TaskItemsList /> : <React.Fragment />}
            </div>
          )}
        </div>
        {/* css check for mobile view is there */}
        {showFilterOnly && (
          <Grid item xs={12} className={classes.cardMobile}>
            <Card raised style={{ width: "100%" }}>
              <CardContent style={{ display: "flex" }}>
                <Button variant="contained" color="primary" fullWidth onClick={() => setShowFilterOnly(false)}>
                  Apply filters
                </Button>
              </CardContent>
            </Card>
          </Grid>
        )}
      </div>
      <WarriorStreakModal open={isOpen} setOpen={setOpen} onClose={() => setOpen(false)} />
      <WarriorNoShowModal
        open={isNoShow}
        setOpen={setIsNoShow}
        onClose={() => {
          setIsNoShow(false);
          segmentTrack("Warrior No Show Modal Acknowledged");
        }}
      />
    </div>
  );
}

export default GetTasksPage;
