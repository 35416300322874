import Section from "@booking/components/Section";
import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useBookingPageContext } from "@booking/context";
import { Input } from "@booking/helpers/enums";
import BusinessClientIcons from "@booking/helpers/fields/BusinessClientIcons";
import ServiceableField from "@booking/helpers/fields/Serviceable";
import TextField from "@booking/helpers/fields/TextField";
import { errorCode, isError, updateField } from "@booking/helpers/utils";
import validators from "@booking/helpers/validators";
import Spacer from "@shared/components/Spacer";
import useMountedEffect from "@shared/hooks/useMountedEffect";
import useTriggerValidation from "@shared/hooks/useTriggerValidation";

const AddressForm = forwardRef(({ defaultValue, onChange, task }, ref) => {
  const { commonForm } = useBookingPageContext();
  const address = useSelector((state) => state.address);

  const [pickupAddressField, setPickupAddressField] = useState(defaultValue[Input.PickupAddress]);
  const [PickupAddressAdditionalField, setPickupAddressAdditionalField] = useState(defaultValue[Input.PickupAddressAdditional]);
  const [CompanyNameField, setCompanyNameField] = useState(defaultValue[Input.CompanyName]);
  const [businessClientField, setBusinessClientField] = useState(defaultValue[Input.BusinessClient]);

  useMountedEffect(() => {
    onChange({
      [Input.PickupAddress]: pickupAddressField,
      [Input.PickupAddressAdditional]: PickupAddressAdditionalField,
      [Input.CompanyName]: CompanyNameField,
      [Input.BusinessClient]: businessClientField,
    });
  }, [pickupAddressField, PickupAddressAdditionalField, CompanyNameField, businessClientField]);

  useEffect(() => {
    setBusinessClientField({ ...businessClientField, ...commonForm[Input.BusinessClient] });
  }, [commonForm]);

  useTriggerValidation(ref, [setPickupAddressField, setPickupAddressAdditionalField, setCompanyNameField, setBusinessClientField]);

  const handlePickupAddressFieldChange = useCallback(
    (field) => {
      setPickupAddressField(updateField(pickupAddressField, field));
    },
    [pickupAddressField]
  );

  const handlePickupAddressAdditionalFieldChange = useCallback(
    (field) => {
      setPickupAddressAdditionalField(updateField(PickupAddressAdditionalField, field));
    },
    [PickupAddressAdditionalField]
  );

  const handleCompanyNameFieldChange = useCallback(
    (field) => {
      setCompanyNameField(updateField(CompanyNameField, field));
    },
    [CompanyNameField]
  );

  const handleBusinessClientChange = useCallback(
    (field) => {
      setBusinessClientField(updateField(businessClientField, field));
    },
    [businessClientField]
  );

  useEffect(() => {
    if (!pickupAddressField.value.location && address.street) {
      handlePickupAddressFieldChange({ value: address });
    }
  }, [address.street]);

  return (
    <div style={{ marginBottom: "32px" }}>
      <Section title="Enter your address" width={"70%"}>
        <BusinessClientIcons
          field={businessClientField}
          onChange={handleBusinessClientChange}
          onChangeCompanyName={handleCompanyNameFieldChange}
          companyNameField={CompanyNameField}
        />
        <Spacer />
        <ServiceableField
          restrict
          label="Pickup Address"
          validate
          type="street"
          task={task}
          field={pickupAddressField}
          onChange={handlePickupAddressFieldChange}
        />
        <Spacer />
        <TextField
          label="Apt number (optional)"
          field={PickupAddressAdditionalField}
          validator={validators[Input.PickupAddressAdditional]}
          onChange={handlePickupAddressAdditionalFieldChange}
          error={isError(PickupAddressAdditionalField.validation)}
          helperText={errorCode(PickupAddressAdditionalField.validation)}
        />
      </Section>
    </div>
  );
});

export default React.memo(AddressForm);
