export const constructUserSearchQuery = (usertype: string, query: string) => {
  const searchQuery = {
    query,
    search_fields: {
      id: {},
      email: {},
      firstname: {},
      lastname: {},
      fullname: {},
      phone: {},
      zip: {},
      city: {},
      state: {},
    },
    page: {
      size: 20,
    },
    sort: {
      firstname: "asc",
    },
    filters: {
      all: [{ usertype }],
    },
  };

  return searchQuery;
};

export const constructUserBookingsSearchQuery = (props: any) => {
  const { fieldName, value, pageNumber } = props;

  const searchQuery = {
    query: "",
    page: {
      size: 20,
      current: pageNumber,
    },
    sort: {
      date_appointment: "desc",
    },
    filters: {
      all: [{ [fieldName]: value }],
    },
  };
  return searchQuery;
};
