import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Box, Typography, Button, Grid, Card, CardContent, Icon } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import alt_avatar from "@assets/alt_avatar.png";
import jobCompletionLogo from "@assets/jobCompletionLogo.png";
import vehicleLogo from "@assets/vehicle.png";
import pluralize from "@methods/pluralize";
import { fetchUserProfile, fetchUserProfileInfo } from "../../../store/actions/users";
import tempDataConstants from "../../../store/constants/tempDataTypes";
import { bookingStatus } from "../../../constants/booking";
import RatingWarrior from "./RatingWarrior";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px",
    animation: "$fadein 0.2s ease-in",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    border: "1px solid #eee",
  },
  "@keyframes fadein": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  avatarDetails: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      textAlign: "center",
    },
  },
  avatarWrapper: {
    height: "140px",
    width: "140px",
    borderRadius: "50%",
    marginBottom: theme.spacing(2),
  },
  avatar: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    width: "100%",
    height: "100%",
    border: "5px solid white",
    borderRadius: "50%",
    backgroundImage: (props) => `url(${props.url})`,
  },
  profileName: {
    fontSize: "20px",
    lineHeight: 1.2,
    fontWeight: "600",
    maxWidth: "190px",
    textAlign: "center",
  },
  ratingContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
  },
  rating: {
    marginLeft: "10px",
    color: "grey",
    fontWeight: "bold",
  },
  viewProfile: {
    width: "100%",
    alignItems: "center",
  },
  jobCompletion: {
    fontWeight: "600",
    fontSize: "20px",
    marginLeft: "20px",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  imageIcon: {
    height: "40px",
    width: "40px",
  },
  iconContainer: {
    [theme.breakpoints.down("xs")]: {
      height: "40px",
      width: "40px",
    },
    textAlign: "center",
    height: "45px",
    width: "45px",
  },
  jobCompletionWrapper: {
    marginTop: "20px",
  },
  staticButton: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  amountLabel: {
    fontWeight: 800,
    fontSize: "18px",
  },
  amountValue: {
    color: theme.palette.primary.main,
    fontWeight: 800,
    fontSize: "24px",
    paddingBottom: theme.spacing(1),
  },
  cardFooter: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  ratingColor: {
    color: theme.palette.primary.main,
  },
}));

const WarriorCardBody = ({ warriorDetails, onClick }) => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.profileName}>{warriorDetails.firstName ?? "-"}</Typography>
      <Box className={classes.ratingContainer}>
        {warriorDetails?.ratingCount >= 3 && (
          <>
            <Rating
              className={classes.ratingColor}
              name="rating"
              size="small"
              value={warriorDetails.ratingCount ? warriorDetails.ratingTotal / warriorDetails.ratingCount : 0}
              readOnly
            />
            <Box className={classes.rating}>{warriorDetails.ratingCount} review(s)</Box>
          </>
        )}
      </Box>

      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid container justifyContent="space-between" className={classes.jobCompletionWrapper}>
          <Icon classes={{ root: classes.iconContainer }}>
            <img className={classes.imageIcon} src={jobCompletionLogo} alt="jobCompletionLogo" />
          </Icon>
          <Typography className={classes.jobCompletion} style={{ display: "flex", alignItems: "center" }}>
            {pluralize(warriorDetails.tasksCount, "Job")} completed
          </Typography>
        </Grid>
        <Button onClick={onClick} color="primary" size="medium">
          View profile
        </Button>
      </Grid>
    </>
  );
};

const WarriorCard = ({ page }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = useSelector((state) => state.firebase.auth?.uid);
  const booking = useSelector((state) => state.booking.selectedBooking);
  const [warriorDetails, setWarriorDetails] = useState(null);
  const classes = useStyles({ url: warriorDetails?.photoURL || alt_avatar });
  const amount = booking?.isTuftAndNeedleBooking ? "0" : booking?.total || "";

  const onClick = (type) => {
    if (type === "profile") {
      history.push(`/profile_details/${warriorDetails.uid}`);
    } else if (isReview || isComplete) {
      history.push(`/review/${booking.id}`);
    }
  };

  const renderActions = () => {
    const status = booking?.status;
    switch (status) {
      case bookingStatus.current.value:
        if (!warriorDetails) {
          return <div />;
        }
        return (
          <Button variant="contained" color="primary" fullWidth className={classes.staticButton}>
            Warrior matched
          </Button>
        );
      case bookingStatus.open.value:
        return (
          <Button variant="contained" color="primary" fullWidth className={classes.staticButton} disabled>
            Finding a warrior
          </Button>
        );
      case bookingStatus.review.value:
      case bookingStatus.done.value:
        return <RatingWarrior booking={booking} userId={userId} page={page} />;
      default:
        return <div />;
    }
  };

  const goToReviewPage = () => {
    history.push(`/review/${booking?.id}`);
  };

  const renderFooter = () => {
    if (
      page !== "details" ||
      !booking ||
      booking?.status === bookingStatus.current.value ||
      booking?.status === bookingStatus.open.value ||
      booking?.status === bookingStatus.booked.value
    ) {
      return <div />;
    }
    const text = booking.status === bookingStatus.review.value ? "Click to Review" : "Open Review";
    return (
      <Button variant="contained" color="primary" fullWidth className={classes.staticButton} onClick={goToReviewPage}>
        {text}
      </Button>
    );
  };

  const [loading, setLoading] = useState(true);

  const getWarriorInfo = async () => {
    dispatch(fetchUserProfile(booking?.warrior_id));
    const warriorData = await fetchUserProfileInfo(booking?.warrior_id);
    setWarriorDetails(warriorData);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    if (booking?.warrior_id) {
      getWarriorInfo();
    } else {
      setWarriorDetails(null);
      setLoading(false);
    }
  }, [booking?.warrior_id]);

  return (
    !loading && (
      <Card variant="outlined" className={classes.root}>
        <CardContent>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            {warriorDetails ? (
              <Grid container justifyContent="center" alignItems="center">
                <div className={classes.avatarWrapper}>
                  <div className={classes.avatar} />
                </div>
              </Grid>
            ) : (
              <Grid container justifyContent="center" style={{ margin: "30px 0" }}>
                <Typography className={classes.profileName}>Your Warrior will appear here</Typography>
                <div style={{ width: "190px", height: "190px", marginTop: "20px" }}>
                  <img src={vehicleLogo} alt="vehicleLogo" width="100%" height="100%" />
                </div>
              </Grid>
            )}
            <div className={classes.contentWrapper}>
              {warriorDetails && <WarriorCardBody warriorDetails={warriorDetails} onClick={() => onClick("profile")} />}
              <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.cardFooter}>
                {!!amount && (
                  <Grid container direction="column" justifyContent="center" alignItems="center" style={{ marginBottom: "10px" }}>
                    <Typography className={classes.amountLabel}>Total amount</Typography>
                    <Typography className={classes.amountValue}>{amount == 0 ? "Free" : `$ ${(amount / 100).toFixed(2)}`}</Typography>
                  </Grid>
                )}
              </Grid>
            </div>
            {renderActions()}
            {renderFooter()}
          </Grid>
        </CardContent>
      </Card>
    )
  );
};

export default WarriorCard;
