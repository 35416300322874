import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import history from "@history";
import querystring from "query-string";

import Loader from "@shared/components/Loader";
import { fetchMyOrders, setActiveBooking } from "@store/actions/booking";
import { setBookingsTab } from "@store/actions/ui";
import { modifyBusinessHours } from "@store/actions/config";
import { setAddress, validateServiceLocation } from "@store/actions/address";
import { applyFiltersMyOrders } from "@helpers/booking";

import Bookings from "./Bookings";
import { segmentTrack } from "../../../helpers/segment";
import { fetchBookingQuotesOfCustomer } from "../../../store/actions/bookingQuotes";
import { bookingStatus } from "../../../constants/booking";
import { ClientTaskPageLayout } from "../../../shared/components/ClientTasksLayout";
import { DefaultFilter } from "../../MyTasksPage/constants";
import FilterBar from "../../MyTasksPage/components/FilterBar";
import NoDataCustomers from "./NoDataCustomers";
import { checkFeatureFlag } from "../../../store/actions/featureFlags";

const OrdersPage = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.firebase.auth?.uid);
  const { uid, businessId } = useSelector((state) => state.firebase.profile);
  const isBusinessAccount = !!businessId;
  const myBookings = useSelector((state) => state.booking?.myBookings) ?? [];
  const myBookingQuotes = useSelector((state) => state.bookingQuote?.userBookingQuotes) ?? [];
  const isLoading = useSelector((state) => state.booking.isLoading);
  const activeStatus = useSelector((state) => state.ui.bookingTableTab);

  const [authorizedDoItAgain, setAuthorizedDoItAgain] = useState(false);
  const [bookings, setBookings] = useState(myBookings ?? []);
  const [quotes, setQuotes] = useState(myBookingQuotes ?? []);
  const [searchQuery, setSearchQuery] = useState("");
  const [view, setView] = useState("list");

  const setActiveStatus = (tab) => {
    dispatch(setBookingsTab(tab));
  };

  useEffect(() => {
    if (userId) {
      dispatch(fetchMyOrders(userId));
      dispatch(fetchBookingQuotesOfCustomer(userId));
    }
  }, [userId, dispatch]);

  useEffect(() => {
    applyFilter();
  }, [activeStatus, myBookings?.length, myBookingQuotes?.length]);

  const applyFilter = (filters = { ...DefaultFilter }) => {
    const filteredBookings = applyFiltersMyOrders(myBookings, { ...filters, activeStatus }).sort((a, b) => new Date(b.date) - new Date(a.date));
    if (activeStatus !== "quotes") {
      setQuotes([]);
      setBookings(filteredBookings);
    } else {
      setBookings([]);
      setQuotes(myBookingQuotes);
    }
  };

  const showBookingDetails = (e, bookingId) => {
    e.stopPropagation();
    history.push(`/bookings/${bookingId}`);
  };

  const handleDoItAgain = async (e, booking) => {
    e.stopPropagation();
    const validation = await dispatch(validateServiceLocation(booking.address, booking.task));
    if (!validation) {
      toastr.error("Error", "Address Location is no longer serviceable");
      return;
    }
    dispatch(setActiveBooking(booking));
    dispatch(setAddress(booking.address));
    dispatch(modifyBusinessHours(booking.date));
    dispatch(setAddress(booking.address));
    history.push(
      "/booking?".concat(
        querystring.stringify({
          task: booking.task,
          business_client: booking.businessClient ? 1 : 0,
          repeat: 1,
          // ...(gclId && { gclid: gclId }),
        })
      )
    );
    segmentTrack("Do it Again Button Clicked", { bookingId: booking.id });
  };

  const handleStatusClick = (e, booking) => {
    e.stopPropagation();
    const isReviewPending = booking.status !== "review";
    if (isReviewPending) {
      showBookingDetails(booking.id);
      return;
    }
    dispatch(setActiveBooking(booking));
    history.push(`/review/${booking.id}`);
  };

  const goToBookingFlow = (e, quoteId) => {
    e.stopPropagation();
    history.push(`/booking?draft_id=${quoteId}`);
  };

  const bookingTableActions = {
    handleDoItAgain,
    handleStatusClick,
    handleBookingDetails: showBookingDetails,
    goToBookingFlow,
  };

  const checkAuthorizedDoItAgain = async () => {
    const isAuth = await checkFeatureFlag("customer-do-it-again-always-on", { key: uid }, false);
    setAuthorizedDoItAgain(isAuth);
  };

  useEffect(() => {
    if (isBusinessAccount) {
      checkAuthorizedDoItAgain();
    }
  }, []);

  const tabs = [
    {
      value: "active",
      label: "Upcoming",
    },
    {
      value: bookingStatus.review.value,
      label: "Needs Review",
    },
    {
      value: bookingStatus.done.value,
      label: "Completed",
    },
    {
      value: bookingStatus.cancelled.value,
      label: "Cancelled",
    },
    {
      value: "quotes",
      label: "Quotes",
    },
  ];

  const layoutProps = {
    title: "My Bookings",
    filterBar: (showMobileFilter, setShowMobileFilter) => (
      <FilterBar
        setView={setView}
        applyFilter={applyFilter}
        headerSearch={searchQuery}
        showMobileFilter={showMobileFilter}
        setShowMobileFilter={setShowMobileFilter}
      />
    ),
    search: searchQuery,
    setSearch: setSearchQuery,
    tabs,
    activeStatus,
    setActiveStatus,
  };

  return (
    <ClientTaskPageLayout {...layoutProps}>
      {isLoading ? (
        <Loader />
      ) : bookings?.length && activeStatus !== "quotes" ? (
        <Bookings bookings={bookings} view={view} setView={setView} actions={bookingTableActions} authorizedDoItAgain={authorizedDoItAgain} />
      ) : quotes?.length && activeStatus === "quotes" ? (
        <Bookings bookings={quotes} showQuotes={true} view={view} setView={setView} actions={bookingTableActions} />
      ) : (
        <NoDataCustomers activeStatus={activeStatus} />
      )}
    </ClientTaskPageLayout>
  );
};

export default OrdersPage;
