import React, { useEffect } from "react";
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, makeStyles } from "@material-ui/core";

import validators from "@booking/helpers/validators";
import { isError, errorCode } from "@booking/helpers/utils";
import { Input } from "@booking/helpers/enums";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  checkBoxLabel: {
    alignItems: "start",
  },
  formHelperText: {
    margin: "4px 32px",
  },
}));

const UnderstandPricing = ({ field, onChange }) => {
  const styles = useStyles();

  const { value, validation } = field;

  useEffect(() => {
    onChange({ validation: validators[Input.UnderstandPricing](field) });
  }, [value]);

  const handleCheckboxChange = (e) => {
    const nextValue = e.target.checked;
    onChange({ value: nextValue });
  };

  const label = `I understand this dumpster rental price is subject to change based on availability. 
  I also understand there is an overweight charge and that e-waste, large furniture, and appliances
  dumped into the dumpsters (eg., mattresses, fridges, sofas, etc) will be charged on a per-item basis,
  based on this pricing chart.`;

  const control = <Checkbox required color="primary" checked={value} onChange={handleCheckboxChange} />;

  return (
    <div className={styles.root}>
      <FormControl error={isError(validation)}>
        <FormControlLabel className={styles.checkBoxLabel} label={label} control={control} onChange={onchange} />
      </FormControl>
      <FormHelperText className={styles.formHelperText} error={isError(validation)}>
        {errorCode(validation)}
      </FormHelperText>
    </div>
  );
};

export default UnderstandPricing;
