import { toastr } from "react-redux-toastr";
import { hostingUrls } from "@constants/hosting";
import { firebase } from "@config/firebaseConfig";
import { updateUserEmail } from "@store/actions/users";
import { useAxiosGet, useAxiosPost } from "../axios";

export const getAuthProviderPermissions = (provider) => {
  switch (provider) {
    case "password":
      return { title: "Regular Sign In", allowEdits: true };
    case "google.com":
      return { title: "Google Sign In", allowEdits: false };
    default:
      return { title: `${provider} Sign In`, allowEdits: false };
  }
};

export const getAuthProvider = (authData) => {
  const provider = authData.providerData[0].providerId;
  return getAuthProviderPermissions(provider);
};

export const getAuthProviderFromId = async (uid) => {
  const fetchedAuthProvider = await useAxiosPost("/users/get-auth-provider-from-uid", { uid });
  if (fetchedAuthProvider.data.success) {
    return fetchedAuthProvider.data.data;
  } else {
    // eslint-disable-next-line no-console
    console.error(fetchedAuthProvider.error);
    return "password";
  }
};

export const onMimicSignin = async (uid) => {
  try {
    // const uid = ''; // TODO remove dev id
    const response = await useAxiosPost(`/admin/create-token`, { uid });
    window.open(`${hostingUrls.subUrl}?jwt=${response.data}`, "_blank");
    // window.open(`https://localhost:3000/?jwt=${response.data}`, "_blank");
  } catch (error) {
    toastr.error("Error", "Something went wrong");
  }
};

export const updateUserProfile = async (newProfile, credentials) => {
  try {
    let user = firebase.auth().currentUser;

    if (credentials) {
      const { currentPassword, newEmail } = credentials;
      if (newEmail && user.email !== newEmail) {
        await updateUserEmail(newEmail, currentPassword);
        newProfile.email = newEmail;
      }
    }
    await useAxiosPost("/users/update-user", { uid: user.uid, ...newProfile });
    toastr.success("Success", "Details updated !");
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("updateProfile Error: ", error);
    toastr.error("Error", "Something went wrong");
  }
};
