import React, { useState, forwardRef, useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import useMountedEffect from "@shared/hooks/useMountedEffect";
import { updateField } from "@booking/helpers/utils";
import { Input } from "@booking/helpers/enums";
import useTriggerValidation from "@shared/hooks/useTriggerValidation";
import NeedHelp from "@shared/components/NeedHelp";
import DescriptionField from "@booking/helpers/fields/DescriptionField";
import PrivilegedComponent from "../../../../../shared/components/PrivilegedComponent";
import { BidPrompt } from "../../../components/BidPrompt";
import ImageHandlerForm from "../../../../CompleteTask/components/ImageHandlerForm";
import Section from "../../../components/Section";
import validators from "../../validators";
import { segmentTrack } from "../../../../../helpers/segment";

const RemovalBidTaskDescriptionForm = forwardRef(({ task, defaultValue, onChange, setIsBidMode, isBidPromptValid, isDefaultBid }, ref) => {
  const [stairsField, setStairsField] = useState(defaultValue[Input.Stairs]);
  const [dismantlingField, setDismantlingField] = useState(defaultValue[Input.Dismantling]);
  const [descriptionField, setDescriptionField] = useState(defaultValue[Input.Description]);
  const [imageField, setImageField] = useState(defaultValue[Input.TaskImages]);

  useMountedEffect(() => {
    onChange({
      [Input.Description]: descriptionField,
      [Input.Stairs]: stairsField,
      [Input.Dismantling]: dismantlingField,
      [Input.TaskImages]: imageField,
    });
  }, [descriptionField, stairsField, dismantlingField, imageField]);

  useTriggerValidation(ref, [setStairsField, setDismantlingField, setDescriptionField, setImageField]);

  const handleDescriptionChange = (field) => setDescriptionField(updateField(descriptionField, field));
  const handleImageChange = (field) => {
    const fieldValidator = validators[Input.TaskImages](field);
    setImageField(updateField(imageField, { ...field, validation: fieldValidator }));
    segmentTrack("Too Many Items Image Uploaded", { service: "Removal" });
  };

  useEffect(() => {
    const imageValidator = validators[Input.TaskImages](imageField);
    const stairsValidator = validators[Input.Stairs](stairsField);
    const dismantlingValidator = validators[Input.Dismantling](dismantlingField);
    setImageField(updateField(imageField, { ...imageField, validation: imageValidator }));
    setStairsField(updateField(stairsField, { ...stairsField, validation: stairsValidator }));
    setDismantlingField(updateField(dismantlingField, { ...dismantlingField, validation: dismantlingValidator }));
  }, []);

  return (
    <Box>
      <DescriptionField
        field={descriptionField}
        rows={8}
        minChars={25}
        width={"75%"}
        description={`For example, '3 big bookcases and a desk that will be located in a garage' 

Please include atleast 25 characters

`}
        onChange={handleDescriptionChange}
      />
      <br />
      <br />
      <Section title="Add pictures to get a better estimate (optional)" width={"100%"}>
        <ImageHandlerForm
          title="Please upload a pictures of the items you wish to remove"
          baseId={"temp"}
          basePath={"bookingImages"}
          images={imageField?.value || []}
          maxImageCount={10}
          destinationPath={"uploads"}
          setImageUrls={(_, images) => handleImageChange({ value: images })}
          loaderState={false}
          setLoaderState={() => {}}
          isDropzoneOnly={true}
        />
      </Section>
      <br />
      <br />
      {(isBidPromptValid || isDefaultBid) && (
        <PrivilegedComponent flag={"b2c-bids"}>
          <BidPrompt isBidMode={true} setIsBidMode={setIsBidMode} isDefaultBid={isDefaultBid} />
        </PrivilegedComponent>
      )}
      <NeedHelp />
    </Box>
  );
});

export default React.memo(RemovalBidTaskDescriptionForm);
