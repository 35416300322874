import React from "react";
import { Button } from "@material-ui/core";
import classes from "@css/components/website/PitchLeft.module.css";
import classNames from "classnames";
import { useHistory } from "react-router";
import TrustBox, { desktopTrustPilotLandingStyles } from "../../../shared/components/TrustPilot";

const HeroText = () => (
  <h3 className={classes.heroText}>
    About
    <br /> Dimension
  </h3>
);

const SubText = () => {
  const history = useHistory();
  return (
    <>
      <p className={classes.subText}>
        Dimension is the first tech-enabled service to radically simplify waste management for companies of any size, nationwide.
      </p>
      <p className={classes.subText}>
        Our solutions are customizable and on-demand, as perfectly suited to solving ad hoc or emergency issues as they are to dealing with regular
        pick-ups.
      </p>
      <div className={classes.buttonContainer}>
        <Button disableRipple className={classes.primaryButton} variant="contained" onClick={() => history.push("/booking?step=initial")}>
          Try Dimension
        </Button>
      </div>
    </>
  );
};

function Pitch() {
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classNames(classes.leftPane, classes.highLeft)}>
          <div className={classes.pitchContent}>
            <HeroText />
            <SubText />
            <TrustBox style="landing-widget" />
          </div>
        </div>
        <div className={classNames(classes.rightPane, classes.aboutUs)} />
      </div>
    </div>
  );
}
export default Pitch;
