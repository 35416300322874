import React from "react";
import { Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Input from "react-phone-number-input/input";
import PhoneField from "@shared/components/PhoneField";
import lookup from "@methods/lookup";

import "react-phone-number-input/style.css";

const InputField = ({ name, control, errors, type, showError, ...rest }) => {
  const error = lookup(errors, name);

  const restFields = {
    variant: "outlined",
    error: !!error,
    helperText: showError && (error?.message ?? null),
    ...rest,
  };

  return (
    <Controller
      name={name}
      as={
        ["phone"].includes(type.toLowerCase()) ? (
          <Input placeholder="Enter phone number" defaultCountry="US" inputComponent={PhoneField} fullWidth {...restFields} />
        ) : (
          <TextField type={type} {...restFields} />
        )
      }
      control={control}
    />
  );
};

InputField.defaultProps = {
  type: "text",
  onChange: () => {},
  errors: {},
  showError: true,
};

export default InputField;
