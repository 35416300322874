import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  productsGridContainer: {
    alignItems: "start",
    display: "grid",
    width: "100%",
    position: "relative",
    gridTemplateColumns: "max-content auto",
    padding: theme.spacing(0, 0, 1),
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  detailsGridItem: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    color: "#4F4F4F",
  },
  productItemText: {
    display: "grid",
    gridTemplateColumns: "40px auto",
  },
}));

function ProductItem({ icon, label, details, count }) {
  const classes = useStyles();
  return (
    <div className={classes.productsGridContainer}>
      <div className={classes.detailsGridItem}>{icon ?? ""}</div>
      <div className={`${classes.productItemText}`}>
        <Typography>{count}</Typography>
        <div style={{ position: "relative", width: "100%" }}>
          <Typography>{label}</Typography>
          {details && <Typography style={{ opacity: 0.7 }}>{details}</Typography>}
        </div>
      </div>
    </div>
  );
}

export default ProductItem;
