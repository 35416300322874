/* eslint-disable no-console */
import itemsTypes from "@store/constants/itemsTypes";
import tempDataConstants from "@store/constants/tempDataTypes";
import { useAxiosGet } from "../../axios";

export const fetchItemsWithPrices = () => async (dispatch) => {
  try {
    const response = await useAxiosGet("/config/fetch-items-prices");
    dispatch({
      type: itemsTypes.FETCH_ADMIN_ITEMS,
      items: response.data.items,
    });
  } catch (error) {
    console.log("Error fetch items: ", error);
    dispatch({ type: itemsTypes.RESET_ADMIN_ITEMS });
  }
};

export const fetchItemsWithoutPrices = () => async (dispatch) => {
  try {
    const response = await useAxiosGet("/config/fetch-items");
    dispatch({
      type: itemsTypes.FETCH_ITEMS,
      items: response.data.items,
    });
  } catch (error) {
    console.log("Error fetch items: ", error);
    dispatch({ type: itemsTypes.RESET_ITEMS });
  }
};

export const fetchLowestPrices = () => async (dispatch) => {
  try {
    const response = await useAxiosGet("/config/fetch-lowest-prices");
    dispatch({
      type: tempDataConstants.SET_LOWEST_PRICES,
      lowestPrices: response?.data,
    });
  } catch (error) {
    console.log("Error fetch items: ", error);
    dispatch({ type: itemsTypes.RESET_ITEMS });
  }
};
