/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, Grid, Typography, Box, TextField, Button, useMediaQuery } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import PhoneIcon from "@material-ui/icons/Phone";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import Rating from "@material-ui/lab/Rating";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import Divider from "@material-ui/core/Divider";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import Input from "react-phone-number-input/input";
import PhoneField from "@shared/components/PhoneField";
import { validateEmail } from "@helpers/validators";

import { updateProfile } from "@store/actions/users";
import { getAuthProvider } from "@helpers/auth";
import { validationSchema } from "../validate";

import PenaltyCard from "./PenaltyCard";
import ConfirmUpdateModal from "./ConfirmUpdateModal";
import Rewards from "./Rewards";
import AccountPaymentMethods from "./AccountPaymentMethods";
import { connectStripeAccount } from "@store/actions/auth";

import { privileges } from "../../../constants/privileges";

import PrivilegedComponent from "../../../shared/components/PrivilegedComponent";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: "5px 0px",
  },
  divider: {
    marginTop: "40px",
    marginBottom: "40px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  nameTitle: {
    fontWeight: "bold",
    fontSize: "25px",
  },
  editBtns: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    textDecoration: "underline",
    fontWeight: "bold",
    textAlign: "right",
    fontSize: "16px",
    padding: 0,
    margin: 0,
    border: "none",
    background: "transparent",
    "&:disabled": {
      color: theme.palette.grey[500],
      textDecoration: "none",
    },
  },
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
  },
  valid: {
    color: "#1E3B47",
  },
  inValid: {
    color: "red",
  },
  header: {
    display: "grid",
    gridTemplateColumns: "auto max-content",
    columnGap: 16,
    width: "100%",
    alignItems: "center",
  },
}));

const ProfileDetails = ({ isProvider, isCustomer, children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const fullNameRef = useRef(null);
  const phoneRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const profile = useSelector((state) => state.firebase.profile);
  const authFirebase = useSelector((state) => state.firebase.auth);
  const authError = useSelector((state) => state.auth.errorMessage);
  const lastAuthProvider = !authFirebase.isEmpty && getAuthProvider(authFirebase);

  const [pristine, setPristine] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [isConnectingStripe, setIsConnectingStripe] = useState(false);

  const { watch, setValue, control, errors, reset, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema()),
    defaultValues: {
      phoneNumber: profile.phoneNumber,
      fullName: profile.fullName,
      newEmail: profile.email,
      newPassword: "",
      oldPassword: "",
    },
  });

  const phoneNumber = watch("phoneNumber");
  const fullName = watch("fullName");
  const newEmail = watch("newEmail");
  const newPassword = watch("newPassword");
  const oldPassword = watch("oldPassword");

  const confirmationModalCondition = lastAuthProvider.allowEdits && !oldPassword && (!!newPassword || profile.email !== newEmail);
  // show if email and password auth, oldPassword is not entered, (newPassword is entered or email has changed)

  // update values again on refresh
  useEffect(() => {
    setValue("fullName", profile.fullName);
    setValue("newEmail", profile.email);
    setValue("phoneNumber", profile.phoneNumber);
  }, [profile.fullName, profile.email, profile.phoneNumber]);

  const handleUpdateDetails = () => {
    if (profile.phoneNumber === phoneNumber && profile.fullName === fullName && profile.email === newEmail && newPassword === "") {
      setPristine(false);
      return;
    }

    if (confirmationModalCondition) {
      // email and password login, then get old password for confirmation
      setConfirmModal(true);
      return;
    }

    if (!validateEmail(newEmail) || !phoneNumber || !isValidPhoneNumber(phoneNumber)) {
      // empty field will break isValidPhoneNumber
      return;
    }

    const newProfile = {
      phoneNumber,
      fullName,
      firstName: fullName.split(" ")[0],
      lastName: fullName.split(" ")[1],
    };

    const credentials = {
      currentPassword: oldPassword,
      newEmail,
      newPassword,
    };
    dispatch(updateProfile(newProfile, credentials));
    setPristine(false);
    setValue("oldPassword", "");
  };

  const cancelUpdateDetails = () => {
    reset();
    setConfirmModal(false);
    setPristine(false);
  };

  const connectToStripe = async () => {
    setIsConnectingStripe(true);
    await dispatch(connectStripeAccount());
    setIsConnectingStripe(false);
  };

  return (
    <>
      {confirmModal && (
        <ConfirmUpdateModal
          setValue={setValue}
          control={control}
          setConfirmModal={setConfirmModal}
          handleUpdateDetails={handleSubmit(handleUpdateDetails)}
        />
      )}
      <Box className={classes.root}>
        <form noValidate onSubmit={handleSubmit(handleUpdateDetails)}>
          <Grid container>
            <Grid item xs={12} sm={12} md={7} style={{ marginTop: isProvider && "25px" }}>
              <Box>
                <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                  <Box className={classes.header}>
                    <Controller
                      name="fullName"
                      render={({ onChange, value }) => (
                        <TextField
                          id="fullName"
                          value={value}
                          onChange={(value) => {
                            setPristine(true);
                            onChange(value);
                          }}
                          inputRef={fullNameRef}
                          InputProps={{
                            classes: {
                              underline: classes.underline,
                              input: classes.nameTitle,
                            },
                          }}
                        />
                      )}
                      control={control}
                      errors={errors}
                    />
                    {/* {profile.isVerified ? <VerifiedUserIcon style={{ color: "#74b78b" }} /> : <div />} */}
                    <EditOutlinedIcon color="primary" style={{ cursor: "pointer" }} onClick={() => fullNameRef.current.focus()} />
                    {isProvider ? (
                      <Rating
                        name="simple-controlled"
                        value={parseInt(profile.ratingTotal / profile.ratingCount)}
                        precision={0.5}
                        style={{ color: "#1E3B47", flex: "1" }}
                        size="small"
                        readOnly
                      />
                    ) : (
                      <div />
                    )}
                  </Box>
                </Box>
              </Box>
              <Box className={classes.divider}>
                <Divider />
              </Box>
              <Grid container style={{ marginBottom: "15px" }}>
                <Grid item xs={2} sm={1}>
                  <ExitToAppIcon fontSize="large" />
                </Grid>
                <Grid item xs={10} sm={11}>
                  <Typography style={{ fontSize: "16px", fontWeight: 500 }}> Login Type </Typography>
                  <Grid container>
                    <Grid item xs={8}>
                      <div style={{ color: "gray", width: "200px" }}> {lastAuthProvider.title} </div>
                      {lastAuthProvider.allowEdits && (
                        <Controller
                          name="newPassword"
                          render={({ onChange, value }) => (
                            <TextField
                              fullWidth
                              id="newPassword"
                              type="password"
                              value={value}
                              onChange={(value) => {
                                setPristine(true);
                                onChange(value);
                              }}
                              inputRef={passwordRef}
                              InputProps={{
                                classes: { underline: classes.underline },
                              }}
                            />
                          )}
                          control={control}
                        />
                      )}
                    </Grid>
                    <Grid item xs={4} className={classes.editBtns}>
                      {lastAuthProvider.allowEdits && (
                        <button className={classes.editBtns} onClick={() => passwordRef.current.focus()}>
                          Edit password
                        </button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container style={{ marginBottom: "15px" }}>
                <Grid item xs={2} sm={1}>
                  <AlternateEmailIcon fontSize="large" />
                </Grid>
                <Grid item xs={10} sm={11}>
                  <Typography style={{ fontSize: "16px", fontWeight: 500 }}> Email </Typography>
                  <Grid container>
                    <Grid item xs={8}>
                      <Controller
                        name="newEmail"
                        render={({ onChange, value }) => (
                          <TextField
                            id="newEmail"
                            inputRef={emailRef}
                            value={value}
                            onChange={(value) => {
                              setPristine(true);
                              onChange(value);
                            }}
                            fullWidth
                            disabled={!lastAuthProvider.allowEdits}
                            InputProps={{
                              classes: {
                                underline: `${classes.underline} ${errors.newEmail ? classes.inValid : classes.valid}`,
                              },
                            }}
                          />
                        )}
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={4} className={classes.editBtns}>
                      {lastAuthProvider.allowEdits && (
                        <button className={classes.editBtns} onClick={() => emailRef.current.focus()}>
                          Edit
                        </button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container style={{ marginBottom: "15px" }}>
                <Grid item xs={2} sm={1}>
                  <PhoneIcon fontSize="large" />
                </Grid>
                <Grid item xs={10} sm={11}>
                  <Typography style={{ fontSize: "16px", fontWeight: 500 }}> Phone Number </Typography>
                  <Grid container>
                    <Grid item xs={8}>
                      <Controller
                        name="phoneNumber"
                        render={({ onChange, value }) => {
                          const phoneNumber = value ? (value.startsWith("+") ? value : "+1" + value) : null;
                          return (
                            <Input
                              fullWidth
                              placeholder="Enter phone number"
                              defaultCountry="US"
                              value={phoneNumber}
                              ref={phoneRef}
                              onChange={(value) => {
                                setPristine(true);
                                onChange(value);
                              }}
                              InputProps={{
                                classes: { underline: classes.underline },
                              }}
                              inputComponent={PhoneField}
                              error={!!errors.phoneNumber}
                            />
                          );
                        }}
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={4} className={classes.editBtns}>
                      <button
                        className={classes.editBtns}
                        onClick={() => {
                          phoneRef.current.focus();
                        }}
                      >
                        {phoneNumber ? "Edit" : "Add"}
                      </button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {isProvider && (
                <Grid container style={{ marginBottom: "15px" }}>
                  <Grid item xs={2} sm={1}>
                    <AttachMoneyIcon fontSize="large" />
                  </Grid>
                  <Grid item xs={10} sm={11}>
                    <Typography style={{ fontSize: "16px", fontWeight: 500 }}>Connect your bank to get paid</Typography>
                    <Grid container>
                      <Grid item xs={8} style={{ color: "gray" }}>
                        {profile?.stripeConnected ? "Completed" : "Not complete"}
                      </Grid>
                      <Grid item xs={4} className={classes.editBtns}>
                        <button className={classes.editBtns} disabled={isConnectingStripe} onClick={connectToStripe}>
                          {isConnectingStripe ? "Connecting..." : profile?.stripeConnected ? "Track your payments" : "Start setup"}
                        </button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {pristine && (
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="contained"
                      style={{
                        height: "40px",
                        width: "100%",
                        marginTop: "20px",
                      }}
                      onClick={cancelUpdateDetails}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <PrivilegedComponent
                      everyPrivileges={isAdmin ? [privileges.MIMIC_LOGIN_USER_EDIT_PROFILE.name] : [privileges.EDIT_ACCOUNT_SETTINGS.name]}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{
                          height: "40px",
                          width: "100%",
                          marginTop: "20px",
                        }}
                        disabled={!!errors.phoneNumber || !!errors.newEmail}
                      >
                        Update
                      </Button>
                    </PrivilegedComponent>
                  </Grid>
                </Grid>
              )}

              <div style={{ color: "red", textAlign: "center", height: "20px" }}>{authError ? <p>{authError}</p> : null}</div>

              {isCustomer && <AccountPaymentMethods />}
              {!isDesktop && (
                <Grid item xs={12} sm={12} md={4} style={{ marginTop: isDesktop ? "-120px" : "10px" }}>
                  {isProvider && <Rewards ratings={profile?.fiveStars} profile={profile} />}
                  {isProvider && <PenaltyCard points={profile?.penaltyPoints || 0} />}
                </Grid>
              )}
              <Grid container style={{ marginBottom: "15px" }}>
                {children ?? <div />}
              </Grid>
            </Grid>
            <Grid item xs={false} sm={false} md={1}></Grid>
            {isDesktop && (
              <Grid item xs={12} sm={12} md={4} style={{ marginTop: isDesktop ? "-120px" : "10px" }}>
                {isProvider && <Rewards ratings={profile?.fiveStars} profile={profile} />}
                {isProvider && <PenaltyCard points={profile?.penaltyPoints || 0} />}
              </Grid>
            )}
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default ProfileDetails;
