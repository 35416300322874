import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, ButtonGroup, Divider, Grid, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import { ViewList, GridOn } from "@material-ui/icons";

import DesktopFilter from "./DesktopFilter";
import MobileFilter from "./MobileFilter";
import StatusTabs from "../../MyOrders/OrdersPage/StatusTabs";
import { bookingStatus } from "../../../constants/booking";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "auto max-content",
    alignItems: "center",
    padding: theme.spacing(2, 1, 1, 4),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: theme.spacing(0, 1, 1, 1),
    },
  },
  filterContainers: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  taskTypesBox: {
    display: "flex",
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "space-evenly",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "15px",
      "& > h5": {
        marginRight: "40px",
      },
    },
    marginTop: "20px",
    marginBottom: "20px",
    "& > .active": {
      color: theme.palette.primary.main,
      borderBottom: "2px solid",
    },
    "& > .inactive": {
      color: "rgba(0, 0, 0, .34)",
      "&:hover": {
        color: "rgba(0, 0, 0)",
        borderBottom: "2px solid",
      },
    },
  },
  viewToggle: {
    marginLeft: theme.spacing(1),
    "& > * > *": {
      height: "40px",
      "&:first-child": {
        border: "none",
      },
    },
  },
  activeTab: {
    color: "#1E3B47",
    fontSize: "16px",
    fontWeight: "900",
    marginTop: "10px",
    marginBottom: "10px",
    cursor: "pointer",
    marginRight: "20px",
  },
  normalTab: {
    color: "#1E3B47",
    fontSize: "16px",
    fontWeight: "900",
    marginTop: "13px",
    marginBottom: "10px",
    cursor: "pointer",
    marginRight: "20px",
  },
  activeDivider: { margin: "10px 0", height: "4px", backgroundColor: "green" },
  normalDivider: { margin: "10px 0", height: "1px", backgroundColor: "green" },
}));

const ViewToggle = ({ setView }) => {
  const classes = useStyles();
  return (
    <div className={classes.viewToggle}>
      <ButtonGroup size="small" variant="text">
        <Button onClick={() => setView("list")}>
          <ViewList color="primary" />
        </Button>
        <Button onClick={() => setView("card")}>
          <GridOn color="primary" />
        </Button>
      </ButtonGroup>
    </div>
  );
};

function FilterBar({ headerSearch, setView, applyFilter, showMobileFilter, setShowMobileFilter, placeholder }) {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:984px)");
  const [filter, setFilter] = useState({});

  useEffect(() => {
    const newFilter = { ...filter, searchQuery: headerSearch };
    applyFilter(newFilter);
    setFilter(newFilter);
  }, [headerSearch]);
  return (
    <div className={classes.container}>
      {!isMobile && (
        <div className={classes.filterContainers}>
          <DesktopFilter filter={filter} setFilter={setFilter} applyFilter={applyFilter} placeholder={placeholder} />
          <ViewToggle setView={setView} />
        </div>
      )}
      {isMobile && showMobileFilter && (
        <MobileFilter filter={filter} setFilter={setFilter} applyFilter={applyFilter} setShowMobileFilter={setShowMobileFilter} />
      )}
    </div>
  );
}

FilterBar.propTypes = {
  activeStatus: PropTypes.string,
  setActiveStatus: PropTypes.func,
  clearAllFilters: PropTypes.func,
  setView: PropTypes.func,
};

export default FilterBar;
