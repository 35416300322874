import React from "react";
import { Button, Typography } from "@material-ui/core";
import howWeWork from "../../../assets/landing_page/how-we-work.jpg";
import BusinessIcon from "../../../assets/landing_page/business-icon.svg";
import IndividualIcon from "../../../assets/landing_page/individual-icon.svg";
import classes from "@css/components/website/HowWeWork.module.css";
import classNames from "classnames";
import { useHistory } from "react-router";
import { segmentTrack } from "../../../helpers/segment";
import { KustomerLauncher } from "../../../containers/KustomerLauncher";

function HowWeWork() {
  const history = useHistory();
  const handleBookNow = (tracker: string, params?: string) => {
    segmentTrack("Click", {
      button: tracker,
    });
    history.push(`booking?step=initial${params ?? ""}`);
  };
  return (
    <>
      <section className={classes.container}>
        <div className={classes.mainContent}>
          <div style={{ backgroundImage: `url(${howWeWork})` }} className={classes.image} />
          <div className={classes.textContainer}>
            <Typography className={classNames(classes.heading, classes.topHeading)}>How Dimension Works</Typography>
            <h2 className={classNames(classes.heading, classes.mainHeading)}>Solving your waste management woes is as simple as 1,2,3!</h2>
            <div className={classes.stepWrapper}>
              <ul className={classes.StepProgress}>
                <li className={classes.StepProgressItem}>
                  <div className={classes.stepContent}>
                    <Typography className={classes.description}>
                      <strong>Enter your ZIP code</strong>, then choose
                      <br className={classes.break} /> a convenient time and date for pickup
                    </Typography>
                    <span className={classes.stepNumber}>01</span>
                  </div>
                </li>
                <li className={classes.StepProgressItem}>
                  <div className={classes.stepContent}>
                    <Typography className={classes.description}>
                      <strong>Get</strong> an instant, upfront <strong>quote</strong>
                    </Typography>
                    <span className={classes.stepNumber}>02</span>
                  </div>
                </li>
                <li className={classes.StepProgressItem}>
                  <div className={classes.stepContent}>
                    <Typography className={classes.description}>
                      <strong>Make a booking</strong> in seconds
                    </Typography>
                    <span className={classes.stepNumber}>03</span>
                  </div>
                </li>
              </ul>
            </div>
            <Typography className={classNames(classes.heading, classes.smallHeading)}>It&apos;s that easy! </Typography>
            <Typography className={classes.description}>
              Once you&apos;ve made your booking, we&apos;ll provide you with the contact information of your reliable waste management provider. If
              you have any questions or want to make a change, you can easily contact us directly by SMS, call or email.
            </Typography>
          </div>
        </div>
      </section>
      <section className={classNames(classes.container, classes.boxSection)}>
        <div className={classes.mainContent}>
          <Typography className={classNames(classes.heading, classes.secondaryHeading)}>Try It Now</Typography>
          <div className={classes.boxContainer}>
            <div className={classes.box}>
              <div className={classes.boxContent}>
                <img className={classes.boxIcon} src={BusinessIcon} />
                <Typography className={classNames(classes.heading, classes.extraSmallHeading)}>Business</Typography>
                <Button
                  disableRipple
                  className={classes.primaryButton}
                  variant="contained"
                  onClick={() => handleBookNow("Try It Now - Business", "&business_client=1")}
                >
                  Book Now
                </Button>
              </div>
            </div>
            <div className={classes.box}>
              <div className={classes.boxContent}>
                <img className={classes.boxIcon} src={IndividualIcon} />
                <Typography className={classNames(classes.heading, classes.extraSmallHeading)}>Individual</Typography>
                <Button disableRipple className={classes.primaryButton} variant="contained" onClick={() => handleBookNow("Try It Now - Individual")}>
                  Book Now
                </Button>
              </div>
            </div>
            <div className={classNames(classes.box, classes.boxFilled)}>
              <div className={classes.boxContent}>
                <Typography className={classNames(classes.heading, classes.extraSmallHeading)}>
                  Complex tasks
                  <br />
                  that need a custom
                  <br />
                  solution?
                </Typography>
                <Button disableRipple style={{ width: "171px", marginTop: 12 }} className={classes.primaryButton} variant="contained">
                  <KustomerLauncher>Chat with us</KustomerLauncher>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HowWeWork;
