import React, { useEffect } from "react";
import PhoneInput from "react-phone-number-input/input";
import PhoneField from "@shared/components/PhoneField";

import useStyles from "./useStyles";
import { valid } from "../validators";

function PhoneFieldComponent({ field, validator, onChange, label, error, helperText }) {
  const styles = useStyles();

  const value = field.value || "";

  useEffect(() => {
    (async () => {
      const validation = await validator(field);
      onChange({ validation });
    })();
  }, [value]);

  const handleChange = (nextValue) => {
    onChange({
      value: nextValue,
    });
  };

  const acceptCarrierType = () => {
    onChange({
      validation: valid,
    });
  };

  return (
    <>
      <PhoneInput
        fullWidth
        placeholder="Enter mobile number"
        defaultCountry="US"
        variant="outlined"
        value={value}
        label={label}
        onChange={handleChange}
        inputComponent={PhoneField}
        error={error}
        helperText={
          <>
            {helperText}
            {helperText && field?.validation?.invalidCarrierType && (
              <span className={styles.linkBtn} onClick={acceptCarrierType}>
                I only have a {field?.validation?.carrierType} number.
              </span>
            )}
          </>
        }
      />
    </>
  );
}

export default PhoneFieldComponent;
