import React, { useCallback, useState, forwardRef } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

import useMountedEffect from "@shared/hooks/useMountedEffect";
import NeedHelp from "@shared/components/NeedHelp";

import "react-phone-number-input/style.css";

import Spacer from "@shared/components/Spacer";

import validators from "@booking/helpers/validators";
import { isError, errorCode } from "@booking/helpers/utils";
import { updateField } from "@booking/helpers/utils";
import useTriggerValidation from "@shared/hooks/useTriggerValidation";
import { Input } from "@booking/helpers/enums";

import TextField from "../fields/TextField";
import PhoneField from "../fields/PhoneField";

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(4),
  },
  columns: {
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
  leftColumn: {
    [theme.breakpoints.up("lg")]: {
      flex: 1,
      paddingRight: theme.spacing(2),
    },
  },
  rightColumn: {
    [theme.breakpoints.up("lg")]: {
      flex: 1,
      paddingLeft: theme.spacing(2),
    },
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

const PaymentInformationForm = forwardRef(({ defaultValue, onChange, isBidMode }, ref) => {
  const styles = useStyles();
  const [paymentFirstNameField, setPaymentFirstNameField] = useState(defaultValue[Input.PaymentFirstName]);
  const [paymentLastNameField, setPaymentLastNameField] = useState(defaultValue[Input.PaymentLastName]);
  const [paymentEmailField, setPaymentEmailField] = useState(defaultValue[Input.PaymentEmail]);
  const [paymentPhoneField, setPaymentPhoneField] = useState(defaultValue[Input.PaymentPhone]);

  useMountedEffect(() => {
    onChange({
      [Input.PaymentFirstName]: paymentFirstNameField,
      [Input.PaymentLastName]: paymentLastNameField,
      [Input.PaymentEmail]: paymentEmailField,
      [Input.PaymentPhone]: paymentPhoneField,
    });
  }, [paymentFirstNameField, paymentLastNameField, paymentEmailField, paymentPhoneField]);

  useTriggerValidation(ref, [setPaymentFirstNameField, setPaymentLastNameField, setPaymentEmailField, setPaymentPhoneField]);

  const handlePaymentFirstNameFieldChange = useCallback(
    (field) => {
      setPaymentFirstNameField(updateField(paymentFirstNameField, field));
    },
    [paymentFirstNameField]
  );

  const handlePaymentLastNameFieldChange = useCallback(
    (field) => {
      setPaymentLastNameField(updateField(paymentLastNameField, field));
    },
    [paymentLastNameField]
  );

  const handlePaymentEmailFieldChange = useCallback(
    (field) => {
      let updatedField = field;
      if (updatedField?.value) {
        updatedField.value = updatedField.value?.trim();
      }
      setPaymentEmailField(updateField(paymentEmailField, updatedField));
    },
    [paymentEmailField]
  );

  const handlePaymentPhoneFieldChange = useCallback(
    async (field) => {
      setPaymentPhoneField(updateField(paymentPhoneField, field));
    },
    [paymentPhoneField]
  );

  return (
    <Box>
      {isBidMode ? (
        <Typography className={styles.text} style={{ maxWidth: 500 }}>
          Enter the following information to process your order and get you the best price for your quote as soon as possible.
        </Typography>
      ) : (
        <Typography className={styles.text}>
          Enter the following information to process
          <br />
          your payment with <b>Stripe.</b>
        </Typography>
      )}
      <Box className={styles.columns}>
        <Box className={styles.leftColumn}>
          <TextField
            label="First Name"
            field={paymentFirstNameField}
            validator={validators[Input.PaymentFirstName]}
            className={styles.textField}
            onChange={handlePaymentFirstNameFieldChange}
            error={isError(paymentFirstNameField.validation)}
            helperText={errorCode(paymentFirstNameField.validation)}
          />
          <TextField
            label="Last Name"
            field={paymentLastNameField}
            validator={validators[Input.PaymentLastName]}
            className={styles.textField}
            onChange={handlePaymentLastNameFieldChange}
            error={isError(paymentLastNameField.validation)}
            helperText={errorCode(paymentLastNameField.validation)}
          />
        </Box>
        <Box className={styles.rightColumn}>
          <TextField
            label="E-mail"
            field={paymentEmailField}
            validator={validators[Input.PaymentEmail]}
            className={styles.textField}
            onChange={handlePaymentEmailFieldChange}
            error={isError(paymentEmailField.validation)}
            helperText={errorCode(paymentEmailField.validation)}
          />
          <PhoneField
            label="Mobile Number"
            field={paymentPhoneField}
            validator={validators[Input.PaymentPhone]}
            onChange={handlePaymentPhoneFieldChange}
            error={isError(paymentPhoneField.validation)}
            helperText={errorCode(paymentPhoneField.validation)}
          />
        </Box>
      </Box>
      <Spacer />
      <NeedHelp />
    </Box>
  );
});

export default React.memo(PaymentInformationForm);
