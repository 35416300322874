/* eslint-disable no-console */
import { useAxiosGet, useAxiosPost } from "../../axios";
import { toastr } from "react-redux-toastr";

export const createUserImages = async (userImages) => {
  try {
    const { data } = await useAxiosPost("users/add-user-image", userImages);
    if (data?.success) {
      console.log("Images added successfully");
    } else {
      throw new Error(data?.error?.message || "Error adding images");
    }
  } catch (error) {
    console.log("Images Add Error: ", error?.message);
    toastr.error("Error", error?.message);
  }
};

export const fetchUserImages = async (userId) => {
  try {
    const response = await useAxiosGet(`/users/fetch-user-images/${userId}`);
    if (response.data?.success) {
      return response.data.data || [];
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error in fetching user images", error.message);
    toastr.error("Error", error.message || "Something went wrong !");
    return [];
  }
};

export const isDuplicateImage = async (userImages) => {
  try {
    const { data } = await useAxiosPost("users/is-duplicate-image", userImages);
    if (data?.success) {
      console.log("Images compared successfully");
      return data.isMatch;
    } else {
      throw new Error(data?.error?.message || "Error adding images");
    }
  } catch (error) {
    console.log("Images Add Error: ", error?.message);
    toastr.error("Error", error?.message);
    return false;
  }
};
