import React, { useState, useEffect } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

import ConfirmAccount from "./ConfirmAccount";
import PossiblePenalty from "./PossiblePenalty";
import TermsAndPolicy from "./TermsAndPolicy";
import CollectPaymentInfo from "@shared/components/CollectPaymentInfo";
import AlertDialog from "@shared/components/AlertDialog";

import { setClaimTask } from "@store/actions/booking";
import { fetchSetupIntent } from "@store/actions/payments";
import { segmentTrack } from "../../../helpers/segment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    [theme.breakpoints.up("xs")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
      paddingTop: theme.spacing(9),
    },
  },
  container: {
    minHeight: "80vh",
  },
  backBtn: {
    height: "30px",
    marginBottom: "10px",
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.hover,
    },
  },
  renderContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
  },
}));

const AcceptTask = ({ close, isEarlyClaim }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [showPaymentModal, setShowPaymentmodal] = useState(false);
  const [paymentMethodSaved, setPaymentMethodSaved] = useState(false);
  const [showAlertStripe, setShowAlertStripe] = useState(false);
  const [isEarly] = useState(isEarlyClaim);

  const bookingId = useParams().id;
  const user = useSelector((state) => state.firebase.auth);
  const paymentMethod = useSelector((state) => state.firebase.profile.paymentMethod);

  const handleClaimTask = (task_id, user_id) => {
    segmentTrack("Warrior Clicks Accept - Task Details", { bookingId: bookingId ?? "" });
    dispatch(setClaimTask({ task_id, user_id, isEarlyClaim }));
  };

  const handlePaymentMethod = async () => {
    await dispatch(fetchSetupIntent());
    setShowPaymentmodal(true);
    segmentTrack("Warrior Clicks Okay - Stripe Payment Info", { bookingId: bookingId ?? "" });
  };

  const handleClosePayment = () => {
    setShowPaymentmodal(false);
    setPaymentMethodSaved(true);
    segmentTrack("Warrior Clicks Save - Credit Card", { bookingId: bookingId ?? "" });
  };

  const handleAlertStripeDialog = () => {
    setShowAlertStripe(false);
    setStep(2);
  };

  const handlePenaltyAccept = () => {
    if (paymentMethod) {
      setStep(2);
    } else {
      handlePaymentMethod();
    }
    segmentTrack("Warrior Clicks Agree - Possible Penalties", { bookingId: bookingId ?? "" });
  };

  const handleConfirmAccount = () => {
    setStep(1);
    segmentTrack("Warrior Clicks Confirm - Confirm Account", { bookingId: bookingId ?? "" });
  };

  useEffect(() => {
    paymentMethodSaved && paymentMethod && setShowAlertStripe(true);
  }, [paymentMethod]);

  const acceptSteps = [
    {
      component: <ConfirmAccount next={handleConfirmAccount} />,
      title: "Confirm your account",
      back: () => close(),
    },
    {
      component: <PossiblePenalty next={handlePenaltyAccept} />,
      title: "Possible penalties",
      back: () => setStep(0),
    },
    {
      component: (
        <TermsAndPolicy
          next={() => {
            segmentTrack("Warrior Clicks Continue - Terms and Policy", { bookingId: bookingId ?? "" });
            handleClaimTask(bookingId, user.uid);
            close();
          }}
        />
      ),
      title: "Terms & Policy",
      back: () => setStep(1),
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div
          style={{
            display: "flex",
            justifyItems: "center",
            alignItems: "center",
          }}>
          <KeyboardBackspaceIcon className={classes.backBtn} fontSize="large" onClick={acceptSteps[step].back} />
          <Typography
            style={{
              fontSize: "27px",
              fontWeight: "900",
              margin: "0px 0px 10px 15px",
            }}>
            {acceptSteps[step].title}
          </Typography>
        </div>
        {showPaymentModal && <CollectPaymentInfo open={showPaymentModal} onClose={() => handleClosePayment()} showWarning={true} />}
        {showAlertStripe && (
          <AlertDialog
            title="Stripe Payment Info"
            body="Your credit card information was successfully saved (but not charged at this time)"
            agreeBtnText="Okay"
            hideDisagreeBtn
            onClose={handleAlertStripeDialog}
            handleProceed={handleAlertStripeDialog}
          />
        )}
        <div className={classes.renderContainer}>{acceptSteps[step].component}</div>
      </div>
    </div>
  );
};

export default AcceptTask;
