import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import {
  Button,
  ButtonGroup,
  Chip,
  FormControl,
  FormControlLabel,
  InputBase,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { ViewList, GridOn, Search } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
import Dropdown from "@shared/components/Dropdown";
import { taskTypes } from "@constants/booking";
import classNames from "classnames";
import { DefaultDropdownState } from "../constants";

const useStyles = makeStyles((theme) => ({
  resetFilters: {
    color: "red",
    fontWeight: "300",
    fontSize: "15px",
    lineHeight: "1.3",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem",
    },
  },
  filterButtonsContainer: {
    width: "100%",
    alignItems: "center",
    display: "flex",
  },
  filterButton: {
    fontSize: "clamp(0.8rem, 0.6rem + 0.3333vw, 1rem)",
    fontWeight: "bold",
    borderColor: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    whiteSpace: "nowrap",
    cursor: "pointer",
    "& span:first-child": {
      lineHeight: "1.2",
    },
  },
  dropdownMenu: {
    display: "flex",
    flexDirection: "column",
  },
  dropdownHeader: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
  },
  dropdownButton: {
    alignSelf: "flex-end",
  },
  search: {
    flexGrow: "1",
    display: "flex",
    alignItems: "center",
    minWidth: "200px",
    maxWidth: "300px",
    backgroundColor: "#f1f1f1",
    borderRadius: "30px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
    },
  },
  searchIcon: {
    margin: theme.spacing(0, 1),
    color: theme.palette.primary.main,
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputInput: {
    fontSize: "clamp(0.8rem, 0.6rem + 0.3333vw, 1rem)",
    padding: theme.spacing(1, 2, 1, 0),
    height: "17px",
  },
}));

const FilterButton = ({ isActive, onClick, text }) => {
  const classes = useStyles();
  const buttonAction = isActive ? { onDelete: onClick, variant: "default" } : { variant: "outlined" };
  return <Chip className={classes.filterButton} label={text} color="primary" {...buttonAction} />;
};

function DesktopFilter({ filter, setFilter, applyFilter, placeholder }) {
  const classes = useStyles();
  const [openDropdown, setOpenDropdown] = useState(DefaultDropdownState);
  const [showClearFilterBtn, setShowClearFilterBtn] = useState(false);

  const taskTypeRef = useRef(null);
  const taskDateRef = useRef(null);
  const taskLocationRef = useRef(null);

  const handleSingleFilterApply = (field, value) => {
    const newFilter = { ...filter, [field]: value };
    applyFilter(newFilter);
    setFilter(newFilter);
    setOpenDropdown({ [field]: false });
  };

  const handleApplyFilter = (appliedFilter, drpDownRef) => {
    applyFilter(filter);
    setShowClearFilterBtn(true);
    setOpenDropdown({ ...openDropdown, [appliedFilter]: false });
    drpDownRef.current.close();
  };

  const clearAllFilters = () => {
    applyFilter();
    setShowClearFilterBtn(true);
    setOpenDropdown(DefaultDropdownState);
  };

  const filterButtonAction = (isActive, filterType) =>
    isActive ? () => handleSingleFilterApply(filterType, null) : () => setOpenDropdown({ ...openDropdown, filterType: true });

  return (
    <div className={classes.filterButtonsContainer}>
      {showClearFilterBtn && (
        <Button onClick={clearAllFilters} className={classes.resetFilters}>
          Clear Filters
        </Button>
      )}
      <Dropdown
        component={
          <FilterButton
            isActive={!!filter.filterTaskType}
            text={filter.filterTaskType ? taskTypes[filter.filterTaskType].label : "Task Type"}
            onClick={filterButtonAction(!!filter.filterTaskType, "filterTaskType")}
          />
        }
        ref={taskTypeRef}>
        <div className={classes.dropdownMenu}>
          <Typography variant="body1" className={classes.dropdownHeader}>
            Select task Type
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup aria-label="taskType" name="taskType" value={filter.filterTaskType}>
              {Object.values(taskTypes).map((task, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    className={classes.normalCheckbox}
                    control={<Radio color="primary" />}
                    label={task.label}
                    value={task.value}
                    onChange={(e) => setFilter({ ...filter, filterTaskType: e.target.value })}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
          <Button color="primary" className={classes.dropdownButton} onClick={() => handleApplyFilter("filterTaskType", taskTypeRef)}>
            Apply
          </Button>
        </div>
      </Dropdown>
      <Dropdown
        component={
          <FilterButton isActive={!!filter.filterDateValue} text="Date" onClick={filterButtonAction(!!filter.filterDateValue, "filterDateValue")} />
        }
        ref={taskDateRef}>
        <div className={classes.dropdownMenu}>
          <Typography variant="body1" className={classes.dropdownHeader}>
            Select task Date
          </Typography>
          <DatePicker
            label=""
            value={filter.filterDateValue}
            inputVariant="outlined"
            placeholder="Select a date"
            onChange={(value) => setFilter({ ...filter, filterDateValue: value })}
          />
          <Button color="primary" className={classes.dropdownButton} onClick={() => handleApplyFilter("filterDateValue", taskDateRef)}>
            Apply
          </Button>
        </div>
      </Dropdown>
      <Dropdown
        right
        component={
          <FilterButton
            isActive={!!filter.filterLocation}
            text={filter.filterLocation || "Location"}
            onClick={filterButtonAction(!!filter.filterLocation, "filterLocation")}
          />
        }
        ref={taskLocationRef}>
        <div className={classes.dropdownMenu}>
          <Typography variant="body1" className={classes.dropdownHeader}>
            Select task location
          </Typography>
          <TextField
            id="location"
            label="Enter ZIP code"
            variant="outlined"
            value={filter.filterLocation}
            onChange={(e) => setFilter({ ...filter, filterLocation: e.target.value })}
          />
          <Button color="primary" className={classes.dropdownButton} onClick={() => handleApplyFilter("filterLocation", taskLocationRef)}>
            Apply
          </Button>
        </div>
      </Dropdown>
      <div className={classes.search}>
        <Search className={classes.searchIcon} />
        <InputBase
          placeholder={placeholder || "Search a past booking.."}
          classes={{
            input: classes.inputInput,
          }}
          inputProps={{ "aria-label": "search" }}
          value={filter.searchQuery ?? ""}
          onChange={(e) => handleSingleFilterApply("searchQuery", e.target.value)}
        />
      </div>
    </div>
  );
}

DesktopFilter.propTypes = {
  filter: PropTypes.object,
  handleApplyFilter: PropTypes.func,
  handleSingleFilterApply: PropTypes.func,
};

export default DesktopFilter;
