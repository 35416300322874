import React from "react";
import classes from "@css/components/website/BusinessServices.module.css";
import { Button, Typography, useMediaQuery } from "@material-ui/core";
import waste from "../../../assets/landing_page/b2b-services/waste-trash.jpg";
import recycling from "../../../assets/landing_page/b2b-services/recycling.jpg";
import classNames from "classnames";
import { useHistory } from "react-router";
import { segmentTrack } from "../../../helpers/segment";

const services = [
  {
    image: waste,
    header: "Waste & Trash Pickup Services",
    features: [
      "Bulk items, appliance & junk hauling",
      "Food waste pick up",
      "Hazardous waste",
      "Hazardous compost management",
      "Construction debris & aerosols",
    ],
    tracker: "Waste & Trash Pickup Services",
  },
  {
    image: recycling,
    header: "Business Recycling Services",
    features: ["Cardboard", "Paper", "Electronics and recycle", "Mixed and commingling recycling"],
    params: "&task=cardboard-removal",
    tracker: "Business Recycling Services",
  },
];

function BusinessServices() {
  const isNotMobile = useMediaQuery("(min-width:650px)");
  const history = useHistory();
  const handleClick = (service: any) => {
    segmentTrack("Click", {
      button: service.tracker,
    });
    history.push(`/booking?business_client=1${service.params ? service.params : ""}`);
  };

  return (
    <section className={classes.container}>
      <div className={classes.mainContent}>
        <h2 className={classes.header}>
          Commercial Trash
          <br />
          Disposal Services, Recycling,
          <br />
          Bulk Item Hauling & More
        </h2>
        <h4 className={classes.subHeader}>
          Dimension is equipped to handle anything,
          <br />
          including jobs your local municipality won’t take.
        </h4>
        <p className={classes.description}>
          Whether you have one business location or one hundred, our wide range of waste solutions, environmental compliance expertise and business
          efficiencies will help us find the perfect solution for your business.
        </p>
        <div className={classes.boxContainer}>
          {services.map((service) => (
            <div key={service.header} className={classes.box}>
              <img className={classes.boxImage} src={service.image} alt={service.header} />
              <div className={classes.boxContent}>
                <h4 className={classNames(classes.subHeader, classes.boxHeading)}>{service.header}</h4>
                <ul className={classes.boxList}>
                  {service.features.map((feature) => (
                    <li key={feature}>{feature}</li>
                  ))}
                </ul>
              </div>
              <Button disableRipple className={classes.primaryButton} onClick={() => handleClick(service)}>
                Schedule Your {isNotMobile && "First"} Pick Up
              </Button>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default BusinessServices;
