import { toastr } from "react-redux-toastr";
import { useAxiosPost, useAxiosGet } from "../../axios";
import { segmentTrack } from "../../helpers/segment";
import bookingQuotesTypes, { bookingBidTypes } from "../constants/bookingQuoteTypes";
import querystring from "query-string";

export const createAdminBookingBid =
  (form, admin, callback, notify = false) =>
  async (dispatch) => {
    try {
      const response = await useAxiosPost(`/booking-bids/create-admin-booking-bid`, { form, admin: admin.email, notify });
      const { data, success, error } = response?.data;
      if (!success) throw new Error(error);
      toastr.success("Done", `Bid successfully created ${data.id}`);
      dispatch({
        type: bookingBidTypes.ADD_BID,
        payload: data,
      });
      dispatch({
        type: bookingBidTypes.SELECT_BID,
        payload: data,
      });
      callback?.(data.id);
      return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return false;
    }
  };

export const createBookingBid = (form, callback) => async (dispatch) => {
  try {
    const response = await useAxiosPost(`/booking-bids/create-booking-bid`, { form });
    const { data, success, error } = response?.data;
    if (!success) throw new Error(error);
    toastr.success("Done", `Request has been submitted successfully`);
    dispatch({
      type: bookingBidTypes.ADD_BID,
      payload: data,
    });
    dispatch({
      type: bookingBidTypes.SELECT_BID,
      payload: data,
    });
    segmentTrack("Customer Booking Bid Created", {
      is_business: data.businessClient,
      bidId: data.id ?? "",
      bidNumber: data.bidNumber ?? "",
      service_name: data.task ?? "",
      phone: data.phone_number ?? "",
      email: data.email ?? "",
    });
    callback?.(data.bidNumber);
    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return false;
  }
};

export const updateAdminBookingBid =
  (form, admin, notify = false) =>
  async (dispatch) => {
    try {
      const response = await useAxiosPost(`/booking-bids/update-admin-booking-bid/${form.id}`, { form, admin: admin.email });
      const { data, success, error } = response?.data;
      if (!success) throw new Error(error);
      toastr.success("Done", `Bid successfully updated ${data.id}`);
      dispatch({
        type: bookingBidTypes.UPDATE_BID,
        payload: data,
      });
      return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

export const fetchBookingBidData = async (bookingBidId) => {
  try {
    const response = await useAxiosGet(`/booking-bids/get-booking-bid/${bookingBidId}`);
    const { success, data, error } = response?.data;
    if (!success) throw new Error(error);
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return null;
  }
};
export const fetchBookingBid = (bookingBidId) => async (dispatch) => {
  const data = await fetchBookingBidData(bookingBidId);
  dispatch({ type: bookingBidTypes.SELECT_BID, payload: data });
};
export const fetchBookingBids = (uid) => async (dispatch) => {
  dispatch({ type: bookingBidTypes.SET_LOADING, payload: true });
  try {
    const response = await useAxiosPost("/booking-bids/get-booking-bids", { uid });
    const { success, data, error } = response?.data;
    if (!success) throw new Error(error);
    dispatch({ type: bookingBidTypes.SET_BIDS, payload: data });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    dispatch({ type: bookingBidTypes.SET_LOADING, payload: false });
  }
};
// To get the bids from the warrior on a booking
export const fetchBookingBidWarriorBids = (bookingBidId) => async (dispatch) => {
  try {
    const response = await useAxiosGet(`/booking-bids/get-booking-bid-warrior-bids/${bookingBidId}`);
    const { data } = response?.data;
    dispatch({ type: bookingBidTypes.SET_WARRIOR_BIDS, payload: data ?? [] });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

// To get the bids submitted by that warrior
export const fetchWarriorSubmittedBids = () => async (dispatch, getState) => {
  const uid = getState().firebase.auth.uid;
  try {
    const response = await useAxiosGet(`/booking-bids/get-warrior-submitted-bids/${uid}`);
    const resData = response?.data;
    dispatch({ type: bookingBidTypes.SET_WARRIOR_BIDS, payload: resData?.data ?? [] });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
// To get the bids open to that warrior
export const fetchAvailableBookingBids = () => async (dispatch, getState) => {
  const uid = getState().firebase.auth.uid;
  try {
    const response = await useAxiosGet(`/booking-bids/get-available-booking-bids/${uid}`);
    const { success, data, error } = response?.data;
    if (!success) throw new Error(error);
    dispatch({ type: bookingBidTypes.SET_BIDS, payload: data });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const submitMyBid = (payload) => async (dispatch) => {
  try {
    const { data } = await useAxiosPost(`/booking-bids/submit-warrior-bid`, payload);
    if (data.success) {
      toastr.success("Success", "Your bid was submitted successfully");
      dispatch({ type: bookingBidTypes.ADD_WARRIOR_BID, payload: data.data });
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Something went wrong: ", error);
  }
};

export const withdrawMyBid = (warriorBidId) => async (dispatch) => {
  try {
    const { data } = await useAxiosGet(`/booking-bids/withdraw-warrior-bid/${warriorBidId}`);
    if (data.success) {
      toastr.success("Success", "Your bid was withdrawn");
      dispatch({ type: bookingBidTypes.UPDATE_WARRIOR_BID, payload: data.data });
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Something went wrong: ", error);
  }
};

export const chooseWarriorBid = (bookingBidId, payload) => async (dispatch) => {
  try {
    const { data } = await useAxiosPost(`/booking-bids/complete-booking-bid/${bookingBidId}`, payload);
    if (data.success) {
      toastr.success("Success", "The warrior was selected successfully");
      return data.data;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Something went wrong: ", error);
  }
};

export const createAdminBookingQuoteFromBid = (form, admin, callback, bidId) => async (dispatch) => {
  try {
    const response = await useAxiosPost(`/booking-bids/create-quote-from-booking-bid/${bidId}`, { form, admin: admin.email });
    const { data, success, error } = response?.data;
    if (!success) throw new Error(error);
    toastr.success("Done", `Bid successfully created ${data.id}`);
    dispatch({
      type: bookingBidTypes.UPDATE_BID,
      payload: data,
    });
    dispatch({
      type: bookingQuotesTypes.ADD_BOOKING_QUOTE,
      payload: data,
    });
    dispatch({
      type: bookingQuotesTypes.SELECT_BID,
      payload: data,
    });
    callback?.(data.id);
    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return false;
  }
};

export const cancelBookingBid = (callback, bidId) => async (dispatch, getState) => {
  const email = getState().firebase.profile?.email;
  try {
    const response = await useAxiosPost(`/booking-bids/cancel-booking-bid/${bidId}`, { admin: email });
    const { data, success, error } = response?.data;
    if (!success) throw new Error(error);
    toastr.success("Done", `Bid successfully cancelled. This will no longer appear to warriors`);
    dispatch({
      type: bookingBidTypes.UPDATE_BID,
      payload: data,
    });
    callback?.();
    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return false;
  }
};

export const reopenBookingBid = (callback, bidId) => async (dispatch, getState) => {
  const email = getState().firebase.profile?.email;
  try {
    const response = await useAxiosPost(`/booking-bids/reopen-booking-bid/${bidId}`, { admin: email });
    const { data, success, error } = response?.data;
    if (!success) throw new Error(error);
    toastr.success("Done", `Bid successfully cancelled. This will no longer appear to warriors`);
    dispatch({
      type: bookingBidTypes.UPDATE_BID,
      payload: data,
    });
    callback?.();
    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return false;
  }
};

export const fetchBidById = (bidId) => async (dispatch, getState) => {
  try {
    const { data } = await useAxiosGet(`/booking-bids/get-booking-bid/${bidId}`);
    if (data.success) {
      dispatch({
        type: bookingBidTypes.SET_SELECTED_BID,
        payload: data.data,
      });
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Something went wrong: ", error);
  }
};

export const fetchBidByNumber = async (bidId) => {
  try {
    const { data } = await useAxiosGet(`/booking-bids/get-booking-bid-number/${bidId}`);
    if (data.success) {
      return data.data;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Something went wrong: ", error);
  }
};

export const notifyWarriors = (bidId) => async (dispatch, getState) => {
  const email = getState().firebase.profile?.email;
  try {
    const { data } = await useAxiosPost(`/booking-bids/notify-warriors/${bidId}`, { admin: email });
    if (data.success) {
      toastr.success("Done", `Warriors successfully notified!`);
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Something went wrong: ", error);
  }
};
