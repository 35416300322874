import React from "react";
import { Button, Typography } from "@material-ui/core";
import classNames from "classnames";
import classes from "@css/components/website/WhyDimension.module.css";
import onDemand from "../../../assets/landing_page/b2b-features/on-demand.svg";
import transparent from "../../../assets/landing_page/b2b-features/transparent.svg";
import flexible from "../../../assets/landing_page/b2b-features/flexible.png";
import satisfaction from "../../../assets/landing_page/b2b-features/satisfaction.svg";
import dimensionLogoDark from "../../../assets/landing_page/dimension-logo-new.svg";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router";
import { segmentTrack } from "../../../helpers/segment";

function WhyDimension() {
  const history = useHistory();
  const handleConsultation = () => {
    segmentTrack("Click", {
      button: "Book a Consultation",
    });
    window.open("https://calendly.com/greg-653/introductory-chat-trash-warrior", "_blank");
  };
  return (
    <section className={classes.container}>
      <div className={classes.mainContent}>
        <Typography className={classes.heading}>Why Dimension?</Typography>
        <p className={classes.description}>
          Dimension makes it easy for companies of any size to book reliable and accountable waste management services online and nationwide
        </p>
        <div className={classes.tableContainer}>
          <div className={classes.fixedColumnTable}>
            <table className={classes.whyDimensionTable}>
              <thead className={classes.tableHeader}>
                <tr>
                  <th className={classes.fixedColumn}>
                    Service
                    <br />
                    Name
                  </th>
                  <th>
                    On
                    <br />
                    Demand
                  </th>
                  <th>
                    Transparent
                    <br />
                    Pricing
                  </th>
                  <th>
                    Flexibility and
                    <br />
                    Accountability
                  </th>
                  <th>
                    Satisfied
                    <br />
                    Customers
                  </th>
                </tr>
              </thead>
              <tbody className={classes.tableBody}>
                <tr>
                  <td className={classes.fixedColumn}>
                    <img src={dimensionLogoDark} alt="Dimension Logo" />
                  </td>
                  <td>
                    <CheckCircleIcon htmlColor="#DBC6AA" />
                  </td>
                  <td>
                    <CheckCircleIcon htmlColor="#DBC6AA" />
                  </td>
                  <td>
                    <CheckCircleIcon htmlColor="#DBC6AA" />
                  </td>
                  <td>
                    <CheckCircleIcon htmlColor="#DBC6AA" />
                  </td>
                </tr>
                <tr>
                  <td className={classes.fixedColumn}>1-800-GOT-JUNK</td>
                  <td>
                    <CheckCircleIcon htmlColor="#DBC6AA" />
                  </td>
                  <td>
                    <CloseIcon htmlColor="#89918b" />
                  </td>
                  <td>
                    <CloseIcon htmlColor="#89918b" />
                  </td>
                  <td>
                    <CheckCircleIcon htmlColor="#DBC6AA" />
                  </td>
                </tr>
                <tr>
                  <td className={classes.fixedColumn}>Junk King</td>
                  <td>
                    <CloseIcon htmlColor="#89918b" />
                  </td>
                  <td>
                    <CloseIcon htmlColor="#89918b" />
                  </td>
                  <td>
                    <CheckCircleIcon htmlColor="#DBC6AA" />
                  </td>
                  <td>
                    <CheckCircleIcon htmlColor="#DBC6AA" />
                  </td>
                </tr>
                <tr>
                  <td className={classes.fixedColumn}>LoadUp</td>
                  <td>
                    <CloseIcon htmlColor="#89918b" />
                  </td>
                  <td>
                    <CheckCircleIcon htmlColor="#DBC6AA" />
                  </td>
                  <td>
                    <CloseIcon htmlColor="#89918b" />
                  </td>
                  <td>
                    <CheckCircleIcon htmlColor="#DBC6AA" />
                  </td>
                </tr>
                <tr>
                  <td className={classes.fixedColumn}>Recology</td>
                  <td>
                    <CloseIcon htmlColor="#89918b" />
                  </td>
                  <td>
                    <CheckCircleIcon htmlColor="#DBC6AA" />
                  </td>
                  <td>
                    <CloseIcon htmlColor="#89918b" />
                  </td>
                  <td>
                    <CloseIcon htmlColor="#89918b" />
                  </td>
                </tr>
                <tr>
                  <td className={classes.fixedColumn}>Waste Management</td>
                  <td>
                    <CloseIcon htmlColor="#89918b" />
                  </td>
                  <td>
                    <CheckCircleIcon htmlColor="#DBC6AA" />
                  </td>
                  <td>
                    <CloseIcon htmlColor="#89918b" />
                  </td>
                  <td>
                    <CloseIcon htmlColor="#89918b" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button disableRipple className={classes.secondaryButton} onClick={() => handleConsultation()}>
            Book a consultation
          </Button>
          <Button disableRipple className={classes.primaryButton} onClick={() => history.push("/booking?step=initial")}>
            Book now
          </Button>
        </div>
        <div className={classes.featureContainer}>
          <div className={classes.feature}>
            <img src={onDemand} />
            <Typography className={classNames(classes.heading, classes.cardHeading)}>On Demand</Typography>
            <Typography className={classes.description}>Get a quote instantly and same-day service every day of the week, 5 am to 8 pm.</Typography>
          </div>
          <div className={classes.feature}>
            <img src={transparent} />
            <Typography className={classNames(classes.heading, classes.cardHeading)}>Transparent Pricing</Typography>
            <Typography className={classes.description}>
              The price we quote you is the price you’ll pay. No hidden fees, no last minute charges, just radically efficient waste removal.
            </Typography>
          </div>
          <div className={classes.feature}>
            <img src={flexible} />
            <Typography className={classNames(classes.heading, classes.cardHeading)}>Flexible and Accountable</Typography>
            <Typography className={classes.description}>
              Whether it&apos;s a bespoke solution that&apos;s tailor-made for you or waste diversion data to ensure compliance, Dimension takes all
              the trouble out of waste management.
            </Typography>
          </div>
          <div className={classes.feature}>
            <img src={satisfaction} />
            <Typography className={classNames(classes.heading, classes.cardHeading)}>Satisfaction Guaranteed</Typography>
            <Typography className={classes.description}>
              Nothing matters to us more than our customers. Everyone at Dimension, from our providers to our ever-helpful support team is here to
              service your needs.
            </Typography>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyDimension;
