import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import withdrawTask from "@assets/withdraw_task.svg";

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    backgroundColor: "rgb(227,227,230)",
  },
  dialogTitle: {
    justifyContent: "space-around",
    display: "flex",
    textAlign: "center",
  },
  dialogActions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 20px",
    "& > button": {
      marginBottom: "10px",
    },
  },
  dialogContent: {
    maxWidth: "450px",
  },
  dialogContentText: {
    display: "inline-block",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  imageWrapper: {
    width: "200px",
  },
  descriptionText: {
    fontSize: "14px",
    color: "#353535",
    fontWeight: 500,
    margin: "20px 0",
  },
}));

const TaskWithdrawModal = ({ open, handleClose, handleProceed }) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth={false}
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="max-width-dialog-title"
      className={classes.dialogContainer}>
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.container}>
          <img src={withdrawTask} alt="withdrawTask" className={classes.imageWrapper} />
        </Box>
        <DialogContentText className={classes.descriptionText}>
          Are you sure you want to withdraw from this task? You will not be able to take new tasks within 24 hours
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions} disableSpacing>
        <Button variant="contained" fullWidth onClick={() => handleClose(false)} color="primary">
          No, keep task
        </Button>
        <Button variant="outlined" fullWidth onClick={handleProceed} color="primary">
          Yes, Withdraw
        </Button>
        <br />
      </DialogActions>
    </Dialog>
  );
};

export default TaskWithdrawModal;
