import React from "react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import dayjs from "dayjs";
import { Button, Card, CardContent, Chip, Divider, IconButton, Typography } from "@material-ui/core";
import classNames from "classnames";
import classes from "@css/components/BookingPreview.module.css";
import NumberService from "../../../../shared/services/NumberService";

type MobileViewProps = {
  bookings: Array<any>;
  confirmAllQuotes: any;
  showDetails: any;
  confirmedQuotes: Array<string>;
};

function MobileView({ bookings, showDetails, confirmAllQuotes, confirmedQuotes }: MobileViewProps) {
  return (
    <div className={classes.cardContainer}>
      <div className={classes.countHeader}>
        <Typography color="primary">
          <strong>
            {bookings.length} Quote{bookings.length > 1 && "s"}
          </strong>
        </Typography>
        <Button className={classes.confirmAllButton} onClick={confirmAllQuotes} color="primary" variant="text">
          {bookings.length === confirmedQuotes.length ? "Unc" : "C"}onfirm All
        </Button>
      </div>
      {bookings.map((booking: any) => {
        const isConfirmedQuote = confirmedQuotes.includes(booking.quoteNumber);
        return (
          <Card variant="outlined" key={booking.quoteNumber} onClick={() => showDetails(booking.quoteNumber)} className={classes.quoteCard}>
            <CardContent className={classes.quoteCardContent}>
              <div className={classes.quoteCardMainContent}>
                <div className={classes.cardHeader}>
                  <Typography variant="h5" color={isConfirmedQuote ? "primary" : "initial"}>
                    {booking.quoteNumber}
                  </Typography>
                  <Divider orientation="vertical" flexItem />
                  <Typography variant="h5">{NumberService.formatToCurrency(booking?.finalTotal)}</Typography>
                </div>
                <div className={classes.cardDetails}>{dayjs(booking.date).tz(booking.timeZone)?.format("MMM, MM YYYY, h:mmA")}</div>
                <div className={classNames(classes.cardDetails, classes.cardAddress)}>{booking.address.location}</div>
                {isConfirmedQuote && <Chip className={classes.quoteCardChip} size="small" color="primary" label="Confirmed" />}
              </div>
              <div className={classes.quoteCardButton}>
                <IconButton aria-label="go to quote">
                  <ChevronRightIcon color="primary" />
                </IconButton>
              </div>
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
}

export default MobileView;
