import React, { Suspense, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import history from "./history";
import WrappingProviders from "./containers/WrappingProviders";
import { privileges } from "./constants/privileges";
import { useLoadScript } from "@react-google-maps/api";
import { closeLDFlags } from "./store/actions/featureFlags";
import tempDataConstants from "@store/constants/tempDataTypes";
import DefaultLayout from "./containers";
import { renderComponent } from "./helpers/renderPage";

import "react-image-gallery/styles/css/image-gallery.css";
import Loader from "./shared/components/Loader";
import { roles } from "./constants/roles";
import { userCloseSession, userLogin } from "./store/actions/users";
import UrlShortnerRedirect from "./containers/UrlShortnerRedirect";

const libraries = ["places", "drawing"];
const googleMapsApiKey = process.env.REACT_APP_GMAPS_API_KEY;
const Admin = React.lazy(() => import("./views/Admin"));
const PrivateData = React.lazy(() => import("./views/Admin/SuperAdmin/Data"));

const routes = [
  { path: "/data/portal", name: "Data", component: PrivateData, privilege: privileges.VIEW_PORTAL_DATA_EXPORTS.name },
  { path: "/portal", name: "Landing Page", component: Admin, privilege: privileges.PORTAL_ACCESS.name },
  { path: "/l/:id", name: "Redirect", component: UrlShortnerRedirect },
  { path: "/", name: "Landing Page", component: DefaultLayout },
];

function App() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.firebase.profile);
  const sessionPrivileges = useSelector((state) => state.auth.privileges);
  const isLoading = useSelector((state) => state.loader.isLoading || state.page.isLoading || state.booking.isLoading);
  const sessionStarted = useRef(false);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey,
    libraries,
  });

  useEffect(() => {
    if (sessionStarted.current === true) return;
    if (profile?.uid) {
      sessionStarted.current = true;
      userLogin(profile);
    }
  }, [profile]);

  useEffect(() => {
    return () => {
      if (profile?.uid) {
        userCloseSession();
      }
      closeLDFlags();
    };
  }, []);

  useEffect(() => {
    if (!profile.isEmpty) {
      if (profile.userType === roles.provider.value && !profile.phoneNumber) {
        dispatch({ type: tempDataConstants.SET_TEMP_USER, userType: roles.provider.value });
        history.push("auth/signup_with_google?invalidPhoneNumber=true");
      }
    }
  }, [profile]);

  if (!profile?.isLoaded || loadError || (!isLoaded && isLoading)) {
    return <Loader />;
  }

  return (
    <Router history={history}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <WrappingProviders>
          <Suspense fallback={<Loader />}>
            {isLoaded && !loadError && (
              <Switch>
                {routes.map((route, idx) => (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={renderComponent(route, sessionPrivileges, profile)}
                  />
                ))}
              </Switch>
            )}
          </Suspense>
        </WrappingProviders>
      </QueryParamProvider>
    </Router>
  );
}

export default App;
