import React, { useCallback, useState, useEffect, useRef } from "react";
import { BooleanParam, StringParam, useQueryParam } from "use-query-params";
import history from "@history";
import { Hidden, Typography, useMediaQuery } from "@material-ui/core";

import * as utils from "@booking/helpers/utils";
import * as forms from "@booking/helpers/forms";
import { stepName, taskName } from "@booking/helpers/wordings";
import { getBookingDefaults } from "@booking/helpers/constants";
import { Input, Step } from "@booking/helpers/enums";
import pluralize from "@methods/pluralize";

import Progress from "./Progress";
import MultiQuotePriceDetails from "./MultiQuotePriceDetails";
import PriceQuote from "./PriceQuote";
import DoItAgainPreview from "./DoItAgainPreview";
import BookingPreview from "./BookingPreview";

import RemovalDateAndTimeForm from "@booking/helpers/forms/Removal/DateAndTime";
import RentalDateAndTimeFormv2 from "@booking/helpers/forms/Rentalv2/DateAndTime";
import RentalTaskDescriptionFormv2 from "@booking/helpers/forms/Rentalv2/TaskDescription";
import ReviewOrderForm from "@booking/helpers/forms/ReviewOrder";
import PaymentInformationForm from "@booking/helpers/forms/PaymentInformation";

import Buttons from "./Buttons";
import BackButton from "./Buttons/BackButton";
import ExpansionPanel from "./ExpansionPanel";
import ProcessPayment from "./ProcessPayment";

import { ElementsConsumer } from "@stripe/react-stripe-js";

import { useDispatch, useSelector } from "react-redux";
import { resetSelectedBooking } from "@store/actions/booking";
import { getTimeZoneText } from "@helpers/location";

import dayjs from "dayjs";
import { isDev } from "@constants/environments";
import { BookingPageContext } from "../context";

import { segmentIdentify, segmentTrack } from "../../../helpers/segment";
import WelcomeDialog from "../../../shared/components/WelcomeDialog";
import QuoteItems from "../helpers/forms/Removal/QuoteItems";
import QuoteDescription from "../helpers/forms/Removal/QuoteDescription";
import { scrollToTop } from "../../../methods/scrollToTop";
import TrustPilot from "../../../shared/components/TrustPilot";
import { fetchUserProfileInfo } from "../../../store/actions/users";
import { bookingQuoteStatus, sameDayBookingPrice } from "../../../constants/booking";
import { debounce } from "lodash";
import { Task } from "../helpers/enums";
import { getTimestamp } from "../../../constants/dayjs";
import { updateAdminBookingQuote } from "../../../store/actions/bookingQuotes";

import rejected from "@assets/rejected.jpg";
import { setBookingPriceSurge } from "../../../store/actions/ui";

import classes from "@css/components/BookingQuote.module.css";
import { useLDChecker } from "../../../shared/hooks/useLDChecker";
import { bidCPHours } from "../../../constants/bids";

const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

function BookingQuote({ draftBooking = null, isMultipleQuotes, resetBooking }) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  const selectedBooking = useSelector((state) => (draftBooking ? state.booking.selectedBooking : null));
  const { priceMultipliers, payoutPercent } = useSelector((state) => state.serviceLocations.activeLocation);
  const selectedCoupon = useSelector((state) => state.coupon.selectedCoupon);
  const { value: sameDaySurge = sameDayBookingPrice } = useSelector((state) => state.ui.sameDaySurge);

  // Multiple Quotes states
  const [confirmedQuotes, setConfirmedQuotes] = useState([]);

  const [isTestLink = false] = useQueryParam("test", BooleanParam);
  const [currentStep, setCurrentStep] = useQueryParam("step", StringParam);

  const [timeTracking, setTimeTracking] = useState({});
  const [processPayment, setProcessPayment] = useState(false);
  const [booking, setBooking] = useState({});
  const [segmentPayment, setSegmentPayment] = useState(false);
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false);
  const [stepList, setStepList] = useState([]);
  const [warrior, setWarrior] = useState(null);
  const [quoteChanges] = useState([]);
  const [activeStep, setActiveStep] = useState(null);
  const [isEarlyInboundDiscount, setIsEarlyInboundDiscount] = useState(false);
  const [activeSteps] = useState([Step.QuoteReview, Step.BidQuoteReview, Step.TaskDescription, Step.DateAndTime, Step.Payment, Step.ReviewOrder]);

  const shouldTrackSegment = !isTestLink;
  const RentalDateAndTime = RentalDateAndTimeFormv2;
  const RentalTaskDescription = RentalTaskDescriptionFormv2;

  const quoteCustomerAuthed = !isMultipleQuotes && selectedBooking?.customer_id === auth?.uid;
  const isBidQuote = !isMultipleQuotes && selectedBooking?.isLockedDate && selectedBooking?.isLockedPrice;
  const showDateSelector = !isMultipleQuotes && !selectedBooking?.isLockedDate;
  const showPricingItems = !isMultipleQuotes && !selectedBooking?.isLockedPrice && !selectedBooking?.task === Task.PowerWashing;
  const isExpiredQuote = !isMultipleQuotes && selectedBooking?.status === "expired";
  const showReducedPrice =
    !isMultipleQuotes &&
    isEarlyInboundDiscount &&
    selectedBooking?.isAutoCreatedQuote &&
    dayjs().diff(selectedBooking?.createdAt, "hours") < bidCPHours;

  const refs = {
    dateAndTime: useRef(null),
    taskDescription: useRef(null),
    reviewOrder: useRef(null),
    payment: useRef(null),
    quoteReview: useRef(null),
    bigQuoteReview: useRef(null),
  };

  const constructedDefaults = getBookingDefaults(isMultipleQuotes ? {} : draftBooking, profile);
  const task = isMultipleQuotes ? {} : draftBooking?.task;
  const businessClient = isMultipleQuotes ? {} : draftBooking?.businessClient;

  const [removalDateAndTimeForm, setRemovalDateAndTimeForm] = useState({
    [Input.Date]: constructedDefaults[Input.Date],
    [Input.Time]: constructedDefaults[Input.Time],
    [Input.Frequency]: constructedDefaults[Input.Frequency],
  });

  const [rentalDateAndTimeForm, setRentalDateAndTimeForm] = useState({
    [Input.Date]: constructedDefaults[Input.Date],
    [Input.Time]: constructedDefaults[Input.Time],
    [Input.Frequency]: constructedDefaults[Input.Frequency],
    [Input.Pickup]: constructedDefaults[Input.Pickup],
    [Input.PickupDate]: constructedDefaults[Input.PickupDate],
    [Input.PickupTime]: constructedDefaults[Input.PickupTime],
  });

  const [rentalTaskDescriptionForm, setRentalTaskDescriptionForm] = useState({
    [Input.Dumpster]: constructedDefaults[Input.Dumpster],
    [Input.Description]: constructedDefaults[Input.Description],
    [Input.UnderstandPricing]: constructedDefaults[Input.UnderstandPricing],
  });

  const [removalQuoteItemsForm, setRemovalQuoteItemsForm] = useState({
    [Input.Junk]: constructedDefaults[Input.Junk],
    [Input.Vehicle]: constructedDefaults[Input.Vehicle],
  });
  const [removalQuoteDescriptionForm, setRemovalQuoteDescriptionForm] = useState({
    [Input.Description]: constructedDefaults[Input.Description],
    [Input.Stairs]: constructedDefaults[Input.Stairs],
    [Input.Dismantling]: constructedDefaults[Input.Dismantling],
  });

  const [reviewOrderForm, setReviewOrderForm] = useState({
    [Input.TermsOfService]: constructedDefaults[Input.TermsOfService],
  });

  const [paymentForm, setPaymentForm] = useState({
    [Input.PaymentFirstName]: constructedDefaults[Input.PaymentFirstName],
    [Input.PaymentLastName]: constructedDefaults[Input.PaymentLastName],
    [Input.PaymentPhone]: constructedDefaults[Input.PaymentPhone],
    [Input.PaymentEmail]: constructedDefaults[Input.PaymentEmail],
  });

  scrollToTop(currentStep);

  useLDChecker({
    flag: "inbound-quote-discount",
    dependencies: [],
    profile,
    callback: (value) => setIsEarlyInboundDiscount(value),
    run: true,
  });

  useEffect(() => {
    if (!isMultipleQuotes && draftBooking?.warrior_id) {
      (async () => {
        const wdata = await fetchUserProfileInfo(draftBooking?.warrior_id);
        if (!wdata) return;
        setWarrior({
          name: wdata.fullName,
          rating: (wdata.ratingTotal / wdata.ratingCount)?.toFixed(2),
          headshot: wdata.photoURL ?? "",
        });
      })();
    }
    setTimeTracking({ bookingStarted: dayjs().utc().format("YYYY-MM-DDTHH:mm:ss.SSSZ") });
    // fetch same day booking surge based on LD variant
    dispatch(setBookingPriceSurge());
    sessionStorage.setItem("phone_trials", 0);
    return () => {
      sessionStorage.setItem("phone_trials", 0);
    };
  }, []);

  useEffect(() => {
    let steps = [];
    if (showPricingItems) {
      steps.push(Step.QuoteReview, Step.TaskDescription);
    }
    if (showDateSelector) {
      steps.push(Step.DateAndTime);
    }
    steps.push(Step.BidQuoteReview, Step.ReviewOrder, Step.Payment);
    setStepList(steps);
    setActiveStep(0);
  }, [selectedBooking]);

  useEffect(() => {
    if (quoteCustomerAuthed) {
      segmentTrack(
        "SalesDrivenLoggedIn",
        {
          userId: auth.uid,
          email: profile?.email,
          userName: profile?.fullName,
          openedAt: getTimestamp(),
        },
        shouldTrackSegment
      );
    }
  }, [quoteCustomerAuthed]);

  useEffect(() => {
    if (!draftBooking) dispatch(resetSelectedBooking());
    return () => {
      resetBooking();
    };
  }, [dispatch]);

  useEffect(() => {
    setCurrentStep(stepList[activeStep]);
    const stepName = stepList[activeStep];
    switch (stepName) {
      case Step.QuoteReview:
        segmentTrack(
          "Booking Quote Items Requested",
          {
            bookingId: draftBooking?.id,
          },
          shouldTrackSegment
        );
        break;
      case Step.BidQuoteReview:
        segmentTrack(
          "SalesDrivenQuoteOpened",
          {
            userId: auth.uid,
            email: profile?.email,
            userName: profile?.fullName,
            openedAt: getTimestamp(),
          },
          shouldTrackSegment
        );
        segmentTrack(
          "Booking Quote Review Requested",
          {
            bookingId: draftBooking?.id,
          },
          shouldTrackSegment
        );
        break;
      case Step.DateAndTime:
        segmentTrack("Booking Quote Date Time Requested", null, shouldTrackSegment);
        break;
      case Step.TaskDescription:
        segmentTrack("Booking Quote Task Description Requested", null, shouldTrackSegment);
        break;
      case Step.Payment:
        segmentTrack("Booking Quote Terms Agreed", null, shouldTrackSegment);
        break;
      default:
        break;
    }
  }, [activeStep]);

  useEffect(() => {
    if (processPayment && !segmentPayment) {
      handleSegmentPayment();
    }
  }, [processPayment]);

  useEffect(() => {
    getValidState();
  }, [removalQuoteDescriptionForm, removalQuoteItemsForm, rentalTaskDescriptionForm, rentalDateAndTimeForm, removalDateAndTimeForm]);

  const getValidState = useCallback(
    debounce(() => {
      setValidForms({
        date: utils.isDateValid(showDateSelector, task, removalDateAndTimeForm, rentalDateAndTimeForm),
        items: utils.isQuoteItemsValid(showPricingItems, task, removalQuoteItemsForm),
        description: utils.isQuoteDescriptionValid(showPricingItems, task, removalQuoteDescriptionForm, rentalTaskDescriptionForm),
      });
    }, 500),
    [removalQuoteDescriptionForm, removalQuoteItemsForm, rentalTaskDescriptionForm, rentalDateAndTimeForm, removalDateAndTimeForm]
  );

  const isReviewOrderFormValid = utils.isFormValid(reviewOrderForm, forms.reviewOrderFormFields);
  const isPaymentFormValid = utils.isFormValid(paymentForm, forms.paymentFormFields);
  const [validForms, setValidForms] = useState({
    date: utils.isDateValid(showDateSelector, task, removalDateAndTimeForm, rentalDateAndTimeForm),
    items: utils.isQuoteItemsValid(showPricingItems, task, removalQuoteItemsForm),
    description: utils.isQuoteDescriptionValid(showPricingItems, task, removalQuoteDescriptionForm, rentalTaskDescriptionForm),
  });

  const dateAndTimeForm = utils.getActiveForm(removalDateAndTimeForm, rentalDateAndTimeForm, task);
  const quoteReviewForm = utils.getActiveForm(removalQuoteItemsForm, rentalTaskDescriptionForm, task);
  const quoteDescriptionForm = utils.getActiveForm(removalQuoteDescriptionForm, rentalTaskDescriptionForm, task);

  // progress bar value
  const progressValue = 100 * ((activeStep + 1) / stepList.length);

  const enableCheckoutButton = () => {
    const finalCheck = isReviewOrderFormValid && isPaymentFormValid;
    return utils.isFormsValid(validForms) && finalCheck;
  };

  // handle form changes
  const handleRentalDateAndTimeFormChange = useCallback((nextDateAndTimeForm) => setRentalDateAndTimeForm(nextDateAndTimeForm), []);
  const handleRemovalDateAndTimeFormChange = useCallback((nextDateAndTimeForm) => setRemovalDateAndTimeForm(nextDateAndTimeForm), []);
  const handleRentalTaskDescriptionFormChange = useCallback((nextTaskDescriptionForm) => setRentalTaskDescriptionForm(nextTaskDescriptionForm), []);
  const handleRemovalQuoteItemsFormChange = useCallback((nextQuoteItemsForm) => setRemovalQuoteItemsForm(nextQuoteItemsForm), []);
  const handleRemovalQuoteDescriptionFormChange = useCallback(
    (nextTaskDescriptionForm) => setRemovalQuoteDescriptionForm(nextTaskDescriptionForm),
    []
  );
  const handleReviewOrderFormChange = useCallback((nextReviewOrderForm) => setReviewOrderForm(nextReviewOrderForm), []);
  const handlePaymentFormChange = useCallback((nextPaymentForm) => setPaymentForm(nextPaymentForm), []);

  const prepareBookingForPayment = (bookingForm, id, timeZone, isMultipleQuotes) => {
    const cleaned = {};
    for (const key in bookingForm) {
      cleaned[key] = isMultipleQuotes ? bookingForm[key] : bookingForm[key].value;
    }

    if (showDateSelector && cleaned["pickup-date"]) {
      cleaned["pickup-date"] = cleaned["pickup-date"]?.tz(timeZone, true);
    }

    if (isMultipleQuotes) {
      cleaned.date = dayjs(cleaned.date);
      cleaned.status = bookingQuoteStatus.completed.value;
    }

    delete cleaned?.time?.isFull;

    return {
      ...cleaned,
      bookingQuoteId: id,
      priceMultipliers: priceMultipliers,
      payoutPercent: payoutPercent,
      timeTracking: timeTracking,
      sameDayBooking: dayjs().isSame(cleaned.date, "day"),
      bookingSurgeAmount: sameDaySurge,
      date: cleaned.date?.tz(timeZone, true),
      isBookingQuoteChanged: quoteChanges?.length > 0,
      bookingQuoteChanges: quoteChanges,
    };
  };

  const formatFormValues = (united) => {
    const cleaned = {};
    for (const key in united) {
      cleaned[key] = united[key].value;
    }
  };

  const startCreateBooking = useCallback(async () => {
    setIsLoadingCheckout(true);
    if (isMultipleQuotes) {
      const quotesToSubmit = draftBooking.filter((booking) => confirmedQuotes.includes(booking.quoteNumber));
      const quotes = quotesToSubmit.map((booking) => prepareBookingForPayment(booking, booking.id, booking?.address?.timeZone, true));
      isDev && console.log("calling createBooking", quotes); // eslint-disable-line no-console
      setBooking(quotes);
      setProcessPayment(true);
    } else {
      const united = {
        ...dateAndTimeForm,
        ...quoteReviewForm,
        ...quoteDescriptionForm,
        ...reviewOrderForm,
        ...paymentForm,
      };
      const cleaned = prepareBookingForPayment(united, draftBooking.id, draftBooking?.address?.timeZone, false);
      isDev && console.log("calling createBooking", cleaned); // eslint-disable-line no-console
      setBooking(cleaned);
      setProcessPayment(true);
      setIsLoadingCheckout(false);
    }
  }, [
    dateAndTimeForm,
    quoteReviewForm,
    quoteDescriptionForm,
    reviewOrderForm,
    paymentForm,
    priceMultipliers,
    payoutPercent,
    task,
    businessClient,
    auth?.uid,
    dispatch,
    selectedCoupon?.uid,
  ]);

  // taking the active summary (eg. summary of current service job)
  const summaryProps = isMultipleQuotes
    ? {}
    : utils.getSummaryProps(task, businessClient, draftBooking, quoteDescriptionForm, dateAndTimeForm, quoteReviewForm);

  const handleSegmentPayment = () => {
    segmentIdentify({
      firstName: paymentForm[Input.PaymentFirstName]?.value ?? "",
      lastName: paymentForm[Input.PaymentLastName]?.value ?? "",
      email: paymentForm[Input.PaymentEmail]?.value ?? "",
      phone: paymentForm[Input.PaymentPhone]?.value ?? "",
    });
    segmentTrack("Booking Quote Payment Started", null, shouldTrackSegment);
    setSegmentPayment(true);
  };

  const handleBack = () => setActiveStep((state) => state - 1);
  const handleContinue = async () => {
    const current = activeStep;
    if (stepList[current] === Step.Payment) {
      startCreateBooking();
      segmentTrack("Credit card info filled (Stripe pop up)", null, shouldTrackSegment);
    } else {
      setActiveStep((state) => state + 1);
    }
  };

  const handleDateAndTimeEdit = useCallback(() => setActiveStep(stepList.findIndex((i) => i === Step.DateAndTime)), [stepList]);
  const handleTaskDescriptionEdit = useCallback(() => setActiveStep(stepList.findIndex((i) => i === Step.TaskDescription)), [stepList]);
  const handleQuoteReviewEdit = useCallback(() => setActiveStep(stepList.findIndex((i) => i === Step.QuoteReview)), [stepList]);
  const handleReviewOrderEdit = useCallback(() => setActiveStep(stepList.findIndex((i) => i === Step.ReviewOrder)), [stepList]);
  const handlePaymentEdit = useCallback(() => setActiveStep(stepList.findIndex((i) => i === Step.Payment)), [stepList]);
  const handleBidQuoteReviewEdit = useCallback(() => setActiveStep(stepList.findIndex((i) => i === Step.BidQuoteReview)), [stepList]);

  const validateForm = (valid) => () => {
    const currentForm = {
      [Step.DateAndTime]: refs.dateAndTime,
      [Step.TaskDescription]: refs.taskDescription,
      [Step.ReviewOrder]: refs.reviewOrder,
      [Step.Payment]: refs.payment,
      [Step.QuoteReview]: refs.quoteReview,
      [Step.BidQuoteReview]: refs.bigQuoteReview,
    }[currentStep];

    currentForm.current?.trigger?.();

    /*
      manual trigger for tos_checked only for desktop
      [Step.ReviewOrder] only comes in mobile view and for desktop view
      tos_checked comes with [Step.Address] form.
     */
    if (currentStep === Step.ReviewOrder) {
      refs.reviewOrder.current?.trigger?.();
    }

    valid && handleContinue();
  };

  const SectionButtons = ({ renderBackButton, invalid, continueButtonLabel }) => (
    <Buttons
      renderBackButton={!!renderBackButton}
      onBack={handleBack}
      onContinue={validateForm(!invalid)}
      continueButtonLabel={continueButtonLabel}
      loading={isLoadingCheckout}
    />
  );

  const DateAndTime = utils.getActiveForm(
    <RemovalDateAndTimeForm
      ref={refs.dateAndTime}
      defaultValue={removalDateAndTimeForm}
      onChange={handleRemovalDateAndTimeFormChange}
      timeZone={getTimeZoneText(draftBooking?.address?.coordinates)}
    />,
    <RentalDateAndTime
      ref={refs.dateAndTime}
      defaultValue={rentalDateAndTimeForm}
      onChange={handleRentalDateAndTimeFormChange}
      timeZone={getTimeZoneText(draftBooking?.address?.coordinates)}
    />,
    task
  );

  const TaskDescription = utils.getActiveForm(
    <QuoteDescription
      ref={refs.taskDescription}
      task={task}
      defaultValue={removalQuoteDescriptionForm}
      onChange={handleRemovalQuoteDescriptionFormChange}
    />,
    <RentalTaskDescription
      ref={refs.taskDescription}
      pickup={rentalDateAndTimeForm[Input.Pickup]}
      defaultValue={rentalTaskDescriptionForm}
      onChange={handleRentalTaskDescriptionFormChange}
    />,
    task
  );

  const QuoteReview = utils.getActiveForm(
    <QuoteItems ref={refs.quoteReview} task={task} defaultValue={removalQuoteItemsForm} onChange={handleRemovalQuoteItemsFormChange} />,
    <RentalTaskDescription
      ref={refs.quoteReview}
      pickup={rentalDateAndTimeForm[Input.Pickup]}
      defaultValue={rentalTaskDescriptionForm}
      onChange={handleRentalTaskDescriptionFormChange}
    />,
    task
  );

  const ReviewOrder = (expandPolicy, showSummary, showPolicy) => (
    <ReviewOrderForm
      ref={refs.reviewOrder}
      config={{
        expandPolicy,
        showSummary,
        showPolicy,
        isMultipleQuotes,
        isBidMode: false,
      }}
      defaultValue={reviewOrderForm}
      onChange={handleReviewOrderFormChange}
      draftBooking={draftBooking}
      bookings={draftBooking}
      summaryProps={{
        ...summaryProps,
        showReducedPrice,
      }}
      confirmedQuotes={confirmedQuotes}
    />
  );

  // expansion panel description
  const initialDescription =
    removalQuoteItemsForm[Input.Vehicle]?.value +
    ", " +
    (removalQuoteItemsForm[Input.Vehicle]
      ? pluralize(removalQuoteItemsForm[Input.Junk]?.value?.length, "item", "selected")
      : pluralize(rentalTaskDescriptionForm[Input.Dumpster]?.value.length, "dumpster", "selected"));
  const dateAndTimeDescription = dateAndTimeForm ? dayjs(dateAndTimeForm[Input.Date]?.value).format("MMM DD YYYY, h:mm A.") : "";
  const taskDescriptionDescription = removalQuoteDescriptionForm ? removalQuoteDescriptionForm[Input.Description]?.value.substr(0, 10) + "..." : "";

  const getPaymentButtonDisabled = () => !utils.isFormsValid(validForms) || !isReviewOrderFormValid;

  const handleExpirationModalClose = (reason) => {
    if (reason && reason == "backdropClick") return;
    if (isExpiredQuote) {
      dispatch(updateAdminBookingQuote({ id: selectedBooking.id, status: "expired" }, false));
      history.push(`/bookings`);
    }
  };

  const PageTitle = () => {
    return (
      <>
        <Typography className={classes.task}>{taskName[task]}</Typography>
        <div className={classes.title}>
          <Typography className={classes.step}>
            {stepName()[currentStep]}
            {selectedBooking?.quoteNumber ? ` - ${selectedBooking?.quoteNumber}` : ""}
          </Typography>
          <Hidden smDown>{activeStep > 0 && <BackButton onClick={handleBack} />}</Hidden>
        </div>
      </>
    );
  };

  return (
    <BookingPageContext.Provider>
      {isExpiredQuote && (
        <WelcomeDialog
          title="Expired Quote!"
          body="Your quote has expired. Please contact your Dimension representative to re-open this quote."
          buttonText="Close"
          image={rejected}
          onClose={handleExpirationModalClose}
          disableEscapeKeyDown
        />
      )}
      {!isExpiredQuote && !!draftBooking && (
        <WelcomeDialog
          title="Welcome Back!"
          body="We've already put back any information you previously filled out. Just check to make sure that everything is still correct and there are no gaps as you complete your booking."
          buttonText="Let's go!"
        />
      )}
      <div>
        <div className={classes.root}>
          <div className={classes.sideBar}>
            <Progress value={progressValue} />
            {isMultipleQuotes ? (
              !isMobile && (
                <MultiQuotePriceDetails
                  enableCheckoutButton={enableCheckoutButton() && !isLoadingCheckout}
                  onClick={handleContinue}
                  bookings={draftBooking}
                  confirmedQuotes={confirmedQuotes}
                  setConfirmedQuotes={setConfirmedQuotes}
                />
              )
            ) : (
              <PriceQuote
                enableCheckoutButton={enableCheckoutButton()}
                onClick={handleContinue}
                loading={isLoadingCheckout}
                summaryProps={{
                  ...summaryProps,
                  showReducedPrice: showReducedPrice,
                }}
                draftBooking={draftBooking}
              />
            )}
          </div>
          <div className={classes.content}>
            <PageTitle />
            {showPricingItems && (
              <ExpansionPanel
                title={"Review Items"}
                description={initialDescription}
                expanded={currentStep === Step.QuoteReview}
                active={activeSteps.includes(Step.QuoteReview)}
                disabled={false}
                onEdit={handleQuoteReviewEdit}
              >
                {QuoteReview}
                <SectionButtons continueButtonLabel="Confirm items" invalid={!validForms.items} />
              </ExpansionPanel>
            )}

            {showPricingItems && (
              <ExpansionPanel
                title="Additional Details"
                description={taskDescriptionDescription}
                expanded={currentStep === Step.TaskDescription}
                active={activeSteps.includes(Step.TaskDescription)}
                disabled={false}
                onEdit={handleTaskDescriptionEdit}
              >
                {TaskDescription}
                <SectionButtons renderBackButton invalid={!validForms.description} />
              </ExpansionPanel>
            )}

            {showDateSelector && (
              <ExpansionPanel
                title="Date And Time"
                description={dateAndTimeDescription}
                expanded={currentStep === Step.DateAndTime}
                active={activeSteps.includes(Step.DateAndTime)}
                disabled={false}
                onEdit={handleDateAndTimeEdit}
              >
                {DateAndTime}
                <SectionButtons renderBackButton invalid={!validForms.date} />
              </ExpansionPanel>
            )}

            <ExpansionPanel
              active
              title="Quote Review"
              expanded={currentStep === Step.BidQuoteReview}
              onEdit={handleBidQuoteReviewEdit}
              disabled={false}
            >
              {selectedBooking && (
                <>
                  <DoItAgainPreview booking={selectedBooking} isQuote={true} summary={summaryProps} warrior={warrior} />
                  <SectionButtons invalid={false} continueButtonLabel="Confirm Quote Details" />
                </>
              )}
              {isMultipleQuotes && (
                <BookingPreview
                  isMultipleQuotes
                  bookings={draftBooking}
                  summary={summaryProps}
                  confirmedQuotes={confirmedQuotes}
                  setConfirmedQuotes={setConfirmedQuotes}
                  loading={isLoadingCheckout}
                  handleContinue={validateForm(true)}
                />
              )}
            </ExpansionPanel>

            <Hidden smDown>
              <ExpansionPanel
                title="Review Terms"
                description={""}
                expanded={currentStep === Step.ReviewOrder}
                active={true}
                disabled={false}
                onEdit={handleReviewOrderEdit}
              >
                <Typography className={classes.cardTitle}>Terms of Service</Typography>
                {ReviewOrder(true)}
                <SectionButtons renderBackButton invalid={isBidQuote ? false : !isReviewOrderFormValid} />
              </ExpansionPanel>
            </Hidden>

            <Hidden mdUp>
              <ExpansionPanel
                expanded={currentStep === Step.ReviewOrder}
                disabled={false}
                active={activeSteps.includes(Step.ReviewOrder)}
                onEdit={handleReviewOrderEdit}
              >
                {ReviewOrder(false, true, true)}
                <SectionButtons renderBackButton invalid={!isReviewOrderFormValid} />
              </ExpansionPanel>
            </Hidden>

            <ExpansionPanel
              title="Payment"
              expanded={currentStep === Step.Payment}
              active={activeSteps.includes(Step.Payment)}
              disabled={getPaymentButtonDisabled()}
              onEdit={handlePaymentEdit}
            >
              <Typography className={classes.cardTitle}>Billing Details</Typography>
              <PaymentInformationForm ref={refs.payment} defaultValue={paymentForm} onChange={handlePaymentFormChange} />
              <SectionButtons renderBackButton invalid={!enableCheckoutButton()} continueButtonLabel="Checkout" />
            </ExpansionPanel>

            {processPayment ? (
              <ElementsConsumer>
                {({ stripe, elements }) => (
                  <ProcessPayment
                    stripe={stripe}
                    elements={elements}
                    open={processPayment}
                    paymentForm={paymentForm}
                    handleClose={setProcessPayment}
                    dateAndTimeForm={dateAndTimeForm}
                    bookingData={booking}
                    summaryProps={{
                      ...summaryProps,
                      showReducedPrice,
                    }}
                    selectedCoupon={selectedCoupon}
                    isMultipleQuotes={isMultipleQuotes}
                    isQuote={true}
                  />
                )}
              </ElementsConsumer>
            ) : null}
          </div>
        </div>
      </div>
      <TrustPilot />
    </BookingPageContext.Provider>
  );
}

export default BookingQuote;
