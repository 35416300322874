import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { Button, Card, CardContent, TextField, Typography } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import classes from "@css/components/BidAcceptCard.module.css";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { validateEmail } from "@helpers/validators";
import FormInput from "../../shared/components/FormInput";
import Loader from "../../shared/components/Loader";

const bidAcceptFormDefaults = (bid) => {
  return {
    price: (bid?.price / 100)?.toFixed() || 0,
    helperCount: bid?.helperCount || 0,
    vehicleSize: "",
    vehicleDescription: bid?.vehicleDescription || "",
    qualifications: bid?.qualifications || "",
  };
};

const formSchema = yup.object({
  isGuest: yup.boolean(),
  email: yup
    .string()
    .email()
    .when("isGuest", {
      is: true,
      then: yup.string().email().required("Please enter your email address"),
    }),
  price: yup.number().test(("greaterThanZero", "Enter a price greater than 0", (number) => number > 0)),
  helperCount: yup.number(),
  vehicleDescription: yup.string().required("Vehicle description is required"),
  qualifications: yup.string(),
});

function BidAcceptCard({ onSubmit, onWithdraw, isGuest, bidSubmitted }) {
  const bidId = useParams().id;
  const submittedBids = useSelector((state) => state.bookingBid.warriorBids);
  const submittedBidsLoaded = useSelector((state) => state.bookingBid.warriorBidsLoaded);
  const matchingBid = submittedBids?.find((bid) => bid.bookingBidId === bidId && bid.status !== "cancelled");
  const [submitted, setSubmitted] = useState(bidSubmitted ?? false);
  const { control, watch, handleSubmit, errors, reset, register, setValue } = useForm({
    defaultValues: bidAcceptFormDefaults(matchingBid),
    resolver: yupResolver(formSchema),
  });

  const showHelperCount = watch("helperCount") === 0;

  useEffect(() => {
    if (isGuest) {
      register("isGuest");
      setValue("isGuest", true);
    }
  }, [isGuest]);

  useEffect(() => {
    if (matchingBid && !submitted) {
      setSubmitted(true);
      reset(bidAcceptFormDefaults(matchingBid));
    } else if (!matchingBid && submitted) {
      setSubmitted(false);
      reset(bidAcceptFormDefaults(matchingBid));
    }
  }, [matchingBid]);

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        {!submittedBidsLoaded && <Loader height={400} />}
        {submittedBidsLoaded && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography className={classes.cardHeading} variant="h5">
              Place your bid
            </Typography>
            {isGuest && (
              <>
                <Typography variant="body1">Please enter your email address</Typography>
                <FormInput
                  name="email"
                  control={control}
                  error={!!errors["email"]}
                  helperText={!!errors["email"] && "Enter a valid email"}
                  type="text"
                  label="Email"
                  disabled={submitted}
                />
              </>
            )}
            <Typography variant="body1">Your bidding price per pickup</Typography>
            <FormInput
              name="price"
              control={control}
              rules={{
                validate: () => getValues("price") > 0,
              }}
              error={!!errors["price"]}
              helperText={!!errors["price"] && "Enter a valid bid amount"}
              type="number"
              label="Bidding Price"
              disabled={submitted}
            />
            <div style={{ height: 16 }} />
            <Typography variant="body1">Other than yourself, how many helpers will be present at each pickup?</Typography>
            <FormInput
              name="helperCount"
              control={control}
              error={!!errors["helperCount"]}
              helperText={errors["helperCount"] ? errors["helperCount"].message : showHelperCount ? "Default count is 0" : ""}
              type="number"
              label="Number of helpers"
              disabled={submitted}
            />
            <div style={{ height: 16 }} />
            <Typography variant="body1">Please descibe the vehicles you will use with as much detail as you can.</Typography>
            <FormInput
              control={control}
              error={!!errors["vehicleDescription"]}
              helperText={errors["vehicleDescription"]?.message}
              name="vehicleDescription"
              label="Vehicle Details"
              disabled={submitted}
              multiline
              minRows={3}
            />
            <div style={{ height: 16 }} />
            <Typography variant="body1">In a few sentences, describe why you think you are qualified and can do a good job for this task</Typography>
            <FormInput control={control} name="qualifications" label="Add short description" multiline minRows={3} disabled={submitted} />
            {!submitted ? (
              <Button fullWidth type="submit" color="primary" variant="contained">
                Submit
              </Button>
            ) : isGuest ? (
              <Button disabled={true} fullWidth type="submit" color="primary" variant="contained">
                Bid Submitted
              </Button>
            ) : (
              <Button fullWidth variant="outlined" onClick={() => onWithdraw(matchingBid.id)}>
                Withdraw
              </Button>
            )}
          </form>
        )}
      </CardContent>
    </Card>
  );
}

BidAcceptCard.defaultValues = {
  onSubmit: () => {},
};

BidAcceptCard.propTypes = {
  onSubmit: PropTypes.func,
  onWithdraw: PropTypes.func,
  isGuest: PropTypes.bool,
};

export default BidAcceptCard;
