import React, { useImperativeHandle, useRef } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import { TextField, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  dialogContainer: {
    backgroundColor: "rgb(227,227,230, 0.3)",
    zIndex: "1400 !important",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  companyName: {
    color: "black",
    fontSize: "18px",
    fontWeight: 600,
  },
  dialogTitle: {
    paddingTop: "60px",
    width: "100%",
    justifyContent: "space-around",
    display: "flex",
    textAlign: "center",
    backgroundColor: "#e8e9eb",
  },
  captionText: {
    fontSize: "0.90rem",
  },
  logo: {
    width: "80px",
    alignSelf: "center",
    margin: "auto",
  },
  logoContainer: {
    display: "flex",
    borderColor: "black",
    borderStyle: "solid",
    borderWidth: "1px",
    width: "100px",
    alignSelf: "center",
    position: "fixed",
    marginTop: "-50px",
    backgroundColor: "white",
    borderRadius: "100px",
    height: "100px",
  },
  formGroup: {
    marginTop: "10px",
    marginBottom: "10px",
    height: "max-content",
  },
  dialogActionsContainer: {
    flex: "auto",
    flexDirection: "column",
    "& > h6": {
      color: "red",
    },
  },
  dialogActions: {
    minWidth: "40%",
    flex: "auto",
    margin: "20px 50px",
    color: "white",
    backgroundColor: "#339edf",
    "&:hover": {
      backgroundColor: "#2194db",
    },
    height: "40px",
    borderRadius: "5px",
  },
  dialogContent: {
    height: "max-content",
    overflowY: "unset",
  },
  cvcCode: {
    flex: 1,
    display: "flex",
  },
  expDate: {
    flex: 1,
    display: "flex",
    marginRight: "10px",
  },
  splitRow: {
    display: "flex",
    alignItems: "space-between",
    marginTop: 25,
  },
}));

const inputProps = {
  options: {
    style: {
      base: {
        fontSize: "16px", // anything less than 16px causes iphones to auto zoom input fields
      },
    },
  },
};

const StripeInput = ({ component: Component, inputRef, ...props }) => {
  const elementRef = useRef();
  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus,
  }));
  return <Component onReady={(element) => (elementRef.current = element)} {...props} />;
};

export const CreditCardForm = ({ isLoggedIn, onChange }) => {
  const classes = useStyles();

  return (
    <DialogContent className={classes.dialogContent}>
      <FormGroup component={"div"} row className={classes.formGroup}>
        <TextField
          label="Credit Card Number"
          name="ccnumber"
          className={classes.ccNumber}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardNumberElement,
              ...inputProps,
            },
          }}
          fullWidth
          variant="outlined"
          required={true}
        />
      </FormGroup>
      <FormGroup component={"div"} row className={classes.splitRow}>
        <FormControl component={"div"} className={classes.expDate}>
          <TextField
            label="Expiration Date"
            name="expdate"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardExpiryElement,
                ...inputProps,
              },
            }}
            variant="outlined"
            required={true}
          />
        </FormControl>
        <FormControl component={"div"} className={classes.cvcCode}>
          <TextField
            label="CVC"
            name="cvccode"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardCvcElement,
                ...inputProps,
              },
            }}
            variant="outlined"
            required={true}
          />
        </FormControl>
      </FormGroup>
      {isLoggedIn && (
        <FormGroup component={"div"} row className={classes.formGroup}>
          <FormControlLabel control={<Checkbox required color="primary" onChange={onChange} />} label="Remember me" />
        </FormGroup>
      )}
    </DialogContent>
  );
};
