import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import classnames from "classnames";
import { DatePicker } from "@material-ui/pickers";
import { Box, FormControl, InputLabel, makeStyles, MenuItem, Select, FormHelperText, Typography } from "@material-ui/core";
import resetDate from "@shared/utils/resetDate";
import createTimeRange from "@shared/utils/timeRange";

import validators from "@booking/helpers/validators";
import { isError, errorCode } from "@booking/helpers/utils";
import { getSoonestBusinessDate } from "@booking/helpers/utils";
import { Input } from "@booking/helpers/enums";
import { createSlotBookingTimeMap } from "@helpers/booking";
import { useSameDayDatePickerText } from "../../../Admin/ABTests/SameDayContext";
import { fetchDateBookings } from "../../../../store/actions/booking";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  container: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      flex: 1,
      maxWidth: theme.spacing(32),
    },
  },
  date: {
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(2),
    },
  },
  time: {
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(2),
    },
  },
  menuPaper: {
    maxHeight: "30vh",
  },
  helperText: {
    color: theme.palette.primary.main,
    padding: theme.spacing(1, 0),
    [theme.breakpoints.up("md")]: {
      whiteSpace: "nowrap",
    },
  },
  dialog: {
    "& > div > div > div:last-child": {
      display: "none",
    },
  },
}));

const findCloserTimeValue = (timeRange, soonestBusinessDate, date) =>
  timeRange.find(
    ({ hour, minute }) =>
      date?.set("hour", hour).set("minute", minute).isSame(soonestBusinessDate) ||
      date?.set("hour", hour).set("minute", minute).isAfter(soonestBusinessDate)
  );

const DateTimeFieldComponent = ({
  dateField,
  timeField,
  onDateChange,
  onTimeChange,
  showDateRangeOnly,
  overRideTimeRange,
  isRental,
  isRemoval,
  minDate,
  maxDate,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const unix = (date) => dayjs(date).unix();

  const { businessHours, dumpsterBusinessTiming, events } = useSelector((state) => state.config);
  const {
    activeLocation,
  } = useSelector((state) => state.serviceLocations);
  const { coordinates: currentCoordinates } = useSelector((state) => state.address);
  const [soonestBusinessDate] = useState(getSoonestBusinessDate());
  const [timeRange, setTimeRange] = useState(createTimeRange(businessHours.earliest.hour, businessHours.latest.hour));
  const { slotBookings } = useSelector((state) => state.booking);

  const [defaultDateValue] = useState(overRideTimeRange ? dayjs(soonestBusinessDate).add(overRideTimeRange, "hours") : soonestBusinessDate);
  const dateValue = dateField.value;
  const timeValue = timeField.value;

  useEffect(() => {
    onDateChange({ validation: validators[Input.Date](dateField, minDate, maxDate) });
  }, [minDate, maxDate, dateValue, defaultDateValue]);

  useEffect(() => {
    if (timeValue) {
      const dateAndTime = dayjs(dateValue)
        .set("hour", timeField?.value ? timeValue.hour : dateValue.get("hour"))
        .set("minute", timeField?.value ? timeValue.minute : dateValue.get("minute"));

      if (dateAndTime.isBefore(soonestBusinessDate)) {
        const nextValue = findCloserTimeValue(timeRange, soonestBusinessDate, dateValue);

        if (nextValue) {
          onTimeChange({ value: nextValue });
        }
      }
    }
    onTimeChange({
      validation: validators[Input.Time](dateField, timeField),
    });
  }, [dateValue, timeValue]);

  useEffect(() => {
    if (activeLocation) {
      setTimeRange(createSlotBookingTimeMap(slotBookings, activeLocation, currentCoordinates, timeRange));
    }
  }, [activeLocation, slotBookings]);

  const unavailableTimeRange = timeRange.filter((time) => {
    return resetDate(dateValue)?.set("hour", time.hour).set("minute", time.minute).isBefore(dayjs(soonestBusinessDate));
  });

  const isUnavailable = (key) => !!unavailableTimeRange.find((time) => time.key === key);

  const handleDateChange = async (nextDate) => {
    if (nextDate) {
      const nextValue = resetDate(nextDate);
      onDateChange({ value: nextValue });
      onTimeChange({ value: undefined });
      if (isRemoval || (isRental && showDateRangeOnly)) {
        const date = dayjs(nextValue).format("YYYY-MM-DD");
        if (!activeLocation?.fbLocationId) return;
        await dispatch(fetchDateBookings(date, activeLocation.fbLocationId));
      }
    }
  };

  const handleTimeChange = ({ target }) => {
    const key = target.value.toString();
    const nextValue = timeRange.find((time) => time.key === key);

    if (nextValue) {
      onTimeChange({ value: nextValue });
      const dateAndTime = dateValue.set("hour", nextValue.hour).set("minute", nextValue.minute);
      onDateChange({ value: dateAndTime });
    }
  };

  const getMenuItems = (key, hour, minute, isFull) => {
    const chosenDate = dayjs(dateValue).set("hour", hour).set("minute", minute);

    const isSameOrAfter = (d1, d2) => d1.isSame(d2) || d1.isAfter(d2);
    const isSameOrBefore = (d1, d2) => d1.isSame(d2) || d1.isBefore(d2);

    const isOffTime = Boolean(
      events?.find(
        (e) =>
          isSameOrAfter(chosenDate, dayjs(e.fromDate).set("hour", e.fromTime.hour).set("minute", e.fromTime.minute)) &&
          isSameOrBefore(chosenDate, dayjs(e.toDate).set("hour", e.toTime.hour).set("minute", e.toTime.minute))
      )
    );

    const isDisabled = isUnavailable(key) || isFull || isOffTime;

    if (showDateRangeOnly) {
      const { startingTime, timeGap } = dumpsterBusinessTiming;
      return startingTime.includes(hour) ? (
        <MenuItem disabled={isDisabled} value={key} key={key}>
          <Box flex={1}>
            {dayjs().set("hour", hour).set("minute", minute).format("h:mm A")} -{" "}
            {dayjs()
              .set("hour", hour + timeGap)
              .set("minute", minute)
              .format("h:mm A")}
          </Box>
          {isFull && (
            <Typography component="span" variant="subtitle2">
              <i>Full</i>
            </Typography>
          )}
        </MenuItem>
      ) : (
        ""
      );
    } else
      return (
        <MenuItem disabled={isDisabled} value={key} key={key}>
          <Box flex={1}>{dayjs().set("hour", hour).set("minute", minute).format("h:mm A")}</Box>
          {isFull && (
            <Typography component="span" variant="subtitle2">
              <i>Full</i>
            </Typography>
          )}
        </MenuItem>
      );
  };

  const disableHolidays = (date) => {
    const currentDate = unix(date);
    const isHoliday = Boolean(events?.find((e) => e.allDay && currentDate >= unix(e.fromDate) && currentDate <= unix(e.toDate)));
    return isHoliday;
  };

  const [dateOpen, setDateOpen] = useState(false);
  const handleOpenPicker = () => {
    setDateOpen(true);
  };
  const handleClosePicker = () => {
    setDateOpen(false);
  };
  useSameDayDatePickerText(dateValue, dateOpen, isRental);

  return (
    <Box className={styles.root}>
      <Box className={classnames(styles.container, styles.date)}>
        <DatePicker
          id="same-day-pricing-date-picker"
          fullWidth
          autoOk
          label="Date"
          inputVariant="outlined"
          format="MMMM DD, YYYY"
          okLabel=""
          cancelLabel=""
          value={dateValue || null}
          minDate={minDate ? minDate : defaultDateValue}
          maxDate={maxDate}
          onClose={handleClosePicker}
          onOpen={handleOpenPicker}
          onChange={handleDateChange}
          error={isError(dateField.validation)}
          shouldDisableDate={disableHolidays}
          helperText={errorCode(dateField.validation)}
          DialogProps={{ className: styles.dialog }}
        />
      </Box>
      <Box className={classnames(styles.container, styles.date)}>
        <FormControl variant="outlined" fullWidth error={isError(timeField.validation)}>
          <InputLabel id="timeLabel">Time</InputLabel>
          <Select
            fullWidth
            labelId="timeLabel"
            label="Time"
            value={timeValue?.key || ""}
            onChange={handleTimeChange}
            MenuProps={{ classes: { paper: styles.menuPaper } }}
            disabled={!dateField?.value}
          >
            {timeRange.map(({ key, hour, minute, isFull = false }) => getMenuItems(key, hour, minute, isFull))}
          </Select>
          <FormHelperText error={isError(timeField.validation)}>{errorCode(timeField.validation)}</FormHelperText>
        </FormControl>
      </Box>
    </Box>
  );
};

DateTimeFieldComponent.defaultProps = {
  showDateRangeOnly: false,
  overRideTimeRange: false,
  isRemoval: false,
  isRental: false,
};

export default React.memo(DateTimeFieldComponent);
