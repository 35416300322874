import React, { useRef } from "react";
import { makeStyles, Button, Box, Typography } from "@material-ui/core";
import truck from "@assets/delivery-truck.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "10000",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0, 0, 0, 0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "scroll",
  },
  modal: {
    background: "white",
    overflow: "scroll",
    borderRadius: "10px",
    width: "80%",
    maxWidth: "450px",
    padding: "30px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  description: {
    textAlign: "center",
    marginBottom: "20px",
    fontSize: "14px",
  },
  title: {
    fontSize: "30px",
    fontWeight: "bold",
    marginBottom: "20px",
    textAlign: "center",
  },
  imageWrapper: {
    width: "300px",
    padding: "30px",
    margin: "20px 0",
  },
}));

export const SuccessModal = ({ onClick }) => {
  const classes = useStyles();
  const wrapperRef = useRef(null);

  return (
    <Box className={classes.root}>
      <Box className={classes.modal} ref={wrapperRef}>
        <Box className={classes.container}>
          <Typography className={classes.title}> Great work, Provider!</Typography>

          <Typography className={classes.description}>Your pictures have been submitted and will be reviewed shortly.</Typography>
          <Typography className={classes.description}>Once your pictures have been reviews and approved, payment will be processed.</Typography>

          <img src={truck} alt="truck" className={classes.imageWrapper} />
        </Box>
        <Button variant="contained" color="primary" fullWidth onClick={onClick}>
          Finish
        </Button>
      </Box>
    </Box>
  );
};

export default SuccessModal;
