import React from "react";
import { Button, Card, CardMedia, CardContent, makeStyles, Typography } from "@material-ui/core";
import shipment_icon from "@assets/shipment_icon.png";
import junkUrl from "@assets/junk.jpg";
import cardboardUrl from "@assets/cardboard.jpg";
import dumpsterUrl from "@assets/dumpster.jpg";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gap: theme.spacing(3),
    gridTemplateColumns: "repeat(auto-fill, minmax(370px, 1fr) )",
    backgroundColor: "#f1f1f1",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3),
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(auto-fill, minmax(360px, 1fr) )",
      borderRadius: 0,
      margin: 0,
    },
  },
  cardRoot: {
    position: "relative",
    borderRadius: theme.spacing(1),
    boxShadow: "0px 4px 20px rgb(0 0 0 / 15%)",
  },
  status: {
    textAlign: "center",
    position: "absolute",
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
  media: {
    position: "relative",
    height: 140,
    backgroundColor: "#f1f1f1",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  mainContainer: {
    flexGrow: 1,
    display: "flex",
    marginBottom: theme.spacing(2),
    "& img:first-child": {
      marginRight: theme.spacing(1.5),
      marginTop: theme.spacing(0.7),
    },
  },
  buttonContainer: {
    display: "flex",
    alignbids: "center",
  },
  cardIcon: {
    paddingRight: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
    fontSize: "clamp(1.3rem, 1.245rem + 0.2125vw, 1.5rem)",
  },
  addressText: {
    fontSize: "12px",
    color: "#bdbdbd",
  },
  descriptionText: {
    fontSize: "14px",
  },
  amount: {
    fontSize: "clamp(1.3rem, 1.245rem + 0.2125vw, 1.5rem)",
    fontWeight: "bold",
    color: "#1E3B47",
    top: theme.spacing(1.7),
    right: theme.spacing(3),
    position: "absolute",
  },
  dateTimeContainer: {
    display: "flex",
    color: "grey",
    paddingRight: "20px",
    "& p:last-child": {
      marginLeft: theme.spacing(2),
    },
  },
  dateTimeText: {
    fontSize: "clamp(0.9rem, 0.79rem + 0.425vw, 1.3rem)",
    fontWeight: "bold",
    color: "black",
  },
  sidebarBox: {
    position: "relative",
    overflowX: "hidden",
    overflowY: "auto",
    cursor: "pointer",
    maxHeight: "200px",
  },
  readMore: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    paddingTop: theme.spacing(8),
    backgroundImage: "linear-gradient(to bottom, transparent, white)",
  },
  greenText: {
    color: theme.palette.primary.main,
    fontSize: "clamp(0.8rem, 0.6rem + 0.33vw, 1rem)",
    fontWeight: "bold",
    textTransform: "capitalize",
  },
  innerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  widthFull: {
    width: "100%",
  },
}));

export const services = {
  "cardboard-removal": "Cardboard Removal",
  "junk-removal": "Waste Removal",
  "dumpster-rental": "Dumpster Rental",
};

export const urlMap = {
  "Waste Removal": junkUrl,
  "Cardboard Removal": cardboardUrl,
  "Dumpster Rental": dumpsterUrl,
};

const BidListCard = ({ bidItems, activeData }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleBidDetails = ({ bidId }) => {
    history.push(`/get_bids/${bidId}`);
  };

  return (
    <div className={classes.container}>
      {bidItems.map((bid) => (
        <Card className={classes.cardRoot} key={bid.id}>
          <CardMedia className={classes.media} image={bid.taskImages?.length ? bid.taskImages[0] : urlMap[services[bid.task]]} title="Task Image" />
          <div
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              border: "1px solid",
              padding: "5px 10px",
              marginTop: "10px",
              marginRight: "10px",
              borderRadius: "10px",
              color: activeData.color,
              backgroundColor: activeData.backgroundColor,
              borderColor: activeData.color,
            }}
          >
            {bid.status}
          </div>
          <CardContent className={classes.cardContent}>
            <div className={classes.mainContainer}>
              <img src={shipment_icon} alt="shipmentIcon" height="20px" width="20px" />
              <div className={classes.widthFull}>
                <div className={classes.innerContainer}>
                  <Typography classes={{ h5: classes.title }} gutterBottom variant="h5" component="h3">
                    {services[bid.task]}
                  </Typography>
                  <Typography classes={{ body1: classes.greenText }} gutterBottom variant="body1" component="p">
                    ${bid.price}
                  </Typography>
                </div>
                <Typography classes={{ body1: classes.greenText }} gutterBottom variant="body1" component="p">
                  {bid.bookingBidId}
                </Typography>
                <Typography gutterBottom classes={{ body1: classes.addressText }} variant="body1" component="p">
                  {bid.address.street}, {bid.address.city}, {bid.address.state}
                </Typography>
              </div>
            </div>
            <div className={classes.buttonContainer}>
              <Button fullWidth variant="contained" color="primary" className={classes.cardButton} onClick={() => handleBidDetails(bid.bookingBidId)}>
                Bid Details
              </Button>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default BidListCard;
