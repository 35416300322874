import React, { useEffect } from "react";
import classnames from "classnames";
import { Box, FormHelperText, makeStyles } from "@material-ui/core";

import validators from "@booking/helpers/validators";
import { isError, errorCode } from "@booking/helpers/utils";
import { Input, Task } from "@booking/helpers/enums";

import Section from "@booking/components/Section";
import useCommonStyles from "../useStyles";

import { segmentTrack } from "@helpers/segment";

import Option from "./Option";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
  option: {
    marginBottom: theme.spacing(1),
    "&:last-child": {
      marginRight: 0,
    },
    [theme.breakpoints.up("lg")]: {
      flex: 1,
      marginRight: theme.spacing(2),
      marginBottom: 0,
    },
  },
}));

const order = {
  "junk-removal": 0,
  "cardboard-removal": 1,
  "dumpster-rental": 2,
};

const TaskField = ({ field, onChange, isBidMode }) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const locationServices = useSelector((state) => state.serviceLocations?.activeLocation?.services) || [
    Task.JunkRemoval,
    Task.CardboardRemoval,
    Task.DumpsterRental,
  ];

  const { value, validation } = field;

  useEffect(() => {
    onChange({ validation: validators[Input.Task](field) });
  }, [value]);

  const isSelected = (vehicle) => vehicle === value;

  const handleSelect = (nextVehicle) => {
    onChange({ value: nextVehicle });

    /* Segment Analytics */
    segmentTrack("Service Selected", { name: nextVehicle });
  };

  const title = "Choose a service";
  const description = "What do you need?";

  const services = locationServices?.sort((a, b) => (order[a] > order[b] ? 1 : -1));

  return (
    <Section title={title} description={description}>
      <Box className={classnames(commonStyles.field, styles.root)}>
        {services &&
          services?.map((service, index) => (
            <Option
              key={service}
              className={styles.option}
              task={service}
              selected={isSelected(service)}
              onSelect={() => handleSelect(service)}
              showPrice={!isBidMode}
            />
          ))}
      </Box>
      <FormHelperText error={isError(validation)}>{errorCode(validation)}</FormHelperText>
    </Section>
  );
};

export default React.memo(TaskField);
