import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { withRouter } from "react-router";
import Icon from "@material-ui/core/Icon";
import history from "@history";
import withWidth from "@material-ui/core/withWidth";
import { CheckBox } from "@material-ui/icons";
import ImageHandlerForm from "./components/ImageHandlerForm";
import { DatePicker } from "@material-ui/pickers";
import dayjs from "dayjs";
import createTimeRange from "@shared/utils/timeRange";
import { setBookingComplete, setActiveBooking, fetchMyBookings } from "@store/actions/booking";
import AddressField from "@shared/components/AddressField";
import { taskTypes } from "@constants/booking";
import SuccessModal from "./components/SuccessModal";
import { getDumpsite } from "../../helpers/dumpSites";
import { dumpSiteTreatments, dumpSiteTypes } from "../../constants/dumpSites";
import TaskReviewModal from "./components/TaskReviewModal";
import { createUserImages, fetchUserImages, isDuplicateImage } from "../../store/actions/userImages";
import { fetchNearestDumpSites } from "../../store/actions/dumpSites";
import { checkFeatureFlag } from "../../store/actions/featureFlags";
import { getDistance } from "../../shared/utils/maps";
import { segmentTrack } from "../../helpers/segment";

const useStyles = (theme) => ({
  titleBox: {
    margin: theme.spacing(7, 0, 0, 2),
    "& .fas": {
      marginRight: theme.spacing(1),
      verticalAlign: "sub",
    },
  },
  cardContainer: {
    margin: theme.spacing(0, 3),
    border: "1px solid #e0e0e0",
    padding: theme.spacing(2.5),
  },
  promptCompleteBox: {
    marginBottom: theme.spacing(2.5),
  },
  taskComplete: {
    color: "black",
    marginBottom: theme.spacing(2),
    "& .icon": {
      marginRight: "15px",
      verticalAlign: "middle",
    },
    "& > p": {
      fontWeight: "500",
    },
  },
  inlineFields: {
    display: "grid",
    gridTemplateColumns: "300px 300px",
    columnGap: theme.spacing(1),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
      gridTemplateColumns: "auto",
    },
  },
  serviceDateTimeBox: {
    marginBottom: theme.spacing(1),
    "& > h6": {
      color: "black",
      fontWeight: "500",
      fontSize: "16px",
    },
  },
  photosBox: {
    marginTop: theme.spacing(1),
    "& > h6": {
      color: "black",
      fontWeight: "500",
      fontSize: "16px",
    },
    "& .fas": {
      marginRight: theme.spacing(1),
      fontSize: "16px",
      fontWeight: "800",
    },
    "& > button": {
      color: theme.palette.primary.main,
      fontSize: "14px",
      fontWeight: "800",
      border: `2px solid ${theme.palette.primary.main}`,
      marginBottom: theme.spacing(2),
      width: "350px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& > .MuiFormControl-root": {
      [theme.breakpoints.up("md")]: {
        width: "350px",
      },
    },
  },
  wasteDumpBox: {
    "& > h6": {
      color: "black",
      fontWeight: "500",
      fontSize: "16px",
    },
    "& > .MuiFormControl-root": {
      marginBottom: theme.spacing(2),
    },
  },
  inlineFormContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  radioGroupContainer: {
    width: "100%",
    boxSizing: "border-box",
    "& > h6": {
      color: "black",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: 1.3,
      margin: theme.spacing(4, 0, 1, 0),
    },
  },
  radioGroup: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 350px)",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  timeFieldSelect: {
    minWidth: "150px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  field: {
    minWidth: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  addressField: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "350px",
    },
  },
  formContinue: {
    "& > button": {
      width: "20%",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  cardMobile: {
    margin: theme.spacing(2, 3),
    position: "sticky",
    bottom: "0px",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  inlineFieldMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
    },
  },
  sectionDivider: {
    height: 10,
  },
  designatedDropoff: {
    margin: theme.spacing(3, 0, 1, 0),
    borderRadius: 4,
    width: "100%",
  },
  designatedDropoffName: {
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  designatedDropoffAddress: {
    fontSize: "0.9rem",
  },
  errorLabel: {
    color: theme.palette.error.main,
  },
  h6: {
    margin: theme.spacing(2, 0),
  },
  dumpsiteOptionsContainer: {
    width: "45%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  dumpsiteOptions: {
    // justifyContent: "space-between",
    // boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
    border: "1px solid #eee",
    borderRadius: "10px",
    margin: "5px 0px",
    padding: "5px 10px",
  },
  dumpsiteAddress: {},
});

class CompleteTask extends Component {
  constructor(props) {
    super(props);
    let timeRange = createTimeRange(this.props.config.businessHours.earliest.hour, this.props.config.businessHours.latest.hour);
    this.state = {
      user: this.props?.firebase?.profile,
      bookingID: this.props.match.params.id,
      item: this.props.item,
      timeRange: timeRange,
      dateValue: dayjs(),
      timeValue: dayjs(),
      dumpAddress: {},
      dumpCost: "",
      dumpSite: "",
      userImages: [],
      currentUploadedImages: [],
      before: [],
      after: [],
      dumping: [],
      showSuccessModal: false,
      showTaskReviewModal: false,
      dumpsiteType: "",
      wasteTreatment: "",
      assignedDumpSiteId: this.props.booking?.selectedBooking?.assignedDumpsiteId,
      assignedDumpSite: null,
      isDesignatedDumpSite: null,
      dumpsiteOptions: [],
      dumpsiteOptionSelected: null,
      dumpWeight: 0,
      dropOffDistance: 0,
      requiredError: false,
      saving: false,
      duplicacyErrors: {
        before: false,
        after: false,
        dumping: false,
      },
      overweight: false,
      differentDumpsite: false,
      isTaskReviewModalSubmitted: false,
      isAnyFieldChanged: false,
    };
  }

  async checkDumpsiteAssignment() {
    if (this.props.booking?.selectedBooking?.assignedDumpsiteId) {
      const dumpsite = await getDumpsite(this.props.booking?.selectedBooking?.assignedDumpsiteId);
      this.setState({ assignedDumpSite: dumpsite });
    }
  }

  componentDidMount() {
    segmentTrack("Complete Task Flow Started", {
      hasAssignedDumpSite: this.state.isDesignatedDumpSite,
      hasSuggestedDumpSites: this.state.isSuggestedDumpSite,
      numberSuggestedDumpSites: this.state.suggestedDumpSitesCount,
      bookingId: this.state.bookingID,
    });
    this.getUserImages();
    document.getElementById("app-scroller")?.scrollTo({ top: 0, behavior: "smooth" });
    if (!this.props.booking?.selectedBooking) {
      this.props.setActiveBooking(false, this.props.match.params.id); // set booking data on page refresh
    } else {
      this.populateState();
      this.checkDumpsiteAssignment();
      if (!this.props.booking?.selectedBooking?.assignedDumpsiteId && this.props.booking?.selectedBooking?.address.coordinates) {
        this.getDumpsiteOptions();
      } else if (!this.props.booking?.selectedBooking?.assignedDumpsiteId) {
        this.getDumpsiteOptions();
      }
    }
  }

  populateState() {
    const selectedBooking = this.props.booking?.selectedBooking;
    if (selectedBooking && selectedBooking.status === "rejected") {
      this.setState({
        dumpAddress: selectedBooking.dump_address,
        dumpCost: selectedBooking.dump_cost,
        dumpSite: selectedBooking.dump_site,
        before: selectedBooking.beforeImages,
        after: selectedBooking.afterImages,
        dumping: selectedBooking.dumpingImages,
        dumpsiteType: selectedBooking.dumpsiteType,
        wasteTreatment: selectedBooking.wasteTreatment?.[0] || "",
        assignedDumpSiteId: selectedBooking.assignedDumpsiteId,
        isDesignatedDumpSite: selectedBooking.isDesignatedDumpSite,
        isSelectedDumpSite: selectedBooking.userSelectedDumpSite,
        dumpWeight: selectedBooking.dumpWeight,
        duplicacyErrors: {
          before: selectedBooking.duplicacyErrors?.before,
          after: selectedBooking.duplicacyErrors?.after,
          dumping: selectedBooking.duplicacyErrors?.dumping,
        },
        overweight: selectedBooking.warriorSubmissionErrors?.overweight,
        differentDumpsite: selectedBooking.warriorSubmissionErrors?.differentDumpsite,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.booking?.selectedBooking !== this.props.booking?.selectedBooking) {
      this.populateState();
      if (!this.props.booking?.selectedBooking?.assignedDumpsiteId && this.state.user && this.props.booking?.selectedBooking?.address.coordinates) {
        this.getDumpsiteOptions();
      }
    }

    if (
      (this.state.dumpAddress?.coordinates &&
        JSON.stringify(prevState.dumpAddress.coordinates) !== JSON.stringify(this.state.dumpAddress.coordinates)) ||
      (prevState.isDesignatedDumpSite !== this.state.isDesignatedDumpSite && this.state.isDesignatedDumpSite === "Yes") ||
      (this.state.dumpsiteOptionSelected &&
        prevState.dumpsiteOptionSelected !== this.state.dumpsiteOptionSelected &&
        this.state.dumpsiteOptionSelected.id !== "other")
    ) {
      this.getPickupToDropOffDistance();
    }
  }

  checkDuplicacy = async (destinationPath) => {
    const destinationImageUrls = this.state[destinationPath];
    const destinationImages = this.state.currentUploadedImages.filter((image) => destinationImageUrls.includes(image.url));
    const destinationImageSizes = destinationImages.map((image) => image.size);

    const isSelfDuplicate = () => destinationImageSizes.some((size, index) => destinationImageSizes.indexOf(size) !== index);
    const isUploadedDuplicate = () => {
      const nonDestinationImages = this.state.currentUploadedImages.filter((image) => !destinationImageUrls.includes(image.url));
      if (!nonDestinationImages.length || !destinationImageSizes.length) return false;
      return !!nonDestinationImages.find((image) => destinationImageSizes.includes(image.size));
    };
    const isPreviousDuplicate = async () => {
      if (!destinationImages.length) return false;
      const isMatch = await isDuplicateImage(
        destinationImages.map((image) => ({
          ...image,
          uid: this.props.firebase.auth?.uid,
        }))
      );
      return isMatch;
    };

    if (isSelfDuplicate() || isUploadedDuplicate() || (await isPreviousDuplicate())) {
      this.setState({ duplicacyErrors: { ...this.state.duplicacyErrors, [destinationPath]: true } });
    } else {
      this.setState({ duplicacyErrors: { ...this.state.duplicacyErrors, [destinationPath]: false } });
    }
  };

  getUserImages = async () => {
    const data = await fetchUserImages(this.props.firebase.auth?.uid);
    this.setState({ userImages: data });
  };

  checkFlagDumpsites = async () => {
    const data = {
      key: this.state.user?.uid,
      custom: {
        market: this.props.booking?.selectedBooking?.address?.city,
      },
    };
    const isValid = await checkFeatureFlag("dumpsite-locations", data, false);
    return isValid;
  };

  getDumpsiteOptions = async () => {
    const shouldFetch = await this.checkFlagDumpsites();
    if (!shouldFetch) return;
    const dumpsitesByDistance = await fetchNearestDumpSites(this.props.booking?.selectedBooking);
    if (dumpsitesByDistance?.length) {
      this.setState({ dumpsiteOptions: dumpsitesByDistance });
    }
  };

  getPickupToDropOffDistance = () => {
    const distance = getDistance(
      this.props.booking?.selectedBooking.address?.coordinates,
      this.state.dumpAddress?.coordinates || { lat: this.state.assignedDumpSite?.lat, lng: this.state.assignedDumpSite?.lng } || {
          lat: this.state.dumpsiteOptionSelected?.lat,
          lng: this.state.dumpsiteOptionSelected?.lng,
        }
    );
    this.setState({ dropOffDistance: distance });
  };

  addUserImages = async () => {
    const data = {
      payload: this.state.currentUploadedImages.map((image) => ({
        ...image,
        bookingID: this.props.match.params.id,
        uid: this.props.firebase.auth?.uid,
      })),
    };
    await createUserImages(data);
  };

  completeTask = async () => {
    const warriorSubmissionErrors = {
      duplicates: Object.values(this.state.duplicacyErrors).some((error) => error),
      overweight: this.state.dumpWeight - this.props.booking?.selectedBooking?.totalWeight >= 1000 ? true : false,
      differentDumpsite: this.state.isDesignatedDumpSite === "No" ? true : false,
    };

    if (!this.state.isAnyFieldChanged) {
      alert("Please make necessary changes before submit");
      return;
    }
    if (this.isButtonDisabled()) {
      alert("Please fill out all of the mandatory fields");
      this.setState({
        requiredError: true,
      });
      return;
    }
    if (!this.state.isTaskReviewModalSubmitted && Object.values(warriorSubmissionErrors).some((error) => error)) {
      this.setState({
        showTaskReviewModal: true,
      });
      return;
    }
    this.setState({
      saving: true,
    });
    const hour = this.state.timeRange.find((x) => x.key === this.state.timeValue)?.hour || 0;
    const minute = this.state.timeRange.find((x) => x.key === this.state.timeValue)?.minutes || 0;
    let completion = dayjs(this.state.dateValue).set("hour", hour).set("minute", minute).format();

    const useAssignedDumpsite = this.state.assignedDumpSite && this.state.isDesignatedDumpSite === "Yes";
    const { assignedDumpSite, dumpsiteOptionSelected, assignedDumpSiteId } = this.state;
    const useDumpsiteOptionSelected = dumpsiteOptionSelected && dumpsiteOptionSelected?.id !== "other";
    const useDumpsite = assignedDumpSiteId ? useAssignedDumpsite : useDumpsiteOptionSelected;
    const choosedDumpsite = assignedDumpSiteId ? assignedDumpSite : dumpsiteOptionSelected;

    // Needs to be refactored
    // AssignedDumpSite uses firestore schema
    // SuggestedDumpSite uses elastic schema
    // A few fields need to have the ?? to catch it
    const dumpSiteFields = useDumpsite
      ? {
          dump_address: {
            city: choosedDumpsite.city ?? "",
            coordinates: {
              lat: choosedDumpsite.lat ?? "",
              lng: choosedDumpsite.lng ?? "",
            },
            county: choosedDumpsite.county ?? "",
            location: choosedDumpsite.google_address ?? choosedDumpsite.googleFormattedAddress ?? choosedDumpsite.address ?? "",
            placeId: choosedDumpsite.id ?? "",
            state: "",
            street: choosedDumpsite.street ?? "",
            timeZone: choosedDumpsite.time_zone ?? choosedDumpsite.timeZone ?? "",
            zipCode: choosedDumpsite.zip ?? "",
          },
          dump_site: choosedDumpsite.name ?? choosedDumpsite.google_name ?? choosedDumpsite.googleName ?? "",
          dumpsiteType: choosedDumpsite.type,
          wasteTreatment: choosedDumpsite.treatments,
          dumpSiteId: choosedDumpsite.id ?? null,
        }
      : {
          dump_address: this.state.dumpAddress,
          dump_site: this.state.dumpSite,
          dumpsiteType: this.state.dumpsiteType,
          wasteTreatment: [this.state.wasteTreatment],
          dumpSiteId: this.state.dumpAddress?.placeId,
        };

    const warriorSubmissionErrorReasons = [];
    if (warriorSubmissionErrors.duplicates) {
      warriorSubmissionErrorReasons.push("Duplicate uploads are found, duplicacy can be in the current booking or with the previous bookings.");
    }
    if (warriorSubmissionErrors.overweight) {
      warriorSubmissionErrorReasons.push("Entered dump weight is atleast 1000 pounds more than the estimated weight.");
    }
    if (warriorSubmissionErrors.differentDumpsite) {
      warriorSubmissionErrorReasons.push(`Warrior Didn&apos;t dropped off items at the designated location`);
    }

    let form = {
      completion_date: completion,
      task_id: this.state.bookingID,
      user_id: this.props.firebase.auth?.uid,
      beforeImages: this.state.before,
      afterImages: this.state.after,
      dumpingImages: this.state.dumping,
      isBeforeUploading: false,
      isAfterUploading: false,
      isDumpingUploading: false,
      dump_cost: this.state.dumpCost * 100,
      dumpWeight: this.state.dumpWeight * 1,
      closestDistanceAvailable: this.state.dumpsiteOptions.length ? this.state.dumpsiteOptions[0].distance : 0,
      furthestDistanceAvailable: this.state.dumpsiteOptions.length ? this.state.dumpsiteOptions[this.state.dumpsiteOptions.length - 1].distance : 0,
      dropOffDistance: this.state.dropOffDistance,
      suggestedDumpSitesCount: this.state.dumpsiteOptions ? this.state.dumpsiteOptions.length : 0,
      isSuggestedDumpSite: this.state.dumpsiteOptionSelected && this.state.dumpsiteOptionSelected?.id !== "other" ? true : false,
      isDesignatedDumpSite: this.state.isDesignatedDumpSite === "Yes" ? true : false,
      userSelectedDumpSite: this.state.dumpsiteOptionSelected?.id !== "other" ? this.state.dumpsiteOptionSelected : "",
      duplicacyErrors: this.state.duplicacyErrors,
      warriorSubmissionErrors,
      warriorSubmissionErrorReasons: warriorSubmissionErrorReasons.join("<br>"),
      ...dumpSiteFields,
    };
    const response = await this.props.setBookingComplete(form);
    response && this.setState({ showSuccessModal: true, saving: false });
    await this.addUserImages();
  };

  isDumpReceiptRequired = () => {
    const isDesignated = this.state.isDesignatedDumpSite === "Yes";
    const isSelectedDumpSite = this.state.dumpsiteOptionSelected?.id !== "other";
    const isDumpsite = this.state.assignedDumpSiteId ? isDesignated : isSelectedDumpSite;
    const choosedDumpsite = this.state.assignedDumpSiteId ? this.state.assignedDumpSite : this.state.dumpsiteOptionSelected;
    const currentType = isDumpsite ? choosedDumpsite?.type : this.state.dumpsiteType;
    const receiptRequired = dumpSiteTypes[currentType]?.required.receipt;
    return receiptRequired;
  };

  isButtonDisabled = () => {
    const {
      booking: { selectedBooking },
    } = this.props;

    const isDesignated = this.state.isDesignatedDumpSite === "Yes";
    const isSelectedDumpSite = this.state.dumpsiteOptionSelected?.id !== "other";
    const dumpSiteValid =
      (this.state.dumpAddress.placeId && this.state.dumpSite && this.state.dumpsiteType && this.state.wasteTreatment) ||
      (this.state.assignedDumpSiteId ? isDesignated : isSelectedDumpSite);

    if (!dumpSiteValid) {
      return true;
    }

    const isDumpsite = this.state.assignedDumpSiteId ? isDesignated : isSelectedDumpSite;
    const choosedDumpsite = this.state.assignedDumpSiteId ? this.state.assignedDumpSite : this.state.dumpsiteOptionSelected;
    const currentType = isDumpsite ? choosedDumpsite?.type : this.state.dumpsiteType;
    const receiptRequired = this.isDumpReceiptRequired();
    const weightRequired = dumpSiteTypes[currentType]?.required.weight;
    const costRequired = dumpSiteTypes[currentType]?.required.cost;
    const dumpFieldsValid =
      ((receiptRequired && this.state.dumping?.length > 0) || !receiptRequired) &&
      ((weightRequired && this.state.dumpWeight) || !weightRequired) &&
      ((costRequired && this.state.dumpCost) || !costRequired);
    const isDisabled =
      !this.state.dateValue ||
      !this.state.timeValue ||
      !this.state.before.length ||
      !this.state.after.length ||
      !dumpSiteValid ||
      !dumpFieldsValid ||
      (selectedBooking.task === taskTypes["dumpster-rental"].value && (!this.state.dumping.length || !this.state.dumpCost));
    return isDisabled;
  };

  setImageUrls = (destination, urlArray) => {
    this.setState({ [destination]: urlArray });
  };

  setLoaderState = (variable, value) => {
    this.setState({ [variable]: value });
  };

  isDisabledTime = (hour) => {
    return dayjs().hour() < hour && dayjs().isSame(this.state.dateValue, "day");
  };

  renderDuplicateError = (destinationPath) => {
    const error = (
      <Typography variant="body2" style={{ color: "red" }}>
        Duplicate images are uploaded, please upload correct images.
      </Typography>
    );
    if (this.state.duplicacyErrors[destinationPath]) return error;
    return null;
  };

  renderDesignatedField = (classes) => (
    <div className={classes.radioGroupContainer}>
      <Typography className={classes.h6}>Did you drop off your items at the designated location?</Typography>
      <div className={classes.designatedDropoff}>
        <Typography className={classes.designatedDropoffName}>
          {this.state.assignedDumpSite?.googleName || this.state.assignedDumpSite?.name}
        </Typography>
        <Typography className={classes.designatedDropoffAddress}>
          {this.state.assignedDumpSite?.googleFormattedAddress || this.state.assignedDumpSite?.address}
        </Typography>
      </div>
      <FormControl component="fieldset">
        <RadioGroup
          className={classes.radioGroup}
          aria-label="designated"
          name="isDesignatedDumpSite"
          onChange={(_, value) => this.setState({ isDesignatedDumpSite: value })}
        >
          <FormControlLabel value={"Yes"} control={<Radio color="primary" />} label="Yes" />
          <FormControlLabel value={"No"} control={<Radio color="primary" />} label="No, different location" />
        </RadioGroup>
      </FormControl>
    </div>
  );

  renderDumpsiteFields = (classes) => (
    <div>
      <div className={classes.wasteDumpBox}>
        {!this.state.dumpsiteOptions && <Typography className={classes.h6}>Where did you dump the waste?</Typography>}
        <div className={`${classes.inlineFields}`}>
          <TextField
            variant="outlined"
            required
            label="Dumpsite name"
            value={this.state.dumpSite}
            onChange={(event) => this.setState({ dumpSite: event.target.value, isAnyFieldChanged: true })}
            style={{ marginBottom: 20 }}
            error={this.state.requiredError && !this.state.dumpSite}
          />
          <AddressField
            address={this.state.dumpAddress}
            label="Please enter dumpsite address"
            setAddress={(dumpAddress) => this.setState({ dumpAddress, isAnyFieldChanged: true })}
            required
            error={this.state.requiredError && !this.state.dumpAddress?.placeId}
          />
        </div>
        {this.state?.differentDumpsite && (
          <Typography variant="body2" className={classes.errorLabel}>
            Warrior Didn&apos;t dropped off items at the designated location
          </Typography>
        )}
      </div>
      <br />
      <div className={classes.radioGroupContainer}>
        <Typography className={`${classes.h6} ${this.state.requiredError && !this.state.dumpsiteType && classes.errorLabel}`}>
          What type of dumpsite is it? *
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            className={classes.radioGroup}
            aria-label="gender"
            name="dumpsiteType"
            value={this.state.dumpsiteType}
            onChange={(_, value) => this.setState({ dumpsiteType: value, isAnyFieldChanged: true })}
          >
            {Object.keys(dumpSiteTypes).map((type) => (
              <FormControlLabel
                key={type}
                value={dumpSiteTypes[type].value}
                control={<Radio color="primary" />}
                label={dumpSiteTypes[type].customerLabel ?? dumpSiteTypes[type].label}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <br />
        <br />
        <Typography className={`${classes.h6} ${this.state.requiredError && !this.state.wasteTreatment && classes.errorLabel}`}>
          To your best knowledge, how are the items in this task going to be treated? *
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            className={classes.radioGroup}
            aria-label="gender"
            name="wasteTreatment"
            value={this.state.wasteTreatment}
            onChange={(_, value) => this.setState({ wasteTreatment: value, isAnyFieldChanged: true })}
          >
            {Object.keys(dumpSiteTreatments).map((type) => (
              <FormControlLabel
                key={type}
                value={dumpSiteTreatments[type].value}
                control={<Radio color="primary" />}
                label={dumpSiteTreatments[type].customerLabel ?? dumpSiteTreatments[type].label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );

  renderDumpsiteOptions = (classes, dumpsiteOptions) => (
    <>
      <div className={classes.wasteDumpBox}>
        <Typography className={classes.h6}>Where did you dump the waste?</Typography>
      </div>
      <RadioGroup
        aria-label="dumpsiteSelected"
        name="dumpsiteOptionSelected"
        value={this.state.dumpsiteOptionSelected?.id || ""}
        onChange={(_, value) => {
          this.setState({
            dumpsiteOptionSelected: value === "other" ? { id: value } : this.state.dumpsiteOptions?.find((site) => site.id === value) || null,
            isAnyFieldChanged: true,
          });
          segmentTrack("Suggested Dumpsite Clicked", {
            bookingId: this.state.bookingID,
            selectedDumpSiteId: value,
            selectedDumpSiteType: this.state.dumpsiteOptions?.find((site) => site.id === value)?.type ?? "",
            distance: this.state.dumpsiteOptions?.find((site) => site.id === value)?.distance ?? "0",
          });
        }}
      >
        {dumpsiteOptions?.length > 0 ? (
          <div className={classes.dumpsiteOptionsContainer}>
            {dumpsiteOptions?.map((item, index) => (
              <FormControlLabel
                className={classes.dumpsiteOptions}
                key={item.id}
                value={item.id}
                control={<Radio color="primary" />}
                label={
                  <div>
                    <Typography className={classes.dumpsiteAddress}>{item?.name || item?.google_name || item.city}</Typography>
                    <Typography className={classes.dumpsiteAddress} style={{ color: "grey" }}>
                      {item.google_address || item.address}
                    </Typography>
                  </div>
                }
              />
            ))}
            <FormControlLabel className={classes.dumpsiteOptions} value="other" control={<Radio color="primary" />} label="Other" />
          </div>
        ) : (
          ""
        )}
      </RadioGroup>
    </>
  );

  renderForm = () => {
    const { classes } = this.props;
    const { assignedDumpSiteId, isDesignatedDumpSite, dumpsiteOptionSelected, dumpsiteOptions } = this.state;
    const showDumpSiteFields =
      (assignedDumpSiteId && isDesignatedDumpSite === "No") ||
      (!assignedDumpSiteId && (dumpsiteOptionSelected?.id == "other" || !dumpsiteOptions.length));
    const showDumpsiteOptions = !assignedDumpSiteId && !!this.state.dumpsiteOptions;

    return (
      <Card className={classes.cardContainer}>
        <CardContent>
          <div className={classes.promptCompleteBox}>
            <Typography variant="subtitle2">Please complete the information below and upload a few before and after pictures</Typography>
          </div>
          <div className={`${classes.serviceDateTimeBox}`}>
            <Typography className={classes.h6}>Service completion date and time *</Typography>
            <div className={`${classes.inlineFields}`}>
              <DatePicker
                label="Select Date"
                inputVariant="outlined"
                value={this.state.dateValue}
                maxDate={new Date()}
                onChange={(value) => this.setState({ dateValue: value })}
                className={classes.inlineFieldMargin}
                style={{ marginBottom: 20 }}
                required
              />
              <FormControl variant="outlined">
                <InputLabel id="timeLabel">Time *</InputLabel>
                <Select
                  className={classes.timeFieldSelect}
                  labelId="timeLabel"
                  label="Time"
                  value={this.state.timeValue.key}
                  required
                  error={this.state.requiredError && !this.state.timeValue}
                  onChange={(event) => this.setState({ timeValue: event.target.value })}
                >
                  {this.state.timeRange.map(({ key, hour, minute }) => (
                    <MenuItem value={key} key={key} disabled={this.isDisabledTime(hour)}>
                      {dayjs().set("hour", hour).set("minute", minute).format("h:mm A")}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={classes.sectionDivider} />
          <div className={classes.photosBox}>
            <Typography className={`${classes.h6} ${this.state.requiredError && !this.state.before.length > 0 && classes.errorLabel}`}>
              Before Images *
            </Typography>
            <ImageHandlerForm
              baseId={this.state.bookingID}
              basePath={"bookingImages"}
              maxImageCount={10}
              setImageUrls={this.setImageUrls}
              destinationPath={"before"}
              loaderState={"isBeforeUploading"}
              setLoaderState={this.setLoaderState}
              images={this.state.before}
              setCurrentUploadedImages={(imagesData) =>
                this.setState({ currentUploadedImages: [...this.state.currentUploadedImages, imagesData], isAnyFieldChanged: true }, function () {
                  this.checkDuplicacy("before");
                })
              }
              deletedCurrentUploadedImage={(url) =>
                this.setState(
                  { currentUploadedImages: this.state.currentUploadedImages.filter((image) => image.url !== url), isAnyFieldChanged: true },
                  function () {
                    this.checkDuplicacy("before");
                    this.checkDuplicacy("after");
                    this.checkDuplicacy("dumping");
                  }
                )
              }
            />
            {this.renderDuplicateError("before")}
          </div>
          <div className={classes.photosBox}>
            <Typography className={`${classes.h6} ${this.state.requiredError && !this.state.after.length > 0 && classes.errorLabel}`}>
              After Images *
            </Typography>
            <ImageHandlerForm
              baseId={this.state.bookingID}
              basePath={"bookingImages"}
              maxImageCount={10}
              setImageUrls={this.setImageUrls}
              destinationPath={"after"}
              loaderState={"isAfterUploading"}
              setLoaderState={this.setLoaderState}
              images={this.state.after}
              setCurrentUploadedImages={(imagesData) =>
                this.setState({ currentUploadedImages: [...this.state.currentUploadedImages, imagesData], isAnyFieldChanged: true }, function () {
                  this.checkDuplicacy("after");
                })
              }
              deletedCurrentUploadedImage={(url) =>
                this.setState(
                  { currentUploadedImages: this.state.currentUploadedImages.filter((image) => image.url !== url), isAnyFieldChanged: true },
                  function () {
                    this.checkDuplicacy("before");
                    this.checkDuplicacy("after");
                    this.checkDuplicacy("dumping");
                  }
                )
              }
            />
            {this.renderDuplicateError("after")}
          </div>
          <div className={classes.sectionDivider} />
          {assignedDumpSiteId && this.renderDesignatedField(classes)}
          {showDumpsiteOptions && this.renderDumpsiteOptions(classes, this.state.dumpsiteOptions)}
          {showDumpSiteFields && this.renderDumpsiteFields(classes)}
          <div className={classes.sectionDivider} />
          <div className={classes.sectionDivider} />
          <div className={classes.sectionDivider} />
          <div className={`${classes.inlineFields}`}>
            <TextField
              type="number"
              variant="outlined"
              label="Dumping cost"
              required
              value={this.state.dumpCost}
              onChange={(event) => this.setState({ dumpCost: event.target.value, isAnyFieldChanged: true })}
              style={{ marginBottom: 20 }}
              error={!this.state.dumpCost && this.state.requiredError}
            />
            <TextField
              type="number"
              variant="outlined"
              label="Dumping weight (lbs)"
              required
              value={this.state.dumpWeight}
              onChange={(event) => this.setState({ dumpWeight: event.target.value, isAnyFieldChanged: true })}
              className={classes.inlineFieldMargin}
              error={this.state.requiredError && !this.state.dumpWeight}
            />
          </div>
          {this.state?.overweight && (
            <Typography variant="body2" className={classes.errorLabel}>
              Provider entered dump weight is atleast 1000 pounds more than the estimated weight.
            </Typography>
          )}
          <div className={classes.photosBox}>
            <Typography
              className={`${classes.h6} ${this.state.requiredError && !this.state.dumping?.length > 0 && classes.errorLabel}`}
            >{`Please upload the dumping receipt ${!this.isDumpReceiptRequired() && this.state.timeValue?.key ? "(Optional)" : "*"}`}</Typography>
            <ImageHandlerForm
              baseId={this.state.bookingID}
              basePath={"bookingImages"}
              maxImageCount={5}
              setImageUrls={this.setImageUrls}
              destinationPath={"dumping"}
              loaderState={"isDumpingUploading"}
              setLoaderState={this.setLoaderState}
              images={this.state.dumping}
              setCurrentUploadedImages={(imagesData) =>
                this.setState({ currentUploadedImages: [...this.state.currentUploadedImages, imagesData], isAnyFieldChanged: true }, function () {
                  this.checkDuplicacy("dumping");
                })
              }
              deletedCurrentUploadedImage={(url) =>
                this.setState(
                  { currentUploadedImages: this.state.currentUploadedImages.filter((image) => image.url !== url), isAnyFieldChanged: true },
                  function () {
                    this.checkDuplicacy("before");
                    this.checkDuplicacy("after");
                    this.checkDuplicacy("dumping");
                  }
                )
              }
            />
            {this.renderDuplicateError("dumping")}
          </div>
          {this.isDumpReceiptRequired() && <Typography variant="subtitle2">Dumping receipt upload is required for this task</Typography>}
          <Grid container justifyContent="flex-end" className={classes.formContinue}>
            <Button
              onClick={this.completeTask}
              variant="contained"
              disabled={this.state.saving || this.state.user.isBanned}
              color={this.isButtonDisabled() ? "default" : "primary"}
            >
              {this.state.saving ? "Processing" : "Complete Task"}
            </Button>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.titleBox}>
          <Typography variant="h5">
            <Icon
              onClick={() => {
                history.goBack();
              }}
              className={`fas fa-arrow-left ${classes.icon}`}
              color="primary"
            />
            Upload Results
          </Typography>
        </div>
        {this.renderForm()}
        <Card className={classes.cardMobile}>
          <CardContent>
            <Button onClick={this.completeTask} disabled={this.state.saving} variant="contained" color="primary" fullWidth>
              {this.state.saving ? "Processing" : "Complete Task"}
            </Button>
          </CardContent>
        </Card>
        {/* // MODAL   */}
        {this.state.showSuccessModal && (
          <SuccessModal
            onClick={() => {
              this.props.fetchMyBookings(this.props.firebase.auth.uid);
              history.push("/tasks");
            }}
          />
        )}
        {this.state.showTaskReviewModal && (
          <TaskReviewModal
            open={this.state.showTaskReviewModal}
            setOpen={(value) => this.setState({ showTaskReviewModal: value })}
            onClose={() => this.setState({ showTaskReviewModal: false })}
            setIsTaskReviewModalSubmitted={(value) =>
              this.setState({ isTaskReviewModalSubmitted: value }, function () {
                this.completeTask();
              })
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default withStyles(useStyles)(
  connect(mapStateToProps, { setBookingComplete, setActiveBooking, fetchMyBookings })(withRouter(withWidth()(CompleteTask)))
);
