import React, { useEffect, useState } from "react";
import { makeStyles, Box, Grid, Typography, Button, Checkbox } from "@material-ui/core";

import url from "@assets/terms_policy.svg";

import Hidden from "@shared/components/Hidden";
import { scrollToTop } from "../../../methods/scrollToTop";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
    },
  },
  text: {
    marginBottom: "15px",
  },
  agreeBtn: {
    width: "100%",
    marginTop: "30px",
    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
  },
  checkBox: {
    color: theme.palette.primary.main,
    "&checked": {
      color: theme.palette.primary.hover,
    },
  },
}));

function TermsAndPolicy({ next }) {
  const classes = useStyles();
  const [agreementCheck, setAgreementCheck] = useState(false);

  scrollToTop();

  return (
    <Grid container className={classes.root} spacing={4}>
      <Grid item xs={12} md={6}>
        <Typography align="justify" className={classes.text}>
          Please acknowledge that you have read and understood the following terms and policy. If you violate the terms, we may not work with you in
          the future.{" "}
        </Typography>

        <Typography align="justify" className={classes.text}>
          • You will do your best to provide a great service to the customer.{" "}
        </Typography>

        <Typography align="justify" className={classes.text}>
          • You will never re-negotiate service time and pricing with the customer. If the customer requests something out of scope, you will ask the
          customer to book a new service on getdimension.com instead of charging them extra for completing the additional scope.{" "}
        </Typography>

        <Typography align="justify" className={classes.text}>
          • You will not show up more than half an hour ahead or more than half an hour late. If you are running late or can no longer make to the
          appointment, please inform the customer support line as soon as possible. You understand you will face a penalty charge for cancellation
          within 7 hours of the task.{" "}
        </Typography>

        <Typography align="justify" className={classes.text}>
          • You understand that you will provide all the tools and transportation needed.{" "}
        </Typography>

        <Typography align="justify" className={classes.text}>
          • You understand that the entire payout will be the amount you just reviewed, and will not include any reimbursement.{" "}
        </Typography>

        <Typography align="justify" className={classes.text}>
          • You understand that the entire payout will be dispensed to you via Stripe Connect after you complete the job and upload before and after
          pictures. If you do not have a Stripe Connect account yet, we will instruct you how to create one.{" "}
        </Typography>

        <Box style={{ display: "flex", marginBottom: "20px" }}>
          <Checkbox checked={agreementCheck} color="primary" onChange={() => setAgreementCheck(!agreementCheck)} className={classes.checkBox} />
          <p style={{ flexGrow: "1", fontWeight: "bold", cursor: "pointer" }} onClick={() => setAgreementCheck(!agreementCheck)}>
            I&apos;ve read and understood these policies.
          </p>
        </Box>

        <Button color="primary" variant="contained" className={classes.agreeBtn} disabled={!agreementCheck} onClick={() => next()}>
          Continue
        </Button>
      </Grid>
      <Grid item md={6}>
        <Hidden mobile>
          <Box
            style={{
              backgroundImage: `url(${url})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
              height: "300px",
              width: "100%",
            }}
          />
        </Hidden>
      </Grid>
    </Grid>
  );
}

export default TermsAndPolicy;
