import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Checkbox, Grid, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

const useStyles = makeStyles((theme) => ({
  resetButton: {
    textAlign: "left",
    marginRight: "auto",
  },
  title: {
    flex: 1,
    marginLeft: "auto",
    marginRight: "auto",
    color: "black",
    fontWeight: "500",
  },
  formFields: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
  },
  selectAll: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    margin: theme.spacing(2, 0, 1, 0),
  },
  formFieldLabel: {
    flex: 0.8,
    display: "flex",
    alignSelf: "flex-start",
    fontWeight: "bold",
    marginLeft: "10px",
  },
  accordion: {
    boxShadow: "none",
  },
  summary: {
    padding: theme.spacing(0, 4, 0, 0),
  },
  accordionDetails: {
    padding: 0,
    display: "block",
  },
  item: {
    display: "flex",
    padding: theme.spacing(0, 2, 2, 4),
  },
  formFieldObject: {
    padding: 0,
  },
}));

const FilterCardGroup = ({ title, items, TitleIcon, onChange, isCollapsable, showMorelabel }) => {
  const classes = useStyles();
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [showAllItems, setShowAllItems] = useState(false);

  const handleSelectAll = () => {
    setIsSelectAll(true);
    const updateCheckboxItems = items.map((item) => ({ ...item, value: true }));
    onChange(updateCheckboxItems);
  };

  const handleResetAll = () => {
    setIsSelectAll(false);
    const updateCheckboxItems = items.map((item) => ({ ...item, value: false }));
    onChange(updateCheckboxItems);
  };

  const updateCheckBoxItem = (item) => {
    const updateCheckboxItems = items;
    const index = updateCheckboxItems.findIndex((i) => i.id === item.id);
    updateCheckboxItems[index] = { ...item, value: !item.value };
    onChange(updateCheckboxItems);
  };

  const handleGroupClick = (group, checked) => {
    const updateCheckboxItems = items.map((item) => (item.group === group ? { ...item, value: !checked } : item));
    onChange(updateCheckboxItems);
  };

  const formattedItems = items
    .reduce((acc, item) => {
      const index = acc.findIndex((i) => i.group === item.group);
      if (index !== -1) acc[index].items.push(item);
      else acc.push({ group: item.group, items: [item] });
      return acc;
    }, [])
    .sort((a, b) => (a.group > b.group) - (a.group < b.group));

  const handleCheckBoxChange = (selectedItem) => {
    setIsSelectAll(false);
    updateCheckBoxItem(selectedItem);
  };

  const CheckboxAccordion = ({ item, index, onClick }) => {
    const isHideItem = isCollapsable && !showAllItems && index > 4;
    const { group, items } = item;
    const isGroupChecked = items.every((i) => i.value);

    return (
      <Box className={classes.formFields} style={{ display: isHideItem ? "none" : "block" }}>
        <Accordion className={classes.accordion} defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="content" id="header" className={classes.summary}>
            <Checkbox
              key={`checkbox-${group}-${index}`}
              className={classes.formFieldObject}
              color="primary"
              checked={isGroupChecked}
              onClick={(e) => {
                e.stopPropagation();
                handleGroupClick(group, isGroupChecked);
              }}
            />
            <Typography variant="body1" className={classes.formFieldLabel} color={"inherit"}>
              {group}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            {items.map((item, index) => (
              <Box key={`checkbox-${item.label}-${index}`} className={classes.item}>
                <Checkbox className={classes.formFieldObject} color="primary" checked={item.value} onClick={() => onClick(item)} />
                <Typography variant="body1" className={classes.formFieldLabel} color={"inherit"}>
                  {item.label}
                </Typography>
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  };

  return (
    <Grid className={classes.container}>
      <Grid container alignItems="center">
        {TitleIcon}
        <Typography variant={"h6"} className={classes.title}>
          {title}
        </Typography>
      </Grid>
      <Box className={classes.selectAll}>
        <Checkbox className={classes.formFieldObject} color="primary" checked={isSelectAll} onClick={handleSelectAll} />
        <Typography variant="body1" className={classes.formFieldLabel} color={"primary"}>
          Select All
        </Typography>
      </Box>
      <Grid className={classes.innerContainer}>
        {formattedItems.map((item, index) => (
          <CheckboxAccordion key={`Group-${index}`} item={item} index={index} onClick={handleCheckBoxChange} />
        ))}
        {isCollapsable && !showAllItems && (
          <Button
            className={classes.resetButton}
            onClick={() => {
              setShowAllItems(true);
            }}>
            {showMorelabel} <ExpandMoreIcon />
          </Button>
        )}
      </Grid>
      <Button color="primary" className={classes.resetButton} onClick={handleResetAll}>
        Reset
      </Button>
    </Grid>
  );
};

FilterCardGroup.defaultProps = {
  isCollapsable: false,
  showMorelabel: "Show more",
  items: [],
  multiSelect: false,
};

export default FilterCardGroup;
