import { useEffect } from "react";

export const scrollToTop = (trigger) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    // document.getElementById("app-scroller")?.scrollTo({ top: 0, behavior: "smooth" });
  }, [trigger]);
};

export const triggerScrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
  // document.getElementById("app-scroller")?.scrollTo({ top: 0, behavior: "smooth" });
};

// pass in the container id that has a scrollbar, and then the element you want to scrollTo
export const scrollToElement = (element, scrollerId = "app-scroller") => {
  const scrollContainer = document.getElementById(scrollerId);
  if (!scrollContainer || !element) return;

  const isString = typeof element === "string";
  let ele = isString ? document.getElementById(element) : element;

  if (!ele && isString) {
    const namedElements = document.getElementsByName(element);
    ele = namedElements?.item(0);
  }

  if (!ele) return;

  ele.scrollIntoView({ block: "center", behavior: "smooth" });
};
