import React from "react";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backButton: {
    minWidth: theme.spacing(20),
    // [theme.breakpoints.down("sm")]: {
    //   marginBottom: theme.spacing(2),
    // },
  },
}));

const BackButton = ({ fullWidth, onClick }) => {
  const styles = useStyles();

  return (
    <Button color="primary" variant="outlined" fullWidth={fullWidth} className={styles.backButton} onClick={onClick}>
      Back
    </Button>
  );
};

export default BackButton;
