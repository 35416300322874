import junkUrl from "@assets/junk.jpg";
import cardboardUrl from "@assets/cardboard.jpg";
import dumpsterUrl from "@assets/dumpster.jpg";

export const DefaultFilter = {
  searchQuery: "",
  filterTaskType: null,
  filterDateValue: null,
  filterLocation: null,
};

export const DefaultDropdownState = {
  filterTaskType: false,
  filterDateValue: false,
  filterLocation: false,
};

export const DefaultPagenation = { pageNumber: 1, pageSize: 10 };

export const ChipProps = {
  dispensed: {
    color: "primary",
    variant: "default",
    label: "Dispensed",
  },
  processing: {
    color: "primary",
    variant: "outlined",
    label: "Processing",
  },
};

export const services = {
  "cardboard-removal": "Cardboard Removal",
  "junk-removal": "Waste Removal",
  "dumpster-rental": "Dumpster Rental",
};

export const urlMap = {
  "Waste Removal": junkUrl,
  "Cardboard Removal": cardboardUrl,
  "Dumpster Rental": dumpsterUrl,
};
