import { lazy } from "react";
import { privileges } from "./constants/privileges";

import LandingPage from "./views/Website/LandingPage";
import AboutUs from "./views/Website/AboutUs";
import Sustainability from "./views/Website/Sustainability";
import Auth from "./views/Auth";
import TaskDetails from "./views/Tasks/TaskDetails";
import GetTasksPage from "./views/Tasks/GetTasksPage";
import AccountPage from "./views/AccountPage";
import ConfirmationPage from "./views/ConfirmationPage";
import MyTasksPage from "./views/MyTasksPage";
import BookingPage from "./views/BookingPage";
import OrdersPage from "./views/MyOrders/OrdersPage";
import BookingDetails from "./views/MyOrders/OrderDetailsPage";
import ReviewOrder from "./views/MyOrders/OrderReviewPage";
import CompleteTask from "./views/CompleteTask";
import { WordPressPost, WordPressPostTest } from "./shared/components/Wordpress/WordPressPost.tsx";
import { WordPressPostList } from "./shared/components/Wordpress/WordPressPostList";
import { WordPressDirectory } from "./shared/components/Wordpress/WordPressDirectory";
import BidOnProjects from "./views/Bids/BidOnProjects";
import BidDetails from "./views/Bids";
import MyBids from "./views/Bids/MyBids";
import B2BSolutions from "./views/Website/B2BSolutions";
const VehicleInfo = lazy(() => import("./views/VehicleInfo"));
const AlertsPage = lazy(() => import("./views/AlertsPage"));
const CustomerAlertSettings = lazy(() => import("./views/CustomerAlertSettings"));
const Company = lazy(() => import("./views/AccountPage/Company"));
const IntegrationsPage = lazy(() => import("./views/IntegrationsPage"));
const DriversLicense = lazy(() => import("./views/DriversLicense"));
const WatchVideo = lazy(() => import("./views/WatchVideo"));
const ProfilePicture = lazy(() => import("./views/ProfilePicture"));
const CompleteProfile = lazy(() => import("./views/CompleteProfile"));
const Page404 = lazy(() => import("@shared/components/Page404"));
const QuotePage = lazy(() => import("./views/QuotePage"));
const ProfilePage = lazy(() => import("./views/ProfilePage"));
const BubbleRedirect = lazy(() => import("./views/BubbleRedirect"));
const ClientIntake = lazy(() => import("./views/ClientIntake"));
const ProviderAgreement = lazy(() => import("./views/ProviderAgreement"));

// static pages
const ProviderPortal = lazy(() => import("./views/Website/ProviderPortal"));
const PricingGuide = lazy(() => import("./views/PricingGuide"));
const Why = lazy(() => import("./pages/Why"));
const Help = lazy(() => import("./pages/Help"));
const HowItWorks = lazy(() => import("./pages/HowItWorks"));
const Terms = lazy(() => import("./pages/Terms"));
const Faq = lazy(() => import("./pages/Faq"));
const CorporateClient = lazy(() => import("./pages/CorporateClient"));
const CorporateServiceAgreement = lazy(() => import("./pages/CorporateServiceAgreement"));
const PreferredPartnerAgreement = lazy(() => import("./pages/PreferredPartnerAgreement"));
const CaseStudies = lazy(() => import("./pages/CaseStudies"));
const Testimonials = lazy(() => import("./pages/Testimonials"));
const Covid19 = lazy(() => import("./pages/Covid19"));
const Pricing = lazy(() => import("./pages/Pricing"));
const CaseStudy = lazy(() => import("./pages/CaseStudy"));
const CleaningReport = lazy(() => import("./pages/CleaningReport"));
const ProviderAgreementV2 = lazy(() => import("./pages/ProviderAgreementV2"));
const ProviderResource = lazy(() => import("./pages/ProviderResource"));
const TieredServicePricing = lazy(() => import("./pages/TieredServicePricing"));
const AmazonCaseStudy = lazy(() => import("./pages/AmazonCaseStudy"));
const SaresRegis = lazy(() => import("./pages/SaresRegis"));

const wordpressGroups = [
  { path: "/materials/:id", name: "Materials & Waste Streams", component: WordPressPost },
  { path: "/recycling/:id", name: "Recycling", component: WordPressPost },
  { path: "/business/:id", name: "Blog Post", component: WordPressPost },
  { path: "/case-studies/:id", name: "Case Study", component: WordPressPost },
];
const wordpressDirectories = [
  // { path: "/sustainability", exact: true, name: "Sustainability", component: WordPressDirectory },
  // { path: "/b2b-solutions", exact: true, name: "B2B Solutions", component: WordPressDirectory },
];

const routes = [
  { path: "/junk_removal", name: "Landing Page", component: LandingPage, privilege: privileges.CREATE_BOOKING.name },
  { path: "/cardboard_removal", name: "Landing Page", component: LandingPage, privilege: privileges.CREATE_BOOKING.name },
  { path: "/dumpster_rental", name: "Landing Page", component: LandingPage, privilege: privileges.CREATE_BOOKING.name },
  { path: "/booking", name: "Booking Page", component: BookingPage, privilege: privileges.CREATE_BOOKING.name, requiresStripe: true },
  { path: "/review/:id", name: "Review Order", component: ReviewOrder, privilege: privileges.VIEW_CUSTOMER_BOOKING_PAGE.name },
  { path: "/bookings/:id", name: "Order Details", component: BookingDetails, privilege: privileges.VIEW_CUSTOMER_BOOKING_PAGE.name },
  { path: "/bookings", name: "My Orders", component: OrdersPage, privilege: privileges.VIEW_MY_BOOKINGS_PAGE.name },
  { path: "/confirmation/:id", name: "Bubble Confirmation Page", component: BubbleRedirect },
  { path: "/confirmation", name: "Booking Confirmation Page", component: ConfirmationPage },
  { path: "/auth", name: "Sign In Page", component: Auth },
  { path: "/auth/reset_pw", name: "Reset Password" }, // only for showing the proper name on breadcrumb
  { path: "/auth/signup_with_google", name: "Sign up with Google" }, // only for showing the proper name on breadcrumb
  { path: "/provider_portal", name: "Provider Portal", component: ProviderPortal },
  { path: "/get_tasks", name: "Tasks Available Page", component: GetTasksPage, privilege: privileges.VIEW_GET_TASKS_PAGE.name },
  { path: "/tasks", name: "Tasks Assigned Page", component: MyTasksPage, privilege: privileges.VIEW_GET_TASKS_PAGE.name },
  { path: "/task_details/:id", name: "Task Details", component: TaskDetails, requiresStripe: true },
  { path: "/bids", name: "Bids Offered Page", component: MyBids },
  { path: "/get_bids", name: "Bid On Projects Page", component: BidOnProjects },
  { path: "/bid_details/:id", name: "Task Details", component: BidDetails, requiresStripe: true },
  { path: "/complete_details/:id", name: "Complete Task", component: CompleteTask, privilege: privileges.VIEW_GET_TASKS_PAGE.name },
  { path: "/quote_details/:id", name: "Quote Details", component: QuotePage },
  { path: "/account", name: "Account", component: AccountPage, privilege: privileges.VIEW_ACCOUNT_PROFILE.name, requiresStripe: true },
  { path: "/integrations", name: "Integrations", component: IntegrationsPage, privilege: privileges.VIEW_INTEGRATIONS_PAGE.name },
  { path: "/company", name: "Company", component: Company, privilege: privileges.VIEW_COMPANY_SETTINGS.name },
  { path: "/alerts", name: "Alerts", component: AlertsPage, privilege: privileges.VIEW_ACCOUNT_SETTINGS.name },
  { path: "/drivers_license", name: "Drivers License", component: DriversLicense, privilege: privileges.VIEW_ACCOUNT_SETTINGS.name },
  { path: "/upload_picture", name: "Upload Picture", component: ProfilePicture, privilege: privileges.VIEW_ACCOUNT_SETTINGS.name },
  { path: "/watch_video", name: "Watch Videos", component: WatchVideo, privilege: privileges.VIEW_ACCOUNT_SETTINGS.name },
  { path: "/vehicle_info", name: "VehicleInfo", component: VehicleInfo, privilege: privileges.VIEW_ACCOUNT_SETTINGS.name },
  { path: "/complete_profile", name: "CompleteProfile", component: CompleteProfile, privilege: privileges.VIEW_ACCOUNT_SETTINGS.name },
  { path: "/alerts_sms_email", name: "CustomerAlertSettings", component: CustomerAlertSettings, privilege: privileges.VIEW_ACCOUNT_SETTINGS.name },
  { path: "/profile_details/:id", name: "Profile Details", component: ProfilePage, authRequired: true },
  { path: "/provider_agreement", name: "Provider Agreement", component: ProviderAgreement, privilege: privileges.VIEW_GET_TASKS_PAGE.name },
  { path: "/guest/:id", name: "Bubble Guest", component: BubbleRedirect },
  { path: "/pricing_guide", name: "Pricing Guide", component: PricingGuide },
  { path: "/newclientintake", name: "New Client Intake", component: ClientIntake, requiresStripe: true },
  { path: "/b2b-solutions", name: "B2B Solutions", component: B2BSolutions },
  { path: "/about_us", name: "About US", component: AboutUs },
  { path: "/sustainability", name: "Sustainability Hub", component: Sustainability },
  { path: "/", exact: true, name: "Home", component: LandingPage },

  // static pages

  { path: "/why", name: "Why Dimension", component: Why },
  { path: "/how-it-works", name: "How It Works", component: HowItWorks },
  { path: "/help", name: "Help", component: Help },
  { path: "/terms", name: "Terms", component: Terms },
  { path: "/faq", name: "Faq", component: Faq },
  { path: "/corporate_client", name: "Corporate Client", component: CorporateClient },
  { path: "/corporateserviceagreement", name: "Corporate Service Agreement", component: CorporateServiceAgreement },
  { path: "/preferredpartneragreement", name: "Preferred Partner Agreement", component: PreferredPartnerAgreement },
  { path: "/case-studies", exact: true, name: "Case Studies", component: CaseStudies },
  { path: "/testimonials", name: "Testimonials", component: Testimonials },
  { path: "/covid-19", name: "Covid 19", component: Covid19 },
  { path: "/pricing", name: "Pricing", component: Pricing },
  { path: "/case-study/:id", name: "Case Study", component: CaseStudy },
  { path: "/cleaning_report/:id", name: "Cleaning Report", component: CleaningReport },
  { path: "/provideragreementv2", name: "Provider Agreement", component: ProviderAgreementV2 },
  { path: "/providerresource", name: "Provider Resource", component: ProviderResource },
  { path: "/tieredservicepricing", name: "Tiered Service Pricing", component: TieredServicePricing },
  { path: "/amazon_case_study", name: "Amazon Case Study", component: AmazonCaseStudy },
  { path: "/sares_regis", name: "Sares Regis", component: SaresRegis },
  { path: "/service-provider-policy", name: "Service Provider Policy", component: WordPressPost },
  { path: "/staging/:id", name: "Test Page", component: WordPressPostTest },
  { path: "/blog", exact: true, name: "Blog", component: WordPressPostList },
  { path: "/blog/:id", name: "Blog Post", component: WordPressPost },
  { path: "/privacy-policy", name: "Privacy Policy", component: WordPressPost },
  { path: "/slack", name: "Slack Integration", component: WordPressPost },
  { path: "/instacart-landing", name: "Instacart", component: WordPressPost },
  { path: "/in-the-news", name: "In the news", component: WordPressPost },
  ...wordpressGroups,
  ...wordpressDirectories,
  // 404

  { path: "*", name: "404 Not Found", component: Page404 },
];

export default routes;
