import React, { useEffect, useState, useRef } from "react";
import { PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import { LineDivider } from "./LineDivider";
import { segmentTrack } from "../../../helpers/segment";
import { DialogContent, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import {
  applyBulkDiscount,
  applyCouponDiscount,
  calculateCustomItemsPrices,
  calculateStairsDismantlingPrice,
  calculateTotalPrice,
} from "../../../views/BookingPage/helpers/utils";
import dayjs from "dayjs";
import { Task } from "../../../views/BookingPage/helpers/enums";

export const WalletPay = ({ stripe, elements, confirmWalletPayment, summaryProps, isMultipleQuotes }) => {
  const [paymentRequest, setPaymentRequest] = useState(null);
  const paid = useRef(false);

  const priceMultiplier = useSelector((state) => state.serviceLocations.activeLocation.priceMultipliers);
  const selectedCoupon = useSelector((state) => state.coupon.selectedCoupon);
  const sameDaySurge = useSelector((state) => state.ui.sameDaySurge.value);

  const { task, vehicle, junk, dumpster, stairs, dismantling, date, pickupDate, isServiceFeeWaived, isVehicleFeeWaived } = summaryProps;
  const extraDays = dayjs(pickupDate).diff(dayjs(date).hour(0).add(20, "days"), "days");
  const isDumpsterTask = task === Task.DumpsterRental;

  const getPrice = () => {
    const cleanTotal = calculateTotalPrice({ task, vehicle, junk, dumpster, isServiceFeeWaived, isVehicleFeeWaived }); // calculate raw total
    const stairsDismantlingTotal = calculateStairsDismantlingPrice({
      stairs,
      dismantling,
    }); // stairs and dismantling total
    const customItemTotal = calculateCustomItemsPrices(junk);

    const t1 = cleanTotal * (priceMultiplier / 100); // apply location multipliers
    const t2 = applyBulkDiscount({ total: t1, task }); // apply bulk discount
    const t3 = t2 + stairsDismantlingTotal;
    const t4 = applyCouponDiscount({ total: t3, selectedCoupon }); // apply coupon
    const t5 = t4 + customItemTotal; // apply the custom items
    let finalTotal = t5;
    let adjustment = 0;

    // same day pricing adjustments
    if ((!date || (date && dayjs().isSame(date, "day"))) && !isDumpsterTask) {
      adjustment = sameDaySurge;
    }

    //pickup days extra charge after 20 days for dumpster rental
    if (extraDays > 0 && isDumpsterTask) {
      adjustment += extraDays * 20 * 100;
    }

    return finalTotal + adjustment;
  };

  const checkMethods = async () => {
    const pr = stripe.paymentRequest({
      currency: "usd",
      country: "US",
      total: {
        label: "Dimension Service",
        amount: Math.floor(getPrice()),
      },
    });
    const result = await pr.canMakePayment();
    if (result) {
      setPaymentRequest(pr);
    }

    pr.on("paymentmethod", async (e) => {
      try {
        if (!paid.current) {
          paid.current = true;
          await confirmWalletPayment(e.paymentMethod.id);
        }
      } catch (error) {
        paid.current = false;
        e.complete("fail");
        return;
      }
      segmentTrack("Wallet Pay");
      e.complete("success");
      return;
    });
  };

  useEffect(() => {
    if (!stripe || !elements || paid.current || isMultipleQuotes) {
      return;
    }
    checkMethods();
  }, [stripe]);

  if (!paymentRequest) {
    return (
      <DialogContent style={{ marginTop: 15, height: "max-content", overflowY: "unset" }}>
        <Typography variant="subtitle2" gutterBottom>
          Please select a payment method
        </Typography>
      </DialogContent>
    );
  }

  return (
    stripe && (
      <>
        <div style={{ paddingTop: 24, paddingLeft: 24, paddingRight: 24, marginBottom: 0 }}>
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        </div>
        <LineDivider text={"Or select another payment method"} />
      </>
    )
  );
};
