import { businessFieldsSchema, registerFormDefaults, registerFormSchema } from "@constants/auth";
import { roles } from "@constants/roles";
import loginClasses from "@css/components/LoginModal.module.css";
import classes from "@css/components/Register.module.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, IconButton, InputAdornment, Typography } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Button from "@shared/components/Button";
import InputField from "@shared/components/Form/InputField";
import OtpValidationPopup from "@shared/components/OtpValidation";
import { registerWithEmail } from "@store/actions/auth";
import { sendVerifyToken } from "@store/actions/twilio";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "react-phone-number-input/style.css";
import { useDispatch, useSelector } from "react-redux";
import { isProduction } from "../../constants/environments";
import { segmentTrack } from "../../helpers/segment";
import { scrollToTop } from "../../methods/scrollToTop";
import GoogleBtn from "../../shared/components/GoogleBtn";
import { LineDivider } from "../../shared/components/PaymentOptions/LineDivider";
import { createBusinessAccountFromClient } from "../../store/actions/businessAccounts";
import AgreementModal from "./AgreementModal";
import AuthRedirector from "./AuthRedirector";

const Register = () => {
  const dispatch = useDispatch();

  const userType = useSelector((state) => state.tempData.userType);
  const isCustomer = userType === roles.customer.value;
  const isBusiness = userType === roles.business.value;

  const [showPassword, setShowPassword] = useState(false);
  const [showAgreement, setAgreement] = useState(false);
  const [agreementCheck, setAgreementCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [isTwilioVerified, setIsTwilioVerified] = useState(false);
  const [btnLabel, setBtnLabel] = useState(isCustomer ? "Create Account" : "Verify");
  const [formData, setFormData] = useState(null);
  const formSchema = isBusiness ? registerFormSchema.concat(businessFieldsSchema) : registerFormSchema;

  scrollToTop();

  const {
    control: registerControl,
    watch,
    handleSubmit: registerSubmit,
    errors: registerErrors,
  } = useForm({
    defaultValues: registerFormDefaults,
    resolver: yupResolver(formSchema),
  });

  const phoneNumber = watch("phone");

  useEffect(() => {
    if (isTwilioVerified) {
      setIsTwilioVerified(false);
      setBtnLabel("Verify");
    }
  }, [phoneNumber]);

  const trackProviderAction = (action, payload) => {
    if (userType === roles.provider.value) {
      segmentTrack(action, payload);
    }
  };

  const registerBusiness = async (data, verified) => {
    const accountData = {
      businessAccountData: {
        name: data.companyName,
        ownerEmail: data.email,
        ownerPhone: data.phoneNumber,
        closedBy: "Other",
      },
      businessUserData: {
        firstName: data.firstName,
        lastName: data.lastName,
        fullName: `${data.firstName} ${data.lastName}`,
        email: data.email,
        password: data.password,
        phone: data.phone,
        userType: "customer",
        twilioVerified: verified,
        companyName: data.companyName,
      },
    };
    await dispatch(createBusinessAccountFromClient(accountData));
    setIsLoading(false);
  };

  const registerClient = async (data, verified) => {
    trackProviderAction("Warrior Clicks Submit - Register", { fullName: `${data.firstName} ${data.lastName}`, email: data.email });
    const user = {
      firstName: data.firstName,
      lastName: data.lastName,
      fullName: `${data.firstName} ${data.lastName}`,
      email: data.email,
      password: data.password,
      phone: data.phone,
      userType,
      twilioVerified: verified,
    };
    await dispatch(registerWithEmail({ newUser: user }));
    setIsLoading(false);
    return;
  };

  const register = async (data, verified) => (isBusiness ? registerBusiness(data, verified) : registerClient(data, verified));

  const onRegisterSubmit = async (data) => {
    setIsLoading(true);

    if (isCustomer || isBusiness || isTwilioVerified || !isProduction) {
      await register(data);
      return;
    }

    if (phoneNumber && !isTwilioVerified) {
      // send verify token
      trackProviderAction("Warrior Clicks Verify - Register", { phone: phoneNumber });
      const res = await sendVerifyToken(phoneNumber);
      setIsLoading(false);
      if (!res) return;
      if (res.status === "approved") {
        setIsTwilioVerified(true);
        setBtnLabel("Create Account");
      } else {
        setFormData(data);
        setShowOtpInput(true);
      }
      return;
    }
  };

  const verify = async (setter) => {
    const data = formData;
    if (!data) {
      setter("Please fill out the form");
      return;
    }
    await register(data, true);
  };

  const resend = async (e, setter) => {
    e.preventDefault();
    if (phoneNumber && !isTwilioVerified) {
      await sendVerifyToken(phoneNumber);
      setter("SMS resent to your phone number");
    }
  };

  useEffect(() => {
    if (userType === roles.provider.value) {
      setAgreement(true);
    }
  }, [userType]);

  const inputProps = {
    control: registerControl,
    errors: registerErrors,
    className: classes.textField,
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <AuthRedirector />

      {showAgreement && <AgreementModal setAgreement={setAgreement} agreementCheck={agreementCheck} setAgreementCheck={setAgreementCheck} />}

      {showOtpInput && (
        <OtpValidationPopup
          phoneNumber={phoneNumber}
          setOpen={setShowOtpInput}
          isNumberVerified={isTwilioVerified}
          setNumberVerified={verify}
          resend={resend}
        />
      )}
      {!isBusiness && !showOtpInput && (
        <>
          <div className={loginClasses.googleBtnContainer}>
            <GoogleBtn className={loginClasses.googleBtn} signUp={true}>
              Sign up with Google
            </GoogleBtn>
          </div>
          <LineDivider text={`Or register with email and password`} />
        </>
      )}
      <form className={classes.form} onSubmit={registerSubmit(onRegisterSubmit)}>
        <div style={{ marginBottom: "10px", display: showOtpInput ? "none" : "inherit" }}>
          {isBusiness && (
            <Grid container spacing={2} className={classes.rowContainer}>
              <Grid item xs={12} md={12}>
                <InputField name="companyName" label="Company Name" {...inputProps} />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2} className={classes.rowContainer}>
            <Grid item xs={12} md={6}>
              <InputField fullWidth name="firstName" label="First Name" {...inputProps} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField fullWidth name="lastName" label="Last Name" {...inputProps} />
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.rowContainer}>
            <Grid item xs={12} md={6}>
              <InputField fullWidth name="email" label="Email" {...inputProps} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField fullWidth type="phone" name="phone" label="Phone Number" placeholder="Phone number" {...inputProps} />
              {isTwilioVerified && <Typography style={{ color: "#1E3B47" }}>Your phone number is verified.</Typography>}
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.rowContainer}>
            <Grid item xs={12} md={6}>
              <InputField
                {...inputProps}
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField
                {...inputProps}
                name="confirmPassword"
                label="Confirm Password"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={2} style={{ display: showOtpInput ? "none" : undefined }}>
          <Grid item style={{ flex: 1 }} align="end">
            <Button type="submit" variant="contained" loading={isLoading} className={classes.registerBtn}>
              {btnLabel}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default Register;
