import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import Button from "@shared/components/Button";

const useStyles = makeStyles((theme) => ({
  continueButton: {
    [theme.breakpoints.up("md")]: {
      maxWidth: theme.spacing(30),
      marginLeft: "auto",
      marginTop: "35px",
    },
  },
}));

const ContinueButton = ({ className, disabled, onClick, label, loading }) => {
  const styles = useStyles();

  return (
    <Button
      fullWidth
      color="primary"
      variant="contained"
      className={classnames(className, styles.continueButton)}
      disabled={disabled}
      onClick={onClick}
      loading={loading}
    >
      {label}
    </Button>
  );
};

export default ContinueButton;
