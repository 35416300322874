import React from "react";
import classnames from "classnames";
import { makeStyles, Typography } from "@material-ui/core";

import { AddressFieldErrorCode } from "@booking/helpers/enums";

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
  },
}));

const AddressError = ({ className, error }) => {
  const styles = useStyles();
  const typographyClassName = classnames(className, styles.error);
  return <Typography className={typographyClassName}>{error.code}</Typography>;
};

export default AddressError;
