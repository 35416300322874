import React, { useEffect } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { Box, makeStyles, Typography } from "@material-ui/core";

import FieldError from "@booking/helpers/errors/FieldError";
import { AddressFieldErrorCode, Task } from "@booking/helpers/enums";

import AddressError from "./AddressError";
import { useDispatch } from "react-redux";
import AddressField, { addressNull } from "@shared/components/AddressField";
import { validateServiceLocation } from "@store/actions/address";
import { segmentTrack } from "@helpers/segment";

const useStyles = makeStyles((theme) => ({
  typography: {
    opacity: "0.5",
    marginBottom: theme.spacing(2),
  },
  success: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
}));

const ServiceableField = (props) => {
  const { field, onChange, validate, label, type, placeholder, restrict, types, hideLabel, inputProps, ...rest } = props;
  const styles = useStyles();
  const dispatch = useDispatch();

  const { value, touched, validation } = field;

  async function validateField(address) {
    if (!address?.location) {
      if (validate) {
        onChange({ validation: { ...validation, isValid: false, error: new FieldError(AddressFieldErrorCode.required) } });
      }
      return;
    }

    const result = await dispatch(validateServiceLocation(address, props.task));

    if (result) {
      if (!address[type]) {
        onChange({ validation: { validate: true, isValid: false, error: new FieldError(AddressFieldErrorCode.invalidAddress) } });
      } else {
        onChange({ validation: { validate: true, isValid: true, error: null } });
      }
    } else {
      onChange({ validation: { validate: true, isValid: false, error: new FieldError(AddressFieldErrorCode.AddressNotSupported) } });
      segmentTrack("Location Not Served", {
        location: address,
        url: location.pathname + location.search,
      });
    }
  }

  useEffect(() => {
    validateField(value);
  }, [value, props.task]);

  const handleChange = (value) => {
    onChange({ value, touched: !!value.location });
  };

  const showError = validate || touched;
  const error = showError && !!(validation && !validation.isValid && validation.validate);

  return (
    <Box {...rest}>
      {!hideLabel &&
        (showError && validation?.validate ? (
          <React.Fragment>
            {validation.isValid && <Typography className={cn(styles.typography, styles.success)}>Yes! We serve your area.</Typography>}
            {error && <AddressError className={styles.typography} error={validation.error} />}
          </React.Fragment>
        ) : (
          <Typography className={cn(styles.typography)}>Check if we serve in your area</Typography>
        ))}
      <AddressField
        restrict={restrict}
        address={value}
        error={error}
        label={label}
        setAddress={handleChange}
        placeholder={placeholder}
        types={types}
        {...inputProps}
      />
    </Box>
  );
};

ServiceableField.defaultProps = {
  field: {},
  label: "",
  task: Task.JunkRemoval,
  onChange: () => {},
  validate: false, // validate on mount
  type: "location",
  hideLabel: false,
  inputProps: {},
};

ServiceableField.propTypes = {
  type: PropTypes.oneOf(Object.keys(addressNull)),
};

export default React.memo(ServiceableField);
