import React from "react";
import PropTypes from "prop-types";
import { DatePicker } from "@material-ui/pickers";
import { Button, Divider, FormControl, FormControlLabel, makeStyles, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";
import { taskTypes } from "@constants/booking";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#fff",
    borderBottom: "1px solid #e0e0e0",
    borderRadius: "5px",
    marginBottom: theme.spacing(4),
    boxSizing: "border-box",
  },
  filterHeader: {
    fontSize: "1.1rem",
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    padding: theme.spacing(2, 4),
    "& h5:first-child": {
      flexGrow: 1,
    },
  },
  filterArea: {
    padding: theme.spacing(2, 4),
  },
  formItem: {
    marginBottom: theme.spacing(2),
  },
  radioBoxes: {
    paddingLeft: theme.spacing(0),
  },
  backIcon: {
    height: "24px",
    cursor: "pointer",
  },
  filterTitle: {
    fontSize: "0.9rem",
    marginBottom: theme.spacing(1),
  },
  resetFilters: {
    color: "red",
    fontSize: "15px",
  },
  buttonContainer: {
    display: "flex",
    padding: theme.spacing(0, 4, 2, 4),
    "& button:first-child": {
      marginRight: theme.spacing(1),
    },
  },
}));

function MobileFilter({ filter, setShowMobileFilter, setFilter, applyFilter }) {
  const classes = useStyles();

  const handleApplyFilter = () => {
    setShowMobileFilter(false);
    applyFilter(filter);
  };
  const handleClearFilter = () => {
    setShowMobileFilter(false);
    applyFilter();
  };

  return (
    <div className={classes.container}>
      <div className={classes.filterArea}>
        <div className={classes.formItem}>
          <div className={classes.filterTitle}>Select task type</div>
          <FormControl className={classes.radioBoxes} component="fieldset">
            <RadioGroup
              aria-label="taskType"
              name="taskType"
              value={filter.filterTaskType}
              onChange={(e) => setFilter({ ...filter, filterTaskType: e.target.value })}>
              {Object.values(taskTypes).map((task, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    className={classes.normalCheckbox}
                    control={<Radio color="primary" />}
                    label={task.label}
                    value={task.value}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </div>
        <div className={classes.formItem}>
          <div className={classes.filterTitle}>Select task date</div>
          <DatePicker
            fullWidth
            label=""
            inputVariant="outlined"
            value={filter.filterDateValue}
            onChange={(value) => setFilter({ ...filter, filterDateValue: value })}
          />
        </div>
        <div className={classes.formItem}>
          <div className={classes.filterTitle}>Enter task location</div>
          <TextField
            fullWidth
            id="location"
            label="Enter ZIP code"
            variant="outlined"
            value={filter.filterLocation}
            onChange={(e) => setFilter({ ...filter, filterLocation: e.target.value })}
          />
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button fullWidth onClick={handleClearFilter} variant="outlined" className={classes.resetFilters}>
          Reset Filters
        </Button>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          disabled={!!(filter.filterTaskType && filter.filterDateValue && filter.filterLocation)}
          onClick={handleApplyFilter}>
          Apply Filters
        </Button>
      </div>
    </div>
  );
}

MobileFilter.propTypes = {
  filter: PropTypes.object,
  setShowMobileFilter: PropTypes.func,
  setFilter: PropTypes.func,
  applyFilter: PropTypes.func,
};

export default MobileFilter;
