import googleSvgUrl from "@assets/google.svg";
import history from "@history";
import { Button, Icon, makeStyles } from "@material-ui/core";
import { signInWithGoogle } from "@store/actions/auth";
import cn from "classnames";
import React from "react";
import { useDispatch } from "react-redux";
import { segmentTrack } from "../../helpers/segment";
import { getLinkWithGclid } from "../../history";

const useStyles = makeStyles((theme) => ({
  googleBtn: {
    "& .G": {
      verticalAlign: "middle",
      display: "inline-block",
      fontSize: 27,
      fontWeight: 900,
      marginRight: theme.spacing(0.8),
    },
  },
  iconContainer: {
    [theme.breakpoints.down("xs")]: {
      height: "35px",
      width: "35px",
    },
    textAlign: "center",
    height: "45px",
    width: "45px",
  },
  imageIcon: {
    [theme.breakpoints.down("xs")]: {
      height: "35px",
    },
    height: "45px",
  },
}));

const GoogleBtn = ({ children, className, signUp, onClick = null }) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const handleClick = () => {
    segmentTrack("Sign In with Google clicked");
    onClick ? onClick() : signUp ? history.push(getLinkWithGclid("/auth/signup_with_google")) : dispatch(signInWithGoogle({ loader: true }));
  };

  return (
    <Button
      fullWidth
      variant="outlined"
      color="primary"
      startIcon={
        <Icon classes={{ root: styles.iconContainer }}>
          <img className={styles.imageIcon} src={googleSvgUrl} />
        </Icon>
      }
      onClick={() => {
        onClick ? onClick() : signUp ? history.push(getLinkWithGclid("/auth/signup_with_google")) : dispatch(signInWithGoogle({ loader: true }));
      }}
      className={cn(styles.googleBtn, className)}
    >
      {children}
    </Button>
  );
};

export default GoogleBtn;
