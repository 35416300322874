/* if we're on iOS, open in Apple Maps */
export const openCoordinatesOnMap = (coordinates) => {
  if (navigator.platform.indexOf("iPhone") != -1 || navigator.platform.indexOf("iPad") != -1 || navigator.platform.indexOf("iPod") != -1) {
    window.open(`maps://maps.google.com/maps?daddr=${coordinates.lat},${coordinates.lng}amp;ll=START_ADD`);
  } else if (!navigator.platform.indexOf("webOS")) {
    /* else use Google */
    window.open(`https://maps.google.com/maps?daddr=${coordinates.lat},${coordinates.lng}amp;ll=START_ADD`);
  } else {
    window.open(`https://maps.google.com/maps?daddr=${coordinates.lat},${coordinates.lng}`);
  }
};
