import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { Box, makeStyles, Typography } from "@material-ui/core";
import deliveryTruck from "../../assets/delivery-truck.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "rgba(0, 0, 0, 0.6)",
    backdropFilter: "blur(7px)",
  },
  title: {
    fontSize: "2.0rem",
    padding: theme.spacing(1.5, 0),
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  body: {
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    lineHeight: "2rem",
    textAlign: "center",
    padding: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(8, 2),
    },
  },
  bodyText: {
    fontSize: "1.2rem",
    padding: theme.spacing(0, 3, 3),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  confirm: {
    border: "1px solid #fff",
    background: theme.palette.primary.main,
    color: "#fff",
    padding: theme.spacing(2, 5),
    height: "max-content",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const WelcomeDialog = ({ title = "Welcome", body = "", buttonText = "Let's go", image = deliveryTruck, onClose, ...props }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const handleClose = (e, reason) => {
    if (onClose) {
      onClose(reason);
    }
    setOpen(false);
  };

  return (
    <Dialog
      className={classes.root}
      open={open}
      {...props}
      onClose={handleClose}
      maxWidth="md"
      aria-labelledby="welcome-dialog-title"
      aria-describedby="welcome-dialog-description"
    >
      <Box className={classes.body}>
        <img height="150" className={classes.image} src={image} alt="Delivery Truck" />
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.bodyText}>{body}</Typography>
        <Box className={classes.actions}>
          <Button className={classes.confirm} onClick={handleClose} color="primary" variant="contained">
            {buttonText}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default WelcomeDialog;
