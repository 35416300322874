import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAxiosGet } from "../axios";
import { isProduction } from "../constants/environments";
import { segmentTrack } from "../helpers/segment";

const UrlShortnerRedirect = () => {
  const { id }: any = useParams();
  const handleRedirect = async () => {
    let link = "";
    try {
      const { data }: any = await useAxiosGet(`/short-link/convert/${id}`);
      link = data;
      if (isProduction) {
        link = data.replace("trash-warrior.web.app", "getdimension.com");
      }
      window.location.replace(link ?? `https://getdimension.com/`);
    } catch (error) {
      segmentTrack("Error Loading Shortlink", { error, path: window.location.pathname });
      window.location.replace(`https://getdimension.com/`);
    }
  };
  useEffect(() => {
    handleRedirect();
  }, []);
  return <div />;
};

export default UrlShortnerRedirect;
