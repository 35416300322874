import React from "react";
import { makeStyles, Box, CardContent, withStyles, LinearProgress, Paper, Divider, Typography } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    margin: theme.spacing(4, 0),
  },

  cardContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 10,
  },

  penalty: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
    "& .penalty": {
      fontWeight: "bold",
      fontSize: 20,
      marginLeft: 10,
    },
  },
  taskBold: {
    fontSize: 14,
    color: "#D63A3B",
    fontWeight: "900",
  },
  description: {
    fontSize: 14,
    color: "gray",
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    borderRadius: 5,
    height: 8,
    margin: "10px 0",
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[300],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#D63A3B",
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "column",
  },
}))(LinearProgress);

const PenaltyCard = ({ points }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Box className={classes.cardContainer}>
        <div className={classes.penalty}>
          <ErrorIcon htmlColor="#D63A3B" size="large" />
          <Typography className="penalty">Penalty Points</Typography>
        </div>
      </Box>
      <Box className={classes.cardContainer}>
        <Typography>
          <span className={classes.taskBold}>{points} out of 10</span>
        </Typography>
        <Box>
          <BorderLinearProgress variant="determinate" value={(points / 10) * 100} />
        </Box>
        <Typography style={{ fontSize: "16px", color: "#333" }}>
          Points reset to 0 on <b>{dayjs().startOf("month").add(1, "month").format("MMM DD")}</b>
        </Typography>
        <Divider style={{ margin: "20px 0px" }} />
      </Box>
      <div style={{ fontSize: "12px", color: "gray" }}>
        For detailed penalty rules, please refer to <span style={{ textDecoration: "underline" }}>Penalties</span>
      </div>
    </Paper>
  );
};

export default PenaltyCard;
