import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Box, Button, makeStyles } from "@material-ui/core";
import Section from "@booking/components/Section";
import { searchCoupon } from "@store/actions/coupon";
import couponTypes from "@store/constants/couponTypes";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
  textField: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
  },
  button: {
    width: "25%",
  },
  removeButton: {
    background: "#d71e1e",
    "&:hover": {
      background: "#b93329",
    },
  },
}));

const CouponField = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.firebase.profile.uid);
  const selectedCoupon = useSelector((state) => state.coupon.selectedCoupon);
  const [couponCode, setCouponCode] = useState("");
  const title = "Coupon Code";

  const handleSearch = async (e) => {
    e.preventDefault();
    if (selectedCoupon) {
      setCouponCode("");
      dispatch({
        type: couponTypes.SET_SELECTED_COUPON,
        payload: null,
      });
    } else {
      await dispatch(searchCoupon(couponCode, userId));
    }
  };

  const onChange = (e) => {
    e.preventDefault();
    setCouponCode(e.target.value);
    if (selectedCoupon) {
      dispatch({
        type: couponTypes.SET_SELECTED_COUPON,
        payload: null,
      });
    }
  };

  return (
    <Section title={title}>
      <div className={styles.container}>
        <TextField
          className={styles.textField}
          variant="outlined"
          placeholder="Enter a promo code"
          label="Enter a promo code"
          value={couponCode}
          onChange={onChange}
        />
        <Button
          className={`${styles.button} ${selectedCoupon ? styles.removeButton : ""}`}
          variant="contained"
          color="primary"
          onClick={handleSearch}
          disabled={!couponCode}
        >
          {selectedCoupon ? "Remove" : "Enter"}
        </Button>
      </div>
    </Section>
  );
};

export default CouponField;
