/* eslint-disable no-console */
import tzlookup from "tz-lookup";
import { useAxiosPost } from "../axios";
export const defaultTimeZone = "America/Los_Angeles";

export const getTimeZone = (coordinates) => {
  if (!coordinates?.lat || !coordinates?.lng) return defaultTimeZone;
  return tzlookup(coordinates.lat, coordinates.lng);
};

export const getTimeZoneText = (coordinates) => `Time zone is 
${getTimeZone(coordinates)} based on the address/zip code you just entered
  `;

export const getMatchingServiceLocation = async (address) => {
  const formattedAddressParam = address.replace(/_/g, " ")?.toLowerCase();
  const response = await useAxiosPost("/locations/get-matching-service-location", { address: formattedAddressParam });
  return response?.data ?? null;
};

export const getServiceLocationWithId = (locationId, locations = []) => locations.find((item) => item.id === locationId);
