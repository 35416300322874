import dayjs from "dayjs";
import { store } from "@index";

export class abTest {
  constructor() {
    this.tests = store?.getState()?.abTest?.tests;
  }

  getSavedTest(testName) {
    return sessionStorage.getItem(`ab-priority-test`) || localStorage.getItem(`ab-${testName?.split(" ").join("-")}`) || undefined;
  }

  getABTest(abTestName) {
    return this.tests.find((i) => i.name === abTestName);
  }

  sameDayBooking(date, testName) {
    return date && dayjs().isSame(date, "day") && this.getSavedTest(testName) === "b";
  }

  testActivated(abTest, market) {
    if (!abTest) {
      return false;
    }
    return !!abTest?.instances.find((instance) => instance?.location?.trim() === market?.pricingZone?.trim() && instance?.status === "live");
  }

  matchingVariant(testId, variant) {
    return this.getSavedTest(testId) === variant;
  }
}
