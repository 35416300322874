import React, { useEffect, useState } from "react";
import Modal from "@shared/components/Modal";
import Button from "@shared/components/Button";
import ProviderBonusSuccess from "@assets/approved.png";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { fetchMyBonusPayouts } from "../../../store/actions/bonuses";
import uiConstants from "../../../store/constants/uiTypes";
import dayjs from "dayjs";

const BonusSuccessContent = ({ handleCollect }) => (
  <div>
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
      <img src={ProviderBonusSuccess} alt="ProviderBonusSuccess" style={{ paddingTop: "40px" }} />
      <span style={{ fontSize: "30px", color: "#4F4F4F", fontWeight: "800", padding: "20px" }}>
        High 5! <br />
        You earned a $30 bonus!
      </span>
      <span style={{ fontSize: "16px", color: "#4F4F4F", padding: "0 40px" }}>
        As you completed 4 tasks this week, we give a $30 bonus that you can see in your account.
      </span>
      <Button variant="contained" color="primary" onClick={() => handleCollect()} style={{ margin: "20px" }}>
        Collect it
      </Button>
    </div>
  </div>
);

const BonusSuccessModal = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const uid = useSelector((state) => state.firebase.profile?.uid);
  const hasCompletedTask = useSelector((state) => state.ui.taskCompleted);
  const bonuses = useSelector((state) => state.auth.bonuses);
  const [open, setOpen] = useState(false);

  const handleCollect = () => {
    history.push("/account");
    setOpen(false);
  };

  const checkForNewBonus = async () => {
    if (!uid) return;
    dispatch(fetchMyBonusPayouts(uid));
  };

  useEffect(() => {
    const hasNewBonus = bonuses.find((bonus) => dayjs(dayjs()).diff(dayjs(bonus.createdAt), "minute") < 1);
    if (hasNewBonus) {
      setOpen(true);
    }
  }, [bonuses?.length]);

  // Here is a workaround until we have a proper notification system in place
  let timeout;
  useEffect(() => {
    if (hasCompletedTask) {
      window.clearTimeout(timeout);
      timeout = window.setTimeout(() => {
        checkForNewBonus();
        dispatch({
          type: uiConstants.SET_TASK_COMPLETED,
          payload: false,
        });
      }, 10000);
    }
  }, [hasCompletedTask]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return <Modal header={<BonusSuccessContent handleCollect={handleCollect} />} open={open} setOpen={() => setOpen(!open)} />;
};

export default BonusSuccessModal;
