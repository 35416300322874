/* eslint-disable */
import React from "react";
import { Grid, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

import Review from "./Review";
import { StarBorderOutlined } from "@material-ui/icons";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "10px 0px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  viewAll: {
    color: "#1E3B47",
    textDecoration: "underline",
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: "16px",
    margin: "30px 0px",
    textAlign: "center",
  },
}));

const Sample = ({ reviews }) => {
  const classes = useStyles();
  const history = useHistory();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <div className={classes.root}>
      <Grid container style={{ alignItems: "center", marginBottom: "15px" }}>
        <Grid item xs={2} sm={1}>
          <StarBorderOutlined fontSize="large" />
        </Grid>
        <Grid item xs={10} sm={11}>
          {reviews.length === 0 ? (
            <Typography style={{ fontSize: "20px" }} color="primary">
              No reviews yet!
            </Typography>
          ) : (
            <Typography style={{ fontSize: "20px" }}>Reviews</Typography>
          )}
        </Grid>
        {/* <Grid item xs={5} md={6} style={{ padding: "30px 0px" }}>
          <div
            onClick={() => reviews.length > 0 && history.push("/account/reviews")}
            style={{
              ...(reviews.length > 0
                ? {
                    color: "#74b78b",
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }
                : {
                    color: "rgba(0, 0, 0, 0.2)",
                  }),
              fontSize: "18px",
              textAlign: "right",
            }}>
            {reviews.length > 0 ? reviews.length : "No"} Reviews
          </div>
        </Grid> */}
      </Grid>
      {!!reviews.length && reviews.map((review, idx) => <Review key={idx} {...review} />)}
      {!!reviews.length && (
        <div className={classes.viewAll} onClick={() => history.push("/account/reviews")}>
          View All
        </div>
      )}
    </div>
  );
};

export default Sample;
