import React, { useEffect } from "react";
import HoverMenu from "material-ui-popup-state/HoverMenu";
import MenuItem from "@material-ui/core/MenuItem";
import { Button, useMediaQuery } from "@material-ui/core";
import classes from "@css/components/website/Header.module.css";
import history from "../../history";
import { ArrowDropDownOutlined } from "@material-ui/icons";
import { usePopupState, bindHover, bindMenu } from "material-ui-popup-state/hooks";
import { KustomerLauncher } from "../KustomerLauncher";

function HeaderMenuItem({ name, menuItems, isOpaque, icon }: { name: string; menuItems: Array<any>; isOpaque: boolean; icon?: string }) {
  const isMobile = useMediaQuery("(max-width:1369px)");

  const popupState = usePopupState({
    variant: "popover",
    popupId: "headerMenu",
  });

  useEffect(() => {
    if (isMobile) {
      popupState.close();
    }
  }, [isMobile]);

  const handleItemClick = (item: any) => {
    item.func ? item.func() : item.link ? (item.isOutsideLink ? window.open(item.link) : history.push(item.link)) : null;
    popupState.close();
  };

  const Icon = ({ isOpen }: { isOpen?: boolean }) => {
    const openStyles = isOpen ? { transform: "rotate(-180deg)" } : {};
    return <ArrowDropDownOutlined style={{ ...openStyles }} htmlColor={isOpaque ? "black" : "white"} />;
  };

  return (
    <>
      <Button
        disableRipple
        className={icon ? classes.menuIconButton : classes.menuButton}
        style={{ color: isOpaque ? "black" : "white" }}
        {...bindHover(popupState)}
      >
        {icon && <img src={icon} />}
        {name}
        <Icon isOpen={popupState.isOpen} />
      </Button>
      <HoverMenu
        {...bindMenu(popupState)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        classes={{ paper: classes.menuDropdown }}
      >
        {menuItems?.map((item) => (
          <MenuItem className={classes.menuItem} key={item.label} onClick={() => handleItemClick(item)}>
            {item.icon && item.icon}
            <span style={{ marginLeft: item.icon ? "16px" : "0" }}>
              {!item.link ? <KustomerLauncher>{item.label}</KustomerLauncher> : item.label}{" "}
            </span>
          </MenuItem>
        ))}
      </HoverMenu>
    </>
  );
}

export default HeaderMenuItem;
