import React from "react";
import { Box, Divider, Grid, useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";
import ChatLink from "../../containers/ChatLink";
import Payout from "./components/Payout";
import ProfileDetails from "./components/ProfileDetails";
import Reviews from "./components/Reviews";

const Provider = () => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const reviews = useSelector((state) => state.review.reviews);
  return (
    <>
      <ProfileDetails isProvider totalReviews={reviews.length}>
        <Divider style={{ margin: "40px 0px", width: "100%" }} />
        <Payout />
        <Divider style={{ margin: "40px 0px", width: "100%" }} />
        <Reviews.Sample reviews={reviews.slice(0, 2)} />
        <Divider style={{ margin: "40px 0px", width: "100%" }} />
        <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "center" }}>
          <ChatLink />
        </Grid>
      </ProfileDetails>
    </>
  );
};

export default Provider;
