import React from "react";
import classnames from "classnames";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";

import NumberService from "@shared/services/NumberService";
import { Vehicle } from "@booking/helpers/enums";
import { vehicles } from "@booking/helpers/apiData";
import { vehicleDescription, vehicleName } from "@booking/helpers/wordings";
import { useSelector } from "react-redux";

import pickupUrl from "@assets/pickup.jpg";
import truckUrl from "@assets/truck.jpg";

const useStyles = makeStyles((theme) => ({
  button: {
    minHeight: theme.spacing(9),
    paddingTop: theme.spacing(1),
    justifyContent: "left",
    "&.inactive": {
      opacity: 0.5,
    },
  },
  image: {
    marginRight: theme.spacing(2),
  },
}));

const svgUrl = {
  [Vehicle.PickupTruck]: pickupUrl,
  [Vehicle.Truck]: truckUrl,
};

const Option = ({ className, vehicle, selected, onSelect }) => {
  const styles = useStyles();
  const buttonClassNames = classnames(styles.button, !selected ? "inactive" : null);
  const priceMultiplier = useSelector((state) => state.serviceLocations.activeLocation.priceMultipliers ?? 100);

  return (
    <Box className={className}>
      <Button fullWidth variant="outlined" color={selected ? "primary" : "default"} className={buttonClassNames} onClick={onSelect}>
        <img className={styles.image} alt={vehicle} src={svgUrl[vehicle]} />
        <Box>
          <Typography align="left">
            <strong>{vehicleName[vehicle]}</strong>
          </Typography>
          <Typography align="left">{vehicleDescription[vehicle]}</Typography>
        </Box>
        <Box flex={1}>
          <Typography align="right">
            <strong>{NumberService.formatToCurrency(vehicles[vehicle].price * (priceMultiplier / 100))}</strong>
            <br />
          </Typography>
        </Box>
      </Button>
    </Box>
  );
};

export default Option;
