import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import React, { useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

type HeaderAccordionProps = {
  classes: any;
  quoteNumber: string;
  total: string;
  isConfirmedQuote: boolean;
  handleConfirmItem: (quoteNumber: string) => void;
  children: any;
};

const HeaderAccordion = ({ classes, quoteNumber, total, isConfirmedQuote, handleConfirmItem, children }: HeaderAccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const itemColor = isConfirmedQuote ? "primary" : "inherit";

  const handleAccordionChange = (e: object, expanded: boolean) => {
    setIsOpen(expanded);
  };

  const accordionClasses = {
    root: classes.accordionRoot,
  };

  const accordionSummaryClasses = {
    root: classes.accordionSummaryRoot,
    content: classes.accordionSummaryContent,
    expandIcon: classes.accordionIcon,
  };

  const handleSelectItem = (event: any) => {
    event.stopPropagation();
    handleConfirmItem(quoteNumber);
  };

  const expandIcon = (
    <>
      {!isOpen && (
        <Typography variant="h6" color={itemColor}>
          <strong>{total}</strong>
        </Typography>
      )}
      <ExpandMoreIcon style={{ width: "30px", height: "30px" }} color={itemColor} />
    </>
  );

  return (
    <Accordion
      id={quoteNumber}
      onChange={handleAccordionChange}
      expanded={isOpen}
      TransitionProps={{ unmountOnExit: true }}
      classes={accordionClasses}>
      <AccordionSummary classes={accordionSummaryClasses} expandIcon={expandIcon}>
        <FormControlLabel
          aria-label="Acknowledge"
          onClick={handleSelectItem}
          control={<Checkbox checked={isConfirmedQuote} color="primary" style={{ padding: "6px 9px 9px 9px" }} />}
          label={
            <Typography variant="h6" color={itemColor}>
              {quoteNumber}
            </Typography>
          }
        />
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default HeaderAccordion;
