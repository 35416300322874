/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Box, makeStyles, Paper, Typography, LinearProgress, withStyles, Divider } from "@material-ui/core";
import { StarBorderOutlined } from "@material-ui/icons";
import { fetchWarriorStreak } from "../../../store/actions/users";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { fetchAvailableBonuses } from "../../../store/actions/bonuses";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    margin: theme.spacing(4, 0),
  },

  cardContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 10,
  },

  rating: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
    "& .reviews": {
      fontWeight: "bold",
      fontSize: 20,
      marginLeft: 10,
    },
  },
  taskBold: {
    fontSize: 14,
    color: "#1E3B47",
    fontWeight: "900",
  },
  description: {
    display: "flex",
    justifyContent: "center",
    fontSize: 14,
    color: "gray",
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    borderRadius: 100,
    height: 32,
    margin: "10px 0",
  },
  colorPrimary: {
    backgroundColor: "#ddd",
  },
  bar: {
    borderRadius: 100,
    backgroundColor: "#1E3B47",
  },
}))(LinearProgress);

export const current5Stars = (stars) => {
  if (stars === 0) return stars;
  const nextPoint = 10;
  return stars % nextPoint || nextPoint;
};

const Rewards = ({ ratings, profile }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [bonuses, setBonuses] = useState([]);
  // const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const subscribed = useRef(false);
  const getBonuses = async () => {
    const streak = await fetchWarriorStreak(profile?.uid);
    if (!subscribed.current) return;
    if (!streak) {
      //
    }
    const last = streak?.lastTask ?? null;
    const week = dayjs().startOf("week").format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    const month = dayjs().startOf("month").format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    const fixedStreak = {
      ...streak,
      weekTotalTasks: !last || (last && last < week) ? 0 : streak.weekTotalTasks || 0,
      monthTotalTasks: !last || (last && last < month) ? 0 : streak.monthTotalTasks || 0,
    };
    const bonusObjects = [];
    const availableBonuses = await fetchAvailableBonuses("provider", profile?.smsSettings?.address?.city);
    if (!subscribed.current) return;
    const useBonuses = availableBonuses ?? [];

    useBonuses?.map((bonus) => {
      let progress = 0;
      if (bonus.lengthType === "week") {
        progress = fixedStreak?.weekTotalTasks ?? 0;
      }
      if (bonus.lengthType === "month") {
        progress = fixedStreak?.monthTotalTasks ?? 0;
      }
      if (progress > bonus.count) {
        progress = bonus.count;
      }
      let description = bonus.description;
      description = description?.replace("{count}", bonus.count);
      description = description?.replace("{lengthType}", bonus.lengthType);
      description = description?.replace("{defaultPayout}", `$${bonus.defaultPayout / 100}`);
      const item = {
        ...bonus,
        description,
        progress,
      };
      bonusObjects.push(item);
    });
    setBonuses(bonusObjects);
    setLoading(false);
  };

  useEffect(() => {
    subscribed.current = true;
    getBonuses();
    return () => {
      subscribed.current = false;
    };
  }, []);

  return (
    !loading && (
      <Paper className={classes.root}>
        <Box className={classes.cardContainer}>
          <div className={classes.rating}>
            <StarBorderOutlined size="large" htmlColor="#fdd835" />
            <Typography className="reviews">Your Rewards</Typography>
          </div>
        </Box>
        <Box className={classes.cardContainer}>
          <Typography className={current5Stars(ratings) === "10" ? classes.taskBold : undefined}>
            <span className={classes.taskBold}>5 Star Bonus - {current5Stars(ratings)} / 10 five star ratings </span>
          </Typography>
          <Box style={{ position: "relative" }}>
            <BorderLinearProgress variant="determinate" value={(current5Stars(ratings) / 10) * 100} color="primary" />
            {current5Stars(ratings) === 10 ? (
              <Box
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "50%",
                  transform: `translate(${50}%, ${-50}%)`,
                }}
              >
                <Typography style={{ fontSize: "10px", color: "#fff", fontWeight: "800" }}>Completed!</Typography>
              </Box>
            ) : (
              <Box
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "50%",
                  transform: `translate(${50}%, ${-50}%)`,
                }}
              >
                <Typography style={{ fontSize: "14px", color: "#fff", fontWeight: "800" }}>{10 - current5Stars(ratings)} ratings to go</Typography>
              </Box>
            )}
          </Box>
          <Typography style={{ fontSize: "16px", color: "#333" }}>Receive 10 5 star ratings to receive a $10 bonus!</Typography>
          {/* <Typography style={{ fontSize: "12px", color: "lightGrey" }}>Points will reset weekly in [5 days]</Typography> */}

          <Divider style={{ margin: "20px 0px" }} />
        </Box>
        {bonuses?.map((bonus, index) => (
          <Box className={classes.cardContainer} key={index}>
            <Typography className={profile.tasksCount >= "4" && classes.taskBold}>
              <span className={classes.taskBold}>
                {bonus.name} - {bonus.progress} / {bonus.count} tasks complete
              </span>
              {/* <span style={{ fontSize: "14px" }}>
              {bonus.progress >= bonus.count ? "-" : " to get a"}
              <strong> ${`${bonus.defaultPayout / 100}`} bonus!</strong>
            </span> */}
            </Typography>
            <Box style={{ position: "relative" }}>
              <BorderLinearProgress variant="determinate" value={(bonus.progress / bonus.count) * 100} color="primary" />
              <Box
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "50%",
                  transform: `translate(${50}%, ${-50}%)`,
                }}
              >
                <Typography style={{ fontSize: "14px", color: "#fff", fontWeight: "800" }}>
                  {bonus.progress === bonus.count ? "Completed!" : `${bonus.count - bonus.progress} more tasks to go`}
                </Typography>
              </Box>
            </Box>
            <Typography style={{ fontSize: "16px", color: "#333" }}>{bonus.description}</Typography>
            {bonus.resets && <Typography style={{ fontSize: "12px", color: "grey" }}>Points will reset {bonus.lengthType}ly</Typography>}
            <Divider style={{ margin: "20px 0px" }} />
          </Box>
        ))}
        <Typography style={{ fontSize: "12px", color: "grey" }}>
          Once a bonus is complete, a payout will automatically be sent to your account via Stripe. Make sure your account is properly set up with
          Stripe Connect!
        </Typography>
        <Typography className={classes.description}></Typography>
      </Paper>
    )
  );
};

Rewards.defaultProps = {
  ratings: 0,
};

export default Rewards;
