import React from "react";
import Pagination from "@material-ui/lab/Pagination";

const Paginations = ({ pageNumber, totalCount, pageSize, setPageNumber, size, ...rest }) => {
  if (!totalCount) {
    return null;
  }
  const totalPages = Math.ceil(totalCount / pageSize);

  const handleChangePage = (_, newPage) => {
    setPageNumber(newPage);
  };

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      siblingCount={2}
      count={totalPages}
      page={pageNumber}
      onChange={handleChangePage}
      size={size}
      {...rest}
    />
  );
};

Paginations.defaultProps = {
  pageNumber: 1,
  size: "medium",
  setPageNumber: () => {},
};

export default Paginations;
