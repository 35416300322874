import React, { forwardRef } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import history from "@history";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    "& p": {
      fontSize: 25,
      fontWeight: 800,
      margin: theme.spacing(0, 0, 2, 2),
      flexGrow: 1,
    },
  },
  backBtn: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    "&:hover": {
      color: theme.palette.primary.hover,
    },
  },

  body: {
    marginTop: theme.spacing(4),
  },
}));

const Form = ({ children, title, ...restProps }, ref) => {
  const classes = useStyles();

  const Header = (
    <Box className={classes.header}>
      <KeyboardBackspaceIcon className={classes.backBtn} fontSize="large" onClick={() => history.goBack()} />
      <Typography>{title}</Typography>
    </Box>
  );

  const Body = (
    <form ref={ref} {...restProps}>
      {children}
    </form>
  );

  return (
    <Box>
      {title && Header}
      {Body}
    </Box>
  );
};

export default forwardRef(Form);
