import React, { useRef, useEffect } from "react";
import { withStyles, Box } from "@material-ui/core";
import useForceUpdate from "@shared/hooks/useForceUpdate";

const Background = ({ children, footer, ...stylesProps }) => {
  if (Array.isArray(children)) throw new Error("Background must have single children");

  const ref = useRef(null);
  const forceUpdate = useForceUpdate();

  const el = ref.current?.children[1];

  useEffect(() => {
    forceUpdate(); // force update to render the styles
  }, [ref.current]);

  const Overlay = withStyles({
    root: {
      height: el?.offsetHeight,
      width: "100%",
      position: "absolute",
      left: 0,
      right: 0,
      zIndex: -1,
      ...stylesProps,
    },
  })(Box);

  return (
    <div ref={ref}>
      <Overlay />
      {children}
    </div>
  );
};

export default Background;
