import React from "react";
import classnames from "classnames";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  Button,
  makeStyles,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  inactive: {
    opacity: 0,
  },
  active: {
    opacity: 1,
    transition: "0.2s all",
  },
  box: {
    paddingTop: theme.spacing(2),
  },
}));

const StyledAccordion = withStyles((theme) => ({
  root: {
    margin: `${theme.spacing(3, 0, 0)} !important`,
    "&:before": {
      display: "none",
    },
  },
}))(Accordion);

const StyledAccordionSummary = withStyles((theme) => ({
  root: {
    minHeight: theme.spacing(7),
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  expanded: {
    minHeight: "auto !important",
    margin: "0 !important",
    display: "none",
  },
  content: {
    margin: "0 !important",
    border: "1px soldi red",
  },
  expandIcon: {
    padding: "0 !important",
  },
}))(AccordionSummary);

const StyledAccordionDetails = withStyles((theme) => ({
  root: {
    display: "block",
    background: "white",
    padding: theme.spacing(6),
  },
}))(AccordionDetails);

const Title = withStyles((theme) => ({
  root: {
    width: theme.spacing(25),
    fontWeight: 500,
    fontSize: 15,
    margin: theme.spacing("auto", 0),
    animation: "$fadein 0.25s ease-in",
  },
  "@keyframes fadein": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 100,
    },
  },
}))(Typography);

const Description = withStyles((theme) => ({
  root: {
    color: theme.palette.grey[600],
    fontWeight: 500,
    fontSize: 15,
  },
}))(Typography);

const ExpansionPanel = ({ title, description, expanded, children, onEdit, active, disabled }) => {
  const theme = useTheme();
  const styles = useStyles();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const editButton = (
    <Button variant="text" color="primary">
      Edit
    </Button>
  );

  if (matches) {
    return (
      <StyledAccordion expanded={expanded} defaultExpanded={false} disabled={disabled}>
        {title && (
          <StyledAccordionSummary
            expandIcon={expanded || !active ? null : editButton}
            className={classnames(!active ? styles.inactive : styles.active)}
            onClick={onEdit}>
            <Title>{title}</Title> {active && <Description>{description}</Description>}
          </StyledAccordionSummary>
        )}

        <StyledAccordionDetails>{children}</StyledAccordionDetails>
      </StyledAccordion>
    );
  }

  if (expanded) {
    return <Box className={styles.box}>{children}</Box>;
  }

  return null;
};

ExpansionPanel.defaultProps = {
  active: false,
  title: null,
  disabled: false,
};

export default ExpansionPanel;
