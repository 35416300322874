import dayjs from "dayjs";
import { taskTypes, bookingStatus } from "@constants/booking";
import { haversineDistance } from "@shared/utils/haversine";
import { bookingQuoteStatus, vehicleTypes } from "../constants/booking";

export const extractNumber = (number) => (number ? number.replace(/[- )(]/g, "") : "");

export const formatBookingId = (x) => x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "); // for better readability eg: 100 023

export const formatPhoneNumber = (phoneNumber) => "+1" + phoneNumber?.toString().slice(-10) ?? "";

export const steadyAdminBookingDefaults = (booking) => ({
  task: booking.task || taskTypes["junk-removal"].value,
  description: booking.description || "",

  frequency: booking.frequency || "once",
  date: dayjs(booking.date).tz(booking.timeZone) || dayjs().hour(0).minute(0),
  time: booking.time || { key: "0-0", hour: 0, minute: 0 },
  pickup: booking.pickup || "next-booking",
  "pickup-date": dayjs(booking["pickup-date"]).tz(booking.timeZone) || dayjs().hour(0).minute(0),
  "pickup-time": booking["pickup-time"] || { key: "0-0", hour: 0, minute: 0 },

  // Addres
  address: booking.address ?? {},
  address_aditional: booking.address_aditional || "",
  phone_number: formatPhoneNumber(booking.phone_number) ?? "",
  onsite: booking.onsite ?? {
    instructions: "",
    images: [],
    hours: "",
    name: "",
    phone_number: "",
    email: "",
  },
  // Booking Elements
  vehicle: booking.vehicle || vehicleTypes["pick-up-truck"].value,
  products: booking.products ?? [],
  services: booking.services ?? [],
  stairs: booking.stairs ?? 0,
  dismantling: booking.dismantling ?? 0,
  coupon: booking.coupon?.couponCode || "",
  waterSource: booking.waterSource || false,
  parkingAccess: booking.parkingAccess || false,
  powerwashingAreas: booking.powerwashingAreas || [],

  // Customer
  first_name: booking.first_name || "",
  last_name: booking.last_name || "",
  email: booking.email || "",
  customer_id: booking.customer_id || "",
  guest_customer: !booking.customer_id || false,
  businessClient: booking.businessClient || !!booking.companyName || false,
  companyName: booking.companyName || "",
  closedBy: booking.businessClientDetails?.closedBy || "",
  businessClientDetails:
    {
      ...booking.businessClientDetails,
      ...(!booking.businessClientDetails?.name && !!booking.companyName
        ? {
            name: booking.companyName,
          }
        : {}),
    } || {},

  // Stripe
  stripe_customer_id: booking.stripeCustomerId || "",

  // Warrior
  warrior_id: booking.warrior_id || "",

  // Gclid
  gclId: booking.gclId || "",
  gclId_intercom: booking.gclId_intercom || "",

  // Images
  taskImages: booking.taskImages || [],

  // Details
  status: booking.status || "",
  notes: booking.notes || "",

  // Pricing
  total: booking.total ? booking.total / 100 : 0,
  payout: booking.payout ? booking.payout / 100 : 0,
  payout_dynamic: booking.payout_dynamic ? booking.payout_dynamic / 100 : 0,
});

export const constructAdminBookingDefaults = (booking) => ({
  ...steadyAdminBookingDefaults(booking),

  // Payment Section
  stripePaymentMethodId: booking.stripePaymentMethodId || "",
  payment_method: booking.payment_method || "ACH",
  payment_method_id: booking.payment_method_id || "ACH",
  includeInvoice: booking.includeInvoice || true,
  // invoice: booking.invoice || "",
  invoices: booking.invoices || [],
  invoiceMemo: booking.invoiceMemo || "",
  paid: booking.paid || false,

  afterImages: booking.afterImages || [],
  beforeImages: booking.beforeImages || [],
  dumpingImages: booking.dumpingImages || [],

  warriorBidId: booking.warriorBidId || "",
  bookingBidId: booking.bookingBidId || "",
  bookingQuoteId: booking.bookingQuoteId || "",
});

export const constructAdminBookingQuoteDefaults = (booking, isNew) => ({
  ...steadyAdminBookingDefaults(booking),
  warriorBidId: booking.warriorBidId || "",
  bookingBidId: booking.bookingBidId || "",
  bookingId: booking.bookingId || "",

  isVehicleFeeWaived: !isNew ? booking.isVehicleFeeWaived : false,
  isServiceFeeWaived: !isNew ? booking.isServiceFeeWaived : false,
  isLockedPrice: !isNew ? booking.isLockedPrice : true,
  isLockedDate: !isNew ? booking.isLockedDate : true,
  isLockedWarrior: true,

  status: !isNew ? booking.status : bookingQuoteStatus.available.value,
});

export const constructAdminBookingBidDefaults = (booking, isNew) => ({
  ...steadyAdminBookingDefaults(booking),

  dateFlexible: booking.dateFlexible ?? false,

  warriorBidId: booking.warriorBidId ?? "",
  warriorBidPrice: booking.warriorBidPrice ?? 0,
  bookingQuoteId: booking.bookingQuoteId ?? "",
  bookingId: booking.bookingId ?? "",

  bidLengthValue: booking.bidLengthValue ?? 1,
  bidLengthType: booking.bidLengthType ?? "day",

  isVehicleFeeWaived: !isNew ? booking.isVehicleFeeWaived : true,
  isServiceFeeWaived: !isNew ? booking.isServiceFeeWaived : true,
  isLockedPrice: !isNew ? booking.isLockedPrice : true,
  isLockedDate: !isNew ? booking.isLockedDate : true,
  isLockedWarrior: true,
  status: booking.status ?? "open",
  isAutoSelectOn: !isNew ? booking.isAutoSelectOn || false : false,
});

export const applyFiltersMyOrders = (bookings, filters) => {
  let filteredBookings = bookings ?? [];
  const { activeStatus, searchQuery, filterTaskType, filterDateValue, filterLocation } = filters;
  const activeOptions = [bookingStatus.open.value, bookingStatus.booked.value, bookingStatus.current.value];
  const reviewOptions = [bookingStatus.review.value];
  const doneOptions = [bookingStatus.done.value];
  const doneOptionsWarrior = [bookingStatus.done.value, bookingStatus.review.value];
  const submissionErrorOptions = [bookingStatus.rejected.value, bookingStatus.needs_approval.value];
  const cancelledOptions = [bookingStatus.cancelled.value, bookingStatus.incomplete.value];

  switch (activeStatus) {
    case "active":
      filteredBookings = filteredBookings.filter((booking) => activeOptions.includes(booking.status));
      break;
    case "submissionError":
      filteredBookings = filteredBookings.filter((booking) => submissionErrorOptions.includes(booking.status));
      break;
    case bookingStatus.review.value:
      filteredBookings = filteredBookings.filter((booking) => reviewOptions.includes(booking.status));
      break;
    case bookingStatus.done.value:
      filteredBookings = filteredBookings.filter((booking) => doneOptions.includes(booking.status));
      break;
    case "warrior-done":
      filteredBookings = filteredBookings.filter((booking) => doneOptionsWarrior.includes(booking.status));
      break;
    case bookingStatus.cancelled.value:
      filteredBookings = filteredBookings.filter((booking) => cancelledOptions.includes(booking.status));
      break;
    default:
      null;
      break;
  }

  if (searchQuery?.trim()) {
    filteredBookings = filteredBookings.filter(
      (booking) =>
        booking.orderNumber?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        booking.description?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        booking.address?.location?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        booking.address_aditional?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        booking.address?.zipCode?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  switch (filterTaskType) {
    case taskTypes["junk-removal"].value:
      filteredBookings = filteredBookings.filter((booking) => booking.task === taskTypes["junk-removal"].value);
      break;
    case taskTypes["cardboard-removal"].value:
      filteredBookings = filteredBookings.filter((booking) => booking.task === taskTypes["cardboard-removal"].value);
      break;
    case taskTypes["dumpster-rental"].value:
      filteredBookings = filteredBookings.filter((booking) => booking.task === taskTypes["dumpster-rental"].value);
      break;
    default:
      null;
  }

  if (filterDateValue) {
    filteredBookings = filteredBookings.filter(
      (booking) =>
        dayjs(booking.date).tz(booking.timeZone).isAfter(dayjs(filterDateValue).startOf("day")) &&
        dayjs(booking.date).tz(booking.timeZone).isBefore(dayjs(filterDateValue).endOf("day"))
    );
  }

  if (filterLocation) {
    filteredBookings = filteredBookings.filter((booking) => booking.address?.zipCode === filterLocation);
  }

  return filteredBookings;
};

export const isBookingOverlap = (booking, listOfBookings = []) => {
  let retVal = false;
  for (const item of listOfBookings) {
    if (item?.status === "current" && dayjs(item?.date).diff(dayjs(booking?.date), "day") === 0 && item?.time.hour === booking?.time.hour) {
      retVal = true;
      break;
    }
  }
  return retVal;
};

export const createSlotBookingTimeMap = (slotBookings = [], currentLocation, currentCoordinates, timeRange) => {
  // default values are set to 100 bookings in 10 mile radius per hour.
  const { maxBooking = 100, slotRadius = 10 } = currentLocation;

  const hourlyBookings = slotBookings.reduce((acc, booking) => {
    const hour = booking.time.hour;
    const isBookingInSlotRadius = haversineDistance(currentCoordinates, booking.coordinates) <= slotRadius;
    if (isBookingInSlotRadius) {
      if (acc[hour]) {
        acc[hour] = acc[hour] + 1;
      } else {
        acc[hour] = 1;
      }
    }
    return acc;
  }, {});

  return timeRange.map((time) => ({
    ...time,
    isFull: hourlyBookings[time.hour] >= maxBooking,
  }));
};

export const refreshBooking = (booking) => {
  const repeatedBooking = {
    ...booking,
    status: booking?.paid ? "booked" : "open",
    invoices: [],
    taskImages: [],
    paid: false,
    sameDayBooking: false,
    waiting_list: [],
    payment_method: "ACH",
  };
  delete repeatedBooking.id;
  delete repeatedBooking.stripePaymentId;
  delete repeatedBooking.stripeChargeId;
  delete repeatedBooking.completeTaskSubmissionDate;
  delete repeatedBooking.completion_date;
  delete repeatedBooking["pickup-date"];
  delete repeatedBooking["pickup-time"];
  delete repeatedBooking.dump_site;
  delete repeatedBooking.gclId;
  delete repeatedBooking.gclId_intercom;
  delete repeatedBooking.dumpSiteId;
  delete repeatedBooking.dump_address;
  delete repeatedBooking.dump_cost;
  delete repeatedBooking.dumpingImages;
  delete repeatedBooking.beforeImages;
  delete repeatedBooking.afterImages;
  delete repeatedBooking.warriorPublicLink;
  delete repeatedBooking.warriorPayoutHistory;
  delete repeatedBooking.customerPublicLink;
  delete repeatedBooking.last_notified_warrior;
  delete repeatedBooking.warriorPayoutStatus;
  delete repeatedBooking.last_waitlist_sms;
  delete repeatedBooking.orderNumber;
  delete repeatedBooking.matchHistory;
  delete repeatedBooking.warriorETA;
  delete repeatedBooking.warrior_id;
  delete repeatedBooking.warriorName;
  delete repeatedBooking.adminActions;
  delete repeatedBooking.timeTracking;
  delete repeatedBooking.date;
  delete repeatedBooking.pictureReminder;
  delete repeatedBooking.receiptUrl;
  delete repeatedBooking.payout_surged;
  delete repeatedBooking.task_rated;
  delete repeatedBooking.time;
  delete repeatedBooking.autoCreated;
  delete repeatedBooking.coupon;
  delete repeatedBooking.invoice;
  delete repeatedBooking.receiptUrl;
  delete repeatedBooking.recurringId;
  delete repeatedBooking.experimentGroup;
  delete repeatedBooking.experimentId;

  return repeatedBooking;
};

export const handleAdminBidObject = ({ form, onSet, onAlert, isUpdate }) => {
  const data = {
    ...form,
    total: form.total * 100 ?? 0,
    date: dayjs(form.date).tz(form.address.timeZone),
    timesNotified: 0,
    time: {
      key: form.time?.key,
      hour: form.time?.hour,
      minute: form.time?.minute,
    },
    timeZone: form.address.timeZone,
    status: form.status,
    guest_customer: !form.customer_id,
    ...(form.businessClientDetails
      ? {
          businessClientDetails: {
            ...form.businessClientDetails,
            closedBy: form.closedBy,
          },
          businessClient: form.businessClient,
        }
      : {}),
  };
  delete data.closedBy;
  if (form.task === taskTypes["power-washing"].value) {
    data.products = form.powerwashingAreas.map((item) => {
      return {
        item: {
          description: item,
          isHelperNeeded: false,
        },
        quantity: 1,
      };
    });
  }

  onSet(data);
  onAlert(
    `This bid will be ${isUpdate ? "updated, the time will be reset and" : ""} released to the warriors in the location and will expire in ${
      form.bidLengthValue
    } ${form.bidLengthValue.length > 1 ? "days" : "day"}.`
  );
};

export const handleCreateQuoteFromBid = ({ form, onSet, onAlert }) => {
  const data = {
    ...form,
    total: form.total * 100 ?? 0,
    payout: form.payout_dynamic * 100,
    payout_dynamic: form.payout_dynamic * 100,
  };

  delete data.closedBy;

  onSet(data);
  onAlert(
    `This will create a quote but the bid will remain open until the quote is completed. To notify the customer, please do so in the Edit Quote page.`
  );
};

export const handleAdminCancelBid = ({ onAlert }) => {
  onAlert(`Are you sure you want to cancel this bid?`);
};
