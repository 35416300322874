import React from "react";
import { BooleanParam, useQueryParam } from "use-query-params";
import { useSelector } from "react-redux";

import Item from "./Items/Item";
import { calculateBookingPriceAdjustment, calculateTotalBookingPrice } from "../../helpers/utils";
import { Task } from "../../helpers/enums";

type TotalProps = {
  className: any;
  tag?: string;
  task?: any;
  vehicle?: any;
  stairs?: any;
  dumpster?: any;
  dismantling?: any;
  total?: number;
  date?: any;
  junk?: any;
  pickupDate?: any;
  isServiceFeeWaived?: boolean;
  isVehicleFeeWaived?: boolean;
  isLockedPrice?: boolean;
  overallTotal?: number;
};

function Total({
  className,
  tag = "Total",
  task,
  vehicle,
  stairs,
  dumpster,
  dismantling,
  total,
  date,
  junk,
  pickupDate,
  isServiceFeeWaived,
  isVehicleFeeWaived,
  isLockedPrice,
  overallTotal,
}: TotalProps) {
  const [repeat] = useQueryParam("repeat", BooleanParam);
  const priceMultiplier = useSelector((state: any) => state.serviceLocations.activeLocation.priceMultipliers);
  const selectedCoupon = useSelector((state: any) => state.coupon.selectedCoupon);
  const isDumpsterTask = task === Task.DumpsterRental;
  let adjustment = 0;
  let finalTotal = total;

  if (repeat || typeof overallTotal === "number") {
    return <Item className={className} tag={tag} price={total || overallTotal} isLockedPrice={isLockedPrice} />;
  }

  if (!isLockedPrice) {
    finalTotal = calculateTotalBookingPrice(
      task,
      vehicle,
      junk,
      stairs,
      dismantling,
      dumpster,
      isServiceFeeWaived,
      isVehicleFeeWaived,
      priceMultiplier,
      selectedCoupon
    );

    adjustment = calculateBookingPriceAdjustment(pickupDate, date, isDumpsterTask);
  }

  return <Item className={className} tag={tag} price={finalTotal} adjustment={adjustment} isLockedPrice={isLockedPrice} />;
}

export default Total;
