/* eslint-disable no-console */
import history from "@history";
import bookingConstants from "@store/constants/bookingTypes";
import loaderConstants from "@store/constants/loadingTypes";
import { toastr } from "react-redux-toastr";
import { isProduction } from "@constants/environments";
import { INDEXED_DB_NAME, DB_TABLES } from "@constants/indexedDB";
import { segmentTrack } from "@helpers/segment";
import useIndexedDBWithBatchFetch from "@shared/hooks/useIndexedDBWithBatchFetch";
import useBatchFetch from "@shared/hooks/useBatchFetch";
import useFirestoreListener from "@shared/hooks/useFirestoreListener";
import { useDispatch } from "react-redux";
import querystring from "query-string";

import { modifyBusinessHours } from "@store/actions/config";
import { setAddress, validateServiceLocation } from "@store/actions/address";
import { useAxiosPost, useAxiosGet } from "../../axios";
import { aeSales } from "../../constants/sales";
import uiConstants from "../constants/uiTypes";

export const setBookings = (bookings) => (dispatch) => {
  dispatch({ type: bookingConstants.SET_BOOKINGS, payload: bookings });
};

export const addBookingState = (booking) => (dispatch) => {
  dispatch({ type: bookingConstants.ADD_BOOKING, payload: booking });
};

export const updateBookingState = (booking) => (dispatch) => {
  dispatch({ type: bookingConstants.UPDATE_BOOKING, payload: booking });
  dispatch({ type: bookingConstants.SET_UPDATED_BOOKING, payload: booking });
};

export const deleteBookingState = (booking) => (dispatch) => {
  dispatch({ type: bookingConstants.DELETE_BOOKING, payload: booking });
};

// recurring

export const addRecurringBookingState = (booking) => (dispatch) => {
  dispatch({ type: bookingConstants.ADD_RECURRING_BOOKING, payload: booking });
};

export const updateRecurringBookingState = (booking) => (dispatch) => {
  dispatch({ type: bookingConstants.UPDATE_RECURRING_BOOKING, payload: booking });
};

export const deleteRecurringBookingState = (booking) => (dispatch) => {
  dispatch({ type: bookingConstants.DELETE_RECURRING_BOOKING, payload: booking });
};

const getAPIPath = (isFromBookingQuote, isMultipleQuotes) => {
  if (isMultipleQuotes) {
    return "create-bookings-from-selected-quotes";
  }
  return isFromBookingQuote ? "create-booking-from-quote" : "create-booking";
};

const getSegmentTrackingTypes = (isProduction, isFromBookingQuote) => {
  const segmentTracker1 = isProduction ? "Order Completed" : "Order Completed Dev";
  let segmentTracker2 = isFromBookingQuote ? "SalesDrivenCreated" : "SelfServeCreated";
  if (!isProduction) {
    segmentTracker2 += "Dev";
  }
  return { segmentTracker1, segmentTracker2 };
};

const setDataLayer = (booking_id, booking, isProduction) => {
  if (isProduction) {
    window.dataLayer.push({
      event: "purchase",
      transactionId: booking_id,
      transactionTotal: booking.total / 100,
      email: booking.email,
      phone: booking.phone_number ?? "",
      ecommerce: {
        purchase: {
          actionField: {
            id: booking_id,
            revenue: booking.total / 100,
          },
        },
      },
    });
  }
};

const triggerSegmentTracker = (segmentPayload, isProduction, isFromBookingQuote) => {
  const { segmentTracker1, segmentTracker2 } = getSegmentTrackingTypes(isProduction, isFromBookingQuote);
  segmentTrack(segmentTracker1, segmentPayload);
  segmentTrack(segmentTracker2, segmentPayload);
};

export const createBooking =
  (booking = {}, form = {}, isMultipleQuotes = false) =>
  async (dispatch, getState) => {
    const user = getState().firebase?.profile;
    const isFromBookingQuote = isMultipleQuotes ? true : !!booking.bookingQuoteId;

    if (!isMultipleQuotes) {
      booking.payment_method = form.payment_method;
      if (form.paymentSelectedMethod) {
        booking.paymentSelectedMethod = form.paymentSelectedMethod;
      }
    }

    try {
      dispatch({
        type: loaderConstants.SET_LOADING,
        isLoading: true,
        message: "Creating booking...",
        loaderType: "boxes",
      });
      const path = getAPIPath(isFromBookingQuote, isMultipleQuotes);
      const payload = isMultipleQuotes ? { quotes: booking, user } : { booking, user, form };
      const response = await useAxiosPost(`/booking/${path}`, payload);
      if (isMultipleQuotes) {
        const { data } = response;
        const { bookings, orderNumbers } = data;
        bookings.forEach((booking) => {
          dispatch({
            type: bookingConstants.CREATE_BOOKING,
            payload: booking,
          });

          setDataLayer(booking.booking_id, booking, isProduction);

          const segmentPayload = {
            is_business: booking.businessClient,
            order_id: booking.booking_id,
            total: Number(booking.total / 100)?.toFixed(2) ?? "",
            priceSurge: booking?.sameDayBooking ? Number(booking?.bookingSurgeAmount ?? 0 / 100) : 0,
            service_name: booking.task ?? "",
          };

          triggerSegmentTracker(segmentPayload, isProduction, isFromBookingQuote);
        });
        history.replace(
          "/confirmation?multipleOrders=1&".concat(
            querystring.stringify({
              order_number: orderNumbers,
            })
          )
        );
      } else {
        if (response?.data?.success) {
          dispatch({
            type: bookingConstants.CREATE_BOOKING,
            payload: response.data,
          });

          const { data } = response;

          setDataLayer(data.booking_id, data.booking, isProduction);

          const segmentPayload = {
            is_business: data.businessClient,
            order_id: data.booking_id,
            total: Number(data.booking.total / 100)?.toFixed(2) ?? "",
            priceSurge: data.booking?.sameDayBooking ? Number(data.booking?.bookingSurgeAmount ?? 0 / 100) : 0,
            service_name: data.booking.task ?? "",
          };

          triggerSegmentTracker(segmentPayload, isProduction, isFromBookingQuote);

          history.replace(
            "/confirmation?".concat(
              querystring.stringify({
                order_number: data.booking.orderNumber,
              })
            )
          );
          return response?.data;
        } else {
          throw response?.data?.error;
        }
      }
    } catch (error) {
      toastr.error("Error", error?.code ?? error?.message ?? error);
      dispatch({ type: bookingConstants.CREATE_BOOKING_ERROR, payload: error });
      return { success: false };
    } finally {
      dispatch({ type: loaderConstants.RESET_LOADING });
    }
  };

export const createAdminBooking = (booking) => async (dispatch, getState) => {
  const adminProfile = getState().firebase?.profile;
  try {
    const response = await useAxiosPost(`/booking/create-admin-booking`, { booking, adminProfile });
    if (response.data.success) {
      toastr.success("Done", `New booking created ${response.data.id}`);
      return true;
    } else {
      throw response?.data?.error;
    }
  } catch (error) {
    toastr.error("Error", error?.code ?? error?.message ?? error);
    console.log("Error creating admin booking: ", error);
    return false;
  }
};

export const updateBooking =
  ({ id, form }, callback) =>
  async (dispatch) => {
    try {
      dispatch({ type: bookingConstants.SET_BOOKING_LOADING });
      const response = await useAxiosPost(`/booking/update-booking/${id}`, form);
      callback?.();
      return response.data.success;
    } catch (error) {
      toastr.error("Error", "Something went wrong !");
      return false;
    } finally {
      dispatch({ type: bookingConstants.RESET_BOOKING_LOADING });
    }
  };

export const updateBookingPublicLink = async (id, userType) => {
  try {
    await useAxiosPost(`/booking/update-public-link/${id}`, userType);
  } catch (error) {
    toastr.error("Error", "Something went wrong !");
  }
};

export const updateAdminBooking =
  ({ id, form }, callback) =>
  async (dispatch, getState) => {
    const user = getState().firebase?.profile;
    try {
      dispatch({ type: bookingConstants.SET_BOOKING_LOADING });
      const response = await useAxiosPost(`/booking/update-admin-booking/${id}`, { form, user });
      callback?.();
      return response.data.success;
    } catch (error) {
      toastr.error("Error", "Something went wrong !");
      return false;
    } finally {
      dispatch({ type: bookingConstants.RESET_BOOKING_LOADING });
    }
  };

export const closedByMe = (bookingId, callback) => async (dispatch, getState) => {
  const email = getState().firebase?.profile?.email?.toLowerCase() ?? "";
  const closer = aeSales[email];

  if (closer) {
    try {
      const booking = await fetchBooking(bookingId);
      if (!booking) {
        toastr.error("No booking found");
      }
      const businessClientDetails = booking.businessClientDetails;
      const adjusted = {
        businessClientDetails: {
          ...businessClientDetails,
          closedBy: closer,
        },
      };
      await dispatch(updateAdminBooking({ id: bookingId, form: adjusted }));
      toastr.success("Booking updated");
      callback?.();
    } catch (e) {
      toastr.error(e);
    }
  } else {
    toastr.error("You don't have permissions to do this");
  }
};

export const reconcileRecurringUserAssignments = (recurrentHistory, newAssignments, onProgress, callback) => async (dispatch, getState) => {
  let total = recurrentHistory.length;
  let current = 0;
  try {
    while (current < total) {
      const bookingToUpdate = recurrentHistory[current];
      current++;

      const fullBooking = await getBookingFromOrderNumber(bookingToUpdate);
      if (!fullBooking.id) return;
      await dispatch(updateAdminBooking({ id: fullBooking.id, form: newAssignments }));
      onProgress();
    }
    callback?.();
  } catch (e) {
    toastr.error(e);
  }
};
export const fetchAvailableBookings = () => async (dispatch, getState) => {
  try {
    dispatch({ type: bookingConstants.SET_BOOKING_LOADING });
    const currentUserId = getState().firebase.auth.uid;
    const response = await useAxiosGet(`/booking/get-available-tasks?uid=${currentUserId}`);
    dispatch({
      type: bookingConstants.FETCH_AVAILABLE_TASKS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: bookingConstants.FETCH_BOOKINGS_ERROR,
      payload: error,
    });
  } finally {
    dispatch({ type: bookingConstants.RESET_BOOKING_LOADING });
  }
};

export const fetchMyBookings = (uid) => async (dispatch) => {
  try {
    const response = await useAxiosGet(`/booking/get-my-tasks?uid=${uid}`);
    dispatch({
      type: bookingConstants.FETCH_MY_BOOKINGS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: bookingConstants.FETCH_MY_BOOKINGS_ERROR,
      payload: [],
    });
  }
};

export const setClaimTask = (form) => async (dispatch) => {
  try {
    const response = await useAxiosPost("/booking/claim-task", form);
    const { booking } = response.data;
    dispatch(setActiveBooking(booking));
    if (booking.warrior_id === form.user_id) {
      segmentTrack("Warrior Assigned", {
        bookingId: booking.id,
        warriorId: booking.warrior_id,
        timeToAssign: booking.timeTracking.timeToAssign,
      });
      toastr.success("Success !", "You are assigned to this task!");
    } else {
      toastr.success("Great !", "You are now added to the waiting list.");
      segmentTrack("Warrior Added To Waitlist", { bookingId: booking?.id ?? "" });
    }
  } catch (error) {
    toastr.error("Error", error);
  }
};

export const setWithdrawTask =
  ({ task_id, user_id }) =>
  async (dispatch) => {
    try {
      const response = await useAxiosGet(`/booking/withdraw-task?task_id=${task_id}&user_id=${user_id}`);
      dispatch(setActiveBooking(response.data.booking));
      toastr.success("Success !", "You have withdrawn from this task!");
      dispatch(fetchAvailableBookings());
      dispatch(fetchMyBookings(user_id));
    } catch (error) {
      console.log("Error in withdraw task: ", error);
      toastr.error("Error", "Something went wrong, please try again later");
    }
  };

export const setBookingComplete = (task) => async (dispatch, getState) => {
  try {
    const warrior = getState().firebase.profile;
    const response = await useAxiosPost("/booking/complete-task", { task, warrior });
    dispatch(fetchAvailableBookings());
    dispatch(fetchMyBookings(task.user_id));
    dispatch({
      type: uiConstants.SET_TASK_COMPLETED,
      payload: true,
    });
    return response?.data?.success;
  } catch (error) {
    toastr.error("Error", "Something went wrong, please try again later");
    return false;
  }
};

export const associateBookingsToUser = async (uid) => {
  let currentBooking = localStorage.getItem("newBooking");
  if (currentBooking !== undefined) {
    currentBooking = JSON.parse(currentBooking);
    let errorInPosts = false;
    if (Array.isArray(currentBooking)) {
      // If already an array we add to it.
      for (const booking of currentBooking) {
        if (booking === null) continue;
        const form = {
          bookingId: booking.booking_id,
          customerId: uid,
        };
        await useAxiosPost("/booking/associate-task", form).catch((error) => {
          console.log("error", error);
          errorInPosts = true;
        });
      }
      if (!errorInPosts) {
        // If associations were successful.
        console.log("removing key");
        localStorage.removeItem("newBooking");
      }
    }
  }
};

export const fetchFirestoreUserBookings = async (uid) => {
  try {
    const response = await useAxiosPost(`/booking/get-my-orders`, { uid });
    return response?.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchMyOrders = (uid) => async (dispatch) => {
  try {
    dispatch({ type: bookingConstants.SET_BOOKING_LOADING });
    const response = await useAxiosPost(`/booking/get-my-orders`, { uid });

    dispatch({
      type: bookingConstants.FETCH_MY_ORDERS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: bookingConstants.FETCH_MY_ORDERS_ERROR,
      payload: error.response,
    });
  } finally {
    dispatch({ type: bookingConstants.RESET_BOOKING_LOADING });
  }
};

export const fetchBooking = async (id) => {
  try {
    const response = await useAxiosGet(`/booking/${id}`);
    return response.data;
  } catch (error) {
    console.log(`Error fetcing booking: ${id}`, error);
    return null;
  }
};

export const fetchBookingQuote = async (id) => {
  try {
    const response = await useAxiosGet(`/booking-quotes/${id}`);
    return response.data;
  } catch (error) {
    console.log(`Error fetching booking quote: ${id}`, error);
    return null;
  }
};

export const fetchAllBookingQuote = async (id) => {
  try {
    const response = await useAxiosGet(`/booking-quotes/my-active-quotes/${id}`);
    return response.data;
  } catch (error) {
    console.log(`Error fetching booking quote: ${id}`, error);
    return null;
  }
};

export const setActiveBooking = (booking, bookingId) => async (dispatch) => {
  try {
    dispatch({ type: bookingConstants.SET_BOOKING_LOADING });

    if (bookingId) {
      const booking = await fetchBooking(bookingId);
      if (!booking) throw "No booking found";
      dispatch({
        type: bookingConstants.ACTIVE_BOOKING,
        selectedBooking: booking,
      });
      return;
    }
    if (!booking.id) {
      return;
    }

    dispatch({
      type: bookingConstants.ACTIVE_BOOKING,
      selectedBooking: booking,
    });
  } catch (error) {
    console.log("Error booking: ", error);
    history.push("/");
  } finally {
    dispatch({ type: bookingConstants.RESET_BOOKING_LOADING });
  }
};

export const setBookingOrderNumber = (orderNumber) => async (dispatch) => {
  const response = await useAxiosGet(`booking/order/${orderNumber}`);
  dispatch(setActiveBooking(response.data));
};

export const getBookingFromOrderNumber = async (orderNumber) => {
  try {
    const response = await useAxiosGet(`booking/order/${orderNumber}`);
    return response?.data;
  } catch (error) {
    console.log("Error on getbookingFromOrderNumber", error);
    return [];
  }
};

export const setSelectedRecurringBooking = (bookingId) => async (dispatch) => {
  dispatch({ type: bookingConstants.SET_BOOKING_LOADING });
  try {
    const response = await useAxiosGet(`/recurring/${bookingId}`);
    dispatch({
      type: bookingConstants.SET_SELECTED_RECURRING_BOOKING,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: bookingConstants.SET_SELECTED_RECURRING_BOOKING,
      payload: null,
    });
    console.log("Error on setSelectedRecurringBooking", error);
  } finally {
    dispatch({ type: bookingConstants.RESET_BOOKING_LOADING });
  }
};

export const duplicateRecurringBooking = (bookingId, callback) => async (_, getState) => {
  try {
    const profile = getState().firebase.profile;
    const response = await useAxiosPost(`/recurring/duplicate_booking`, {
      id: bookingId,
      userId: profile.uid,
    });
    if (response.data.success) {
      toastr.success("Success !", response.data.message);
      callback?.();
    } else {
      toastr.error("Error !", response.data.message);
      callback?.();
    }
  } catch (error) {
    console.log("Error in duplicateRecurringBooking", error);
  }
};

export const updateRecurringBooking =
  ({ id, form }) =>
  async (dispatch) => {
    try {
      dispatch({ type: bookingConstants.SET_BOOKING_LOADING });
      const response = await useAxiosPost(`/recurring/update/${id}`, form);
      return response.data.success;
    } catch (error) {
      toastr.error("Error", "Something went wrong !");
      return false;
    } finally {
      dispatch({ type: bookingConstants.RESET_BOOKING_LOADING });
    }
  };

export const fetchBookingsBetweenDates = async (form) => {
  try {
    const response = await useAxiosPost("/booking/between-dates/", form);
    return response.data;
  } catch (error) {
    toastr.error("Error finding bookings", "Something went wrong !");
    return null;
  }
};

export const resetSelectedBooking = () => (dispatch) => {
  dispatch({
    type: bookingConstants.RELEASE_ACTIVE_BOOKING,
  });
};
export const resetSelectedRecurringBooking = () => (dispatch) => {
  dispatch({
    type: bookingConstants.RELEASE_ACTIVE_RECURRING_BOOKING,
  });
};

const setBooking =
  (booking, allowUnserviceable = false) =>
  async (dispatch) => {
    try {
      if (booking) {
        const validation = await dispatch(validateServiceLocation(booking.address, booking.task, allowUnserviceable));
        if (!validation) {
          throw "Address Location is no longer serviceable !";
        }
        dispatch(setActiveBooking(booking));
        dispatch(setAddress(booking.address));
        dispatch(modifyBusinessHours(booking.date));
      } else {
        throw "Booking not found !";
      }
    } catch (error) {
      toastr.error("Error", error || "Something went wrong !");
    }
  };

export const fetchAndSetBooking = (bookingId) => async (dispatch) => {
  try {
    if (!bookingId) throw "Invalid booking Id";
    const booking = await fetchBooking(bookingId);
    dispatch(setBooking(booking));
  } catch (error) {
    toastr.error("Error", error || "Something went wrong !");
    history.push("/");
  }
};

export const fetchAndSetBookingQuote = (draftId) => async (dispatch) => {
  try {
    const allowUnserviceable = true;
    if (!draftId) throw "Invalid quote Id";
    const booking = await fetchBookingQuote(draftId);
    if (!booking) throw "Quote not found !";

    dispatch(setBooking(booking, allowUnserviceable));
    dispatch({ type: bookingConstants.SET_DRAFT_BOOKING, payload: booking });
  } catch (error) {
    toastr.error("Error", error || "Something went wrong !");
    history.push("/");
  }
};

export const fetchAndSetAllQuote = (customerId) => async (dispatch) => {
  try {
    if (!customerId) throw "Invalid customer Id";
    const data = await fetchAllBookingQuote(customerId);
    const bookings = data.response;
    if (bookings.length === 0) throw "You don't have any more open quotes!";
    return bookings;
  } catch (error) {
    toastr.error("All quotes completed", error || "Something went wrong !");
    history.push("/booking?step=initial");
  }
};

export const fetchDateBookings = (date, locationId) => async (dispatch) => {
  try {
    const res = await useAxiosPost("/booking/date-location-bookings", { date, locationId });
    dispatch({ type: bookingConstants.SET_SLOT_BOOKINGS, payload: res.data });
  } catch (error) {
    toastr.error("Error", "Something went wrong !");
    dispatch({ type: bookingConstants.SET_SLOT_BOOKINGS, payload: [] });
  }
};

export const syncTaskData = async (bookingId) => {
  try {
    const response = await useAxiosPost("/booking/sync_task_data", { bookingId });
    if (response.data.success) {
      toastr.success("Synced...");
    } else {
      toastr.error("Error: ", response.data?.error?.message);
    }
  } catch (error) {
    console.log("Error in syncTaskData: ", error.message);
    toastr.error("Error", error.message || "Something went wrong !");
  }
};

export const fetchRevisionHistory = async (bookingId) => {
  try {
    const response = await useAxiosGet(`/booking/field-tracking/${bookingId}`);
    if (!response.data.success) {
      throw response.data.error;
    }
    return response.data.data;
  } catch (error) {
    console.log("Error in fetchRevisionHistory: ", error.message);
    return [];
  }
};

export const fetchPaymentActivity = async (bookingId) => {
  try {
    const response = await useAxiosGet(`/booking/payment-activities/${bookingId}`);
    if (!response.data.success) {
      throw response.data.error;
    }
    return response.data.data;
  } catch (error) {
    console.log("Error in fetchRevisionHistory: ", error.message);
    return [];
  }
};

// firestore

export const fetchBookings = async () => {
  try {
    const response = await useAxiosGet("/booking/get-bookings");
    return response.data.payload;
  } catch (error) {
    console.error("Error in FetchBookings", error.message);
    toastr.error("Error", error.message || "Something went wrong !");
    return [];
  }
};

//elasticSearch

export const fetchEBookings = async (query, envType) => {
  try {
    const response = await useAxiosPost("/booking/e/get-bookings", { query, envType });
    return response.data.payload;
  } catch (error) {
    console.error("Error in FetchEBookings", error.message);
    toastr.error("Error", error.message || "Something went wrong !");
    return [];
  }
};

export const fetchESalesBookings = async (query, envType) => {
  try {
    const response = await useAxiosPost("/booking/e/get-sales-bookings", { query, envType });
    return response.data.payload;
  } catch (error) {
    console.error("Error in FetchEBookings", error.message);
    toastr.error("Error", error.message || "Something went wrong !");
    return [];
  }
};

// hooks

// const bookingsBatchFetchProps = {
//   URL: "/booking/get-bookings",
//   SET_DATA: bookingConstants.FETCH_ALL_BOOKINGS,
//   SET_LOADER: bookingConstants.SET_BOOKINGS_LOADING,
//   RESET_LOADER: bookingConstants.RESET_BOOKINGS_LOADING,
//   FETCHING: bookingConstants.FETCHING_BOOKINGS,
// };

const recurringBookingsBatchFetchProps = {
  URL: "/recurring/get-recurring-bookings",
  SET_DATA: bookingConstants.FETCH_ALL_RECURRING_BOOKINGS,
  SET_LOADER: bookingConstants.SET_BOOKINGS_LOADING,
  RESET_LOADER: bookingConstants.RESET_BOOKINGS_LOADING,
};

// export const useFetchBookings = (settings) =>
//   useBatchFetch({
//     settings,
//     ...bookingsBatchFetchProps,
//   });

// export const useFetchBookingsWithIDB = (settings) =>
//   useIndexedDBWithBatchFetch(DB_TABLES.bookings, {
//     settings,
//     ...bookingsBatchFetchProps,
//   });

export const useFetchRecurringBookings = (settings) =>
  useBatchFetch({
    settings,
    ...recurringBookingsBatchFetchProps,
  });

export const useFetchRecurringBookingsWithIDB = (settings) =>
  useIndexedDBWithBatchFetch(DB_TABLES.recurringBookings, {
    settings,
    ...recurringBookingsBatchFetchProps,
  });

// export const useSubscribeToBookings = () => {
//   const db = window[INDEXED_DB_NAME];
//   const dispatch = useDispatch();

//   useFirestoreListener("bookings", {
//     onAdd: (newDoc) => {
//       db(DB_TABLES.bookings, (store) => store.upsert(newDoc));
//       dispatch(addBookingState(newDoc));
//     },
//     onUpdate: (updatedDoc) => {
//       db(DB_TABLES.bookings, (store) => store.upsert(updatedDoc));
//       dispatch(updateBookingState(updatedDoc));
//     },
//     onDelete: (deletedDoc) => {
//       db(DB_TABLES.bookings, (store) => store.remove(deletedDoc.id));
//       dispatch(deleteBookingState(deletedDoc));
//     },
//   });
// };

export const useSubscribeToRecurringBookings = () => {
  const db = window[INDEXED_DB_NAME];
  const dispatch = useDispatch();

  useFirestoreListener("recurring", {
    onAdd: (newDoc) => {
      db(DB_TABLES.recurringBookings, (store) => store.upsert(newDoc));
      dispatch(addRecurringBookingState(newDoc));
    },
    onUpdate: (updatedDoc) => {
      db(DB_TABLES.recurringBookings, (store) => store.upsert(updatedDoc));
      dispatch(updateRecurringBookingState(updatedDoc));
    },
    onDelete: (deletedDoc) => {
      db(DB_TABLES.recurringBookings, (store) => store.remove(deletedDoc.id));
      dispatch(deleteRecurringBookingState(deletedDoc));
    },
  });
};

export const reWorkLedger = async (bookingId) => {
  try {
    const { data, status } = await useAxiosGet(`booking/re-work-ledger/${bookingId}`);
    if (status === 400) {
      console.error("Could not get updated doc");
      return;
    }
    toastr.success("Ledger reworked");
    return data;
  } catch (error) {
    console.error(error);
    toastr.error("Could not rework the ledger", error);
    return;
  }
};

/* Used to get who would get batch sms */
export const simulateBatchSms = async (bookingId) => {
  try {
    const { data } = await useAxiosGet(`booking/simulate-batch-sms/${bookingId}`);
    console.log(data);
    return data?.sort((a, b) => (a.name > b.name ? 1 : -1)) ?? [];
  } catch (error) {
    console.error(error);
    toastr.error("Could not simualte", error);
    return;
  }
};
