import { makeStyles, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { segmentTrack } from "../../../helpers/segment";

const useStyles = makeStyles((theme) => ({
  text: {
    fontWeight: 500,
    fontSize: 18,
    background: "#fafafa",
    padding: theme.spacing(2),
    borderRadius: 4,
    width: "max-content",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      textAlign: "center",
    }
  },
  noBackgroundText: {
    fontWeight: 500,
    fontSize: 16,
    borderRadius: 4,
    width: "max-content",
    boxSizing: "border-box",
  },
  click: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    cursor: "pointer",
    fontWeight: 700,
  },
  split: {
    display: "block",
    height: 4,
  }
}));
export const BidPrompt = ({ isBidMode, setIsBidMode, isDefaultBid, message }) => {
  const classes = useStyles();
  const handleClick = (value) => {
    const clickValue = value ? "Click" : "Unclick";
    segmentTrack(`${clickValue} Too Many Items`);
    setIsBidMode(value);
  };
  useEffect(() => {
    segmentTrack("Too Many Items Prompt Displayed");
  }, []);
  return (
    <div className="bid-prompt-container">
      {isBidMode && !isDefaultBid && (
        <Typography className={classes.noBackgroundText}>
          To go back to the item selector,{" "}
          <span className={classes.click} onClick={() => handleClick(false)}>
            click here
          </span>
        </Typography>
      )}
      {!isBidMode && (
        <Typography className={classes.text}>
          {message ?? "Do you have a complicated order?"}{" "}
          <span className={classes.split} />
          <span className={classes.click} onClick={() => handleClick(true)}>
            Click here
          </span>{" "}
          to get a customized quote!
        </Typography>
      )}
    </div>
  );
};
