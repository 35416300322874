import React from "react";
import { Redirect } from "react-router-dom";
import { roles } from "../constants/roles";
import { toastr } from "react-redux-toastr";
import { Elements } from "@stripe/react-stripe-js";

export const renderComponent = (route, sessionPrivileges, profile, stripe) => (props) => {
  const isAuthenticated = !profile?.isEmpty;
  const needAccess = route.privilege ?? false;
  const hasAccess = sessionPrivileges.includes(route.privilege);
  const defaultRole = roles.customer.value;

  if (profile?.isBanned && !["/", "/account"].includes(route.path)) {
    toastr.info("You have a ban imposed for your account. Redirecting...");
    return <Redirect to="/account" />;
  }

  if (needAccess && !hasAccess) {
    // toastr.info("You do not have access to this page. Redirecting...");
    return isAuthenticated ? <Redirect to={roles[profile.userType].homepage} /> : <Redirect to="/" />;
  }

  const component = () => <route.component {...props} />;
  return component();
};
