import React from "react";
import { scrollToTop } from "../../../methods/scrollToTop";
import BottomCTA from "../LandingPage/BottomCTA";
import Clients from "../LandingPage/Clients";
import Pitch from "../LandingPage/Pitch";
import B2bTestimonials from "./B2bTestimonials";
import BusinessServices from "./BusinessServices";
import Sustainability from "./Sustainability";
import WhyDimension from "./WhyDimension";

const B2BSolutions = () => {
  scrollToTop();
  return (
    <>
      <Pitch isBusiness />
      <WhyDimension />
      <Clients isB2b />
      <BusinessServices />
      <B2bTestimonials />
      <Sustainability />
      <BottomCTA isB2b />
    </>
  );
};

export default B2BSolutions;
