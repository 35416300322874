/* eslint-disable no-console */
import { toastr } from "react-redux-toastr";
import reviewConstants from "../constants/reviewTypes";
import dayjs from "dayjs";
import { useAxiosGet, useAxiosPost } from "../../axios";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export const postReviewRating = (review) => async (_, getState) => {
  try {
    const booking = getState().booking.selectedBooking;
    const result = await useAxiosPost("/reviews/post-review", { review, booking });
    if (result.data.success) {
      toastr.success("Success", "Review posted successfully");
    } else {
      throw result.data.error;
    }
  } catch (error) {
    toastr.error("Error", error);
  }
};

export const fetchReviews = (userId) => async (dispatch) => {
  try {
    const response = await useAxiosGet(`/reviews/get-reviews/${userId}`);
    dispatch({ type: reviewConstants.SET_REVIEWS, payload: response.data });
  } catch (error) {
    console.error("❗️Error fetching reviews", error);
  }
};

export const fetchBookingReview = async (bookingId) => {
  try {
    const response = await useAxiosGet(`/reviews/get-booking-review/${bookingId}`);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};
