import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useQueryParam, BooleanParam, StringParam } from "use-query-params";

import { Button, makeStyles, Typography, Grid, Card, CardContent, Accordion, AccordionSummary, AccordionDetails, Divider } from "@material-ui/core";
import dayjs from "dayjs";
import history from "@history";
import { toastr } from "react-redux-toastr";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";
import TimeIcon from "@material-ui/icons/AccessTime";

import AcceptTask from "../AcceptTask";
import WithdrawButton from "./Buttons/WithdrawButton";
import AcceptButton from "./Buttons/AcceptButton";
import CompleteButton from "./Buttons/CompleteButton";
import WaitlistButton from "./Buttons/WaitlistButton";
import CountDown from "./CountDown";

import { taskTypes, bookingStatus } from "@constants/booking";
import { isBookingOverlap } from "@helpers/booking";
import { setActiveBooking, setClaimTask, setWithdrawTask } from "@store/actions/booking";
import tempDataConstants from "@store/constants/tempDataTypes";
import { segmentTrack, pushToSegment } from "@helpers/segment";

import ConfirmationModal from "@shared/components/ConfirmationModal";
import Loader from "@shared/components/Loader";
import TaskWithdrawModal from "./TaskWithdrawModal";
import BubbleRedirect from "../../BubbleRedirect";
import { useAxiosGet } from "../../../axios";
import TaskDetailCard from "./TaskDetailCard";
import BookingDetailsLayout from "@shared/components/BookingDetailsLayout";
import BookingDetailsItem from "@shared/components/BookingDetailsLayout/shared/BookingDetailsItem";
import ProductItem from "@shared/components/ProductItem";
import AddToCalendar from "@shared/components/AddToCalendar";
import {
  DesktopWindows,
  EmailOutlined,
  Error,
  LocalShippingOutlined,
  Map,
  MonetizationOnOutlined,
  PersonOutlined,
  PhoneInTalk,
  Schedule,
} from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ImageGalleryCustom from "@shared/components/ImageGallery";
import Spacer from "@shared/components/Spacer";
import GoogleMaps from "@shared/components/GoogleMaps";
import { vehicleTypes } from "../../../constants/booking";
import { getDumpsite } from "../../../helpers/dumpSites";
import { dumpSiteTypes } from "../../../constants/dumpSites";
import { getDistance } from "../../../shared/utils/maps";
import { roles } from "../../../constants/roles";

import clientsName from "@assets/booking_details/clients-name.svg";
import calendarDate from "@assets/booking_details/calendar-date.svg";
import address from "@assets/booking_details/address.svg";
import onsiteInstructions from "@assets/booking_details/onsite-instructions.svg";
import onsiteHours from "@assets/booking_details/onsite-hours.svg";
import onsiteImages from "@assets/booking_details/onsite-images.svg";
import clientNotes from "@assets/booking_details/client-notes.svg";
import itemsExpected from "@assets/booking_details/items-expected.svg";
import stairs from "@assets/booking_details/stairs.svg";
import dismantling from "@assets/booking_details/dismantling.svg";
import destinationType from "@assets/booking_details/destination-type.svg";
import endDestination from "@assets/booking_details/end-destination.svg";
import phone from "@assets/booking_details/phone.svg";
import minVehicle from "@assets/booking_details/min-vehicle.svg";
import warning from "@assets/booking_details/warning.svg";
import { scrollToTop } from "../../../methods/scrollToTop";
import { fetchNearestDumpSites } from "../../../store/actions/dumpSites";
import { checkFeatureFlag } from "../../../store/actions/featureFlags";
import { useLDChecker } from "../../../shared/hooks/useLDChecker";
import { WarriorEarlyTaskPrice } from "../GetTasksPage/components/WarriorEarlyTaskPrice";
import { dumpsterPickups, Pickup } from "../../../constants/dumpsters";

const useStyles = makeStyles((theme) => ({
  assignedBanner: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
      marginTop: 10,
    },
  },
  bannerText: {
    fontSize: "18px",
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0),
    },
  },
  container: {
    minHeight: "80vh",
  },
  subTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
    fontSize: "22px",
    color: "black",
    fontWeight: "600",
  },
  backBtn: {
    height: "30px",
    marginBottom: "10px",
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.hover,
    },
  },
  detailsContainer: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
      margin: "0 20px",
    },
  },
  detailsCardTitle: {
    fontWeight: "bold",
    fontSize: "18px",
  },
  greenText: {
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  cardMobile: {
    position: "sticky",
    bottom: "0px",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  header: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  headerText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "27px",
    },
    fontSize: "45px",
    fontWeight: "900",
  },
  titleContainer: {
    [theme.breakpoints.down("sm")]: {
      margin: "unset",
    },
    margin: "0 20px",
  },
  backLinkButton: {
    textDecoration: "none",
    fontSize: "14px",
    fontWeight: "400",
  },
  destinationAddress: {
    fontWeight: 400,
    color: theme.palette.primary.main,
  },
  bottomBuffer: {
    height: 300,
    [theme.breakpoints.down("sm")]: {
      height: 0,
    },
  },
  endDestContainer: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column",
    "& > svg": {
      marginBottom: theme.spacing(-1),
    },
    "& div:first-child": {
      display: "flex",
      alignItems: "center",
      "& > svg": {
        marginLeft: theme.spacing(0.5),
      },
    },
  },
  withdrawContainer: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(5),
      padding: theme.spacing(2),
    },
  },
  flexBox: {
    display: "flex",
    justifyContent: "top",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  flexRowContainer: {
    display: "flex",
    justifyContent: "top",
    marginBottom: "10px",
  },
  flexColumnContainer: {
    display: "flex",
    flexDirection: "column",
  },
  width_100: {
    width: "100%",
  },
  width_30: {
    width: "30%",
  },
  width_40: {
    width: "40%",
  },
  marginIcon: {
    marginLeft: "5px",
  },
  marginLeftAuto: {
    marginLeft: "auto",
    textAlign: "end",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "unset",
      textAlign: "start",
    },
  },
  marginTopBottom: {
    margin: "10px 0px",
  },
  accordion: {
    boxShadow: "none",
    border: "1px solid #eee",
    borderRadius: 4,
  },
  breakAllWords: {
    wordBreak: "break-all",
  },
  greenTextNormal: {
    color: theme.palette.primary.main,
  },
  greyText: {
    color: "grey",
  },
  redText: {
    color: "red",
  },
}));

const ImageContainer = ({ image, ...otherProps }) => <img width={21} src={image} {...otherProps} />;

function TaskDetails() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const bookingId = useParams().id;
  const [tracking] = useQueryParam("tracking", BooleanParam);
  const [view] = useQueryParam("view", StringParam);
  const [alertId] = useQueryParam("alert_id", StringParam);
  const [trigger] = useQueryParam("trigger", StringParam);
  const [channel] = useQueryParam("channel", StringParam);

  const booking = useSelector((state) => state.booking.selectedBooking);
  const isLoading = useSelector((state) => state.booking.isLoading);
  const user = useSelector((state) => state.firebase.profile);
  const isLoggedInProvider = user?.userType === roles.provider.value;

  const [redirect] = useState(view === "true" ? true : false);
  const [slugged, setSlugged] = useState(false);
  const [tracked, setTracked] = useState(false);
  const [taskAccepted, setTaskAccepted] = useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [showWaitingListModal, setShowWaitingListModal] = useState(false);
  const [showUserBanModal, setShowUserBanModal] = useState(false);
  const [showBookingOverlapModal, setShowBookingOverlapModal] = useState(false);
  const [destination, setDestination] = useState(null);
  const [renderMap, setRenderMap] = useState(false);
  const [dumpsiteOptions, setDumpsitesOptions] = useState([]);
  const [displayBonus, setDisplayBonus] = useState(false);

  const handleGoBack = () => {
    history.goBack();
  };

  const handleTaskAccept = async () => {
    // check for live booking data once more to make sure waitinglist
    const { data: booking } = await useAxiosGet(`/booking/${bookingId}`);
    const { data: tasks } = await useAxiosGet(`/booking/get-my-tasks?uid=${user.uid}`);

    segmentTrack("Warrior Task Details Accept Button Click", { bookingId: bookingId });

    const blockAcceptTask = isBookingOverlap(booking, tasks);
    if (blockAcceptTask) {
      setShowBookingOverlapModal(true);
      segmentTrack("Warrior Task Details Accept Blocked", { bookingId: bookingId });
      return;
    }

    const banTimeDiff = dayjs().diff(dayjs(user?.soft_ban), "seconds");
    const isUserSoftBanned = 0 < banTimeDiff && banTimeDiff < 24 * 60 * 60; // 24 hours
    if (isUserSoftBanned) {
      setShowUserBanModal(true);
      segmentTrack("Warrior Task Details Accept Banned", { bookingId: bookingId });
    } else if (!booking.warrior_id && booking?.waiting_list[0] === user?.uid) {
      setTaskAccepted(true);
    } else if (booking?.waiting_list.length) {
      setShowWaitingListModal(true);
      segmentTrack("Warrior Task Details Accept Waiting List", { bookingId: bookingId });
    } else {
      setTaskAccepted(true);
    }
  };

  const handleTaskComplete = () => {
    history.push(`/complete_details/${bookingId}`);
  };

  const handleback = () => {
    history.push(`/get_tasks`);
  };

  const handleWithdrawModal = (value) => {
    setShowWithdrawModal(!!value);
  };

  const prepMap = (dest) => {
    setDestination(dest);
    setRenderMap(true);
  };

  const checkFlagDumpsites = async () => {
    const data = {
      key: user?.uid,
      custom: {
        market: booking?.address?.city,
      },
    };
    const isValid = await checkFeatureFlag("dumpsite-locations", data, false);
    return isValid;
  };

  const getDumpsiteOptions = async () => {
    const shouldFetch = await checkFlagDumpsites();
    if (!shouldFetch) return;
    const dumpsitesByDistance = await fetchNearestDumpSites(booking);
    if (dumpsitesByDistance?.length) {
      setDumpsitesOptions(dumpsitesByDistance);
    }
  };

  useEffect(() => {
    if (booking?.assignedDumpsiteId) {
      getDumpsite(booking?.assignedDumpsiteId, prepMap);
    } else {
      setDestination(null);
      if (booking?.address.city && !isCompleted) {
        getDumpsiteOptions();
      }
    }
  }, [booking, user]);

  scrollToTop();

  useEffect(() => {
    if (!redirect && tracking && booking && user && user.userType === roles.provider.value && !tracked) {
      pushToSegment("SMS Job Alert Clicked", {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        booking_id: booking.orderNumber ?? bookingId,
      });
      setTracked(true);
    }
  }, [tracking, user, booking]);

  useEffect(() => {
    if (booking?.slugged) {
      setSlugged(true);
    }
    if (!redirect && !booking?.slugged && (!booking || booking?.id !== bookingId)) {
      dispatch(setActiveBooking(booking, bookingId));
    }

    return () => {
      dispatch({ type: tempDataConstants.RESET });
    };
  }, [bookingId, booking, dispatch]);

  const handleDisplayBonus = (value) => {
    setDisplayBonus(value);
  };
  // Launch Darkly 15 Minute Bonus Checked
  useLDChecker({
    flag: "fifteen-minute-bonus",
    dependencies: [],
    profile: user,
    callback: (value) => handleDisplayBonus(value),
    run: isLoggedInProvider,
  });

  if (view || slugged) {
    const url = new URL(window.location.href);
    const slugId = url.pathname.split("/").pop();
    return <BubbleRedirect slugId={slugId} />;
  }

  const isBookingAccepted = [bookingStatus.current.value, bookingStatus.review.value, bookingStatus.done.value].includes(booking?.status);
  const isCurrent = booking?.status === bookingStatus.current.value;
  const isDumpsterRental = booking?.task === taskTypes["dumpster-rental"].value;
  const isBefore2hours = isBookingAccepted && dayjs(booking?.date).tz(booking.timeZone).subtract(2, "hours").isBefore(dayjs().tz(booking?.timeZone));
  const isBefore4hours = isBookingAccepted && dayjs(booking?.date).tz(booking.timeZone).subtract(4, "hours").isBefore(dayjs().tz(booking?.timeZone));
  const isAssignedToMe = booking?.warrior_id === user?.uid;
  const isCompleted = !!booking?.completion_date;
  const isAfter4hours = isCompleted && dayjs(booking?.completion_date).tz(booking?.timeZone).add(4, "hours").isAfter(dayjs().tz(booking?.timeZone));
  const googleMapsPoints = booking && [booking?.address.coordinates];
  const directions =
    destination && booking && isBefore2hours
      ? { origin: booking.address.coordinates, destination: { lat: destination.lat, lng: destination.lng } }
      : null;
  const hasAssignedDumpsite = booking && !!booking?.assignedDumpsiteId;
  const userSelectedDumpSite = booking && booking?.userSelectedDumpSite;
  const showDumpsiteOptions = userSelectedDumpSite ? [userSelectedDumpSite] : dumpsiteOptions;

  const calendarEvent = {
    description: booking?.description,
    duration: 1,
    endDatetime: dayjs(booking?.date).tz(booking?.timeZone).add(3, "Hour").format("YYYYMMDDTHHmmssZ"),
    location: isBefore2hours ? booking?.address.location : `${booking?.address?.city}, ${booking?.address?.zipCode}`,
    startDatetime: dayjs(booking?.date).tz(booking?.timeZone).format("YYYYMMDDTHHmmssZ"),
    title: taskTypes[booking?.task]?.label,
  };

  const isIn15Minutes = displayBonus && dayjs(booking?.createdAt).diff(dayjs(), "minute") > -30;
  const getFinalPayoutPrice = () => {
    return (
      <WarriorEarlyTaskPrice
        item={booking}
        applyBonus={isIn15Minutes || (booking?.isEarlyWarriorClaim && !!booking?.warrior_id)}
        isTaskDetails={true}
      />
    );
  };

  const getDestinationTypeText = () => {
    if (!destination?.type) {
      return null;
    } else {
      return dumpSiteTypes[destination.type].label;
    }
  };

  const getDestinationText = (componentClasses) => {
    if (!destination) {
      return (
        <div className={classes.endDestContainer}>
          {showDumpsiteOptions?.length ? (
            <Typography className={componentClasses.greenText}>Flexible, suggested dumpsites below</Typography>
          ) : (
            <Typography className={componentClasses.greenText}>Flexible, provider&apos;s choice</Typography>
          )}
        </div>
      );
    } else {
      return (
        <div className={classes.endDestContainer}>
          <Typography className={componentClasses.greenText}>{destination.name}</Typography>
          <Typography className={classes.destinationAddress}>{destination.street}</Typography>
          <Typography className={classes.destinationAddress}>
            {`${destination.city ? `${destination.city}, ` : ""}${destination.state ?? ""} ${destination.zip ?? ""}`.trim()}
          </Typography>
          <Typography className={componentClasses.helpText}>
            {`${getDistance(booking?.address?.coordinates, { lat: destination.lat, lng: destination.lng })} miles from pickup location`}
          </Typography>
          <Typography className={componentClasses.helpText}>* Please drop it off at this location to receive payment *</Typography>
        </div>
      );
    }
  };

  const submissionRejectedReason = () => {
    if (booking?.status === "rejected") {
      return (
        <div>
          <div>
            <Typography style={{ color: "red" }}>There was an issue with your task completion</Typography>
            <Typography variant="body2">The reason for the same can be any from the following:</Typography>
            <li>A duplicate picture has been submitted previously</li>
            <li>Multiple of the same photos are submitted in one submission</li>
            <li>The dumping weight indicated is more than 1000 pounds different than what the customer/system</li>
            <li>The task has a destination selected, but the provider does not dump there</li>
            <div style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}>
              <b>Rejection Notes</b>
              <span>{booking?.adminNotes}</span>
            </div>
          </div>
        </div>
      );
    }
  };

  const customerOnsiteInformation = () => {
    return [
      {
        icon: <ImageContainer image={clientsName} alt="Client's Name" />,
        label: "Client's Name",
        show: isAssignedToMe,
        render: (componentClasses) => <Typography className={componentClasses.itemTopLine}>{booking.first_name}</Typography>,
      },
      {
        icon: <ImageContainer image={calendarDate} alt={isDumpsterRental ? "Dumpster drop off date" : "Date and time"} />,
        label: isDumpsterRental ? "Dumpster drop off date" : "Date and time",
        show: true,
        render: (componentClasses) => (
          <div>
            <Typography className={`${componentClasses.itemTopLine} ${componentClasses.greenText}`}>
              {dayjs(booking.date).tz(booking.timeZone).format("MMM DD, YYYY h:mm A")}
            </Typography>
            {isAssignedToMe && <AddToCalendar event={calendarEvent} />}
          </div>
        ),
      },
      {
        icon: <ImageContainer image={address} alt="Service Address" />,
        label: "Service Address",
        show: booking && true,
        render: (componentClasses) =>
          isBefore2hours && isAfter4hours ? (
            <div>
              <Typography className={`${componentClasses.greenText} ${componentClasses.itemTopLine}`}>
                {booking.address?.city}, {booking.address?.zipCode}
              </Typography>
            </div>
          ) : isBefore2hours ? (
            <div>
              <Typography className={componentClasses.greenText}>{`${booking.address.street}`}</Typography>
              {booking.address_aditional && <Typography className={classes.destinationAddress}>{`${booking.address_aditional}`}</Typography>}
              <Typography className={classes.destinationAddress}>
                {`${booking.address?.city}, ${booking.address.state} ${booking.address?.zipCode}`}
              </Typography>
            </div>
          ) : (
            <div>
              <Typography className={`${componentClasses.greenText} ${componentClasses.itemTopLine}`}>
                {booking.address?.city}, {booking.address?.zipCode}
              </Typography>
              <Typography className={componentClasses.helpText}>Street number will be available within 2 hrs of the appointment.</Typography>
            </div>
          ),
      },
      {
        icon: <PersonOutlined />,
        label: "Onsite Manager",
        show: isAssignedToMe && booking?.onsite?.name,
        render: (componentClasses) => <Typography className={componentClasses.itemTopLine}>{booking?.onsite?.name}</Typography>,
      },
      {
        icon: <EmailOutlined />,
        label: "Onsite Email",
        show: isAssignedToMe && booking?.onsite?.email,
        render: (componentClasses) => <Typography className={componentClasses.itemTopLine}>{booking?.onsite?.email}</Typography>,
      },
      {
        icon: <PhoneInTalk />,
        label: "Onsite Phone",
        show: isAssignedToMe && booking?.onsite?.phone_number,
        render: (componentClasses) => <Typography className={componentClasses.itemTopLine}>{booking?.onsite?.phone_number}</Typography>,
      },
      {
        icon: <ImageContainer image={onsiteInstructions} alt="Onsite Instructions" />,
        label: "Onsite Instructions",
        show: isAssignedToMe && booking?.onsite?.instructions,
        render: (componentClasses) => <Typography className={componentClasses.itemTopLine}>{booking?.onsite?.instructions}</Typography>,
      },
      {
        icon: <ImageContainer image={onsiteHours} alt="Onsite Hours" />,
        label: "Onsite Hours",
        show: isAssignedToMe && booking?.onsite?.hours,
        render: (componentClasses) => <Typography className={componentClasses.itemTopLine}>{booking?.onsite?.hours}</Typography>,
      },
      {
        icon: <ImageContainer image={onsiteImages} alt="Onsite Pictures" />,
        label: "Onsite Pictures",
        show: isAssignedToMe && booking?.onsite?.images,
        render: () => <ImageGalleryCustom images={booking?.onsite?.images} />,
      },
    ];
  };

  const destinationInformation = () => {
    return [
      {
        icon: <ImageContainer image={endDestination} alt="End Destination" />,
        label: "End Destination",
        show: true,
        render: (componentClasses) => (
          <div>
            {getDestinationText(componentClasses)}
            {((dumpsiteOptions && !isCompleted) || (userSelectedDumpSite && isCompleted)) &&
              !hasAssignedDumpsite &&
              showDumpsiteOptions?.length > 0 &&
              showDumpsiteOptions
                .sort((a, b) => (a.distance > b.distance ? 1 : -1))
                .map((item) => (
                  <Accordion key={item.id} TransitionProps={{ unmountOnExit: true }} className={`${classes.marginTopBottom} ${classes.accordion}`}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                      <div className={`${classes.flexBox} ${classes.width_100}`}>
                        <div className={classes.flexColumnContainer}>
                          <span>{item?.name || item?.google_name || item.city}</span>
                          <span className={classes.greyText}>{item.google_address || item.address}</span>
                        </div>
                        <span className={`${componentClasses.greenText} ${classes.marginLeftAuto} ${classes.width_40}`}>
                          {item.distance?.toFixed(2)} miles away
                        </span>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails className={`${classes.greyText} ${classes.flexColumnContainer}`}>
                      <div className={`${classes.flexColumnContainer} ${classes.width_100} `}>
                        <div className={`${classes.flexRowContainer} `}>
                          <LocalShippingOutlined />
                          <span className={classes.marginIcon}>{item.type}</span>
                        </div>
                        {item.google_phone && (
                          <div className={`${classes.flexRowContainer} `}>
                            <PhoneInTalk />
                            <span className={classes.marginIcon}>{item.google_phone || "-"}</span>
                          </div>
                        )}
                        {item.website && (
                          <div className={`${classes.flexRowContainer}`}>
                            <DesktopWindows />
                            <span className={`${classes.marginIcon} ${classes.breakAllWords}`}>{item.website || "-"}</span>
                          </div>
                        )}
                      </div>
                      {item?.openingHours?.length ? (
                        <>
                          <Divider className={classes.marginTopBottom} />
                          <div className={`${classes.flexRowContainer} ${classes.width_100} `}>
                            <Schedule />
                            <div className={`${classes.flexColumnContainer} ${classes.width_100}`}>
                              {item?.openingHours.map((item, index) => (
                                <div key={index} className={`${classes.flexRowContainer} ${classes.width_100} `}>
                                  <span className={`${classes.greenTextNormal} ${classes.marginIcon} ${classes.width_30}`}>
                                    {item.split(": ")[0]}
                                  </span>
                                  <span className={item.split(": ")[1] === "Closed" && classes.redText}>{item.split(": ")[1]}</span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {item.metaData?.totalSpent && (
                        <>
                          <Divider className={classes.marginTopBottom} />
                          <div className={classes.flexRowContainer}>
                            <MonetizationOnOutlined />
                            <span className={classes.marginIcon}>
                              Estimated Cost:
                              {isNaN(item.metaData?.totalSpent) || item.metaData?.totalSpent === 0
                                ? "-"
                                : `$ ${Math.floor(Number(item.metaData?.totalSpent) / 2000)} per ton of trash`}
                            </span>
                          </div>
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>
                ))}
          </div>
        ),
        greenLabel: true,
      },
      {
        icon: <ImageContainer image={destinationType} alt="Destination Type" />,
        label: "Destination Type",
        show: !!destination,
        render: (componentClasses) => <Typography className={componentClasses.greenText}>{getDestinationTypeText(componentClasses)}</Typography>,
        greenLabel: true,
      },
    ];
  };

  const jobDetails = () => {
    return [
      {
        icon: <ImageContainer image={clientNotes} alt="Client Notes" />,
        label: "Client Notes",
        show: true,
        render: (componentClasses) => <Typography>{booking.description}</Typography>,
      },
      {
        icon: <ImageContainer image={minVehicle} alt="Min Vehicle Requirement" />,
        label: "Min Vehicle Requirement",
        show: !!booking.vehicle,
        render: (componentClasses) => <Typography>{vehicleTypes[booking.vehicle]?.label}</Typography>,
      },
      {
        icon: <ImageContainer image={phone} alt="Phone Number" />,
        label: "Phone Number",
        show: true,
        render: (componentClasses) =>
          isBefore4hours && isAfter4hours ? (
            <Typography className={classes.helpText}>Client phone number is not available.</Typography>
          ) : isBefore4hours ? (
            <Typography>{booking.phone_number}</Typography>
          ) : (
            <Typography className={classes.helpText}>Client phone number will be available on this page 4 hours before booking.</Typography>
          ),
      },
      {
        icon: <ImageContainer image={stairs} alt="stairs" />,
        label: "Stairs",
        show: !isDumpsterRental,
        render: (componentClasses) => <Typography>{booking.stairs ?? "Not expected"}</Typography>,
      },
      {
        icon: <ImageContainer image={dismantling} alt="dismantling" />,
        label: "Dismantling",
        show: !isDumpsterRental,
        render: (componentClasses) => <Typography>{booking.dismantling ?? "Not expected"}</Typography>,
      },
      {
        icon: <ImageContainer image={warning} alt="Note" />,
        label: "Note:",
        show: isBefore2hours,
        render: (componentClasses) => (
          <Typography variant="subtitle2">
            Note: please use the phone number associated with your account to contact customer. if you use a different phone number, the call or SMS
            will not go through. if you need assistant, contact customer support 415-417-0266.
          </Typography>
        ),
      },
      {
        icon: <ImageContainer image={itemsExpected} alt="Items Expected" />,
        label: "Items Expected",
        show: !isDumpsterRental,
        render: (componentClasses) => (
          <div className={componentClasses.productList}>
            {booking?.products?.map((item, index) => (
              <ProductItem key={index} icon={null} label={item.item.description} count={item.quantity} details={item.item.details} />
            ))}
          </div>
        ),
      },
      {
        icon: <TimeIcon />,
        label: "Expected Duration of Dumpster at Client site",
        show: isDumpsterRental,
        render: (componentClasses) => (
          <Typography className={componentClasses.greenText}>
            {booking.pickup === Pickup.Manual
              ? `${dayjs(booking["pickup-date"]).tz(booking.timeZone).format("MMM DD, YYYY")} ${dayjs()
                  .hour(booking["pickup-time"].hour)
                  .minute(0)
                  .format("h:mm A")}`
              : dumpsterPickups[booking.pickup]?.shortLabel}
          </Typography>
        ),
      },
      {
        icon: <BuildOutlinedIcon />,
        label: "Dumpsters Expected",
        show: isDumpsterRental,
        render: (componentClasses) => (
          <div>
            {booking?.services?.map((item, index) => (
              <div key={index}>
                <Typography>
                  <b>Dumpster size: {item.item.description}</b>
                </Typography>
                <Spacer />
                {item.item.items.map((material, index) => (
                  <Typography key={index}>
                    Dumping material: {material.description}
                    <Spacer />
                  </Typography>
                ))}
              </div>
            ))}
          </div>
        ),
      },
      {
        icon: <Error />,
        label: "Note:",
        show: isDumpsterRental,
        render: (componentClasses) => (
          <Typography variant="subtitle2">
            Note: only accept this task if you are familiar with dumpster rental tasks. you are required to drop off empty dumpsters and pick up full
            ones in a few days.
          </Typography>
        ),
      },
    ];
  };

  const imageItems = () => {
    return [
      {
        icon: null,
        label: "Task Images",
        show: true,
        block: true,
        render: (componentClasses) =>
          booking?.taskImages?.length ? (
            <ImageGalleryCustom images={booking.taskImages} grid={true} />
          ) : (
            <Typography className={classes.greyedText}>Not available</Typography>
          ),
      },
    ];
  };

  if (taskAccepted) {
    return <AcceptTask close={() => setTaskAccepted(false)} isEarlyClaim={isIn15Minutes} />;
  }

  return isLoading ? (
    <Loader />
  ) : (
    <div className={classes.root}>
      <ConfirmationModal
        open={showUserBanModal}
        body={<CountDown time1={dayjs(user?.soft_ban).add(24, "hour")} />}
        hideCancel
        proceedBtnText="GO BACK"
        onProceed={() => setShowUserBanModal(false)}
        onCancel={() => setShowUserBanModal(false)}
      />
      <ConfirmationModal
        open={showBookingOverlapModal}
        body={
          <div style={{ maxWidth: "320px", textAlign: "center", fontSize: "18px", color: "#353535" }}>
            {"Sorry! You already have another task that is starting at the same time."}
          </div>
        }
        hideCancel
        proceedBtnText="GO BACK"
        onProceed={() => setShowBookingOverlapModal(false)}
        onCancel={() => setShowBookingOverlapModal(false)}
      />
      <TaskWithdrawModal
        open={showWithdrawModal}
        handleClose={() => {
          setShowWithdrawModal(false);
        }}
        handleProceed={() => {
          setShowWithdrawModal(false);
          dispatch(setWithdrawTask({ task_id: bookingId, user_id: user.uid }));
        }}
      />
      <ConfirmationModal
        open={showWaitingListModal}
        title={
          <div style={{ fontSize: "24px", fontWeight: "500", textAlign: "center", color: "#353535", marginBottom: "-10px" }}>
            Sorry, you are a few minutes late!
          </div>
        }
        body={
          <div style={{ fontSize: "18px", textAlign: "center", marginBottom: "20px" }}>
            Another warrior just took this task. You can still get on the waitlist.{" "}
          </div>
        }
        proceedBtnText="JOIN WAITLIST"
        cancelBtnTxt="GO BACK"
        proceedBtnProps={{ variant: "outlined", style: { width: "100%" } }}
        cancelBtnProps={{ variant: "contained", style: { width: "100%" } }}
        onProceed={async () => {
          try {
            setShowWaitingListModal(false);
            await dispatch(setClaimTask({ task_id: bookingId, user_id: user.uid }));
            if (alertId) {
              segmentTrack("Warrior Notification Claimed Task", {
                task_id: bookingId,
                user_id: user.uid,
                alert_id: alertId,
                trigger,
                channel,
              });
            }
          } catch (err) {
            toastr.error("Error", "Something went wrong.");
          }
        }}
        onCancel={() => setShowWaitingListModal(false)}
        footer="Get on the waitlist in case this job reopens. 30% of our jobs are serviced by providers on the waitlist."
      />
      {isCurrent && !isCompleted && booking?.warrior_id === user?.uid && (
        <div className={classes.assignedBanner}>
          <div className={classes.bannerText}>Success! You are assigned to this task!</div>
        </div>
      )}
      {booking && (
        <BookingDetailsLayout
          subTitle={`Order ${booking.orderNumber} - ${taskTypes[booking.task].label}`}
          title={`${`Task Details`}`}
          backText={"Back to tasks"}
          notes={submissionRejectedReason()}
          sections={[customerOnsiteInformation(), jobDetails(), imageItems(), destinationInformation()]}
          sectionTitles={["Customer Onsite Information", "Job Details", "", "Destination Information"]}
          goBack={handleback}
          card={
            <TaskDetailCard
              handleTaskAccept={handleTaskAccept}
              handleTaskComplete={handleTaskComplete}
              handleGoBack={handleGoBack}
              handleWithdrawModal={handleWithdrawModal}
              booking={booking}
              showWithdraw={!isCompleted && isAssignedToMe}
              user={user}
              getFinalPayoutPrice={getFinalPayoutPrice}
            />
          }
        >
          <React.Fragment>
            {renderMap && !isCompleted && (
              <BookingDetailsItem
                icon={<Map />}
                block={true}
                label="Map"
                render={(componentClasses) => (
                  <div className={componentClasses.map}>
                    <GoogleMaps markers={isBefore2hours ? [googleMapsPoints[0]] : googleMapsPoints} showCard={!isBefore2hours} route={directions} />
                  </div>
                )}
              />
            )}
          </React.Fragment>
        </BookingDetailsLayout>
      )}
      {booking && (
        <Grid item xs={12} className={classes.cardMobile}>
          <Card raised style={{ width: "100%" }}>
            <CardContent>
              <Typography style={{ fontWeight: "bold", marginBottom: "15px" }}>
                {dayjs(booking.date).tz(booking.timeZone).format("MMM DD, YYYY  hh:mm A (ddd)")}
              </Typography>
              <div className={classes.detailsCardTitle}>Payout</div>
              {getFinalPayoutPrice()}
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", marginTop: 30, marginBottom: 30, columnGap: 15 }}>
                {booking?.status === "rejected" ? (
                  <CompleteButton fullWidth buttonTitle="Submit Again" onClick={handleTaskComplete} />
                ) : (
                  <>
                    <div>{!isCompleted && isAssignedToMe && <WithdrawButton onClick={handleWithdrawModal} />}</div>
                    <div>
                      <AcceptButton fullWidth onClick={handleTaskAccept} disabled={!user.uid} />
                      <CompleteButton onClick={handleTaskComplete} />
                      <WaitlistButton />
                    </div>
                  </>
                )}
              </div>
              <Button onClick={handleGoBack} fullWidth className={classes.backLinkButton}>
                Back to Tasks
              </Button>
            </CardContent>
          </Card>
        </Grid>
      )}
    </div>
  );
}

export default TaskDetails;
