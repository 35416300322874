import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect, useSelector } from "react-redux";

import { makeStyles, useMediaQuery } from "@material-ui/core";

import { applyFiltersMyBids } from "@helpers/bids";

import Paginations from "@shared/components/Paginations";
import NoData from "@shared/components/NoData";

import BidListCards from "./BidListCards";
import FilterBar from "../../MyTasksPage/components/FilterBar";

import { DefaultFilter, DefaultPagination } from "../constants";
import { bidsStatus } from "@constants/bids";
import { ClientTaskPageLayout } from "@shared/components/ClientTasksLayout";
import BidsListTable from "./BidsListTable";

const useStyles = makeStyles((theme) => ({
  mobilePagination: {
    display: "flex",
    height: "55px",
    boxShadow: "0px 4px 20px rgb(0 0 0 / 15%)",
    justifyContent: "center",
    alignItems: "center",
    bottom: 0,
    position: "sticky",
    border: "1px solid #e0e0e0",
    margin: theme.spacing(3, 0),
    backgroundColor: theme.palette.primary.contrastText,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  desktopPagination: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));
function MyBids() {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:984px)");
  const allBids = useSelector((state) => state.bookingBid.warriorBids) ?? [];
  const [activeStatus, setActiveStatus] = useState("pending");
  const [listStyle, setListStyle] = useState("list");
  const [taskListItems, setTaskListItems] = useState([]);
  const [paginationDetails, setPaginationDetails] = useState(DefaultPagination);
  const [headerSearch, setHeaderSearch] = useState("");

  const setPageNumber = (newPage) => setPaginationDetails({ ...paginationDetails, pageNumber: newPage });

  useEffect(() => {
    if (!allBids) return;
    applyFilter();
  }, [allBids?.length, activeStatus, paginationDetails.pageNumber]);

  const { pageNumber, pageSize } = paginationDetails;
  const visitedData = (pageNumber - 1) * pageSize;
  const slicedTasks = taskListItems?.slice(visitedData, visitedData + pageSize) || [];

  const applyFilter = (filters = { ...DefaultFilter }) => {
    const filteredBids = applyFiltersMyBids(allBids, { ...filters, activeStatus }).sort((a, b) => new Date(b.date) - new Date(a.date));
    setTaskListItems(filteredBids);
  };

  const tabs = [
    {
      value: "pending",
      label: bidsStatus.pending.label,
    },
    {
      value: "won",
      label: bidsStatus.won.label,
    },
    {
      value: "lost",
      label: bidsStatus.lost.label,
    },
    {
      value: "cancelled",
      label: bidsStatus.cancelled.label,
    },
  ];

  const layoutProps = {
    title: "My Bids",
    filterBar: (showMobileFilter, setShowMobileFilter) => (
      <FilterBar
        setView={setListStyle}
        applyFilter={applyFilter}
        headerSearch={headerSearch}
        showMobileFilter={showMobileFilter}
        setShowMobileFilter={setShowMobileFilter}
        placeholder="Search for a bid"
      />
    ),
    search: headerSearch,
    setSearch: setHeaderSearch,
    placeholder: "Search for a bid",
    tabs,
    activeStatus,
    setActiveStatus,
  };

  const activeData = bidsStatus[activeStatus === "active" ? "pending" : activeStatus]?.value;

  return (
    <ClientTaskPageLayout {...layoutProps}>
      {slicedTasks?.length ? (
        <>
          {/* {listStyle === "card" || isMobile ? (
            <BidListCards bidItems={slicedTasks} activeData={activeData} />
          ) : ( */}
          <BidsListTable taskListItems={slicedTasks} activeData={activeData} />
          {/* )} */}
          <div className={isMobile ? classes.mobilePagination : classes.desktopPagination}>
            <Paginations totalCount={taskListItems.length} setPageNumber={setPageNumber} {...paginationDetails} />
          </div>
        </>
      ) : (
        <NoData displayText="No Bids Found!" />
      )}
    </ClientTaskPageLayout>
  );
}

export default MyBids;
