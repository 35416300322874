import React from "react";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  startOverButton: {
    minWidth: theme.spacing(20),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
}));

const StartOverButton = ({ fullWidth, onClick }) => {
  const styles = useStyles();

  return (
    <Button color="primary" variant="outlined" fullWidth={fullWidth} className={styles.startOverButton} onClick={onClick}>
      Start Over
    </Button>
  );
};

export default StartOverButton;
