/* eslint-disable no-console */
import { geocodeByAddress } from "react-places-autocomplete";
import { getAddressFromComponents, getZip } from "@shared/utils/maps";
import { getTimeZone } from "@helpers/location";

export const addressNull = {
  location: "",
  coordinates: {},
  placeId: "",
  zipCode: "",
  street: "",
  city: "",
  state: "",
  county: "",
  timeZone: "",
};

export const defaultSFAddress = {
  city: "San Francisco",
  coordinates: {
    lat: 37.7749295,
    lng: -122.4194155,
  },
  county: "San Francisco County",
  location: "SF, CA, USA",
  placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  state: "California",
  street: "",
  timeZone: "America/Los_Angeles",
};

export const getFormattedAddress = async (dynamicAddress) => {
  try {
    const [result] = await geocodeByAddress(dynamicAddress);
    const { street, city, state, zip, county } = getAddressFromComponents(result.address_components);
    const placeId = result.place_id;
    const coordinates = {
      lat: result.geometry.location.lat(),
      lng: result.geometry.location.lng(),
    };
    const zipCode = zip || (await getZip(coordinates));
    const timeZone = getTimeZone(coordinates);

    return {
      location: result.formatted_address,
      coordinates,
      placeId,
      zipCode,
      city,
      street,
      state,
      county,
      timeZone,
    };
  } catch (error) {
    console.log("Error getFormattedAddress: ", error);
    return addressNull;
  }
};
