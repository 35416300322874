const configTypes = {
  FETCH_CONFIG: "FETCH_CONFIG",
  SET_CONFIG: "SET_CONFIG",
  RESET_CONFIG: "RESET_CONFIG",
  UPDATE_BUSINESS_HOURS: "UPDATE_BUSINESS_HOURS",
  SET_CONFIG_CACHED: "SET_CONFIG_CACHED",

  HIDE_STRIPE_DUE_TRIGGER: "HIDE_STRIPE_DUE_TRIGGER",

  SET_PENALTY_POINT_REASONS: "SET_PENALTY_POINT_REASONS",
};

export default configTypes;
