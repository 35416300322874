/* eslint-disable no-console */
import uiConstants from "@store/constants/uiTypes";
import { useAxiosPost } from "@axios";
import { uuid } from "../../shared/utils/generateUID";
import { sameDayBookingPrice } from "../../constants/booking";
import { checkFeatureFlag } from "./featureFlags";

export const setBookingsTab = (tab) => (dispatch) => {
  dispatch({ type: uiConstants.SET_BOOKINGS_TAB, payload: tab });
};

export const setAdminPage = (page) => (dispatch) => {
  dispatch({ type: uiConstants.SET_ADMIN_PAGE, payload: page });
};

export const setAdminTableState = (state) => (dispatch) => {
  dispatch({ type: uiConstants.SET_ADMIN_TABLE_STATE, payload: { ...state } });
};

export const setForceQueryTrigger = (state) => (dispatch) => {
  dispatch({ type: uiConstants.SET_FORCE_QUERY_TRIGGER, payload: state });
};

export const fetchUnreadModals = (subscribed, override) => async (dispatch, getState) => {
  try {
    const uid = getState()?.firebase?.profile?.uid;
    const initialized = getState().ui.modalsInitialized;
    if (!uid || initialized) return;
    dispatch({
      type: uiConstants.SET_MODALS_LOADING,
      payload: true,
    });
    const response = await useAxiosPost(`/modals/get-unread-modals/${uid}`, { override });
    if (!subscribed.current) return;
    dispatch({
      type: uiConstants.SET_MODALS,
      payload: response?.data?.data ?? [],
    });
    dispatch({
      type: uiConstants.SET_CURRENT_MODAL,
      payload: 0,
    });
    dispatch({
      type: uiConstants.SET_MODALS_LOADING,
      payload: false,
    });
    dispatch({
      type: uiConstants.SET_MODALS_OPEN,
      payload: response?.data?.data?.length > 0 ? true : false,
    });
  } catch (error) {
    dispatch({
      type: uiConstants.SET_MODALS_LOADING,
      payload: false,
    });
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const handleViewModal = (modalId) => async (_, getState) => {
  const uid = getState()?.firebase?.profile?.uid;
  if (!uid) return;
  try {
    await useAxiosPost("/modals/view-alert-modal", { uid, modalId });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const handleClickModal = (modalId) => async (_, getState) => {
  const uid = getState().firebase.profile.uid;
  try {
    await useAxiosPost("/modals/click-alert-modal", { uid, modalId });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const handleToggleModals = (value) => (dispatch) => {
  dispatch({ type: uiConstants.SET_MODALS_OPEN, payload: value });
};

export const setBookingSurgeVal = (val) => async (dispatch) => {
  dispatch({ type: uiConstants.SET_SURGE_VAL, payload: val });
};

export const setFetchedSurgeVal = (val) => async (dispatch) => {
  dispatch({ type: uiConstants.SET_FETCH_SURGE_VAL, payload: val });
};

export const setBookingPriceSurge = () => async (dispatch, getState) => {
  let surgingPrice = sameDayBookingPrice; // if ld fails
  const userId = getState().firebase?.profile?.uid ?? uuid();
  try {
    surgingPrice = await checkFeatureFlag("same-day-pricing-v2", { key: userId }, surgingPrice);
    if (isNaN(surgingPrice)) surgingPrice = sameDayBookingPrice; // any fails handled to default
    dispatch(setFetchedSurgeVal(surgingPrice));
  } catch (err) {
    console.log("Something went wring fetching feature flag", err);
  } finally {
    dispatch(setBookingSurgeVal(surgingPrice));
  }
};
