import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Grid, Hidden, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import { desktopSteps, getBookingDefaults, mobileSteps, removalTasks, rentalTasks } from "@booking/helpers/constants";
import { Frequency, Input, Step, Task } from "@booking/helpers/enums";
import AddressForm from "@booking/helpers/forms/Address";
import InitialForm from "@booking/helpers/forms/InitialForm";
import {
  addressFormFields,
  bidRemovalTaskDescriptionFormFields,
  bidRentalTaskDescriptionFormFields,
  bookFormFields,
  paymentFormFields,
  removalDateAndTimeFormFields,
  removalTaskDescriptionFormFields,
  rentalDateAndTimeFormFields,
  rentalTaskDescriptionFormFields,
  reviewOrderFormFields,
} from "../helpers/forms";
import PaymentInformationForm from "@booking/helpers/forms/PaymentInformation";
import RemovalDateAndTimeForm from "@booking/helpers/forms/Removal/DateAndTime";
import RemovalTaskDescriptionForm from "@booking/helpers/forms/Removal/TaskDescription";
import RemovalBidTaskDescriptionForm from "@booking/helpers/forms/Removal/BidTaskDescription";
import RentalDateAndTimeFormv2 from "@booking/helpers/forms/Rentalv2/DateAndTime";
import RentalTaskDescriptionFormv2 from "@booking/helpers/forms/Rentalv2/TaskDescription";
import BidRentalTaskDescriptionForm from "@booking/helpers/forms/Rentalv2/BidRentalTaskDescription";
import ReviewOrderForm from "@booking/helpers/forms/ReviewOrder";
import * as utils from "@booking/helpers/utils";
import { stepName, taskName, vehicleName } from "@booking/helpers/wordings";
import { isDev } from "@constants/environments";
import { getTimeZoneText } from "@helpers/location";
import history from "@history";
import pluralize from "@methods/pluralize";
import AuthPopup from "@shared/components/AuthPopup";
import Spacer from "@shared/components/Spacer";
import usePrevious from "@shared/hooks/usePrevious";
import { resetAddress } from "@store/actions/address";
import { resetSelectedBooking } from "@store/actions/booking";
import { resetSelectedCoupon, searchCoupon } from "@store/actions/coupon";
import tempDataConstants from "@store/constants/tempDataTypes";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { StringParam, useQueryParam } from "use-query-params";
import { sameDayBookingPrice, taskTypes } from "../../../constants/booking";
import { roles } from "../../../constants/roles";
import { abTest } from "../../../helpers/abTest";
import { segmentIdentify, segmentTrack } from "../../../helpers/segment";
import { scrollToTop } from "../../../methods/scrollToTop";
import { BookingPageContext } from "../context";
import Buttons from "./Buttons";
import BackButton from "./Buttons/BackButton";
import ContinueButton from "./Buttons/ContinueButton";
import StartOverButton from "./Buttons/StartOverButton";
import DoItAgainPreview from "./DoItAgainPreview";
import ExpansionPanel from "./ExpansionPanel";
import PriceQuote from "./PriceQuote";
import ProcessPayment from "./ProcessPayment";
import Progress from "./Progress";
import BookingDetails from "./summary/BookingDetailsMin";
import { ProcessBid } from "./ProcessBid";
import BidQuote from "./BidQuote";
import { setBookingPriceSurge, setBookingSurgeVal } from "../../../store/actions/ui";
import { isDaysAfter } from "../../../helpers/time";
import TrustBox from "../../../shared/components/TrustPilot";
import { Kustomer } from "../../../helpers/kustomer";
import { syncKustomerData } from "../../../store/actions/kustomer";

const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    margin: "50px auto",
    maxWidth: "1440px",
    padding: theme.spacing(0, 4, 2),
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row-reverse",
      alignItems: "flex-start",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0px 0",
      paddingBottom: "50px",
      width: "100%",
      padding: theme.spacing(0, 0, 2),
    },
    minHeight: "100vh",
  },
  sideBar: {
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(8),
      marginLeft: theme.spacing(3),
      width: theme.spacing(48),
      top: 0,
      position: "sticky",
    },
    [theme.breakpoints.down("sm")]: {
      top: "70px",
      position: "fixed",
      zIndex: 10,
      width: "100%",
    },
  },
  content: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(10),
      padding: theme.spacing(0, 2),
    },
    [theme.breakpoints.up("md")]: {
      flex: 1,
    },
  },
  task: {
    fontSize: 14,
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      fontSize: 22,
    },
  },
  title: {
    display: "flex",
  },
  step: {
    fontSize: 20,
    fontWeight: 800,
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      flex: 1,
      fontSize: 32,
    },
  },
  authpopLink: {
    textDecoration: "underline",
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontWeight: "700",
  },
  dumpsterSubheading: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
    },
  },
}));

const Booking = ({ defaultTask, defaultBusinessClient, repeat = false, isDefaultBid = false, sourceReferral = "" }) => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const styles = useStyles();
  const dispatch = useDispatch();

  // Store values
  const defaultAddress = useSelector((state) => state.address);
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  const selectedBooking = useSelector((state) => (repeat ? state.booking.selectedBooking : null));
  const { priceMultipliers, payoutPercent, pricingZone, fbLocationId } = useSelector((state) => state.serviceLocations.activeLocation);
  const selectedCoupon = useSelector((state) => state.coupon.selectedCoupon);
  const gclId = useSelector((state) => state.tracking.gclId);
  const { fetchedValue: sameDaySurgeFetched = sameDayBookingPrice, value: sameDaySurge = sameDayBookingPrice } = useSelector(
    (state) => state.ui.sameDaySurge
  );

  // Local values
  const [timeTracking, setTimeTracking] = useState({});
  const [showAuthPopup, setShowAuthPopup] = useState(false);
  const [currentStep, setCurrentStep] = useQueryParam("step", StringParam);
  const [activeSteps, setActiveSteps] = useState(!defaultAddress.location ? [Step.Initial] : [Step.Initial, Step.DateAndTime]);
  const [processPayment, setProcessPayment] = useState(false);
  const [processBid, setProcessBid] = useState(false);
  const [booking, setBooking] = useState({});
  const [segmentPayment, setSegmentPayment] = useState(false);
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false);
  const [isStartOver, setIsStartOver] = useState(false);
  const [isBidMode, setIsBidMode] = useState(isDefaultBid);

  const RentalDateAndTime = RentalDateAndTimeFormv2;
  const RentalTaskDescription = isBidMode ? BidRentalTaskDescriptionForm : RentalTaskDescriptionFormv2;

  const defaultSettings = {
    isDefaultBid,
  };

  const constructedDefaults = getBookingDefaults({ ...selectedBooking, [Input.BusinessClient]: defaultBusinessClient }, profile, defaultSettings);

  // Scroll to top on landing
  scrollToTop(currentStep);

  // Mount effect
  useEffect(() => {
    // Start the bookingStarted tracker
    setTimeTracking({ bookingStarted: dayjs().utc().format("YYYY-MM-DDTHH:mm:ss.SSSZ") });
    // fetch same day booking surge based on LD variant
    dispatch(setBookingPriceSurge());
    if (!repeat) dispatch(resetSelectedBooking());
    if (!currentStep) setCurrentStep(!defaultAddress.location ? Step.Initial : Step.DateAndTime);
    return () => {
      dispatch(resetSelectedCoupon());
      dispatch(resetAddress());
    };
  }, []);

  const [commonForm, setCommonForm] = useState({
    [Input.BusinessClient]: {
      value: constructedDefaults[Input.BusinessClient].value,
      validation: { isValid: typeof defaultBusinessClient === "boolean" },
    },
  });

  const [initialForm, setInitialForm] = useState({
    [Input.Task]: { value: defaultTask, validation: { isValid: !!defaultTask } },
    [Input.PickupAddress]: { value: defaultAddress, validation: { isValid: !!defaultAddress?.location } },
  });

  const [rentalDateAndTimeForm, setRentalDateAndTimeForm] = useState({
    [Input.Date]: constructedDefaults[Input.Date],
    [Input.Time]: constructedDefaults[Input.Time],
    [Input.Frequency]: constructedDefaults[Input.Frequency],
    [Input.Pickup]: constructedDefaults[Input.Pickup],
    [Input.PickupDate]: constructedDefaults[Input.PickupDate],
    [Input.PickupTime]: constructedDefaults[Input.PickupTime],
  });

  const [removalDateAndTimeForm, setRemovalDateAndTimeForm] = useState({
    [Input.Date]: constructedDefaults[Input.Date],
    [Input.Time]: constructedDefaults[Input.Time],
    [Input.Frequency]: constructedDefaults[Input.Frequency],
  });

  const [rentalTaskDescriptionForm, setRentalTaskDescriptionForm] = useState({
    [Input.Dumpster]: constructedDefaults[Input.Dumpster],
    [Input.Description]: constructedDefaults[Input.Description],
    [Input.UnderstandPricing]: constructedDefaults[Input.UnderstandPricing],
  });

  // Normal Version of the removalTaskDescriptionForm form
  const [removalTaskDescriptionForm, setRemovalTaskDescriptionForm] = useState({
    [Input.Vehicle]: constructedDefaults[Input.Vehicle],
    [Input.Junk]: constructedDefaults[Input.Junk],
    [Input.Description]: constructedDefaults[Input.Description],
    [Input.Stairs]: constructedDefaults[Input.Stairs],
    [Input.Dismantling]: constructedDefaults[Input.Dismantling],
  });

  // Bid Version of the removalTaskDescriptionForm form
  const [bidRemovalTaskDescriptionForm, setBidRemovalTaskDescriptionForm] = useState({
    [Input.Description]: constructedDefaults[Input.Description],
    [Input.Stairs]: constructedDefaults[Input.Stairs],
    [Input.Dismantling]: constructedDefaults[Input.Dismantling],
    [Input.TaskImages]: constructedDefaults[Input.TaskImages],
  });

  // Bid Version of the rentalTaskDescriptionForm form
  const [bidRentalTaskDescriptionForm, setBidRentalTaskDescriptionForm] = useState({
    [Input.TaskImages]: constructedDefaults[Input.TaskImages],
    [Input.Description]: constructedDefaults[Input.Description],
  });

  const [addressForm, setAddressForm] = useState({
    [Input.CompanyName]: constructedDefaults[Input.CompanyName],
    [Input.PickupAddress]: constructedDefaults[Input.PickupAddress],
    [Input.PickupAddressAdditional]: constructedDefaults[Input.PickupAddressAdditional],
    [Input.BusinessClient]: constructedDefaults[Input.BusinessClient],
  });

  const [reviewOrderForm, setReviewOrderForm] = useState({
    [Input.TermsOfService]: constructedDefaults[Input.TermsOfService],
  });

  const [paymentForm, setPaymentForm] = useState({
    [Input.PaymentFirstName]: constructedDefaults[Input.PaymentFirstName],
    [Input.PaymentLastName]: constructedDefaults[Input.PaymentLastName],
    [Input.PaymentPhone]: constructedDefaults[Input.PaymentPhone],
    [Input.PaymentEmail]: constructedDefaults[Input.PaymentEmail],
  });

  const task = initialForm[Input.Task]?.value;
  const businessClient = commonForm[Input.BusinessClient]?.value;
  const address = initialForm[Input.PickupAddress]?.value;

  // to get the active booking state and summary.
  const getActiveForm = (removal, rental) => ({ [removalTasks.includes(task)]: removal, [rentalTasks.includes(task)]: rental }.true);

  const dateAndTimeForm = getActiveForm(removalDateAndTimeForm, rentalDateAndTimeForm);
  const taskDescriptionForm = getActiveForm(removalTaskDescriptionForm, rentalTaskDescriptionForm);
  const bidTaskDescriptionForm = getActiveForm(bidRemovalTaskDescriptionForm, bidRentalTaskDescriptionForm);

  // active form fields
  const dateAndTimeFormFields = getActiveForm(removalDateAndTimeFormFields, rentalDateAndTimeFormFields);
  const taskDescriptionFormFields = getActiveForm(removalTaskDescriptionFormFields, rentalTaskDescriptionFormFields);
  const bidTaskDescriptionFormFields = getActiveForm(bidRemovalTaskDescriptionFormFields, bidRentalTaskDescriptionFormFields);

  // checking valid forms
  const isInitialFormValid = utils.isFormValid(initialForm, bookFormFields);
  const isDateAndTimeFormValid = utils.isFormValid(dateAndTimeForm, dateAndTimeFormFields);
  const isTaskDescriptionFormValid = utils.isFormValid(taskDescriptionForm, taskDescriptionFormFields);
  const isAddressFormValid = utils.isFormValid(addressForm, addressFormFields);
  const isReviewOrderFormValid = utils.isFormValid(reviewOrderForm, reviewOrderFormFields);
  const isPaymentFormValid = utils.isFormValid(paymentForm, paymentFormFields);

  // checking valid bid forms
  const isBidTaskDescriptionFormValid = utils.isFormValid(bidTaskDescriptionForm, bidTaskDescriptionFormFields);

  // bid vs regular
  const isDescriptionFormValid = isBidMode ? isBidTaskDescriptionFormValid : isTaskDescriptionFormValid;
  // progress bar value
  const progressValue =
    (isDesktop ? activeSteps.filter((item) => desktopSteps.includes(item)) : activeSteps.filter((item) => mobileSteps.includes(item))).length *
    (100 / (isDesktop ? desktopSteps : mobileSteps).length);

  const enableCheckoutButton = repeat
    ? isDateAndTimeFormValid && isPaymentFormValid
    : isInitialFormValid && isDateAndTimeFormValid && isDescriptionFormValid && isAddressFormValid && isReviewOrderFormValid && isPaymentFormValid;

  // handle form changes
  const handleInitialFormChange = useCallback((nextInitialform) => setInitialForm((initialForm) => ({ ...initialForm, ...nextInitialform })), []);
  const handleRentalDateAndTimeFormChange = useCallback((nextDateAndTimeForm) => setRentalDateAndTimeForm(nextDateAndTimeForm), []);
  const handleRemovalDateAndTimeFormChange = useCallback((nextDateAndTimeForm) => setRemovalDateAndTimeForm(nextDateAndTimeForm), []);
  const handleRentalTaskDescriptionFormChange = useCallback((nextTaskDescriptionForm) => setRentalTaskDescriptionForm(nextTaskDescriptionForm), []);
  const handleRemovalTaskDescriptionFormChange = useCallback((nextTaskDescriptionForm) => setRemovalTaskDescriptionForm(nextTaskDescriptionForm), []);
  const handleBidRemovalTaskDescriptionFormChange = useCallback(
    (nextTaskDescriptionForm) => setBidRemovalTaskDescriptionForm(nextTaskDescriptionForm),
    []
  );
  const handleBidRentalTaskDescriptionFormChange = useCallback(
    (nextTaskDescriptionForm) => setBidRentalTaskDescriptionForm(nextTaskDescriptionForm),
    []
  );
  const handleAddressFormChange = useCallback((nextAddressForm) => setAddressForm((addressForm) => ({ ...addressForm, ...nextAddressForm })), []);
  const handleReviewOrderFormChange = useCallback((nextReviewOrderForm) => setReviewOrderForm(nextReviewOrderForm), []);
  const handlePaymentFormChange = useCallback((nextPaymentForm) => setPaymentForm(nextPaymentForm), []);

  useEffect(() => {
    if (!processPayment) {
      setIsLoadingCheckout(false);
    }
  }, [processPayment]);

  const startCreateBid = async () => {
    setIsLoadingCheckout(true);
    const united = {
      ...dateAndTimeForm,
      ...bidTaskDescriptionForm,
      ...addressForm,
      ...reviewOrderForm,
      ...paymentForm,
    };
    const cleaned = {};
    for (const key in united) {
      cleaned[key] = united[key].value;
    }
    cleaned.priceMultipliers = priceMultipliers;
    cleaned.pricingZone = pricingZone;
    cleaned.payoutPercent = payoutPercent;
    cleaned.task = task;
    cleaned.businessClient = businessClient;
    if (cleaned.companyName) {
      cleaned.businessClient === true;
    }
    cleaned.customer_id = auth?.uid || null;
    cleaned.timeZone = address.timeZone;
    cleaned.repeat = false;
    cleaned.gclId = gclId;
    cleaned.fbLocationId = fbLocationId;
    cleaned.timeTracking = timeTracking;
    cleaned.sameDayBooking = false;
    cleaned.date = cleaned.date.tz(address.timeZone, true);
    delete cleaned?.time.isFull;
    delete cleaned[Input.BusinessClient];
    if (cleaned["pickup-date"]) {
      cleaned["pickup-date"] = cleaned["pickup-date"].tz(address.timeZone, true);
    }
    cleaned.sourceReferral = sourceReferral ?? "";
    isDev && console.log("calling createBookingBid", cleaned); // eslint-disable-line no-console
    setBooking(cleaned);
    setProcessBid(true);
  };

  const startCreateBooking = useCallback(async () => {
    setIsLoadingCheckout(true);
    const united = {
      ...dateAndTimeForm,
      ...taskDescriptionForm,
      ...addressForm,
      ...reviewOrderForm,
      ...paymentForm,
    };
    const cleaned = {};
    for (const key in united) {
      cleaned[key] = united[key].value;
    }
    cleaned.priceMultipliers = priceMultipliers;
    cleaned.pricingZone = pricingZone;
    cleaned.payoutPercent = payoutPercent;
    cleaned.task = task;
    cleaned.businessClient = businessClient;
    cleaned.customer_id = auth?.uid || null;
    cleaned.timeZone = address.timeZone;
    cleaned.coupon = selectedCoupon;
    cleaned.repeat = !!repeat;
    cleaned.isStartOver = isStartOver;
    cleaned.gclId = gclId;
    cleaned.fbLocationId = fbLocationId;
    cleaned.timeTracking = timeTracking;
    const ABTest = new abTest();
    const abTestRunning = null;
    if (abTestRunning) {
      cleaned.experimentId = ABTest.getABTest("Same Day Pricing")?.id ?? "";
      cleaned.experimentGroup = abTestRunning;
    }
    cleaned.sameDayBooking = dayjs().isSame(cleaned.date, "day");
    cleaned.bookingSurgeAmount = sameDaySurge;
    cleaned.date = cleaned.date.tz(address.timeZone, true);
    delete cleaned?.time.isFull;
    delete cleaned[Input.BusinessClient];
    if (cleaned["pickup-date"]) {
      cleaned["pickup-date"] = cleaned["pickup-date"].tz(address.timeZone, true);
    }
    if (repeat) {
      cleaned.total = selectedBooking.total;
    }
    if (profile.isRememberPaymentMethod) {
      cleaned.payment_method = profile.paymentMethod?.type || "ACH";
    }
    if (!cleaned.businessClient) {
      cleaned.companyName = "";
    }
    isDev && console.log("calling createBooking", cleaned); // eslint-disable-line no-console
    setBooking(cleaned);
    setProcessPayment(true);
  }, [
    dateAndTimeForm,
    taskDescriptionForm,
    addressForm,
    reviewOrderForm,
    paymentForm,
    priceMultipliers,
    payoutPercent,
    task,
    businessClient,
    auth?.uid,
    dispatch,
    selectedCoupon?.uid,
  ]);
  // taking the active summary (eg. summary of current service job)
  const summaryProps = {
    task,
    frequency: dateAndTimeForm[Input.Frequency]?.value,
    vehicle: taskDescriptionForm[Input.Vehicle]?.value,
    junk: taskDescriptionForm[Input.Junk]?.value,
    dumpster: taskDescriptionForm[Input.Dumpster]?.value,
    companyName: addressForm[Input.CompanyName]?.value,
    address: addressForm[Input.PickupAddress]?.value,
    apartment: addressForm[Input.PickupAddressAdditional]?.value,
    total: selectedBooking?.total || null,
    date: dateAndTimeForm[Input.Date]?.value,
    stairs: taskDescriptionForm[Input.Stairs]?.value,
    dismantling: taskDescriptionForm[Input.Dismantling]?.value,
    pickup: dateAndTimeForm[Input.Pickup]?.value,
    pickupDate: dateAndTimeForm[Input.PickupDate]?.value,
  };

  useEffect(() => {
    if (address.location) {
      segmentTrack("Booking Started", {
        timestamp: Date.now(),
      });
    }
    sessionStorage.setItem("phone_trials", 0);
    return () => {
      sessionStorage.setItem("phone_trials", 0);
    };
  }, []);

  // ab testing same day booking hook
  const dateObject = dateAndTimeForm[Input.Date]?.value;
  const isSameDay = dateObject && dayjs().isSame(dateObject, "day");
  const prevIsSameDay = usePrevious(isSameDay);

  useEffect(() => {
    if (task === Task.DumpsterRental) {
      dispatch(setBookingSurgeVal(0));
      return;
    }
    if (dateObject) {
      const priceSurge = sameDaySurgeFetched / 100;
      if (isSameDay) {
        segmentTrack("Choose Same Day Booking", { priceSurge });
      } else {
        dispatch(setBookingSurgeVal(0));
      }
      // if user chose same day booking and later changed it to some other date
      if (prevIsSameDay && !isSameDay) {
        segmentTrack("Cancel Same Day Booking", { priceSurge });
      }
    } else {
      dispatch(setBookingPriceSurge());
    }
  }, [isSameDay, task]);

  // Analytics hook
  useEffect(() => {
    const dateObject = dateAndTimeForm[Input.Date]?.value;
    const timeObject = dateAndTimeForm[Input.Time]?.value;
    switch (currentStep) {
      case Step.Initial:
        segmentTrack("Booking Started", {
          timestamp: Date.now(),
        });
        break;
      case Step.DateAndTime:
        segmentTrack("Service Selected", {
          name: task,
        });
        segmentTrack("Service Time Requested");
        break;
      case Step.TaskDescription:
        segmentTrack("Service Time Chosen", {
          date: dateObject ?? "",
          time: timeObject ?? "",
          is_recurring: dateAndTimeForm[Input.Frequency].value !== Frequency.Once,
        });
        segmentTrack("Vehicle size requested");
        segmentTrack("Drop down items requested");
        segmentTrack("Vehicle size chosen");
        break;
      case Step.Address: // Terms
        segmentTrack("Additional Info Entered");
        segmentTrack("Service Address Requested");
        segmentTrack("Terms Displayed");
        break;
      case Step.Payment:
        segmentTrack("Service Address Saved", {
          address: addressForm[Input.PickupAddress]?.value?.location,
          city: addressForm[Input.PickupAddress]?.value?.city,
          state: addressForm[Input.PickupAddress]?.value?.state,
          zip: addressForm[Input.PickupAddress]?.value?.zipCode,
        });
        segmentTrack("Terms Agreed");
        segmentTrack("Order Reviewed");
        break;
      default:
        break;
    }
  }, [currentStep]);

  const handleSegmentPayment = () => {
    segmentIdentify({
      firstName: paymentForm[Input.PaymentFirstName]?.value ?? "",
      lastName: paymentForm[Input.PaymentLastName]?.value ?? "",
      email: paymentForm[Input.PaymentEmail]?.value ?? "",
      phone: paymentForm[Input.PaymentPhone]?.value ?? "",
    });
    segmentTrack("Contact Information Filled", {
      firstName: paymentForm[Input.PaymentFirstName]?.value ?? "",
      lastName: paymentForm[Input.PaymentLastName]?.value ?? "",
      email: paymentForm[Input.PaymentEmail]?.value ?? "",
      phone: paymentForm[Input.PaymentPhone]?.value ?? "",
      isBusiness: businessClient ?? false,
      companyName: addressForm[Input.CompanyName]?.value ?? "",
    });
    Kustomer.describe({
      attributes: {
        emails: [paymentForm[Input.PaymentEmail]?.value],
        phones: [paymentForm[Input.PaymentPhone]?.value ?? ""],
      },
      customAttributes: {
        isBusinessClientBool: businessClient ?? false,
        companyNameStr: addressForm[Input.CompanyName]?.value ?? "",
        userTypeStr: "customer",
        marketStr: pricingZone,
      },
    }, () => syncKustomerData({
      id: paymentForm[Input.PaymentEmail]?.value,
      name: paymentForm[Input.PaymentFirstName]?.value + " " + paymentForm[Input.PaymentLastName]?.value,
    }));
    segmentTrack("Payment Started");
    setSegmentPayment(true);
  };

  // Trigger to set the form to Bid Mode
  const handleBidMode = (value) => {
    setIsBidMode(value);
  };

  useEffect(() => {
    if (processPayment && !segmentPayment) {
      handleSegmentPayment();
    }
  }, [processPayment]);

  const handleBack = useCallback(() => {
    const stepsMap = {
      [Step.DoItAgainPreview]: Step.DateAndTime,
      [Step.DateAndTime]: Step.Initial,
      [Step.TaskDescription]: Step.DateAndTime,
      [Step.Address]: Step.TaskDescription,
      [Step.ReviewOrder]: isDesktop ? Step.DateAndTime : Step.Address,
      [Step.Payment]: isDesktop ? Step.Address : repeat ? Step.DoItAgainPreview : Step.ReviewOrder,
    };
    setCurrentStep(stepsMap[currentStep]);
  }, [currentStep, isDesktop]);

  const handleStartOver = () => {
    history.replace(history.location.pathname);
    segmentTrack("Start Over Button Clicked");
    setIsStartOver(true);
    setCurrentStep(Step.DateAndTime);
  };

  const handleContinue = useCallback(() => {
    const addActiveStep = (stepToBeAdded) => {
      setActiveSteps(activeSteps.filter((step) => step !== stepToBeAdded).concat(stepToBeAdded));
    };

    const shouldShowAuthPopup = () => {
      if (dateAndTimeForm[Input.Frequency].value !== Frequency.Once && !auth?.uid) {
        // AUTH POPUP
        dispatch({
          type: tempDataConstants.SET_TEMP_USER,
          userType: roles.customer.value,
        });
        setShowAuthPopup(true);
        return true;
      }
      return false;
    };

    const continueStepAddress = () => {
      if (shouldShowAuthPopup()) return;
      else {
        if (isDesktop) {
          setCurrentStep(Step.Payment);
          addActiveStep(Step.Payment);
        } else {
          setCurrentStep(Step.ReviewOrder);
          addActiveStep(Step.ReviewOrder);
        }
      }
    };

    const continueStepPayment = async () => {
      if (shouldShowAuthPopup()) return;
      if (selectedCoupon) {
        const response = await dispatch(searchCoupon(selectedCoupon.couponCode, auth?.uid));
        if (!response?.data?.success) {
          setCurrentStep(Step.TaskDescription);
          return;
        }
      }
      if (isBidMode) {
        startCreateBid();
        segmentTrack("Request Bid Confirm Prompt");
      } else {
        startCreateBooking();
        segmentTrack("Credit card info filled (Stripe pop up)");
      }
    };

    const stepsMap = {
      [Step.Initial]: Step.DateAndTime,
      [Step.DateAndTime]: repeat && selectedBooking ? Step.DoItAgainPreview : Step.TaskDescription,
      [Step.DoItAgainPreview]: Step.Payment,
      [Step.TaskDescription]: Step.Address,
      [Step.Address]: continueStepAddress,
      [Step.Payment]: continueStepPayment,
      [Step.ReviewOrder]: Step.Payment,
    };

    //execute current step action
    const nextStep = stepsMap[currentStep];
    switch (typeof nextStep) {
      case "string": {
        if (Object.values(Step).includes(nextStep)) {
          setCurrentStep(nextStep);
          addActiveStep(nextStep);
        }
        break;
      }
      case "function":
        nextStep();
        break;
    }
  }, [currentStep, activeSteps, isDesktop, auth, repeat, selectedBooking, startCreateBooking, dispatch, dateAndTimeForm, selectedCoupon, isBidMode]);

  const handleInitialEdit = useCallback(() => setCurrentStep(Step.Initial), []);
  const handleDateAndTimeEdit = useCallback(() => setCurrentStep(Step.DateAndTime), []);
  const handleDoItAgainPreview = useCallback(() => setCurrentStep(Step.DoItAgainPreview), []);
  const handleTaskDescriptionEdit = useCallback(() => setCurrentStep(Step.TaskDescription), []);
  const handleAddressEdit = useCallback(() => setCurrentStep(Step.Address), []);
  const handleReviewOrderEdit = useCallback(() => setCurrentStep(Step.ReviewOrder), []);
  const handlePaymentEdit = useCallback(() => setCurrentStep(Step.Payment), []);

  const refs = {
    initial: useRef(null),
    dateAndTime: useRef(null),
    taskDescription: useRef(null),
    bidTaskDescription: useRef(null),
    address: useRef(null),
    reviewOrder: useRef(null),
    payment: useRef(null),
  };

  const validateForm = (valid, isBidMode) => () => {
    const currentForm = {
      [Step.Initial]: refs.initial,
      [Step.DateAndTime]: refs.dateAndTime,
      [Step.TaskDescription]: isBidMode ? refs.bidTaskDescription : refs.taskDescription,
      [Step.Address]: refs.address,
      [Step.ReviewOrder]: refs.reviewOrder,
      [Step.Payment]: refs.payment,
    }[currentStep];

    currentForm?.current?.trigger?.();

    /* 
      manual trigger for tos_checked only for desktop
      [Step.ReviewOrder] only comes in mobile view and for desktop view
      tos_checked comes with [Step.Address] form.
     */

    if (currentStep === Step.Address) {
      refs.reviewOrder.current?.trigger?.();
    }

    valid && handleContinue();
  };

  const buttons = (renderBackButton, invalid, continueButtonLabel) => (
    <Buttons
      renderBackButton={renderBackButton}
      onBack={handleBack}
      onContinue={validateForm(!invalid)}
      continueButtonLabel={continueButtonLabel}
      loading={isLoadingCheckout}
    />
  );

  const dateAndTime = getActiveForm(
    <RemovalDateAndTimeForm
      ref={refs.dateAndTime}
      defaultValue={removalDateAndTimeForm}
      onChange={handleRemovalDateAndTimeFormChange}
      timeZone={getTimeZoneText(address.coordinates)}
      isDefaultBid={isDefaultBid}
    />,
    <RentalDateAndTime
      ref={refs.dateAndTime}
      defaultValue={rentalDateAndTimeForm}
      onChange={handleRentalDateAndTimeFormChange}
      timeZone={getTimeZoneText(address.coordinates)}
    />
  );

  const taskDescription = isBidMode
    ? getActiveForm(
        <RemovalBidTaskDescriptionForm
          ref={refs.taskDescription}
          task={task}
          defaultValue={bidRemovalTaskDescriptionForm}
          onChange={handleBidRemovalTaskDescriptionFormChange}
          setIsBidMode={handleBidMode}
          isBidPromptValid={isDaysAfter(dateObject, 2)}
          isDefaultBid={isDefaultBid}
        />,
        <BidRentalTaskDescriptionForm
          ref={refs.taskDescription}
          defaultValue={bidRentalTaskDescriptionForm}
          onChange={handleBidRentalTaskDescriptionFormChange}
          setIsBidMode={handleBidMode}
          isDefaultBid={isDefaultBid}
        />
      )
    : getActiveForm(
        <RemovalTaskDescriptionForm
          ref={refs.taskDescription}
          task={task}
          defaultValue={removalTaskDescriptionForm}
          onChange={handleRemovalTaskDescriptionFormChange}
          setIsBidMode={handleBidMode}
          isBidPromptValid={isDaysAfter(dateObject, 2)}
          isDefaultBid={isDefaultBid}
        />,
        <RentalTaskDescription
          ref={refs.taskDescription}
          pickupForm={rentalDateAndTimeForm}
          defaultValue={rentalTaskDescriptionForm}
          onChange={handleRentalTaskDescriptionFormChange}
          setIsBidMode={handleBidMode}
          isDefaultBid={isDefaultBid}
        />
      );

  const renderAddressForm = <AddressForm ref={refs.address} task={task} defaultValue={addressForm} onChange={handleAddressFormChange} />;

  const reviewOrder = (expandPolicy, showSummary, showPolicy) => (
    <ReviewOrderForm
      ref={refs.reviewOrder}
      config={{
        expandPolicy,
        showSummary,
        showPolicy,
        isBidMode,
      }}
      defaultValue={reviewOrderForm}
      onChange={handleReviewOrderFormChange}
      summaryProps={summaryProps}
    />
  );

  const handleFrequencyChange = () => {
    if (isDesktop) {
      refs.dateAndTime.current?.set(Frequency.Once);
    } else {
      getActiveForm(
        setRemovalDateAndTimeForm,
        setRentalDateAndTimeForm
      )?.((pF) => ({ ...pF, [Input.Frequency]: { ...pF[Input.Frequency], value: Frequency.Once } }));
    }
  };

  // expansion panel description
  const initialDescription = taskName[task];
  const dateAndTimeDescription = dayjs(dateAndTimeForm[Input.Date]?.value).format("MMM DD YYYY, h:mm A.");
  const taskDescriptionDescription =
    (taskDescriptionForm[Input.Vehicle]
      ? vehicleName[taskDescriptionForm[Input.Vehicle]?.value] + ", " + pluralize(taskDescriptionForm[Input.Junk]?.value?.length, "item", "selected")
      : pluralize(taskDescriptionForm[Input.Dumpster]?.value.length, "dumpster", "selected")) +
    ", " +
    (selectedCoupon?.couponCode ?? "no ") +
    "coupon code";
  const reviewOrderDescription = addressForm[Input.PickupAddress]?.value?.location;

  return (
    <BookingPageContext.Provider value={{ initialForm, commonForm, setCommonForm }}>
      {showAuthPopup && (
        <AuthPopup
          onClose={() => setShowAuthPopup(false)}
          context={
            <Box style={{ textAlign: "center" }}>
              You are booking a recurring service with us! To keep track of all of your bookings, please create an account. <br />
              Didn&apos;t mean to book a recurring service? Switch to{" "}
              <span
                className={styles.authpopLink}
                onClick={() => {
                  setCurrentStep(Step.DateAndTime);
                  setShowAuthPopup(false);
                  handleFrequencyChange();
                }}
              >
                Once
              </span>
              .
            </Box>
          }
        />
      )}
      <Box>
        <Box className={styles.root}>
          <Box className={styles.sideBar}>
            <Progress value={progressValue} />
            {isBidMode && (
              <Hidden smDown>
                <BidQuote enableCheckoutButton={enableCheckoutButton} onClick={handleContinue} loading={isLoadingCheckout} />
              </Hidden>
            )}
            {!isBidMode && (
              <PriceQuote
                enableCheckoutButton={enableCheckoutButton}
                onClick={handleContinue}
                loading={isLoadingCheckout}
                summaryProps={summaryProps}
              />
            )}
            <Hidden smDown>
              <div style={{ padding: "24px 0px" }}>
                <TrustBox style="micro-button" />
              </div>
            </Hidden>
          </Box>
          <Box className={styles.content}>
            <Typography className={styles.task}>{taskName[task]}</Typography>
            {task === taskTypes["dumpster-rental"].value && (
              <Typography className={styles.dumpsterSubheading} variant="body2">
                Either a Dumpster or a trailer will be delivered based on the availability of our providers.
              </Typography>
            )}
            <Box className={styles.title}>
              <Typography className={styles.step}>{stepName(isDefaultBid)[currentStep]}</Typography>
              <Hidden smDown>
                {currentStep !== Step.DateAndTime ? (
                  repeat && selectedBooking ? (
                    <StartOverButton onClick={handleStartOver} />
                  ) : (
                    <BackButton onClick={handleBack} />
                  )
                ) : null}
              </Hidden>
            </Box>
            <ExpansionPanel
              title={isDefaultBid ? "Submit a Quote Request" : "What are you looking for?"}
              description={initialDescription}
              expanded={currentStep === Step.Initial}
              active={activeSteps.includes(Step.Initial)}
              onEdit={handleInitialEdit}
            >
              <InitialForm ref={refs.initial} defaultValue={initialForm} onChange={handleInitialFormChange} isBidMode={isBidMode} />
              {buttons(false, !isInitialFormValid)}
            </ExpansionPanel>
            <ExpansionPanel
              title="Date And Time"
              description={dateAndTimeDescription}
              expanded={currentStep === Step.DateAndTime}
              active={activeSteps.includes(Step.DateAndTime)}
              disabled={!isInitialFormValid}
              onEdit={handleDateAndTimeEdit}
            >
              {dateAndTime}
              {buttons(true, !isDateAndTimeFormValid)}
            </ExpansionPanel>
            {repeat && selectedBooking ? (
              <ExpansionPanel
                title="Booking Preview"
                expanded={currentStep === Step.DoItAgainPreview}
                active={activeSteps.includes(Step.DoItAgainPreview)}
                disabled={!isDateAndTimeFormValid}
                onEdit={handleDoItAgainPreview}
              >
                <DoItAgainPreview booking={selectedBooking} />
                <Grid container alignContent="space-between" spacing={3}>
                  <Grid item xs={6}>
                    <Hidden mdUp>
                      <StartOverButton onClick={handleStartOver} />
                    </Hidden>
                  </Grid>
                  <Grid item xs={6}>
                    <ContinueButton onClick={handleContinue} label="Confirm" />
                  </Grid>
                </Grid>
              </ExpansionPanel>
            ) : (
              <>
                <ExpansionPanel
                  title="Task Description"
                  description={taskDescriptionDescription}
                  expanded={currentStep === Step.TaskDescription}
                  active={activeSteps.includes(Step.TaskDescription)}
                  disabled={!isDateAndTimeFormValid}
                  onEdit={handleTaskDescriptionEdit}
                >
                  {taskDescription}
                  {buttons(true, !isDescriptionFormValid)}
                </ExpansionPanel>
                <Hidden smDown>
                  <ExpansionPanel
                    title="Review your order"
                    description={reviewOrderDescription}
                    expanded={currentStep === Step.Address || currentStep === Step.ReviewOrder}
                    active={activeSteps.includes(Step.Address) || activeSteps.includes(Step.ReviewOrder)}
                    disabled={!isDescriptionFormValid}
                    onEdit={handleAddressEdit}
                  >
                    {renderAddressForm}
                    <Spacer x={40} />
                    <BookingDetails {...summaryProps} />
                    {reviewOrder(true)}
                    {buttons(true, !(isAddressFormValid && isReviewOrderFormValid))}
                  </ExpansionPanel>
                </Hidden>
                <Hidden mdUp>
                  <ExpansionPanel expanded={currentStep === Step.Address} active={activeSteps.includes(Step.Address)} onEdit={handleAddressEdit}>
                    {renderAddressForm}
                    {reviewOrder(false, false)}
                    {buttons(true, !(isAddressFormValid && isReviewOrderFormValid))}
                  </ExpansionPanel>
                  <ExpansionPanel
                    expanded={currentStep === Step.ReviewOrder}
                    active={activeSteps.includes(Step.ReviewOrder)}
                    onEdit={handleReviewOrderEdit}
                  >
                    {reviewOrder(false, true, false)}
                    {buttons(true, !isReviewOrderFormValid)}
                  </ExpansionPanel>
                </Hidden>
              </>
            )}
            <ExpansionPanel
              title="Payment"
              expanded={currentStep === Step.Payment}
              active={activeSteps.includes(Step.Payment)}
              disabled={
                repeat
                  ? !isDateAndTimeFormValid
                  : !(isDateAndTimeFormValid && isTaskDescriptionFormValid && isAddressFormValid && isReviewOrderFormValid)
              }
              onEdit={handlePaymentEdit}
            >
              <PaymentInformationForm ref={refs.payment} defaultValue={paymentForm} onChange={handlePaymentFormChange} isBidMode={isBidMode} />
              {buttons(true, !enableCheckoutButton, isBidMode ? "Submit" : "Checkout")}
            </ExpansionPanel>
            {processPayment ? (
              <ElementsConsumer>
                {({ stripe, elements }) => (
                  <ProcessPayment
                    stripe={stripe}
                    elements={elements}
                    open={processPayment}
                    paymentForm={paymentForm}
                    handleClose={setProcessPayment}
                    dateAndTimeForm={dateAndTimeForm}
                    bookingData={booking}
                    summaryProps={summaryProps}
                    selectedCoupon={selectedCoupon}
                  />
                )}
              </ElementsConsumer>
            ) : null}
            {/* <Hidden mdUp>
              <TrustBox style="micro-button" styles={{ marginLeft: "auto", display: "block", marginTop: 50 }} />
            </Hidden> */}
            <ProcessBid open={processBid} handleClose={setProcessBid} bookingData={booking} />
          </Box>
        </Box>
      </Box>
    </BookingPageContext.Provider>
  );
};

Booking.defaultProps = {
  defaultTask: Task.JunkRemoval,
  defaultBusinessClient: false,
};

export default Booking;
