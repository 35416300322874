import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  field: {
    marginBottom: theme.spacing(2),
  },
  note: {
    color: "grey",
    fontSize: 12,
    marginBottom: 20,
  },
  mobileHelperText: {
    fontSize: 12,
    opacity: 0.6,
    marginBottom: 15,
  },
  linkBtn: {
    cursor: "pointer",
    textDecoration: "underline",
    color: "grey",
    fontWeight: 500,
    fontSize: 14,
    whiteSpace: "nowrap",
    margin: theme.spacing(0, 0.5),
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  addAnotherLink: {
    fontSize: 17,
    fontWeight: 500,
    textAlign: "center",
    padding: theme.spacing(2, 0),
    cursor: "pointer",
  },
  tooManyItemsButton: {
    fontWeight: 500,
    fontSize: "0.9rem",
  },
  tooManyItemsLauncher: {
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
