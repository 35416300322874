import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    "& p": {
      color: theme.palette.primary.main,
      fontWeight: 600,
      [theme.breakpoints.up("md")]: {
        fontSize: 16,
      },
    },
  },
}));

export const NextDayDiscount = ({ date }) => {
  const sameDaySurge = useSelector((state) => state.ui.sameDaySurge.fetchedValue);

  const nextDayDiscountText = "Booking tomorrow or later";
  const classes = useStyles();
  const discountAmount = sameDaySurge / 100;
  const discountTag = () => (
    <Box className={classes.root}>
      <Typography>{nextDayDiscountText}</Typography>
      <Typography>- ${discountAmount.toFixed(2)}</Typography>
    </Box>
  );

  const showDiscount = date && !dayjs().isSame(date, "day");

  if (!showDiscount) {
    return null;
  }

  return discountTag();
};
