import React, { useState, forwardRef } from "react";
import { makeStyles } from "@material-ui/core";

import { Input } from "@booking/helpers/enums";
import DateTimeField from "@booking/helpers/fields/DateTimeField";
import PickupFieldv2 from "@booking/helpers/fields/PickupFieldv2";
import FrequencyField from "@booking/helpers/fields/FrequencyField";
import Section from "@booking/components/Section";
import { updateField, calculatePickupDate, calculatePickupTime } from "@booking/helpers/utils";

import useMountedEffect from "@shared/hooks/useMountedEffect";
import useTriggerValidation from "@shared/hooks/useTriggerValidation";

const useStyles = makeStyles((theme) => ({
  container: {
    "& > div:first-child": {
      marginBottom: "40px",
    },
  },
  frequencyField: {
    width: "60%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

const DateAndTimeForm = forwardRef(({ defaultValue, onChange }, ref) => {
  const classes = useStyles();
  const [dateField, setDatefield] = useState(defaultValue[Input.Date]);
  const [timeField, setTimeField] = useState(defaultValue[Input.Time]);
  const [pickupField, setPickupField] = useState(defaultValue[Input.Pickup]);
  const [pickupDateField, setPickupDateField] = useState(defaultValue[Input.PickupDate]);
  const [pickupTimeField, setPickupTimeField] = useState(defaultValue[Input.PickupTime]);
  const [frequencyField, setFrequencyField] = useState(defaultValue[Input.Frequency]);

  useMountedEffect(() => {
    onChange({
      [Input.Date]: dateField,
      [Input.Time]: timeField,
      [Input.Pickup]: pickupField,
      [Input.PickupDate]: calculatePickupDate(pickupField, pickupDateField, dateField),
      [Input.PickupTime]: calculatePickupTime(pickupField, pickupTimeField, timeField),
      [Input.Frequency]: frequencyField,
    });
  }, [dateField, timeField, pickupField, pickupDateField, pickupTimeField, frequencyField]);

  useTriggerValidation(
    ref,
    [setDatefield, setTimeField, setPickupField, setPickupDateField, setPickupTimeField, setFrequencyField],
    setFrequencyField
  );

  const handleDateChange = (field) => {
    setDatefield(updateField(dateField, field));
  };

  const handleTimeChange = (field) => {
    setTimeField(updateField(timeField, field));
  };

  const handlePickupChange = (value) => {
    setPickupField(updateField(pickupField, value));
  };

  const handlePickupDateChange = (value) => {
    setPickupDateField(updateField(pickupDateField, value));
  };

  const handlePickupTimeChange = (value) => {
    setPickupTimeField(updateField(pickupTimeField, value));
  };

  const handleFrequencyChange = (value) => {
    setFrequencyField(updateField(frequencyField, value));
  };

  return (
    <Section title="Drop off date">
      <div className={classes.container}>
        <DateTimeField
          dateField={dateField}
          timeField={timeField}
          onDateChange={handleDateChange}
          onTimeChange={handleTimeChange}
          showDateRangeOnly
          isRental
          overRideTimeRange={72}
        />
        <PickupFieldv2 field={pickupField} selectedDate={dateField.value} onChange={handlePickupChange}>
          <DateTimeField
            dateField={pickupDateField}
            timeField={pickupTimeField}
            onDateChange={handlePickupDateChange}
            onTimeChange={handlePickupTimeChange}
            isRental
            overRideTimeRange={72}
            minDate={dateField.value}
          />
        </PickupFieldv2>
        <div className={classes.frequencyField}>
          <FrequencyField field={frequencyField} onChange={handleFrequencyChange} />
        </div>
      </div>
    </Section>
  );
});

export default React.memo(DateAndTimeForm);
