import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Button, Card, CardContent, CircularProgress, Grid, Typography } from "@material-ui/core";
import history from "@history";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import dropOffIcon from "@assets/drop-off.svg";
import filterIcon from "@assets/filter-icon.svg";
import locationPinIcon from "@assets/location-pin-icon.svg";
import serviceTypeIcon from "@assets/service-type.svg";
import NoDataImage1 from "@assets/nodata/warrior_no_data_group1.png";
import NoDataImage2 from "@assets/nodata/warrior_no_data_group2.png";
import Spacer from "@shared/components/Spacer";
import useLocalStorage from "@shared/hooks/useLocalStorage";
import { haversineDistance } from "@shared/utils/haversine";
import { updateProfile } from "@store/actions/users";
import isEqual from "../../../methods/isEqual";
import FilterCard from "../../Tasks/GetTasksPage/components/FilterCard";
import FilterCardGroup from "../../Tasks/GetTasksPage/components/FilterCardGroup";
import BidCard from "./BidCard";
import { fetchAvailableBookingBids } from "../../../store/actions/bookingBids";
import NoDataWarrior from "../../../shared/components/NoDataWarrior";
import { getlocalStorage } from "../../../shared/hooks/useLocalStorage";

const ServiceTypes = [
  { label: "Cardboard Removal", value: true, id: "cardboard-removal" },
  { label: "Waste Removal", value: true, id: "junk-removal" },
  { label: "Dumpster Rental", value: true, id: "dumpster-rental" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 0),
    },
  },
  header: {
    margin: theme.spacing(4, 2, 2, 2),
    display: "flex",
  },
  headerTitle: {
    display: "flex",
    flexGrow: 1,
    [theme.breakpoints.only("sm")]: {
      marginLeft: theme.spacing(3),
    },
  },
  backBtn: {
    height: "30px",
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.hover,
    },
    cursor: "pointer",
  },
  backBtnText: {
    fontSize: "22px",
    fontWeight: "bold",
    cursor: "pointer",
  },
  cardsContainer: {
    flexGrow: 1,
    padding: theme.spacing(2, 3),
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  contentContainer: {
    display: "flex",
    justifyItems: "center",
    padding: theme.spacing(2, 3),
    minHeight: "500px",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#fff",
      padding: theme.spacing(1),
    },
  },
  filterBar: {
    display: "flex",
    flexDirection: "column",
    minWidth: "20%",
    padding: theme.spacing(2, 3),
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  cardMobile: {
    position: "sticky",
    bottom: 0,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  filterIconContainer: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  filterIcon: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  noJobsDescription: {
    textAlign: "center",
    margin: "40px 0",
  },
  titleIcon: {
    marginRight: theme.spacing(1),
  },
  loader: { textAlign: "center", margin: "50px 0" },
  listContainer: {
    display: "grid",
    gap: theme.spacing(3),
    gridTemplateColumns: "repeat(auto-fill, minmax(340px, 1fr) )",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "auto",
    },
  },
}));

function PageLoader() {
  const classes = useStyles();
  return (
    <div className={classes.loader}>
      <CircularProgress />
      <Spacer />
      <Typography color="primary">Loading bids...</Typography>
    </div>
  );
}

const BidOnProjects = () => {
  const classes = useStyles();
  const profile = useSelector((state) => state.firebase.profile);
  const notificationSettings = useSelector((state) => state.firebase.profile?.alerts);
  const bids = useSelector((state) => state.bookingBid.bids);
  const dispatch = useDispatch();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const serviceLocations = useSelector((state) => state.serviceLocations.locations);
  const savedLocations = profile?.savedPreferences?.locations;
  const savedServices = profile?.savedPreferences?.services;
  const defaultServiceTypes =
    savedServices?.length > 0 ? ServiceTypes.map((item) => ({ ...item, value: savedServices.includes(item.id) })) : ServiceTypes;

  const defaultDropOffDistances = [
    { label: "Less than 200 miles", value: "less_than_200" },
    { label: "200-400 miles", value: "200_to_400" },
    { label: "More tan 400 miles", value: "more_than_400" },
  ];

  // states
  const [services, setServices] = useLocalStorage("services", defaultServiceTypes);
  const [locations, setLocations] = useLocalStorage("locations", []);
  const [dropOffDistance, setDropOffDistance] = useState(defaultDropOffDistances);
  const [showFilterOnly, setShowFilterOnly] = useState(false);

  const applyFilters = (bidList) => {
    if (!bidList) return [];
    return bidList.filter((item) => {
      const filteredServices = services.filter((service) => {
        return !service.value && item.task?.toLowerCase() === service?.id?.toLowerCase();
      });
      if (filteredServices.length > 0) {
        return false; //We're currently filtering this item out
      }

      let hasNoLocationFilter = true;
      locations.forEach((location) => {
        const calculatedDistance = haversineDistance(item?.address.coordinates, location.coordinates);
        if ((!location.limits || (location.limits && calculatedDistance <= location.limits)) && location.value) {
          hasNoLocationFilter = false;
        }
      });
      return !hasNoLocationFilter;
    });
  };

  const handleGoBack = () => {
    if (isMobile && showFilterOnly) {
      setShowFilterOnly(false);
    } else history.goBack();
  };

  useEffect(() => {
    const activeLocations = serviceLocations.map((item) => {
      const id = item.address.placeId;
      let selected = false;
      if (savedLocations && savedLocations?.includes(id)) {
        selected = true;
      }
      return {
        id,
        value: selected,
        label: item.address.city,
        group: item.address.state,
        coordinates: item.center?.coordinates ?? item.address.coordinates,
        limits: 200,
      };
    });
    // sort alphabetically (a-z)
    activeLocations.sort((a, b) => (a.label > b.label) - (a.label < b.label)).sort((x, y) => y.value - x.value);
    setLocations(activeLocations);
  }, [serviceLocations]);

  const init = () => {
    const localServices = getlocalStorage.read("services");
    if (localServices?.length && !localServices[0]?.id) {
      getlocalStorage.remove("services");
    }
    dispatch(fetchAvailableBookingBids());
  };

  const getActiveIds = (arr) =>
    arr.reduce((ids, i) => {
      if (i.value) ids.push(i.id);
      return ids;
    }, []);

  const handleServicesChange = async (values) => {
    setServices(values);
    const ids = getActiveIds(values);
    await dispatch(updateProfile({ savedPreferences: { services: ids } }, null, false, false));
  };

  const handleLocationsChange = async (values) => {
    setLocations(values);
    const ids = getActiveIds(values);
    await dispatch(updateProfile({ savedPreferences: { locations: ids } }, null, false, false));
  };

  useEffect(() => {
    init();
  }, []);

  const BidItemsList = () => {
    const filteredBids = applyFilters(bids);
    return filteredBids.length ? (
      <div className={classes.listContainer}>
        {filteredBids.map((bid) => (
          <BidCard bid={bid} key={`bidtask-${bid.id}`} />
        ))}
      </div>
    ) : !filteredBids.length && !notificationSettings?.length ? (
      <NoDataWarrior
        bannerImage={NoDataImage1}
        displayText="No available projects right now"
        subText="Turn on your On Demand notifications and be the first to know when a new project is available"
        buttonText="Update Notifications"
        buttonLink="/alerts"
      />
    ) : (
      !filteredBids.length &&
      notificationSettings?.length && (
        <NoDataWarrior
          bannerImage={NoDataImage2}
          displayText="No available projects right now"
          subText="Don't worry - check back soon or expand your location range to see additional projects"
        />
      )
    );
  };

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.headerTitle}>
            <KeyboardBackspaceIcon className={classes.backBtn} fontSize="large" onClick={handleGoBack} />
            <div onClick={() => history.push("/provider_portal")}>
              <Typography className={classes.backBtnText}>{isMobile && showFilterOnly ? "Filter" : "Bid on projects"}</Typography>
            </div>
          </div>
          {!showFilterOnly && ( // css check for mobile view is there
            <div className={classes.filterIconContainer}>
              <img src={filterIcon} alt="filterIcon" className={classes.filterIcon} onClick={() => setShowFilterOnly(true)} />
            </div>
          )}
        </div>
        {isMobile && showFilterOnly && <Typography style={{ marginLeft: "30px" }}>Select the options from below</Typography>}
        <div className={classes.contentContainer}>
          {(!isMobile || (isMobile && showFilterOnly)) && (
            <div className={classes.filterBar}>
              <FilterCard
                key="serviceTypeFilter"
                items={services}
                title="Service Type"
                TitleIcon={<img src={serviceTypeIcon} alt="serviceTypeIcon" className={classes.titleIcon} />}
                onChange={handleServicesChange}
              />
              <FilterCardGroup
                key="locationTypeFilter"
                items={locations}
                title="Location"
                TitleIcon={<img src={locationPinIcon} alt="locationPinIcon" className={classes.titleIcon} />}
                isCollapsable
                showMorelabel="More locations"
                onChange={handleLocationsChange}
              />
              <FilterCard
                key="dropOffDistanceFilter"
                items={dropOffDistance}
                title="Drop Off Distance"
                TitleIcon={<img src={dropOffIcon} alt="dropOffIcon" className={classes.titleIcon} />}
                onChange={(values) => setDropOffDistance([...values])}
              />
            </div>
          )}
          {(!showFilterOnly || !isMobile) && <div className={classes.cardsContainer}>{bids ? <BidItemsList /> : <PageLoader />}</div>}
        </div>
        {/* css check for mobile view is there */}
        {showFilterOnly && (
          <Grid item xs={12} className={classes.cardMobile}>
            <Card raised style={{ width: "100%" }}>
              <CardContent style={{ display: "flex" }}>
                <Button variant="contained" color="primary" fullWidth onClick={() => setShowFilterOnly(false)}>
                  Apply filters
                </Button>
              </CardContent>
            </Card>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default BidOnProjects;
