import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    boxSizing: "border-box",
    minHeight: "100vh",
  },
  root: {
    maxWidth: 1000,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    padding: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    "& h1": {
      fontSize: "2.5rem",
      color: theme.palette.primary.main,
      fontWeight: 500,
      padding: theme.spacing(2, 0),
    },
    "& h2": {
      color: "#575760",
      fontSize: "2.0rem",
      fontWeight: 400,
      padding: theme.spacing(2, 0),
    },
    "& h3": {
      color: "#575760",
      fontSize: "1.5rem",
      fontWeight: 400,
      margin: 0,
      padding: theme.spacing(2, 0),
    },
    "& h4": {
      color: "#575760",
      fontSize: "1.4rem",
      fontWeight: 400,
      margin: 0,
      padding: theme.spacing(2, 0),
    },
    "& h5": {
      color: "#575760",
      fontSize: "1.2rem",
      fontWeight: 400,
      margin: 0,
    },
    "& p": {
      color: "#575760",
      lineHeight: "1.9rem",
      fontSize: "1.05rem",
    },
    "& .wp-block-button, .action-button, .action-link-button": {
      background: theme.palette.primary.main,
      padding: theme.spacing(2),
      borderRadius: "100px",
      display: "inline-block",
      width: "max-content",
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
      border: "none",
      color: "#fff",
      "&:hover": {
        cursor: "pointer",
        background: theme.palette.primary.dark,
      },
      "&:active": {
        opacity: 0.5,
      },
      "& a": {
        color: "#fff",
        textDecoration: "none",
      },
    },
    "& ol": {
      margin: theme.spacing(1, 0),
    },
    "& ul": {
      margin: theme.spacing(5, 0),
    },
    "& li": {
      margin: theme.spacing(1, 0),
      fontSize: "1.05rem",
      color: "#575760",
    },
    "& img": {
      maxWidth: "100%",
      maxHeight: 350,
      objectFit: "cover",
      borderRadius: 4,
    },
    "& .wp-block-image": {
      "& .aligncenter": {
        "& img": {
          display: "block",
          margin: theme.spacing(5, 0),
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "100%",
        },
      },
    },
    "& .are-vertically-aligned-center": {
      alignItems: "center",
    },
    "& .wp-block-jetpack-layout-grid": {
      display: "block",
      margin: theme.spacing(0, 0),
      padding: theme.spacing(4, 0),
      [theme.breakpoints.up("md")]: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        columnGap: theme.spacing(8),
      },
      "& .size-full": {
        margin: theme.spacing(0),
      },
    },
    "& .has-base-background-color": {
      position: "relative",
    },
    "& .has-base-2-background-color": {
      position: "relative",
    },
    "& .has-background": {
      padding: theme.spacing(4, 0),
    },
  },
  directoryRoot: {
    width: "100vw",
    maxWidth: "100vw",
    padding: 0,
    position: "relative",
    boxSizing: "border-box",
    height: "min-content",
  },
  directoryHeaderActions: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  directoryContainer: {
    width: "100%",
    padding: theme.spacing(20, 0, 14),
  },
  directoryHeader: {
    textAlign: "center",
    maxWidth: 1000,
    padding: theme.spacing(0, 4),
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  directoryTitle: {
    color: "#fff !important",
    fontSize: "52px !important",
    fontWeight: "500 !important",
    lineHeight: "3rem !important",
  },
  directoryDescription: {
    color: "#fff !important",
    fontSize: "24px !important",
    fontWeight: "400 !important",
    lineHeight: "2.2rem !important",
    marginTop: theme.spacing(5),
  },
  directorySectionContainer: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    padding: theme.spacing(8),
    position: "relative",
  },
  directorySectionHeader: {},
  directorySectionTitle: {
    marginBottom: theme.spacing(3),
    fontSize: "40px !important",
    fontWeight: "500 !important",
  },
  directorySectionDescription: {
    marginBottom: theme.spacing(3),
    fontSize: "20px !important",
  },
  directorySectionContainerContent: {
    maxWidth: 1000,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  directoryTable: {
    display: "grid",
    gridTemplateColumns: "1fr",
    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      columnGap: 1,
    },
  },
  directoryTablePost: {
    display: "grid",
    gridTemplateColumns: "40px auto",
    columnGap: theme.spacing(2),
    alignItems: "center",
    padding: theme.spacing(2),
    border: "1px solid #eee",
    marginBottom: 1,
    "& img": {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      height: 40,
      width: 40,
      borderRadius: 100,
      objectFit: "cover",
      border: "1px solid #FFF",
    },
    "& a": {
      display: "block",
      color: theme.palette.primary.main,
      textDecoration: "none",
      fontSize: "22px",
      "&:hover": {
        color: theme.palette.primary.dark,
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
  },
  directoryTablePostAvatar: {
    borderRadius: 100,
    height: 40,
    width: 40,
    border: "1px solid #FFF",
    background: "#ccc",
  },
  sectionActions: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(5),
    width: "max-content",
    "& div, button": {
      display: "inline-block",
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
      border: "none",
      color: "#fff",
      fontSize: "1.2rem",
      cursor: "pointer",
    },
    "& button": {
      "&:hover": {
        background: theme.palette.primary.light,
      },
    },
    "& .action-link-button": {
      background: "#fafafa",
      color: theme.palette.primary.main,
      textTransform: "capitalize",
    },
  },
  innerAnchor: {
    position: "absolute",
    top: -80,
  },
}));
