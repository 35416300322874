import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

import Button from "@shared/components/Button";
import Hidden from "@shared/components/Hidden";
import NeedHelp from "@shared/components/NeedHelp";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3, 3),
      flexDirection: "column",
      backgroundColor: "#f2f2f2",
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3, 3),
      backgroundColor: "#fafafa",
      borderRadius: 8,
    },
    "& .title": {
      fontSize: 24,
      marginBottom: theme.spacing(3),
    },
    "& .booking-details": {
      paddingBottom: theme.spacing(7),
    },
  },
}));

const BidQuote = ({ onClick, loading, enableCheckoutButton }) => {

  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Hidden desktop>
        <Typography variant="h3" className="title">
          Price Quote
        </Typography>
        <Typography>Once you submit a quote request, we will reach out via text with the best available pricing for your job in 24 hours.</Typography>
        <NeedHelp />
      </Hidden>
      <Hidden mobile>
        <Typography variant="h3" className="title">
          Price Quote
        </Typography>
        <Typography>Once you submit a quote request, we will reach out with the best available pricing for your job in 24 hours.</Typography>
        <br />
        <br />
        <Button fullWidth disabled={!enableCheckoutButton} color="primary" variant="contained" onClick={onClick} loading={loading}>
          Submit
        </Button>
        <NeedHelp />
      </Hidden>
    </div>
  );
};

BidQuote.defaultProps = {
  date: undefined,
  address: undefined,
  apartment: undefined,
  onClick: () => {},
};

export default React.memo(BidQuote);
