/* eslint-disable no-console */
import dayjs from "dayjs";
import configConstants from "@store/constants/configTypes";
import { toastr } from "react-redux-toastr";
import deepClone from "@methods/deepClone";
import { useAxiosGet, useAxiosPost } from "../../axios";

export const fetchConfig = () => async (dispatch) => {
  try {
    dispatch({ type: configConstants.FETCH_CONFIG });
    const response = await useAxiosGet("/config/all");
    if (!response?.data) throw "response invalid";
    dispatch({ type: configConstants.SET_CONFIG, config: response.data });
  } catch (error) {
    console.error("Error fetch config: ", error);
    dispatch({ type: configConstants.RESET_CONFIG });
  }
};

export const upsertConfig = (form) => async (dispatch) => {
  try {
    const response = await useAxiosPost(`/config/update`, form);
    dispatch({ type: configConstants.SET_CONFIG, config: response.data });
    toastr.success("Updated !", "Config updated successfully");
  } catch (error) {
    console.log("Error upsert config: ", error);
    toastr.error("Error", "Something went wrong, please try again later");
  }
};

export const fireCronLogic =
  ({ cronType, hours }) =>
  async (_) => {
    try {
      await useAxiosPost("/config/trigger", { cronType, hours });
      toastr.success("Function triggered !");
    } catch (error) {
      console.log("Error manual trigger: ", error);
      toastr.error("Error", "Something went wrong, please try again later");
    }
  };

export const modifyBusinessHours = (bookingDateTime) => (dispatch, getState) => {
  const bookingHour = dayjs(bookingDateTime).hour();
  const businessHours = getState().config.businessHours;
  let updatedBusinessHours = deepClone(businessHours);
  if (bookingHour >= businessHours.earliest.hour && bookingHour <= businessHours.latest.hour) {
    return;
  }
  if (bookingHour < businessHours.earliest.hour) {
    updatedBusinessHours.earliest.hour = bookingHour;
  }
  if (bookingHour > businessHours.latest.hour) {
    updatedBusinessHours.latest.hour = bookingHour;
  }
  dispatch({ type: configConstants.UPDATE_BUSINESS_HOURS, businessHours: updatedBusinessHours });
};
