import React, { useState } from "react";
import dayjs from "dayjs";

const calculateTimeLeft = (time1, time2) => {
  let difference = dayjs(time1) - dayjs(time2);
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
    };
  }
  return timeLeft;
};

const getCountDownText = (timeLeft, time) => {
  return (
    <span style={{ maxWidth: "320px", textAlign: "center", fontSize: "18px", color: "#353535" }}>
      Due to a recent task withdrawal, the next time you can accept a new task will be <span>{dayjs(time).format("MMMM DD hh:mm A")}</span>
      <br />
      <br />
      {/* Time Left: <span style={{ color: "red" }}>{timeLeft}</span> */}
    </span>
  );
};

const CountDown = ({ time1, time2 = dayjs() }) => {
  const [timeLeft] = useState(calculateTimeLeft(time1, time2));

  let timerComponents = "";

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents += `${timeLeft[interval]} ${interval} `;
  });

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      {timerComponents.length ? getCountDownText(timerComponents, time1) : <></>}
    </div>
  );
};

CountDown.defaultProps = {
  time2: dayjs(),
};

export default CountDown;
