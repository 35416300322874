import { bidsStatus } from "@constants/bids";
import dayjs from "dayjs";
import { taskTypes } from "@constants/booking";
import { getTimestamp } from "../constants/dayjs";

export const applyFiltersMyBids = (bids, filters) => {
  let filteredBids = bids;
  const { activeStatus, searchQuery, filterTaskType, filterDateValue, filterLocation } = filters;

  switch (activeStatus) {
    case bidsStatus.pending.value:
      filteredBids = filteredBids.filter((bid) => bid.status === "pending" && bid.bidData?.expiresAt > getTimestamp());
      break;
    case bidsStatus.won.value:
      filteredBids = filteredBids.filter((bid) => bid.status === "won");
      break;
    case bidsStatus.lost.value:
      filteredBids = filteredBids.filter((bid) => bid.status === "lost");
      break;
    case bidsStatus.cancelled.value:
      filteredBids = filteredBids.filter((bid) => bid.status === "cancelled");
      break;
    default:
      null;
      break;
  }

  if (searchQuery?.trim()) {
    filteredBids = filteredBids.filter(
      (bid) =>
        bid.bookingBidId?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        bid.qualifications?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        bid.vehicleDescription?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  switch (filterTaskType) {
    case taskTypes["junk-removal"].value:
      filteredBids = filteredBids.filter((bid) => bid.task === taskTypes["junk-removal"].value);
      break;
    case taskTypes["cardboard-removal"].value:
      filteredBids = filteredBids.filter((bid) => bid.task === taskTypes["cardboard-removal"].value);
      break;
    case taskTypes["dumpster-rental"].value:
      filteredBids = filteredBids.filter((bid) => bid.task === taskTypes["dumpster-rental"].value);
      break;
    default:
      null;
  }

  if (filterDateValue) {
    filteredBids = filteredBids.filter(
      (bid) =>
        dayjs(bid.createdAt).tz(bid.timeZone).isAfter(dayjs(filterDateValue).startOf("day")) &&
        dayjs(bid.createdAt).tz(bid.timeZone).isBefore(dayjs(filterDateValue).endOf("day"))
    );
  }

  if (filterLocation) {
    filteredBids = filteredBids.filter((bid) => bid.address?.zipCode === filterLocation);
  }

  return filteredBids;
};
