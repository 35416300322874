/* eslint-disable */
import React from "react";
import { makeStyles } from "@material-ui/core";
import { Payment } from "../../../../constants/payments";
import { useSelector } from "react-redux";
import { CreditCard, AccountBalance, Receipt } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 15,
    paddingTop: 5,
    paddingLeft: 25,
    paddingRight: 25,
    position: "relative",
  },
  cardSelected: {
    textAlign: "center",
    height: 70,
    width: 130,
    verticalAlign: "top",
    padding: 20,
    border: `2px solid ${theme.palette.primary.main}`,
    display: "inline-block",
    borderRadius: 4,
    marginRight: 8,
    marginBotom: 5,
  },
  card: {
    textAlign: "center",
    height: 70,
    width: 130,
    verticalAlign: "top",
    padding: 20,
    border: "1px solid #ccc",
    display: "inline-block",
    border: `2px solid ${theme.palette.grey[100]}`,
    borderRadius: 4,
    marginRight: 8,
    marginBotom: 5,
  },
  iconContainer: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 5,
    marginTop: 5,
    color: `${theme.palette.primary.main}`,
    cursor: "pointer",
  },
}));

function PaymentMethodField({ selected, onChange, isBusiness, isQuote, isMultipleQuotes }) {
  const classes = useStyles();
  const user = useSelector((state) => state.firebase.profile);

  const paymentMethods = [
    { label: "Credit Card", value: Payment.Card, icon: <CreditCard />, authOnly: false, firstBooking: true, businessOnly: false },
    { label: "Check", value: Payment.Check, icon: <Receipt />, authOnly: isQuote ? false : true, firstBooking: true, businessOnly: true },
    {
      label: "ACH Transfer",
      value: Payment.Transfer,
      icon: <AccountBalance />,
      authOnly: isQuote ? false : true,
      firstBooking: true,
      businessOnly: true,
    },
  ];

  return (
    <div className={classes.container}>
      {paymentMethods
        .filter((paymentMethod) => {
          if (isMultipleQuotes) {
            return [Payment.Transfer, Payment.Check]?.includes(paymentMethod.value);
          }
          const authValid = (paymentMethod.authOnly && !!user?.uid) || !paymentMethod.authOnly;
          const businessValid = (paymentMethod.businessOnly && (isBusiness || !!user?.companyName)) || !paymentMethod.businessOnly;
          const firstValid = (!paymentMethod.firstBooking && user?.bookingCount > 0) || paymentMethod.firstBooking;
          return authValid && businessValid && firstValid;
        })
        .map((card, index) => (
          <div key={index} className={selected === card.value ? classes.cardSelected : classes.card} onClick={() => onChange(card.value)}>
            <div className={classes.iconContainer}>{card.icon}</div>
            <div className={classes.label}>{card.label}</div>
          </div>
        ))}
    </div>
  );
}

export default React.memo(PaymentMethodField);
