import React, { createContext, useContext, useRef } from "react";

const MainPageContext = createContext(null);

export const useMainPageContext = () => useContext(MainPageContext);

const MainPageContextProvider = ({ children }) => {
  const refs = {
    pastBookingsRef: useRef(null),
    removalRef: useRef(null),
    pitchRef: useRef(null),
  };

  return <MainPageContext.Provider value={{ refs }}>{children}</MainPageContext.Provider>;
};

export default MainPageContextProvider;
